import React, { useCallback, useState } from 'react';
import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Input,
  Label,
  Pagination,
  Popup,
  Table,
} from 'semantic-ui-react';

import { StatusOptions } from '../../constants/Enums';
import Config from '../../constants/Config';
import ElectronicContentModalContainer from '../../containers/ElectronicContentModalContainer';

import styles from './ElectronicContents.module.scss';

const ElectronicContents = React.memo(({ categories, items, contentTotal }) => {
  const [t] = useTranslation();
  const [isContentModalOpened, setContentModalOpened] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [isViewing, setIsViewing] = useState(false);

  const handleEditOpen = useCallback((id) => {
    setSelectedId(id);
    setContentModalOpened(true);
  }, []);

  const handleViewOpen = useCallback((id) => {
    setSelectedId(id);
    setContentModalOpened(true);
    setIsViewing((prev) => !prev);
  }, []);

  const handleContentModalOpen = useCallback(() => {
    setSelectedId(undefined);
    setContentModalOpened(true);
  }, []);

  const handleContentModalClose = useCallback(() => {
    setContentModalOpened(false);
    setIsViewing(false);
  }, []);

  const textCell = (text, numCharacters = 20) => {
    return text.length > numCharacters ? (
      <Popup
        size="tiny"
        content={text}
        trigger={<span>{truncate(text, { length: numCharacters })}</span>}
      />
    ) : (
      text
    );
  };

  const categoryOptions = categories.map(({ id, name: categoryName }) => ({
    key: id,
    value: id,
    text: categoryName,
  }));

  const renderLabel = (label) => ({
    content: truncate(label.text, { length: 20 }),
  });

  return (
    <div className={styles.wrapper}>
      <Form size="small">
        <div className={styles.wrapperFilterAdd}>
          <div className={styles.filters}>
            <Input
              icon="filter"
              iconPosition="left"
              placeholder="Tên bản tin"
              className={styles.input}
            />
            <Dropdown
              options={categoryOptions}
              selection
              multiple
              placeholder="Chọn loại lĩnh vực"
              className={styles.input}
              renderLabel={renderLabel}
            />
            <Dropdown
              options={StatusOptions.map((item) => ({
                ...item,
                text: t(`common.${item.value}`),
              }))}
              selection
              multiple
              className={styles.input}
              placeholder="Chọn trạng thái"
            />
            <Button content="Lọc" className={styles.fieldButton} />
          </div>
          <Button
            content="Thêm"
            className={styles.fieldButtonAdd}
            type="button"
            onClick={handleContentModalOpen}
          />
        </div>
      </Form>
      <div className={styles.wrapperTable}>
        <Table unstackable singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('common.name')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.type')}</Table.HeaderCell>
              {/* <Table.HeaderCell>{t('common.description')}</Table.HeaderCell> */}
              <Table.HeaderCell>{t('common.category')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.playCount')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.status')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              const categoriesToString = item.categories.map(({ name }) => name).join(',');

              return (
                <Table.Row key={item.id}>
                  <Table.Cell>{textCell(item.name, 30)}</Table.Cell>
                  <Table.Cell>{t(`common.${item.type}`)}</Table.Cell>
                  {/* <Table.Cell>{item.description}</Table.Cell> */}
                  <Table.Cell>{textCell(categoriesToString)}</Table.Cell>
                  <Table.Cell>{item.playCount}</Table.Cell>
                  <Table.Cell>
                    <Label
                      color={StatusOptions.find(({ value }) => value === item.status).color}
                      content={t(`common.${item.status}`)}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Button
                      className={styles.buttonIcon}
                      size="mini"
                      icon="eye"
                      color="blue"
                      onClick={() => handleViewOpen(item.id)}
                    />
                    <Button
                      className={styles.buttonIcon}
                      size="mini"
                      icon="edit outline"
                      color="yellow"
                      onClick={() => handleEditOpen(item.id)}
                    />
                    <Button
                      className={classNames(styles.buttonIcon, styles.delete)}
                      size="mini"
                      icon="trash alternate outline"
                      color="red"
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {items.length > 0 && (
          <div className={styles.pagination}>
            <Pagination
              totalPages={Math.ceil(contentTotal / Config.LIMIT_PER_PAGE)}
              boundaryRange={2}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
            />
          </div>
        )}
      </div>
      {isContentModalOpened && (
        <ElectronicContentModalContainer
          onClose={handleContentModalClose}
          selectedId={selectedId}
          isViewing={isViewing}
        />
      )}
    </div>
  );
});

export default ElectronicContents;

ElectronicContents.propTypes = {
  categories: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  contentTotal: PropTypes.number.isRequired,
};
