import { attr, fk, many } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';
import Config from '../constants/Config';

export default class extends BaseModel {
  static modelName = 'ContentLocation';

  static fields = {
    id: attr(),
    position: attr(),
    name: attr(),
    color: attr(),
    type: attr(),
    file: attr(),
    fileDirname: attr(),
    playCount: attr(),
    status: attr(),
    wardId: fk({
      to: 'Ward',
      as: 'ward',
      relatedName: 'contentLocations',
    }),
    deviceId: fk({
      to: 'Device',
      as: 'device',
      relatedName: 'contentLocations',
    }),
    contentId: fk({
      to: 'Content',
      as: 'content',
      relatedName: 'contentLocations',
    }),
    // categories: many('Category', 'contents'),
    // locations: many('Ward', 'contents'),
  };

  static reducer({ type, payload }, ContentLocation) {
    switch (type) {
      case ActionTypes.SCHEDULES_FETCH__SUCCESS:
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
        if (payload.contentLocations) {
          payload.contentLocations.forEach((location) => {
            ContentLocation.upsert(location);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
      case ActionTypes.CONTENTS_FETCH__SUCCESS:
        ContentLocation.all().delete();

        if (payload.contentLocations) {
          payload.contentLocations.forEach((location) => {
            ContentLocation.upsert(location);
          });
        }

        break;

      // case ActionTypes.CONTENT_CREATE:
      // case ActionTypes.CONTENT_CREATE_HANDLE:
      // case ActionTypes.CONTENT_UPDATE__SUCCESS:
      // case ActionTypes.CONTENT_UPDATE_HANDLE:
      //   Content.upsert(payload.content);

      //   break;
      case ActionTypes.CONTENT_UPDATE__SUCCESS:
      case ActionTypes.CONTENT_CREATE__SUCCESS:
        if (payload.contentLocations) {
          const contentLocationModels = ContentLocation.filter({ contentId: payload.content.id });

          if (contentLocationModels) {
            contentLocationModels.delete();
          }

          payload.contentLocations.forEach((location) => {
            ContentLocation.upsert(location);
          });
        }

        break;
      // case ActionTypes.CONTENT_UPDATE:
      //   Content.withId(payload.id).update(payload.data);

      //   break;
      // case ActionTypes.CONTENT_DELETE:
      //   Content.withId(payload.id).delete();

      //   break;
      case ActionTypes.CONTENT_DELETE__SUCCESS:
      case ActionTypes.CONTENT_DELETE_HANDLE: {
        const contentLocationsModel = ContentLocation.filter({
          contentId: payload.content.id,
        }).toModelArray();

        if (contentLocationsModel.length > 0) {
          contentLocationsModel.forEach((contentLocationModel) => {
            contentLocationModel.delete();
          });
        }

        break;
      }
      case ActionTypes.CONTENT_UPDATE_HANDLE:
        if (payload.included?.contentLocations) {
          payload.included.contentLocations.forEach((item) => {
            ContentLocation.upsert(item);
          });
        }

        break;
      case ActionTypes.CONTENT_LOCATION_CREATE_HANDLE:
        if (payload.contentLocation) {
          ContentLocation.upsert(payload.contentLocation);
        }

        break;
      case ActionTypes.CONTENT_LOCATION_UPDATE_HANDLE:
        if (payload.contentLocations) {
          payload.contentLocations.forEach((location) => {
            ContentLocation.upsert(location);
          });
        }

        break;
      case ActionTypes.CONTENT_LOCATION_DELETE_HANDLE:
        if (payload.contentLocation) {
          ContentLocation.withId(payload.contentLocation.id)?.delete();
        }

        break;
      default:
    }
  }

  getOrderedAttachmentsQuerySet() {
    return this.attachments.orderBy('id', false);
  }

  getOrderedCategoriesQuerySet() {
    return this.categories.orderBy('id', false);
  }

  getOrderedSchedulesQuerySet() {
    return this.schedules.orderBy('start', false);
  }

  getOrderedWardsQuerySet() {
    return this.locations.orderBy('id', false);
  }
}
