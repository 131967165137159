import { areDatesEqual } from './modify-time';

export function pickLocationSelected(locations, sources) {
  const selectedWardIds = locations.map((w) => w.id);

  const wardOptions = sources.filter((item) => selectedWardIds.includes(item.id) && item);

  return wardOptions;
}

export const convertScheduleContent = (schedules, sources) => {
  if (!schedules || schedules.length === 0) return [];

  /**
   * Convert { start: 'time', end: 'time}
   * 
   * To {
      NgayPhat: epochTime,
      ThoiDiemPhat: [
        { 
          ThoiGianBatDau: epochTime, 
          ThoiGianKetThuc: epochTime 
        }
      ],
    }
   */

  const rs = schedules.reduce((result, schedule) => {
    const st = new Date(schedule.start).getTime();
    const en = new Date(schedule.end).getTime();

    const date = new Date(schedule.start).toDateString();

    const index = result.findIndex((it) => new Date(it.NgayPhat).toDateString() === date);

    if (index !== -1) {
      const time = {
        id: new Date(st).toDateString(),
        NgayPhat: result[index].NgayPhat,
        ThoiDiemPhat: [
          ...result[index].ThoiDiemPhat,
          {
            id: schedule.id,
            ThoiGianBatDau: st,
            ThoiGianKetThuc: en,
            title: schedule.title,
            ward: schedule.ward,
            wardId: schedule.wardId,
            playStatus: schedule.playStatus,
          },
        ],
      };

      const newResult = result.map((it, idx) => (idx === index ? time : it));

      return newResult;
    }

    const time = {
      id: new Date(st).toDateString(),
      NgayPhat: st,
      ThoiDiemPhat: [
        {
          id: schedule.id,
          ThoiGianBatDau: st,
          ThoiGianKetThuc: en,
          title: schedule.title,
          ward: schedule.ward,
          wardId: schedule.wardId,
          playStatus: schedule.playStatus,
        },
      ],
    };
    return [...result, time];
  }, []);

  rs.sort((a, b) => a.NgayPhat - b.NgayPhat);

  return rs.map((s) => {
    const { already, items } = areDatesEqual(s.NgayPhat, s.ThoiDiemPhat, sources);
    return { ...s, already, schedules: items };
  });
};

export const normalAndPriorityTopic = (sources) => {
  let topic;
  let location;
  const { provinces, districts, wards } = sources;

  if (wards.length === 1) {
    const { code, id: wardId } = wards[0];
    topic = `xa/${code}`;
    location = [wardId];

    return { topic, location };
  }

  if (wards.length > 1 && districts.length === 1) {
    const { code } = districts[0];
    topic = `huyen/${code}`;
    location = wards.map((w) => w.id);

    return { topic, location };
  }

  const { code } = provinces[0];

  topic = `tinh/${code}`;
  location = wards.map((w) => w.id);

  return { topic, location };
};

export const urgentTopic = (sources) => {
  let topic;
  let location;
  const { provinces, districts, wards, hamlets, devices } = sources;

  if (devices.length === 1) {
    const { code, id: deviceId } = devices[0];
    topic = `thietbi/${code}`;
    location = [deviceId];

    return { topic, location };
  }

  if (devices.length > 1 && hamlets.length === 1) {
    const { code } = wards[0];

    topic = `xa/${code}`;
    location = devices.map((d) => d.id);

    return { topic, location };
  }

  if (hamlets.length > 1 && wards.length === 1) {
    const { code } = wards[0];
    topic = `xa/${code}`;
    location = devices.map((d) => d.id);

    return { topic, location };
  }

  if (wards.length > 1 && districts.length === 1) {
    const { code } = districts[0];
    topic = `huyen/${code}`;
    location = devices.map((d) => d.id);

    return { topic, location };
  }

  const { code } = provinces[0];
  topic = `tinh/${code}`;
  location = devices.map((d) => d.id);

  return { topic, location };
};

export const topicBasedOnSelectedLocations = (sources, type) => {
  let topic;
  let location;

  switch (type) {
    case 2:
    case 3:
      ({ topic, location } = normalAndPriorityTopic(sources));
      return { topic, location };

    case 1:
      ({ topic, location } = urgentTopic(sources));

      return { topic, location };

    default:
      return { topic, location };
  }
};

export const getDuration = (file) => {
  return new Promise((resolve) => {
    const fileURL = URL.createObjectURL(file);
    const mediaElement = file.type.startsWith('audio') ? new Audio(fileURL) : new Video(fileURL);

    mediaElement.addEventListener('loadedmetadata', () => {
      const { duration } = mediaElement;
      URL.revokeObjectURL(fileURL);
      resolve(duration);
    });
  });
};

export const removeValues = (sources, condition, property) => {
  if (sources.length <= 0) return [];

  const values = !Array.isArray(condition) ? [condition] : condition;

  const properties = values.map((it) => it[property]);

  return sources.filter((s) => !properties.includes(s[property]));
};

export const pickScheduleInLocations = (locations, schedules, property) => {
  if (locations.length <= 0) return [];

  const targets = [];
  const locationIds = locations.map((it) => it.id);

  if (locationIds.length > 0) {
    const scheduleLocationIds = schedules.map((it) => it[property]);

    locationIds.forEach((locationId) => {
      if (scheduleLocationIds.includes(locationId)) {
        const filterSchedules = schedules.filter((ls) => ls[property] === locationId);

        targets.push(...filterSchedules);
      }
    });
  }

  return targets;
};
