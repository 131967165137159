const USERS = 'USERS';
const USER_SETTINGS = 'USER_SETTINGS';
const PROVINCE_ADD = 'PROVINCE_ADD';
const PROVINCE_SETTINGS = 'PROVINCE_SETTINGS';
const STATION = 'STATION';
const USERS_ACCOUNTS_MODAL = 'USERS_ACCOUNTS_MODAL';
export default {
  USERS,
  USERS_ACCOUNTS_MODAL,
  USER_SETTINGS,
  PROVINCE_ADD,
  PROVINCE_SETTINGS,
  STATION,
};
