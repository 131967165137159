import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tab } from 'semantic-ui-react';

import ManagersPane from './ManagersPane';
import BackgroundPane from './BackgroundPane';
import GeneralPane from './GeneralPane';
import CategoriesPane from './CategoriesPane';

const ProvinceSettingsModal = React.memo(
  ({
    canAdd,
    canEdit,
    canDelete,
    name,
    code,
    background,
    backgroundImage,
    isBackgroundImageUpdating,
    isDarkMode,
    isAdmin,
    managers,
    allUsers,
    categories,
    error,
    onUpdate,
    onBackgroundImageUpdate,
    onDelete,
    onManagerCreate,
    onManagerDelete,
    onCategoryCreate,
    onCategoryUpdate,
    onCategoryDelete,
    onCategoryMove,
    onClose,
    // onCategoryMove,
  }) => {
    const [t] = useTranslation();

    const handleBackgroundUpdate = useCallback(
      (newBackground) => {
        onUpdate({
          background: newBackground,
        });
      },
      [onUpdate],
    );

    const handleBackgroundImageDelete = useCallback(() => {
      onUpdate({
        backgroundImage: null,
      });
    }, [onUpdate]);

    const panes = [
      {
        menuItem: t('common.general', {
          context: 'title',
        }),
        render: () => (
          <GeneralPane
            name={name}
            code={code}
            error={error}
            canEdit={canEdit}
            canDelete={canDelete}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        ),
      },
      {
        menuItem: t('common.category', {
          context: 'title',
        }),
        render: () => (
          <CategoriesPane
            isAdmin={isAdmin}
            categories={categories}
            onCreate={onCategoryCreate}
            onUpdate={onCategoryUpdate}
            onDelete={onCategoryDelete}
            onMove={onCategoryMove}
            // onCategoryMove={onCategoryMove}
          />
        ),
      },
      // {
      //   menuItem: t('common.managers', {
      //     context: 'title',
      //   }),
      //   render: () => (
      //     <ManagersPane
      //       canAdd={canAdd}
      //       items={managers}
      //       allUsers={allUsers}
      //       onCreate={onManagerCreate}
      //       onDelete={onManagerDelete}
      //     />
      //   ),
      // },
    ];

    // const panesBackground =
    //   (isAdmin && [
    //     {
    //       menuItem: t('common.background', {
    //         context: 'title',
    //       }),
    //       render: () => (
    //         <BackgroundPane
    //           item={background}
    //           imageCoverUrl={backgroundImage && backgroundImage.coverUrl}
    //           isImageUpdating={isBackgroundImageUpdating}
    //           isDarkMode={isDarkMode}
    //           onUpdate={handleBackgroundUpdate}
    //           onImageUpdate={onBackgroundImageUpdate}
    //           onImageDelete={handleBackgroundImageDelete}
    //         />
    //       ),
    //     },
    //   ]) ||
    //   [];

    return (
      <div style={{ margin: '0 20px' }}>
        <Tab
          menu={{
            secondary: true,
            pointing: true,
          }}
          panes={[
            ...panes,
            //  ...panesBackground
          ]}
        />
      </div>
    );
  },
);

ProvinceSettingsModal.propTypes = {
  canAdd: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  background: PropTypes.object,
  backgroundImage: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
  isBackgroundImageUpdating: PropTypes.bool.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  /* eslint-disable react/forbid-prop-types */
  managers: PropTypes.array.isRequired,
  allUsers: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  error: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
  onUpdate: PropTypes.func.isRequired,
  onBackgroundImageUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onManagerCreate: PropTypes.func.isRequired,
  onManagerDelete: PropTypes.func.isRequired,
  onCategoryCreate: PropTypes.func.isRequired,
  onCategoryUpdate: PropTypes.func.isRequired,
  onCategoryDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCategoryMove: PropTypes.func.isRequired,
  // onCategoryMove: PropTypes.func.isRequired,
};

ProvinceSettingsModal.defaultProps = {
  background: undefined,
  backgroundImage: undefined,
  error: undefined,
};

export default ProvinceSettingsModal;
