import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createUserGroup(data) {
  const localId = yield call(createLocalId);
  const { id: provinceId } = yield select(selectors.selectCurrentProvince);

  yield put(
    actions.createUserGroup({
      ...data,
      id: localId,
    }),
  );

  let userGroup;
  try {
    ({ item: userGroup } = yield call(request, api.createUserGroup, data));
  } catch (error) {
    yield put(actions.createUserGroup.failure(localId, error));
    return;
  }

  yield put(actions.createUserGroup.success(localId, userGroup, provinceId));
}

export function* handleUserGroupCreate(userGroup) {
  yield put(actions.handleUserGroupCreate(userGroup));
}

export function* updateUserGroup(id, data) {
  yield put(actions.updateUserGroup(id, data));

  let userGroup;
  try {
    ({ item: userGroup } = yield call(request, api.updateUserGroup, id, data));
  } catch (error) {
    yield put(actions.updateUserGroup.failure(id, error));
    return;
  }

  yield put(actions.updateUserGroup.success(userGroup));
}

export function* handleUserGroupUpdate(userGroup) {
  yield put(actions.handleUserGroupUpdate(userGroup));
}

export function* deleteUserGroup(id) {
  const { id: provinceId } = yield select(selectors.selectCurrentProvince);

  yield put(actions.deleteUserGroup(id));

  let userGroup;
  try {
    ({ item: userGroup } = yield call(request, api.deleteUserGroup, id));
  } catch (error) {
    yield put(actions.deleteUserGroup.failure(id, error));
    return;
  }

  yield put(actions.deleteUserGroup.success(userGroup, provinceId));
}

export function* handleUserGroupDelete(userGroup) {
  yield put(actions.handleUserGroupDelete(userGroup));
}

export function* clearUserGroupError() {
  yield put(actions.clearUserGroupError());
}

export function* fetchUserGroups({ page, limit, districtIds }) {
  yield put(actions.fetchUserGroups(page, limit));

  let userGroups;
  // let filter;
  let total;

  // if (districtIds?.length > 0) {
  //   filter = JSON.stringify({ districtId: districtIds });
  // }

  try {
    ({
      items: userGroups,
      included: { total },
    } = yield call(request, api.getUserGroups, {
      page,
      limit,
      // filter
    }));
  } catch (error) {
    yield put(actions.fetchUserGroups.failure(error));
  }

  yield put(actions.fetchUserGroups.success(userGroups, total));
}

export default {
  createUserGroup,
  handleUserGroupCreate,
  updateUserGroup,
  handleUserGroupUpdate,
  deleteUserGroup,
  handleUserGroupDelete,
  clearUserGroupError,
  fetchUserGroups,
};
