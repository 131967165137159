import { createSelector } from 'redux-orm';

import orm from '../orm';

export const makeSelectProvinceManagerById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ ProvinceManager }, id) => {
      const provinceManagerModel = ProvinceManager.withId(id);

      if (!provinceManagerModel) {
        return provinceManagerModel;
      }

      return provinceManagerModel.ref;
    },
  );

export const selectProvinceManagerById = makeSelectProvinceManagerById();

export default {
  makeSelectProvinceManagerById,
  selectProvinceManagerById,
};
