import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Icon, Input, Message, Modal } from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { getDuration } from '../../../utils/content-method';
import Config from '../../../constants/Config';

import styles from './MergeFile.module.scss';

const durationToTime = (durationInSeconds) => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor(durationInSeconds % 60);

  let timeFormats = [];

  if (hours > 0) {
    timeFormats = [hours, minutes, seconds];
  } else {
    timeFormats = [minutes, seconds];
  }

  return timeFormats.map((n) => (n >= 10 ? n : `0${n}`)).join(':');
};

const formatBytes = (bytes) => {
  if (bytes === 0) return '0 B';

  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  const k = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${(bytes / k ** i).toFixed(2)} ${units[i]}`;
};

const createMessage = (message) => {
  if (!message) return message;

  switch (message) {
    case 'load':
    case 'merge':
      return { type: 'warning', content: 'common.handleFileWaitingForASeconds' };

    default:
      return { type: 'error', content: 'common.unknownError' };
  }
};

const LIMIT_SIZE = Config.FILE_SIZE;

const MergeFile = React.memo(
  ({
    accessToken,
    isConcating,
    attachment,
    error,
    onClose,
    onConcatClear,
    onDeleteAttachment,
    onErrorClear,
    onMergeFiles,
    onSelect,
  }) => {
    const [t] = useTranslation();

    const fileRef = useRef(null);
    const [files, setFiles] = useState([]);
    // const [loading, setLoading] = useState(false);
    // const [mergeAudio, setMergeAudio] = useState();
    const [filename, setFilename] = useState(attachment?.name || '');
    const [message, setMessage] = useState();

    const modalRef = useRef(null);

    const announcement = useMemo(() => createMessage(message || !!error), [message, error]);

    const totalFileSize = useMemo(() => {
      const size = files.reduce((s, file) => s + file.size, 0);

      return formatBytes(size);
    }, [files]);

    const isLargerThanLimit = +totalFileSize.split(' ')[0] >= LIMIT_SIZE;

    const handleOnClickFile = useCallback(() => {
      fileRef.current.click();
    }, []);

    const handleOnChangeFile = useCallback(async (e) => {
      const arrayFile = Object.values(e.target.files);

      if (arrayFile) {
        // setLoading(true);
        setMessage('load');
        e.target.value = null;

        try {
          const promises = arrayFile.map(async (file) => {
            const duration = await getDuration(file);

            return {
              name: file.name,
              size: file.size,
              duration,
              id: `${new Date().getTime() * Math.exp(Math.random())}`,
              file,
            };
          });

          const results = await Promise.all(promises);

          setFiles((prev) => [...prev, ...results]);

          setMessage();
          // setLoading(false);
        } catch (err) {
          setMessage(err.message);
          // setLoading(false);
        }
      }
    }, []);

    const handleDeleteFile = useCallback((id) => {
      setFiles((prev) => prev.filter((it) => it.id !== id));
    }, []);

    const handleConcatAudio = () => {
      const filesMap = files.map((it) => it.file);

      if (isLargerThanLimit) {
        return;
      }

      onMergeFiles({ file: filesMap });
    };

    const handleSaveFile = useCallback(() => {
      const attachmentData = {
        ...attachment,
      };

      if (attachment && filename) {
        const ext = attachmentData.name.split('.')[1];
        attachmentData.name = `${filename}.${ext}`;
      }

      onSelect(attachmentData);
      onConcatClear();
      onClose();
    }, [attachment, filename, onSelect, onClose, onConcatClear]);

    const handleOnChange = useCallback((e) => {
      setFilename(e.target.value);
    }, []);

    const handleCloseIcon = useCallback(
      (e) => {
        if (e.target.className.includes('close') && announcement?.type !== 'warning') {
          if (attachment) {
            onConcatClear();
            onDeleteAttachment(attachment.id);
          }

          if (error) onErrorClear();

          onClose();
        }
      },
      [
        announcement?.type,
        error,
        attachment,
        onClose,
        onConcatClear,
        onDeleteAttachment,
        onErrorClear,
      ],
    );

    const handleClearMergeFile = () => {
      setFiles([]);
      onConcatClear();
      onDeleteAttachment(attachment.id);
    };

    const handleOnDragEnd = (result) => {
      if (!result.destination) return;

      const items = Array.from(files);

      const [reorderedItem] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderedItem);

      setFiles(items);
    };

    const handleDismiss = useCallback(() => {
      if (message) setMessage();
      if (error) onErrorClear();
    }, [message, error, onErrorClear]);

    useEffect(() => {
      setMessage(isConcating ? 'merge' : undefined);
    }, [isConcating]);

    const getItemStyle = useCallback((draggableStyle, snapshot) => {
      const modalElement = modalRef.current.dimmerRef.current.children[0];

      const customStyle = { ...draggableStyle };

      if (draggableStyle.top && modalElement) {
        if (modalElement) {
          const rect = modalElement.getBoundingClientRect();

          customStyle.top = draggableStyle.top - rect.top;
          customStyle.left = draggableStyle.left - rect.left;
        }
      }

      return {
        ...customStyle,
        ...(snapshot.isDragging && {
          background: 'lightgreen',
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          textAlign: 'inherit',
          display: 'grid',
        }),
      };
    }, []);

    const tableNode = (
      <div className={styles.contentWrapper}>
        <div className={styles.caption}>
          <p> {t('common.pleaseSelectFilesInTheOrderToBePlayed')}</p>
          <Button
            icon="upload"
            content={t('action.uploadFileAudio')}
            className={styles.upload}
            onClick={handleOnClickFile}
          />
          <input
            type="file"
            multiple
            accept="audio/*"
            ref={fileRef}
            hidden
            onChange={handleOnChangeFile}
          />
        </div>
        <Message
          size="small"
          info={!isLargerThanLimit}
          negative={isLargerThanLimit}
          content={t('common.totalFileSizeDoNotExceedTheLimit', {
            fileSize: totalFileSize,
            limit: LIMIT_SIZE,
          })}
          header={isLargerThanLimit ? t('common.totalFileSizeExceedsLimit') : ''}
        />
        <div className={styles.containerDragDrop}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="rows">
              {(provided) => (
                <table
                  {...provided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
                  ref={provided.innerRef}
                  className={styles.table}
                  style={{ pointerEvents: isConcating ? 'none' : 'auto' }}
                >
                  <thead className={styles.tableHeader}>
                    <tr>
                      <th>STT</th>
                      <th>{t('common.nameFile')}</th>
                      <th>{t('common.fileSize')}</th>
                      <th>{t('common.duration')}</th>
                      <th aria-label="actions" />
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((it, index) => (
                      <Draggable key={it.id} draggableId={it.id} index={index}>
                        {({ innerRef, draggableProps, dragHandleProps }, snapshot) => (
                          <tr
                            ref={innerRef}
                            {...draggableProps} // eslint-disable-line react/jsx-props-no-spreading
                            {...dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
                            style={getItemStyle(draggableProps.style, snapshot)}
                          >
                            <td>{index + 1}</td>
                            <td>{it.name}</td>
                            <td>{formatBytes(it.size)}</td>
                            <td>{durationToTime(it.duration)}</td>
                            <td>
                              <Button
                                icon="trash alternate outline"
                                className={styles.delete}
                                onClick={() => handleDeleteFile(it.id)}
                              />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div>
          <Button
            loading={isConcating && message === 'merge'}
            className={styles.save}
            content={t('action.concat')}
            disabled={files.length <= 0}
            type="button"
            onClick={handleConcatAudio}
          />
          <Button
            negative
            content={t('action.clearAll')}
            disabled={files.length <= 0 || (isConcating && message === 'merge')}
            type="button"
            onClick={() => setFiles([])}
          />
        </div>
      </div>
    );

    return (
      <Modal
        open
        centered={false}
        closeIcon
        onClose={handleCloseIcon}
        className={styles.wrapper}
        ref={modalRef}
      >
        <Modal.Header className={styles.header}>
          <div className={styles.wrapperIcon}>
            <Icon name="microphone" />
          </div>
          {t('common.joinMultiFilesAudio')}
        </Modal.Header>
        <Modal.Content>
          {announcement && (
            <Message
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(announcement.type === 'error' && {
                onDismiss: handleDismiss,
              })}
              {...{ [announcement.type]: true }}
              icon
              size="mini"
            >
              {announcement.type === 'warning' && <Icon name="circle notched" loading />}
              <Message.Header>
                {t(announcement.content, {
                  message: message === 'load' ? `tải` : 'ghép nối',
                })}
              </Message.Header>
            </Message>
          )}
          {!attachment ? (
            tableNode
          ) : (
            <Form>
              <div className={styles.audioControls}>
                <Form.Field width={8}>
                  <span>{t('common.nameFile')}</span>
                  <Input
                    fluid
                    placeholder={attachment.name.split('.')[0]}
                    value={filename}
                    onChange={handleOnChange}
                  />
                </Form.Field>
                <audio src={`${attachment.url}?accessToken=${accessToken}`} controls>
                  <track kind="captions" />
                </audio>
              </div>
              <Button
                disabled={!attachment}
                className={styles.save}
                content={t('action.save')}
                onClick={handleSaveFile}
              />
              <Button negative content={t('action.cancel')} onClick={handleClearMergeFile} />
            </Form>
          )}
        </Modal.Content>
      </Modal>
    );
  },
);

MergeFile.propTypes = {
  accessToken: PropTypes.string.isRequired,
  isConcating: PropTypes.bool.isRequired,
  attachment: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  error: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onClose: PropTypes.func.isRequired,
  onConcatClear: PropTypes.func.isRequired,
  onDeleteAttachment: PropTypes.func.isRequired,
  onErrorClear: PropTypes.func.isRequired,
  onMergeFiles: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

MergeFile.defaultProps = {
  attachment: null,
  error: null,
};

export default MergeFile;
