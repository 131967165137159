import ActionTypes from '../constants/ActionTypes';

const createSchedule = (schedule) => ({
  type: ActionTypes.SCHEDULE_CREATE,
  payload: {
    schedule,
  },
});

createSchedule.success = (localId, schedule) => ({
  type: ActionTypes.SCHEDULE_CREATE__SUCCESS,
  payload: {
    localId,
    schedule,
  },
});

createSchedule.failure = (localId, error) => ({
  type: ActionTypes.SCHEDULE_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleScheduleCreate = (schedule) => ({
  type: ActionTypes.SCHEDULE_CREATE_HANDLE,
  payload: {
    schedule,
  },
});

const fetchSchedules = (provinceId) => ({
  type: ActionTypes.SCHEDULES_FETCH,
  payload: {
    provinceId,
  },
});

fetchSchedules.success = (
  provinceId,
  schedules,
  users,
  contents,
  attachments,
  contentCategories,
  contentLocations,
) => ({
  type: ActionTypes.SCHEDULES_FETCH__SUCCESS,
  payload: {
    provinceId,
    schedules,
    users,
    contents,
    attachments,
    contentCategories,
    contentLocations,
  },
});

fetchSchedules.failure = (provinceId, error) => ({
  type: ActionTypes.SCHEDULES_FETCH__FAILURE,
  payload: {
    provinceId,
    error,
  },
});

const updateSchedule = (id, data) => ({
  type: ActionTypes.SCHEDULE_UPDATE,
  payload: {
    id,
    data,
  },
});

updateSchedule.success = (schedule) => ({
  type: ActionTypes.SCHEDULE_UPDATE__SUCCESS,
  payload: {
    schedule,
  },
});

updateSchedule.failure = (id, error) => ({
  type: ActionTypes.SCHEDULE_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleScheduleUpdate = (schedule) => ({
  type: ActionTypes.SCHEDULE_UPDATE_HANDLE,
  payload: {
    schedule,
  },
});

const deleteSchedule = (id) => ({
  type: ActionTypes.SCHEDULE_DELETE,
  payload: {
    id,
  },
});

deleteSchedule.success = (schedule) => ({
  type: ActionTypes.SCHEDULE_DELETE__SUCCESS,
  payload: {
    schedule,
  },
});

deleteSchedule.failure = (id, error) => ({
  type: ActionTypes.SCHEDULE_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleScheduleDelete = (schedule) => ({
  type: ActionTypes.SCHEDULE_DELETE_HANDLE,
  payload: {
    schedule,
  },
});

const addScheduleToDevice = (id, deviceId) => ({
  type: ActionTypes.SCHEDULE_TO_DEVICE_ADD,
  payload: {
    id,
    deviceId,
  },
});

addScheduleToDevice.success = (deviceSchedule) => ({
  type: ActionTypes.SCHEDULE_TO_DEVICE_ADD__SUCCESS,
  payload: {
    deviceSchedule,
  },
});

addScheduleToDevice.failure = (id, deviceId, error) => ({
  type: ActionTypes.SCHEDULE_TO_DEVICE_ADD__FAILURE,
  payload: {
    id,
    deviceId,
    error,
  },
});

const handleScheduleToDeviceAdd = (deviceSchedule) => ({
  type: ActionTypes.SCHEDULE_TO_DEVICE_ADD_HANDLE,
  payload: {
    deviceSchedule,
  },
});

const removeScheduleFromDevice = (id, deviceId) => ({
  type: ActionTypes.SCHEDULE_FROM_DEVICE_REMOVE,
  payload: {
    id,
    deviceId,
  },
});

removeScheduleFromDevice.success = (deviceSchedule) => ({
  type: ActionTypes.SCHEDULE_FROM_DEVICE_REMOVE__SUCCESS,
  payload: {
    deviceSchedule,
  },
});

removeScheduleFromDevice.failure = (id, deviceId, error) => ({
  type: ActionTypes.SCHEDULE_FROM_DEVICE_REMOVE__FAILURE,
  payload: {
    id,
    deviceId,
    error,
  },
});

const handleScheduleFromDeviceRemove = (deviceSchedule) => ({
  type: ActionTypes.SCHEDULE_FROM_DEVICE_REMOVE_HANDLE,
  payload: {
    deviceSchedule,
  },
});

const addScheduleToDistrictFilter = (id, districtId) => ({
  type: ActionTypes.SCHEDULE_TO_DISTRICT_FILTER_ADD,
  payload: {
    id,
    districtId,
  },
});

const removeScheduleFromDistrictFilter = (id, districtId) => ({
  type: ActionTypes.SCHEDULE_FROM_DISTRICT_FILTER_REMOVE,
  payload: {
    id,
    districtId,
  },
});

const clearScheduleError = () => ({
  type: ActionTypes.SCHEDULE_ERROR_CLEAR,
  payload: {},
});

export default {
  createSchedule,
  handleScheduleCreate,
  fetchSchedules,
  updateSchedule,
  handleScheduleUpdate,
  deleteSchedule,
  handleScheduleDelete,
  addScheduleToDevice,
  handleScheduleToDeviceAdd,
  removeScheduleFromDevice,
  handleScheduleFromDeviceRemove,
  addScheduleToDistrictFilter,
  removeScheduleFromDistrictFilter,
  clearScheduleError,
};
