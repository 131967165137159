import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Label, Modal } from 'semantic-ui-react';
import classNames from 'classnames';

import { pickDayOfWeek } from '../../utils/custom-repeat';
import {
  ActivityTypes,
  CONTROL_CODES,
  PriorityTypes,
  STOP_PLAYING_CONTENT,
  USER_PERMISSIONS,
  WATTAGE_MODES,
} from '../../constants/Enums';

import styles from './UserLogModal.module.scss';

const TEXT_BY_TYPE = {
  [ActivityTypes.CREATE_CONTENT]: {
    caption1: 'common.contents',
    caption2: 'common.areaCreated',
  },
  [ActivityTypes.CREATE_DEVICE]: {
    caption1: 'common.deviceCreated',
    caption2: 'common.stations',
  },
  // [ActivityTypes.CREATE_SCHEDULE]: {},
  [ActivityTypes.CREATE_STATION]: {
    caption1: 'common.stationCreated',
    caption2: 'common.location',
  },
  [ActivityTypes.CREATE_USER]: {
    caption1: 'common.userCreated',
    caption2: 'common.areaCreated',
  },
  [ActivityTypes.UPDATE_CONTENT]: {
    caption1: 'common.contentUpdated',
    caption2: 'common.areaUpdated',
  },
  [ActivityTypes.UPDATE_CONTENT_LOCATION]: {
    caption1: 'common.contentUpdated',
    caption2: 'common.areaUpdated',
  },
  [ActivityTypes.UPDATE_DEVICE]: {
    caption1: 'common.deviceUpdated',
    caption2: 'common.stations',
  },
  [ActivityTypes.UPDATE_SCHEDULE]: {
    caption1: 'common.contentUpdated',
    caption2: 'common.areaUpdated',
    caption3: 'common.scheduleUpdated',
  },
  [ActivityTypes.UPDATE_STATION]: {
    caption1: 'common.stationUpdated',
    caption2: 'common.location',
  },
  [ActivityTypes.DELETE_CONTENT]: {
    caption1: 'common.contentDeleted',
    caption2: 'common.areaDeleted',
  },
  [ActivityTypes.DELETE_CONTENT_LOCATION]: {
    caption1: 'common.contents',
    caption2: 'common.areaDeleted',
  },
  [ActivityTypes.DELETE_DEVICE]: {
    caption1: 'common.deviceDeleted',
    caption2: 'common.stations',
  },
  [ActivityTypes.DELETE_SCHEDULE]: {
    caption1: 'common.contents',
    caption2: 'common.areas',
    caption3: 'common.scheduleDeleted',
  },
  [ActivityTypes.DELETE_STATION]: {
    caption1: 'common.stationDeleted',
    caption2: 'common.location',
  },
  [ActivityTypes.DELETE_USER]: {
    caption1: 'common.userDeleted',
    caption2: 'common.areaDeleted',
  },
  [ActivityTypes.CANCEL_SCHEDULE]: {
    caption1: 'common.contentCancelSchedule',
    caption2: 'common.areaCancelSchedule',
  },
  [ActivityTypes.CONTROL_DEVICE]: {
    caption1: 'common.devices',
    caption2: 'common.controlDevice',
  },
  [ActivityTypes.CONTROL_MULTI_DEVICE]: {
    caption1: 'common.devices',
    caption2: 'common.controlDevice',
  },
  [ActivityTypes.CREATE_USER_GROUP]: {
    caption1: 'common.userGroups',
  },
  [ActivityTypes.UPDATE_USER_GROUP]: {
    caption1: 'common.userGroups',
  },
  [ActivityTypes.DELETE_USER_GROUP]: {
    caption1: 'common.userGroups',
  },
};

const IP_ADDRESS = '0.0.0.0';

const findTypeParamDeviceControl = (params, t) => {
  let paramControl;

  switch (+params.MaLenh) {
    case 0:
      paramControl = params.ThamSo.replace(/^0+/, '');

      break;
    case 1: {
      const mode = WATTAGE_MODES.find((it) => it.value === +params.ThamSo);
      paramControl = t(`common.${mode.key}`);

      break;
    }
    case 2: {
      const stopType = STOP_PLAYING_CONTENT.find((it) => it.value === +params.ThamSo);
      paramControl = t(`common.${stopType.key}`);

      break;
    }
    case 3:
      paramControl = `${params.ThamSo} (s)`;

      break;
    case 4:
      paramControl = `${t(`common.link`)}: ${params.ThamSo}`;

      break;

    default:
  }

  return paramControl;
};

const UserLogModal = React.memo(({ action, onClose }) => {
  const [t] = useTranslation();

  const detailRender = useCallback(
    (data) => {
      if (!data.type) return null;

      const text = TEXT_BY_TYPE[data.type];

      switch (data.type) {
        /* CONTENT */

        case ActivityTypes.CREATE_CONTENT:
        case ActivityTypes.UPDATE_CONTENT:
        case ActivityTypes.UPDATE_CONTENT_LOCATION:
        case ActivityTypes.DELETE_CONTENT:
        case ActivityTypes.DELETE_CONTENT_LOCATION: {
          const {
            data: { wards, content },
          } = data;

          const priority = PriorityTypes.find((it) => it.value === content?.MucDoUuTien);

          return (
            <div className={styles.wrapperDetail}>
              <div>{t(text.caption1)}</div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="list alternate" />
                  {t('common.name')}
                </strong>
                <Label className={styles.fitContent} content={content?.name || '-'} />
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="fire" />
                  {t('common.priorityLevel')}
                </strong>
                <Label
                  className={styles.fitContent}
                  content={priority?.text ? t(`common.${priority.text}`) : '-'}
                />
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="block layout" />
                  {t('common.contentType')}
                </strong>
                <Label
                  className={styles.fitContent}
                  content={content?.type ? t(`common.${content.type}`) : '-'}
                />
              </div>
              <div className={classNames(styles.row, styles.gridHorizontal)}>
                <strong className={styles.item}>
                  <Icon name="podcast" corner />
                  {t(text.caption2)}
                </strong>
                <div>
                  {wards.map((it) => (
                    <Label style={{ marginBottom: 4 }} key={it.id} content={it.name} />
                  ))}
                </div>
              </div>
            </div>
          );
        }

        /* DEVICE */
        case ActivityTypes.CREATE_DEVICE:
        case ActivityTypes.UPDATE_DEVICE:
        case ActivityTypes.DELETE_DEVICE: {
          const {
            device,
            data: { station },
          } = data;
          return (
            <div className={styles.wrapperDetail}>
              <div>{t(text.caption1)}</div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="list alternate" />
                  {t('common.name')}
                </strong>
                <Label className={styles.fitContent} content={device?.name || '-'} />
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="barcode" />
                  {t('common.deviceCode')}
                </strong>
                <Label className={styles.fitContent} content={device?.code || '-'} />
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="block layout" />
                  {t('common.deviceType')}
                </strong>
                <Label
                  className={styles.fitContent}
                  content={device?.type ? t(`common.${device?.type}`) : '-'}
                />
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="building outline" />
                  {t(text.caption2)}
                </strong>
                <Label className={styles.fitContent} content={station.name || '-'} />
              </div>
            </div>
          );
        }

        case ActivityTypes.CONTROL_DEVICE: {
          const {
            data: { device, from, to },
          } = data;

          const parameters = from.controlDevice || [];
          const controlCode = CONTROL_CODES.find((it) => it.value === +to.MaLenh);
          const previousParam = parameters.find((f) => f.MaLenh === to.MaLenh);

          const before = previousParam
            ? findTypeParamDeviceControl(previousParam, t)
            : t('common.notYetSetUp');
          const after = findTypeParamDeviceControl(to, t);

          return (
            <div className={styles.wrapperDetail}>
              <div>{t(text.caption1)}</div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="list alternate" />
                  {t('common.name')}
                </strong>
                <Label className={styles.fitContent} content={device?.name || '-'} />
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="barcode" />
                  {t('common.deviceCode')}
                </strong>
                <Label className={styles.fitContent} content={device?.code || '-'} />
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="block layout" />
                  {t('common.deviceType')}
                </strong>
                <Label
                  className={styles.fitContent}
                  content={device?.type ? t(`common.${device?.type}`) : '-'}
                />
              </div>
              {controlCode.key && (
                <>
                  <div>{t(`common.${controlCode.key}`)}</div>
                  <div className={styles.detail}>
                    <div>
                      <h5>{t('common.initial')}</h5>
                      <Label content={before} />
                    </div>
                    <div>
                      <h5>{t('common.change')}</h5>
                      <Label color="blue" content={after || '-'} />
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        }

        case ActivityTypes.CONTROL_MULTI_DEVICE: {
          const {
            data: { devices, to },
          } = data;

          const controlCode = CONTROL_CODES.find((it) => it.value === +to.MaLenh);

          const after = findTypeParamDeviceControl(to, t);

          return (
            <div className={styles.wrapperDetail}>
              <div>{t(text.caption1)}</div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="list alternate" />
                  {t('common.name')}
                </strong>
                <div>
                  {devices.map((d) => (
                    <Label
                      key={d.id}
                      className={styles.fitContent}
                      style={{ marginBottom: 4 }}
                      content={`${d.name} - ${t(`common.${d.type}`)}`}
                    />
                  ))}
                </div>
              </div>
              {controlCode.key && (
                <>
                  <div>{t(`common.${controlCode.key}`)}</div>
                  <div className={styles.detail}>
                    <div>
                      <h5>{t('common.change')}</h5>
                      <Label color="blue" content={after || '-'} />
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        }

        /* SCHEDULE */

        case ActivityTypes.UPDATE_SCHEDULE:
        case ActivityTypes.DELETE_SCHEDULE: {
          const {
            data: { wards, content, schedules },
          } = data;

          const priority = PriorityTypes.find((it) => it.value === content?.MucDoUuTien);

          return (
            <div className={styles.wrapperDetail}>
              <div>{t(text.caption1)}</div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="list alternate" />
                  {t('common.name')}
                </strong>
                <Label className={styles.fitContent} content={content?.name || '-'} />
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="fire" />
                  {t('common.priorityLevel')}
                </strong>
                <Label
                  className={styles.fitContent}
                  content={priority?.text ? t(`common.${priority.text}`) : '-'}
                />
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="block layout" />
                  {t('common.contentType')}
                </strong>
                <Label
                  className={styles.fitContent}
                  content={content?.type ? t(`common.${content.type}`) : '-'}
                />
              </div>
              <div className={classNames(styles.row, styles.gridHorizontal)}>
                <strong className={styles.item}>
                  <Icon name="podcast" corner />
                  {t(text.caption2)}
                </strong>
                <div>
                  {wards.map((it) => (
                    <Label style={{ marginBottom: 4 }} key={it.id} content={it.name} />
                  ))}
                </div>
              </div>
              <div className={classNames(styles.row, styles.gridHorizontal)}>
                <strong className={styles.item}>
                  <Icon name="podcast" corner />
                  {t(text.caption3)}
                </strong>
                <div>
                  {schedules.map((it) => (
                    <Label
                      key={it.id}
                      detail={it.title}
                      style={{ marginBottom: 4 }}
                      color="blue"
                      content={`
                      ${new Date(it.start).toLocaleTimeString('en-US', { hour12: false })}
                      -
                      ${new Date(it.end).toLocaleTimeString('en-US', { hour12: false })}
                      `}
                    />
                  ))}
                </div>
              </div>
            </div>
          );
        }

        case ActivityTypes.CANCEL_SCHEDULE: {
          const {
            data: { wards, content },
          } = data;

          const priority = PriorityTypes.find((it) => it.value === content?.MucDoUuTien);

          return (
            <div className={styles.wrapperDetail}>
              <div>{t(text.caption1)}</div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="list alternate" />
                  {t('common.name')}
                </strong>
                <Label className={styles.fitContent} content={content?.name || '-'} />
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="fire" />
                  {t('common.priorityLevel')}
                </strong>
                <Label
                  className={styles.fitContent}
                  content={priority?.text ? t(`common.${priority.text}`) : '-'}
                />
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="block layout" />
                  {t('common.contentType')}
                </strong>
                <Label
                  className={styles.fitContent}
                  content={content?.type ? t(`common.${content.type}`) : '-'}
                />
              </div>
              <div className={classNames(styles.row, styles.gridHorizontal)}>
                <strong className={styles.item}>
                  <Icon name="podcast" corner />
                  {t(text.caption2)}
                </strong>
                <div>
                  {wards.map((it) => (
                    <Label style={{ marginBottom: 4 }} key={it.id} content={it.name} />
                  ))}
                </div>
              </div>
            </div>
          );
        }

        /* STATION */

        case ActivityTypes.CREATE_STATION:
        case ActivityTypes.UPDATE_STATION:
        case ActivityTypes.DELETE_STATION: {
          const {
            data: { ward, station },
          } = data;

          return (
            <div className={styles.wrapperDetail}>
              <div>{t(text.caption1)}</div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="list alternate" />
                  {t('common.name')}
                </strong>
                <Label className={styles.fitContent}>{station?.name || '-'}</Label>
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="podcast" />
                  {t(text.caption2)}
                </strong>
                <Label className={styles.fitContent}>{ward?.name || '-'}</Label>
              </div>
            </div>
          );
        }

        /* USER */
        case ActivityTypes.CREATE_USER:
        case ActivityTypes.DELETE_USER: {
          const {
            data: { location, user },
          } = data;

          return (
            <div className={styles.wrapperDetail}>
              <div>{t(text.caption1)}</div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="list alternate" />
                  {t('common.name')}
                </strong>
                <Label className={styles.fitContent}>{user?.name || '-'}</Label>
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="mail" />
                  {t('common.email')}
                </strong>
                <Label className={styles.fitContent}>{user?.email || '-'}</Label>
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="bars" />
                  {t('user.level')}
                </strong>
                <Label className={styles.fitContent}>{t(`user.${user?.level}`)}</Label>
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon name="podcast" />
                  {t(text.caption2)}
                </strong>
                <Label className={styles.fitContent}>{location?.name || '-'}</Label>
              </div>
            </div>
          );
        }

        /* USER GROUP */
        case ActivityTypes.CREATE_USER_GROUP:
        case ActivityTypes.UPDATE_USER_GROUP:
        case ActivityTypes.DELETE_USER_GROUP: {
          const {
            data: { userGroup },
          } = data;

          return (
            <div className={styles.wrapperDetail}>
              <h5>{t(text.caption1)}</h5>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon loading name="user circle" />
                  {t('common.name')}
                </strong>
                <Label className={styles.fitContent}>{userGroup?.name || '-'}</Label>
              </div>
              <div className={styles.row}>
                <strong className={styles.item}>
                  <Icon loading name="rebel" corner />
                  {t('common.permissions')}
                </strong>
                <div>
                  {userGroup.permissions.map((it) => (
                    <Label
                      style={{ marginBottom: 4 }}
                      key={it}
                      content={t(`permission.${USER_PERMISSIONS[it]}`)}
                    />
                  ))}
                </div>
              </div>
            </div>
          );
        }

        default:
          return null;
      }
    },
    [t],
  );

  return (
    <Modal
      open
      closeIcon
      centered={false}
      size="small"
      onClose={onClose}
      className={styles.wrapper}
    >
      <Modal.Header>{t('common.activityDetails')}</Modal.Header>
      <Modal.Content>
        <div className={styles.field}>
          <div className={styles.name}>{t('common.activity')}</div>
          <div>{t(`common.${action?.type}`)}</div>
        </div>
        <div className={styles.field}>
          <div className={styles.name}>{t('common.implementationAccount')}</div>
          <div>{action?.user?.name}</div>
        </div>
        <div className={styles.field}>
          <div className={styles.name}>{t('common.time')}</div>
          <div>{pickDayOfWeek(action.createdAt, 'cccc pp, dd/MM/yyyy')}</div>
        </div>
        <div className={styles.field}>
          <div className={styles.name}>{t('common.executionIp')}</div>
          <div>{action.ipAddress || IP_ADDRESS}</div>
        </div>
        <div className={styles.box}>
          <div className={styles.name}>{t('common.detail')}</div>
          {detailRender(action)}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Đóng" color="red" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
});

UserLogModal.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  action: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UserLogModal;
