export default {
  /* Router */

  LOCATION_CHANGE_HANDLE: 'LOCATION_CHANGE_HANDLE',
  LOCATION_CHANGE_HANDLE__DISTRICT_FETCH: 'LOCATION_CHANGE_HANDLE__DISTRICT_FETCH',
  LOCATION_CHANGE_HANDLE__DEVICES_FETCH: 'LOCATION_CHANGE_HANDLE__DEVICES_FETCH',
  LOCATION_CHANGE_HANDLE__CONTENTS_FETCH: 'LOCATION_CHANGE_HANDLE__CONTENTS_FETCH',
  LOCATION_CHANGE_HANDLE__SCHEDULES_FETCH: 'LOCATION_CHANGE_HANDLE__SCHEDULES_FETCH',
  LOCATION_CHANGE_HANDLE__STATIONS_FETCH: 'LOCATION_CHANGE_HANDLE__STATIONS_FETCH',
  LOCATION_CHANGE_HANDLE__REPORTS_FETCH: 'LOCATION_CHANGE_HANDLE__REPORTS_FETCH',
  LOCATION_CHANGE_HANDLE__USER_GROUPS_FETCH: 'LOCATION_CHANGE_HANDLE__USER_GROUPS_FETCH',
  LOCATION_CHANGE_HANDLE__ACTIONS_FETCH: 'LOCATION_CHANGE_HANDLE__ACTIONS_FETCH',

  /* Socket */

  SOCKET_DISCONNECT_HANDLE: 'SOCKET_DISCONNECT_HANDLE',
  SOCKET_RECONNECT_HANDLE: 'SOCKET_RECONNECT_HANDLE',
  SOCKET_RECONNECT_HANDLE__CORE_FETCH: 'SOCKET_RECONNECT_HANDLE__CORE_FETCH',

  /* Login */

  AUTHENTICATE: 'AUTHENTICATE',
  AUTHENTICATE__SUCCESS: 'AUTHENTICATE__SUCCESS',
  AUTHENTICATE__FAILURE: 'AUTHENTICATE__FAILURE',
  AUTHENTICATE_CODE: 'AUTHENTICATE_CODE',
  AUTHENTICATE_CODE__SUCCESS: 'AUTHENTICATE_CODE__SUCCESS',
  AUTHENTICATE_CODE__FAILURE: 'AUTHENTICATE_CODE__FAILURE',
  AUTHENTICATE_CODE_RETRIEVE: 'AUTHENTICATE_CODE_RETRIEVE',
  AUTHENTICATE_CODE_RETRIEVE__SUCCESS: 'AUTHENTICATE_CODE_RETRIEVE__SUCCESS',
  AUTHENTICATE_CODE_RETRIEVE__FAILURE: 'AUTHENTICATE_CODE_RETRIEVE__FAILURE',
  AUTHENTICATE_QRCODE: 'AUTHENTICATE_QRCODE',
  AUTHENTICATE_QRCODE__SUCCESS: 'AUTHENTICATE_QRCODE__SUCCESS',
  AUTHENTICATE_QRCODE__FAILURE: 'AUTHENTICATE_QRCODE__FAILURE',
  AUTHENTICATE_QRCODE_RETRIEVE: 'AUTHENTICATE_QRCODE_RETRIEVE',
  AUTHENTICATE_QRCODE_RETRIEVE__SUCCESS: 'AUTHENTICATE_QRCODE_RETRIEVE__SUCCESS',
  AUTHENTICATE_QRCODE_RETRIEVE__FAILURE: 'AUTHENTICATE_QRCODE_RETRIEVE__FAILURE',
  AUTHENTICATE_ERROR_CLEAR: 'AUTHENTICATE_ERROR_CLEAR',
  AUTHENTICATE_GET_QRCODE: 'AUTHENTICATE_GET_QRCODE',
  AUTHENTICATE_COUNT_DOWN_CLEAR: 'AUTHENTICATE_COUNT_DOWN_CLEAR',
  AUTHENTICATE_GET_EXPIRES_CODE: 'AUTHENTICATE_GET_EXPIRES_CODE',

  /* Core */

  CORE_INITIALIZE: 'CORE_INITIALIZE',
  LOGOUT: 'LOGOUT',
  LOGOUT__ACCESS_TOKEN_INVALIDATE: 'LOGOUT__ACCESS_TOKEN_INVALIDATE',

  /* Modals */

  MODAL_OPEN: 'MODAL_OPEN',
  MODAL_CLOSE: 'MODAL_CLOSE',

  /* Sidebar */

  SIDEBAR_MENU: 'SIDEBAR_MENU',

  /* Users */

  USER_CREATE: 'USER_CREATE',
  USER_CREATE__SUCCESS: 'USER_CREATE__SUCCESS',
  USER_CREATE__FAILURE: 'USER_CREATE__FAILURE',
  USER_CREATE_HANDLE: 'USER_CREATE_HANDLE',
  USER_CREATE_ERROR_CLEAR: 'USER_CREATE_ERROR_CLEAR',
  USER_UPDATE: 'USER_UPDATE',
  USER_UPDATE__SUCCESS: 'USER_UPDATE__SUCCESS',
  USER_UPDATE__FAILURE: 'USER_UPDATE__FAILURE',
  USER_UPDATE_HANDLE: 'USER_UPDATE_HANDLE',
  USER_EMAIL_UPDATE: 'USER_EMAIL_UPDATE',
  USER_EMAIL_UPDATE__SUCCESS: 'USER_EMAIL_UPDATE__SUCCESS',
  USER_EMAIL_UPDATE__FAILURE: 'USER_EMAIL_UPDATE__FAILURE',
  USER_EMAIL_UPDATE_ERROR_CLEAR: 'USER_EMAIL_UPDATE_ERROR_CLEAR',
  USER_PASSWORD_UPDATE: 'USER_PASSWORD_UPDATE',
  USER_PASSWORD_UPDATE__SUCCESS: 'USER_PASSWORD_UPDATE__SUCCESS',
  USER_PASSWORD_UPDATE__FAILURE: 'USER_PASSWORD_UPDATE__FAILURE',
  USER_PASSWORD_UPDATE_ERROR_CLEAR: 'USER_PASSWORD_UPDATE_ERROR_CLEAR',
  USER_USERNAME_UPDATE: 'USER_USERNAME_UPDATE',
  USER_USERNAME_UPDATE__SUCCESS: 'USER_USERNAME_UPDATE__SUCCESS',
  USER_USERNAME_UPDATE__FAILURE: 'USER_USERNAME_UPDATE__FAILURE',
  USER_PERMISSION_UPDATE: 'USER_PERMISSION_UPDATE',
  USER_PERMISSION_UPDATE__SUCCESS: 'USER_PERMISSION_UPDATE__SUCCESS',
  USER_PERMISSION_UPDATE__FAILURE: 'USER_PERMISSION_UPDATE__FAILURE',
  USER_PERMISSION_UPDATE_ERROR_CLEAR: 'USER_PERMISSION_UPDATE_ERROR_CLEAR',
  USER_USERNAME_UPDATE_ERROR_CLEAR: 'USER_USERNAME_UPDATE_ERROR_CLEAR',
  USER_AVATAR_UPDATE: 'USER_AVATAR_UPDATE',
  USER_AVATAR_UPDATE__SUCCESS: 'USER_AVATAR_UPDATE__SUCCESS',
  USER_AVATAR_UPDATE__FAILURE: 'USER_AVATAR_UPDATE__FAILURE',
  USER_DELETE: 'USER_DELETE',
  USER_DELETE__SUCCESS: 'USER_DELETE__SUCCESS',
  USER_DELETE__FAILURE: 'USER_DELETE__FAILURE',
  USER_DELETE_HANDLE: 'USER_DELETE_HANDLE',
  USER_TO_DEVICE_ADD: 'USER_TO_DEVICE_ADD',
  USER_TO_DEVICE_ADD__SUCCESS: 'USER_TO_DEVICE_ADD__SUCCESS',
  USER_TO_DEVICE_ADD__FAILURE: 'USER_TO_DEVICE_ADD__FAILURE',
  USER_TO_DEVICE_ADD_HANDLE: 'USER_TO_DEVICE_ADD_HANDLE',
  USER_FROM_DEVICE_REMOVE: 'USER_FROM_DEVICE_REMOVE',
  USER_FROM_DEVICE_REMOVE__SUCCESS: 'USER_FROM_DEVICE_REMOVE__SUCCESS',
  USER_FROM_DEVICE_REMOVE__FAILURE: 'USER_FROM_DEVICE_REMOVE__FAILURE',
  USER_FROM_DEVICE_REMOVE_HANDLE: 'USER_FROM_DEVICE_REMOVE_HANDLE',
  USER_TO_DISTRICT_FILTER_ADD: 'USER_TO_DISTRICT_FILTER_ADD',
  USER_FROM_DISTRICT_FILTER_REMOVE: 'USER_FROM_DISTRICT_FILTER_REMOVE',

  /* User permission */

  CURRENT_USER_GROUP_UPDATE: 'CURRENT_USER_GROUP_UPDATE',
  USER_PERMISSION_CREATE_HANDLE: 'USER_PERMISSION_CREATE_HANDLE',
  USER_PERMISSION_UPDATE_HANDLE: 'USER_PERMISSION_UPDATE_HANDLE',

  /* User groups */
  USER_GROUP_CREATE: 'USER_GROUP_CREATE',
  USER_GROUP_CREATE__SUCCESS: 'USER_GROUP_CREATE__SUCCESS',
  USER_GROUP_CREATE__FAILURE: 'USER_GROUP_CREATE__FAILURE',
  USER_GROUP_CREATE_HANDLE: 'USER_GROUP_CREATE_HANDLE',
  USER_GROUP_ERROR_CLEAR: 'USER_GROUP_ERROR_CLEAR',
  USER_GROUP_UPDATE: 'USER_GROUP_UPDATE',
  USER_GROUP_UPDATE__SUCCESS: 'USER_GROUP_UPDATE__SUCCESS',
  USER_GROUP_UPDATE__FAILURE: 'USER_GROUP_UPDATE__FAILURE',
  USER_GROUP_UPDATE_HANDLE: 'USER_GROUP_UPDATE_HANDLE',
  USER_GROUP_DELETE: 'USER_GROUP_DELETE',
  USER_GROUP_DELETE__SUCCESS: 'USER_GROUP_DELETE__SUCCESS',
  USER_GROUP_DELETE__FAILURE: 'USER_GROUP_DELETE__FAILURE',
  USER_GROUP_DELETE_HANDLE: 'USER_GROUP_DELETE_HANDLE',
  USER_GROUPS_FETCH: 'USER_GROUPS_FETCH',
  USER_GROUPS_FETCH__SUCCESS: 'USER_GROUPS_FETCH__SUCCESS',
  USER_GROUPS_FETCH__FAILURE: 'USER_GROUPS_FETCH__FAILURE',
  /* Provinces */

  PROVINCE_CREATE: 'PROVINCE_CREATE',
  PROVINCE_CREATE__SUCCESS: 'PROVINCE_CREATE__SUCCESS',
  PROVINCE_CREATE__FAILURE: 'PROVINCE_CREATE__FAILURE',
  PROVINCE_CREATE_HANDLE: 'PROVINCE_CREATE_HANDLE',
  PROVINCE_UPDATE: 'PROVINCE_UPDATE',
  PROVINCE_UPDATE__SUCCESS: 'PROVINCE_UPDATE__SUCCESS',
  PROVINCE_UPDATE__FAILURE: 'PROVINCE_UPDATE__FAILURE',
  PROVINCE_UPDATE_HANDLE: 'PROVINCE_UPDATE_HANDLE',
  PROVINCE_BACKGROUND_IMAGE_UPDATE: 'PROVINCE_BACKGROUND_IMAGE_UPDATE',
  PROVINCE_BACKGROUND_IMAGE_UPDATE__SUCCESS: 'PROVINCE_BACKGROUND_IMAGE_UPDATE__SUCCESS',
  PROVINCE_BACKGROUND_IMAGE_UPDATE__FAILURE: 'PROVINCE_BACKGROUND_IMAGE_UPDATE__FAILURE',
  PROVINCE_DELETE: 'PROVINCE_DELETE',
  PROVINCE_DELETE__SUCCESS: 'PROVINCE_DELETE__SUCCESS',
  PROVINCE_DELETE__FAILURE: 'PROVINCE_DELETE__FAILURE',
  PROVINCE_DELETE_HANDLE: 'PROVINCE_DELETE_HANDLE',

  /* Province managers */

  PROVINCE_MANAGER_CREATE: 'PROVINCE_MANAGER_CREATE',
  PROVINCE_MANAGER_CREATE__SUCCESS: 'PROVINCE_MANAGER_CREATE__SUCCESS',
  PROVINCE_MANAGER_CREATE__FAILURE: 'PROVINCE_MANAGER_CREATE__FAILURE',
  PROVINCE_MANAGER_CREATE_HANDLE: 'PROVINCE_MANAGER_CREATE_HANDLE',
  PROVINCE_MANAGER_CREATE_HANDLE__PROVINCE_FETCH: 'PROVINCE_MANAGER_CREATE_HANDLE__PROVINCE_FETCH',
  PROVINCE_MANAGER_DELETE: 'PROVINCE_MANAGER_DELETE',
  PROVINCE_MANAGER_DELETE__SUCCESS: 'PROVINCE_MANAGER_DELETE__SUCCESS',
  PROVINCE_MANAGER_DELETE__FAILURE: 'PROVINCE_MANAGER_DELETE__FAILURE',
  PROVINCE_MANAGER_DELETE_HANDLE: 'PROVINCE_MANAGER_DELETE_HANDLE',

  /* Districts */

  DISTRICT_CREATE: 'DISTRICT_CREATE',
  DISTRICT_CREATE__SUCCESS: 'DISTRICT_CREATE__SUCCESS',
  DISTRICT_CREATE__FAILURE: 'DISTRICT_CREATE__FAILURE',
  DISTRICT_CREATE_HANDLE: 'DISTRICT_CREATE_HANDLE',
  DISTRICT_FETCH: 'DISTRICT_FETCH',
  DISTRICT_FETCH__SUCCESS: 'DISTRICT_FETCH__SUCCESS',
  DISTRICT_FETCH__FAILURE: 'DISTRICT_FETCH__FAILURE',
  DISTRICT_UPDATE: 'DISTRICT_UPDATE',
  DISTRICT_UPDATE__SUCCESS: 'DISTRICT_UPDATE__SUCCESS',
  DISTRICT_UPDATE__FAILURE: 'DISTRICT_UPDATE__FAILURE',
  DISTRICT_UPDATE_HANDLE: 'DISTRICT_UPDATE_HANDLE',
  DISTRICT_DELETE: 'DISTRICT_DELETE',
  DISTRICT_DELETE__SUCCESS: 'DISTRICT_DELETE__SUCCESS',
  DISTRICT_DELETE__FAILURE: 'DISTRICT_DELETE__FAILURE',
  DISTRICT_DELETE_HANDLE: 'DISTRICT_DELETE_HANDLE',

  /* District memberships */

  DISTRICT_MEMBERSHIP_CREATE: 'DISTRICT_MEMBERSHIP_CREATE',
  DISTRICT_MEMBERSHIP_CREATE__SUCCESS: 'DISTRICT_MEMBERSHIP_CREATE__SUCCESS',
  DISTRICT_MEMBERSHIP_CREATE__FAILURE: 'DISTRICT_MEMBERSHIP_CREATE__FAILURE',
  DISTRICT_MEMBERSHIP_CREATE_HANDLE: 'DISTRICT_MEMBERSHIP_CREATE_HANDLE',
  DISTRICT_MEMBERSHIP_CREATE_HANDLE__PROVINCE_FETCH:
    'DISTRICT_MEMBERSHIP_CREATE_HANDLE__PROVINCE_FETCH',
  DISTRICT_MEMBERSHIP_UPDATE: 'DISTRICT_MEMBERSHIP_UPDATE',
  DISTRICT_MEMBERSHIP_UPDATE__SUCCESS: 'DISTRICT_MEMBERSHIP_UPDATE__SUCCESS',
  DISTRICT_MEMBERSHIP_UPDATE__FAILURE: 'DISTRICT_MEMBERSHIP_UPDATE__FAILURE',
  DISTRICT_MEMBERSHIP_UPDATE_HANDLE: 'DISTRICT_MEMBERSHIP_UPDATE_HANDLE',
  DISTRICT_MEMBERSHIP_DELETE: 'DISTRICT_MEMBERSHIP_DELETE',
  DISTRICT_MEMBERSHIP_DELETE__SUCCESS: 'DISTRICT_MEMBERSHIP_DELETE__SUCCESS',
  DISTRICT_MEMBERSHIP_DELETE__FAILURE: 'DISTRICT_MEMBERSHIP_DELETE__FAILURE',
  DISTRICT_MEMBERSHIP_DELETE_HANDLE: 'DISTRICT_MEMBERSHIP_DELETE_HANDLE',

  /* Wards */

  WARD_CREATE: 'WARD_CREATE',
  WARD_CREATE__SUCCESS: 'WARD_CREATE__SUCCESS',
  WARD_CREATE__FAILURE: 'WARD_CREATE__FAILURE',
  WARD_CREATE_HANDLE: 'WARD_CREATE_HANDLE',
  WARD_FETCH: 'WARD_FETCH',
  WARD_FETCH__SUCCESS: 'WARD_FETCH__SUCCESS',
  WARD_FETCH__FAILURE: 'WARD_FETCH__FAILURE',
  WARD_UPDATE: 'WARD_UPDATE',
  WARD_UPDATE__SUCCESS: 'WARD_UPDATE__SUCCESS',
  WARD_UPDATE__FAILURE: 'WARD_UPDATE__FAILURE',
  WARD_UPDATE_HANDLE: 'WARD_UPDATE_HANDLE',
  WARD_DELETE: 'WARD_DELETE',
  WARD_DELETE__SUCCESS: 'WARD_DELETE__SUCCESS',
  WARD_DELETE__FAILURE: 'WARD_DELETE__FAILURE',
  WARD_DELETE_HANDLE: 'WARD_DELETE_HANDLE',

  /* Ward memberships */

  WARD_MEMBERSHIP_CREATE: 'WARD_MEMBERSHIP_CREATE',
  WARD_MEMBERSHIP_CREATE__SUCCESS: 'WARD_MEMBERSHIP_CREATE__SUCCESS',
  WARD_MEMBERSHIP_CREATE__FAILURE: 'WARD_MEMBERSHIP_CREATE__FAILURE',
  WARD_MEMBERSHIP_CREATE_HANDLE: 'WARD_MEMBERSHIP_CREATE_HANDLE',
  WARD_MEMBERSHIP_CREATE_HANDLE__PROVINCE_FETCH: 'WARD_MEMBERSHIP_CREATE_HANDLE__PROVINCE_FETCH',
  WARD_MEMBERSHIP_UPDATE: 'WARD_MEMBERSHIP_UPDATE',
  WARD_MEMBERSHIP_UPDATE__SUCCESS: 'WARD_MEMBERSHIP_UPDATE__SUCCESS',
  WARD_MEMBERSHIP_UPDATE__FAILURE: 'WARD_MEMBERSHIP_UPDATE__FAILURE',
  WARD_MEMBERSHIP_UPDATE_HANDLE: 'WARD_MEMBERSHIP_UPDATE_HANDLE',
  WARD_MEMBERSHIP_DELETE: 'WARD_MEMBERSHIP_DELETE',
  WARD_MEMBERSHIP_DELETE__SUCCESS: 'WARD_MEMBERSHIP_DELETE__SUCCESS',
  WARD_MEMBERSHIP_DELETE__FAILURE: 'WARD_MEMBERSHIP_DELETE__FAILURE',
  WARD_MEMBERSHIP_DELETE_HANDLE: 'WARD_MEMBERSHIP_DELETE_HANDLE',

  /* Hamlets */

  HAMLET_CREATE: 'HAMLET_CREATE',
  HAMLET_CREATE__SUCCESS: 'HAMLET_CREATE__SUCCESS',
  HAMLET_CREATE__FAILURE: 'HAMLET_CREATE__FAILURE',
  HAMLET_CREATE_HANDLE: 'HAMLET_CREATE_HANDLE',
  HAMLET_FETCH: 'HAMLET_FETCH',
  HAMLET_FETCH__SUCCESS: 'HAMLET_FETCH__SUCCESS',
  HAMLET_FETCH__FAILURE: 'HAMLET_FETCH__FAILURE',
  HAMLET_UPDATE: 'HAMLET_UPDATE',
  HAMLET_UPDATE__SUCCESS: 'HAMLET_UPDATE__SUCCESS',
  HAMLET_UPDATE__FAILURE: 'HAMLET_UPDATE__FAILURE',
  HAMLET_UPDATE_HANDLE: 'HAMLET_UPDATE_HANDLE',
  HAMLET_DELETE: 'HAMLET_DELETE',
  HAMLET_DELETE__SUCCESS: 'HAMLET_DELETE__SUCCESS',
  HAMLET_DELETE__FAILURE: 'HAMLET_DELETE__FAILURE',
  HAMLET_DELETE_HANDLE: 'HAMLET_DELETE_HANDLE',

  /* Categories */

  CATEGORY_CREATE: 'CATEGORY_CREATE',
  CATEGORY_CREATE__SUCCESS: 'CATEGORY_CREATE__SUCCESS',
  CATEGORY_CREATE__FAILURE: 'CATEGORY_CREATE__FAILURE',
  CATEGORY_CREATE_HANDLE: 'CATEGORY_CREATE_HANDLE',
  CATEGORY_UPDATE: 'CATEGORY_UPDATE',
  CATEGORY_UPDATE__SUCCESS: 'CATEGORY_UPDATE__SUCCESS',
  CATEGORY_UPDATE__FAILURE: 'CATEGORY_UPDATE__FAILURE',
  CATEGORY_UPDATE_HANDLE: 'CATEGORY_UPDATE_HANDLE',
  CATEGORY_DELETE: 'CATEGORY_DELETE',
  CATEGORY_DELETE__SUCCESS: 'CATEGORY_DELETE__SUCCESS',
  CATEGORY_DELETE__FAILURE: 'CATEGORY_DELETE__FAILURE',
  CATEGORY_DELETE_HANDLE: 'CATEGORY_DELETE_HANDLE',

  /* Contents */

  CONTENT_CREATE: 'CONTENT_CREATE',
  CONTENT_CREATE__SUCCESS: 'CONTENT_CREATE__SUCCESS',
  CONTENT_CREATE__FAILURE: 'CONTENT_CREATE__FAILURE',
  CONTENT_CREATE_HANDLE: 'CONTENT_CREATE_HANDLE',
  CONTENTS_FETCH: 'CONTENTS_FETCH',
  CONTENTS_FETCH__SUCCESS: 'CONTENTS_FETCH__SUCCESS',
  CONTENTS_FETCH__FAILURE: 'CONTENTS_FETCH__FAILURE',
  CONTENTS_SEARCH: 'CONTENTS_SEARCH',
  CONTENTS_SEARCH__SUCCESS: 'CONTENTS_SEARCH__SUCCESS',
  CONTENTS_SEARCH__FAILURE: 'CONTENTS_SEARCH__FAILURE',
  CONTENT_SELECT: 'CONTENT_SELECT',
  CONTENT_UPDATE: 'CONTENT_UPDATE',
  CONTENT_UPDATE__SUCCESS: 'CONTENT_UPDATE__SUCCESS',
  CONTENT_UPDATE__FAILURE: 'CONTENT_UPDATE__FAILURE',
  CONTENT_UPDATE_HANDLE: 'CONTENT_UPDATE_HANDLE',
  CONTENT_DELETE: 'CONTENT_DELETE',
  CONTENT_DELETE__SUCCESS: 'CONTENT_DELETE__SUCCESS',
  CONTENT_DELETE__FAILURE: 'CONTENT_DELETE__FAILURE',
  CONTENT_DELETE_HANDLE: 'CONTENT_DELETE_HANDLE',
  CONTENT_TEXT_TO_SPEECH: 'CONTENT_TEXT_TO_SPEECH',
  CONTENT_TEXT_TO_SPEECH__SUCCESS: 'CONTENT_TEXT_TO_SPEECH__SUCCESS',
  CONTENT_TEXT_TO_SPEECH__FAILURE: 'CONTENT_TEXT_TO_SPEECH__FAILURE',
  CONTENT_TO_DEVICE_ADD: 'CONTENT_TO_DEVICE_ADD',
  CONTENT_TO_DEVICE_ADD__SUCCESS: 'CONTENT_TO_DEVICE_ADD__SUCCESS',
  CONTENT_TO_DEVICE_ADD__FAILURE: 'CONTENT_TO_DEVICE_ADD__FAILURE',
  CONTENT_TO_DEVICE_ADD_HANDLE: 'CONTENT_TO_DEVICE_ADD_HANDLE',
  CONTENT_FROM_DEVICE_REMOVE: 'CONTENT_FROM_DEVICE_REMOVE',
  CONTENT_FROM_DEVICE_REMOVE__SUCCESS: 'CONTENT_FROM_DEVICE_REMOVE__SUCCESS',
  CONTENT_FROM_DEVICE_REMOVE__FAILURE: 'CONTENT_FROM_DEVICE_REMOVE__FAILURE',
  CONTENT_FROM_DEVICE_REMOVE_HANDLE: 'CONTENT_FROM_DEVICE_REMOVE_HANDLE',
  CONTENT_TO_DISTRICT_FILTER_ADD: 'CONTENT_TO_DISTRICT_FILTER_ADD',
  CONTENT_FROM_DISTRICT_FILTER_REMOVE: 'CONTENT_FROM_DISTRICT_FILTER_REMOVE',
  CONTENT_ERROR_CLEAR: 'CONTENT_ERROR_CLEAR',
  CONTENT_PLAYLIST_CLEAR: 'CONTENT_PLAYLIST_CLEAR',
  SELECT_CONTENT_ID: 'SELECT_CONTENT_ID',
  CONTENT_CANCEL_SCHEDULE: 'CONTENT_CANCEL_SCHEDULE',
  CONTENT_CANCEL_SCHEDULE__SUCCESS: 'CONTENT_CANCEL_SCHEDULE__SUCCESS',
  CONTENT_CANCEL_SCHEDULE__FAILURE: 'CONTENT_CANCEL_SCHEDULE__FAILURE',
  CONTENT_CANCEL_SCHEDULE_HANDLE: 'CONTENT_CANCEL_SCHEDULE_HANDLE',
  CONTENT_FETCH_STATIONS_BROADCASTED: 'CONTENT_FETCH_STATIONS_BROADCASTED',
  CONTENT_FETCH_STATIONS_BROADCASTED__SUCCESS: 'CONTENT_FETCH_STATIONS_BROADCASTED__SUCCESS',
  CONTENT_FETCH_STATIONS_BROADCASTED__FAILURE: 'CONTENT_FETCH_STATIONS_BROADCASTED__FAILURE',
  CONTENT_CONCAT_FILE: 'CONTENT_CONCAT_FILE',
  CONTENT_CONCAT_FILE__SUCCESS: 'CONTENT_CONCAT_FILE__SUCCESS',
  CONTENT_CONCAT_FILE__FAILURE: 'CONTENT_CONCAT_FILE__FAILURE',
  CONTENT_CONCAT_FILE__CLEAR: 'CONTENT_CONCAT_FILE__CLEAR',
  ENABLE_CONTENTS_FETCHING: 'ENABLE_CONTENTS_FETCHING',

  /* Content Category */

  CONTENT_CATEGORY_CREATE_HANDLE: 'CONTENT_CATEGORY_CREATE_HANDLE',
  CONTENT_CATEGORY_UPDATE_HANDLE: 'CONTENT_CATEGORY_UPDATE_HANDLE',
  CONTENT_CATEGORY_DELETE_HANDLE: 'CONTENT_CATEGORY_DELETE_HANDLE',

  /* Content Location */

  CONTENT_LOCATION_CREATE_HANDLE: 'CONTENT_LOCATION_CREATE_HANDLE',
  CONTENT_LOCATION_DELETE_HANDLE: 'CONTENT_LOCATION_DELETE_HANDLE',
  CONTENT_LOCATION_UPDATE_HANDLE: 'CONTENT_LOCATION_UPDATE_HANDLE',

  /* Schedules */

  SCHEDULE_CREATE: 'SCHEDULE_CREATE',
  SCHEDULE_CREATE__SUCCESS: 'SCHEDULE_CREATE__SUCCESS',
  SCHEDULE_CREATE__FAILURE: 'SCHEDULE_CREATE__FAILURE',
  SCHEDULE_CREATE_HANDLE: 'SCHEDULE_CREATE_HANDLE',
  SCHEDULES_FETCH: 'SCHEDULES_FETCH',
  SCHEDULES_FETCH__SUCCESS: 'SCHEDULES_FETCH__SUCCESS',
  SCHEDULES_FETCH__FAILURE: 'SCHEDULES_FETCH__FAILURE',
  SCHEDULE_UPDATE: 'SCHEDULE_UPDATE',
  SCHEDULE_UPDATE__SUCCESS: 'SCHEDULE_UPDATE__SUCCESS',
  SCHEDULE_UPDATE__FAILURE: 'SCHEDULE_UPDATE__FAILURE',
  SCHEDULE_UPDATE_HANDLE: 'SCHEDULE_UPDATE_HANDLE',
  SCHEDULE_DELETE: 'SCHEDULE_DELETE',
  SCHEDULE_DELETE__SUCCESS: 'SCHEDULE_DELETE__SUCCESS',
  SCHEDULE_DELETE__FAILURE: 'SCHEDULE_DELETE__FAILURE',
  SCHEDULE_DELETE_HANDLE: 'SCHEDULE_DELETE_HANDLE',
  SCHEDULE_TO_DEVICE_ADD: 'SCHEDULE_TO_DEVICE_ADD',
  SCHEDULE_TO_DEVICE_ADD__SUCCESS: 'SCHEDULE_TO_DEVICE_ADD__SUCCESS',
  SCHEDULE_TO_DEVICE_ADD__FAILURE: 'SCHEDULE_TO_DEVICE_ADD__FAILURE',
  SCHEDULE_TO_DEVICE_ADD_HANDLE: 'SCHEDULE_TO_DEVICE_ADD_HANDLE',
  SCHEDULE_FROM_DEVICE_REMOVE: 'SCHEDULE_FROM_DEVICE_REMOVE',
  SCHEDULE_FROM_DEVICE_REMOVE__SUCCESS: 'SCHEDULE_FROM_DEVICE_REMOVE__SUCCESS',
  SCHEDULE_FROM_DEVICE_REMOVE__FAILURE: 'SCHEDULE_FROM_DEVICE_REMOVE__FAILURE',
  SCHEDULE_FROM_DEVICE_REMOVE_HANDLE: 'SCHEDULE_FROM_DEVICE_REMOVE_HANDLE',
  SCHEDULE_TO_DISTRICT_FILTER_ADD: 'SCHEDULE_TO_DISTRICT_FILTER_ADD',
  SCHEDULE_FROM_DISTRICT_FILTER_REMOVE: 'SCHEDULE_FROM_DISTRICT_FILTER_REMOVE',
  SCHEDULE_ERROR_CLEAR: 'SCHEDULE_ERROR_CLEAR',

  /* Stations */

  STATION_CREATE: 'STATION_CREATE',
  STATION_CREATE__SUCCESS: 'STATION_CREATE__SUCCESS',
  STATION_CREATE__FAILURE: 'STATION_CREATE__FAILURE',
  STATION_CREATE_HANDLE: 'STATION_CREATE_HANDLE',
  STATIONS_FETCH: 'STATIONS_FETCH',
  STATIONS_FETCH__SUCCESS: 'STATIONS_FETCH__SUCCESS',
  STATIONS_FETCH__FAILURE: 'STATIONS_FETCH__FAILURE',
  STATION_UPDATE: 'STATION_UPDATE',
  STATION_UPDATE__SUCCESS: 'STATION_UPDATE__SUCCESS',
  STATION_UPDATE__FAILURE: 'STATION_UPDATE__FAILURE',
  STATION_UPDATE_HANDLE: 'STATION_UPDATE_HANDLE',
  STATION_DELETE: 'STATION_DELETE',
  STATION_DELETE__SUCCESS: 'STATION_DELETE__SUCCESS',
  STATION_DELETE__FAILURE: 'STATION_DELETE__FAILURE',
  STATION_DELETE_HANDLE: 'STATION_DELETE_HANDLE',
  STATION_ERROR_CLEAR: 'STATION_ERROR_CLEAR',

  /* Devices */

  DEVICE_CREATE: 'DEVICE_CREATE',
  DEVICE_CREATE__SUCCESS: 'DEVICE_CREATE__SUCCESS',
  DEVICE_CREATE__FAILURE: 'DEVICE_CREATE__FAILURE',
  DEVICE_CREATE_HANDLE: 'DEVICE_CREATE_HANDLE',
  DEVICES_FETCH: 'DEVICES_FETCH',
  DEVICES_FETCH__SUCCESS: 'DEVICES_FETCH__SUCCESS',
  DEVICES_FETCH__FAILURE: 'DEVICES_FETCH__FAILURE',
  DEVICE_FETCH: 'DEVICE_FETCH',
  DEVICE_FETCH__SUCCESS: 'DEVICE_FETCH__SUCCESS',
  DEVICE_FETCH__FAILURE: 'DEVICE_FETCH__FAILURE',
  DEVICE_UPDATE: 'DEVICE_UPDATE',
  DEVICE_UPDATE__SUCCESS: 'DEVICE_UPDATE__SUCCESS',
  DEVICE_UPDATE__FAILURE: 'DEVICE_UPDATE__FAILURE',
  DEVICE_UPDATE_HANDLE: 'DEVICE_UPDATE_HANDLE',
  DEVICE_TRANSFER: 'DEVICE_TRANSFER',
  DEVICE_TRANSFER__SUCCESS: 'DEVICE_TRANSFER__SUCCESS',
  DEVICE_TRANSFER__FAILURE: 'DEVICE_TRANSFER__FAILURE',
  DEVICE_DELETE: 'DEVICE_DELETE',
  DEVICE_DELETE__SUCCESS: 'DEVICE_DELETE__SUCCESS',
  DEVICE_DELETE__FAILURE: 'DEVICE_DELETE__FAILURE',
  DEVICE_CONTROL: 'DEVICE_CONTROL',
  DEVICE_CONTROL__SUCCESS: 'DEVICE_CONTROL__SUCCESS',
  DEVICE_CONTROL__FAILURE: 'DEVICE_CONTROL__FAILURE',
  DEVICE_DELETE_HANDLE: 'DEVICE_DELETE_HANDLE',

  /* Tasks */

  TASK_CREATE: 'TASK_CREATE',
  TASK_CREATE__SUCCESS: 'TASK_CREATE__SUCCESS',
  TASK_CREATE__FAILURE: 'TASK_CREATE__FAILURE',
  TASK_CREATE_HANDLE: 'TASK_CREATE_HANDLE',
  TASK_UPDATE: 'TASK_UPDATE',
  TASK_UPDATE__SUCCESS: 'TASK_UPDATE__SUCCESS',
  TASK_UPDATE__FAILURE: 'TASK_UPDATE__FAILURE',
  TASK_UPDATE_HANDLE: 'TASK_UPDATE_HANDLE',
  TASK_DELETE: 'TASK_DELETE',
  TASK_DELETE__SUCCESS: 'TASK_DELETE__SUCCESS',
  TASK_DELETE__FAILURE: 'TASK_DELETE__FAILURE',
  TASK_DELETE_HANDLE: 'TASK_DELETE_HANDLE',

  /* Attachments */

  ATTACHMENT_CREATE: 'ATTACHMENT_CREATE',
  ATTACHMENT_CREATE__SUCCESS: 'ATTACHMENT_CREATE__SUCCESS',
  ATTACHMENT_CREATE__FAILURE: 'ATTACHMENT_CREATE__FAILURE',
  ATTACHMENT_CREATE_HANDLE: 'ATTACHMENT_CREATE_HANDLE',
  ATTACHMENT_UPDATE: 'ATTACHMENT_UPDATE',
  ATTACHMENT_UPDATE__SUCCESS: 'ATTACHMENT_UPDATE__SUCCESS',
  ATTACHMENT_UPDATE__FAILURE: 'ATTACHMENT_UPDATE__FAILURE',
  ATTACHMENT_UPDATE_HANDLE: 'ATTACHMENT_UPDATE_HANDLE',
  ATTACHMENT_DELETE: 'ATTACHMENT_DELETE',
  ATTACHMENT_DELETE__SUCCESS: 'ATTACHMENT_DELETE__SUCCESS',
  ATTACHMENT_DELETE__FAILURE: 'ATTACHMENT_DELETE__FAILURE',
  ATTACHMENT_DELETE_HANDLE: 'ATTACHMENT_DELETE_HANDLE',

  /* Activities */

  ACTIVITIES_FETCH: 'ACTIVITIES_FETCH',
  ACTIVITIES_FETCH__SUCCESS: 'ACTIVITIES_FETCH__SUCCESS',
  ACTIVITIES_FETCH__FAILURE: 'ACTIVITIES_FETCH__FAILURE',
  ACTIVITIES_DETAILS_TOGGLE: 'ACTIVITIES_DETAILS_TOGGLE',
  ACTIVITIES_DETAILS_TOGGLE__SUCCESS: 'ACTIVITIES_DETAILS_TOGGLE__SUCCESS',
  ACTIVITIES_DETAILS_TOGGLE__FAILURE: 'ACTIVITIES_DETAILS_TOGGLE__FAILURE',
  ACTIVITY_CREATE_HANDLE: 'ACTIVITY_CREATE_HANDLE',
  ACTIVITY_UPDATE_HANDLE: 'ACTIVITY_UPDATE_HANDLE',
  ACTIVITY_DELETE_HANDLE: 'ACTIVITY_DELETE_HANDLE',

  /* Comment activities */

  COMMENT_ACTIVITY_CREATE: 'COMMENT_ACTIVITY_CREATE',
  COMMENT_ACTIVITY_CREATE__SUCCESS: 'COMMENT_ACTIVITY_CREATE__SUCCESS',
  COMMENT_ACTIVITY_CREATE__FAILURE: 'COMMENT_ACTIVITY_CREATE__FAILURE',
  COMMENT_ACTIVITY_UPDATE: 'COMMENT_ACTIVITY_UPDATE',
  COMMENT_ACTIVITY_UPDATE__SUCCESS: 'COMMENT_ACTIVITY_UPDATE__SUCCESS',
  COMMENT_ACTIVITY_UPDATE__FAILURE: 'COMMENT_ACTIVITY_UPDATE__FAILURE',
  COMMENT_ACTIVITY_DELETE: 'COMMENT_ACTIVITY_DELETE',
  COMMENT_ACTIVITY_DELETE__SUCCESS: 'COMMENT_ACTIVITY_DELETE__SUCCESS',
  COMMENT_ACTIVITY_DELETE__FAILURE: 'COMMENT_ACTIVITY_DELETE__FAILURE',

  /* Notifications */

  NOTIFICATION_CREATE_HANDLE: 'NOTIFICATION_CREATE_HANDLE',
  NOTIFICATION_UPDATE: 'NOTIFICATION_UPDATE',
  NOTIFICATION_UPDATE__SUCCESS: 'NOTIFICATION_UPDATE__SUCCESS',
  NOTIFICATION_UPDATE__FAILURE: 'NOTIFICATION_UPDATE__FAILURE',
  NOTIFICATION_DELETE: 'NOTIFICATION_DELETE',
  NOTIFICATION_DELETE__SUCCESS: 'NOTIFICATION_DELETE__SUCCESS',
  NOTIFICATION_DELETE__FAILURE: 'NOTIFICATION_DELETE__FAILURE',
  NOTIFICATION_DELETE_HANDLE: 'NOTIFICATION_DELETE_HANDLE',
  NOTIFICATION_FETCH__SUCCESS: 'NOTIFICATION_FETCH__SUCCESS',

  /* Live broadcast */
  LIVE_BROADCAST_ERROR_HANDLE: 'LIVE_BROADCAST_ERROR_HANDLE',
  LIVE_BROADCAST_FINISHED_HANDLE: 'LIVE_BROADCAST_FINISHED_HANDLE',
  LIVE_BROADCAST_REQUEST: 'LIVE_BROADCAST_REQUEST',
  LIVE_BROADCAST_START: 'LIVE_BROADCAST_START',
  LIVE_BROADCAST_STOP: 'LIVE_BROADCAST_STOP',
  LIVE_BROADCAST_ERROR: 'LIVE_BROADCAST_ERROR',
  LIVE_BROADCAST_CLEAR: 'LIVE_BROADCAST_CLEAR',
  LIVE_BROADCAST_MESSAGE_CLOSE: 'LIVE_BROADCAST_MESSAGE_CLOSE',
  OVERRIDE_CONTENT_BY_MICROPHONE: 'OVERRIDE_CONTENT_BY_MICROPHONE',

  /* Reports */

  REPORTS_FETCH: 'REPORTS_FETCH',
  REPORTS_FETCH__SUCCESS: 'REPORTS_FETCH__SUCCESS',
  REPORTS_FETCH__FAILURE: 'REPORTS_FETCH__FAILURE',
  REPORTS_EXPORT: 'REPORTS_EXPORT',
  REPORTS_EXPORT__SUCCESS: 'REPORTS_EXPORT__SUCCESS',
  REPORTS_EXPORT__FAILURE: 'REPORTS_EXPORT__FAILURE',
  REPORTS_EXPORT_CLEAR: 'REPORTS_EXPORT_CLEAR',

  /* Actions */

  ACTIONS_FETCH: 'ACTIONS_FETCH',
  ACTIONS_FETCH__SUCCESS: 'ACTIONS_FETCH__SUCCESS',
  ACTIONS_FETCH__FAILURE: 'ACTIONS_FETCH__FAILURE',
};
