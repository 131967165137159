import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import UserLogModal from '../components/UserLogModal';

const mapStateToProps = (state, { selectedId }) => {
  const action = selectors.selectActionById(state, selectedId);

  return { action };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserLogModal);
