import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Sidebar from '../components/Sidebar';

const mapStateToProps = (state) => {
  const sidebarMenu = selectors.selectSidebarMenu(state);
  const isDarkMode = selectors.selectIsDarkMode(state);
  const provinces = selectors.selectProvincesForCurrentUser(state);
  const province = provinces.length > 0 ? provinces[0] : {};
  const { name, avatarUrl, id, isAdmin } = selectors.selectCurrentUser(state);
  const {
    pattern: { path },
  } = selectors.selectPathsMatch(state);

  return {
    sidebarMenu,
    isDarkMode,
    province,
    user: { name, avatarUrl, id, isAdmin },
    path,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSidebarMenu: entryActions.changeSidebarMenu,
      onLogout: entryActions.logout,
      onRefetchContents: entryActions.enalbleContentsFetching,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
