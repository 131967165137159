import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';
import { Icon, Menu, Modal } from 'semantic-ui-react';
import { usePopup } from '../../lib/popup';

import Paths from '../../constants/Paths';
import NotificationsStep from './NotificationsStep';
// import UserStep from '../UserStep';
import DateTimeDisplay from './DateTimeDisplay';

import styles from './Header.module.scss';
import popupStyles from '../../lib/popup/Popup.module.css';

const POPUP_PROPS = {
  position: 'bottom right',
  className: classNames(popupStyles.wrapper, styles.wrapperNotification),
};

const Titles = {
  [Paths.CONTENTS]: 'menu.content',
  [Paths.ELECTRONIC_CONTENTS]: 'menu.electronicContent',
  [Paths.SCHEDULES]: 'menu.content',
  [Paths.DEVICES]: 'menu.speaker',
  [Paths.DISPLAYS]: 'menu.display',
  [Paths.STATIONS]: 'menu.station',
  [Paths.REPORTS]: 'menu.report',
  [Paths.SETTINGS]: 'menu.setting',
  [Paths.SETTING_USERS]: 'menu.accountSettings',
  [Paths.DISTRICTS]: 'menu.manageZone',
  [Paths.SETTING_PROVINCE_CATEGOGY]: 'menu.installDistrictCommuneDirectory',
  [Paths.SETTING_USER_GROUPS]: 'menu.userGroupSettings',
  [Paths.USER_LOG]: 'menu.userLog',
};

const LIMIT_NOTIFICATION = 20;

const Header = React.memo(
  ({
    province,
    user,
    notifications,
    unreadTotal,
    notificationTotal,
    // isLogouting,
    isDarkMode,
    path,
    // canEditProvince,
    // canEditUsers,
    location,
    // onProvinceSettingsClick,
    // onUsersClick,
    onNotificationUpdate,
    onNotificationDelete,
    // onUserSettingsClick,
    // onLogout,
    // onSidebarMenu,
    onFetchNotifications,
  }) => {
    const [t] = useTranslation();

    const [isOpenNoticeMobile, setOpenNoticeMobile] = useState(false);

    // const handleProvinceSettingsClick = useCallback(() => {
    //   if (canEditProvince) {
    //     onProvinceSettingsClick();
    //   }
    // }, [canEditProvince, onProvinceSettingsClick]);

    const handleTriggerNoticeMobile = useCallback(() => {
      setOpenNoticeMobile((prev) => !prev);
    }, []);

    const NotificationsPopup = usePopup(NotificationsStep, POPUP_PROPS);
    // const UserPopup = usePopup(UserStep, POPUP_PROPS);

    const title = t(Titles[path]);

    return (
      <div className={styles.wrapper}>
        <Menu inverted={isDarkMode} size="large" className={styles.menu}>
          {/* <Menu.Item as="a" onClick={() => onSidebarMenu()}>
            <Icon name="bars" />
          </Menu.Item> */}
          {/* {province && (
            <Menu.Menu position="left">
              <Menu.Item
                as={Link}
                to={Paths.ROOT}
                className={classNames(styles.item, styles.itemHoverable)}
              >
                <Icon fitted name="arrow left" />
              </Menu.Item>
              <Menu.Item
                className={classNames(
                  styles.item,
                  canEditProvince && styles.itemHoverable,
                  styles.title,
                )}
                // onClick={handleProvinceSettingsClick}
              >
                {province.name}
              </Menu.Item>
            </Menu.Menu>
          )} */}
          <Menu.Menu position="left">
            <Menu.Item className={classNames(styles.title)}>{title}</Menu.Item>
          </Menu.Menu>
          <Menu.Item className={styles.time}>
            <DateTimeDisplay />
          </Menu.Item>
          <Menu.Menu position="right">
            {/* {canEditUsers && (
              <Menu.Item
                className={classNames(styles.item, styles.itemHoverable)}
                onClick={onUsersClick}
              >
                <Icon fitted name="users" />
              </Menu.Item>
            )} */}
            <NotificationsPopup
              total={notificationTotal}
              items={notifications}
              level={user.level}
              location={location}
              type={province.notificationType}
              onUpdate={onNotificationUpdate}
              onDelete={onNotificationDelete}
              onFetch={onFetchNotifications}
            >
              <Menu.Item
                className={classNames(styles.item, styles.itemHoverable)}
                onClick={() => setOpenNoticeMobile(true)}
              >
                <Icon fitted name="bell" />
                {!!unreadTotal && (
                  <span className={styles.notification}>
                    {unreadTotal > LIMIT_NOTIFICATION ? `${LIMIT_NOTIFICATION}+` : unreadTotal}
                  </span>
                )}
                <button
                  type="button"
                  label="button"
                  className={classNames(styles.triggerMobile)}
                  onClick={handleTriggerNoticeMobile}
                />
              </Menu.Item>
            </NotificationsPopup>
            {/* <UserPopup
              isLogouting={isLogouting}
              onSettingsClick={onUserSettingsClick}
              onLogout={onLogout}
            >
              <Menu.Item className={classNames(styles.item, styles.itemHoverable)}>
                {user.name}
              </Menu.Item>
            </UserPopup> */}
          </Menu.Menu>
        </Menu>
        <Modal
          open={isOpenNoticeMobile}
          closeIcon
          centered={false}
          onClose={handleTriggerNoticeMobile}
          className={styles.wrapperNotificationMobile}
          content={
            <NotificationsStep
              total={notificationTotal}
              items={notifications}
              level={user.level}
              location={location}
              type={province.notificationType}
              onUpdate={onNotificationUpdate}
              onDelete={onNotificationDelete}
              onFetch={onFetchNotifications}
              onClose={() => {}}
            />
          }
        />
      </div>
    );
  },
);

Header.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  province: PropTypes.object,
  user: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  unreadTotal: PropTypes.number.isRequired,
  notificationTotal: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
  isDarkMode: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  // isLogouting: PropTypes.bool.isRequired,
  // canEditProvince: PropTypes.bool.isRequired,
  // canEditUsers: PropTypes.bool.isRequired,
  // onProvinceSettingsClick: PropTypes.func.isRequired,
  // onUsersClick: PropTypes.func.isRequired,
  onNotificationUpdate: PropTypes.func.isRequired,
  onNotificationDelete: PropTypes.func.isRequired,
  // onUserSettingsClick: PropTypes.func.isRequired,
  // onLogout: PropTypes.func.isRequired,
  // onSidebarMenu: PropTypes.func.isRequired,
  onFetchNotifications: PropTypes.func.isRequired,
};

Header.defaultProps = {
  province: undefined,
};

export default Header;
