import router from './router';
import socket from './socket';
import core from './core';
import modals from './modals';
import users from './users';
import provinces from './provinces';
import provinceManagers from './province-managers';
import districts from './districts';
import districtMemberships from './district-memberships';
import categories from './categories';
import contents from './contents';
import schedules from './schedules';
import stations from './stations';
import devices from './devices';
import tasks from './tasks';
import attachments from './attachments';
import wards from './wards';
import wardMemberships from './ward-memberships';
import userGroups from './user-groups';
import actions from './actions';

export default {
  ...router,
  ...socket,
  ...core,
  ...modals,
  ...users,
  ...provinces,
  ...provinceManagers,
  ...districts,
  ...districtMemberships,
  ...categories,
  ...contents,
  ...schedules,
  ...stations,
  ...devices,
  ...tasks,
  ...attachments,
  ...wards,
  ...wardMemberships,
  ...userGroups,
  ...actions,
};
