import socket from './socket';
import http from './http';
import { transformDevice } from './devices';
import { transformAttachment } from './attachments';

/* Actions */

const createWard = (districtId, data, headers) =>
  socket.post(`/districts/${districtId}/wards`, data, headers);

const createWardWithImport = (districtId, data, requestId, headers) =>
  http.post(`/districts/${districtId}/wards?requestId=${requestId}`, data, headers);

const getWard = (id, subscribe, headers) =>
  socket
    .get(`/wards/${id}${subscribe ? '?subscribe=true' : ''}`, undefined, headers)
    .then((body) => ({
      ...body,
      included: {
        ...body.included,
        devices: body.included.devices.map(transformDevice),
        attachments: body.included.attachments.map(transformAttachment),
      },
    }));

const updateWard = (id, data, headers) => socket.patch(`/wards/${id}`, data, headers);

const deleteWard = (id, headers) => socket.delete(`/wards/${id}`, undefined, headers);

export default {
  createWard,
  createWardWithImport,
  getWard,
  updateWard,
  deleteWard,
};
