import { all, takeEvery } from 'redux-saga/effects';

import EntryActionTypes from '../../../constants/EntryActionTypes';
import services from '../services';

export default function* reportsWarchers() {
  yield all([
    takeEvery(EntryActionTypes.ACTIONS_FETCH, ({ payload: { filter, limit, page } }) =>
      services.fetchActions(filter, limit, page),
    ),
  ]);
}
