export default {
  translation: {
    common: {
      anErrorOccuredDuringVerification: 'Đã xảy ra lỗi trong quá trình xác minh, hãy thử lại',
      codeNotCorrect: 'Mã xác thực chưa chính xác, hãy thử lại',
      emailOrUsername: 'E-mail hoặc username',
      enterOtpCode: 'Nhập mã OTP',
      occuredErrorWhenRetrieveQRCodeTryLater: 'Xảy ra lỗi lấy lại mã QR, hãy thử lại sau',
      informationIsInCorrect: 'Thông tin chưa chính xác, hãy thử lại',
      youDidNotReceiveVerificationCode: 'Bạn không nhận được mã xác thực?',
      codeIsExpired: 'Mã xác thực đã hết hạn. Nhấn nút "Lấy lại mã" để tạo mã mới.',
      invalidEmailOrUsername: 'Lỗi e-mail hoặc tên đăng nhập',
      invalidPassword: 'Sai password',
      logInToWowSpeaker: 'Đăng nhập',
      noInternetConnection: 'Không có kết nối internet',
      pageNotFound_title: 'Không tìm thấy',
      password: 'Mật khẩu',
      provinceManagement: 'Giải pháp truyền thanh thông minh ',
      qrCode: 'Mã QR',
      retrieveTheCode: 'Lấy lại mã',
      scanQrcodeBelowToGetTheLoginCodeByUsingGoogleAuthenticator:
        '<0>Quét mã QR bên dưới để nhận mã đăng nhập.</0><1 /><2>(Sử dụng ứng dụng <3>Google Authenticator</3>)</2>',
      sendEmailFailed: 'Xảy ra lỗi khi gửi đến email, hãy thử lại',
      serverConnectionFailed: 'Mất kết nối',
      unknownError: 'Đã xảy ra lỗi, hãy thử lại',
      verifyInformation: 'Xác thực thông tin',
      varifycationCodes: 'Mã xác thực',
      sentEmailSuccessfully: 'Đã gởi mã QR đến email của bạn.',
      toLoginEnterTheCodeSuppliedToYourEmailDddress:
        '(Nhập mã đã được gửi vào email của bạn để thực hiện đăng nhập)',
    },

    action: {
      logIn: 'Đăng nhập',
      send: 'Gửi',
      retrieveQRCode: 'Lấy lại mã QR',
      retrieveCode: 'Lấy lại mã',
    },
  },
};
