/* Actions */

import { transformActivity } from './activities';
import socket from './socket';

const getActions = (provinceId, data, headers) =>
  socket.get(`/provinces/${provinceId}/actions`, data, headers).then((body) => ({
    ...body,
    items: body.items.map(transformActivity),
  }));

export default { getActions };
