import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* schedulesWatchers() {
  yield all([
    takeEvery(EntryActionTypes.SCHEDULE_IN_CURRENT_DISTRICT_CREATE, ({ payload: { data } }) =>
      services.createScheduleInCurrentDistrict(data),
    ),
    takeEvery(EntryActionTypes.SCHEDULE_IN_CURRENT_PROVINCE_CREATE, ({ payload: { data } }) =>
      services.createScheduleInCurrentProvince(data),
    ),
    takeEvery(EntryActionTypes.SCHEDULE_CREATE_HANDLE, ({ payload: { schedule } }) =>
      services.handleScheduleCreate(schedule),
    ),
    takeEvery(EntryActionTypes.SCHEDULES_FETCH, ({ payload: { data } }) =>
      services.fetchSchedules(data),
    ),
    takeEvery(EntryActionTypes.SCHEDULE_UPDATE, ({ payload: { id, data } }) =>
      services.updateSchedule(id, data),
    ),
    takeEvery(EntryActionTypes.SCHEDULE_UPDATE_HANDLE, ({ payload: { schedule } }) =>
      services.handleScheduleUpdate(schedule),
    ),
    takeEvery(EntryActionTypes.SCHEDULE_MOVE, ({ payload: { id, index } }) =>
      services.moveSchedule(id, index),
    ),
    takeEvery(EntryActionTypes.SCHEDULE_DELETE, ({ payload: { id } }) =>
      services.deleteSchedule(id),
    ),
    takeEvery(EntryActionTypes.SCHEDULE_DELETE_HANDLE, ({ payload: { schedule } }) =>
      services.handleScheduleDelete(schedule),
    ),
    takeEvery(EntryActionTypes.SCHEDULE_TO_DEVICE_ADD, ({ payload: { id, deviceId } }) =>
      services.addScheduleToDevice(id, deviceId),
    ),
    takeEvery(EntryActionTypes.SCHEDULE_TO_CURRENT_DEVICE_ADD, ({ payload: { id } }) =>
      services.addScheduleToCurrentDevice(id),
    ),
    takeEvery(EntryActionTypes.SCHEDULE_TO_DEVICE_ADD_HANDLE, ({ payload: { deviceSchedule } }) =>
      services.handleScheduleToDeviceAdd(deviceSchedule),
    ),
    takeEvery(EntryActionTypes.SCHEDULE_FROM_DEVICE_REMOVE, ({ payload: { id, deviceId } }) =>
      services.removeScheduleFromDevice(id, deviceId),
    ),
    takeEvery(EntryActionTypes.SCHEDULE_FROM_CURRENT_DEVICE_REMOVE, ({ payload: { id } }) =>
      services.removeScheduleFromCurrentDevice(id),
    ),
    takeEvery(
      EntryActionTypes.SCHEDULE_FROM_DEVICE_REMOVE_HANDLE,
      ({ payload: { deviceSchedule } }) => services.handleScheduleFromDeviceRemove(deviceSchedule),
    ),
    takeEvery(EntryActionTypes.SCHEDULE_TO_FILTER_IN_CURRENT_DISTRICT_ADD, ({ payload: { id } }) =>
      services.addScheduleToFilterInCurrentDistrict(id),
    ),
    takeEvery(
      EntryActionTypes.SCHEDULE_FROM_FILTER_IN_CURRENT_DISTRICT_REMOVE,
      ({ payload: { id } }) => services.removeScheduleFromFilterInCurrentDistrict(id),
    ),
    takeEvery(EntryActionTypes.SCHEDULE_ERROR_CLEAR, () => services.clearScheduleError()),
  ]);
}
