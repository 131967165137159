import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './SettingProvinceCategory.module.scss';
import TableSelect from './TableSelect';
import { USER_PERMISSIONS } from '../../constants/Enums';

const SettingProvinceCategory = React.memo(
  ({
    permission,
    name,
    provinceId,
    code,
    districts,
    wards,
    hamlets,
    onCreateDistrict,
    onCreateWard,
    onUpdateDistrict,
    onUpdateWard,
    onDeleteDistrict,
    onDeleteWard,
    onCreateHamlet,
    onUpdateHamlet,
    onDeleteHamlet,
  }) => {
    const [t] = useTranslation();

    const [selectedId, setSelectedId] = useState();
    const [currentWardId, setCurrentWardId] = useState();

    const handleSelectDistrict = useCallback((_, { value }) => {
      setSelectedId((prev) => (prev === value ? null : value));
      setCurrentWardId();
    }, []);

    const handleSelectWard = useCallback((_, { value }) => {
      setCurrentWardId((prev) => (prev === value ? null : value));
    }, []);

    const districtDetail = useMemo(() => {
      return districts.find(({ id }) => id === selectedId) || { id: null };
    }, [selectedId, districts]);

    const wardDetail = useMemo(() => {
      return wards.find(({ id }) => id === currentWardId) || { id: null };
    }, [currentWardId, wards]);

    return (
      <div className={styles.wrapper}>
        <h2>Đơn vị hành chính tỉnh {name}</h2>
        <div className={styles.content}>
          <TableSelect
            items={districts}
            canAdd={permission.district.includes(USER_PERMISSIONS.CAN_ADD)}
            canEdit={permission.district.includes(USER_PERMISSIONS.CAN_EDIT)}
            id={provinceId}
            parentCode={code}
            name="DISTRICT"
            onChange={handleSelectDistrict}
            currentId={selectedId}
            onCreate={onCreateDistrict}
            onUpdate={onUpdateDistrict}
            onDelete={onDeleteDistrict}
          />
          <TableSelect
            items={wards.filter(({ districtId }) => districtId === selectedId)}
            canAdd={permission.ward.includes(USER_PERMISSIONS.CAN_ADD)}
            canEdit={permission.ward.includes(USER_PERMISSIONS.CAN_EDIT)}
            id={districtDetail.id}
            parentCode={districtDetail.code}
            name="WARD"
            onChange={handleSelectWard}
            currentId={currentWardId}
            onCreate={onCreateWard}
            onUpdate={onUpdateWard}
            onDelete={onDeleteWard}
          />
          <TableSelect
            items={hamlets.filter(({ wardId }) => wardId === currentWardId)}
            canAdd={permission.hamlet.includes(USER_PERMISSIONS.CAN_ADD)}
            canEdit={permission.hamlet.includes(USER_PERMISSIONS.CAN_EDIT)}
            id={wardDetail.id}
            parentCode={wardDetail.code}
            name="HAMLET"
            onCreate={onCreateHamlet}
            onUpdate={onUpdateHamlet}
            onDelete={onDeleteHamlet}
          />
        </div>
      </div>
    );
  },
);

export default SettingProvinceCategory;

SettingProvinceCategory.propTypes = {
  code: PropTypes.string.isRequired,
  permission: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  provinceId: PropTypes.string.isRequired,
  districts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  wards: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  hamlets: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onCreateDistrict: PropTypes.func.isRequired,
  onCreateWard: PropTypes.func.isRequired,
  onUpdateDistrict: PropTypes.func.isRequired,
  onUpdateWard: PropTypes.func.isRequired,
  onDeleteDistrict: PropTypes.func.isRequired,
  onDeleteWard: PropTypes.func.isRequired,
  onCreateHamlet: PropTypes.func.isRequired,
  onUpdateHamlet: PropTypes.func.isRequired,
  onDeleteHamlet: PropTypes.func.isRequired,
};

SettingProvinceCategory.defaultProps = {};
