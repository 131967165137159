import { attr, fk } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

export default class extends BaseModel {
  static modelName = 'WardMembership';

  static fields = {
    id: attr(),
    role: attr(),
    canComment: attr(),
    wardId: fk({
      to: 'Ward',
      as: 'ward',
      relatedName: 'memberships',
    }),
    userId: fk({
      to: 'User',
      as: 'user',
      relatedName: 'wardMemberships',
    }),
  };

  static reducer({ type, payload }, WardMembership) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE:
        if (payload.wardMemberships) {
          payload.wardMemberships.forEach((wardMembership) => {
            WardMembership.upsert(wardMembership);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        WardMembership.all().delete();
        if (payload.wardMemberships) {
          payload.wardMemberships.forEach((wardMembership) => {
            WardMembership.upsert(wardMembership);
          });
        }
        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROVINCE_CREATE_HANDLE:
      case ActionTypes.WARD_CREATE__SUCCESS:
      case ActionTypes.WARD_FETCH__SUCCESS:
        if (payload.wardMemberships) {
          payload.wardMemberships.forEach((wardMembership) => {
            WardMembership.upsert(wardMembership);
          });
        }

        break;
      case ActionTypes.WARD_MEMBERSHIP_CREATE:
        WardMembership.upsert(payload.wardMembership);

        break;
      case ActionTypes.WARD_MEMBERSHIP_CREATE__SUCCESS:
        WardMembership.withId(payload.localId).delete();
        WardMembership.upsert(payload.wardMembership);

        break;
      case ActionTypes.WARD_MEMBERSHIP_CREATE_HANDLE:
        WardMembership.upsert(payload.wardMembership);

        if (payload.wardMemberships) {
          payload.wardMemberships.forEach((wardMembership) => {
            WardMembership.upsert(wardMembership);
          });
        }

        break;
      case ActionTypes.WARD_MEMBERSHIP_UPDATE:
        WardMembership.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.WARD_MEMBERSHIP_UPDATE__SUCCESS:
      case ActionTypes.WARD_MEMBERSHIP_UPDATE_HANDLE:
        WardMembership.upsert(payload.wardMembership);

        break;
      case ActionTypes.WARD_MEMBERSHIP_DELETE:
        WardMembership.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.WARD_MEMBERSHIP_DELETE__SUCCESS:
      case ActionTypes.WARD_MEMBERSHIP_DELETE_HANDLE: {
        const wardMembershipModel = WardMembership.withId(payload.wardMembership.id);

        if (wardMembershipModel) {
          wardMembershipModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.USER_CREATE__SUCCESS:
      case ActionTypes.USER_PERMISSION_UPDATE__SUCCESS:
        if (payload.wardMemberships) {
          payload.wardMemberships.forEach((member) => {
            WardMembership.upsert(member);
          });
        }

        break;

      default:
    }
  }

  // deleteRelated() {
  //   this.wards.devices.toModelArray().forEach((deviceModel) => {
  //     try {
  //       deviceModel.users.remove(this.userId);
  //     } catch {} // eslint-disable-line no-empty
  //   });
  //   try {
  //     this.district.filterUsers.remove(this.userId);
  //   } catch {} // eslint-disable-line no-empty
  // }

  deleteWithRelated() {
    // this.deleteRelated();
    this.delete();
  }
}
