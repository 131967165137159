import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import UserSettings from '../components/UserSettings';

const mapStateToProps = (state) => {
  const isDarkMode = selectors.selectIsDarkMode(state);
  const {
    avatarUrl,
    email,
    emailUpdateForm,
    isAdmin,
    name,
    organization,
    passwordUpdateForm,
    usernameUpdateForm,
    phone,
    username,
    permissions,
    id,
  } = selectors.selectCurrentUser(state);
  const currentUser = selectors.selectCurrentUser(state);
  const isLogouting = selectors.selectIsLogouting(state);

  const group = selectors.selectGroupByUserId(state, id);

  return {
    avatarUrl,
    email,
    usernameUpdateForm,
    emailUpdateForm,
    isAdmin,
    name,
    organization,
    passwordUpdateForm,
    phone,
    username,
    defaultData: currentUser,
    permissions,
    group,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onAvatarUpdate: entryActions.updateCurrentUserAvatar,
      onEmailUpdate: entryActions.updateCurrentUserEmail,
      onEmailUpdateMessageDismiss: entryActions.clearUserEmailUpdateError,
      onLogout: entryActions.logout,
      onMessageDissmiss: entryActions.clearCurrentUserEmailUpdateError,
      onPasswordUpdate: entryActions.updateCurrentUserPassword,
      onPasswordUpdateMessageDismiss: entryActions.clearUserPasswordUpdateError,
      onUpdate: entryActions.updateCurrentUser,
      onUsernameUpdate: entryActions.updateCurrentUserUsername,
      onUsernameUpdateMessageDismiss: entryActions.clearUserUsernameUpdateError,
      onUserSettingsClick: entryActions.openUserSettingsModal,
      // onUsernameUpdateMessageDismiss: entryActions.clearUserUsernameUpdateError,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
