import socket from './socket';

/* Actions */

const createDistrictMembership = (districtId, data, headers) =>
  socket.post(`/districts/${districtId}/memberships`, data, headers);

const updateDistrictMembership = (id, data, headers) =>
  socket.patch(`/district-memberships/${id}`, data, headers);

const deleteDistrictMembership = (id, headers) =>
  socket.delete(`/district-memberships/${id}`, undefined, headers);

export default {
  createDistrictMembership,
  updateDistrictMembership,
  deleteDistrictMembership,
};
