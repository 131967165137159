import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Search } from 'semantic-ui-react';

import styles from './SearchContent.module.scss';

const SearchContent = React.memo(
  ({ searchValue, isLoading, results, onSearch, onSelect, onSelectContent }) => {
    const [query, setQuery] = useState(searchValue);
    const [open, setOpen] = useState(false);
    const timeoutRef = useRef(null);

    const handleOnFocus = useCallback(() => {
      setOpen(true);
      onSearch({ name: query });
    }, [onSearch, query]);

    const handleSearchChange = React.useCallback(
      (_, data) => {
        clearTimeout(timeoutRef.current);
        setQuery(data.value);
        setOpen(true);

        timeoutRef.current = setTimeout(() => {
          onSearch({ name: data.value });
        }, 200);
      },
      [onSearch],
    );

    const handleResultSelect = useCallback(
      (_, { result }) => {
        const content = results.find(({ id }) => id === result.value);

        if (result.title !== query) {
          onSelectContent(content);
        }
        onSelect(result.value);
        setQuery(result.title);
        setOpen(false);
      },
      [onSelect, onSelectContent, results, query],
    );

    useEffect(() => {
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, []);

    const resultsMap = results.map(({ name, id }) => ({ title: name, key: id, value: id }));

    return (
      <Search
        className={styles.wrapper}
        open={open}
        onMouseDown={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        onFocus={handleOnFocus}
        loading={isLoading}
        placeholder="Tìm bản tin..."
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        noResultsMessage="Không tìm thấy kết quả."
        results={resultsMap}
        value={query}
        size="small"
      />
    );
  },
);

SearchContent.propTypes = {
  searchValue: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  results: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSelectContent: PropTypes.func.isRequired,
};

SearchContent.defaultProps = {
  searchValue: '',
};

export default SearchContent;
