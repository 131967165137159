import * as React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

import styles from './AudioProgressBar.module.scss';

function formatDurationDisplay(duration) {
  const min = Math.floor(duration / 60);
  const sec = Math.floor(duration - min * 60);
  const formatted = [min, sec].map((n) => (n < 10 ? `0${n}` : n)).join(':'); // format - mm:ss
  return formatted;
}

const AudioProgressBar = React.memo(
  ({ duration, currentProgress, buffered, sidebarMenu, ...rest }) => {
    const [position, setPosition] = React.useState({ left: 0, top: 0 });
    const [hoverTime, setHoverTime] = React.useState(0);
    const [isEnter, setEnter] = React.useState(false);
    const [endOfRange, setEndOfRange] = React.useState(false);

    const progressBarWidth = Number.isNaN(currentProgress / duration)
      ? 0
      : currentProgress / duration;
    const bufferedWidth = Number.isNaN(buffered / duration) ? 0 : buffered / duration;

    const progressStyles = {
      '--progress-width': progressBarWidth,
      '--buffered-width': bufferedWidth,
    };

    const handleOnMouse = React.useCallback(
      (e) => {
        if (!duration) return;

        const { left } = e.target.getBoundingClientRect();
        const leftDistance = e.clientX - e.target.offsetLeft - left;
        const timeHover =
          leftDistance > 0 ? Math.ceil((leftDistance / e.target.clientWidth) * duration) : 0;

        setEnter(true);

        setHoverTime(timeHover);

        setPosition({
          left: leftDistance - (leftDistance > e.target.clientWidth * 0.8 ? 30 : 5),
          top: e.target.offsetTop - 100,
        });

        setEndOfRange(leftDistance > e.target.clientWidth * 0.8);
      },
      [duration],
    );

    return (
      <div className={styles.wrapper}>
        <Popup
          className={classNames(endOfRange && styles.pointer)}
          open={isEnter}
          style={{
            position: 'absolute',
            ...position,
          }}
          content={formatDurationDisplay(hoverTime)}
          size="small"
          trigger={
            <input
              className={styles.slider}
              type="range"
              name="progress"
              style={progressStyles}
              min={0}
              step={0.05}
              max={duration}
              value={currentProgress}
              onMouseEnter={handleOnMouse}
              onMouseMove={handleOnMouse}
              onMouseLeave={() => setEnter(false)}
              {...rest} // eslint-disable-line react/jsx-props-no-spreading
            />
          }
        />
      </div>
    );
  },
);

AudioProgressBar.propTypes = {
  duration: PropTypes.number.isRequired,
  currentProgress: PropTypes.number.isRequired,
  buffered: PropTypes.number.isRequired,
  sidebarMenu: PropTypes.bool.isRequired,
};

export default AudioProgressBar;
