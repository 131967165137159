import ActionTypes from '../constants/ActionTypes';

const createUserGroup = (userGroup) => ({
  type: ActionTypes.USER_GROUP_CREATE,
  payload: {
    userGroup,
  },
});

createUserGroup.success = (localId, userGroup, provinceId) => ({
  type: ActionTypes.USER_GROUP_CREATE__SUCCESS,
  payload: {
    userGroup,
    localId,
    provinceId,
  },
});

createUserGroup.failure = (localId, error) => ({
  type: ActionTypes.USER_GROUP_CREATE__FAILURE,
  payload: {
    error,
    localId,
  },
});

const handleUserGroupCreate = (userGroup) => ({
  type: ActionTypes.USER_GROUP_CREATE_HANDLE,
  payload: {
    userGroup,
  },
});

const fetchUserGroups = (page, limit) => ({
  type: ActionTypes.USER_GROUPS_FETCH,
  payload: {
    page,
    limit,
  },
});

fetchUserGroups.success = (userGroups, total) => ({
  type: ActionTypes.USER_GROUPS_FETCH__SUCCESS,
  payload: {
    userGroups,
    total,
  },
});

fetchUserGroups.failure = (error) => ({
  type: ActionTypes.USER_GROUPS_FETCH__FAILURE,
  payload: {
    error,
  },
});

const updateUserGroup = (id, data) => ({
  type: ActionTypes.USER_GROUP_UPDATE,
  payload: {
    id,
    data,
  },
});

updateUserGroup.success = (userGroup) => ({
  type: ActionTypes.USER_GROUP_UPDATE__SUCCESS,
  payload: {
    userGroup,
  },
});

updateUserGroup.failure = (id, error) => ({
  type: ActionTypes.USER_GROUP_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleUserGroupUpdate = (userGroup) => ({
  type: ActionTypes.USER_GROUP_UPDATE_HANDLE,
  payload: {
    userGroup,
  },
});

const deleteUserGroup = (id) => ({
  type: ActionTypes.USER_GROUP_DELETE,
  payload: {
    id,
  },
});

deleteUserGroup.success = (userGroup, provinceId) => ({
  type: ActionTypes.USER_GROUP_DELETE__SUCCESS,
  payload: {
    userGroup,
    provinceId,
  },
});

deleteUserGroup.failure = (id, error) => ({
  type: ActionTypes.USER_GROUP_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleUserGroupDelete = (userGroup) => ({
  type: ActionTypes.USER_GROUP_DELETE_HANDLE,
  payload: {
    userGroup,
  },
});

const clearUserGroupError = () => ({
  type: ActionTypes.USER_GROUP_ERROR_CLEAR,
  payload: {},
});

export default {
  createUserGroup,
  handleUserGroupCreate,
  fetchUserGroups,
  updateUserGroup,
  handleUserGroupUpdate,
  deleteUserGroup,
  handleUserGroupDelete,
  clearUserGroupError,
};
