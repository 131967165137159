import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  data: {
    contentId: '',
    title: '',
    start: '',
    end: '',
    repeatRule: '',
    resource: '',
    status: 'active',
  },
  isSubmitting: false,
  error: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SCHEDULE_CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
        isSubmitting: true,
      };
    case ActionTypes.SCHEDULE_CREATE__SUCCESS:
      return {
        ...initialState,
      };
    case ActionTypes.SCHEDULE_CREATE__FAILURE:
      return {
        ...state,
        isSubmitting: false,
      };
    case ActionTypes.SCHEDULE_ERROR_CLEAR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
