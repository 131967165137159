import Config from './Config';

const ROOT = `${Config.BASE_PATH}/`;
const LOGIN = `${Config.BASE_PATH}/login`;
const SCAN_QRCODE = `${Config.BASE_PATH}/login/scan-qrcode`;
const VERIFY_CODE = `${Config.BASE_PATH}/login/verify-code`;
const USERS = `${Config.BASE_PATH}/users`;
const PROVINCES = `${Config.BASE_PATH}/:slug`;
const SETTING_PROVINCE_CATEGOGY = `${Config.BASE_PATH}/:slug/don-vi-hanh-chinh`;
const CONTENTS = `${Config.BASE_PATH}/:slug/ban-tin`;
const CONTENT = `${Config.BASE_PATH}/:slug/ban-tin/:id`;
const ELECTRONIC_CONTENTS = `${Config.BASE_PATH}/:slug/ban-tin-dien-tu-cong-cong`;
const REPORTS = `${Config.BASE_PATH}/:slug/bao-cao`;
const SETTINGS = `${Config.BASE_PATH}/:slug/cai-dat`;
const SETTING_USERS = `${Config.BASE_PATH}/:slug/cai-dat/tai-khoan-nguoi-dung`;
const SCHEDULES = `${Config.BASE_PATH}/:slug/lich-phat`;
const SCHEDULES_LOGS = `${Config.BASE_PATH}/:slug/ban-tin/lich-phat`;
const DISTRICTS = `${Config.BASE_PATH}/:slug/dia-ban/:id`;
const STATIONS = `${Config.BASE_PATH}/:slug/dai-truyen-thanh`;
const DEVICES = `${Config.BASE_PATH}/:slug/cum-loa`;
const DISPLAYS = `${Config.BASE_PATH}/:slug/man-hinh`;
const DEVICE = `${Config.BASE_PATH}/cum-loa/:id`;
const USER = `${Config.BASE_PATH}/:id/cai-dat-nguoi-dung`;
const SETTING_USER_GROUPS = `${Config.BASE_PATH}/:slug/cai-dat-nhom-nguoi-dung`;
const USER_LOG = `${Config.BASE_PATH}/:slug/nhat-ky-hoat-dong`;

export default {
  ROOT,
  LOGIN,
  SCAN_QRCODE,
  VERIFY_CODE,
  USERS,
  PROVINCES,
  CONTENTS,
  CONTENT,
  ELECTRONIC_CONTENTS,
  REPORTS,
  SETTINGS,
  SETTING_USERS,
  SCHEDULES,
  SCHEDULES_LOGS,
  DISTRICTS,
  STATIONS,
  DEVICES,
  DISPLAYS,
  DEVICE,
  USER,
  SETTING_PROVINCE_CATEGOGY,
  SETTING_USER_GROUPS,
  USER_LOG,
};
