import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { Icon, Loader } from 'semantic-ui-react';

// import ProvincesContainer from '../../containers/ProvincesContainer';
import ContentsContainer from '../../containers/ContentsContainer';
import ReportsContainer from '../../containers/ReportsContainer';
import ProvinceSettingsContainer from '../../containers/ProvinceSettingsContainer';
import SchedulesContainer from '../../containers/SchedulesContainer';
import DistrictContainer from '../../containers/DistrictContainer';
import StationsContainer from '../../containers/StationsContainer';
import DevicesContainer from '../../containers/DevicesContainer';
import DisplaysContainer from '../../containers/DisplaysContainer';
import UserSettingsContainer from '../../containers/UserSettingsContainer';
import HomeContainer from '../../containers/HomeContainer';
import UserAccountsContainer from '../../containers/UserAccountsContainer';
import SchedulesLogsContainer from '../../containers/SchedulesLogsContainer';
import SettingProvinceCategoryContainer from '../../containers/SettingProvinceCategoryContainer';
import ElectronicContentContainer from '../../containers/ElectronicContentsContainer';
import UserGroupsContainer from '../../containers/UserGroupsContainer';
import UserLogContainer from '../../containers/UserLogContainer';

import styles from './Static.module.scss';
import Paths from '../../constants/Paths';

function Static({ path, provinceId, deviceId, district, sidebarMenu, isDarkMode, level }) {
  const [t] = useTranslation();

  if (path === Paths.ROOT) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        {/* <ProvincesContainer /> */}
        <HomeContainer />
      </div>
    );
  }

  if (path === Paths.PROVINCES) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <HomeContainer />
      </div>
    );
  }

  if (path === Paths.SETTING_PROVINCE_CATEGOGY) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <SettingProvinceCategoryContainer />
      </div>
    );
  }

  if (path === Paths.STATIONS) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <StationsContainer />
      </div>
    );
  }

  if (path === Paths.DEVICES) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <DevicesContainer />
      </div>
    );
  }

  if (path === Paths.DISPLAYS) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <DisplaysContainer />
      </div>
    );
  }

  if (path === Paths.CONTENTS || path === Paths.CONTENT) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <ContentsContainer />
      </div>
    );
  }

  if (path === Paths.ELECTRONIC_CONTENTS) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <ElectronicContentContainer />
      </div>
    );
  }

  if (path === Paths.REPORTS) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <ReportsContainer />
      </div>
    );
  }

  if (path === Paths.SETTINGS) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <ProvinceSettingsContainer />
      </div>
    );
  }

  if (path === Paths.SETTING_USERS) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <UserAccountsContainer />
      </div>
    );
  }

  if (path === Paths.SCHEDULES) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <SchedulesContainer />
      </div>
    );
  }

  if (path === Paths.SCHEDULES_LOGS) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <SchedulesLogsContainer />
      </div>
    );
  }

  if (path === Paths.USER) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <UserSettingsContainer />
      </div>
    );
  }

  if (path === Paths.SETTING_USER_GROUPS) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <UserGroupsContainer />
      </div>
    );
  }

  if (path === Paths.USER_LOG) {
    return (
      <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
        <UserLogContainer />
      </div>
    );
  }

  if (deviceId === null) {
    return (
      <div
        className={classNames(
          styles.wrapper,
          styles.wrapperFlex,
          sidebarMenu && styles.sidebarMargin,
        )}
      >
        <div className={styles.message}>
          <h1>
            {t('common.deviceNotFound', {
              context: 'title',
            })}
          </h1>
        </div>
      </div>
    );
  }

  if (district === null) {
    return (
      <div
        className={classNames(
          styles.wrapper,
          styles.wrapperFlex,
          sidebarMenu && styles.sidebarMargin,
        )}
      >
        <div className={styles.message}>
          <h1>
            {t('common.districtNotFound', {
              context: 'title',
            })}
          </h1>
        </div>
      </div>
    );
  }

  if (provinceId === null) {
    return (
      <div
        className={classNames(
          styles.wrapper,
          styles.wrapperFlex,
          sidebarMenu && styles.sidebarMargin,
        )}
      >
        <div className={styles.message}>
          <h1>
            {t('common.provinceNotFound', {
              context: 'title',
            })}
          </h1>
        </div>
      </div>
    );
  }

  if (district === undefined) {
    return (
      <div
        className={classNames(
          styles.wrapper,
          styles.wrapperFlex,
          styles.wrapperProvince,
          sidebarMenu && styles.sidebarMargin,
        )}
      >
        <div className={styles.message}>
          <Icon
            inverted={isDarkMode}
            name="hand point up outline"
            size="huge"
            className={styles.messageIcon}
          />
          <h1 className={styles.messageTitle}>
            {t('common.openDistrict', {
              context: 'title',
            })}
          </h1>
          <div className={styles.messageContent}>
            <Trans i18nKey="common.createNewOneOrSelectExistingOne" />
          </div>
        </div>
      </div>
    );
  }

  if (district.isFetching) {
    return (
      <div
        className={classNames(
          styles.wrapper,
          styles.wrapperLoader,
          styles.wrapperProvince,
          sidebarMenu && styles.sidebarMargin,
        )}
      >
        <Loader active size="big" />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        styles.wrapper,
        styles.wrapperFlex,
        styles.wrapperDistrict,
        sidebarMenu && styles.sidebarMargin,
        level !== 'province' && styles.reduceMarginTop,
      )}
    >
      <DistrictContainer />
    </div>
  );
}

Static.propTypes = {
  path: PropTypes.string.isRequired,
  provinceId: PropTypes.string,
  deviceId: PropTypes.string,
  district: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  sidebarMenu: PropTypes.bool.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  level: PropTypes.string.isRequired,
};

Static.defaultProps = {
  provinceId: undefined,
  deviceId: undefined,
  district: undefined,
};

export default Static;
