import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import entryActions from '../entry-actions';
import Login from '../components/Login';
import { selectPathname } from '../selectors/router';
import Paths from '../constants/Paths';

const mapStateToProps = ({
  ui: {
    authenticateForm: {
      data: defaultData,
      isSubmitting,
      qrcode,
      isInitial,
      error,
      isRetrieveQRCode,
      expires,
    },
  },
  router,
}) => {
  const path = selectPathname({ router });

  return {
    defaultData,
    isInitial,
    isSubmitting,
    isVerifyCode: path === Paths.VERIFY_CODE,
    isQRCode: path === Paths.SCAN_QRCODE,
    isRetrieveQRCode,
    qrcode,
    expires,
    error,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onAuthenticate: entryActions.authenticate,
      onAuthenticateCode: entryActions.authenticateCode,
      onAuthenticateQRCode: entryActions.authenticateQRCode,
      onRetrieveQRCode: entryActions.retrieveQRCode,
      onMessageDismiss: entryActions.clearAuthenticateError,
      onStopCountDown: entryActions.clearAuthenticateCountDown,
      onFetchCode: entryActions.retrieveAuthenticateCode,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
