import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import { push } from '../lib/redux-router';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Paths from '../constants/Paths';
import ElectronicContentModal from '../components/ElectronicContentModal';

const mapStateToProps = (state, { selectedId, isViewing }) => {
  const { data: defaultData, isSubmitting, contentId, error } = state.ui.contentCreateForm;
  const { provinceId } = selectors.selectPath(state);
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);
  const isDarkMode = selectors.selectIsDarkMode(state);
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const wards = [];

  let content;
  let attachments = [];
  if (selectedId) {
    content = selectors.selectContentById(state, selectedId);
    Object.keys(content).forEach((k) => content[k] == null && delete content[k]);

    attachments = selectors.selectAttachmentsByContentId(state, selectedId);
    content.categories = selectors
      .selectCategoriesByContentId(state, selectedId)
      .map((cat) => cat.id);
  }

  const isCurrentUserEditor = false;
  const isCurrentUserEditorOrCanComment = false;
  const allCategories = selectors.selectCategoriesForCurrentProvince(state);

  return {
    // name,
    // color,
    // type,
    // file,
    // fileDirname,
    // playCount,
    // status,
    // description,
    defaultData: { ...defaultData, ...content },
    isDarkMode,
    districts,
    wards,
    // districtId,
    provinceId,
    attachments,
    allCategories,
    isSubmitting,
    isViewing,
    isEditting: selectedId !== undefined && !isViewing,
    contentId,
    canEdit: isCurrentUserEditor,
    canEditCommentActivities: isCurrentUserEditorOrCanComment,
    canEditAllCommentActivities: isCurrentUserManager,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createContentInCurrentProvince,
      onUpdate: entryActions.updateContent,
      onDelete: entryActions.deleteCurrentDevice,
      onContentMove: entryActions.moveContent,
      onContentDelete: entryActions.deleteContent,
      onAttachmentCreate: entryActions.createAttachmentToContent,
      onAttachmentUpdate: entryActions.updateAttachment,
      onAttachmentDelete: entryActions.deleteAttachment,
      onErrorClear: entryActions.clearContentError,
      // onClose: entryActions.closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ElectronicContentModal);
