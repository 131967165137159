import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* wardsWatchers() {
  yield all([
    takeEvery(EntryActionTypes.WARD_IN_CURRENT_PROVINCE_CREATE, ({ payload: { data } }) =>
      services.createWardInCurrentProvince(data),
    ),
    takeEvery(EntryActionTypes.WARD_CREATE_HANDLE, ({ payload: { ward, requestId } }) =>
      services.handleWardCreate(ward, requestId),
    ),
    takeEvery(EntryActionTypes.WARD_FETCH, ({ payload: { id } }) => services.fetchWard(id)),
    takeEvery(EntryActionTypes.WARD_UPDATE, ({ payload: { id, data } }) =>
      services.updateWard(id, data),
    ),
    takeEvery(EntryActionTypes.WARD_UPDATE_HANDLE, ({ payload: { ward } }) =>
      services.handleWardUpdate(ward),
    ),
    takeEvery(EntryActionTypes.WARD_MOVE, ({ payload: { id, index } }) =>
      services.moveWard(id, index),
    ),
    takeEvery(EntryActionTypes.WARD_DELETE, ({ payload: { id } }) => services.deleteWard(id)),
    takeEvery(EntryActionTypes.WARD_DELETE_HANDLE, ({ payload: { ward } }) =>
      services.handleWardDelete(ward),
    ),
  ]);
}
