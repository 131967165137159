/* eslint-disable no-plusplus */
import PizZip from 'pizzip';
import { DOMParser } from '@xmldom/xmldom';

const str2xml = (str) => {
  let text = str;
  if (str.charCodeAt(0) === 65279) {
    // BOM sequence
    text = str.substr(1);
  }
  return new DOMParser().parseFromString(text, 'text/xml');
};

// Get paragraphs as javascript array
const getParagraphs = (content) => {
  const zip = new PizZip(content);
  const xml = str2xml(zip.files['word/document.xml'].asText());
  const paragraphsXml = xml.getElementsByTagName('w:p');
  const paragraphs = [];

  for (let i = 0, len = paragraphsXml.length; i < len; i++) {
    let fullText = '';
    const textsXml = paragraphsXml[i].getElementsByTagName('w:t');
    for (let j = 0, len2 = textsXml.length; j < len2; j++) {
      const textXml = textsXml[j];
      if (textXml.childNodes) {
        fullText += textXml.childNodes[0]?.nodeValue ?? '';
      }
    }
    if (fullText) {
      paragraphs.push(fullText);
    }
  }
  return paragraphs;
};

export default getParagraphs;
