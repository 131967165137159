import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';

import Export from '../components/Reports/Export';
import entryActions from '../entry-actions';

const mapStateToProps = (state) => {
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const wards = selectors.selectWardsForCurrentProvince(state);

  const { level } = selectors.selectCurrentUser(state);

  const {
    export: { contents, devices, stations, isSuccess },
  } = state.ui.reports;

  return {
    isSuccess,
    level,
    districts,
    wards,
    contents,
    devices,
    stations,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onExport: entryActions.getExportReport,
      onClear: entryActions.clearExportState,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Export);
