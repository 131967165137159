import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dropdown, Icon, Menu, Sidebar as SematicSidebar } from 'semantic-ui-react';

import submenu from '../../constants/SubMenu';
import Paths from '../../constants/Paths';

import styles from './SidebarNew.module.scss';
import UserMenu from './UserMenu';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as LogoText } from '../../assets/images/logo-text.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/menu/home.svg';
import { ReactComponent as StationsIcon } from '../../assets/icons/menu/stations.svg';
import { ReactComponent as DeviceIcon } from '../../assets/icons/menu/device.svg';
import { ReactComponent as ContentIcon } from '../../assets/icons/menu/content.svg';
import { ReactComponent as ScheduleIcon } from '../../assets/icons/menu/schedule.svg';
import { ReactComponent as ReportIcon } from '../../assets/icons/menu/report.svg';
import { ReactComponent as SettingIcon } from '../../assets/icons/menu/setting.svg';
import { ReactComponent as UserLogIcon } from '../../assets/icons/menu/timer.svg';

const MENU = {
  HOME: 'home',
  STATIONS: 'stations',
  DEVICES: 'devices',
  CONTENTS: 'contents',
  SCHEDULES: 'schedules',
  REPORT: 'report',
  SETTINGS: 'settings',
  USER_LOG: 'userLog',
};

function Sidebar({
  sidebarMenu,
  isDarkMode,
  province,
  user,
  path,
  onSidebarMenu,
  onLogout,
  onRefetchContents,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [activeItem, setActiveItem] = useState(null);

  const matchPath = (pathCheck) => path !== Paths.ROOT && pathCheck === path;

  const handleItemClick = useCallback(
    (e, { to }) => {
      if (
        e.target.parentNode.classList.contains('pointing') ||
        e.target.parentNode instanceof SVGElement
      ) {
        navigate(to);
      }
    },
    [navigate],
  );

  const handleTriggerContents = useCallback(() => {
    if ([Paths.SCHEDULES, Paths.REPORTS, Paths.USER_LOG].includes(path)) {
      onRefetchContents();
    }
  }, [path, onRefetchContents]);

  return (
    <SematicSidebar
      as={Menu}
      animation="push"
      // icon="contented"
      inverted={isDarkMode}
      pointing
      vertical
      visible
      className={classNames(styles.wrapper, !sidebarMenu && styles.condensed)}
    >
      <Button
        circular
        icon
        className={classNames(styles.condensedButton, !sidebarMenu && styles.position)}
        onClick={onSidebarMenu}
      >
        <Icon name={`angle ${sidebarMenu ? 'left' : 'right'}`} />
      </Button>
      <Menu.Item as={Link} to={Paths.ROOT} className={classNames(styles.logo)}>
        {sidebarMenu ? <LogoText /> : <Logo />}
      </Menu.Item>
      <Menu.Item as={Link} to={Paths.ROOT} className={classNames(styles.logoMobile)}>
        <Logo />
      </Menu.Item>

      <Menu.Item
        as={Link}
        to={Paths.ROOT}
        className={classNames(
          styles.item,
          !sidebarMenu && styles.itemPadding,
          Paths.ROOT === path && styles.active,
        )}
        name="home"
      >
        <HomeIcon stroke="#1E3932" />
        {sidebarMenu && <span>{t('menu.home')}</span>}
      </Menu.Item>
      <Menu.Item
        as={Link}
        to={Paths.STATIONS.replace(':slug', province.slug)}
        className={classNames(
          styles.item,
          !sidebarMenu && styles.itemPadding,
          matchPath(Paths.STATIONS) && styles.active,
        )}
        name="station"
      >
        <StationsIcon />
        {sidebarMenu && <span>{t('menu.station')}</span>}
      </Menu.Item>

      <Dropdown
        item
        name={MENU.DEVICES}
        icon={
          <>
            <DeviceIcon />
            {sidebarMenu && <span>{t('menu.device')}</span>}
            <Icon name="chevron right" className={styles.hidden} />
          </>
        }
        className={classNames(
          styles.item,
          !sidebarMenu && styles.itemPadding,
          (matchPath(Paths.DEVICES) || matchPath(Paths.DISTRICTS) || matchPath(Paths.DISPLAYS)) &&
            styles.active,
        )}
        to={Paths.DEVICES.replace(':slug', province.slug)}
        pointing="left"
        open={activeItem === MENU.DEVICES}
        onMouseOver={() => setActiveItem(MENU.DEVICES)}
        onMouseOut={() => setActiveItem(null)}
        onClick={handleItemClick}
      >
        <Dropdown.Menu style={{ marginLeft: '0' }}>
          {submenu.device.map((item) => (
            <Dropdown.Item
              key={item.name}
              as={Link}
              name={item.name}
              text={t(`menu.${item.name}`)}
              to={item.path
                .replace(':slug', province.slug)
                .replace(':id', province.firstDistrictId)}
              onClick={() => setActiveItem(null)}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Item
        as={Link}
        to={Paths.CONTENTS.replace(':slug', province.slug)}
        className={classNames(
          styles.item,
          !sidebarMenu && styles.itemPadding,
          (matchPath(Paths.CONTENTS) || matchPath(Paths.ELECTRONIC_CONTENTS)) && styles.active,
        )}
        name={MENU.CONTENTS}
        onClick={handleTriggerContents}
      >
        <ContentIcon />
        {sidebarMenu && <span>{t('menu.content')}</span>}
      </Menu.Item>
      {/* <Dropdown
        item
        name={MENU.CONTENTS}
        icon={
          <>
            <ContentIcon />
            {sidebarMenu && <span>{t('menu.content')}</span>}
            <Icon name="chevron right" className={styles.hidden} />
          </>
        }
        className={classNames(
          styles.item,
          !sidebarMenu && styles.itemPadding,
          (matchPath(Paths.CONTENTS) || matchPath(Paths.ELECTRONIC_CONTENTS)) && styles.active,
        )}
        to={Paths.CONTENTS.replace(':slug', province.slug)}
        pointing="left"
        open={activeItem === MENU.CONTENTS}
        onMouseOver={() => setActiveItem(MENU.CONTENTS)}
        onMouseOut={() => setActiveItem(null)}
        onClick={handleItemClick}
      >
        <Dropdown.Menu style={{ marginLeft: '0' }}>
          {submenu.content.map((item) => (
            <Dropdown.Item
              key={item.name}
              as={Link}
              name={item.name}
              text={t(`menu.${item.name}`)}
              to={item.path.replace(':slug', province.slug)}
              onClick={() => setActiveItem(null)}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown> */}
      {/* <Dropdown
        item
        name={MENU.SCHEDULES}
        icon={
          <>
            <ScheduleIcon />
            {sidebarMenu && <span>{t('menu.schedule')}</span>}
            <Icon name="chevron right" className={styles.hidden} />
          </>
        }
        className={classNames(
          styles.item,
          !sidebarMenu && styles.itemPadding,
          (matchPath(Paths.SCHEDULES) || matchPath(Paths.SCHEDULES_LOGS)) && styles.active,
        )}
        to={Paths.SCHEDULES.replace(':slug', province.slug)}
        pointing="left"
        open={activeItem === MENU.SCHEDULES}
        onMouseOver={() => setActiveItem(MENU.SCHEDULES)}
        onMouseOut={() => setActiveItem(null)}
        onClick={handleItemClick}
      >
        <Dropdown.Menu style={{ marginLeft: '0' }}>
          {submenu.schedule.map((item) => (
            <Dropdown.Item
              key={item.name}
              as={Link}
              name={item.name}
              text={t(`menu.${item.name}`)}
              to={item.path.replace(':slug', province.slug)}
              onClick={() => setActiveItem(null)}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown> */}
      <Menu.Item
        as={Link}
        to={Paths.REPORTS.replace(':slug', province.slug)}
        className={classNames(
          styles.item,
          !sidebarMenu && styles.itemPadding,
          matchPath(Paths.REPORTS) && styles.active,
        )}
        name="report"
      >
        <ReportIcon />
        {sidebarMenu && <span>{t('menu.report')}</span>}
      </Menu.Item>
      <Dropdown
        item
        name={MENU.SETTINGS}
        icon={
          <>
            <SettingIcon />
            {sidebarMenu && <span>{t('menu.setting')}</span>}
            <Icon name="chevron right" className={styles.hidden} />
          </>
        }
        className={classNames(
          styles.item,
          !sidebarMenu && styles.itemPadding,
          (matchPath(Paths.SETTINGS) ||
            matchPath(Paths.SETTING_PROVINCE_CATEGOGY) ||
            matchPath(Paths.SETTING_USERS)) &&
            styles.active,
        )}
        to={Paths.SETTINGS.replace(':slug', province.slug)}
        pointing="left"
        open={activeItem === MENU.SETTINGS}
        onMouseOver={() => setActiveItem(MENU.SETTINGS)}
        onMouseOut={() => setActiveItem(null)}
        onClick={handleItemClick}
      >
        <Dropdown.Menu style={{ marginLeft: '0' }}>
          {submenu.setting.map((item) =>
            /* eslint-disable-next-line no-nested-ternary */
            [Paths.SETTING_USERS, Paths.SETTING_USER_GROUPS].includes(item.path) ? (
              user.isAdmin ? (
                <Dropdown.Item
                  key={item.name}
                  as={Link}
                  name={item.name}
                  text={t(`menu.${item.name}`)}
                  to={item.path.replace(':slug', province.slug)}
                  onClick={() => setActiveItem(null)}
                />
              ) : null
            ) : (
              <Dropdown.Item
                key={item.name}
                as={Link}
                name={item.name}
                text={t(`menu.${item.name}`)}
                to={item.path.replace(':slug', province.slug)}
                onClick={() => setActiveItem(null)}
              />
            ),
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Item
        as={Link}
        to={Paths.USER_LOG.replace(':slug', province.slug)}
        className={classNames(
          styles.item,
          !sidebarMenu && styles.itemPadding,
          matchPath(Paths.USER_LOG) && styles.active,
        )}
        name={MENU.USER_LOG}
      >
        <UserLogIcon />
        {sidebarMenu && <span>{t('menu.userLog')}</span>}
      </Menu.Item>
      <UserMenu sidebarMenu={sidebarMenu} onLogout={onLogout} user={user} />
    </SematicSidebar>
  );
}

Sidebar.propTypes = {
  sidebarMenu: PropTypes.bool.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  province: PropTypes.objectOf(PropTypes.any).isRequired,
  /* eslint-disable react/forbid-prop-types */
  user: PropTypes.object.isRequired,
  onSidebarMenu: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onRefetchContents: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {};

export default Sidebar;
