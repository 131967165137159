import ActionTypes from '../constants/ActionTypes';

const createWard = (ward) => ({
  type: ActionTypes.WARD_CREATE,
  payload: {
    ward,
  },
});

createWard.success = (localId, ward, wardMemberships) => ({
  type: ActionTypes.WARD_CREATE__SUCCESS,
  payload: {
    localId,
    ward,
    wardMemberships,
  },
});

createWard.failure = (localId, error) => ({
  type: ActionTypes.WARD_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleWardCreate = (ward) => ({
  type: ActionTypes.WARD_CREATE_HANDLE,
  payload: {
    ward,
  },
});

const fetchWard = (id) => ({
  type: ActionTypes.WARD_FETCH,
  payload: {
    id,
  },
});

fetchWard.success = (
  ward,
  users,
  provinces,
  wardMemberships,
  contents,
  contentCategories,
  stations,
  devices,
  deviceMemberships,
  deviceContents,
  tasks,
  attachments,
) => ({
  type: ActionTypes.WARD_FETCH__SUCCESS,
  payload: {
    ward,
    users,
    provinces,
    wardMemberships,
    contents,
    contentCategories,
    stations,
    devices,
    deviceMemberships,
    deviceContents,
    tasks,
    attachments,
  },
});

fetchWard.failure = (id, error) => ({
  type: ActionTypes.WARD_FETCH__FAILURE,
  payload: {
    id,
    error,
  },
});

const updateWard = (id, data) => ({
  type: ActionTypes.WARD_UPDATE,
  payload: {
    id,
    data,
  },
});

updateWard.success = (ward) => ({
  type: ActionTypes.WARD_UPDATE__SUCCESS,
  payload: {
    ward,
  },
});

updateWard.failure = (id, error) => ({
  type: ActionTypes.WARD_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleWardUpdate = (ward) => ({
  type: ActionTypes.WARD_UPDATE_HANDLE,
  payload: {
    ward,
  },
});

const deleteWard = (id) => ({
  type: ActionTypes.WARD_DELETE,
  payload: {
    id,
  },
});

deleteWard.success = (ward) => ({
  type: ActionTypes.WARD_DELETE__SUCCESS,
  payload: {
    ward,
  },
});

deleteWard.failure = (id, error) => ({
  type: ActionTypes.WARD_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleWardDelete = (ward) => ({
  type: ActionTypes.WARD_DELETE_HANDLE,
  payload: {
    ward,
  },
});

export default {
  createWard,
  handleWardCreate,
  fetchWard,
  updateWard,
  handleWardUpdate,
  deleteWard,
  handleWardDelete,
};
