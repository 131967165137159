import socket from './socket';

/* Actions */

export const transformSchedule = (schedule) => ({
  ...schedule,
  ...(schedule.start && {
    start: new Date(schedule.start),
  }),
  ...(schedule.end && {
    end: new Date(schedule.end),
  }),
  ...(schedule.repeatUntil && {
    repeatUntil: new Date(schedule.repeatUntil),
  }),
});

export const transformScheduleData = (data) => ({
  ...data,
  ...(data.start && {
    start: data.start.toISOString(),
  }),
  ...(data.end && {
    end: data.end.toISOString(),
  }),
  ...(data.repeatUntil && {
    repeatUntil: data.repeatUntil.toISOString(),
  }),
});

const createSchedule = (provinceId, data, headers) =>
  socket
    .post(`/provinces/${provinceId}/schedules`, transformScheduleData(data), headers)
    .then((body) => ({
      ...body,
      item: transformSchedule(body.item),
    }));

const getSchedules = (provinceId, data, headers) =>
  socket.get(`/provinces/${provinceId}/schedules`, data, headers).then((body) => ({
    ...body,
    items: body.items.map(transformSchedule),
  }));

const updateSchedule = (id, data, headers) =>
  socket.patch(`/schedules/${id}`, transformScheduleData(data), headers).then((body) => ({
    ...body,
    item: transformSchedule(body.item),
  }));

const deleteSchedule = (id, headers) =>
  socket.delete(`/schedules/${id}`, undefined, headers).then((body) => ({
    ...body,
    item: transformSchedule(body.item),
  }));

/* Event handlers */

const makeHandleScheduleCreate = (next) => (body) => {
  next({
    ...body,
    item: transformSchedule(body.item),
  });
};

const makeHandleScheduleUpdate = makeHandleScheduleCreate;

const makeHandleScheduleDelete = makeHandleScheduleCreate;

export default {
  createSchedule,
  getSchedules,
  updateSchedule,
  deleteSchedule,
  makeHandleScheduleCreate,
  makeHandleScheduleUpdate,
  makeHandleScheduleDelete,
};
