import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import entryActions from '../entry-actions';

import UserModalAddStep from '../components/UserModalAddStep';
import selectors from '../selectors';

const mapStateToProps = (state) => {
  const {
    userCreateForm: { data: defaultData, isSubmitting, error },
  } = state.ui;

  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const wards = selectors.selectWardsForCurrentProvince(state);
  const province = selectors.selectCurrentProvince(state);
  const userGroups = selectors.selectUserGroupsForCurrentProvince(state);
  const { level } = selectors.selectCurrentUser(state);

  return {
    defaultData,
    provinces: [province],
    districts,
    wards,
    groups: userGroups,
    isSubmitting,
    error,
    level,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createUser,
      onMessageDismiss: entryActions.clearUserCreateError,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserModalAddStep);
