import { createSelector } from 'redux-orm';
import { pick } from 'lodash';

import orm from '../orm';

export const selectCurrentUserId = ({ auth: { userId } }) => userId;

export const selectUsers = createSelector(orm, ({ User }) =>
  User.getOrderedUndeletedQuerySet().toRefArray(),
);

export const selectUsersExceptCurrent = createSelector(
  orm,
  (state) => selectCurrentUserId(state),
  ({ User }, id) =>
    User.getOrderedUndeletedQuerySet()
      .exclude({
        id,
      })
      .toModelArray()
      .map((userModel) => ({
        ...userModel.ref,
        isProvinceManager: userModel.provinceManagers.toRefArray().length > 0,
        managers: userModel.provinceManagers.toRefArray(),
      })),
);

export const selectCurrentUser = createSelector(
  orm,
  (state) => selectCurrentUserId(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return { ...userModel.ref, permissions: userModel.groups.toRefArray()[0]?.permissions };
  },
);

export const selectProvincesForCurrentUser = createSelector(
  orm,
  (state) => selectCurrentUserId(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return userModel.getOrderedAvailableProvincesModelArray().map((provinceModel) => {
      const districtsModels = provinceModel.getOrderedDistrictsModelArrayAvailableForUser(
        userModel.id,
      );

      let notificationsTotal = 0;
      districtsModels.forEach((districtModel) => {
        districtModel.devices.toModelArray().forEach((deviceModel) => {
          notificationsTotal += deviceModel.getUnreadNotificationsQuerySet().count();
        });
      });

      return {
        ...provinceModel.ref,
        notificationsTotal,
        firstDistrictId: districtsModels[0] && districtsModels[0].id,
      };
    });
  },
);

export const selectProvincesToStationsForCurrentUser = createSelector(
  orm,
  (state) => selectCurrentUserId(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return userModel.getOrderedAvailableProvincesModelArray().map((provinceModel) => ({
      ...provinceModel.ref,
      districts: provinceModel.getOrderedDistrictsModelArrayForUser(id).map((districtModel) => ({
        ...districtModel.ref,
        stations: districtModel.getOrderedStationsQuerySet().toRefArray(),
      })),
    }));
  },
);

export const selectNotificationsForCurrentUser = createSelector(
  orm,
  (state) => selectCurrentUserId(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return userModel
      .getOrderedNotificationsQuerySet()
      .toModelArray()
      .map((notificationModel) => {
        return {
          ...notificationModel.ref,
          activity: notificationModel.activity && {
            ...notificationModel.activity.ref,
            user: notificationModel.activity.user.ref,
          },
          device: notificationModel.activity
            ? // && notificationModel.activity.type === 'deleteDevice'
              notificationModel.activity.data.device
            : notificationModel.device && notificationModel.device.ref,
          content: notificationModel.activity
            ? // && notificationModel.activity.type === 'deleteStation'
              notificationModel.activity.data.content
            : notificationModel.content && notificationModel.content.ref,
          station: notificationModel.activity
            ? // && notificationModel.activity.type === 'deleteStation'
              notificationModel.activity.data.station
            : notificationModel.station && notificationModel.station.ref,
          devices: notificationModel.activity ? notificationModel.activity.data.devices : [],
        };
      });
  },
);

export const selectDistrictMembershipsByUserId = createSelector(
  orm,
  (_, id) => id,
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);
    if (!userModel) {
      return userModel;
    }

    return userModel.districtMemberships.toRefArray();
  },
);

export const selectWardMembershipsByUserId = createSelector(
  orm,
  (_, id) => id,
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);
    if (!userModel) {
      return userModel;
    }

    return userModel.wardMemberships.toRefArray();
  },
);

export const selectAreaForCurrentUser = createSelector(
  orm,
  (state) => selectCurrentUserId(state),
  ({ User, Province }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    const user = pick(userModel.ref, ['level', 'id', 'isAdmin', 'name']);

    if (userModel.level === 'ward') {
      const [wardModel] = userModel.wardMemberships.toModelArray();
      return {
        ...user,
        location: wardModel.ward.ref,
        role: wardModel.role,
      };
    }

    if (userModel.level === 'district') {
      const [districtModel] = userModel.districtMemberships.toModelArray();
      return {
        ...user,
        location: districtModel.district.ref,
        role: districtModel.role,
      };
    }

    const provinceModel = Province.first();

    return {
      ...user,
      location: provinceModel.ref,
      role: userModel.districtMemberships.toModelArray()[0].role,
    };
  },
);

export const selectGroupByUserId = createSelector(
  orm,
  (_, id) => id,
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return userModel.groups.toRefArray()[0];
  },
);

export default {
  selectCurrentUserId,
  selectUsers,
  selectUsersExceptCurrent,
  selectCurrentUser,
  selectProvincesForCurrentUser,
  selectProvincesToStationsForCurrentUser,
  selectNotificationsForCurrentUser,
  selectDistrictMembershipsByUserId,
  selectWardMembershipsByUserId,
  selectAreaForCurrentUser,
  selectGroupByUserId,
};
