import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { closePopup } from '../../lib/popup';

import DroppableTypes from '../../constants/DroppableTypes';
// import StationContainer from '../../containers/StationContainer';
import DeviceModalContainer from '../../containers/DeviceModalContainer';
// import StationAdd from './StationAdd';
// import { ReactComponent as PlusMathIcon } from '../../assets/images/plus-math-icon.svg';
import WardContainer from '../../containers/WardContainer';

import styles from './District.module.scss';

const parseDndId = (dndId) => dndId.split(':')[1];

const District = React.memo(
  ({
    stationIds,
    wardIds,
    isDeviceModalOpened,
    // canEdit,
    // onStationCreate,
    onStationMove,
    onDeviceMove,
  }) => {
    const [t] = useTranslation();
    const [isStationAddOpened, setIsStationAddOpened] = useState(false);

    const wrapper = useRef(null);
    const prevPosition = useRef(null);

    // const handleAddStationClick = useCallback(() => {
    //   setIsStationAddOpened(true);
    // }, []);

    // const handleAddStationClose = useCallback(() => {
    //   setIsStationAddOpened(false);
    // }, []);

    const handleDragStart = useCallback(() => {
      closePopup();
    }, []);

    const handleDragEnd = useCallback(
      ({ draggableId, type, source, destination }) => {
        if (
          !destination ||
          (source.droppableId === destination.droppableId && source.index === destination.index)
        ) {
          return;
        }

        const id = parseDndId(draggableId);

        switch (type) {
          case DroppableTypes.STATION:
            onStationMove(id, destination.index);

            break;
          case DroppableTypes.DEVICE:
            onDeviceMove(id, parseDndId(destination.droppableId), destination.index);

            break;
          default:
        }
      },
      [onStationMove, onDeviceMove],
    );

    const handleMouseDown = useCallback(
      (event) => {
        // If button is defined and not equal to 0 (left click)
        if (event.button) {
          return;
        }

        if (event.target !== wrapper.current && !event.target.dataset.dragScroller) {
          return;
        }

        prevPosition.current = event.clientX;
      },
      [wrapper],
    );

    const handleWindowMouseMove = useCallback(
      (event) => {
        if (!prevPosition.current) {
          return;
        }

        event.preventDefault();

        window.scrollBy({
          left: prevPosition.current - event.clientX,
        });

        prevPosition.current = event.clientX;
      },
      [prevPosition],
    );

    const handleWindowMouseUp = useCallback(() => {
      prevPosition.current = null;
    }, [prevPosition]);

    useEffect(() => {
      document.body.style.overflowX = 'auto';

      return () => {
        document.body.style.overflowX = null;
      };
    }, []);

    useEffect(() => {
      if (isStationAddOpened) {
        window.scroll(document.body.scrollWidth, 0);
      }
    }, [stationIds, isStationAddOpened]);

    useEffect(() => {
      window.addEventListener('mouseup', handleWindowMouseUp);
      window.addEventListener('mousemove', handleWindowMouseMove);

      return () => {
        window.removeEventListener('mouseup', handleWindowMouseUp);
        window.removeEventListener('mousemove', handleWindowMouseMove);
      };
    }, [handleWindowMouseUp, handleWindowMouseMove]);

    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div ref={wrapper} className={styles.wrapper} onMouseDown={handleMouseDown}>
          <div>
            <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
              <Droppable
                droppableId="district"
                type={DroppableTypes.STATION}
                direction="horizontal"
              >
                {({ innerRef, droppableProps, placeholder }) => (
                  <div
                    {...droppableProps} // eslint-disable-line react/jsx-props-no-spreading
                    data-drag-scroller
                    ref={innerRef}
                    className={styles.stations}
                  >
                    {/* {stationIds.map((stationId, index) => (
                      <StationContainer key={stationId} id={stationId} index={index} />
                    ))} */}
                    {wardIds.map((wardId, index) => (
                      <WardContainer key={wardId} id={wardId} index={index} />
                    ))}
                    {placeholder}
                    {/* {canEdit && (
                      <div data-drag-scroller className={styles.station}>
                        {isStationAddOpened ? (
                          <StationAdd onCreate={onStationCreate} onClose={handleAddStationClose} />
                        ) : (
                          <button
                            type="button"
                            className={styles.addStationButton}
                            onClick={handleAddStationClick}
                          >
                            <PlusMathIcon className={styles.addStationButtonIcon} />
                            <span className={styles.addStationButtonText}>
                              {stationIds.length > 0
                                ? t('action.addAnotherStation')
                                : t('action.addStation')}
                            </span>
                          </button>
                        )}
                      </div>
                    )} */}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        {isDeviceModalOpened && <DeviceModalContainer />}
      </>
    );
  },
);

District.propTypes = {
  stationIds: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  wardIds: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  isDeviceModalOpened: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onStationCreate: PropTypes.func.isRequired,
  onStationMove: PropTypes.func.isRequired,
  onDeviceMove: PropTypes.func.isRequired,
};

export default District;
