import { createSelector } from 'redux-orm';

import orm from '../orm';
import { isLocalId } from '../utils/local-id';
import { selectCurrentUserId } from './users';

export const makeSelectWardById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Ward }, id) => {
      const wardModel = Ward.withId(id);

      if (!wardModel) {
        return wardModel;
      }

      return {
        ...wardModel.ref,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const selectWardById = makeSelectWardById();

export const makeSelectDeviceIdsByStationId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Station }, id) => {
      const wardModel = Station.withId(id);

      if (!wardModel) {
        return wardModel;
      }

      return wardModel.getFilteredOrderedDevicesModelArray().map((deviceModel) => deviceModel.id);
    },
  );

export const selectDeviceIdsByStationId = makeSelectDeviceIdsByStationId();

export const selectCurrentUserMembershipForCurrentWard = createSelector(
  orm,
  (_, wardId) => wardId,
  (state) => selectCurrentUserId(state),
  ({ Ward }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const wardModel = Ward.withId(id);

    if (!wardModel) {
      return wardModel;
    }

    const wardMembershipModel = wardModel.getMembershipModelForUser(currentUserId);

    if (!wardMembershipModel) {
      return wardMembershipModel;
    }

    return wardMembershipModel.ref;
  },
);

export const selectMembershipsForCurrentWard = createSelector(
  orm,
  (_, id) => id,
  (state) => selectCurrentUserId(state),
  ({ Ward }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const wardModel = Ward.withId(id);

    if (!wardModel) {
      return wardModel;
    }

    return wardModel
      .getOrderedMembershipsQuerySet()
      .toModelArray()
      .map((membershipModel) => ({
        ...membershipModel.ref,
        isPersisted: !isLocalId(id),
        user: {
          ...membershipModel.user.ref,
          isCurrent: membershipModel.user.id === currentUserId,
        },
      }));
  },
);

export const selectUsersForCurrentWard = createSelector(
  orm,
  (_, id) => id,
  (state) => selectCurrentUserId(state),
  ({ Ward }, wardId, currentUserId) => {
    if (!wardId) {
      return wardId;
    }

    const wardModel = Ward.withId(wardId);

    if (!wardModel) {
      return wardModel;
    }

    return wardModel
      .getOrderedMembershipsQuerySet()
      .toModelArray()
      .map((membershipModel) => ({
        ...membershipModel.user.ref,
        isCurrent: membershipModel.user.id === currentUserId,
      }));
  },
);

export default {
  makeSelectWardById,
  selectWardById,
  makeSelectDeviceIdsByStationId,
  selectDeviceIdsByStationId,
  selectMembershipsForCurrentWard,
  selectCurrentUserMembershipForCurrentWard,
  selectUsersForCurrentWard,
};
