import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  error: null,
  report: {
    contents: [],
    contentCategories: [],
    devices: [],
    stations: [],
    districtId: null,
  },
  export: {
    isSuccess: false,
    contents: [],
    devices: [],
    stations: [],
  },
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.REPORTS_EXPORT:
      return {
        ...state,
        export: {
          ...state.export,
          isSuccess: false,
        },
      };
    case ActionTypes.REPORTS_FETCH:
      return {
        ...state,
        report: {
          ...state.report,
          districtId: payload.districtId,
        },
      };
    case ActionTypes.LOCATION_CHANGE_HANDLE:
    case ActionTypes.REPORTS_FETCH__SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          contents: payload.contents || [],
          contentCategories: payload.contentCategories || [],
          devices: payload.devices || [],
          districtId: payload.districtId,
          stations: payload.stations || [],
        },
      };
    case ActionTypes.REPORTS_EXPORT__SUCCESS:
      return {
        ...state,
        export: {
          isSuccess: true,
          contents: payload.contents || [],
          devices: payload.devices || [],
          stations: payload.stations || [],
        },
      };
    case ActionTypes.REPORTS_EXPORT__FAILURE: {
      return {
        ...state,
        export: { ...state.export, isSuccess: false },
        error: payload.error,
      };
    }
    case ActionTypes.REPORTS_FETCH__FAILURE:
      return { ...state, error: payload.error };
    case ActionTypes.REPORTS_EXPORT_CLEAR:
      return { ...state, export: initialState.export };

    default:
      return state;
  }
};
