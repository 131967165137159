const DistrictRadioTelevisionActivities = (data, districts, contents) => {
  const dataReport = districts.map((district) => {
    const { KyThuat, SanXuatNoiDung, CongViecKhac } = district.NhanLuc;

    const workerTotal = KyThuat + SanXuatNoiDung + CongViecKhac;

    const contentTotal = contents.filter((c) => c.districtId === district.id).length;

    return {
      code: district.code,
      name: district.name,
      computer: district.ThietBiCongNghe.MayTinh,
      videoCamera: district.ThietBiCongNghe.MayQuayPhim,
      recorder: district.ThietBiCongNghe.MayThuAmKyThuatSo,
      camera: district.ThietBiCongNghe.MayAnh,
      softwareRadio: district.ThietBiCongNghe.PhanMemBienTapChuongTrinhPhatThanh,
      sofwareTelevision: district.ThietBiCongNghe.PhanMemBienTapChuongTrinhTruyenHinh,
      workerTotal,
      contentMember: SanXuatNoiDung,
      technicalMember: KyThuat,
      otherMember: CongViecKhac,
      contentRaio: contentTotal, // district.ChuongTrinhTuSanXuat.PhatThanh,
      contentTelevision: district.ChuongTrinhTuSanXuat.TruyenHinh,
      media: district.ChuongTrinhTuSanXuat.TruyenThongDaPhuongTien,
    };
  });

  const accomulator = dataReport.reduce(
    (acc, item) => {
      Object.keys(item).forEach((key) => {
        if (acc[key] !== undefined) {
          acc[key] += item[key];
        }
      });
      return acc;
    },
    {
      computer: 0,
      videoCamera: 0,
      recorder: 0,
      camera: 0,
      softwareRadio: 0,
      sofwareTelevision: 0,
      workerTotal: 0,
      contentMember: 0,
      technicalMember: 0,
      otherMember: 0,
      contentRaio: 0,
      contentTelevision: 0,
      media: 0,
    },
  );

  let html = `<div class="header">
  <h3>Bảng 2</h3>
  <p>SỐ LIỆU HOẠT ĐỘNG TRUYỀN THANH - TRUYỀN HÌNH CẤP HUYỆN</p>
  <p>Kỳ báo cáo: Quý ${data.quater} năm ${data.year.getFullYear()}.</p>
</div>
<table>
  <thead>
    <tr>
      <th rowspan="2">TT</th>
      <th rowspan="2" style="width: 10%">
        Mã định danh<br/> (Quyết định số 20/2020/QĐ-TTg ngày 22/7/2020 của Thủ tướng Chính phủ)
      </th>
      <th rowspan="2">Tên đơn vị hành chính</th>
      <th colspan="6">Thiết bị kỹ thuật, công nghệ phục vụ sản xuất nội dung chương trình</th>
      <th colspan="4">Nhân lực</th>
      <th colspan="3">Nội dung chương trình tự sản xuất (theo kỳ báo cáo)</th>
    </tr>
    <tr>
      <th>Số lượng máy tính</th>
      <th>Số lượng máy quay phim</th>
      <th>Số lượng máy thu âm kỹ thuật số</th>
      <th>Số lượng máy ảnh</th>
      <th>Số lượng phần mềm biên tập chương trình truyền hình</th>
      <th>Số lượng phần mềm biên tập chương trình phát thanh</th>
      <th>Tổng số người đã có (10=11+12+13)</th>
      <th>Số người thực hiện công việc liên quan đến sản xuất nội dung</th>
      <th>Số người thực hiện công việc liên quan đến kỹ thuật</th>
      <th>Số người làm công việc khác</th>
      <th>Số lượng chương trình phát thanh</th>
      <th>Số lượng chương trình truyền hình</th>
      <th>Các sản phẩm truyền thông đa phương tiện (Videoclip, Infographic,...)</th>
    </tr>
  </thead>
  <tbody>
    <tr style="font-style: italic; text-align: center">
      <td>1</td>
      <td>2</td>
      <td>3</td>
      <td>4</td>
      <td>5</td>
      <td>6</td>
      <td>7</td>
      <td>8</td>
      <td>9</td>
      <td>10</td>
      <td>11</td>
      <td>12</td>
      <td>13</td>
      <td>14</td>
      <td>15</td>
      <td>16</td>
    </tr>`;

  dataReport.forEach((it, index) => {
    html += `<tr style="text-align: center">
      <td>${index + 1}</td>
      <td style="text-align: left">${it.code}</td>
      <td style="text-align: left">${it.name}</td>
      <td>${it.computer}</td>
      <td>${it.videoCamera}</td>
      <td>${it.recorder}</td>
      <td>${it.camera}</td>
      <td>${it.softwareRadio}</td>
      <td>${it.sofwareTelevision}</td>
      <td>${it.workerTotal}</td>
      <td>${it.contentMember}</td>
      <td>${it.technicalMember}</td>
      <td>${it.otherMember}</td>
      <td>${it.contentRaio}</td>
      <td>${it.contentTelevision}</td>
      <td>${it.media}</td>
    </tr>`;
  });

  html += `<tr>
      <td colspan="3"><strong>Tổng cộng:</strong></td>
      <td style="text-align: center">${accomulator.computer}</td>
      <td style="text-align: center">${accomulator.videoCamera}</td>
      <td style="text-align: center">${accomulator.recorder}</td>
      <td style="text-align: center">${accomulator.camera}</td>
      <td style="text-align: center">${accomulator.softwareRadio}</td>
      <td style="text-align: center">${accomulator.sofwareTelevision}</td>
      <td style="text-align: center">${accomulator.workerTotal}</td>
      <td style="text-align: center">${accomulator.contentMember}</td>
      <td style="text-align: center">${accomulator.technicalMember}</td>
      <td style="text-align: center">${accomulator.otherMember}</td>
      <td style="text-align: center">${accomulator.contentRaio}</td>
      <td style="text-align: center">${accomulator.contentTelevision}</td>
      <td style="text-align: center">${accomulator.media}</td>
    </tr>
  </tbody>
</table>
<div class="footer">
  <p>
    <strong>1. Đơn vị cập nhật số liệu: </strong>Cơ sở truyền thanh - truyền hình cấp huyện.
  </p>
  <p>
    <strong>2. Yêu cầu trích xuất số liệu báo cáo theo các biểu mẫu: </strong><br />
    - Số huyện của tỉnh (thành phố trực thuộc TW); <br />
    - Số quận của tỉnh (thành phố trực thuộc TW);<br />
    - Số thị xã của tỉnh (thành phố trực thuộc TW);<br />
    - Số thành phố của tỉnh (thành phố trực thuộc TW).
  </p>
  <p>
    <strong>3. Thời hạn gửi báo cáo: </strong> <br />
    - Chậm nhất ngày 08 của tháng cuối quý đối với báo cáo định kỳ hằng quý; <br />
    - Chậm nhất ngày 27/11 đối với báo cáo định kỳ hằng năm.
  </p>
  </div>`;
  return html;
};

export default DistrictRadioTelevisionActivities;
