import { attr, fk } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

export default class extends BaseModel {
  static modelName = 'Action';

  static fields = {
    id: attr(),
    type: attr(),
    data: attr(),
    createdAt: attr({
      getDefault: () => new Date(),
    }),
    contentId: fk({
      to: 'Content',
      as: 'content',
      relatedName: 'actions',
    }),
    deviceId: fk({
      to: 'Device',
      as: 'device',
      relatedName: 'actions',
    }),
    stationId: fk({
      to: 'Station',
      as: 'station',
      relatedName: 'actions',
    }),
    userId: fk({
      to: 'User',
      as: 'user',
      relatedName: 'actions',
    }),
  };

  static reducer({ type, payload }, Action) {
    switch (type) {
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        if (payload.actions) {
          Action.all().delete();

          payload.actions.forEach((action) => {
            Action.upsert({
              ...action,
              isInDevice: false,
            });
          });
        }

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
        if (payload.actions) {
          Action.all().delete();

          payload.actions.forEach((action) => {
            Action.upsert({
              ...action,
              isInDevice: false,
            });
          });
        }

        break;
      case ActionTypes.ACTIONS_FETCH__SUCCESS:
        Action.all().delete();

        if (payload.actions) {
          payload.actions.forEach((action) => {
            Action.upsert(action);
          });
        }

        break;
      case ActionTypes.NOTIFICATION_CREATE_HANDLE:
        // Action.all().delete();

        payload.activities.forEach((action) => {
          Action.upsert(action);
        });

        break;
      default:
    }
  }
}
