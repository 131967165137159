import isEmail from 'validator/lib/isEmail';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Form, Grid, Header, Message } from 'semantic-ui-react';
import { useDidUpdate, usePrevious, useToggle } from '../../lib/hooks';
import { Input } from '../../lib/custom-ui';

import { useForm } from '../../hooks';
import { isUsername } from '../../utils/validator';

import QRCode from './QRCode';

import styles from './Login.module.scss';
import VerifyCode from './VerifyCode';

const createMessage = (error, isRetrieveQRCode) => {
  if (isRetrieveQRCode) {
    return {
      type: 'success',
      content: 'common.sentEmailSuccessfully',
    };
  }

  if (!error) {
    return error;
  }

  switch (error.message) {
    case 'Invalid email or username':
      return {
        type: 'error',
        content: 'common.invalidEmailOrUsername',
      };
    case 'Invalid password':
      return {
        type: 'error',
        content: 'common.invalidPassword',
      };
    case 'Failed to fetch':
      return {
        type: 'warning',
        content: 'common.noInternetConnection',
      };
    case 'Network request failed':
      return {
        type: 'warning',
        content: 'common.serverConnectionFailed',
      };
    case 'Invalid OTP':
    case 'Invalid OTP code':
      return {
        type: 'error',
        content: 'common.codeNotCorrect',
      };
    case 'OTP verification failed':
      return {
        type: 'error',
        content: 'common.anErrorOccuredDuringVerification',
      };

    case 'OTP code expired':
      return {
        type: 'error',
        content: 'common.codeIsExpired',
      };
    case 'Invalid token':
      return {
        type: 'error',
        content: 'common.informationIsInCorrect',
      };
    case 'Generate QR code failed':
    case 'Send email failed':
      return {
        type: 'error',
        content: 'common.occuredErrorWhenRetrieveQRCodeTryLater',
      };

    default:
      return {
        type: 'warning',
        content: 'common.unknownError',
      };
  }
};

const Login = React.memo(
  ({
    defaultData,
    isDarkMode,
    isInitial,
    isRetrieveQRCode,
    qrcode,
    isSubmitting,
    isVerifyCode,
    isQRCode,
    expires,
    error,
    onAuthenticate,
    onAuthenticateCode,
    onAuthenticateQRCode,
    onMessageDismiss,
    onRetrieveQRCode,
    onStopCountDown,
    onFetchCode,
  }) => {
    const [t] = useTranslation();
    const wasSubmitting = usePrevious(isSubmitting);

    const [data, handleFieldChange, setData] = useForm(() => ({
      emailOrUsername: '',
      password: '',
      ...defaultData,
      type: '',
    }));

    const message = useMemo(
      () => createMessage(error, isRetrieveQRCode),
      [error, isRetrieveQRCode],
    );

    const [focusPasswordFieldState, focusPasswordField] = useToggle();

    const emailOrUsernameField = useRef(null);
    const passwordField = useRef(null);

    const handleSubmit = useCallback(
      (type) => {
        setData((prev) => ({ ...prev, type }));

        const cleanData = {
          ...data,
          emailOrUsername: data.emailOrUsername.trim(),
        };

        if (!isEmail(cleanData.emailOrUsername) && !isUsername(cleanData.emailOrUsername)) {
          emailOrUsernameField.current.select();
          return;
        }

        if (!cleanData.password) {
          passwordField.current.focus();
          return;
        }

        // if (!cleanData.type) {
        //   return;
        // }

        if (type === 'qrcode') {
          onAuthenticateQRCode(cleanData);
        } else {
          onAuthenticateCode(cleanData);
        }

        // onAuthenticate(cleanData);
      },
      [
        data,
        setData,
        onAuthenticateCode,
        onAuthenticateQRCode,
        // onAuthenticate,
      ],
    );

    // const handleSelectAuthType = useCallback(
    //   (type) => {
    //     setData((prev) => ({ ...prev, type }));
    //   },
    //   [setData],
    // );

    useEffect(() => {
      emailOrUsernameField.current.focus();
    }, []);

    useEffect(() => {
      if (wasSubmitting && !isSubmitting && error) {
        switch (error.message) {
          case 'Invalid email or username':
            emailOrUsernameField.current.select();

            break;
          case 'Invalid password':
            setData((prevData) => ({
              ...prevData,
              password: '',
            }));
            focusPasswordField();

            break;
          default:
        }
      }
    }, [isSubmitting, wasSubmitting, error, setData, focusPasswordField]);

    useDidUpdate(() => {
      passwordField.current.focus();
    }, [focusPasswordFieldState]);

    return (
      <div className={classNames(styles.wrapper, styles.fullHeight)}>
        <Grid verticalAlign="middle" padded="horizontally" className={styles.fullHeightPaddingFix}>
          <Grid.Column
            widescreen={8}
            largeScreen={8}
            computer={8}
            tablet={16}
            mobile={16}
            // only="computer"
            className={classNames(styles.cover, styles.fullHeight)}
          >
            {/* <div className={styles.descriptionWrapperOverlay} /> */}
            <div className={styles.descriptionWrapper}>
              {/* <Header inverted as="h1" content="WowSpeaker" className={styles.descriptionTitle} /> */}
              {/* <Header
                inverted
                as="h2"
                content={t('common.provinceManagement')}
                className={styles.descriptionSubtitle}
              /> */}
            </div>
          </Grid.Column>
          <Grid.Column widescreen={8} largeScreen={8} computer={8} tablet={16} mobile={16}>
            <Grid verticalAlign="middle" className={styles.fullHeightPaddingFix}>
              <Grid.Column>
                <div className={styles.transition}>
                  <div
                    className={classNames(
                      styles.loginWrapper,
                      (isVerifyCode || isQRCode) && styles.toLeft,
                    )}
                  >
                    <Header
                      as="h1"
                      textAlign="left"
                      content={t('common.logInToWowSpeaker')}
                      className={styles.formTitle}
                    />
                    <div>
                      {message && (
                        <Message
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...{
                            [message.type]: true,
                          }}
                          visible
                          content={t(message.content)}
                          onDismiss={onMessageDismiss}
                        />
                      )}
                      <Form
                        size="large"
                        // onSubmit={handleSubmit}
                      >
                        <div className={styles.inputWrapper}>
                          <div className={styles.inputLabel}>{t('common.emailOrUsername')}</div>
                          <Input
                            fluid
                            ref={emailOrUsernameField}
                            name="emailOrUsername"
                            value={data.emailOrUsername}
                            readOnly={isSubmitting}
                            className={styles.input}
                            onChange={handleFieldChange}
                          />
                        </div>
                        <div className={styles.inputWrapper}>
                          <div className={styles.inputLabel}>{t('common.password')}</div>
                          <Input.Password
                            fluid
                            ref={passwordField}
                            name="password"
                            autoComplete="password"
                            value={data.password}
                            readOnly={isSubmitting}
                            className={styles.input}
                            onChange={handleFieldChange}
                          />
                        </div>
                        <div className={styles.inputWrapper}>
                          <Button
                            type="button"
                            className={classNames(
                              styles.buttonAuthType,
                              data.type === 'email' && styles.active,
                            )}
                            fluid
                            primary
                            content="Xác thực qua Email"
                            onClick={() => handleSubmit('email')}
                            loading={isSubmitting && data.type === 'email'}
                          />
                          <Button
                            type="button"
                            className={classNames(
                              styles.buttonAuthType,
                              data.type === 'qrcode' && styles.active,
                            )}
                            fluid
                            primary
                            content="Quét mã QR"
                            onClick={() => handleSubmit('qrcode')}
                            loading={isSubmitting && data.type === 'qrcode'}
                          />
                        </div>
                        {/* <Form.Button
                          size="large"
                          content={t('action.logIn')}
                          loading={isSubmitting}
                          disabled={isSubmitting || !data.type}
                          className={styles.button}
                        /> */}
                      </Form>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      styles.loginWrapper,
                      (isQRCode || isVerifyCode) && styles.toLeft,
                    )}
                  >
                    {isQRCode && (
                      <QRCode
                        isSubmitting={isSubmitting}
                        isInitial={isInitial}
                        isRetrieveQRCode={isRetrieveQRCode}
                        message={message}
                        qrcode={qrcode}
                        onMessageDismiss={onMessageDismiss}
                        onAuthenticate={onAuthenticate}
                        onRetrieveQRCode={onRetrieveQRCode}
                      />
                    )}
                    {isVerifyCode && (
                      <VerifyCode
                        isSubmitting={isSubmitting}
                        message={message}
                        onMessageDismiss={onMessageDismiss}
                        onAuthenticate={onAuthenticate}
                        expires={expires}
                        onStopCountDown={onStopCountDown}
                        isVerifyCode={isVerifyCode}
                        onFetchCode={onFetchCode}
                      />
                    )}
                  </div>
                </div>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      </div>
    );
  },
);

Login.propTypes = {
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isDarkMode: PropTypes.bool,
  isInitial: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  isRetrieveQRCode: PropTypes.bool,
  qrcode: PropTypes.string,
  isVerifyCode: PropTypes.bool.isRequired,
  isQRCode: PropTypes.bool.isRequired,
  expires: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  error: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onAuthenticate: PropTypes.func.isRequired,
  onAuthenticateCode: PropTypes.func.isRequired,
  onAuthenticateQRCode: PropTypes.func.isRequired,
  onMessageDismiss: PropTypes.func.isRequired,
  onRetrieveQRCode: PropTypes.func.isRequired,
  onStopCountDown: PropTypes.func.isRequired,
  onFetchCode: PropTypes.func.isRequired,
};

Login.defaultProps = {
  isDarkMode: false,
  expires: null,
  error: undefined,
  qrcode: null,
  isInitial: null,
  isRetrieveQRCode: null,
};

export default Login;
