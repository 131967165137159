import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Modal,
  Select,
  Message,
  Tab,
  TabPane,
  MenuItem,
  TransitionablePortal,
  Segment,
  Header,
  Dimmer,
  Icon,
} from 'semantic-ui-react';
import { pick } from 'lodash';
import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';

import { useForm } from '../../hooks';
import getParagraphs from '../../utils/docx';
import {
  MessageSchedule,
  NoticeMessages,
  SelectContentTypesOptions,
  StatusOptions,
} from '../../constants/Enums';
import ScheduleColors from '../../constants/ScheduleColors';
import { epochTime } from '../../utils/modify-time';
import {
  convertScheduleContent,
  getDuration,
  topicBasedOnSelectedLocations,
} from '../../utils/content-method';
import durationToTime from '../../utils/duration-to-time';
import Config from '../../constants/Config';

import TextToSpeech from '../Contents/TextToSpeech';
import AttachmentAddZone from './AttachmentAddZone';
import StepLocation from './StepLocation';
import StepSchedule from './StepSchedule';
import ReviewContent from './ReviewContent';
import Notice from './Notice';
import StepCreateContent from './StepCreateContent';
import LivestreamContainer from '../../containers/LivestreamContainer';

import styles from './ContentModalNew.module.scss';

const createMessage = (error) => {
  if (!error) {
    return error;
  }
  const er = error?.playlist?.find((it) => it.code > 0) || { msg: '' };

  switch (er.msg) {
    case 'Current login is invalid, please log in again':
      return {
        type: 'error',
        content: 'common.ttsCurrentLogin',
      };
    default:
      return {
        type: 'warning',
        content: 'common.unknownError',
      };
  }
};

const MIN_WIDTH = 600;

const checkWindowIsNotMobile = () => {
  const hasWindow = typeof window || undefined;

  if (hasWindow) {
    return window.innerWidth > MIN_WIDTH;
  }

  return true;
};

const checkNotExistContent = (payload, media, file) => {
  if (payload.NguonPhat === 'microphone') return true;

  if (payload.PhanLoai === 'bulletinBoard') {
    if (['text'].includes(payload.type) && !payload.NoiDung) {
      return false;
    }

    if (['stream'].includes(payload.type) && !payload.NoiDung && !file) {
      return false;
    }

    if (!['text', 'stream'].includes(payload.type) && !media) {
      return false;
    }
  }

  if (payload.PhanLoai === 'broadcasting' && payload.type === 'audio' && !media) {
    return false;
  }

  return true;
};

const isValidFileType = (fileType) => {
  const allowedTypes = [
    'audio/mpeg',
    'audio/wav',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];
  return allowedTypes.includes(fileType);
};

const ContentModal = React.memo(
  ({
    accessToken,
    currentArea,
    existScheduleInWards,
    broadcast,
    contentLocations,
    contentSchedules,
    province,
    selectedContentId,
    playlist,
    defaultData,
    isDarkMode,
    districts,
    wards,
    hamlets,
    schedules,
    devices,
    // districtId,
    // provinceId,
    attachments,
    allCategories,
    canEdit,
    isEditing,
    isSubmitting,
    error,
    contentId,
    attachment,
    onCreate,
    onUpdate,
    onDelete,
    onErrorClear,
    onText2Speech,
    onAttachmentCreate,
    onAttachmentUpdate,
    onAttachmentDelete,
    onClose,
    onPlaylistClear,
    onClearSelectedContentId,
    onLiveBroadcast,
    onLiveBroadcastStop,
    onClearBroadcast,
    onCloseMessageBroadcast,
    onControl,
  }) => {
    const [t] = useTranslation();

    const stepCreateContentChild = useRef();
    const notice = useRef();
    const warningChangeAttactment = useRef();

    const [isMobile, setIsMobile] = useState(checkWindowIsNotMobile());

    const [myFile, setMyFile] = useState();
    const [isOpenTTSModal, handleOpenTTSModal] = useState();
    const [tabsActive, setTabsActive] = useState(isEditing ? [0, 1, 2, 3] : [0]);
    const [currentTab, setCurrentTab] = useState(0);
    const [openAlertPortal, setOpenAlertPortal] = useState(false);
    const [prevScheduleArea, setPrevScheduleArea] = useState(existScheduleInWards);
    const [openReviewContent, setOpenReviewContent] = useState(false);
    const [openLiveStream, setOpenLiveStream] = useState(false);
    const [alertMessage, setAlertMessage] = useState(false);
    const [areasReceived, setAreasReceived] = useState({
      provinces: province,
      districts: [],
      wards: [],
      hamlets: [],
      devices: [],
      ...contentLocations,
    });

    const [data, handleFieldChange, setData] = useForm(() => ({
      color: ScheduleColors[0],
      status: 'new',
      TacGia: {
        TenDayDu: '',
        ButDanh: '',
        Email: '',
      },
      ThongTinChiTietBanTin: [],
      TiepAm: 1,
      NguonTiepAm: '',
      duration: 0,
      link: '',
      TenLichPhat: '',
      ...defaultData,
      DanhSachNgayPhat: [],
      // DanhSachNgayPhat: convertScheduleContent(contentSchedules, schedules) || [],
      MucDoUuTien: defaultData.MucDoUuTien || 3,
      NguonPhat: defaultData.type === 'stream' ? 'normal' : '',
      NoiDungTomTat: '',
      NoiDung: defaultData.NoiDung || '',
      PhanLoai: defaultData.PhanLoai || 'broadcasting',
      VungPhat: defaultData.VungPhat || 0,
      deletedSchedules: [],
    }));

    const [time, setTime] = useState(
      defaultData.duration ? durationToTime(defaultData.duration, true) : '00:00:00',
    );

    const [attachmentDeleteId, setAttachmentDeleteId] = useState();
    const [concatFile, setConcatFile] = useState();

    const audioContent = useMemo(() => {
      if (myFile && data.PhanLoai === 'broadcasting' && myFile.type.includes('audio/')) {
        return URL.createObjectURL(myFile);
      }

      // if (playlist.length > 0) {
      //   const url = playlist[0];
      //   return url;
      // }

      // if (data.file?.length > 0) {
      //   const url = data.file[0];
      //   return url;
      // }

      if (concatFile) {
        const { url } = concatFile;
        return url;
      }

      if (attachment) {
        const { url } = attachment;
        return url;
      }

      if (attachments.length > 0) {
        const { url } = attachments[0];
        return url;
      }

      return undefined;
    }, [
      attachments,
      attachment,
      concatFile,
      // data.file,
      myFile,
      data.PhanLoai,
      // playlist
    ]);

    const handleCloseReviewContent = useCallback(() => {
      setOpenReviewContent(false);
    }, []);

    const handleCloseLiveStream = useCallback(() => {
      setOpenLiveStream(false);
      if (broadcast.status === 'played') {
        onClose();
        onErrorClear();
        onClearBroadcast();
      }
    }, [broadcast.status, onClose, onClearBroadcast, onErrorClear]);

    const handleCloseAlertPortal = useCallback(() => {
      setOpenAlertPortal(false);
    }, []);

    const handleSubmit = useCallback(
      (url) => {
        const { topic, location } = topicBasedOnSelectedLocations(areasReceived, data.MucDoUuTien);
        const cleanData = {
          ...data,
          name: data.name.trim(),
          color:
            data.categories.length > 0
              ? allCategories.find((it) => it.id === data.categories[0]).color
              : data.color,
          description: data.description?.trim() || undefined,
          type: data.type?.trim() || undefined,
          // file:
          //   isEditing && myFile && myFile.type.includes('audio') // eslint-disable-line no-nested-ternary
          //     ? []
          //     : playlist.length > 0
          //     ? playlist
          //     : undefined,
          fileDirname: data.fileDirname?.trim() || undefined,
          duration: data.duration || undefined,
          categories: data.categories || undefined,
          PhanLoai: data.PhanLoai,
          NoiDung: data.NoiDung || undefined,
          NguonPhat: data.NguonPhat || undefined,
          VungPhat: String(data.VungPhat) || undefined,
          MucDoUuTien: String(data.MucDoUuTien),
          DanhSachNgayPhat: epochTime(data.DanhSachNgayPhat),
          DanhSachDiaBanNhan: location,
          topic,
          TiepAm: data.PhanLoai === 'broadcasting' ? data.TiepAm : 1,
          NguonTiepAm: data.PhanLoai === 'broadcasting' ? data.NguonTiepAm : undefined,
          link: data.link || undefined,
          TenLichPhat: data.TenLichPhat || data.name.trim(),
          deletedSchedules: data.deletedSchedules.length > 0 ? data.deletedSchedules : undefined,
          ...(url && typeof url === 'string' && { link: url }),
          ...(currentArea.level === 'district' && { districtId: currentArea.location.id }),
          ...(currentArea.level === 'ward' && { wardId: currentArea.location.id }),
          ...(data.status !== 'draft' &&
            data.DanhSachNgayPhat.length > 0 && { sendStatus: 'sent' }),
          attachmentId: attachment?.id || concatFile?.id,
          attachmentName: concatFile?.name || undefined,
        };

        if (isEditing) {
          onUpdate(cleanData.id, cleanData);
          if (myFile) {
            onAttachmentCreate({
              file: myFile,
              contentId: cleanData.id,
            });
          }

          if (attachmentDeleteId) {
            onAttachmentDelete(attachmentDeleteId);
          }
          // onErrorClear();
          // onClose();
        } else {
          onCreate(cleanData);
          setOpenReviewContent(false);
        }
      },
      [
        currentArea.level,
        currentArea.location.id,
        areasReceived,
        allCategories,
        data,
        isEditing,
        myFile,
        attachment?.id,
        concatFile,
        attachmentDeleteId,
        onAttachmentDelete,
        onAttachmentCreate,
        // onClose,
        onCreate,
        // onErrorClear,
        onUpdate,
        // playlist,
      ],
    );

    const handleConfirmData = useCallback(() => {
      if (!data.name || !data.type || data.categories.length <= 0 || !data.description) {
        if (stepCreateContentChild.current) {
          stepCreateContentChild.current.handleValidateField();
        } else {
          notice.current.handleAlertTrigger(NoticeMessages.notEnoughInfor);
        }
        return;
      }

      if (data.MucDoUuTien !== 1 && !data.TenLichPhat && currentArea.level === 'ward') {
        notice.current.handleAlertTrigger(NoticeMessages.noScheduleName);
        return;
      }

      const isExitedContent = checkNotExistContent(
        pick(data, ['NguonPhat', 'PhanLoai', 'NoiDung', 'type', 'description']),
        audioContent,
        myFile,
      );

      if (!isExitedContent || (attachmentDeleteId && !myFile && !concatFile && !attachment)) {
        notice.current.handleAlertTrigger(NoticeMessages.noContent);
        return;
      }

      const locations = areasReceived.wards.map((w) => w.id);

      if (locations.length <= 0) {
        setAlertMessage(MessageSchedule.NO_AREA);
        setOpenAlertPortal(true);
        return;
      }

      const isNotIncludedSchedule =
        data.DanhSachNgayPhat.length <= 0 ||
        data.DanhSachNgayPhat.every((s) => s.ThoiDiemPhat.length <= 0);

      if (isNotIncludedSchedule && currentArea.level === 'ward') {
        setAlertMessage(MessageSchedule.NO_SCHEDULE);
        setOpenAlertPortal(true);
        return;
      }

      const schedulesConflict = data.DanhSachNgayPhat.filter((it) => it.already);

      const schedulesFinish = contentSchedules.filter((it) => it.playStatus === 'finish');

      if (schedulesConflict.length > 0 && schedulesFinish.length <= 0) {
        setAlertMessage(MessageSchedule.CONFLICT_SCHEDULE);
        setOpenAlertPortal(true);

        return;
      }

      if (isEditing) {
        if (
          !data.name ||
          !data.type ||
          data.categories.length <= 0 ||
          !data.description ||
          !data.duration ||
          (data.TacGia.Email && !isEmail(data.TacGia.Email)) ||
          (data.link && !isURL(data.link))
        ) {
          if (stepCreateContentChild.current) {
            stepCreateContentChild.current.handleValidateField();
          } else {
            notice.current.handleAlertTrigger(NoticeMessages.notEnoughInfor);
          }
          return;
        }
        handleSubmit();
        return;
      }

      if (data.MucDoUuTien === 1) {
        setOpenLiveStream(true);
      } else {
        setOpenReviewContent(true);
      }
    }, [
      areasReceived.wards,
      currentArea.level,
      myFile,
      concatFile,
      attachment,
      audioContent,
      data,
      isEditing,
      attachmentDeleteId,
      contentSchedules,
      handleSubmit,
    ]);

    const message = useMemo(() => createMessage(error), [error]);

    const handleFileSelect = useCallback(
      async (file) => {
        if (['audio/mpeg', 'audio/wav'].includes(file.type)) {
          const bytes = file.size;
          const k = 1024;
          const i = Math.floor(Math.log(bytes) / Math.log(k));

          const fileSize = (bytes / k ** i).toFixed(2);

          if (+fileSize >= Config.FILE_SIZE) {
            stepCreateContentChild.current.handleLargeFileSize('common.fileSizeLargerThanLimit');
            return;
          }
        }

        if (
          file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const content = e.target.result;
            const paragraphs = getParagraphs(content);

            if (data.PhanLoai === 'bulletinBoard' && ['text', 'stream'].includes(data.type)) {
              handleFieldChange(_, { name: 'NoiDung', value: paragraphs.join('\n') });
            }
            handleFieldChange(_, { name: 'description', value: paragraphs.join('\n') });
          };

          reader.onerror = (err) => console.error(err);

          reader.readAsBinaryString(file);
        } else {
          // if (playlist.length > 0) {
          if (attachment) {
            onPlaylistClear();
            onAttachmentDelete(attachment.id);
          }

          if (concatFile) {
            setConcatFile();
            onAttachmentDelete(concatFile.id);
          }

          if (attachments.length > 0) {
            setAttachmentDeleteId(attachments[0].id);
          }

          handleFieldChange(_, { name: 'NoiDung', value: '' });
        }

        setMyFile(file);
        stepCreateContentChild.current.handleLargeFileSize('');

        if ((file.type.includes('audio') || file.type.includes('video')) && !data.duration) {
          const duration = await getDuration(file);

          handleFieldChange(_, {
            name: 'duration',
            value: Math.ceil(duration),
          });

          setTime(durationToTime(Math.ceil(duration), true));
        }
      },
      [
        // playlist,
        attachments,
        attachment,
        concatFile,
        data.duration,
        data.PhanLoai,
        data.type,
        // defaultData.id,
        setAttachmentDeleteId,
        handleFieldChange,
        onPlaylistClear,
        onAttachmentDelete,
        // onClose
      ],
    );

    const handleAttachmentCreate = useCallback(
      ({ file }) => {
        if (data.type === 'audio' && isValidFileType(file.type)) {
          const handleWarningAttachment = (currentAttachment) => {
            warningChangeAttactment.current.handleWarningChangeAttachment({
              updateAttachment: file,
              attachment: currentAttachment,
              onUpdate: () => handleFileSelect(file),
            });
          };

          if (attachment) {
            handleWarningAttachment(attachment);

            return;
          }

          if (concatFile) {
            handleWarningAttachment(concatFile);
            return;
          }

          if (myFile) {
            handleWarningAttachment(myFile);
            return;
          }

          if (attachments.length > 0) {
            handleWarningAttachment(attachments[0]);

            return;
          }

          handleFileSelect(file);
        }
      },
      [attachments, attachment, concatFile, myFile, data.type, handleFileSelect],
    );

    const handleCloseIcon = useCallback(
      (e) => {
        if (isEditing || e.target.className.includes('close')) {
          if (attachment) {
            onPlaylistClear();
            onAttachmentDelete(attachment.id);
          }

          if (concatFile) {
            setConcatFile();
            onAttachmentDelete(concatFile.id);
          }

          if (error || defaultData.duration > 0) {
            onErrorClear();
          }

          if (selectedContentId) {
            onClearSelectedContentId('');
          }

          if (broadcast.status) {
            onClearBroadcast();
          }
          onClose();
        }
      },
      [
        broadcast.status,
        defaultData.duration,
        selectedContentId,
        isEditing,
        attachment,
        concatFile,
        error,
        setConcatFile,
        onClose,
        onErrorClear,
        onPlaylistClear,
        onClearSelectedContentId,
        onClearBroadcast,
        onAttachmentDelete,
      ],
    );

    const handleAttachmentDelete = useCallback((id) => {
      setAttachmentDeleteId(id);
    }, []);

    const handleSelectCategories = useCallback(
      (categories) => {
        setData((prev) => ({ ...prev, categories: categories.slice(-1) }));
      },
      [setData],
    );

    const handleChangePriorityLevel = useCallback(
      (_, { value }) => {
        if (value !== 1) {
          if (isEditing) {
            setTabsActive((prev) => [...prev, 3]);
          }
          // if (data.type === 'stream') {
          //   handleFieldChange(_, { name: 'type', value: '' });
          // }
          handleFieldChange(_, { name: 'NguonPhat', value: '' });
        } else {
          setTabsActive((prev) => prev.filter((num) => num !== 3));
          handleFieldChange(_, { name: 'NguonPhat', value: 'normal' });
          handleFieldChange(_, { name: 'status', value: 'new' });
        }

        handleFieldChange(_, { name: 'MucDoUuTien', value });
      },
      [
        isEditing,
        // data.type,
        handleFieldChange,
      ],
    );

    const handleNextStep = useCallback(() => {
      const nextIndex = currentTab + 1;
      if (nextIndex > 3) return;

      const isExitedContent = checkNotExistContent(
        pick(data, ['NguonPhat', 'PhanLoai', 'NoiDung', 'type', 'description']),
        audioContent,
        myFile,
      );

      if (currentTab === 1) {
        if (
          !data.name ||
          !data.type ||
          data.categories.length <= 0 ||
          !data.description ||
          !data.duration ||
          (data.TacGia.Email && !isEmail(data.TacGia.Email)) ||
          (data.type === 'stream' &&
            data.PhanLoai === 'broadcasting' &&
            data.NguonPhat === 'normal' &&
            !isURL(data.link))
        ) {
          stepCreateContentChild.current.handleValidateField();
          return;
        }

        if (!isExitedContent) {
          notice.current.handleAlertTrigger(NoticeMessages.noContent);
          return;
        }
      }

      const locations = areasReceived.wards.map((w) => w.id);

      if (currentTab === 2) {
        if (
          !data.name ||
          !data.type ||
          data.categories.length <= 0 ||
          !data.description ||
          (data.type === 'stream' &&
            data.PhanLoai === 'broadcasting' &&
            data.NguonPhat === 'normal' &&
            !isURL(data.link))
        ) {
          notice.current.handleAlertTrigger(NoticeMessages.notEnoughInfor);
          return;
        }

        if (!isExitedContent) {
          notice.current.handleAlertTrigger(NoticeMessages.noContent);
          return;
        }

        if (locations.length <= 0) {
          notice.current.handleAlertTrigger(NoticeMessages.chooseArea);
          return;
        }

        if (data.MucDoUuTien === 1) {
          setOpenLiveStream(true);

          return;
        }

        const locationIds = [];
        const pickScheduleOfLocation = [];

        areasReceived.wards.forEach((ward) => {
          locationIds.push(ward.id);
        });

        if (locationIds.length > 0) {
          const locationScheduleIds = schedules.map((l) => l.wardId);

          locationIds.forEach((locationId) => {
            if (locationScheduleIds.includes(locationId)) {
              const filterSchedules = schedules.filter((ls) => ls.wardId === locationId);

              pickScheduleOfLocation.push(...filterSchedules);
            }
          });
        }

        setPrevScheduleArea(pickScheduleOfLocation);
      }

      setTabsActive((prev) => (prev.includes(nextIndex) ? prev : [...prev, nextIndex]));
      setCurrentTab(nextIndex);
    }, [
      // playlist,
      areasReceived.wards,
      schedules,
      audioContent,
      myFile,
      currentTab,
      data,
    ]);

    const handleTabChange = useCallback((_, { activeIndex }) => {
      setCurrentTab(activeIndex);
    }, []);

    const handleSelectSchedule = useCallback(
      (calendar) => {
        setData((prev) => ({
          ...prev,
          // repeatRule: calendar.repeatRule,
          DanhSachNgayPhat: calendar.schedules,
        }));
      },
      [setData],
    );

    const handleSelectContentType = useCallback(
      (_, { name, value }) => {
        setData((prev) => ({ ...prev, [name]: value, type: '', NoiDung: '' }));
        setMyFile();
        if (attachment) {
          onPlaylistClear();
        }
      },
      [setData, attachment, onPlaylistClear],
    );

    const handleSelectTypeOfContent = useCallback(
      (_, { name, value }) => {
        if (data.PhanLoai === 'bulletinBoard' && !['text', 'stream'].includes(value)) {
          setMyFile();
        }

        if (data.PhanLoai === 'broadcasting' && ['stream'].includes(value)) {
          setMyFile();
          setConcatFile();
        }

        if (value === 'stream') {
          setTabsActive([0, 1, 2]);
        }

        setData((prev) => ({
          ...prev,
          ...{
            [name]: value,
            NoiDung: ['video', 'image'].includes(value) ? '' : prev.NoiDung,
            status: value === 'stream' ? 'new' : prev.status,
            // MucDoUuTien: value === 'stream' ? 1 : 3,
            // NguonPhat: value === 'stream' ? 'normal' : '',
          },
        }));
      },
      [data.PhanLoai, setData],
    );

    const handleChangeAuthor = useCallback(
      (_, { name, value }) => {
        setData((prev) => ({ ...prev, TacGia: { ...prev.TacGia, [name]: value } }));
      },
      [setData],
    );

    const handleChangeContentDetails = useCallback(
      (values, index) => {
        setData((prev) => {
          const newDetails =
            typeof index === 'number'
              ? prev.ThongTinChiTietBanTin.filter((_, idx) => idx !== index)
              : [...prev.ThongTinChiTietBanTin, values];

          return {
            ...prev,
            ThongTinChiTietBanTin: newDetails,
          };
        });
      },
      [setData],
    );

    const handleChangeDuration = useCallback(
      (_, { value }) => {
        if (value.match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/)) {
          const [hour, minute, second] = value.split(':').map(Number);

          const toDuration = hour * 3600 + minute * 60 + second;
          handleFieldChange(_, { name: 'duration', value: toDuration });
        }

        setTime(value);
      },
      [handleFieldChange],
    );

    const handleSelectDeletedSchedule = useCallback(
      (items) => {
        if (isEditing) {
          setData((prev) => ({
            ...prev,
            deletedSchedules: [...prev.deletedSchedules, ...items],
          }));
        }
      },
      [isEditing, setData],
    );

    const handleSelectSource = useCallback(
      (_, { name, value }) => {
        if (value === 'microphone') {
          setMyFile();
        }
        handleFieldChange(_, { name, value });
      },
      [handleFieldChange],
    );

    const handleSelectConcatFile = useCallback(
      (file) => {
        if (myFile) {
          setMyFile();
        }

        if (attachment) {
          onPlaylistClear();
          onAttachmentDelete(attachment.id);
        }
        setConcatFile(file);
      },
      [myFile, attachment, onPlaylistClear, onAttachmentDelete],
    );

    const handleRemoveConcatFile = useCallback(() => {
      if (concatFile) {
        onAttachmentDelete(concatFile.id);
        setConcatFile();
      }
    }, [concatFile, setConcatFile, onAttachmentDelete]);

    const handleRemoveT2S = useCallback(() => {
      if (attachment) {
        onAttachmentDelete(attachment.id);
        onPlaylistClear();
      }
    }, [attachment, onAttachmentDelete, onPlaylistClear]);

    useEffect(() => {
      if (defaultData.duration >= 0) {
        setTime(durationToTime(defaultData.duration, true));
        setData((prev) => ({ ...prev, duration: defaultData.duration }));
      }
    }, [defaultData.duration, setData]);

    useEffect(() => {
      if (contentId) {
        if (!isEditing) {
          if (myFile) {
            onAttachmentCreate({
              file: myFile,
              contentId,
            });
          }
        }
        if (data.NguonPhat !== 'microphone') {
          onClose();
        }
        onErrorClear();
      }
    }, [data.NguonPhat, isEditing, contentId, myFile, onAttachmentCreate, onClose, onErrorClear]);

    /* Check if the user's device is a mobile */
    useEffect(() => {
      const handleResize = (e) => {
        setIsMobile(window.innerWidth > MIN_WIDTH);
      };

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
      if (attachment && myFile) {
        setMyFile(null);
      }
    }, [attachment, myFile]);

    useEffect(() => {
      if (isEditing) {
        setData((prev) => ({
          ...prev,
          ...defaultData,
          DanhSachNgayPhat: convertScheduleContent(contentSchedules, schedules) || [],
        }));
        setAreasReceived((prev) => ({ ...prev, ...contentLocations }));
        setPrevScheduleArea(existScheduleInWards);
      }
    }, [
      isEditing,
      defaultData,
      contentLocations,
      contentSchedules,
      schedules,
      existScheduleInWards,
      setData,
      setAreasReceived,
      setPrevScheduleArea,
    ]);

    const panes = [
      {
        menuItem: <MenuItem key={0}>{t('common.selectContentType')}</MenuItem>,
        render: () => (
          <TabPane attached={false} className={styles.tabPane}>
            <Select
              disabled={isEditing}
              fluid
              options={SelectContentTypesOptions.map((it) => ({
                ...it,
                text: t(`common.${it.text}`),
              }))}
              value={data.PhanLoai}
              name="PhanLoai"
              onChange={handleSelectContentType}
            />
          </TabPane>
        ),
      },
      {
        menuItem: (
          <MenuItem key={1} disabled={!tabsActive.includes(1)}>
            {t('common.createContentDetail')}
          </MenuItem>
        ),
        render: () => (
          <TabPane attached={false} className={styles.tabPane}>
            <StepCreateContent
              ref={stepCreateContentChild}
              warningRef={warningChangeAttactment}
              token={accessToken}
              attachments={attachments.filter((at) => at.id !== attachmentDeleteId)}
              categories={allCategories}
              data={data}
              isSubmitting={isSubmitting}
              isEditing={isEditing}
              myFile={myFile}
              playlist={playlist}
              attachment={attachment}
              time={time}
              concatFile={concatFile}
              onChange={handleFieldChange}
              onFileSelect={handleFileSelect}
              onDeleteMyFile={() => setMyFile()}
              onDeleteAttachment={handleAttachmentDelete}
              onSelectCategories={handleSelectCategories}
              onText2Speech={onText2Speech}
              onSelectPriorityLevel={handleChangePriorityLevel}
              onSelectContentType={handleSelectTypeOfContent}
              onChangeAuthor={handleChangeAuthor}
              onChangeContentDetails={handleChangeContentDetails}
              onChangeDuration={handleChangeDuration}
              onSelectSource={handleSelectSource}
              onSelectConcatFile={handleSelectConcatFile}
              onRemoveConcatFile={handleRemoveConcatFile}
              onRemoveT2S={handleRemoveT2S}
            />
          </TabPane>
        ),
      },
      {
        menuItem: (
          <MenuItem key={2} disabled={!tabsActive.includes(2)}>
            {t('common.selectArea')}
          </MenuItem>
        ),
        render: () => (
          <TabPane attached={false} className={styles.tabPane}>
            <StepLocation
              isMobile={isMobile}
              priority={data.MucDoUuTien}
              districts={districts}
              wards={wards}
              hamlets={hamlets}
              devices={devices}
              areasReceived={areasReceived}
              onCloseAlertPortal={handleCloseAlertPortal}
              onSetAreasReceived={setAreasReceived}
            />
          </TabPane>
        ),
      },
      {
        menuItem: (
          <MenuItem
            key={3}
            disabled={!tabsActive.includes(3) || (data.MucDoUuTien === 1 && isEditing)}
          >
            {t('common.selectSchedule')}
          </MenuItem>
        ),
        render: () => (
          <TabPane attached={false} className={styles.tabPane}>
            <StepSchedule
              defaultData={data}
              locations={areasReceived.wards}
              schedules={prevScheduleArea}
              onSelect={handleSelectSchedule}
              onChange={handleFieldChange}
              onSelectDeletedSchedules={handleSelectDeletedSchedule}
            />
          </TabPane>
        ),
      },
    ];

    return (
      <>
        <AttachmentAddZone isDarkMode={isDarkMode} onCreate={handleAttachmentCreate}>
          <Modal
            open
            closeIcon
            centered={false}
            onClose={handleCloseIcon}
            className={styles.wrapper}
          >
            <Modal.Header size="huge">
              {t(
                // eslint-disable-next-line no-nested-ternary
                isEditing
                  ? canEdit
                    ? 'common.editContent'
                    : 'common.viewContent'
                  : 'common.createContent',
                {
                  context: 'title',
                },
              )}
            </Modal.Header>
            <Modal.Content className={styles.positionRelative}>
              <Notice ref={notice} />
              {message && (
                <Message
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...{
                    [message.type]: true,
                  }}
                  visible
                  content={t(message.content)}
                  onDismiss={onErrorClear}
                />
              )}
              <Form>
                <Tab
                  activeIndex={currentTab}
                  onTabChange={handleTabChange}
                  menu={{ secondary: true }}
                  panes={panes}
                />
                <div className={styles.actionWrapper}>
                  <Select
                    disabled={data.type === 'stream' && isEditing}
                    name="status"
                    options={StatusOptions.map((it) => ({
                      ...it,
                      disabled:
                        (!['new', 'draft'].includes(it.key) && !isEditing) ||
                        (it.key === 'draft' && isEditing && data.sendStatus === 'sent'),
                      text: t(`common.${it.key}`),
                    }))}
                    label={{ children: t('common.status'), htmlFor: 'form-select-control-status' }}
                    value={data.status}
                    placeholder={t('common.status')}
                    onChange={handleFieldChange}
                  />
                  {currentTab < 3 && !isEditing && (
                    <Button
                      className={styles.nextButton}
                      color="teal"
                      // icon="arrow right"
                      type="button"
                      // labelPosition="right"
                      content={t(`action.next`)}
                      onClick={handleNextStep}
                    />
                  )}
                  {(tabsActive.length === 4 || isEditing) && data.MucDoUuTien !== 1 && canEdit && (
                    <Button
                      color="green"
                      icon="checkmark"
                      type="button"
                      content={t('action.save')}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={handleConfirmData}
                    />
                  )}
                </div>
              </Form>
            </Modal.Content>
          </Modal>
        </AttachmentAddZone>
        <TransitionablePortal onClose={handleCloseAlertPortal} open={openAlertPortal}>
          <Segment className={styles.alertSchedule}>
            {alertMessage && (
              <>
                <Header color="red">{t(alertMessage[0])}</Header>
                <p style={{ fontWeight: 'bold' }}>{t(alertMessage[1])}</p>
                <small>{t('common.pressOutToCloseNotification')}</small>
              </>
            )}
          </Segment>
        </TransitionablePortal>
        {openReviewContent && (
          <ReviewContent
            data={{ ...data, myFile, playlist, attachments, attachment, concatFile }}
            wards={wards}
            areasReceived={areasReceived.wards}
            categories={allCategories}
            onCancel={handleCloseReviewContent}
            onConfirm={handleSubmit}
          />
        )}
        {openLiveStream && (
          <LivestreamContainer
            data={{ ...data, myFile, playlist, attachments, attachment, concatFile }}
            areasReceived={areasReceived}
            onUpdateData={setData}
            onClose={handleCloseLiveStream}
            onCreate={handleSubmit}
          />
        )}
        {isOpenTTSModal && <TextToSpeech setMyFile={setMyFile} onClose={handleOpenTTSModal} />}
        <TransitionablePortal
          onClose={onCloseMessageBroadcast}
          open={broadcast.isFinished && !broadcast.isStop}
        >
          <Modal
            size="mini"
            centered={false}
            open={broadcast.isFinished && !broadcast.isStop}
            onClose={onCloseMessageBroadcast}
            style={{ textAlign: 'center', top: '30%' }}
          >
            <Modal.Content>
              <Header color="blue" as="h2">
                <Icon name="check circle" />
                {t('common.broadcastCompletedContent')}
              </Header>
              <strong>{t('common.pressOutToCloseNotification')}</strong>
            </Modal.Content>
          </Modal>
        </TransitionablePortal>
      </>
    );
  },
);

ContentModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  playlist: PropTypes.arrayOf(PropTypes.any),
  currentArea: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  selectedContentId: PropTypes.string.isRequired,
  existScheduleInWards: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  broadcast: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isDarkMode: PropTypes.bool.isRequired,
  contentLocations: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  contentSchedules: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  province: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  districts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  wards: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  hamlets: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  schedules: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  devices: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  // districtId: PropTypes.string.isRequired,
  // provinceId: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  attachments: PropTypes.array.isRequired,
  allCategories: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  isEditing: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  contentId: PropTypes.string,
  attachment: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  accessToken: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onErrorClear: PropTypes.func.isRequired,
  onText2Speech: PropTypes.func.isRequired,
  onAttachmentCreate: PropTypes.func.isRequired,
  onAttachmentUpdate: PropTypes.func.isRequired,
  onAttachmentDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onPlaylistClear: PropTypes.func.isRequired,
  onClearSelectedContentId: PropTypes.func.isRequired,
  onLiveBroadcast: PropTypes.func.isRequired,
  onLiveBroadcastStop: PropTypes.func.isRequired,
  onClearBroadcast: PropTypes.func.isRequired,
  onCloseMessageBroadcast: PropTypes.func.isRequired,
  onControl: PropTypes.func.isRequired,
};

ContentModal.defaultProps = {
  playlist: [],
  error: null,
  contentId: null,
  attachment: undefined,
  contentLocations: undefined,
  contentSchedules: undefined,
};

export default ContentModal;
