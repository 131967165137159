import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Confirm, Image, Label, Select } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { pickDayOfWeek } from '../../../utils/custom-repeat';
import { timeToString } from '../../../utils/modify-time';
import { BroadcastingZone, PriorityTypes, RelaySourceOptions } from '../../../constants/Enums';
import { Markdown } from '../../../lib/custom-ui';
import { pickLocationSelected } from '../../../utils/content-method';
import durationToTime from '../../../utils/duration-to-time';

import styles from './ReviewContent.module.scss';

const ReviewContent = React.memo(
  ({ areasReceived, data, categories, wards, onCancel, onConfirm }) => {
    const [t] = useTranslation();

    const wardFilter = pickLocationSelected(areasReceived, wards);

    const audioContent = useMemo(() => {
      if (data.myFile) {
        const fileExt = data.myFile.type;

        if (fileExt.includes('audio') || fileExt.includes('image') || fileExt.includes('video')) {
          return URL.createObjectURL(data.myFile);
        }
      }

      if (data.concatFile) {
        return data.concatFile.url;
      }

      if (data.attachment) {
        return data.attachment.url;
      }

      if (data.link) {
        return data.link;
      }

      return undefined;
    }, [data.myFile, data.concatFile, data.attachment, data.link]);

    const broadcastingZone =
      data.PhanLoai === 'bulletinBoard' &&
      BroadcastingZone.find(({ value }) => value === data.VungPhat);

    const streamElement = useCallback((file) => {
      const url = URL.createObjectURL(file);

      if (file.type.includes('audio')) {
        return (
          <audio controls>
            <source src={url} />
            <track kind="captions" />
          </audio>
        );
      }

      if (file.type.includes('video')) {
        return <iframe src={url} width="100%" height="300" title="Video" />;
      }

      if (file.type.includes('image')) {
        return <Image src={url} height={300} alt="Content Image" />;
      }

      return undefined;
    }, []);

    /* eslint-disable no-nested-ternary */
    const mediaContentNode =
      data.type === 'video' ? (
        <iframe src={audioContent} width="100%" height="300" title={audioContent} />
      ) : data.type === 'image' ? (
        <Image src={audioContent} height={300} title="Content Image" />
      ) : data.type === 'stream' && data.PhanLoai === 'bulletinBoard' ? (
        streamElement(data.myFile)
      ) : (
        <audio controls>
          <source src={audioContent} />
          <track kind="captions" />
        </audio>
      );

    const priority = PriorityTypes.find((p) => p.value === data.MucDoUuTien);
    const relay = RelaySourceOptions.find((p) => p.value === data.TiepAm);

    const detailContentNode = data.NoiDung ? (
      <Markdown linkTarget="_blank">{data.NoiDung}</Markdown>
    ) : (
      mediaContentNode
    );

    const contentNode = (
      <div className={styles.content}>
        <div className={styles.field}>
          <span className={styles.fieldName}>{t('common.priorityLevel')}</span>
          <span>{t(`common.${priority.text}`)}</span>
        </div>
        <div className={styles.field}>
          <span className={styles.fieldName}>{t('common.contentName')}</span>
          <span>{data.name}</span>
        </div>
        <div className={styles.field}>
          <span className={styles.fieldName}>{t('common.nameSchedule')}</span>
          <span>{data.TenLichPhat}</span>
        </div>
        {broadcastingZone && (
          <div className={styles.field}>
            <span className={styles.fieldName}>{t('common.broadcastingZone')}</span>
            <span>{t(`common.${broadcastingZone.key}`)}</span>
          </div>
        )}
        <div className={styles.field}>
          <span className={styles.fieldName}>{t('common.status')}</span>
          <span>{t(`common.${data.status}`)}</span>
        </div>
        <div className={styles.field}>
          <span className={styles.fieldName}>{t('common.duration')}</span>
          <span>{durationToTime(data.duration)}</span>
        </div>
        <div className={styles.field}>
          <span className={styles.fieldName}>{t('common.relay')}</span>
          <span>{t(`common.${relay.key}`)}</span>
        </div>
        <div className={styles.field}>
          <span className={styles.fieldName}>{t('common.relaySource')}</span>
          <span>{data.NguonTiepAm}</span>
        </div>
        <div className={styles.field}>
          <span className={styles.fieldName}>{t('common.contentType')}</span>
          <span>{`Bản tin ${t(`common.${data.type}`)}`}</span>
        </div>
        <div className={styles.field}>
          <span className={styles.fieldName}>{t('common.content')}</span>
          <div style={{ overflowX: 'hidden', overflowY: 'auto' }}>
            {detailContentNode || t('common.noAudioContent')}
          </div>
        </div>
        <div className={styles.field}>
          <span className={styles.fieldName}>{t('common.category')}</span>
          <div className={styles.listCategories}>
            {categories.map((cate) =>
              data.categories.includes(cate.id) ? (
                <span
                  key={cate.id}
                  style={{ backgroundColor: cate.color }}
                  className={styles.category}
                >
                  {cate.name}
                </span>
              ) : (
                ''
              ),
            )}
          </div>
        </div>
        <div className={styles.field}>
          <span className={styles.fieldName}>{t('common.broadcastingArea')}</span>
          <div className={styles.wrapperWard}>
            {wardFilter.map((w) => (
              <Label key={w.id} content={w.name} color="teal" className={styles.labelWard} />
            ))}
          </div>
        </div>
        <div className={styles.field}>
          <span className={styles.fieldName}>{t('common.releaseDate')}</span>
          <div className={styles.listTime}>
            {data.DanhSachNgayPhat.filter((it) => it.ThoiDiemPhat.length > 0).map((it) => (
              <div className={styles.field} key={it.id}>
                <Label
                  color="teal"
                  pointing="right"
                  content={pickDayOfWeek(it.NgayPhat, 'ccc, dd/MM/yyyy')}
                />
                <div>
                  {it.ThoiDiemPhat.map((time) => (
                    <Label className={styles.marginBottomFive} key={time.ThoiGianBatDau}>
                      {timeToString(time)}
                    </Label>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.field}>
          <span className={styles.fieldName}>{t('common.contentDetails')}</span>
          <div className={styles.wrapperWard}>
            {React.Children.toArray(
              data.ThongTinChiTietBanTin.map((it) => (
                <Label
                  style={{ marginBottom: 4 }}
                  color="teal"
                  content={`${it.Ten} - ${it.GiaTri}`}
                />
              )),
            )}
          </div>
        </div>
        <div className={styles.field}>
          <span className={styles.fieldName}>{t('common.author')}</span>
          <div>
            <Label size="large">
              <span className={styles.marginRightTen}>{t('common.fullname')}:</span>
              {data.TacGia.TenDayDu}
            </Label>
            <Label size="large">
              <span className={styles.marginRightTen}>{t('common.pseudonym')}:</span>
              {data.TacGia.ButDanh}
            </Label>
            <Label size="large">
              <span className={styles.marginRightTen}>Email:</span>
              {data.TacGia.Email}
            </Label>
          </div>
        </div>
      </div>
    );

    return (
      <Confirm
        className={styles.wrapper}
        centered={false}
        header={`${t('common.informationContent')} ${t(`common.${data.PhanLoai}`)}`}
        content={contentNode}
        open
        onCancel={onCancel}
        onConfirm={onConfirm}
        size="large"
        cancelButton={t('action.cancel')}
        confirmButton={t('action.confirm')}
      />
    );
  },
);

export default ReviewContent;

ReviewContent.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  areasReceived: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  wards: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  categories: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
