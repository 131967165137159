import socket from './socket';

/* Actions */

const createUserGroup = (data, headers) => socket.post(`/user-groups`, data, headers);

const getUserGroups = (data, headers) => socket.get(`/user-groups`, data, headers);

const updateUserGroup = (id, data, headers) => socket.patch(`/user-groups/${id}`, data, headers);

const deleteUserGroup = (id, headers) => socket.delete(`/user-groups/${id}`, undefined, headers);

export default {
  createUserGroup,
  getUserGroups,
  updateUserGroup,
  deleteUserGroup,
};
