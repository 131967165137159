import dateFns from 'date-fns/locale/vi';

export default {
  dateFns,
  format: {
    date: 'd/M/yyyy',
    time: 'HH:mm',
    dateTime: '$t(format:date) $t(format:time)',
    longDate: 'MMM d',
    longDateTime: "MMMM d 'at' p",
  },

  translation: {
    common: {
      aboutWowSpeaker: 'Về WowSpeaker',
      account: 'Tài khoản',
      actions: 'Thao tác',
      activated: 'Kích hoạt',
      active: 'Chạy',
      activity: 'Hoạt động',
      activityDetails: 'Chi tiết hoạt động',
      addAttachment_title: 'Thêm file',
      addComment: 'Thêm bình luận',
      address: 'Địa chỉ',
      addManager_title: 'Thêm quản lý',
      addMember_title: 'Thêm thành viên',
      addUser_title: 'Thêm tài khoản',
      addUserGroup_title: 'Thêm nhóm người dùng',
      administrator: 'Quản lý',
      admin: 'Quản lý',
      after: 'Sau',
      afterPeriod: 'Sau thời gian(s)',
      all: 'Tất cả',
      allChangesWillBeAutomaticallySavedAfterConnectionRestored:
        'Thay đổi sẽ được tự động lưu<br />sau khi có lại kết nối',
      anErrorOccured: 'Đã xảy ra lỗi. Vui lòng thử lại sau.',
      softwareVersion: 'Phiên bản phần mềm',
      approved: 'Đã duyệt',
      areas: 'Địa bàn',
      areaCancelSchedule: 'Địa bàn hủy lịch phát',
      areaCreated: 'Địa bàn tạo',
      areaDeleted: 'Địa bàn đã xóa',
      areaUpdated: 'Địa bàn cập nhật',
      areYouSureYouWantToCancelThisSchedule: 'Bạn có muốn hủy lịch phát không?',
      areYouSureYouWantToDeleteThisAttachment: 'Bạn có muốn xóa file không?',
      areYouSureYouWantToDeleteThisContentCannotUndo:
        'Bạn có chắc muốn xóa bản tin {{content}}? Bạn sẽ không thể hoàn tác những thay đổi.',
      areYouSureYouWantToDeleteThisDistrict: 'Bạn có muốn xóa quận/huyện không?',
      areYouSureYouWantToDeleteThisDevice: 'Bạn có muốn xóa cụm loa không?',
      areYouSureYouWantToDeleteThisDeviceCannotUndo:
        'Bạn có chắc muốn xóa cụm loa {{device}}? Bạn sẽ không thể hoàn tác những thay đổi.',
      areYouSureYouWantToDeleteThisDisplayCannotUndo:
        'Bạn có chắc muốn xóa màn hình {{display}}? Bạn sẽ không thể hoàn tác những thay đổi.',
      areYouSureYouWantToDeleteThisComment: 'Bạn có muốn xóa bình luận không?',
      areYouSureYouWantToDeleteThisCategory: 'Bạn có muốn xóa loại lĩnh vực không?',
      areYouSureYouWantToDeleteThisContent: 'Bạn có muốn xóa bản tin không?',
      areYouSureYouWantToDeleteThisDate: 'Bạn có muốn xóa ngày này không?',
      areYouSureYouWantToDeleteThisHamlet: 'Bạn có muốn xóa thôn/ tổ không?',
      areYouSureYouWantToDeleteThisStation: 'Bạn có muốn xóa đài không?',
      areYouSureYouWantToDeleteThisLog: 'Bạn có muốn xóa nhật ký này không?',
      areYouSureYouWantToDeleteThisStationCannotUndo:
        'Bạn có chắc muốn xóa đài truyền thanh {{station}}? Bạn sẽ không thể hoàn tác những thay đổi.',
      areYouSureYouWantToDeleteThisProvince: 'Bạn có muốn xóa tỉnh/thành phố không?',
      areYouSureYouWantToDeleteThisSchedule: 'Bạn có muốn xóa lịch không?',
      areYouSureYouWantToDeleteThisTask: 'Bạn có muốn xóa việc không?',
      areYouSureYouWantToDeleteThisUser: 'Bạn có muốn xóa người dùng không?',
      areYouSureYouWantToDeleteThisUserGroup: 'Bạn có muốn xóa người nhóm dùng không?',
      areYouSureYouWantToDeleteThisWard: 'Bạn có muốn xóa xã/phường/thị trấn không?',
      areYouSureYouWantToLeaveDistrict: 'Bạn có muốn rời khỏi quận/huyện không?',
      areYouSureYouWantToLeaveProvince: 'Bạn có muốn xóa tỉnh/thành phố không?',
      areYouSureYouWantToLeaveWard: 'Bạn có muốn rời khỏi xã/phường/thị trấn không?',
      areYouSureYouWantToRemoveThisManagerFromProvince: 'Bạn có muốn xóa quản lý này?',
      areYouSureYouWantToRemoveThisMemberFromDistrict: 'Bạn có muốn xóa thành viên này?',
      areYouSureYouWantToRemoveThisMemberFromWard: 'Bạn có muốn xóa thành viên này?',
      areYouSureYouWantToRestartThisDevice: 'Bạn có chắc muốn khởi động lại thiết bị?',
      areYouSureYouWantToReplaceThisFile: 'Bạn có chắc muốn thay thế tệp này?',
      appearance: 'lần xuất hiện',
      attachment: 'Đính kèm',
      attachments: 'Đính kèm',
      atLeastFourCharacters: 'Độ dài ít nhất 4 từ',
      audio: 'Âm thanh',
      authentication: 'Tài khoản',
      author: 'Tác giả',
      avatar: 'Ảnh đại diện',
      avatarDescription: 'Dung lượng file tối đa 1Mb. Định dạng JPEG, PNG',
      background: 'Hình nền',
      broadcastCompletedContent: 'Bản tin đã phát xong',
      broadcasting: 'Phát thanh',
      broadcastingArea: 'Địa bàn phát',
      broadcastingZone: 'Vùng phát',
      broadcastScheduleHasNotBeenSelected: 'Lịch phát chưa được chọn',
      bulletinBoard: 'Bảng tin điện tử công cộng',
      cancelSchedule: 'Hủy lịch phát',
      canceled: 'Đã hủy',
      cannotDeleteThisStation:
        'Không thể xóa đài truyền thanh {{station}}. Đã thiết lập thiết bị cho đài này.',
      canComment: 'Có thể bình luận',
      canEditContentOfDistrict: 'Có thể sửa bản tin của quận huyện',
      canEditContentOfWard: 'Có thể sửa bản tin của xã phường thị trấn',
      canOnlyUnderFiveThousandsCharacters: 'Độ dài không vượt quá 10000 từ',
      canOnlyViewDistrict: 'Chỉ xem được quận huyện',
      canOnlyViewWard: 'Chỉ xem được xã phường thị trấn',
      category: 'Loại lĩnh vực',
      category_title: 'Loại lĩnh vực',
      categoryInUse: 'Loại lĩnh vực đã được thiết lập',
      character: 'Ký tự',
      change: 'Thay đổi',
      classification: 'Phân loại',
      code: 'Mã',
      confirmPassword: 'Xác nhận mật khẩu',
      connectStatus: 'Trạng thái kết nối',
      content: 'Nội dung',
      contentCancelSchedule: 'Bản tin xóa lịch phát',
      contentDeleted: 'Bản tin đã xóa',
      contentUpdated: 'Bản tin cập nhật',
      contents: 'Bản tin',
      contentBroadcast: 'Bản tin phát thanh',
      contentPlayed: 'Bản tin đã phát',
      contentName: 'Tên bản tin',
      contentType: 'Loại bản tin',
      contentNotPlay: 'Bản tin chưa phát',
      contentLive: 'Bản tin tiếp sóng',
      contentDetails: 'Thông tin chi tiết bản tin',
      color: 'Màu',
      controlDevice: 'Điều khiển thiết bị',
      controlMultiDevice: 'Điều khiển thiết bị',
      controlVolume: 'Điều khiển âm lượng',
      convertTextToSpeech_title: 'Chuyển đổi văn bản thành giọng nói',
      createdIn: 'Địa bàn tạo',
      createDevice: 'Tạo thiết bị',
      createContent: 'Tạo bản tin',
      createSchedule: 'Tạo lịch phát',
      createStation: 'Tạo đài truyền thanh',
      createContentDetail: 'Tạo nội dung bản tin',
      createDistrict_title: 'Thêm Quận/huyện',
      createCategory_title: 'Thêm Loại lĩnh vực',
      createContent_title: 'Thêm Bản tin',
      createHamlet_title: 'Thêm Thôn/ tổ',
      createNewOneOrSelectExistingOne: 'Tạo mới hoặc chọn sẵn có',
      createProvince_title: 'Thêm Tỉnh thành',
      createSchedule_title: 'Thêm Lịch phát',
      createStation_title: 'Thêm Đài truyền thanh',
      createTextFile_title: 'Thêm Tiêu đề file',
      createWard_title: 'Thêm Xã/phường/thị trấn',
      createUser: 'Tạo người dùng',
      createUserGroup: 'Tạo nhóm người dùng',
      creator: 'Người tạo',
      currentPassword: 'Mật khẩu hiện tại',
      custom: 'Tùy chỉnh',
      daily: 'Hàng ngày',
      dangerZone_title: 'Nguy hiểm',
      date: 'Ngày',
      dateActive: 'Ngày bắt đầu hoạt động',
      day: 'Ngày',
      deleteDevice: 'Xóa thiết bị',
      deleteContent: 'Xóa bản tin',
      deleteContentLocation: 'Xóa địa bàn bản tin',
      deleteStation: 'Xóa đài truyền thanh',
      dueDate_title: 'Hạn',
      deleteAttachment_title: 'Xóa Đính kèm',
      deleteCategory_titile: 'Xoá Loại lĩnh vực',
      deleteDate_title: 'Xóa ngày',
      deleteDistrict_title: 'Xóa Quận/huyện',
      deleteDevice_title: 'Xóa Thiết bị',
      deleteComment_title: 'Xóa Bình luận',
      deleteContent_title: 'Xóa Bản tin',
      deleteHamlet_title: 'Xóa Thôn/ tổ',
      deleteLog_title: 'Xóa Nhật ký',
      deleteStation_title: 'Xóa Đài truyền thanh',
      deleteProvince_title: 'Xóa Tỉnh thành',
      deleteTask_title: 'Xóa Việc',
      deleteWard_title: 'Xóa xã/phường/thị trấn',
      deleteUser: 'Xóa người dùng',
      deleteUserGroup: 'Xóa nhóm người dùng',
      deleted: 'Xóa',
      deleteSchedule: 'Xóa lịch phát',
      deleteUser_title: 'Xóa Người dùng',
      deleteUserGroup_title: 'Xóa Nhóm người dùng',
      description: 'Miêu tả',
      detail: 'Chi tiết',
      detectAutomatically: 'Theo hệ thống',
      des: 'Mô tả',
      deviceCode: 'Mã thiết bị',
      deviceCreated: 'Thiết bị đã tạo',
      deviceDeleted: 'Thiết bị đã xóa',
      deviceInformation: 'Thông tin thiết bị',
      deviceName: 'Tên thiết bị',
      deviceType: 'Loại thiết bị',
      devices: 'Thiết bị',
      deviceActions_title: 'Thao tác thiết bị',
      deviceNotFound_title: 'Không tìm thấy thiết bị',
      deviceOrActionAreDeleted: 'Thiết bị hoặc thao tác đã xóa',
      deviceNotSelected: 'Thiết bị chưa được chọn',
      deviceUpdated: 'Thiết bị cập nhật',
      disconnect: 'Ngắt',
      display: 'Màn hình',
      district: 'Quận-huyện',
      districtLevelUsers: 'Người dùng cấp huyện',
      districtNotFound_title: 'Không tìm thấy quận huyện',
      draft: 'Nháp',
      dropFileToUpload: 'Thả file để tải lên',
      duration: 'Thời lượng',
      editAttachment_title: 'Sửa Đính kèm',
      editAvatar_title: 'Sửa Avatar',
      editContent_title: 'Sửa Bản tin',
      editCategory_title: 'Sửa Loại lĩnh vực',
      editDevice_title: 'Sửa Thiết bị',
      editDistrict_title: 'Sửa Quận/huyện',
      editDueDate_title: 'Sửa hạn',
      editEmail_title: 'Sửa E-mail',
      editHamlet_title: 'Sửa Thôn/ tổ',
      editInformation_title: 'Sửa Thông tin',
      editIdentifierSIM_title: 'Sửa Mã định danh/ Số SIM',
      editor: 'Biên tập viên',
      editPassword_title: 'Sửa Mật khẩu',
      editPermissions_title: 'Sửa quyền hạn',
      editSchedule: 'Sửa lịch phát',
      editStation_title: 'Chỉnh sửa thông tin',
      editStopwatch_title: 'Sửa Đếm giờ',
      editUsername_title: 'Sửa Tài khoản',
      editWard_title: 'Sửa Xã/phường/thị trấn',
      editUserGroup_title: 'Sửa nhóm người dùng',
      electricBoard: 'Bảng tin điện tử',
      electronicBulletinBoardNews: 'Bản tin bảng tin điện tử',
      email: 'E-mail',
      emailAlreadyInUse: 'E-mail đã tồn tại',
      emailInvalid: 'Email không hợp lệ',
      emergencyBroadcasting: 'Phát bản tin phát thanh khẩn cấp',
      emergencyBulletinBoard: 'Phát bản tin bảng tin điện tử công cộng khẩn cấp',
      emptyPermission: 'Chưa chọn quyền',
      end: 'Kết thúc',
      enterDeviceTitle: 'Nhập tên thiết bị... nhấn [Ctrl+Enter] để nhập chi tiết',
      enterDescription: 'Sửa mô tả...',
      enterFilename: 'Nhập tên file',
      enterOrClickIcon: 'Nhập hoặc nhấn biểu tượng để chọn vị trí',
      enterQuater: 'Nhập quý',
      enterStationTitle: 'Nhập tên Đài...',
      enterProvinceTitle: 'Nhập tên Tỉnh/thành',
      enterTaskDescription: 'Nhập miêu tả việc...',
      executionIp: 'Ip thực hiện',
      handleFileWaitingForASeconds: 'Đang {{message}} tệp tin... Vui lòng chờ trong giây lát',
      fileMP3: 'File',
      fileSizeLargerThanLimit: 'Kích thước têp vượt quá  {{fileSize}} MB',
      filterByContents_title: 'Lọc theo Bản tin',
      filterByMembers_title: 'Lọc theo Thành viên',
      filters: 'Bộ lọc',
      fifth: 'thứ năm',
      fileSize: 'Dung lượng(KB)',
      fromComputer_title: 'Từ Máy tính',
      fromTrello: 'Từ Trello',
      fromDate: 'Từ ngày',
      fourth: 'thứ tư',
      general: 'Chung',
      hamlet: 'Thôn-tổ',
      hasRelay: 'Có tiếp âm',
      hours: 'Giờ',
      ICTBasedRadioStation: 'Đài truyền thanh ứng dụng CNTT',
      identifierSIM_title: 'Mã định danh/ Số SIM',
      implementationAccount: 'Tài khoản thực hiện',
      importDistrict_title: 'Nhập Quận/huyện',
      inactive: 'Không hoạt động',
      informationContent: 'Thông tin bản tin',
      initial: 'Ban đầu',
      integratedDevice: 'Thiết bị tích hợp',
      invalidCurrentPassword: 'Sai mật khẩu hiện tại',
      joinMultiFiles: 'Ghép nhiều tệp tin',
      joinMultiFilesAudio: 'Ghép nhiều tệp tin âm thanh',
      connecting: 'Đang kết nối',
      language: 'Ngôn ngữ',
      latitude: 'Vĩ độ',
      leaveDistrict_title: 'Rời khỏi Quận/huyện',
      leaveProvince_title: 'Rời khỏi Tỉnh thành',
      leaveWard_title: 'Rời khỏi Xã/phường/thị trấn',
      leftZone: 'Vùng bên trái',
      link: 'Đường dẫn',
      linkLiveStream: 'Đường dẫn âm thanh trực tiếp',
      location: 'Vị trí',
      locationInformation: 'Thông tin vị trí',
      locationInUse: 'Địa bạn này đã được thiết lập, không thể xóa.',
      longitude: 'Kinh độ',
      file: 'Tệp',
      filterByRegion: 'Lọc theo khu vực',
      finish: 'Hoàn thành',
      first: 'thứ nhất',
      fullname: 'Tên đầy đủ',
      fullScreen: 'Toàn màn hình',
      identifier: 'Mã định danh',
      image: 'Hình ảnh',
      manageAccountProfileInformation: 'Quản lý thông tin hồ sơ',
      managers: 'Quản lý',
      members: 'Thành viên',
      microphone: 'Microphone',
      minutes: 'Phút',
      month: 'Tháng',
      monthly: 'Hàng tháng',
      monthlyOnDate: 'Hàng tháng vào ngày',
      moveDevice_title: 'Chuyển Thiết bị',
      myAccount: 'Tài khoản của tôi',
      name: 'Tên',
      nameCategory: 'Tên loại lĩnh vực',
      nameContent: 'Tên bản tin',
      nameDistrict: 'Tên quận/huyện',
      nameFile: 'Tên tệp tin',
      nameHamlet: 'Tên thôn/ tổ',
      nameRecord: 'Tên bản ghi',
      nameStation: 'Tên đài truyền thanh',
      nameWard: 'Tên xã/phường/thị trấn',
      nameSchedule: 'Tên lịch phát',
      newEmail: 'Email mới',
      newPassword: 'Mật khẩu mới',
      needMircophonePermission: 'Cần quyền sử dụng micro',
      newsNumber: 'Số bản tin: ',
      newsletterContent: 'Nội dung bản tin',
      newsletterRecording_title: 'Ghi âm bản tin',
      newUsername: 'Tài khoản mới',
      never: 'Không bao giờ',
      nextTenSeconds: 'Tới 10s',
      noAudioContent: 'Chưa có nội dung bản tin',
      noBroadcastSchedules: 'Chưa có lịch phát.',
      noConnectionToServer: 'Mất kết nối đến máy chủ',
      noDistricts: 'Không có quận/huyện',
      noProvinces: 'Không có tỉnh thành',
      noRelay: 'Không tiếp âm',
      noRepeat: 'Không lặp lại',
      noSignal: 'Không phát sóng',
      new: 'Tạo mới',
      normal: 'Thông thường',
      noResultsFound: 'Không tìm thấy kết quả',
      noStations: 'Không có đài',
      notEnoughInformation: 'Chưa nhập đủ thông tin',
      notifications: 'Thông báo',
      notificationZone: 'Vùng thông báo',
      notFoundDistricts: 'Không tìm thấy quận/huyện',
      notFoundWards: 'Không tìm thấy xã/phường/thị trấn',
      notFoundHamlets: 'Không tìm thấy thôn/tổ',
      notFoundStations: 'Không tìm thấy đài truyền thanh',
      notYetSetUp: 'Chưa thiết lập',
      noUnreadNotifications: 'Không có thông báo mới',
      onDate: 'Vào ngày',
      openDistrict_title: 'Mở Quận/huyện',
      operationOfTheDevice: 'Hoạt động của thiết bị',
      option: 'Tùy chọn',
      optional_inline: 'tùy chọn',
      organization: 'Đơn vị công tác',
      otherInformation: 'Thông tin khác',
      pause: 'Dừng',
      pauseCurrentlyPlayingContent: 'Tạm dừng nội dung đang phát',
      pending: 'Đang chờ',
      permissions: 'Quyền',
      phone: 'Điện thoại',
      play: 'Phát',
      playing: 'Đang phát',
      played: 'Đã phát',
      playCount: 'Lần phát',
      pleaseChooseBroadcastSchedule: 'Vui lòng chọn lịch phát',
      pleaseChooseAnotherBroadcastSchedule: 'Vui lòng chọn lịch phát khác',
      pleaseChooseBroadcastArea: 'Vui lòng chọn địa bàn nhận',
      pleaseSelectFilesInTheOrderToBePlayed: 'Vui lòng chọn tệp tin theo thứ tự cần phát',
      position: 'Chức vụ',
      preferences: 'Tùy chỉnh',
      pressPasteShortcutToAddAttachmentFromClipboard:
        'Mẹo: nhấn Ctrl-V (Cmd-V trên Mac) để thêm đính kèm từ clipboard',
      pressOutToCloseNotification: '(Nhấn ra ngoài để đóng thông báo)',
      prevTenSeconds: 'Lùi 10s',
      priority: 'Ưu tiên',
      priorityLevel: 'Mức độ ưu tiên',
      province: 'Tỉnh/thành',
      provinceLevelUsers: 'Người dùng cấp tỉnh',
      provinceNotFound_title: 'Không có Tỉnh/thành',
      pseudonym: 'Bút danh',
      publish: 'Công khai',
      quantity: 'Số lượng',
      quaterNotSelected: 'Chưa chọn quý',
      radioNews: 'Bản tin đài truyền thanh',
      ratio: 'Tỷ lệ: ',
      read: 'Đã đọc',
      receivingArea: 'Địa bàn nhận',
      receivingAreaHasNotBeenSelected: 'Chưa chọn địa bàn nhận',
      relay: 'Tiếp âm',
      relaySource: 'Nguồn tiếp âm',
      releaseDate: 'Ngày phát',
      removeManager_title: 'Xóa Quản lý',
      removeMember_title: 'Xóa Thành viên',
      repeatMode: 'Lặp lại',
      repeatOn: 'Lặp lại vào',
      repeatEach: 'Lặp lại mỗi',
      repeatRuleCustom: 'Lặp lại tùy chỉnh',
      reportTemplate: 'Mẫu báo cáo',
      reportTemplateNotSelected: 'Chưa chọn mẫu báo cáo',
      resumePreviouslyStoppedContent: 'Phát  tiếp  nội  dung dừng trước đó',
      role: 'Phân quyền',
      restartDevice: 'Khởi động lại thiết bị',
      restartDevice_title: 'Khởi động lại thiết bị',
      rightZone: 'Vùng bên phải',
      scheduleCreator: 'Người tạo lịch phát',
      scheduleNameHasNotBeenEnterd: 'Tên lịch phát chưa nhập',
      screen: 'Bảng tin điện tử',
      searchContents: 'Tìm Bản tin...',
      searchCategory: 'Tìm loại lĩnh vực',
      searchMembers: 'Tìm Thành viên...',
      searchUsers: 'Tìm người dùng...',
      second: 'thứ hai',
      seconds: 'Giây',
      securitySetting: 'Cài đặt tài khoản',
      selectAll: 'Chọn tất cả',
      selectedTimeIsInConflict: 'Thời gian đã được chọn',
      selectArea: 'Chọn địa bàn',
      selectCategory: 'Chọn loại lĩnh vực',
      selectControl: 'Chọn loại điều khiển',
      selectContent: 'Chọn bản tin',
      selectContentType: 'Chọn loại bản tin',
      selectDistrict: 'Chọn quận/huyện',
      selectDeviceLocation: 'Chọn vị trí thiết bị',
      selectHamlet: 'Chọn thôn/ tổ',
      selectLevel: 'Chọn cấp',
      selectStation: 'Chọn đài',
      selectStatus: 'Chọn trạng thái',
      selectPermissions_title: 'Chọn quyền hạn',
      selectProvince: 'Chọn tỉnh/thành',
      selectReportTemplate: 'Chọn mẫu báo cáo',
      selectWard: 'Chọn xã/phường',
      selectFile: 'Chọn files',
      selectAnotherFile: 'Chọn files khác',
      selectStationLocation: 'Chọn vị trí đài truyền thanh',
      selectLocation: 'Chọn vị trí',
      selectSchedule: 'Chọn lịch phát',
      selectSource: 'Chọn địa bàn tạo',
      selectPermissions: 'Chọn quyền',
      selectUser: 'Chọn người dùng',
      selectUserGroup: 'Chọn nhóm người dùng',
      selectedDevices: 'Thiết bị đã chọn',
      selectedScheduleAlreadyExists: 'Lịch phát được chọn đã tồn tại',
      settings: 'Cài đặt',
      setupDeviceControl: 'Thiết lập điều khiển thiết bị',
      scheduleDeleted: 'Lịch phát đã xóa',
      scheduleUpdated: 'Lịch phát đã cập nhật',
      schedules: 'Lịch phát',
      sim4GInformation: 'Thông tin SIM 4G',
      simNumber: 'Số thuê bao SIM',
      simSerial: 'Số serial SIM',
      sixth: 'thứ sáu',
      source: 'Nguồn phát',
      speed: 'Tốc độ',
      speakers: 'Cụm loa',
      speaker: 'Cụm loa',
      speakersElectronicBoardIntegratedDevice: '(Cụm loa/ Bảng tin điện tử/ Thiết bị tích hợp)',
      start: 'Bắt đầu',
      status: 'Trạng thái',
      stationCreated: 'Đài truyền thanh đã tạo',
      stationDeleted: 'Đài truyền thanh đã xóa',
      stationUpdated: 'Đài truyền thanh đã cập nhật',
      stations: 'Đài truyền thanh',
      stationsBroadcastContents: 'DS đài truyền thanh đã phát "{{content}}"',
      stationActions_title: 'Thao tác Đài',
      stationAt: 'Đài truyền thanh tại',
      stream: 'Trực tiếp',
      stopwatch: 'Đếm giờ',
      stopAllContent: 'Dừng phát tất cả các bản tin',
      stopsCurrentContentAndMoveToNextContentIfItExists:
        'Dừng  phát  bản  tin hiện   thời   đang   phát, chuyển sang bản tin kế tiếp nếu trong lịch phát vẫn còn',
      stopPlayingContent: 'Dừng phát nội dung',
      subscribeToMyOwnDevicesByDefault: 'Mặc định theo dõi thiết bị tôi tạo',
      summaryContent: 'Nội dung tóm tắt',
      supplierCode: 'Mã nhà cung cấp',
      supplierName: 'Tên nhà cung cấp',
      taskActions_title: 'Thao tác công việc',
      tasks: 'Việc cần làm',
      temperature: 'Nhiệt độ',
      text: 'Ký tự',
      text2speech: 'Chuyển đổi sang âm thanh',
      thereIsNoPreviewAvailableForThisAttachment: 'Không có bản xem trước của đính kèm',
      time: 'Thời gian',
      timeCreate: 'Thời gian tạo',
      timeSlot: 'Khung giờ',
      timeStart: 'Thời gian bắt đầu',
      timeEnd: 'Thời gian kết thúc',
      title: 'Tiêu đề',
      third: 'thứ ba',
      today: 'Hôm nay',
      toDate: 'Đến ngày',
      totalFileSizeDoNotExceedTheLimit:
        'Tổng kích thước tệp: {{fileSize}} (Giới hạn kích thước tệp: {{limit}} MB)',
      totalFileSizeExceedsLimit: 'Tổng kích thước tệp vượt quá giới hạn',
      transmitting: 'Đang phát sóng',
      transmissionPaused: 'Tạm dừng phát sóng',
      transmissionOff: 'Không phát sóng',
      tryListening: 'Nghe thử',
      ttsCurrentLogin: 'Chưa đăng nhập tts',
      turnOn: 'Bật',
      turnOff: 'Tắt',
      type: 'Loại',
      unapproved: 'Chưa duyệt',
      unread: 'Chưa đọc',
      updateContent: 'Cập nhật bản tin',
      updateContentLocation: 'Cập nhật địa bàn bản tin',
      updateDevice: 'Cập nhật thiết bị',
      updateSchedule: 'Cập nhật lịch phát',
      updateStation: 'Cập nhật đài truyền thanh',
      updateUserGroup: 'Cập nhật nhóm người dùng',
      urgent: 'Khẩn cấp',
      unknownError: 'Đã xảy ra lỗi, hãy thử lại',
      userActions_title: 'Thao tác người dùng',
      userAddedThisDeviceToStation: '<0>{{user}}</0><1> đã thêm thiết bị vào đài {{station}}</1>',
      userAddLocationToContent:
        '<0>{{user}}</0> thêm địa bàn <2>{{wards}}</2> cho bản tin {{content}}',
      userBroadcast: 'Người phát',
      userLevel_title: 'Cấp độ người dùng',
      userCancelScheduleOfContent:
        '<0>{{user}}</0> đã hủy lịch phát của bản tin <2>{{content}}</2> tại {{wards}}',
      userCreateContentToWards:
        '<0>{{user}}</0> tạo mới bản tin <2>{{priority}}</2> <3>{{content}}</3> cho {{wards}}',
      userCreated: 'Người dùng đã tạo',
      userDeleted: 'Người dùng đã xóa',
      userCreateDeviceToStation:
        '<0>{{user}}</0> tạo mới thiết bị <2>{{device}}</2> cho đài {{station}}',
      userCreateStationToStation:
        '<0>{{user}}</0> tạo mới đài truyền thanh <2>{{station}}</2> cho {{ward}}',
      userControlModeToSpeaker:
        '<0>{{user}}</0> thiết lập <2>{{mode}}</2> cho thiết bị <4>{{device}}</4>',
      userControlModeToSpeakers:
        '<0>{{user}}</0> thiết lập <2>{{mode}}</2> cho thiết bị <4>{{devices}}</4>',
      userDeleteContentAtWards: '<0>{{user}}</0> xóa bản tin <2>{{content}}</2> tại {{wards}}',
      userDeleteLocationToContent:
        '<0>{{user}}</0> xóa địa bàn <2>{{wards}}</2> khỏi bản tin {{content}}',
      userDeleteScheduleToContent:
        '<0>{{user}}</0> xóa lịch phát bản tin <2>{{content}}</2> khỏi {{wards}}',
      userDeleteDeviceAtStation:
        '<0>{{user}}</0> xóa thiết bị <2>{{device}}</2> tại đài {{station}}',
      userDeleteStationAtWard:
        '<0>{{user}}</0> xóa đài truyền thanh <2>{{station}}</2> tại {{ward}}',
      userLeftNewCommentToDevice:
        '{{user}} đã bình luận «{{comment}}» trong thiết bị <2>{{device}}</2>',
      userMovedDeviceFromStationToStation:
        '{{user}} đã chuyển thiết bị <2>{{device}}</2> từ đài {{fromStation}} đến {{toStation}}',
      userMovedThisDeviceFromStationToStation:
        '<0>{{user}}</0><1> đã chuyển thiết bị này từ đài {{fromStation}} đến {{toStation}}</1>',
      userUpdateInfoDeviceAtStation:
        '<0>{{user}}</0> cập nhật thông tin thiết bị <2>{{device}}</2> tại đài {{station}}',
      userUpdateInfoStationAtWard:
        '<0>{{user}}</0> cập nhật thông tin đài truyền thanh <2>{{station}}</2> tại {{ward}}',
      userUpdateInfoContentToWards:
        '<0>{{user}}</0> cập nhật thông tin bản tin <2>{{content}}</2> cho {{wards}}',
      userUpdateScheduleContentToWards:
        '<0>{{user}}</0> cập nhật lịch phát cho bản tin <2>{{content}}</2> tại {{wards}}',
      username: 'Tài khoản',
      usernameAlreadyInUse: 'Tài khoản đã tồn tại',
      users: 'Người dùng',
      userGroups: 'Nhóm người dùng',
      userGroupBeingUserd: 'Nhóm người dùng này đang được sử dụng, không thể xóa bỏ',
      value: 'Giá trị',
      version: 'Phiên bản',
      versionUpgrade: 'Nâng cấp phiên bản ứng dụng',
      video: 'Video',
      viewer: 'Người xem',
      viewInfoDevice_title: 'Xem thông tin thiết bị',
      viewStation_title: 'Xem thông tin đài truyền thanh',
      voice: 'Giọng đọc',
      volume: 'Âm lượng',
      ward: 'Xã-phường-thị trấn',
      wardLevelUsers: 'Người dùng cấp xã',
      wattage: 'Công suất',
      week: 'Tuần',
      weekly: 'Hàng tuần',
      wifiSetupInformation: 'Thông tin thiết lập WIFI',
      viewContent_title: 'Xem bản tin',
      windowCannotBeClosedWhileContentIsPlaying: 'Không thể đóng của sổ này khi đang phát nội dung',
      writeComment: 'Viết bình luận...',
      yearly: 'Hàng năm',
      year: 'Năm',
      yearNotSelected: 'Chưa chọn năm',
    },

    menu: {
      accessPermissionSettings: 'Cài đặt quyền truy cập',
      accountSettings: 'Cài đặt tài khoản',
      content: 'Quản lý Bản tin',
      contents: 'Bản tin',
      contentBroadcast: 'Bản tin phát thanh',
      electronicContent: 'Bản tin điện tử công cộng',
      createDevice: 'Tạo thiết bị',
      device: 'Quản lý Thiết bị',
      display: 'Quản lý Màn hình',
      deviceSettings: 'Cài đặt thiết bị',
      generalSettings: 'Cài đặt chung',
      helps: 'Trợ giúp',
      historyAired: 'Chương trình đã phát',
      home: 'Trang chủ',
      installDistrictCommuneDirectory: 'Cài đặt danh mục huyện/xã',
      liveBroadcast: 'Phát thanh trực tiếp',
      manageDevice: 'Quản lý Thiết bị',
      manageDisplay: 'Quản lý Màn hình',
      manageZone: 'Quản lý Địa bàn',
      schedules: 'Lịch phát',
      publicElectronicBulletin: 'Bản tin điện tử công cộng',
      report: 'Báo cáo Thống kê',
      schedule: 'Quản lý Lịch phát',
      setting: 'Cài đặt',
      station: 'Quản lý Đài truyền thanh',
      speaker: 'Quản lý Cụm loa',
      userActivities: 'Nhật ký người sử dụng',
      userLog: 'Nhật ký hoạt động',
      userSettings: 'Cài đặt người dùng',
      userGroupSettings: 'Cài đặt nhóm người dùng',
    },

    action: {
      addAnotherDevice: 'Thêm thiết bị khác',
      addAnotherStation: 'Thêm đài khác',
      addAnotherTask: 'Thêm việc khác',
      addAnotherUser: 'Thêm người dùng khác',
      addDevice: 'Thêm thiết bị',
      addDevice_title: 'Thêm Thiết bị',
      addComment: 'Thêm bình luận',
      addStation: 'Thêm đài',
      addMember: 'Thêm thành viên',
      addMoreDetailedDescription: 'Thêm chi tiết khác',
      addTask: 'Thêm việc',
      addToDevice: 'Thêm vào thiết bị',
      addUser: 'Thêm người dùng',
      addUserGroup: 'Thêm nhóm người dùng',
      areas: 'Địa bàn',
      cancelSchedule: 'Hủy lịch',
      cancel: 'Hủy bỏ',
      category: 'Lĩnh vực',
      concat: 'Ghép',
      content: 'Nội dung',
      convert: 'Chuyển đổi',
      confirm: 'Xác nhận',
      control: 'Điều khiển',
      controlDevice: 'Điều khiển thiết bị',
      create: 'Thêm',
      createCategory: 'Thêm loại lĩnh vực',
      createDistrict: 'Thêm quận/huyện',
      createFile: 'Thêm file',
      createContent: 'Thêm bản tin',
      createNewContent: 'Thêm bản tin',
      createProvince: 'Thêm tỉnh/thành',
      createSchedule: 'Thêm lịch',
      createStation: 'Thêm đài',
      clearAll: 'Xóa tất cả',
      delete: 'Xóa',
      deleteAttachment: 'Xóa đính kèm',
      deleteAvatar: 'Xóa avatar',
      deleteDistrict: 'Xóa quận/huyện',
      deleteDevice: 'Xóa thiết bị',
      deleteDevice_title: 'Xóa Thiết bị',
      deleteComment: 'Xóa bình luận',
      deleteImage: 'Xóa ảnh',
      deleteContent: 'Xóa bản tin',
      deleteCategory: 'Xoá loại lĩnh vực',
      deleteThisNotification: 'Xóa thông báo này',
      deleteSchedule: 'Xóa lịch',
      deleteStation: 'Xóa đài',
      deleteStation_title: 'Xóa Đài',
      deleteProvince: 'Xóa tỉnh/thành',
      deleteProvince_title: 'Xóa Tỉnh/thành',
      deleteTask: 'Xóa việc',
      deleteTask_title: 'Xóa Việc',
      deleteUser: 'Xóa người dùng',
      done: 'Xong',
      edit: 'Sửa',
      editContent: 'Sửa bản tin',
      editDueDate_title: 'Sửa hạn',
      editDescription_title: 'Sửa miêu tả',
      editEmail_title: 'Sửa Email',
      editInformation_title: 'Sửa Thông tin',
      editPassword_title: 'Sửa Mật khẩu',
      editPermissions: 'Sửa Quyền hạn',
      editSchedule: 'Sửa lịch',
      editStopwatch_title: 'Sửa Đếm giờ',
      editTitle_title: 'Sửa Tên',
      editUsername_title: 'Sửa Tài khoản',
      editWard: 'Sửa Xã/phường/thị trấn',
      exportReport: 'Xuất báo cáo',
      filter: 'Lọc',
      hideDetails: 'Ẩn chi tiết',
      history: 'Lịch sử',
      import: 'Nhập khẩu',
      leaveDistrict: 'Rời quận/huyện',
      leaveProvince: 'Rời tỉnh/thành',
      leaveWard: 'Rời xã/phường/thị trấn',
      logOut_title: 'Đăng xuất',
      makeCover_title: 'Đặt làm hình nền',
      move: 'Chuyển',
      moveDevice_title: 'Chuyển Thiết bị',
      next: 'Tiếp tục',
      no: 'Không',
      play: 'Phát',
      refresh: 'Làm mới',
      remove: 'Xóa',
      removeBackground: 'Xóa nền',
      removeCover_title: 'Xóa hình nền',
      removeFromDistrict: 'Xóa khỏi quận/huyện',
      removeFromProvince: 'Xóa khỏi tỉnh/thành',
      removeFromWard: 'Xóa khỏi xã/phường/thị trấn',
      removeManager: 'Xóa quản lý',
      removeMember: 'Xóa thành viên',
      report: 'Báo cáo',
      reset: 'Khởi động',
      save: 'Lưu',
      saveChange: 'Lưu thay đổi',
      saveAndPlay: 'Lưu và phát',
      seeMore: 'Xem thêm',
      seeResults: 'Xem kết quả',
      select: 'Chọn',
      showAllAttachments: 'Hiện tất cả đính kèm ({{hidden}} ẩn)',
      showDetails: 'Hiện chi tiết',
      showFewerAttachments: 'Ẩn bới đính kèm',
      start: 'Chạy',
      stationBroadcastContent: 'Đài truyền thanh đã phát bản tin',
      stop: 'Dừng',
      subscribe: 'Theo dõi',
      unsubscribe: 'Hủy theo dõi',
      unchecked: 'Bỏ chọn',
      uploadNewAvatar: 'Tải avatar mới',
      uploadNewImage: 'Tải ảnh mới',
      uploadFileAudio: 'Tải tệp âm thanh',
      view: 'Xem',
      yes: 'Có',
    },

    rrule: {
      every: 'Hàng',
      week: 'tuần',
      weeks: 'tuần',
      month: 'tháng',
      months: 'tháng',
      year: 'năm',
      years: 'năm',
      on: 'vào',
      Monday: 'Thứ hai',
      Tuesday: 'Thứ ba',
      Wednesday: 'Thứ tư',
      Thursday: 'Thứ năm',
      Friday: 'Thứ sáu',
      Saturday: 'Thứ bảy',
      Sunday: 'Chủ nhật',
      for: ',',
      times: 'lần',
      until: 'đến',
    },
    user: {
      level: 'Cấp độ',
      province: 'Tỉnh',
      district: 'Huyện',
      ward: 'Xã',
    },
    permission: {
      canAdd: 'Thêm',
      canEdit: 'Sửa',
      canDelete: 'Xóa',
      canView: 'Xem',
    },
  },
};
