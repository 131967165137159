import ActionTypes from '../constants/ActionTypes';

const handleSocketDisconnect = () => ({
  type: ActionTypes.SOCKET_DISCONNECT_HANDLE,
  payload: {},
});

const handleSocketReconnect = (
  user,
  district,
  users,
  province,
  provinces,
  provinceManagers,
  districts,
  districtMemberships,
  wards,
  wardMemberships,
  hamlets,
  categories,
  contents,
  contentCategories,
  schedules,
  stations,
  devices,
  deviceMemberships,
  deviceContents,
  tasks,
  attachments,
  activities,
  notifications,
  totals,
  contentLocations,
  contentSchedules,
  userGroups,
) => ({
  type: ActionTypes.SOCKET_RECONNECT_HANDLE,
  payload: {
    user,
    district,
    users,
    province,
    provinces,
    provinceManagers,
    districts,
    districtMemberships,
    wards,
    wardMemberships,
    hamlets,
    categories,
    contents,
    contentCategories,
    schedules,
    stations,
    devices,
    deviceMemberships,
    deviceContents,
    tasks,
    attachments,
    activities,
    notifications,
    totals,
    contentLocations,
    contentSchedules,
    userGroups,
  },
});

handleSocketReconnect.fetchCore = (currentUserId, currentDistrictId) => ({
  type: ActionTypes.SOCKET_RECONNECT_HANDLE__CORE_FETCH,
  payload: {
    currentUserId,
    currentDistrictId,
  },
});

export default {
  handleSocketDisconnect,
  handleSocketReconnect,
};
