import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Form } from 'semantic-ui-react';
import { Popup } from '../../lib/custom-ui';

import { useForm } from '../../hooks';

import styles from './DeviceMoveStep.module.scss';

const DeviceMoveStep = React.memo(
  ({ provincesToStations, defaultPath, onMove, onTransfer, onDistrictFetch, onBack, onClose }) => {
    const [t] = useTranslation();

    const [path, handleFieldChange] = useForm(() => ({
      provinceId: null,
      districtId: null,
      stationId: null,
      ...defaultPath,
    }));

    const selectedProvince = useMemo(
      () => provincesToStations.find((province) => province.id === path.provinceId) || null,
      [provincesToStations, path.provinceId],
    );

    const selectedDistrict = useMemo(
      () =>
        (selectedProvince &&
          selectedProvince.districts.find((district) => district.id === path.districtId)) ||
        null,
      [selectedProvince, path.districtId],
    );

    const selectedStation = useMemo(
      () =>
        (selectedDistrict &&
          selectedDistrict.stations.find((station) => station.id === path.stationId)) ||
        null,
      [selectedDistrict, path.stationId],
    );

    const handleDistrictIdChange = useCallback(
      (event, data) => {
        if (
          selectedProvince.districts.find((district) => district.id === data.value).isFetching ===
          null
        ) {
          onDistrictFetch(data.value);
        }

        handleFieldChange(event, data);
      },
      [onDistrictFetch, handleFieldChange, selectedProvince],
    );

    const handleSubmit = useCallback(() => {
      if (selectedDistrict.id !== defaultPath.districtId) {
        onTransfer(selectedDistrict.id, selectedStation.id);
      } else if (selectedStation.id !== defaultPath.stationId) {
        onMove(selectedStation.id);
      }

      onClose();
    }, [defaultPath, onMove, onTransfer, onClose, selectedDistrict, selectedStation]);

    return (
      <>
        <Popup.Header onBack={onBack}>
          {t('common.moveDevice', {
            context: 'title',
          })}
        </Popup.Header>
        <Popup.Content>
          <Form onSubmit={handleSubmit}>
            {/* <div className={styles.text}>{t('common.province')}</div>
            <Dropdown
              fluid
              selection
              name="provinceId"
              options={provincesToStations.map((province) => ({
                text: province.name,
                value: province.id,
              }))}
              value={selectedProvince && selectedProvince.id}
              placeholder={
                provincesToStations.length === 0
                  ? t('common.noProvinces')
                  : t('common.selectProvince')
              }
              disabled={provincesToStations.length === 0}
              className={styles.field}
              onChange={handleFieldChange}
            /> */}
            {selectedProvince && (
              <>
                <div className={styles.text}>{t('common.district')}</div>
                <Dropdown
                  fluid
                  selection
                  name="districtId"
                  options={selectedProvince.districts.map((district) => ({
                    text: district.name,
                    value: district.id,
                  }))}
                  value={selectedDistrict && selectedDistrict.id}
                  placeholder={
                    selectedProvince.districts.length === 0
                      ? t('common.noDistricts')
                      : t('common.selectDistrict')
                  }
                  disabled={selectedProvince.districts.length === 0}
                  className={styles.field}
                  onChange={handleDistrictIdChange}
                />
              </>
            )}
            {selectedDistrict && (
              <>
                <div className={styles.text}>{t('common.stations')}</div>
                <Dropdown
                  fluid
                  selection
                  name="stationId"
                  options={selectedDistrict.stations.map((station) => ({
                    text: station.name,
                    value: station.id,
                  }))}
                  value={selectedStation && selectedStation.id}
                  placeholder={
                    selectedDistrict.isFetching === false && selectedDistrict.stations.length === 0
                      ? t('common.noStations')
                      : t('common.selectStation')
                  }
                  loading={selectedDistrict.isFetching !== false}
                  disabled={
                    selectedDistrict.isFetching !== false || selectedDistrict.stations.length === 0
                  }
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </>
            )}
            <Button
              positive
              content={t('action.move')}
              disabled={
                (selectedDistrict && selectedDistrict.isFetching !== false) || !selectedStation
              }
            />
          </Form>
        </Popup.Content>
      </>
    );
  },
);

DeviceMoveStep.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  provincesToStations: PropTypes.array.isRequired,
  defaultPath: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
  onMove: PropTypes.func.isRequired,
  onTransfer: PropTypes.func.isRequired,
  onDistrictFetch: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

DeviceMoveStep.defaultProps = {
  onBack: undefined,
};

export default DeviceMoveStep;
