import { attr, fk, many } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

const DEFAULT_DELETE_FORM = {
  data: {},
  error: null,
};

export default class extends BaseModel {
  static modelName = 'Hamlet';

  static fields = {
    id: attr(),
    name: attr(),
    code: attr(),
    isFetching: attr({
      getDefault: () => null,
    }),
    provinceId: fk({
      to: 'Province',
      as: 'province',
      relatedName: 'hamlets',
    }),
    districtId: fk({
      to: 'District',
      as: 'district',
      relatedName: 'hamlets',
    }),
    wardId: fk({
      to: 'Ward',
      as: 'ward',
      relatedName: 'hamlets',
    }),
    deleteForm: attr({
      getDefault: () => DEFAULT_DELETE_FORM,
    }),
    // memberUsers: many({
    //   to: 'User',
    //   through: 'HamletMembership',
    //   relatedName: 'districts',
    // }),
    // filterUsers: many('User', 'filterHamlets'),
    // filterContents: many('Content', 'filterHamlets'),
  };

  static reducer({ type, payload }, Hamlet) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
        if (payload.hamlet) {
          Hamlet.upsert({
            ...payload.hamlet,
            isFetching: false,
          });
        }

        break;
      // case ActionTypes.LOCATION_CHANGE_HANDLE__DISTRICT_FETCH:
      // case ActionTypes.DISTRICT_FETCH:
      //   Hamlet.withId(payload.id).update({
      //     isFetching: true,
      //   });

      //   break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Hamlet.all().delete();

        if (payload.hamlet) {
          Hamlet.upsert({
            ...payload.hamlet,
            isFetching: false,
          });
        }

        payload.hamlets.forEach((hamlet) => {
          Hamlet.upsert(hamlet);
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE__CORE_FETCH:
        // Hamlet.all()
        //   .toModelArray()
        //   .forEach((hamletModel) => {
        //     if (hamletModel.id !== payload.currentHamletId) {
        //       hamletModel.update({
        //         isFetching: null,
        //       });

        //       hamletModel.deleteRelated(payload.currentUserId);
        //     }
        //   });

        break;
      case ActionTypes.CORE_INITIALIZE:
        if (payload.hamlet) {
          Hamlet.upsert({
            ...payload.hamlet,
            isFetching: false,
          });
        }

        payload.hamlets.forEach((hamlet) => {
          Hamlet.upsert(hamlet);
        });

        break;
      // case ActionTypes.USER_TO_DISTRICT_FILTER_ADD:
      //   Hamlet.withId(payload.hamletId).filterUsers.add(payload.id);

      //   break;
      // case ActionTypes.USER_FROM_DISTRICT_FILTER_REMOVE:
      //   Hamlet.withId(payload.hamletId).filterUsers.remove(payload.id);

      //   break;
      case ActionTypes.PROVINCE_CREATE_HANDLE:
        payload.hamlets.forEach((hamlet) => {
          Hamlet.upsert(hamlet);
        });

        break;
      case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE:
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE:
        if (payload.hamlets) {
          payload.hamlets.forEach((hamlet) => {
            Hamlet.upsert({
              ...hamlet,
              ...(payload.hamlet &&
                payload.hamlet.id === hamlet.id && {
                  isFetching: false,
                }),
            });
          });
        }

        break;
      case ActionTypes.HAMLET_CREATE:
      case ActionTypes.HAMLET_CREATE_HANDLE:
      case ActionTypes.HAMLET_UPDATE__SUCCESS:
      case ActionTypes.HAMLET_UPDATE_HANDLE:
        Hamlet.upsert(payload.hamlet);

        break;
      case ActionTypes.HAMLET_CREATE__SUCCESS:
        Hamlet.withId(payload.localId).delete();
        Hamlet.upsert(payload.hamlet);

        break;
      case ActionTypes.HAMLET_FETCH__SUCCESS:
        Hamlet.upsert({
          ...payload.hamlet,
          isFetching: false,
        });

        break;
      case ActionTypes.HAMLET_FETCH__FAILURE:
        Hamlet.withId(payload.id).update({
          isFetching: null,
        });

        break;
      case ActionTypes.HAMLET_UPDATE:
        Hamlet.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.HAMLET_DELETE:
        // Hamlet.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.HAMLET_DELETE__SUCCESS:
      case ActionTypes.HAMLET_DELETE_HANDLE: {
        const hamletModel = Hamlet.withId(payload.hamlet.id);

        if (hamletModel) {
          hamletModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.HAMLET_DELETE__FAILURE: {
        const hamletModel = Hamlet.withId(payload.id);

        hamletModel.update({
          deleteForm: {
            ...hamletModel.deleteForm,
            error: payload.error,
          },
        });

        break;
      }
      // case ActionTypes.CONTENT_TO_HAMLET_FILTER_ADD:
      //   Hamlet.withId(payload.districtId).filterContents.add(payload.id);

      //   break;
      // case ActionTypes.CONTENT_FROM_HAMLET_FILTER_REMOVE:
      //   Hamlet.withId(payload.districtId).filterContents.remove(payload.id);

      //   break;
      default:
    }
  }

  getOrderedMembershipsQuerySet() {
    return this.memberships.orderBy('id');
  }

  getOrderedContentsQuerySet() {
    return this.contents.orderBy('position');
  }

  getOrderedStationsQuerySet() {
    return this.stations.orderBy('position');
  }

  getMembershipModelForUser(userId) {
    return this.memberships
      .filter({
        userId,
      })
      .first();
  }

  hasMembershipForUser(userId) {
    return this.memberships
      .filter({
        userId,
      })
      .exists();
  }

  isAvailableForUser(userId) {
    return (
      this.province &&
      (this.province.hasManagerForUser(userId) || this.hasMembershipForUser(userId))
    );
  }

  deleteRelated(exceptMemberUserId) {
    this.memberships.toModelArray().forEach((hamletMembershipModel) => {
      if (hamletMembershipModel.userId !== exceptMemberUserId) {
        hamletMembershipModel.deleteWithRelated();
      }
    });

    this.contents.delete();

    this.stations.toModelArray().forEach((stationModel) => {
      stationModel.deleteWithRelated();
    });
  }

  deleteWithRelated() {
    // this.deleteRelated();
    this.delete();
  }
}
