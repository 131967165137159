import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import requests from '../requests';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';
import mergeRecords from '../../../utils/merge-records';

export function* createProvinceManager(provinceId, data) {
  const localId = yield call(createLocalId);

  yield put(
    actions.createProvinceManager({
      ...data,
      provinceId,
      id: localId,
    }),
  );

  let provinceManager;
  try {
    ({ item: provinceManager } = yield call(request, api.createProvinceManager, provinceId, data));
  } catch (error) {
    yield put(actions.createProvinceManager.failure(localId, error));
    return;
  }

  yield put(actions.createProvinceManager.success(localId, provinceManager));
}

export function* createManagerInCurrentProvince(data) {
  const { provinceId } = yield select(selectors.selectPath);

  yield call(createProvinceManager, provinceId, data);
}

export function* handleProvinceManagerCreate(provinceManager) {
  const currentUserId = yield select(selectors.selectCurrentUserId);
  const isCurrentUser = provinceManager.userId === currentUserId;

  let user;
  let province;
  let district;
  let users1;
  let users2;
  let provinceManagers;
  let districts;
  let districtMemberships1;
  let districtMemberships2;
  let contents;
  let stations;
  let devices;
  let deviceMemberships;
  let deviceContents;
  let tasks;
  let attachments;
  let deletedNotifications;

  if (isCurrentUser) {
    const { districtId } = yield select(selectors.selectPath);

    yield put(
      actions.handleProvinceManagerCreate.fetchProvince(
        provinceManager.provinceId,
        currentUserId,
        districtId,
      ),
    );

    try {
      ({
        item: province,
        included: {
          users: users1,
          provinceManagers,
          districts,
          districtMemberships: districtMemberships1,
        },
      } = yield call(request, api.getProvince, provinceManager.provinceId));
    } catch {
      return;
    }

    let body;
    try {
      body = yield call(requests.fetchDistrictByCurrentPath);
    } catch {} // eslint-disable-line no-empty

    if (body && body.province && body.province.id === provinceManager.provinceId) {
      ({
        province,
        district,
        users: users2,
        districtMemberships: districtMemberships2,
        contents,
        stations,
        devices,
        deviceMemberships,
        deviceContents,
        tasks,
        attachments,
      } = body);

      if (body.device) {
        deletedNotifications = yield select(
          selectors.selectNotificationsByDeviceId,
          body.device.id,
        );
      }
    }
  } else {
    try {
      ({ item: user } = yield call(request, api.getUser, provinceManager.userId));
    } catch {
      return;
    }
  }

  yield put(
    actions.handleProvinceManagerCreate(
      provinceManager,
      province,
      district,
      isCurrentUser ? mergeRecords(users1, users2) : [user],
      provinceManagers,
      districts,
      mergeRecords(districtMemberships1, districtMemberships2),
      contents,
      stations,
      devices,
      deviceMemberships,
      deviceContents,
      tasks,
      attachments,
      deletedNotifications,
    ),
  );
}

export function* deleteProvinceManager(id) {
  let provinceManager = yield select(selectors.selectProvinceManagerById, id);

  const currentUserId = yield select(selectors.selectCurrentUserId);
  const { provinceId } = yield select(selectors.selectPath);

  yield put(
    actions.deleteProvinceManager(
      id,
      provinceManager.userId === currentUserId,
      provinceManager.provinceId === provinceId,
    ),
  );

  try {
    ({ item: provinceManager } = yield call(request, api.deleteProvinceManager, id));
  } catch (error) {
    yield put(actions.deleteProvinceManager.failure(id, error));
    return;
  }

  yield put(actions.deleteProvinceManager.success(provinceManager));
}

export function* handleProvinceManagerDelete(provinceManager) {
  const currentUserId = yield select(selectors.selectCurrentUserId);
  const { provinceId } = yield select(selectors.selectPath);

  yield put(
    actions.handleProvinceManagerDelete(
      provinceManager,
      provinceManager.userId === currentUserId,
      provinceManager.provinceId === provinceId,
    ),
  );
}

export default {
  createProvinceManager,
  createManagerInCurrentProvince,
  handleProvinceManagerCreate,
  deleteProvinceManager,
  handleProvinceManagerDelete,
};
