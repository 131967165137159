import { createSelector } from 'redux-orm';

import orm from '../orm';
import { selectPath } from './router';
import { selectCurrentUserId } from './users';
import { isLocalId } from '../utils/local-id';

export const makeSelectDistrictById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ District }, id) => {
      const districtModel = District.withId(id);

      if (!districtModel) {
        return districtModel;
      }

      return districtModel.ref;
    },
  );

export const selectDistrictById = makeSelectDistrictById();

export const selectCurrentDistrict = createSelector(
  orm,
  (state) => selectPath(state).districtId,
  ({ District }, id) => {
    if (!id) {
      return id;
    }

    const districtModel = District.withId(id);

    if (!districtModel) {
      return districtModel;
    }

    return districtModel.ref;
  },
);

export const selectMembershipsForCurrentDistrict = createSelector(
  orm,
  (state) => selectPath(state).districtId,
  (state) => selectCurrentUserId(state),
  ({ District }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const districtModel = District.withId(id);

    if (!districtModel) {
      return districtModel;
    }

    return districtModel
      .getOrderedMembershipsQuerySet()
      .toModelArray()
      .map((districtMembershipModel) => ({
        ...districtMembershipModel.ref,
        isPersisted: !isLocalId(districtMembershipModel.id),
        user: {
          ...districtMembershipModel.user.ref,
          isCurrent: districtMembershipModel.user.id === currentUserId,
        },
      }));
  },
);

export const selectCurrentUserMembershipForCurrentDistrict = createSelector(
  orm,
  (state) => selectPath(state).districtId,
  (state) => selectCurrentUserId(state),
  ({ District }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const districtModel = District.withId(id);

    if (!districtModel) {
      return districtModel;
    }

    const districtMembershipModel = districtModel.getMembershipModelForUser(currentUserId);

    if (!districtMembershipModel) {
      return districtMembershipModel;
    }

    return districtMembershipModel.ref;
  },
);

export const selectContentsForCurrentDistrict = createSelector(
  orm,
  (state) => selectPath(state).districtId,
  ({ District }, id) => {
    if (!id) {
      return id;
    }

    const districtModel = District.withId(id);

    if (!districtModel) {
      return districtModel;
    }

    return districtModel
      .getOrderedContentsQuerySet()
      .toRefArray()
      .map((content) => ({
        ...content,
        isPersisted: !isLocalId(content.id),
      }));
  },
);

export const selectStationIdsForCurrentDistrict = createSelector(
  orm,
  (state) => selectPath(state).districtId,
  ({ District }, id) => {
    if (!id) {
      return id;
    }

    const districtModel = District.withId(id);

    if (!districtModel) {
      return districtModel;
    }

    return districtModel
      .getOrderedStationsQuerySet()
      .toRefArray()
      .map((station) => station.id);
  },
);

export const selectWardIdsForCurrentDistrict = createSelector(
  orm,
  (state) => selectPath(state).districtId,
  ({ District }, id) => {
    if (!id) {
      return id;
    }

    const districtModel = District.withId(id);

    if (!districtModel) {
      return districtModel;
    }

    return districtModel
      .getOrderedWardsQuerySet()
      .toRefArray()
      .map((ward) => ward.id);
  },
);

export const selectFilterUsersForCurrentDistrict = createSelector(
  orm,
  (state) => selectPath(state).districtId,
  ({ District }, id) => {
    if (!id) {
      return id;
    }

    const districtModel = District.withId(id);

    if (!districtModel) {
      return districtModel;
    }

    return districtModel.filterUsers.toRefArray();
  },
);

export const selectFilterContentsForCurrentDistrict = createSelector(
  orm,
  (state) => selectPath(state).districtId,
  ({ District }, id) => {
    if (!id) {
      return id;
    }

    const districtModel = District.withId(id);

    if (!districtModel) {
      return districtModel;
    }

    return districtModel.filterContents.toRefArray();
  },
);

export const selectIsDistrictWithIdExists = createSelector(
  orm,
  (_, id) => id,
  ({ District }, id) => District.idExists(id),
);

export const selectUsersForCurrentDistrict = createSelector(
  orm,
  (_, id) => id,
  (state) => selectCurrentUserId(state),
  ({ District }, districtId, currentUserId) => {
    if (!districtId) {
      return districtId;
    }

    const districtModel = District.withId(districtId);

    if (!districtModel) {
      return districtModel;
    }

    return districtModel
      .getOrderedMembershipsQuerySet()
      .toModelArray()
      .map((membershipModel) => ({
        ...membershipModel.user.ref,
        isCurrent: membershipModel.user.id === currentUserId,
      }));
  },
);

export default {
  makeSelectDistrictById,
  selectDistrictById,
  selectCurrentDistrict,
  selectMembershipsForCurrentDistrict,
  selectCurrentUserMembershipForCurrentDistrict,
  selectContentsForCurrentDistrict,
  selectStationIdsForCurrentDistrict,
  selectFilterUsersForCurrentDistrict,
  selectFilterContentsForCurrentDistrict,
  selectIsDistrictWithIdExists,
  selectWardIdsForCurrentDistrict,
  selectUsersForCurrentDistrict,
};
