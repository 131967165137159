import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { omit } from 'lodash';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import { Button, Checkbox, Dropdown, Input, Modal, Select } from 'semantic-ui-react';
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule';

import { useForm } from '../../../hooks';
import { addDayWeekMonthYear, detailDayInMonth, pickDayOfWeek } from '../../../utils/custom-repeat';
import { CustomOptions, WEEKS, BYWEEKDAYS, FREQS, CHECK_TYPES } from '../../../constants/Enums';

import styles from './CustomStep.module.scss';

const types = [
  {
    l: 'year',
    t: 'yearly',
  },
  {
    l: 'month',
    t: 'monthly',
  },
  {
    l: 'week',
    t: 'weekly',
  },
  {
    l: 'day',
    t: 'daily',
  },
];

const CustomStep = React.memo(
  ({ title, repeatRule, startDate, dayOfMonth, never, onConfirm, onClose }) => {
    const [t] = useTranslation();

    const text = t('common.monthlyOnDate');
    const detailDay = detailDayInMonth(startDate);

    let rr = {
      interval: 1,
    };

    const monthlyOnDate = `${text} ${detailDay.day}`;
    const monthlyOnOrdinal = `${text} ${detailDay.dayOfWeek} ${t(`common.${detailDay.frequency}`)}`;
    let currentOption = CustomOptions[1];

    if (repeatRule) {
      rr = RRule.fromString(repeatRule);
      const ori = rr.origOptions;
      currentOption = {
        text: types[ori.freq].l,
        type: types[ori.freq].t,
        count: ori.count || 0,
        interval: ori.interval || 0,
        until: ori.until,
        byweekday: ori.byweekday && ori.byweekday.map((it) => WEEKS[it.weekday]),
      };
    }

    const [data, handleFieldChange, setData] = useForm({
      type: currentOption.type,
      interval: currentOption.interval || 1,
      byweekday: currentOption.byweekday || [pickDayOfWeek(startDate, 'eeeee')],
      endDate:
        currentOption.until ||
        addDayWeekMonthYear(currentOption.type, startDate, currentOption.count),
      // eslint-disable-next-line no-nested-ternary
      typeEnd: currentOption.count
        ? CHECK_TYPES.after
        : currentOption.until
        ? CHECK_TYPES.inDate
        : CHECK_TYPES.never,
      count: currentOption.count || 1,
      monthlyOn: monthlyOnDate,
    });

    const handleSelectCustomOption = useCallback(
      (_, { value }) => {
        const option = CustomOptions.find(({ type }) => type === value);
        const endDate = addDayWeekMonthYear(option.type, startDate, option.count);

        setData((prev) => ({
          ...prev,
          ...(option.type === 'monthly' && { monthlyOn: monthlyOnDate }),
          type: option.type,
          count: option.count,
          endDate,
        }));
      },
      [startDate, monthlyOnDate, setData],
    );

    const handleSelectDayOfWeek = useCallback(
      (day) => {
        const arrayDays = data.byweekday;
        if (!arrayDays.includes(day)) {
          setData((prev) => ({ ...prev, byweekday: [...arrayDays, day] }));
          return;
        }

        const filterDays = arrayDays.filter((d) => d !== day);

        if (filterDays.length <= 0) {
          setData((prev) => ({ ...prev, byweekday: [pickDayOfWeek(startDate, 'eeeee')] }));
          return;
        }
        setData((prev) => ({ ...prev, byweekday: filterDays }));
      },
      [startDate, data.byweekday, setData],
    );

    const handleOnCancel = useCallback(() => {
      onClose();
    }, [onClose]);

    const handleOnSave = useCallback(() => {
      const r = {
        freq: FREQS[data.type],
        interval: data.interval,
        ...(data.type === 'weekly' && { byweekday: data.byweekday.map((it) => BYWEEKDAYS[it]) }),
        ...(data.type === 'monthly' &&
          dayOfMonth &&
          data.monthlyOn === monthlyOnOrdinal && {
            byweekday: BYWEEKDAYS[detailDay.byday].nth(detailDay.freq),
          }),
        dtstart: startDate,
        ...(data.typeEnd === CHECK_TYPES.inDate && { until: data.endDate }),
        ...(data.typeEnd === CHECK_TYPES.after && { count: data.count }),
      };
      const rule = new RRule(r);

      onConfirm(rule.toString(), r.until);
      onClose();
    }, [
      data.byweekday,
      data.count,
      data.endDate,
      data.interval,
      data.monthlyOn,
      data.type,
      data.typeEnd,
      detailDay.byday,
      detailDay.freq,
      monthlyOnOrdinal,
      dayOfMonth,
      onClose,
      onConfirm,
      startDate,
    ]);

    const SelectOptions = CustomOptions.map((ot, idx) => ({
      key: idx,
      value: ot.type,
      text: t(`common.${ot.text}`),
    }));

    const SelectMonthOptions = [monthlyOnDate, monthlyOnOrdinal].map((ot, idx) => ({
      key: idx,
      value: ot,
      text: ot,
    }));

    const renderEverySelect = useCallback(
      (type) => {
        switch (type) {
          case 'weekly':
            return (
              <div className={styles.marginTop}>
                <div>{t('common.repeatOn')}</div>
                <div className={styles.wrapperDayOfWeek}>
                  {WEEKS.map((d) => (
                    <Button
                      circular
                      size="tiny"
                      key={d}
                      className={classNames(
                        styles.dayOfWeek,
                        data.byweekday.includes(d) && styles.active,
                      )}
                      onClick={() => handleSelectDayOfWeek(d)}
                    >
                      {d}
                    </Button>
                  ))}
                </div>
              </div>
            );
          case 'monthly': {
            const filterOptions = SelectMonthOptions.filter((_, i) => i !== 1);
            return (
              <Dropdown
                options={dayOfMonth ? SelectMonthOptions : filterOptions}
                className={styles.selectMonth}
                name="monthlyOn"
                value={data.monthlyOn}
                onChange={handleFieldChange}
              />
            );
          }
          default:
            break;
        }

        return null;
      },
      [
        t,
        data.monthlyOn,
        data.byweekday,
        dayOfMonth,
        SelectMonthOptions,
        handleFieldChange,
        handleSelectDayOfWeek,
      ],
    );

    return (
      <Modal open closeIcon centered size="mini" onClose={handleOnCancel}>
        <Modal.Header>
          {t(title, {
            context: 'title',
          })}
        </Modal.Header>
        <Modal.Content>
          <div className={styles.itemEvery}>
            <span>{t('common.repeatEach')}</span>
            <Input
              type="number"
              value={data.interval}
              name="interval"
              onChange={handleFieldChange}
            />
            <Select
              options={SelectOptions}
              className={styles.selectEvery}
              value={data.type}
              onChange={handleSelectCustomOption}
            />
          </div>
          {renderEverySelect(data.type)}
          <div className={styles.marginTop}>{t('common.end')}</div>
          {never && (
            <div className={styles.marginTop}>
              <Checkbox
                checked={data.typeEnd === CHECK_TYPES.never}
                label={t('common.never')}
                name="typeEnd"
                radio
                onChange={handleFieldChange}
                value={CHECK_TYPES.never}
              />
            </div>
          )}
          <div className={styles.itemCheck}>
            <Checkbox
              checked={data.typeEnd === CHECK_TYPES.inDate}
              label={t('common.onDate')}
              name="typeEnd"
              radio
              onChange={handleFieldChange}
              value={CHECK_TYPES.inDate}
            />
            <ReactDatePicker
              dateFormat="d MMM, yyyy"
              disabledKeyboardNavigation
              selected={data.endDate}
              disabled={data.typeEnd !== CHECK_TYPES.inDate}
              className={styles.datePicker}
              calendarClassName={styles.calendar}
              onChange={(date) => handleFieldChange(_, { name: 'endDate', value: date })}
            />
          </div>
          <div className={styles.itemCheck}>
            <Checkbox
              checked={data.typeEnd === CHECK_TYPES.after}
              label={t('common.after')}
              name="typeEnd"
              radio
              onChange={handleFieldChange}
              value={CHECK_TYPES.after}
            />
            <div
              className={classNames(
                styles.itemCheckInput,
                data.typeEnd !== CHECK_TYPES.after && styles.disabled,
              )}
            >
              <Input type="number" value={data.count} name="count" onChange={handleFieldChange} />
              <span>{t('common.appearance')}</span>
            </div>
          </div>
          <div className={styles.wrapperActions}>
            <Button className={styles.action} onClick={handleOnCancel}>
              {t('action.cancel')}
            </Button>
            <Button className={styles.action} onClick={handleOnSave}>
              {t('action.done')}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  },
);

CustomStep.propTypes = {
  startDate: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  dayOfMonth: PropTypes.bool,
  title: PropTypes.string.isRequired,
  never: PropTypes.bool,
  repeatRule: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

CustomStep.defaultProps = {
  repeatRule: undefined,
  dayOfMonth: true,
  never: true,
};

export default CustomStep;
