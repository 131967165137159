import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Icon, Input, Label, Select, Table } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
import { closePopup, usePopup } from '../../lib/popup';

// import Paths from '../../constants/Paths';
import Config from '../../constants/Config';
import { DeviceConnectStatusOptionsColors, DeviceStatusOptions } from '../../constants/Enums';
import { useToggle } from '../../lib/hooks';

// import AddStep from './AddStep';
// import EditStep from './EditStep';
import DeleteDevice from './DeleteDevice';
// import RestartDevice from './RestartDevice';
import DeviceAddModalContainer from '../../containers/DeviceAddModalContainer';
import DeviceModalContainer from '../../containers/DeviceModalContainer';
import PickLocation from '../StationModal/PickLocation';
import DevicesControlContainer from '../../containers/DevicesControlContainer';
import SelectOption from '../DevicesControlModal/SelectOption';
import PaginationCustom from '../Pagination';

import styles from './Devices.module.scss';

const LIMIT = Config.LIMIT_PER_PAGE;

const Devices = React.memo(
  ({
    items,
    districts,
    currentId,
    canAdd,
    canDelete,
    canEdit,
    canView,
    // onCreate,
    // onUpdate,
    // onMove,
    onDelete,
    onFetch,
    onRestart,
    onControl,
    // onEditDeviceClick,
    province,
  }) => {
    const [t] = useTranslation();
    const [isDeviceModalOpened, setDeviceModalOpened] = useState(false);
    const [isDevicesControlModalOpened, setDevicesControlModalOpened] = useState(false);
    const [name, setName] = useState('');
    const [selectedId, setSelectedId] = useState();
    const [districtIds, setSelectDistrictId] = useState([]);
    const [location, setLocation] = useState({ lat: null, lng: null });
    const [isViewing, setIsViewing] = useState(false);

    const [limit, setLimit] = useState(LIMIT);
    const [page, setPage] = useState(1);

    const [selectedDevice, setSelectedDevice] = useState();
    const [isOpenSelectOption, handleToggleSelectOption] = useToggle(false);

    const DeletePopup = usePopup(DeleteDevice);
    // const RestartPopup = usePopup(RestartDevice);

    const handleRefetchDevices = useCallback(() => {
      setPage(1);
      onFetch(1, limit, {
        ...(name && { name }),
        ...(districtIds.length > 0 && { districtId: districtIds }),
      });
    }, [onFetch, limit, name, districtIds]);

    // const handleClick = useCallback(() => {
    //   if (document.activeElement) {
    //     document.activeElement.blur();
    //   }
    // }, []);

    const handleDelete = useCallback(
      (id) => {
        onDelete(id);
      },
      [onDelete],
    );

    // const handleRestart = useCallback(
    //   (id) => {
    //     onRestart(id);
    //   },
    //   [onRestart],
    // );

    const handleDeviceModalOpen = useCallback(() => {
      setSelectedId(undefined);
      setDeviceModalOpened(true);
    }, []);

    const handleDeviceModalClose = useCallback(() => {
      setDeviceModalOpened(false);
      setIsViewing(false);
    }, []);

    const handleDevicesControlModalOpen = useCallback(() => {
      setDevicesControlModalOpened(true);
    }, []);

    const handleDevicesControlModalClose = useCallback(() => {
      setDevicesControlModalOpened(false);
    }, []);

    const handleViewOpen = useCallback((id) => {
      setSelectedId(id);
      setDeviceModalOpened(true);
      setIsViewing((prev) => !prev);
    }, []);

    const handleEditOpen = useCallback((id) => {
      setSelectedId(id);
      setDeviceModalOpened(true);
    }, []);

    const handleSetLocation = useCallback((lat, lng) => {
      setLocation({ lat, lng });
    }, []);

    const handleSelectPage = useCallback(
      (_, { activePage }) => {
        setPage(activePage);
        onFetch(activePage, limit, {
          ...(name && { name }),
          ...(districtIds.length > 0 && { districtId: districtIds }),
        });
      },
      [onFetch, limit, name, districtIds],
    );

    const onChangeSelectDistrict = useCallback((_, { value }) => {
      setSelectDistrictId(value);
    }, []);

    const onChangeName = useCallback((_, { value }) => {
      setName(value);
    }, []);

    const handleFilterRegion = useCallback(() => {
      handleRefetchDevices();
    }, [handleRefetchDevices]);

    const handleOpenControlDevice = useCallback(
      (item) => {
        setSelectedDevice(item);
        handleToggleSelectOption(true);
      },
      [handleToggleSelectOption],
    );

    const handleCloseControlDevice = useCallback(
      (item) => {
        setSelectedDevice();
        handleToggleSelectOption(false);
      },
      [handleToggleSelectOption],
    );

    const districtOptions = districts.map(({ id, name: text }) => ({ key: id, value: id, text }));

    return (
      <>
        <div className={styles.wrapper}>
          <div className={styles.wrapperSelect}>
            <div className={styles.alignLeft}>
              <Input
                placeholder={t('common.name')}
                icon="filter"
                iconPosition="left"
                value={name}
                onChange={onChangeName}
              />
              <Select
                multiple
                closeOnChange
                placeholder={t('common.selectDistrict')}
                options={districtOptions}
                value={districtIds}
                onChange={onChangeSelectDistrict}
              />
              <Button className={styles.buttonFilter} onClick={handleFilterRegion} size="mini">
                <Icon name="filter" />
                <span>{t('action.filter')}</span>
              </Button>
              {canEdit && (
                <Button
                  className={styles.button}
                  onClick={handleDevicesControlModalOpen}
                  size="mini"
                  content={t('action.controlDevice')}
                />
              )}
            </div>
            {canAdd && (
              <Button className={styles.buttonAdd} onClick={handleDeviceModalOpen} size="mini">
                <Icon name="add" />
                <span>{t('action.addDevice')}</span>
              </Button>
            )}
          </div>
          <div className={styles.wrapperTable}>
            <Table unstackable singleLine className={styles.table}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('common.name')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.code')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.type')}</Table.HeaderCell>
                  {/* <Table.HeaderCell>{t('common.district')}</Table.HeaderCell> */}
                  <Table.HeaderCell>{t('common.stations')}</Table.HeaderCell>
                  {/* <Table.HeaderCell>{t('common.phone')}</Table.HeaderCell> */}
                  <Table.HeaderCell>{t('common.status')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.connectStatus')}</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {items.map((it) => {
                  return (
                    <Table.Row key={it.id}>
                      <Table.Cell>{it.name}</Table.Cell>
                      <Table.Cell>
                        {it.code}
                        {it.lat > 0 && it.lng > 0 && (
                          <Button
                            icon="map marker alternate"
                            className={styles.position}
                            size="mini"
                            onClick={() => handleSetLocation(it.lat, it.lng)}
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>{t(`common.${it.type}`)}</Table.Cell>
                      <Table.Cell>{`${it.station}, ${it.ward}, ${it.district}`}</Table.Cell>
                      {/* <Table.Cell>{it.phone}</Table.Cell> */}
                      <Table.Cell>
                        {it.status && (
                          <Label
                            color={DeviceStatusOptions.find(({ key }) => key === it.status).color}
                            content={t(`common.${it.status}`)}
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {it.connectStatus ? (
                          <Label
                            color={DeviceConnectStatusOptionsColors[it.connectStatus]}
                            content={it.connectStatus}
                          />
                        ) : (
                          <span>-</span>
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {/* <Link to={Paths.DEVICE.replace(':id', it.id)} onClick={handleClick}> */}
                        {canEdit && (
                          <Button
                            icon
                            size="mini"
                            content={t('action.control')}
                            className={styles.action}
                            onClick={() => handleOpenControlDevice(it)}
                          />
                        )}
                        {canView && (
                          <Button
                            icon
                            size="mini"
                            content={t('action.view')}
                            className={styles.action}
                            onClick={() => handleViewOpen(it.id)}
                          />
                        )}
                        {/* </Link> */}
                        {canEdit && (
                          <Button
                            onClick={() => handleEditOpen(it.id)}
                            icon
                            size="mini"
                            content={t('action.edit')}
                            className={styles.action}
                          />
                        )}
                        {/* <RestartPopup
                          title={t('common.restartDevice_title')}
                          description={t('common.areYouSureYouWantToRestartThisDevice')}
                          onRestart={() => handleRestart(it.id)}
                        >
                          <Button
                            icon
                            size="mini"
                            content={t('action.reset')}
                            className={styles.action}
                          />
                        </RestartPopup> */}
                        {canDelete && (
                          <DeletePopup
                            title={t('common.deleteDevice_title')}
                            device={it.name}
                            onDelete={() => handleDelete(it.id)}
                          >
                            <Button
                              icon
                              size="mini"
                              content={t('action.delete')}
                              className={classNames(styles.action, styles.delete)}
                            />
                          </DeletePopup>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
          <div className={styles.pagination}>
            <PaginationCustom
              onPageChange={handleSelectPage}
              activePage={page}
              totalPages={Math.ceil(province.deviceTotal / limit)}
            />
          </div>
          {isDeviceModalOpened && (
            <DeviceAddModalContainer
              onClose={handleDeviceModalClose}
              selectedId={selectedId}
              isViewing={isViewing}
            />
          )}
          {isDevicesControlModalOpened && (
            <DevicesControlContainer onClose={handleDevicesControlModalClose} />
          )}
          {location.lat && location.lng && (
            <PickLocation
              title="common.location"
              showAction={false}
              currentLocation={location}
              onBack={() => setLocation({ lat: null, lng: null })}
              onClick={() => setLocation({ lat: null, lng: null })}
            />
          )}
        </div>
        {isOpenSelectOption && (
          <SelectOption
            item={selectedDevice}
            payload={{
              topic: `thietbi/${selectedDevice.code}`,
              DanhSachDiaBanNhan: [],
              DanhSachThietBi: [],
              deviceIds: [selectedDevice.id],
            }}
            onClose={handleCloseControlDevice}
            onControl={onControl}
          />
        )}
        {currentId && <DeviceModalContainer />}
      </>
    );
  },
);

Devices.propTypes = {
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  districts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  currentId: PropTypes.string,
  canAdd: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canView: PropTypes.bool.isRequired,
  // onCreate: PropTypes.func.isRequired,
  // onUpdate: PropTypes.func.isRequired,
  // onMove: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
  onRestart: PropTypes.func.isRequired,
  onControl: PropTypes.func.isRequired,
  // onEditDeviceClick: PropTypes.func.isRequired,
  province: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

Devices.defaultProps = {
  currentId: undefined,
};

export default Devices;
