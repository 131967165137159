import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import { push } from '../lib/redux-router';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Paths from '../constants/Paths';
import { DistrictMembershipRoles } from '../constants/Enums';
import DeviceModal from '../components/DeviceModal';

const mapStateToProps = (state) => {
  const { provinceId } = selectors.selectPath(state);
  const { slug } = selectors.selectCurrentProvince(state);
  const allProvincesToStations = selectors.selectProvincesToStationsForCurrentUser(state);
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);
  const allDistrictMemberships = selectors.selectMembershipsForCurrentDistrict(state);
  const allContents = selectors.selectContentsForCurrentDistrict(state);
  const currentUserMembership = selectors.selectCurrentUserMembershipForCurrentDistrict(state);
  const isDarkMode = selectors.selectIsDarkMode(state);

  const {
    name,
    description,
    dueDate,
    stopwatch,
    isSubscribed,
    isActivitiesFetching,
    isAllActivitiesFetched,
    isActivitiesDetailsVisible,
    isActivitiesDetailsFetching,
    districtId,
    stationId,
    lat,
    lng,
    simNumber,
    code,
    createdAt,
  } = selectors.selectCurrentDevice(state);

  const users = selectors.selectUsersForCurrentDevice(state);
  const contents = selectors.selectContentsForCurrentDevice(state);
  const tasks = selectors.selectTasksForCurrentDevice(state);
  const attachments = selectors.selectAttachmentsForCurrentDevice(state);
  const activities = selectors.selectActivitiesForCurrentDevice(state);

  let isCurrentUserEditor = false;
  let isCurrentUserEditorOrCanComment = false;

  if (currentUserMembership) {
    isCurrentUserEditor = currentUserMembership.role === DistrictMembershipRoles.EDITOR;
    isCurrentUserEditorOrCanComment = isCurrentUserEditor || currentUserMembership.canComment;
  }

  return {
    name,
    description,
    dueDate,
    stopwatch,
    isSubscribed,
    isActivitiesFetching,
    isAllActivitiesFetched,
    isActivitiesDetailsVisible,
    isActivitiesDetailsFetching,
    isDarkMode,
    stationId,
    districtId,
    provinceId,
    location: { lat, lng },
    simNumber,
    code,
    createdAt,
    province: { id: provinceId, slug },
    users,
    contents,
    tasks,
    attachments,
    activities,
    allProvincesToStations,
    allDistrictMemberships,
    allContents,
    canEdit: isCurrentUserEditor,
    canEditCommentActivities: isCurrentUserEditorOrCanComment,
    canEditAllCommentActivities: isCurrentUserManager,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdate: entryActions.updateCurrentDevice,
      onMove: entryActions.moveCurrentDevice,
      onTransfer: entryActions.transferCurrentDevice,
      onDelete: entryActions.deleteCurrentDevice,
      onUserAdd: entryActions.addUserToCurrentDevice,
      onUserRemove: entryActions.removeUserFromCurrentDevice,
      onDistrictFetch: entryActions.fetchDistrict,
      onContentAdd: entryActions.addContentToCurrentDevice,
      onContentRemove: entryActions.removeContentFromCurrentDevice,
      onContentCreate: entryActions.createContentInCurrentDistrict,
      onContentUpdate: entryActions.updateContent,
      onContentMove: entryActions.moveContent,
      onContentDelete: entryActions.deleteContent,
      onTaskCreate: entryActions.createTaskInCurrentDevice,
      onTaskUpdate: entryActions.updateTask,
      onTaskMove: entryActions.moveTask,
      onTaskDelete: entryActions.deleteTask,
      onAttachmentCreate: entryActions.createAttachmentInCurrentDevice,
      onAttachmentUpdate: entryActions.updateAttachment,
      onAttachmentDelete: entryActions.deleteAttachment,
      onActivitiesFetch: entryActions.fetchActivitiesInCurrentDevice,
      onActivitiesDetailsToggle: entryActions.toggleActivitiesDetailsInCurrentDevice,
      onCommentActivityCreate: entryActions.createCommentActivityInCurrentDevice,
      onCommentActivityUpdate: entryActions.updateCommentActivity,
      onCommentActivityDelete: entryActions.deleteCommentActivity,
      push,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...omit(dispatchProps, 'push'),
  onClose: () => {
    return dispatchProps.push(
      Paths.DISTRICTS.replace(':slug', stateProps.province.slug).replace(
        ':id',
        stateProps.districtId,
      ),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DeviceModal);
