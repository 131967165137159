import EntryActionTypes from '../constants/EntryActionTypes';

const fetchStations = (page, limit, districtIds) => ({
  type: EntryActionTypes.STATIONS_FETCH,
  payload: {
    page,
    limit,
    districtIds,
  },
});

const fetchStationsLogs = (page, limit, districtIds) => ({
  type: EntryActionTypes.STATIONS_LOGS_FETCH,
  payload: {
    page,
    limit,
    districtIds,
  },
});

const createStationInCurrentDistrict = (data) => ({
  type: EntryActionTypes.STATION_IN_CURRENT_DISTRICT_CREATE,
  payload: {
    data,
  },
});

const handleStationCreate = (station) => ({
  type: EntryActionTypes.STATION_CREATE_HANDLE,
  payload: {
    station,
  },
});

const updateStation = (id, data) => ({
  type: EntryActionTypes.STATION_UPDATE,
  payload: {
    id,
    data,
  },
});

const handleStationUpdate = (station) => ({
  type: EntryActionTypes.STATION_UPDATE_HANDLE,
  payload: {
    station,
  },
});

const moveStation = (id, index) => ({
  type: EntryActionTypes.STATION_MOVE,
  payload: {
    id,
    index,
  },
});

const deleteStation = (id) => ({
  type: EntryActionTypes.STATION_DELETE,
  payload: {
    id,
  },
});

const deleteStationLog = (id) => ({
  type: EntryActionTypes.STATION_LOG_DELETE,
  payload: {
    id,
  },
});

const handleStationDelete = (station) => ({
  type: EntryActionTypes.STATION_DELETE_HANDLE,
  payload: {
    station,
  },
});

const clearStationError = () => ({
  type: EntryActionTypes.STATION_ERROR_CLEAR,
  payload: {},
});

export default {
  createStationInCurrentDistrict,
  clearStationError,
  fetchStations,
  fetchStationsLogs,
  handleStationCreate,
  updateStation,
  handleStationUpdate,
  moveStation,
  deleteStation,
  deleteStationLog,
  handleStationDelete,
};
