const createDataTree = (dataset) => {
  const hashTable = Object.create(null);

  dataset.forEach((aData) => (hashTable[aData.id] = { ...aData, childNodes: [] })); // eslint-disable-line no-return-assign
  const dataTree = [];
  dataset.forEach((aData) => {
    if (aData.parentId) hashTable[aData.parentId]?.childNodes.push(hashTable[aData.id]);
    else dataTree.push(hashTable[aData.id]);
  });
  return dataTree;
};

export default createDataTree;
