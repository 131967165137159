import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Dropdown, Icon, Input, Label, Pagination, Popup, Table } from 'semantic-ui-react';

import { usePopup } from '../../lib/popup';
import Config from '../../constants/Config';
import { ScheduleLogStatusOptions } from '../../constants/Enums';
import durationToTime from '../../utils/duration-to-time';

import DeleteStep from './DeleteStep';

import styles from './SchedulesLogs.module.scss';

const LIMIT = Config.LIMIT_PER_PAGE;

const SchedulesLogs = React.memo(({ items, scheduleLogTotal, onFetch, onDelete }) => {
  const [t] = useTranslation();

  const [limit, setLimit] = useState(LIMIT);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const handleSelectPage = useCallback(
    (_, { activePage }) => {
      setPage(activePage);
      onFetch({ page: activePage, limit });
    },
    [limit, onFetch],
  );

  const handleOnChangeSearch = (_, { value }) => {
    setSearch(value);
  };

  const handleDeleteLog = useCallback((id) => onDelete(id), [onDelete]);

  const handleFilter = useCallback(() => {
    const filter = {
      ...(search && { title: search }),
    };
    setPage(1);
    onFetch({ page: 1, limit, filter });
  }, [search, limit, onFetch]);

  const handleFetchSchedulesLogs = useCallback(() => {
    setPage(1);
    setSearch('');
    onFetch();
  }, [onFetch]);

  const DeletePopup = usePopup(DeleteStep);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperSelect}>
        <Input
          className={styles.marginRight}
          placeholder="Chọn tên"
          icon="filter"
          iconPosition="left"
          value={search}
          onChange={handleOnChangeSearch}
        />
        <Dropdown placeholder="Chọn khu vực" selection options={[]} />
        <Button content={t('action.filter')} className={styles.button} onClick={handleFilter} />
        <Button
          icon="recycle"
          content="Làm mới"
          className={styles.button}
          onClick={handleFetchSchedulesLogs}
        />
      </div>
      <div className={styles.wrapperTable}>
        <Table unstackable singleLine className={styles.table}>
          <Table.Header className={styles.tableHeader}>
            <Table.Row>
              {/* <Table.HeaderCell>STT</Table.HeaderCell> */}
              <Table.HeaderCell>Tên chương trình</Table.HeaderCell>
              <Table.HeaderCell>Danh mục</Table.HeaderCell>
              <Table.HeaderCell>Ngày bắt đầu</Table.HeaderCell>
              <Table.HeaderCell>{t('common.duration')}</Table.HeaderCell>
              <Table.HeaderCell>Người duyệt</Table.HeaderCell>
              <Table.HeaderCell>{t('common.status')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((it, index) => (
              <Table.Row key={it.id}>
                {/* <Table.Cell>{`${page - 1}${index + 1}`}</Table.Cell> */}
                <Table.Cell>{it.title}</Table.Cell>
                <Table.Cell>{it.categories.map(({ name }) => name).join(', ')}</Table.Cell>
                <Table.Cell>
                  {new Date(it.start).toLocaleString('vi-VN', {
                    dateStyle: 'medium',
                    timeStyle: 'medium',
                  })}
                </Table.Cell>
                <Table.Cell>
                  {durationToTime(Math.floor((it.end.getTime() - it.start.getTime()) / 1000), true)}
                </Table.Cell>
                <Table.Cell>{it.user.name}</Table.Cell>
                <Table.Cell>
                  {it.status && (
                    <Label
                      color={ScheduleLogStatusOptions.find(({ key }) => key === it.status).color}
                      content={t(`common.${it.status}`)}
                    />
                  )}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <DeletePopup
                    title={t('common.deleteLog_title')}
                    content={t('common.areYouSureYouWantToDeleteThisLog')}
                    onDelete={() => handleDeleteLog(it.id)}
                  >
                    <Button
                      icon="trash alternate outline"
                      className={classNames(styles.action, styles.delete)}
                    />
                  </DeletePopup>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <div className={styles.pagination}>
        <Pagination
          ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
          firstItem={{ content: <Icon name="angle double left" />, icon: true }}
          lastItem={{ content: <Icon name="angle double right" />, icon: true }}
          prevItem={{ content: <Icon name="angle left" />, icon: true }}
          nextItem={{ content: <Icon name="angle right" />, icon: true }}
          onPageChange={handleSelectPage}
          activePage={page}
          totalPages={Math.ceil(scheduleLogTotal / limit)}
        />
      </div>
    </div>
  );
});

SchedulesLogs.propTypes = {
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  scheduleLogTotal: PropTypes.number.isRequired,
  onFetch: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SchedulesLogs;
