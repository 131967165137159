import React from 'react';
import { Button, Image, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import alertDeleteIcon from '../../../assets/images/alert-delete.png';
import styles from './DeleteDevice.module.scss';

const DeleteDevice = React.memo(({ title, device, onDelete, onClose }) => {
  const [t] = useTranslation();

  const contentNode = (
    <Trans
      i18nKey="common.areYouSureYouWantToDeleteThisDeviceCannotUndo"
      values={{
        device,
      }}
    >
      {device}
    </Trans>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Image src={alertDeleteIcon} alt="" width={48} />
        <div>
          <h4>{title}</h4>
          <p>{contentNode}</p>
        </div>
      </div>
      <div className={styles.actions}>
        <Button onClick={onClose}>{t('action.cancel')}</Button>
        <Button onClick={onDelete}>{t('action.delete')}</Button>
      </div>
    </div>
  );
});

DeleteDevice.propTypes = {
  title: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteDevice;
