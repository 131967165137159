import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Reports from '../components/Reports';
import { USER_LEVELS } from '../constants/Enums';

const mapStateToProps = (state) => {
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  let wards = selectors.selectWardsForCurrentProvince(state);
  const categories = selectors.selectCategoriesForCurrentProvince(state);
  const { level } = selectors.selectCurrentUser(state);

  const {
    report: { contents, contentCategories, devices, districtId, stations },
  } = state.ui.reports;

  const newContents = [];

  contentCategories.forEach(({ contentId, categoryId }) => {
    const findCategory = categories.find((c) => c.id === categoryId);
    const content = contents.find((c) => c.id === contentId);
    newContents.push({ ...content, categories: [findCategory] });
  });

  let district = {};

  if (districtId) {
    district = districts.find((d) => d.id === districtId);
    wards = wards.filter((w) => w.districtId === districtId);
  }

  // const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);

  return {
    district,
    districts,
    contents: newContents,
    devices,
    wards,
    stations,
    canFilter: level === USER_LEVELS.PROVINCE,
    // canEdit: isCurrentUserManager,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onFetchReports: entryActions.getReports,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
