import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Icon, Label, MenuItem, Tab } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import usePopup from '../../../lib/popup/use-popup';
import { pickDayOfWeek } from '../../../utils/custom-repeat';
import { timeToString } from '../../../utils/modify-time';

import AddTime from './AddTime';
import EditTime from './EditTime';
import DeleteStep from '../../DeleteStep';

import styles from './ListSchedule.module.scss';

const ListSchedule = React.memo(
  ({
    activePosition,
    duration,
    items,
    onSelect,
    onAddTime,
    onUpdateTime,
    onDeleteTime,
    onDeleteDate,
  }) => {
    const [t] = useTranslation();

    const DeleteDatePopup = usePopup(DeleteStep);

    const handleClick = useCallback(() => {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }, []);

    const handleTabChange = useCallback(
      (_, { activeIndex }) => {
        onSelect(activeIndex, items[activeIndex]);
      },
      [items, onSelect],
    );

    const renderTimeNode = useCallback(
      (timeId) => {
        const dateTime = items.find((it) => it.id === timeId);

        const times = dateTime.ThoiDiemPhat || [];

        const mergeTimes = times.filter((it, index, source) => {
          return !source
            .slice(index + 1)
            .some(
              (s) =>
                s.ThoiGianBatDau === it.ThoiGianBatDau && s.ThoiGianKetThuc === it.ThoiGianKetThuc,
            );
        });

        return mergeTimes.map((it, i) => (
          <EditTime
            key={it.id || it.ThoiGianBatDau}
            item={it}
            dateTime={dateTime}
            duration={duration}
            position={i}
            canEdit={it.playStatus && it.playStatus === 'pending'}
            onEdit={(updateTime) => onUpdateTime(dateTime, updateTime)}
          >
            <Label
              size="large"
              as="a"
              onRemove={() => onDeleteTime(dateTime, i)}
              className={classNames(
                styles.time,
                it.playStatus && it.playStatus !== 'pending' && styles.disabled,
              )}
              key={it.ThoiGianBatDau}
              content={timeToString(it)}
              onClick={handleClick}
            />
          </EditTime>
        ));
      },
      [items, duration, onUpdateTime, onDeleteTime, handleClick],
    );

    const menuItemChildren = useCallback((item) => {
      const label = pickDayOfWeek(item.NgayPhat, 'ccc, dd/MM/yyyy');

      return <span>{label}</span>;
    }, []);

    const panes = items.map((d, i) => ({
      menuItem: (
        <MenuItem
          key={d.id}
          className={classNames(
            styles.item,
            d.already && d.ThoiDiemPhat.length > 0 && styles.conflict,
            activePosition === i && styles.activeItem,
          )}
        >
          <DeleteDatePopup
            title="common.deleteDate"
            content="common.areYouSureYouWantToDeleteThisDate"
            buttonContent="action.delete"
            onConfirm={() => onDeleteDate(d)}
          >
            <Icon name="trash alternate outline" onClick={(e) => e.stopPropagation()} />
          </DeleteDatePopup>
          {menuItemChildren(d)}
        </MenuItem>
      ),
      render: () => (
        <div key={d.id}>
          {renderTimeNode(d.id)}
          <AddTime
            position="top center"
            item={d}
            duration={duration}
            onAdd={(time) => onAddTime(d.id, time)}
          />
        </div>
      ),
    }));

    return (
      <div className={styles.wrapper}>
        {items.length > 0 ? (
          <Tab
            menu={{ fluid: true, vertical: true }}
            grid={{ paneWidth: 8, tabWidth: 8 }}
            panes={panes}
            onTabChange={handleTabChange}
            activeIndex={activePosition}
          />
        ) : (
          <div>{t('common.noBroadcastSchedules')}</div>
        )}
      </div>
    );
  },
);

export default ListSchedule;

ListSchedule.propTypes = {
  activePosition: PropTypes.number.isRequired,
  duration: PropTypes.number,
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onAddTime: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUpdateTime: PropTypes.func.isRequired,
  onDeleteTime: PropTypes.func.isRequired,
  onDeleteDate: PropTypes.func.isRequired,
};

ListSchedule.defaultProps = {
  duration: 0,
};
