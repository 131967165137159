import ActionTypes from '../constants/ActionTypes';

const createWardMembership = (wardMembership) => ({
  type: ActionTypes.WARD_MEMBERSHIP_CREATE,
  payload: {
    wardMembership,
  },
});

createWardMembership.success = (localId, wardMembership, districtMembership) => ({
  type: ActionTypes.WARD_MEMBERSHIP_CREATE__SUCCESS,
  payload: {
    localId,
    wardMembership,
    districtMembership,
  },
});

createWardMembership.failure = (localId, error) => ({
  type: ActionTypes.WARD_MEMBERSHIP_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleWardMembershipCreate = (
  wardMembership,
  province,
  district,
  users,
  provinceManagers,
  districts,
  wardMemberships,
  contents,
  stations,
  devices,
  deviceMemberships,
  deviceContents,
  tasks,
  attachments,
  deletedNotifications,
) => ({
  type: ActionTypes.WARD_MEMBERSHIP_CREATE_HANDLE,
  payload: {
    wardMembership,
    province,
    district,
    users,
    provinceManagers,
    districts,
    wardMemberships,
    contents,
    stations,
    devices,
    deviceMemberships,
    deviceContents,
    tasks,
    attachments,
    deletedNotifications,
  },
});

handleWardMembershipCreate.fetchProvince = (id, currentUserId, currentWardId) => ({
  type: ActionTypes.WARD_MEMBERSHIP_CREATE_HANDLE__PROVINCE_FETCH,
  payload: {
    id,
    currentUserId,
    currentWardId,
  },
});

const updateWardMembership = (id, data) => ({
  type: ActionTypes.WARD_MEMBERSHIP_UPDATE,
  payload: {
    id,
    data,
  },
});

updateWardMembership.success = (wardMembership) => ({
  type: ActionTypes.WARD_MEMBERSHIP_UPDATE__SUCCESS,
  payload: {
    wardMembership,
  },
});

updateWardMembership.failure = (id, error) => ({
  type: ActionTypes.WARD_MEMBERSHIP_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleWardMembershipUpdate = (wardMembership) => ({
  type: ActionTypes.WARD_MEMBERSHIP_UPDATE_HANDLE,
  payload: {
    wardMembership,
  },
});

const deleteWardMembership = (id) => ({
  type: ActionTypes.WARD_MEMBERSHIP_DELETE,
  payload: {
    id,
  },
});

deleteWardMembership.success = (wardMembership, districtMembership) => ({
  type: ActionTypes.WARD_MEMBERSHIP_DELETE__SUCCESS,
  payload: {
    wardMembership,
    districtMembership,
  },
});

deleteWardMembership.failure = (id, error) => ({
  type: ActionTypes.WARD_MEMBERSHIP_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleWardMembershipDelete = (wardMembership) => ({
  type: ActionTypes.WARD_MEMBERSHIP_DELETE_HANDLE,
  payload: {
    wardMembership,
  },
});

export default {
  createWardMembership,
  handleWardMembershipCreate,
  updateWardMembership,
  handleWardMembershipUpdate,
  deleteWardMembership,
  handleWardMembershipDelete,
};
