import * as React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

import styles from './TreeItem.module.scss';

const TreeItem = React.memo(({ children, name, id, expand, onSelect }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <List.Item>
      {expand ? (
        <List.Icon
          className={styles.icon}
          onClick={() => setIsOpen(!isOpen)}
          name={isOpen ? 'minus square' : 'plus square'}
        />
      ) : (
        <List.Icon className={styles.icon} name="minus square" />
      )}
      <List.Content>
        <List.Header
          className={styles.nameItem}
          as="a"
          onClick={() => (!expand ? onSelect({ name, id }) : setIsOpen(!isOpen))}
        >
          {name}
        </List.Header>
        {isOpen && expand && <List.List>{children}</List.List>}
      </List.Content>
    </List.Item>
  );
});

TreeItem.propTypes = {
  expand: PropTypes.bool,
  name: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  onSelect: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

TreeItem.defaultProps = {
  expand: false,
  children: '',
};

export default TreeItem;
