import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Form, Grid, Input, Label, Select } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import vi from 'date-fns/locale/vi';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { format, getWeekOfMonth } from 'date-fns';
import { RRule } from 'rrule';

import { useForm } from '../../../hooks';
// import { pickDayOfWeek } from '../../../utils/custom-repeat';
import rruleTextVi from '../../../utils/rrule';
// import { BYWEEKDAYS } from '../../../constants/Enums';
import {
  areDatesEqual,
  generateEventsFromRRule,
  setDateToTime,
  setDateToTimeFrame,
  timeToString,
} from '../../../utils/modify-time';
import mergeRecordsSchedule from '../../../utils/merge-records-schedule';

import CustomStep from '../../Schedules/CustomStep';
import ListSchedule from './ListSchedule';
import ViewCalendar from './Calendar';
import AddTime from './AddTime';

import styles from './StepSchedule.module.scss';

registerLocale('vi', vi);

const dateDefault = new Date(2024, 1, 1);

const StepSchedule = React.memo(
  ({ defaultData, locations, schedules, onSelect, onChange, onSelectDeletedSchedules }) => {
    const [t] = useTranslation();

    const [activeDateIndex, setActiveDateIndex] = useState(0);
    const [isShowCustomStep, setIsShowCustomStep] = useState(false);
    const [listSchedule, setListSchedule] = useState(defaultData.DanhSachNgayPhat);
    // const [open, setOpen] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState(defaultData.DanhSachNgayPhat[0]);
    const [timeSlot, setTimeSlot] = useState([]);
    const [datePicker, setDatePicker] = useState(null);

    const [data, handleFieldChange, setData] = useForm(() => ({
      ...defaultData,
      startDate: defaultData.start || new Date(),
      repeatRule: '',
    }));

    const handleClick = useCallback(() => {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }, []);

    const handleDatePickerChange = useCallback(
      (date) => {
        if (!date) return;

        const startDate = new Date();
        startDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());

        let repeatRule = '';

        if (data.repeatRule) {
          const options = RRule.parseString(data.repeatRule);
          options.dtstart = date;
          repeatRule = new RRule(options).toString();
        }

        setData((prevData) => {
          return {
            ...prevData,
            startDate,
            repeatRule,
          };
        });
        setDatePicker(date);
      },
      [data.repeatRule, setData],
    );

    const handleChangeTimeFrame = useCallback((time) => {
      const modifyTime = {
        ThoiGianBatDau: setDateToTime(dateDefault, time.ThoiGianBatDau),
        ThoiGianKetThuc: setDateToTime(dateDefault, time.ThoiGianKetThuc),
      };

      setTimeSlot((prev) => [...prev, modifyTime]);
    }, []);

    const handleDeleteTimeFrame = useCallback((index) => {
      setTimeSlot((prev) => prev.filter((_, i) => i !== index));
    }, []);

    const repeatRuleOptions = useMemo(() => {
      // const WD = pickDayOfWeek(data.startDate, 'eeeee');

      // const r1 = new RRule({
      //   freq: RRule.DAILY,
      //   interval: 1,
      //   dtstart: data.startDate,
      // });
      // const r2 = new RRule({
      //   freq: RRule.WEEKLY,
      //   interval: 1,
      //   dtstart: data.startDate,
      //   byweekday: [BYWEEKDAYS[WD]],
      // });
      // const r3 = new RRule({
      //   freq: RRule.MONTHLY,
      //   interval: 1,
      //   dtstart: data.startDate,
      //   byweekday: BYWEEKDAYS[WD].nth(getWeekOfMonth(data.startDate)),
      // });
      // const r4 = new RRule({
      //   freq: RRule.YEARLY,
      //   interval: 1,
      //   dtstart: data.startDate,
      // });

      const ret = [
        {
          key: 'norepeat',
          value: null,
          text: t(`common.noRepeat`),
        },
        // {
        //   key: 'daily',
        //   value: r1.toString(),
        //   text: rruleTextVi(r1.toText()),
        // },
        // {
        //   key: 'weekly',
        //   value: r2.toString(),
        //   text: rruleTextVi(r2.toText()),
        // },
        // {
        //   key: 'monthly',
        //   value: r3.toString(),
        //   text: rruleTextVi(r3.toText()),
        // },
        // {
        //   key: 'yearly',
        //   value: r4.toString(),
        //   text: `${rruleTextVi(r4.toText())} vào ngày ${format(data.startDate, "d 'tháng' M")}`,
        // },
      ];

      if (
        data.repeatRule &&
        data.repeatRule.includes('DTSTART') &&
        !ret.find((it) => it.value === data.repeatRule)?.key
      ) {
        const r5 = RRule.fromString(data.repeatRule);
        let text = rruleTextVi(r5.toText());

        if (text === 'Hàng tháng') {
          text += ` vào ngày ${format(data.startDate, 'd')}`;
        }
        ret.push({
          key: r5.toString(),
          value: r5.toString(),
          text,
        });
      }

      ret.push({
        key: 'custom',
        value: 'custom',
        text: t(`common.repeatMode`),
      });

      return ret;
    }, [data.repeatRule, data.startDate, t]);

    const handleRepeatRule = useCallback(
      (_, { name, value }) => {
        if (value === 'custom') {
          setIsShowCustomStep((prev) => !prev);
        } else {
          setData((prev) => ({ ...prev, [name]: value }));
        }
      },
      [setData],
    );

    const handleRepeatRuleConfirm = useCallback(
      (rruleString, until) => {
        handleFieldChange(_, { name: 'repeatRule', value: rruleString });
        handleFieldChange(_, { name: 'repeatUntil', value: until });
      },
      [handleFieldChange],
    );

    const handleCloseCustomStep = useCallback(() => {
      setIsShowCustomStep((prev) => !prev);
    }, []);

    const handleSelectDateTime = useCallback(() => {
      const id = new Date(data.startDate);
      const dateTime = new Date(data.startDate).getTime();
      id.setHours(0, 0, 0, 0);

      const event = {
        id: id.toDateString(),
        NgayPhat: dateTime,
        ThoiDiemPhat: setDateToTimeFrame(data.startDate, timeSlot),
      };

      const x = [event];

      // Generate Events From RRule
      if (data.repeatRule) {
        const rr = RRule.fromString(data.repeatRule);

        const generatedEvents = generateEventsFromRRule(rr.all(), data.startDate, timeSlot);

        x.push(...generatedEvents);
        setData((prev) => ({ ...prev, repeatRule: '' }));
      }

      const mergedArray = mergeRecordsSchedule(listSchedule, x);

      mergedArray.sort((a, b) => a.NgayPhat - b.NgayPhat);

      const scheduleHaveAlready = mergedArray.map((s) => {
        const { already, items } = areDatesEqual(s.NgayPhat, s.ThoiDiemPhat, schedules);
        return { ...s, already, schedules: items };
      });

      const eventIndex = scheduleHaveAlready.findIndex((it) => it.id === event.id);

      setActiveDateIndex(eventIndex);
      setListSchedule(scheduleHaveAlready);
      onSelect({ ...data, schedules: scheduleHaveAlready });
      setSelectedSchedule(scheduleHaveAlready[eventIndex]);
      setDatePicker(null);
    }, [listSchedule, timeSlot, schedules, data, setData, onSelect]);

    const handleSelectScheduleFromList = useCallback((activeIndex, event) => {
      setActiveDateIndex(activeIndex);
      setSelectedSchedule(event);
    }, []);

    const handleAddTimeToListSchedule = useCallback(
      (timeId, time) => {
        const updateSchedule = (schedule) => ({
          ...schedule,
          ThoiDiemPhat: [...schedule.ThoiDiemPhat, time],
        });

        const updateListSchedule = listSchedule.map((it) =>
          it.id === timeId ? updateSchedule(it) : it,
        );

        setListSchedule(updateListSchedule);
        setSelectedSchedule((prev) => ({ ...prev, ThoiDiemPhat: [...prev.ThoiDiemPhat, time] }));
        onSelect({ ...data, schedules: updateListSchedule });
      },
      [listSchedule, data, onSelect],
    );

    const handleRemoveTimeToListSchedule = useCallback(
      (date, index) => {
        const filterTime = date.ThoiDiemPhat.filter((_, i) => i !== index);

        const { already } = areDatesEqual(date.NgayPhat, filterTime, date.schedules);

        const updateSchedule = (schedule) => ({
          ...schedule,
          already,
          ThoiDiemPhat: filterTime,
        });

        const newListSchedule = listSchedule.map((it) =>
          it.id === date.id ? updateSchedule(it) : it,
        );
        const currentSelectedSchedule = { ...date, already, ThoiDiemPhat: filterTime };

        setListSchedule(newListSchedule);
        setSelectedSchedule(currentSelectedSchedule);
        onSelect({ ...data, schedules: newListSchedule });

        if (date.ThoiDiemPhat[index]?.id) {
          onSelectDeletedSchedules([date.ThoiDiemPhat[index]]);
        }
      },
      [listSchedule, data, onSelect, onSelectDeletedSchedules],
    );

    const handleEditTimeToListSchedule = useCallback(
      (date, updateTime) => {
        const { already } = areDatesEqual(date.NgayPhat, updateTime, date.schedules);

        const updateSchedule = (schedule) => ({
          ...schedule,
          already,
          ThoiDiemPhat: updateTime,
        });

        const newListSchedule = listSchedule.map((it) =>
          it.id === date.id ? updateSchedule(it) : it,
        );

        const currentSelectedSchedule = { ...date, already, ThoiDiemPhat: updateTime };

        setListSchedule(newListSchedule);
        setSelectedSchedule(currentSelectedSchedule);
        onSelect({ ...data, schedules: newListSchedule });
      },
      [listSchedule, data, onSelect],
    );

    const handleDeleteDateInListSchedule = useCallback(
      (date) => {
        const filterListSchedule = listSchedule.filter((it) => it.id !== date.id);

        const scheduleExistToDelete = date.ThoiDiemPhat.filter((s) => s.id);

        setListSchedule(filterListSchedule);
        setSelectedSchedule(filterListSchedule[0]);
        setActiveDateIndex(0);
        onSelect({ ...data, schedules: filterListSchedule });

        if (scheduleExistToDelete.length > 0) {
          onSelectDeletedSchedules(date.ThoiDiemPhat);
        }
      },
      [listSchedule, data, onSelect, onSelectDeletedSchedules],
    );

    return (
      <div className={styles.wrapper}>
        <Grid>
          <Grid.Column widescreen={8} largeScreen={8} computer={8} tablet={8} mobile={16}>
            <Form.Group widths="equal" className={styles.groupField}>
              <Form.Field className={styles.marginBottom}>
                <span>{t('common.date')}</span>
                <ReactDatePicker
                  calendarClassName={styles.calendar}
                  wrapperClassName={styles.calendarWrapper}
                  dateFormat="d/M/yyyy"
                  disabledKeyboardNavigation
                  selected={datePicker}
                  onChange={handleDatePickerChange}
                  // excludeDates={listSchedule.length > 0 && listSchedule.map((d) => d.NgayPhat)}
                />
              </Form.Field>
              <Form.Field className={styles.marginBottom}>
                <span>{t('common.nameSchedule')}</span>
                <Input
                  name="TenLichPhat"
                  placeholder={t('common.nameSchedule')}
                  value={defaultData.TenLichPhat}
                  onChange={onChange}
                />
              </Form.Field>
            </Form.Group>
            <div className={styles.timeSlot}>
              <div className={styles.caption}>
                <span>{t('common.timeSlot')}</span>
                <AddTime
                  item={{
                    NgayPhat: dateDefault,
                    ThoiDiemPhat: timeSlot,
                  }}
                  duration={defaultData.duration}
                  position="right center"
                  onAdd={handleChangeTimeFrame}
                />
              </div>
              {timeSlot.length > 0 && (
                <div className={styles.border}>
                  {timeSlot.map((it, i) => (
                    <Label
                      size="large"
                      as="a"
                      onRemove={() => handleDeleteTimeFrame(i)}
                      className={styles.time}
                      key={it.ThoiGianBatDau}
                      content={timeToString(it)}
                      onClick={handleClick}
                    />
                  ))}
                </div>
              )}
            </div>
            <Form.Field>
              <span>{t('common.repeatMode')}</span>
              <Select
                name="repeatRule"
                options={repeatRuleOptions}
                value={data.repeatRule}
                placeholder={t('common.noRepeat')}
                onChange={handleRepeatRule}
              />
            </Form.Field>
            <Button
              type="button"
              disabled={!datePicker}
              content={t('action.create')}
              onClick={handleSelectDateTime}
            />
            <Divider />
            <ListSchedule
              items={listSchedule}
              duration={defaultData.duration}
              activePosition={activeDateIndex}
              onSelect={handleSelectScheduleFromList}
              onAddTime={handleAddTimeToListSchedule}
              onUpdateTime={handleEditTimeToListSchedule}
              onDeleteTime={handleRemoveTimeToListSchedule}
              onDeleteDate={handleDeleteDateInListSchedule}
            />
          </Grid.Column>
          <Grid.Column widescreen={8} largeScreen={8} computer={8} tablet={8} mobile={16}>
            <ViewCalendar
              contentName={defaultData.name}
              locations={locations}
              schedule={selectedSchedule}
              schedules={schedules}
            />
          </Grid.Column>
        </Grid>
        {isShowCustomStep && (
          <CustomStep
            title="common.repeatRuleCustom"
            repeatRule={data.repeatRule}
            startDate={data.startDate}
            never={false}
            dayOfMonth={false}
            onConfirm={handleRepeatRuleConfirm}
            onClose={handleCloseCustomStep}
          />
        )}
      </div>
    );
  },
);

export default StepSchedule;

StepSchedule.propTypes = {
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  schedules: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  locations: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelectDeletedSchedules: PropTypes.func.isRequired,
};
