import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import ScheduleEditModal from '../components/ScheduleEditModal';

const mapStateToProps = (state, { slots }) => {
  const {
    data: defaultData,
    isSubmitting,
    isPlaying,
    schedule: item,
    // error,
  } = state.ui.scheduleCreateForm;
  const { accessToken } = state.auth;
  const { slug } = selectors.selectCurrentProvince(state);

  const contents = selectors.selectContentsForCurrentProvince(state);
  const { name, permissions } = selectors.selectCurrentUser(state);
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);

  let schedule;
  if (slots.event.id) {
    // schedule = selectors.selectScheduleById(state, slots.id);
    schedule = slots.event;
    const users = selectors.selectUsers(state);

    const creatorUser = users.find((user) => user.id === slots.event.creatorUserId);
    schedule.user = creatorUser;
  } else {
    const { event } = slots;
    event.end.setDate(event.start.getDate());
    // event.end.setMinutes(event.end.getMinutes() + 10);
    schedule = {
      start: event.start,
      end: event.end,
      // title: event.title,
      // contentId: slots.contentId,
    };
  }

  const isCurrentUserEditor = permissions.includes('CAN_EDIT');
  const isCurrentUserEditorOrCanComment = false;

  return {
    defaultData: { ...defaultData, ...schedule },
    contents,
    slug,
    isSubmitting,
    isPlaying: isPlaying && item.id === slots.event.id,
    canEdit: isCurrentUserEditor,
    canEditCommentActivities: isCurrentUserEditorOrCanComment,
    canEditAllCommentActivities: isCurrentUserManager,
    username: name,
    token: accessToken,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdate: entryActions.updateSchedule,
      onDelete: entryActions.deleteSchedule,
      onErrorClear: entryActions.clearScheduleError,
      onEditContent: entryActions.selectContentId,
      onRefetchContents: entryActions.enalbleContentsFetching,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleEditModal);
