import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import { DistrictMembershipRoles } from '../constants/Enums';
import District from '../components/District';

const mapStateToProps = (state) => {
  const { deviceId } = selectors.selectPath(state);
  const currentUserMembership = selectors.selectCurrentUserMembershipForCurrentDistrict(state);
  const stationIds = selectors.selectStationIdsForCurrentDistrict(state);
  const wardIds = selectors.selectWardIdsForCurrentDistrict(state);

  const isCurrentUserEditor =
    !!currentUserMembership && currentUserMembership.role === DistrictMembershipRoles.EDITOR;

  return {
    stationIds,
    wardIds,
    isDeviceModalOpened: !!deviceId,
    canEdit: isCurrentUserEditor,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onStationCreate: entryActions.createStationInCurrentDistrict,
      onStationMove: entryActions.moveStation,
      onDeviceMove: entryActions.moveDevice,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(District);
