import EntryActionTypes from '../constants/EntryActionTypes';

const createProvince = (data) => ({
  type: EntryActionTypes.PROVINCE_CREATE,
  payload: {
    data,
  },
});

const handleProvinceCreate = (province) => ({
  type: EntryActionTypes.PROVINCE_CREATE_HANDLE,
  payload: {
    province,
  },
});

const updateCurrentProvince = (data) => ({
  type: EntryActionTypes.CURRENT_PROVINCE_UPDATE,
  payload: {
    data,
  },
});

const handleProvinceUpdate = (province) => ({
  type: EntryActionTypes.PROVINCE_UPDATE_HANDLE,
  payload: {
    province,
  },
});

const updateCurrentProvinceBackgroundImage = (data) => ({
  type: EntryActionTypes.CURRENT_PROVINCE_BACKGROUND_IMAGE_UPDATE,
  payload: {
    data,
  },
});

const deleteCurrentProvince = () => ({
  type: EntryActionTypes.CURRENT_PROVINCE_DELETE,
  payload: {},
});

const handleProvinceDelete = (province) => ({
  type: EntryActionTypes.PROVINCE_DELETE_HANDLE,
  payload: {
    province,
  },
});

export default {
  createProvince,
  handleProvinceCreate,
  updateCurrentProvince,
  handleProvinceUpdate,
  updateCurrentProvinceBackgroundImage,
  deleteCurrentProvince,
  handleProvinceDelete,
};
