import { all, takeEvery } from 'redux-saga/effects';

import EntryActionTypes from '../../../constants/EntryActionTypes';
import services from '../services';

export default function* reportsWarchers() {
  yield all([
    takeEvery(EntryActionTypes.REPORTS_FETCH, ({ payload: { districtId } }) =>
      services.getReports(districtId),
    ),
    takeEvery(EntryActionTypes.REPORTS_EXPORT, ({ payload: { data } }) =>
      services.getExportReport(data),
    ),
    takeEvery(EntryActionTypes.REPORTS_EXPORT_CLEAR, ({ payload: { data } }) =>
      services.clearExportState(data),
    ),
  ]);
}
