import socket from './socket';

/* Actions */

const createStation = (districtId, data, headers) =>
  socket.post(`/districts/${districtId}/stations`, data, headers);

const getStations = (provinceId, data, headers) =>
  socket.get(`/provinces/${provinceId}/stations`, data, headers);

const updateStation = (id, data, headers) => socket.patch(`/stations/${id}`, data, headers);

const deleteStation = (id, headers) => socket.delete(`/stations/${id}`, undefined, headers);

export default {
  createStation,
  getStations,
  updateStation,
  deleteStation,
};
