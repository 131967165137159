import socket from './socket';

/* Actions */

const createDeviceContent = (deviceId, data, headers) =>
  socket.post(`/devices/${deviceId}/contents`, data, headers);

const deleteDeviceContent = (deviceId, contentId, headers) =>
  socket.delete(`/devices/${deviceId}/contents/${contentId}`, undefined, headers);

export default {
  createDeviceContent,
  deleteDeviceContent,
};
