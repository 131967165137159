import socket from './socket';

/* Actions */

const createCategory = (provinceId, data, headers) =>
  socket.post(`/provinces/${provinceId}/categories`, data, headers);

// const getContents = (provinceId, data, headers) =>
//   socket.get(`/provinces/${provinceId}/contents`, data, headers);

const updateCategory = (id, data, headers) => socket.patch(`/categories/${id}`, data, headers);

const deleteCategory = (id, headers) => socket.delete(`/categories/${id}`, undefined, headers);

export default {
  createCategory,
  deleteCategory,
  updateCategory,
};
