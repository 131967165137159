import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Config from '../constants/Config';
import { USER_LEVELS } from '../constants/Enums';

import UserLog from '../components/UserLog';

const mapStateToProps = (state) => {
  const { actionTotal } = selectors.selectCurrentProvince(state);

  let users = selectors.selectUsers(state);

  const { level, email } = selectors.selectCurrentUser(state);
  const { location } = selectors.selectAreaForCurrentUser(state);

  if (level === USER_LEVELS.DISTRICT) {
    users = selectors.selectUsersForCurrentDistrict(state, location.id);
    users = users.filter((user) => user.level !== USER_LEVELS.PROVINCE);
  } else if (level === USER_LEVELS.WARD) {
    users = selectors.selectUsersForCurrentWard(state, location.id);
  }

  if (email !== Config.ADMIN_EMAIL) {
    users = users.filter((user) => user.email !== Config.ADMIN_EMAIL);
  }

  const actions = selectors.selectActions(state);

  return { items: actions, users, total: actionTotal };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onFetch: entryActions.fetchActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserLog);
