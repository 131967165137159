import socket from './socket';

/* Actions */

const createDeviceMembership = (deviceId, data, headers) =>
  socket.post(`/devices/${deviceId}/memberships`, data, headers);

const deleteDeviceMembership = (deviceId, userId, headers) =>
  socket.delete(`/devices/${deviceId}/memberships?userId=${userId}`, undefined, headers);

export default {
  createDeviceMembership,
  deleteDeviceMembership,
};
