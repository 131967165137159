import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import { push } from '../lib/redux-router';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Paths from '../constants/Paths';
import DeviceAddModal from '../components/DeviceAddModal';

const mapStateToProps = (state, { selectedId, isViewing }) => {
  const { data: defaultData, isSubmitting, deviceId, error } = state.ui.deviceCreateForm;
  const { provinceId } = selectors.selectPath(state);
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);
  const isDarkMode = selectors.selectIsDarkMode(state);

  let device;
  const attachments = [];
  if (selectedId) {
    device = selectors.selectDeviceById(state, selectedId);
    Object.keys(device).forEach((k) => device[k] == null && delete device[k]);

    // attachments = selectors.selectAttachmentsByDeviceId(state, selectedId);
  }

  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const stations = selectors.selectStationsForCurrentProvince(state);
  const wards = selectors.selectWardsForCurrentProvince(state);
  const hamlets = selectors.selectHamletsForCurrentProvince(state);
  const { permissions } = selectors.selectCurrentUser(state);

  return {
    defaultData: { ...defaultData, ...device },
    isDarkMode,
    provinceId,
    attachments,
    districts,
    stations,
    wards,
    hamlets,
    isSubmitting,
    isViewing,
    isEditting: selectedId !== undefined && !isViewing,
    deviceId,
    canAdd: permissions.includes('CAN_ADD'),
    canEdit: permissions.includes('CAN_EDIT'),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: (data) => entryActions.createDevice(data.stationId, data, false),
      onUpdate: entryActions.updateDevice,
      onDelete: entryActions.deleteCurrentDevice,
      onAttachmentCreate: entryActions.createAttachmentToDevice,
      onAttachmentUpdate: entryActions.updateAttachment,
      onAttachmentDelete: entryActions.deleteAttachment,
      // onClose: entryActions.closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeviceAddModal);
