import { attr, fk } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';
import Config from '../constants/Config';

export default class extends BaseModel {
  static modelName = 'UserGroup';

  static fields = {
    id: attr(),
    name: attr(),
    provinceId: fk({
      to: 'Province',
      as: 'province',
      relatedName: 'userGroups',
    }),
    // deviceId: fk({
    //   to: 'Device',
    //   as: 'device',
    //   relatedName: 'userGroups',
    // }),
    creatorUserId: fk({
      to: 'User',
      as: 'user',
      relatedName: 'userGroups',
    }),
  };

  static reducer({ type, payload }, UserGroup) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
        if (payload.userGroups) {
          UserGroup.all().delete();

          payload.userGroups.forEach((userGroup) => {
            UserGroup.upsert(userGroup);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        if (payload.userGroups) {
          UserGroup.all().delete();

          payload.userGroups.forEach((userGroup) => {
            UserGroup.upsert(userGroup);
          });
        }

        break;
      case ActionTypes.USER_GROUP_CREATE:
      case ActionTypes.USER_GROUP_CREATE_HANDLE:
      case ActionTypes.USER_GROUP_UPDATE__SUCCESS:
      case ActionTypes.USER_GROUP_UPDATE_HANDLE:
        if (payload.userGroup) {
          UserGroup.upsert(payload.userGroup);
        }

        break;
      case ActionTypes.USER_GROUP_CREATE__SUCCESS:
        UserGroup.withId(payload.localId).delete();

        if (payload.userGroup) {
          const userGroupTotal = UserGroup.count();

          if (userGroupTotal >= Config.LIMIT_PER_PAGE) {
            const userGroupList = UserGroup.orderBy('createdAt', false).toRefArray();
            const lastObject = userGroupList.slice(-1)[0];

            UserGroup.withId(lastObject.id).delete();
            UserGroup.upsert(payload.userGroup);
          } else {
            UserGroup.upsert(payload.userGroup);
          }
        }

        break;
      case ActionTypes.USER_GROUP_UPDATE:
        UserGroup.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.USER_GROUP_DELETE:
        // UserGroup.withId(payload.id).delete();

        break;
      case ActionTypes.USER_GROUP_DELETE__SUCCESS:
      case ActionTypes.USER_GROUP_DELETE_HANDLE: {
        const userGroupModel = UserGroup.withId(payload.userGroup.id);

        if (userGroupModel) {
          userGroupModel.delete();
        }

        break;
      }
      case ActionTypes.USER_GROUPS_FETCH__SUCCESS:
        UserGroup.all().delete();

        payload.userGroups.forEach((userGroup) => {
          UserGroup.upsert(userGroup);
        });

        break;
      default:
    }
  }

  static getOrderedUserGroupsQuerySet() {
    return this.all().orderBy('createdAt', false);
  }
}
