import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import { USER_PERMISSIONS } from '../constants/Enums';

import SettingProvinceCategory from '../components/SettingProvinceCategory';

const mapStateToProps = (state) => {
  const { name, id: provinceId, code } = selectors.selectCurrentProvince(state);

  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const wards = selectors.selectWardsForCurrentProvince(state);
  const hamlets = selectors.selectHamletsForCurrentProvince(state);
  const { isAdmin, level } = selectors.selectCurrentUser(state);

  let permission = {
    district: [],
    ward: [],
    hamlet: [],
  };

  const userPermission = Object.values(USER_PERMISSIONS);

  if (level === 'province') {
    permission = {
      district: isAdmin ? userPermission : [],
      ward: isAdmin ? userPermission : [],
      hamlet: isAdmin ? userPermission : [],
    };
  }

  if (level === 'district') {
    permission = {
      district: isAdmin ? [userPermission[1]] : [],
      ward: isAdmin ? userPermission : [],
      hamlet: isAdmin ? userPermission : [],
    };
  }

  if (level === 'ward') {
    permission = {
      district: [],
      ward: isAdmin ? [userPermission[1]] : [],
      hamlet: isAdmin ? userPermission : [],
    };
  }

  return {
    districts,
    wards,
    hamlets,
    name,
    provinceId,
    permission,
    code,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreateDistrict: entryActions.createDistrictInCurrentProvince,
      onCreateHamlet: entryActions.createHamletInCurrentProvince,
      onCreateWard: entryActions.createWardInCurrentProvince,
      onUpdateDistrict: entryActions.updateDistrict,
      onUpdateHamlet: entryActions.updateHamlet,
      onUpdateWard: entryActions.updateWard,
      onDeleteDistrict: entryActions.deleteDistrict,
      onDeleteHamlet: entryActions.deleteHamlet,
      onDeleteWard: entryActions.deleteWard,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SettingProvinceCategory);
