import { all, takeEvery } from 'redux-saga/effects';

import EntryActionTypes from '../../../constants/EntryActionTypes';
import services from '../services';

export default function* liveBroadcastWatchers() {
  yield all([
    takeEvery(EntryActionTypes.LIVE_BROADCAST_START, ({ payload: { data } }) =>
      services.liveBroadcast(data),
    ),
    takeEvery(EntryActionTypes.LIVE_BROADCAST_STOP, ({ payload: { data } }) =>
      services.liveBroadcastStop(data),
    ),
    takeEvery(EntryActionTypes.LIVE_BROADCAST_ERROR_HANDLE, ({ payload: { message } }) =>
      services.handleBroadcastingError(message),
    ),
    takeEvery(EntryActionTypes.LIVE_BROADCAST_FINISHED_HANDLE, ({ payload: { message } }) =>
      services.handleBroadcastFinished(message),
    ),
    takeEvery(EntryActionTypes.LIVE_BROADCAST_CLEAR, () => services.clearBroadcast()),
    takeEvery(EntryActionTypes.LIVE_BROADCAST_MESSAGE_CLOSE, () =>
      services.closeMessageBroadcast(),
    ),
    takeEvery(EntryActionTypes.OVERRIDE_CONTENT_BY_MICROPHONE, ({ payload: { content } }) =>
      services.handleOverrideContentByMicrophone(content),
    ),
  ]);
}
