import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Label } from 'semantic-ui-react';
import classNames from 'classnames';
import { useDidUpdate, useToggle } from '../../../lib/hooks';
import { Input, Popup } from '../../../lib/custom-ui';
import { useForm } from '../../../hooks';
import ScheduleColors from '../../../constants/ScheduleColors';

import styles from './AddStep.module.scss';

const AddStep = React.memo(({ parentId, onCreate, onClose }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange, setData] = useForm({
    name: '',
    color: ScheduleColors[0],
    position: 0,
    value: 1,
  });

  const [focusNameFieldState, focusNameField] = useToggle();

  const nameField = useRef(null);
  const valueField = useRef(null);
  const colorField = useRef(null);

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      name: data.name.trim(),
      position: data.position,
      value: data.value,
      ...(parentId && { parentId }),
    };

    if (!cleanData.name) {
      nameField.current.select();
      return;
    }

    if (!cleanData.value) {
      valueField.current.select();
      return;
    }

    if (!cleanData.color) {
      colorField.current.select();
      return;
    }

    onCreate(cleanData);
    onClose();
  }, [onClose, data, onCreate, parentId]);

  useEffect(() => {
    nameField.current.focus({
      preventScroll: true,
    });
  }, []);

  useDidUpdate(() => {
    nameField.current.focus();
  }, [focusNameFieldState]);

  return (
    <>
      <Popup.Header>
        {t('common.createCategory_title', {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Form onSubmit={handleSubmit}>
          <Input
            placeholder={t('common.nameCategory')}
            fluid
            ref={nameField}
            name="name"
            value={data.name}
            className={styles.field}
            onChange={handleFieldChange}
          />
          <Input
            // placeholder={t('common.position')}
            fluid
            ref={valueField}
            name="value"
            value={data.value}
            className={styles.field}
            onChange={handleFieldChange}
          />
          <div className={styles.text}>{t('common.color')}</div>
          {ScheduleColors.map((color) => (
            <Button
              key={color}
              type="button"
              ref={colorField}
              name="color"
              value={color}
              className={classNames(
                styles.colorButton,
                color === data.color && styles.colorButtonActive,
              )}
              style={{ backgroundColor: color }}
              onClick={handleFieldChange}
            />
          ))}
          <div className={styles.controls}>
            <Button positive content={t('action.createCategory')} className={styles.createButton} />
          </div>
        </Form>
      </Popup.Content>
    </>
  );
});

AddStep.propTypes = {
  parentId: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
AddStep.defaultProps = {
  parentId: '',
};

export default AddStep;
