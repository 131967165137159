import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Header from '../components/Header';

const mapStateToProps = (state) => {
  const isLogouting = selectors.selectIsLogouting(state);
  const currentUser = selectors.selectCurrentUser(state);
  const currentProvince = selectors.selectCurrentProvince(state);
  const notifications = selectors.selectNotificationsForCurrentUser(state);
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);
  const isDarkMode = selectors.selectIsDarkMode(state);
  const {
    pattern: { path },
  } = selectors.selectPathsMatch(state);
  const { location } = selectors.selectAreaForCurrentUser(state);

  const { unreadTotal, notificationTotal, notificationType } = currentProvince;

  let items = notifications;

  if (notificationType === 'read') {
    items = notifications.filter((n) => n.isRead);
  }

  return {
    notifications: items,
    isLogouting,
    province: currentProvince,
    unreadTotal,
    notificationTotal,
    user: currentUser,
    isDarkMode,
    path,
    canEditProvince: isCurrentUserManager,
    canEditUsers: currentUser.isAdmin,
    location,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onProvinceSettingsClick: entryActions.openProvinceSettingsModal,
      onUsersClick: entryActions.openUsersModal,
      onNotificationUpdate: entryActions.updateNotification,
      onUserSettingsClick: entryActions.openUserSettingsModal,
      onLogout: entryActions.logout,
      onSidebarMenu: entryActions.changeSidebarMenu,
      onFetchNotifications: entryActions.fetchNotifications,
      onNotificationDelete: entryActions.deleteNotification,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
