import Paths from './Paths';

export default {
  device: [
    { name: 'manageDevice', path: Paths.DEVICES },
    // { name: 'manageDisplay', path: Paths.DISPLAYS },
    { name: 'manageZone', path: Paths.DISTRICTS },
  ],
  content: [
    { name: 'contentBroadcast', path: Paths.CONTENTS },
    { name: 'publicElectronicBulletin', path: Paths.ELECTRONIC_CONTENTS },
    { name: 'liveBroadcast', path: '/' },
  ],
  schedule: [
    { name: 'schedules', path: Paths.SCHEDULES },
    { name: 'historyAired', path: Paths.SCHEDULES_LOGS },
  ],
  setting: [
    { name: 'generalSettings', path: Paths.SETTINGS },
    { name: 'installDistrictCommuneDirectory', path: Paths.SETTING_PROVINCE_CATEGOGY },
    { name: 'accountSettings', path: Paths.SETTING_USERS },
    { name: 'userGroupSettings', path: Paths.SETTING_USER_GROUPS },
  ],
};
