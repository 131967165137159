import { attr, fk } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';
import Config from '../constants/Config';

export default class extends BaseModel {
  static modelName = 'Station';

  static fields = {
    id: attr(),
    position: attr(),
    name: attr(),
    description: attr(),
    phone: attr(),
    status: attr(),
    lat: attr(),
    lng: attr(),
    provinceId: fk({
      to: 'Province',
      as: 'province',
      relatedName: 'stations',
    }),
    districtId: fk({
      to: 'District',
      as: 'district',
      relatedName: 'stations',
    }),
    wardId: fk({
      to: 'Ward',
      as: 'ward',
      relatedName: 'stations',
    }),
  };

  static reducer({ type, payload }, Station) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE:
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE:
      case ActionTypes.ACTIONS_FETCH__SUCCESS:
        if (payload.stations) {
          payload.stations.forEach((station) => {
            Station.upsert(station);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Station.all().delete();

        if (payload.stations) {
          payload.stations.forEach((station) => {
            Station.upsert(station);
          });
        }

        break;
      case ActionTypes.STATIONS_FETCH__SUCCESS:
      case ActionTypes.DISTRICT_FETCH__SUCCESS:
        Station.all().delete();

        payload.stations.forEach((station) => {
          Station.upsert(station);
        });

        break;
      case ActionTypes.STATION_CREATE:
      case ActionTypes.STATION_CREATE_HANDLE:
      case ActionTypes.STATION_UPDATE__SUCCESS:
      case ActionTypes.STATION_UPDATE_HANDLE:
        Station.upsert(payload.station);

        break;
      case ActionTypes.STATION_CREATE__SUCCESS: {
        Station.withId(payload.localId).delete();

        const stationTotal = Station.all().count();
        if (stationTotal < Config.LIMIT_PER_PAGE) {
          Station.upsert(payload.station);
        }

        break;
      }
      case ActionTypes.STATION_UPDATE:
        Station.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.STATION_DELETE:
        // Station.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.STATION_DELETE__SUCCESS:
      case ActionTypes.STATION_DELETE_HANDLE: {
        const stationModel = Station.withId(payload.station.id);

        if (stationModel) {
          stationModel.deleteWithRelated();
        }

        break;
      }

      case ActionTypes.CONTENT_FETCH_STATIONS_BROADCASTED__SUCCESS:
      case ActionTypes.DEVICES_FETCH__SUCCESS:
      case ActionTypes.NOTIFICATION_FETCH__SUCCESS:
        if (payload.stations) {
          payload.stations.forEach((item) => {
            Station.upsert(item);
          });
        }

        break;
      default:
    }
  }

  getOrderedDevicesQuerySet() {
    return this.devices.orderBy('position');
  }

  getFilteredOrderedDevicesModelArray() {
    let deviceModels = this.getOrderedDevicesQuerySet().toModelArray();

    const filterUserIds = this.district.filterUsers.toRefArray().map((user) => user.id);
    const filterContentIds = this.district.filterContents.toRefArray().map((content) => content.id);

    if (filterUserIds.length > 0) {
      deviceModels = deviceModels.filter((deviceModel) => {
        const users = deviceModel.users.toRefArray();

        return users.some((user) => filterUserIds.includes(user.id));
      });
    }

    if (filterContentIds.length > 0) {
      deviceModels = deviceModels.filter((deviceModel) => {
        const contents = deviceModel.contents.toRefArray();

        return contents.some((content) => filterContentIds.includes(content.id));
      });
    }

    return deviceModels;
  }

  deleteRelated() {
    this.devices.toModelArray().forEach((deviceModel) => {
      deviceModel.deleteWithRelated();
    });
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
