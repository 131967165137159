import socket from './socket';

/* Actions */

const liveBroadcast = (provinceId, data, headers) =>
  socket.post(`/provinces/${provinceId}/live-broadcast`, data, headers);

export default {
  liveBroadcast,
};
