import { call, put, select } from 'redux-saga/effects';
import { push } from '../../../lib/redux-router';

import selectors from '../../../selectors';
import Paths from '../../../constants/Paths';
import actions from '../../../actions';
import { getItemStorage } from '../../../utils/local-storage';

export const selectQRCode = ({
  ui: {
    authenticateForm: { qrcode },
  },
}) => qrcode;

export const selectExpires = ({
  ui: {
    authenticateForm: { expires },
  },
}) => expires;

export function* goToLogin() {
  yield put(push(Paths.LOGIN));
}

export function* goToScanQRCode(token) {
  yield put(push(`${Paths.SCAN_QRCODE}?${token}`));
}

export function* goToVerifyCode(token) {
  yield put(push(`${Paths.VERIFY_CODE}?${token}`));
}

export function* goToRoot() {
  yield put(push(Paths.ROOT));
}

export function* handleLocationChange() {
  const pathsMatch = yield select(selectors.selectPathsMatch);

  if (!pathsMatch) {
    return;
  }

  switch (pathsMatch.pattern.path) {
    case Paths.ROOT:
    case Paths.PROVINCES:
    case Paths.CONTENTS:
    case Paths.STATIONS:
    case Paths.SETTINGS:
    case Paths.SETTING_USERS:
    case Paths.REPORTS:
    case Paths.DISTRICTS:
    case Paths.DEVICES:
    case Paths.DEVICE:
    case Paths.USER:
      yield call(goToLogin);

      break;

    case Paths.SCAN_QRCODE: {
      const isQRcode = yield call(getItemStorage, 'qrcode');

      if (!isQRcode) {
        yield call(goToLogin);
      }

      const qrcodeLocal = yield select(selectQRCode);

      if (!qrcodeLocal) {
        const qrcode = yield call(getItemStorage, 'qrcode');

        if (qrcode) {
          yield put(actions.getQRCodeAuthenticate(qrcode));
        }
      }
      break;
    }

    case Paths.VERIFY_CODE: {
      const isVerify = yield call(getItemStorage, 'isVerify');

      if (!JSON.parse(isVerify)) {
        yield call(goToLogin);
      }

      const expiresLocal = yield select(selectExpires);

      if (!expiresLocal) {
        const expiresCode = yield call(getItemStorage, 'expires');

        if (expiresCode) {
          yield put(actions.getExpiresCodeAuthenticate(expiresCode));
        }
      }
      break;
    }
    default:
  }
}

export default {
  goToLogin,
  goToScanQRCode,
  goToVerifyCode,
  goToRoot,
  handleLocationChange,
};
