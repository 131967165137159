import { createSelector } from 'redux-orm';

import orm from '../orm';

export const makeSelectDistrictMembershipById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ DistrictMembership }, id) => {
      const districtMembershipModel = DistrictMembership.withId(id);

      if (!districtMembershipModel) {
        return districtMembershipModel;
      }

      return districtMembershipModel.ref;
    },
  );

export const selectDistrictMembershipById = makeSelectDistrictMembershipById();

export const selectDistrictMemberships = createSelector(orm, ({ DistrictMembership }) => {
  return DistrictMembership.all().toRefArray();
});

export const selectDistrictMembershipIds = createSelector(orm, ({ DistrictMembership }) => {
  return DistrictMembership.all()
    .toRefArray()
    .map((membership) => membership.userId);
});

export default {
  makeSelectDistrictMembershipById,
  selectDistrictMembershipById,
  selectDistrictMemberships,
  selectDistrictMembershipIds,
};
