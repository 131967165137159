import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { uniq } from 'lodash';

import entryActions from '../entry-actions';
import selectors from '../selectors';

import UserPermissionEditStep from '../components/UserPermissionEditStep';

const mapStateToProps = (state, { email, isCurrentUser }) => {
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const wards = selectors.selectWardsForCurrentProvince(state);
  const province = selectors.selectCurrentProvince(state);

  let user;

  if (isCurrentUser) {
    user = selectors.selectCurrentUser(state);
  } else {
    const users = selectors.selectUsersExceptCurrent(state);
    user = users.find((u) => u.email === email);
  }

  const groups = selectors.selectUserGroupsForCurrentProvince(state);

  let locationId;
  // let role;
  let group;

  if (user) {
    if (user.level === 'province') {
      // const districtMemberships = selectors.selectDistrictMembershipsByUserId(state, user.id);
      // const roles = uniq(districtMemberships.map((member) => member.role));

      locationId = province.id;
      // [role] = roles;
    }

    if (user.level === 'district') {
      const districtMemberships = selectors.selectDistrictMembershipsByUserId(state, user.id);
      // const roles = uniq(districtMemberships.map((member) => member.role));
      const [districtMembership] = districtMemberships;

      locationId = districtMembership?.districtId || '';
      // [role] = roles;
    }

    if (user.level === 'ward') {
      const wardMemberships = selectors.selectWardMembershipsByUserId(state, user.id);

      const [wardMembership] = wardMemberships;

      // const roles = uniq(wardMemberships.map((member) => member.role));

      locationId = wardMembership?.wardId || '';
      // [role] = roles;
    }

    group = selectors.selectGroupByUserId(state, user.id);
  }

  return {
    provinces: [province],
    districts,
    wards,
    user: {
      ...user,
      // role
    },
    locationId,
    group,
    groups,
    isSubmitting: user.permissionUpdateForm.isSubmitting,
    error: user.permissionUpdateForm.error,
    usePasswordConfirmation: !!isCurrentUser,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createUser,
      onMessageDismiss: entryActions.clearUserPermissionUpdateError,
      onUpdate: entryActions.updateUserPermission,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissionEditStep);
