import EntryActionTypes from '../constants/EntryActionTypes';

const getReports = (districtId) => ({
  type: EntryActionTypes.REPORTS_FETCH,
  payload: { districtId },
});

const getExportReport = (data) => ({
  type: EntryActionTypes.REPORTS_EXPORT,
  payload: { data },
});

const clearExportState = () => ({
  type: EntryActionTypes.REPORTS_EXPORT_CLEAR,
  payload: {},
});

export default {
  getReports,
  getExportReport,
  clearExportState,
};
