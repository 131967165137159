import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import ProvinceSettingsModal from '../components/ProvinceSettingsModal';

const mapStateToProps = (state) => {
  const users = selectors.selectUsers(state);

  const { name, background, backgroundImage, isBackgroundImageUpdating } =
    selectors.selectCurrentProvince(state);

  const managers = selectors.selectManagersForCurrentProvince(state);
  const isDarkMode = selectors.selectIsDarkMode(state);

  return {
    name,
    background,
    backgroundImage,
    isBackgroundImageUpdating,
    isDarkMode,
    managers,
    allUsers: users,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdate: entryActions.updateCurrentProvince,
      onBackgroundImageUpdate: entryActions.updateCurrentProvinceBackgroundImage,
      onDelete: entryActions.deleteCurrentProvince,
      onManagerCreate: entryActions.createManagerInCurrentProvince,
      onManagerDelete: entryActions.deleteProvinceManager,
      onClose: entryActions.closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProvinceSettingsModal);
