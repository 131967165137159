import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './DevicesOperation.module.scss';

const filterDeviceByType = (sources, type) => {
  if (sources.length <= 0) {
    return { on: 0, off: 0, total: 0 };
  }

  return sources
    .filter((d) => d.type === type)
    .reduce(
      (rs, cur) => {
        /* eslint-disable no-param-reassign */
        rs.on = ['transmitting', 'transmissionPaused'].includes(cur.status) ? rs.on + 1 : rs.on;
        rs.off = ['new', 'transmissionOff'].includes(cur.status) ? rs.off + 1 : rs.off;
        rs.total = rs.on + rs.off;
        return rs;
      },
      { on: 0, off: 0, total: 0 },
    );
};

const DevicesOperation = React.memo(({ devices }) => {
  const [t] = useTranslation();

  const speakersActive = filterDeviceByType(devices, 'speaker');

  const screensActive = filterDeviceByType(devices, 'screen');

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.title}>{t('common.operationOfTheDevice')}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.item}>
          <p className={styles.count}>
            {speakersActive.total}
            <span>{t('common.speaker')}</span>
          </p>
          <input
            className={styles.progressBar}
            type="range"
            value={
              speakersActive.total ? Math.ceil((speakersActive.on / speakersActive.total) * 100) : 0
            }
            readOnly
          />
          <div className={styles.footer}>
            <div className={styles.label}>
              <div className={classNames(styles.tickLabel, styles.tickLabelActive)} />
              <div className={styles.textLabel}>
                <p>{t('common.activity')}</p>
                <p>
                  {speakersActive.on} {t('common.devices')}
                </p>
              </div>
            </div>
            <div className={styles.label}>
              <div className={styles.tickLabel} />
              <div className={styles.textLabel}>
                <p>{t('common.inactive')}</p>
                <p>
                  {speakersActive.off} {t('common.devices')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.item}>
          <p className={styles.count}>
            {screensActive.total}
            <span>{t('common.screen')}</span>
          </p>
          <input
            className={styles.progressBar}
            type="range"
            value={
              screensActive.total ? Math.ceil((screensActive.on / screensActive.total) * 100) : 0
            }
            readOnly
          />
          <div className={styles.footer}>
            <div className={styles.label}>
              <div className={classNames(styles.tickLabel, styles.tickLabelActive)} />
              <div className={styles.textLabel}>
                <p>{t('common.activity')}</p>
                <p>
                  {screensActive.on} {t('common.devices')}
                </p>
              </div>
            </div>
            <div className={styles.label}>
              <div className={styles.tickLabel} />
              <div className={styles.textLabel}>
                <p>{t('common.inactive')}</p>
                <p>
                  {screensActive.off} {t('common.devices')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

DevicesOperation.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DevicesOperation;
