const DISTRICT = 'DISTRICT';
const CONTENT = 'CONTENT';
const STATION = 'STATION';
const DEVICE = 'DEVICE';
const TASK = 'TASK';

export default {
  DISTRICT,
  CONTENT,
  STATION,
  DEVICE,
  TASK,
};
