import ActionTypes from '../constants/ActionTypes';

const createStation = (station) => ({
  type: ActionTypes.STATION_CREATE,
  payload: {
    station,
  },
});

createStation.success = (localId, station) => ({
  type: ActionTypes.STATION_CREATE__SUCCESS,
  payload: {
    localId,
    station,
  },
});

createStation.failure = (localId, error) => ({
  type: ActionTypes.STATION_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleStationCreate = (station) => ({
  type: ActionTypes.STATION_CREATE_HANDLE,
  payload: {
    station,
  },
});

const fetchStations = (provinceId) => ({
  type: ActionTypes.STATIONS_FETCH,
  payload: {
    provinceId,
  },
});

fetchStations.success = (provinceId, stations, total) => ({
  type: ActionTypes.STATIONS_FETCH__SUCCESS,
  payload: {
    provinceId,
    stations,
    total,
  },
});

fetchStations.failure = (provinceId, error) => ({
  type: ActionTypes.STATIONS_FETCH__FAILURE,
  payload: {
    provinceId,
    error,
  },
});

const updateStation = (id, data) => ({
  type: ActionTypes.STATION_UPDATE,
  payload: {
    id,
    data,
  },
});

updateStation.success = (station) => ({
  type: ActionTypes.STATION_UPDATE__SUCCESS,
  payload: {
    station,
  },
});

updateStation.failure = (id, error) => ({
  type: ActionTypes.STATION_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleStationUpdate = (station) => ({
  type: ActionTypes.STATION_UPDATE_HANDLE,
  payload: {
    station,
  },
});

const deleteStation = (id) => ({
  type: ActionTypes.STATION_DELETE,
  payload: {
    id,
  },
});

deleteStation.success = (station) => ({
  type: ActionTypes.STATION_DELETE__SUCCESS,
  payload: {
    station,
  },
});

deleteStation.failure = (id, error) => ({
  type: ActionTypes.STATION_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleStationDelete = (station) => ({
  type: ActionTypes.STATION_DELETE_HANDLE,
  payload: {
    station,
  },
});

const clearStationError = () => ({
  type: ActionTypes.STATION_ERROR_CLEAR,
  payload: {},
});

export default {
  createStation,
  handleStationCreate,
  fetchStations,
  updateStation,
  handleStationUpdate,
  deleteStation,
  handleStationDelete,
  clearStationError,
};
