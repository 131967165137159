import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Form, Grid, Header, MenuItem, Modal, Tab } from 'semantic-ui-react';

import styles from './SelectAddress.module.scss';

const SelectAddress = React.memo(
  ({ locationSelected, districts, wards, onClose, onSelectLocation }) => {
    const [t] = useTranslation();

    const [selectedAddress, setSelectedAddress] = useState(locationSelected);

    const handleSelectWard = useCallback((districtId, wardId) => {
      setSelectedAddress((prev) => {
        if (!prev[districtId]) {
          return { ...prev, [districtId]: [wardId] };
        }

        const wardIds = prev[districtId];

        const filterWardIds = wardIds.filter((id) => id !== wardId);

        return {
          ...prev,
          [districtId]: wardIds.includes(wardId) ? filterWardIds : [...wardIds, wardId],
        };
      });
    }, []);

    const handleSelectAll = useCallback(
      (districtId) => {
        const filterWards = wards.filter((w) => w.districtId === districtId);

        const wardIds = filterWards.map((it) => it.id);

        setSelectedAddress((prev) => ({
          ...prev,
          [districtId]:
            prev[districtId] && prev[districtId].length === wardIds.length ? [] : wardIds,
        }));
      },
      [wards],
    );

    const handleSave = useCallback(() => {
      const location = selectedAddress;

      Object.keys(location).forEach((key) => location[key].length <= 0 && delete location[key]);

      onSelectLocation(location);
      onClose();
    }, [selectedAddress, onClose, onSelectLocation]);

    const renderWardNode = useCallback(
      (districtId) => {
        const wardsInDistrict = wards.filter((w) => w.districtId === districtId);

        const selectedWardIds = selectedAddress[districtId] || [];

        return (
          <Form>
            <Form.Group grouped>
              {wardsInDistrict.map((it) => (
                <Form.Checkbox
                  key={it.id}
                  label={it.name}
                  checked={selectedWardIds.includes(it.id)}
                  onClick={() => handleSelectWard(districtId, it.id)}
                />
              ))}
            </Form.Group>
          </Form>
        );
      },
      [selectedAddress, wards, handleSelectWard],
    );

    const menuItemChildren = useCallback(
      (item) => {
        const wardsInDistrict = wards.filter((w) => w.districtId === item.id).map((w) => w.id);

        const selectedDistricts = Object.keys(selectedAddress);

        const isIndeterminate =
          selectedDistricts.includes(item.id) &&
          selectedAddress[item.id].length > 0 &&
          selectedAddress[item.id].length < wardsInDistrict.length;

        const isChecked =
          selectedDistricts.includes(item.id) &&
          selectedAddress[item.id].length === wardsInDistrict.length;

        return (
          <>
            <Checkbox
              indeterminate={isIndeterminate}
              checked={isChecked}
              onClick={() => handleSelectAll(item.id)}
            />
            {item.name}
          </>
        );
      },
      [selectedAddress, wards, handleSelectAll],
    );

    const panes = districts.map((d) => ({
      menuItem: (
        <MenuItem key={d.id} className={styles.item}>
          {menuItemChildren(d)}
        </MenuItem>
      ),
      render: () => renderWardNode(d.id),
    }));

    return (
      <Modal open closeIcon centered={false} onClose={onClose} className={styles.wrapper}>
        <Header>Danh sách địa bàn</Header>
        <Modal.Content className={styles.content}>
          <Tab
            grid={{ paneWidth: 11, tabWidth: 5 }}
            menu={{ fluid: true, vertical: true, tabular: true }}
            panes={panes}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" content={t('action.cancel')} negative onClick={onClose} />
          <Button type="button" content={t('action.save')} positive onClick={handleSave} />
        </Modal.Actions>
      </Modal>
    );
  },
);

export default SelectAddress;

SelectAddress.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  locationSelected: PropTypes.object.isRequired,
  districts: PropTypes.array.isRequired,
  wards: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectLocation: PropTypes.func.isRequired,
};
