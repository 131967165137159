import ActionTypes from '../constants/ActionTypes';

const handleNotificationCreate = (
  notification,
  users,
  devices,
  activities,
  contents,
  stations,
) => ({
  type: ActionTypes.NOTIFICATION_CREATE_HANDLE,
  payload: {
    notification,
    users,
    devices,
    activities,
    contents,
    stations,
  },
});

const updateNotification = (id) => ({
  type: ActionTypes.NOTIFICATION_UPDATE,
  payload: {
    id,
  },
});

updateNotification.success = (notification) => ({
  type: ActionTypes.NOTIFICATION_UPDATE__SUCCESS,
  payload: {
    notification,
  },
});

updateNotification.failure = (id, error) => ({
  type: ActionTypes.NOTIFICATION_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const deleteNotification = (id) => ({
  type: ActionTypes.NOTIFICATION_DELETE,
  payload: {
    id,
  },
});

deleteNotification.success = (notification) => ({
  type: ActionTypes.NOTIFICATION_DELETE__SUCCESS,
  payload: {
    notification,
  },
});

deleteNotification.failure = (id, error) => ({
  type: ActionTypes.NOTIFICATION_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleNotificationDelete = (notification) => ({
  type: ActionTypes.NOTIFICATION_DELETE_HANDLE,
  payload: {
    notification,
  },
});

const fetchNotifications = (
  notifications,
  users,
  devices,
  activities,
  contents,
  stations,
  type,
  total,
  isChangeType,
) => ({
  type: ActionTypes.NOTIFICATION_FETCH__SUCCESS,
  payload: {
    notifications,
    users,
    devices,
    activities,
    contents,
    stations,
    type,
    total,
    isChangeType,
  },
});

export default {
  handleNotificationCreate,
  updateNotification,
  deleteNotification,
  handleNotificationDelete,
  fetchNotifications,
};
