import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import { push } from '../lib/redux-router';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Paths from '../constants/Paths';
import ScheduleModal from '../components/ScheduleModal';

const mapStateToProps = (state, { slots }) => {
  const { data: defaultData, isSubmitting, error } = state.ui.scheduleCreateForm;
  const { provinceId } = selectors.selectPath(state);
  const contents = selectors.selectContentsForCurrentProvince(state);
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);

  let schedule;
  if (slots.event.id) {
    // schedule = selectors.selectScheduleById(state, slots.id);
    schedule = slots.event;
  } else {
    const { event } = slots;
    event.end.setDate(event.start.getDate());

    if (event.start.getMonth() !== event.end.getMonth()) {
      event.end.setMonth(event.start.getMonth());
    }
    // event.end.setMinutes(event.end.getMinutes() + 10);
    schedule = {
      start: event.start,
      end: event.end,
      // title: event.title,
      // contentId: slots.contentId,
    };
  }

  const isCurrentUserEditor = false;
  const isCurrentUserEditorOrCanComment = false;

  return {
    // name,
    // color,
    // type,
    // file,
    // fileDirname,
    // playCount,
    // status,
    // description,
    defaultData: { ...defaultData, ...schedule },
    contents,
    provinceId,
    isSubmitting,
    isEditting: !!slots.event.id,
    canEdit: isCurrentUserEditor,
    canEditCommentActivities: isCurrentUserEditorOrCanComment,
    canEditAllCommentActivities: isCurrentUserManager,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createScheduleInCurrentProvince,
      onUpdate: entryActions.updateSchedule,
      onDelete: entryActions.deleteCurrentDevice,
      onErrorClear: entryActions.clearScheduleError,
      // onClose: entryActions.closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleModal);
