import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Stations from '../components/Stations';

const mapStateToProps = (state) => {
  const { districtId } = selectors.selectPath(state);
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const wards = selectors.selectWardsForCurrentProvince(state);
  const stations = selectors.selectStationsForCurrentProvince(state);
  const { name, stationTotal } = selectors.selectCurrentProvince(state);
  // const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);
  const { permissions, isAdmin } = selectors.selectCurrentUser(state);
  const { error } = state.ui.stationCreateForm;

  return {
    items: stations || [],
    districts,
    wards,
    currentId: districtId,
    canAdd: permissions.includes('CAN_ADD') && isAdmin,
    canDelete: permissions.includes('CAN_DELETE') && isAdmin,
    canEdit: permissions.includes('CAN_EDIT'),
    canView: permissions.includes('CAN_VIEW'),
    province: { name, stationTotal },
    error,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createDistrictInCurrentProvince,
      onUpdate: entryActions.updateDistrict,
      onMove: entryActions.moveDistrict,
      onDelete: entryActions.deleteStation,
      onFetchStations: entryActions.fetchStations,
      onClear: entryActions.clearStationError,
      // onEditStationClick: entryActions.openEditStationModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Stations);
