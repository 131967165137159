import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Paths from '../../constants/Paths';

import styles from './CustomToolbar.module.scss';

const getClassActive = (type, view) => {
  if (type === view) return 'rbc-active';

  return '';
};

const CustomToolbar = React.memo((toolbar) => {
  const [t] = useTranslation();

  const { onNavigate, onView, label, view, slug, onRefetchContents } = toolbar;

  const goToView = (type) => {
    onView(type);
  };

  return (
    <div className={`${styles.wrapperToolbar} rbc-toolbar`}>
      <div className="rbc-btn-group">
        <Link to={Paths.CONTENTS.replace(':slug', slug)} onClick={onRefetchContents}>
          <Button className={styles.buttonFilter} content={t('menu.contents')} />
        </Link>
        <Button type="button" onClick={() => onNavigate('TODAY')}>
          {t('common.today')}
        </Button>
        <Button type="button" onClick={() => onNavigate('PREV')}>
          {`<`}
        </Button>
        <Button type="button" onClick={() => onNavigate('NEXT')}>
          {`>`}
        </Button>
      </div>
      <div className="rbc-toolbar-label">{label}</div>
      <div className="rbc-btn-group">
        <Button
          className={getClassActive('month', view)}
          type="button"
          onClick={() => goToView('month')}
        >
          {t('common.month')}
        </Button>
        <Button
          className={getClassActive('week', view)}
          type="button"
          onClick={() => goToView('week')}
        >
          {t('common.week')}
        </Button>
        <Button
          className={getClassActive('day', view)}
          type="button"
          onClick={() => goToView('day')}
        >
          {t('common.day')}
        </Button>
        <Button
          className={getClassActive('agenda', view)}
          type="button"
          onClick={() => goToView('agenda')}
        >
          {t('common.contents')}
        </Button>
      </div>
    </div>
  );
});

export default CustomToolbar;
