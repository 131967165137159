import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Feed, Image, Menu } from 'semantic-ui-react';
import Paths from '../../constants/Paths';

import { ReactComponent as SettingUserIcon } from '../../assets/icons/menu/setting-user.svg';
import { ReactComponent as UserLogIcon } from '../../assets/icons/menu/user-log.svg';
import { ReactComponent as HelpIcon } from '../../assets/icons/menu/help.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/menu/logout.svg';
import styles from './UserMenu.module.scss';
import User from '../User';

function UserMenu({ sidebarMenu, onLogout, user }) {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const handleClick = (event) => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handler = (e) => {
      if (!ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  return (
    <div ref={ref} className={styles.wrapper}>
      <Button onClick={handleClick} className={styles.button}>
        <div className={classNames(!sidebarMenu && styles.iconUser)}>
          <User name={user.name} avatarUrl={user.avatarUrl} size="medium" />
        </div>
        {sidebarMenu && <span className={styles.username}>{user.name}</span>}
      </Button>
      <Menu vertical className={classNames(styles.menu, open && styles.show)}>
        <Menu.Item
          as={Link}
          to={Paths.USER.replace(':id', user.id)}
          className={styles.item}
          onClick={handleClose}
        >
          <SettingUserIcon />
          {t('menu.userSettings')}
        </Menu.Item>
        {/* <Menu.Item className={styles.item} onClick={handleClose}>
          <UserLogIcon />
          {t('menu.userLogs')}
        </Menu.Item>
        <Menu.Item className={styles.item} onClick={handleClose}>
          <HelpIcon />
          {t('menu.helps')}
        </Menu.Item> */}
        <Menu.Item className={styles.item} onClick={onLogout}>
          <LogoutIcon stroke="#DC362E" />
          {t('action.logOut_title')}
        </Menu.Item>
      </Menu>
    </div>
  );
}

UserMenu.propTypes = {
  sidebarMenu: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  user: PropTypes.object.isRequired,
};

export default UserMenu;
