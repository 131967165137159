import ActionTypes from '../constants/ActionTypes';

const createDistrict = (district) => ({
  type: ActionTypes.DISTRICT_CREATE,
  payload: {
    district,
  },
});

createDistrict.success = (localId, district, districtMemberships) => ({
  type: ActionTypes.DISTRICT_CREATE__SUCCESS,
  payload: {
    localId,
    district,
    districtMemberships,
  },
});

createDistrict.failure = (localId, error) => ({
  type: ActionTypes.DISTRICT_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleDistrictCreate = (district) => ({
  type: ActionTypes.DISTRICT_CREATE_HANDLE,
  payload: {
    district,
  },
});

const fetchDistrict = (id) => ({
  type: ActionTypes.DISTRICT_FETCH,
  payload: {
    id,
  },
});

fetchDistrict.success = (
  district,
  users,
  provinces,
  districtMemberships,
  contents,
  contentCategories,
  stations,
  devices,
  deviceMemberships,
  deviceContents,
  tasks,
  attachments,
) => ({
  type: ActionTypes.DISTRICT_FETCH__SUCCESS,
  payload: {
    district,
    users,
    provinces,
    districtMemberships,
    contents,
    contentCategories,
    stations,
    devices,
    deviceMemberships,
    deviceContents,
    tasks,
    attachments,
  },
});

fetchDistrict.failure = (id, error) => ({
  type: ActionTypes.DISTRICT_FETCH__FAILURE,
  payload: {
    id,
    error,
  },
});

const updateDistrict = (id, data) => ({
  type: ActionTypes.DISTRICT_UPDATE,
  payload: {
    id,
    data,
  },
});

updateDistrict.success = (district) => ({
  type: ActionTypes.DISTRICT_UPDATE__SUCCESS,
  payload: {
    district,
  },
});

updateDistrict.failure = (id, error) => ({
  type: ActionTypes.DISTRICT_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleDistrictUpdate = (district) => ({
  type: ActionTypes.DISTRICT_UPDATE_HANDLE,
  payload: {
    district,
  },
});

const deleteDistrict = (id) => ({
  type: ActionTypes.DISTRICT_DELETE,
  payload: {
    id,
  },
});

deleteDistrict.success = (district) => ({
  type: ActionTypes.DISTRICT_DELETE__SUCCESS,
  payload: {
    district,
  },
});

deleteDistrict.failure = (id, error) => ({
  type: ActionTypes.DISTRICT_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleDistrictDelete = (district) => ({
  type: ActionTypes.DISTRICT_DELETE_HANDLE,
  payload: {
    district,
  },
});

export default {
  createDistrict,
  handleDistrictCreate,
  fetchDistrict,
  updateDistrict,
  handleDistrictUpdate,
  deleteDistrict,
  handleDistrictDelete,
};
