import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
  Select,
  Image,
  Label,
  TextArea,
  Popup,
} from 'semantic-ui-react';
import SimpleMDE from 'react-simplemde-editor';
import { format, getWeekOfMonth } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import { RRule } from 'rrule';

import { usePopup } from '../../lib/popup';
import { useForm } from '../../hooks';
import { FilePicker } from '../../lib/custom-ui';
import getLinkStream from '../../utils/get-link-stream';
import { detailDayInMonth, pickDayOfWeek } from '../../utils/custom-repeat';
import rruleTextVi from '../../utils/rrule';
import { ScheduleStatusOptions, BYWEEKDAYS } from '../../constants/Enums';

import DeleteStep from '../DeleteStep';
import CustomStep from '../Schedules/CustomStep';

import styles from './ScheduleModal.module.scss';
import ScheduleEditModalContainer from '../../containers/ScheduleEditModalContainer';
import SearchContentContainer from '../../containers/SearchContentContainer';

const ScheduleModal = React.memo(
  ({
    // name,
    // color,
    // type,
    // file,
    // fileDirname,
    // playCount,
    // status,
    // description,
    defaultData,
    contents,
    // provinceId,
    canEdit,
    isEditting,
    isSubmitting,
    slots,
    onCreate,
    onUpdate,
    onDelete,
    onErrorClear,
    onClose,
    onSetStyleModal,
  }) => {
    const [t] = useTranslation();
    const titleField = useRef(null);
    const startRef = useRef(null);
    const endRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [data, handleFieldChange, setData] = useForm(() => ({
      ...defaultData,
      startDate: defaultData.start,
      description: '',
      // attachment: '',
      color: '#006C4C',
      // startDate: format(defaultData.start, 'dd/MM/yyyy'),
      // startDate: t('format:date', {
      //   postProcess: 'formatDate',
      //   value: defaultData.startDate || new Date().setHours(12, 0, 0, 0),
      // }),
      // start: t('format:time', {
      //   postProcess: 'formatDate',
      //   value: defaultData.start || new Date().setHours(12, 0, 0, 0),
      // }),
    }));
    const [currentAudioUrl, setCurrentAudioUrl] = useState('');
    const [isShowCustomStep, setIsShowCustomStep] = useState(false);

    const contentCurrent = contents.find(({ id }) => id === data.contentId);
    const attachment =
      contentCurrent?.type === 'file' // eslint-disable-line no-nested-ternary
        ? contentCurrent?.attachments?.[0]
        : contentCurrent?.type === 'stream'
        ? {
            name: getLinkStream(contentCurrent.description),
            url: getLinkStream(contentCurrent.description),
          }
        : undefined;

    const handleDatePickerChange = useCallback(
      (date) => {
        if (!date) return;

        setData((prevData) => {
          const { start, end } = prevData;
          start.setDate(date.getDate());
          end.setDate(date.getDate());
          return {
            ...prevData,
            startDate: date,
            start,
            end,
            // start: prevData.start.setDate(),
          };
        });
        // startRef.current.setFocus();
        // selectTimeField();
      },
      [setData],
    );

    const handleStartTimeChange = useCallback(
      (date) => {
        setData((prevData) => ({
          ...prevData,
          start: date,
          ...(date > prevData.end && { end: date }),
        }));
        // selectTimeField();
        // endRef.current.setFocus();
      },
      [setData],
    );

    const handleEndTimeChange = useCallback(
      (date) => {
        setData((prevData) => ({
          ...prevData,
          end: date,
        }));
        // selectTimeField();
      },
      [setData],
    );

    // const handleFileSelect = useCallback(
    //   (file) => {
    //     setData((prevData) => ({
    //       ...prevData,
    //       attachment: file,
    //     }));
    //   },
    //   [setData],
    // );

    const handleContentSelect = useCallback(
      (id) => {
        setData((prevData) => ({
          ...prevData,
          contentId: id,
        }));
        setCurrentAudioUrl('');
      },
      [setData],
    );

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    const handleOnSelect = useCallback(() => {
      startRef.current.setFocus();
    }, []);

    const handleOnCalendarClose = useCallback(() => {
      if (data.end <= data.start) {
        setOpen(true);
      }
    }, [data.end, data.start]);

    const handlePlay = useCallback(
      (url) => {
        if (url === currentAudioUrl && currentAudioUrl !== '') {
          setCurrentAudioUrl('');
        } else {
          setCurrentAudioUrl(url);
        }
      },
      [currentAudioUrl],
    );

    const handleSubmit = useCallback(() => {
      const content = contents.find(({ id }) => id === data.contentId);
      // const [sd, sm, sy] = data.startDate.split('/');
      // const st = data.start.split(':');
      // const start = new Date(sy, sm - 1, sd, ...st);
      // const [ed, em, ey] = data.startDate.split('/');
      // const et = data.end.split(':');
      // const end = new Date(ey, em - 1, ed, ...et);

      const cleanData = {
        contentId: data.contentId,
        title: data.title.trim() || content?.name,
        start: data.start,
        end: data.end,
        repeatRule: data.repeatRule?.trim() || undefined,
        repeatUntil: data.repeatUntil || undefined,
        color: data.color.trim() || undefined,
        status: 'active',
      };

      if (!cleanData.title) {
        // nameField.current.select();
        return;
      }

      if (!cleanData.contentId) {
        return;
      }

      if (cleanData.start >= cleanData.end) {
        endRef.current.setFocus();
        return;
      }

      if (isEditting) {
        onUpdate(data.id, cleanData);
        onClose();
      } else {
        onCreate(cleanData);
      }
      onClose();
    }, [
      contents,
      data.contentId,
      data.title,
      data.start,
      data.end,
      data.repeatRule,
      data.repeatUntil,
      data.color,
      data.id,
      isEditting,
      onClose,
      onUpdate,
      onCreate,
    ]);

    const handleRepeatRule = useCallback(
      (_, { name, value }) => {
        if (value === 'custom') {
          setIsShowCustomStep((prev) => !prev);
        } else {
          setData((prev) => ({ ...prev, [name]: value }));
        }
      },
      [setData],
    );

    const handleRepeatRuleConfirm = useCallback(
      (rruleString, until) => {
        handleFieldChange(_, { name: 'repeatRule', value: rruleString });
        handleFieldChange(_, { name: 'repeatUntil', value: until });
      },
      [handleFieldChange],
    );

    const handleCloseCustomStep = useCallback(() => {
      setIsShowCustomStep((prev) => !prev);
    }, []);

    // const DeletePopup = usePopup(DeleteStep);

    const handleWindowMouseDown = useCallback(
      (e) => {
        if (e.target.className.includes('modals')) return;
        if (!e.target.closest('.rbc-calendar')) onClose();
        if (e.target.closest('.rbc-toolbar')) onClose();
        if (isEditting) onClose();
      },
      [isEditting, onClose],
    );

    const handleMeClick = useCallback((e) => {
      e.stopPropagation();
    }, []);

    const repeatRuleOptions = useMemo(() => {
      const WD = pickDayOfWeek(data.startDate, 'eeeee');

      const r1 = new RRule({
        freq: RRule.DAILY,
        interval: 1,
        dtstart: data.startDate,
      });
      const r2 = new RRule({
        freq: RRule.WEEKLY,
        interval: 1,
        dtstart: data.startDate,
        byweekday: [BYWEEKDAYS[WD]],
      });
      const r3 = new RRule({
        freq: RRule.MONTHLY,
        interval: 1,
        dtstart: data.startDate,
        byweekday: BYWEEKDAYS[WD].nth(detailDayInMonth(data.startDate).freq),
      });
      const r4 = new RRule({
        freq: RRule.YEARLY,
        interval: 1,
        dtstart: data.startDate,
      });

      const ret = [
        {
          key: 'norepeat',
          value: null,
          text: t(`common.noRepeat`),
        },
        {
          key: 'daily',
          value: r1.toString(),
          text: rruleTextVi(r1.toText()),
        },
        {
          key: 'weekly',
          value: r2.toString(),
          text: rruleTextVi(r2.toText()),
        },
        {
          key: 'monthly',
          value: r3.toString(),
          text: rruleTextVi(r3.toText()),
        },
        {
          key: 'yearly',
          value: r4.toString(),
          text: `${rruleTextVi(r4.toText())} vào ngày ${format(data.startDate, "d 'tháng' M")}`,
        },
      ];

      if (
        data.repeatRule &&
        data.repeatRule.includes('DTSTART') &&
        !ret.find((it) => it.value === data.repeatRule)?.key
      ) {
        const r5 = RRule.fromString(data.repeatRule);
        let text = rruleTextVi(r5.toText());

        if (text === 'Hàng tháng') {
          text += ` vào ngày ${format(data.startDate, 'd')}`;
        }
        ret.push({
          key: r5.toString(),
          value: r5.toString(),
          text,
        });
      }

      ret.push({
        key: 'custom',
        value: 'custom',
        text: t(`common.custom`),
      });

      return ret;
    }, [data.repeatRule, data.startDate, t]);

    useEffect(() => {
      setData((prev) => ({
        ...defaultData,
        contentId: isEditting ? '' : defaultData.contentId || prev.contentId,
        title: prev.title,
        startDate: defaultData.start,
        color: isEditting ? '#006C4C' : prev.color,
        start: prev.start,
        end: prev.end,
        // startTime: format(defaultData.start, 'HH:mm'),
        // endTime: format(defaultData.end, 'HH:mm'),
      }));
    }, [defaultData, isEditting, setData]);

    useEffect(() => {
      if (titleField.current) titleField.current.focus();
    }, []);

    useEffect(() => {
      window.addEventListener('click', handleWindowMouseDown);

      return () => {
        window.removeEventListener('click', handleWindowMouseDown);
      };
    }, [handleWindowMouseDown]);

    useEffect(() => {
      if (open) {
        endRef.current.setFocus();
      }
    }, [open]);

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        open
        // closeIcon
        // centered={false}
        size="tiny"
        // onClose={handleClose}
        style={slots.style}
        className={classNames(styles.wrapper, isEditting && styles.paddingEditModal)}
        onClick={handleMeClick}
        onKeyDown={handleMeClick}
      >
        {isEditting ? (
          <ScheduleEditModalContainer
            onClose={onClose}
            slots={slots}
            onSetStyleModal={onSetStyleModal}
          />
        ) : (
          <>
            {/* <AttachmentAddZone isDarkMode={isDarkMode} onCreate={onAttachmentCreate}> */}
            <div size="huge" className={styles.header}>
              <div>
                {t(isEditting ? 'common.editSchedule' : 'common.createSchedule', {
                  context: 'title',
                })}
              </div>
              <Button icon="close" onClick={onClose} />
            </div>
            {/* </AttachmentAddZone> */}

            <Modal.Content>
              <Form onSubmit={handleSubmit}>
                <Form.Group widths="equal" className={styles.field}>
                  <Form.Field width={1}>
                    <Icon name="newspaper outline" />
                  </Form.Field>
                  <Form.Field width={15}>
                    <SearchContentContainer onSelect={handleContentSelect} />
                  </Form.Field>
                  {/* <Form.Field
                    width={15}
                    id="form-input-control-name"
                    name="contentId"
                    control={Select}
                    options={contents.map((it) => ({ key: it.id, value: it.id, text: it.name }))}
                    placeholder={t('common.selectContent')}
                    required
                    value={data.contentId}
                    onChange={handleFieldChange}
                  /> */}
                </Form.Group>
                <Form.Group widths="equal" className={styles.wrapperFields}>
                  <Form.Field width={1} className={styles.field}>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <Icon name="paint brush" className={styles.labelMobile} />
                    <label
                      htmlFor="form-input-color"
                      className={styles.pickColor}
                      style={{ backgroundColor: data.color }}
                    >
                      <input
                        id="form-input-color"
                        type="color"
                        value={data.color}
                        onChange={(e) =>
                          handleFieldChange(_, { name: 'color', value: e.target.value })
                        }
                      />
                    </label>
                  </Form.Field>
                  <Form.Field width={15} className={styles.field}>
                    <Icon name="id card outline" className={styles.labelMobile} />
                    <Input
                      ref={titleField}
                      id="form-input-control-name"
                      name="title"
                      placeholder={t('common.name')}
                      value={data.title}
                      onChange={handleFieldChange}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal" className={styles.field}>
                  <Form.Field width={1}>
                    <Icon name="time" />
                  </Form.Field>
                  <Form.Group className={styles.fieldTime}>
                    <Form.Field width={8} className={styles.marginBottom}>
                      <span className={styles.labelMobile}>{t('common.date')}</span>
                      <ReactDatePicker
                        dateFormat="d/M/yyyy"
                        disabledKeydistrictNavigation
                        selected={data.startDate}
                        onChange={handleDatePickerChange}
                        onSelect={handleOnSelect}
                        onKeyDown={(e) => e.key === 'Enter' && startRef.current.setFocus()}
                      />
                    </Form.Field>
                    <Form.Field name="startTime" width={4} className={styles.marginBottom}>
                      <span className={styles.labelMobile}>{t('common.timeStart')}</span>
                      <ReactDatePicker
                        ref={startRef}
                        selected={data.start}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={10}
                        timeCaption={t('common.start')}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        onChange={handleStartTimeChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            startRef.current.setOpen(false);
                            endRef.current.setFocus();
                          }
                        }}
                        onCalendarClose={handleOnCalendarClose}
                      />
                    </Form.Field>
                    <Form.Field name="endTime" width={4} className={styles.marginBottom}>
                      <span className={styles.labelMobile}>{t('common.timeEnd')}</span>
                      <ReactDatePicker
                        ref={endRef}
                        selected={data.end || data.start}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={10}
                        timeCaption={t('common.end')}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        onChange={handleEndTimeChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && data.end > data.start) {
                            endRef.current.setOpen(false);
                          }
                        }}
                        onCalendarOpen={() => setOpen(false)}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form.Group>
                <Form.Group widths="equal" className={styles.field}>
                  <Form.Field width={1}>
                    <Icon name="retweet" />
                  </Form.Field>
                  <Form.Field width={15}>
                    <Select
                      name="repeatRule"
                      options={repeatRuleOptions}
                      // label={{ children: t('common.noRepeat'), htmlFor: 'form-select-control-status' }}
                      value={data.repeatRule}
                      placeholder={t('common.noRepeat')}
                      style={{ minWidth: '60px' }}
                      onChange={handleRepeatRule}
                    />
                  </Form.Field>
                </Form.Group>
                {attachment && (
                  <Form.Group widths="equal" className={styles.field}>
                    <Form.Field width={1}>
                      <Icon name="attach" />
                    </Form.Field>
                    <Form.Field width={15}>
                      <Popup
                        content={attachment.name}
                        size="mini"
                        trigger={
                          <Label
                            as="a"
                            content={truncate(attachment.name, { length: 20 })}
                            onClick={() => handlePlay(attachment.url)}
                            icon={currentAudioUrl === attachment.url ? 'pause' : 'play'}
                          />
                        }
                        wide="very"
                      />
                    </Form.Field>
                  </Form.Group>
                )}
                <audio
                  src={currentAudioUrl}
                  autoPlay={!!currentAudioUrl}
                  onPause={() => handlePlay('')}
                >
                  <track kind="captions" />
                </audio>
                <div className={styles.actionWrapper}>
                  <Select
                    name="status"
                    options={ScheduleStatusOptions.map((it) => ({
                      ...it,
                      text: t(`common.${it.key}`),
                    }))}
                    label={{ children: t('common.status'), htmlFor: 'form-select-control-status' }}
                    value={data.status}
                    placeholder={t('common.status')}
                    style={{ minWidth: '60px' }}
                    onChange={handleFieldChange}
                  />
                  <Button
                    color="green"
                    icon="checkmark"
                    type="submit"
                    content={t(isEditting ? 'action.editSchedule' : 'action.createSchedule')}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </div>
              </Form>
            </Modal.Content>
            {isShowCustomStep && (
              <CustomStep
                title="common.repeatRuleCustom"
                repeatRule={data.repeatRule}
                startDate={data.startDate}
                days={data.daysOfWeek}
                onConfirm={handleRepeatRuleConfirm}
                onClose={handleCloseCustomStep}
              />
            )}
          </>
        )}
      </div>
    );
  },
);

ScheduleModal.propTypes = {
  // name: PropTypes.string.isRequired,
  // color: PropTypes.string,
  // type: PropTypes.string.isRequired,
  // file: PropTypes.string,
  // fileDirname: PropTypes.string,
  // playCount: PropTypes.number.isRequired,
  // status: PropTypes.string.isRequired,
  // description: PropTypes.string,
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  contents: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  // provinceId: PropTypes.string.isRequired,
  isEditting: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  slots: PropTypes.objectOf(PropTypes.any),
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onErrorClear: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSetStyleModal: PropTypes.func.isRequired,
};

ScheduleModal.defaultProps = {
  // color: undefined,
  // file: undefined,
  // fileDirname: undefined,
  slots: null,
};

export default ScheduleModal;
