import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { truncate } from 'lodash';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Button, Form, Icon, Input, Modal, Select, Image, TextArea } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';

import { useForm } from '../../hooks';
import { useToggle } from '../../lib/hooks';
import { StatusOptions } from '../../constants/Enums';
// import { FilePicker, Markdown } from '../../lib/custom-ui';

import ScheduleColors from '../../constants/ScheduleColors';
import DropdownCategory from '../ContentModal/DropdownCategory';
import AttachmentAddZone from '../ContentModal/AttachmentAddZone';

import styles from './ElectronicContentModal.module.scss';
import SelectAddress from './SelectAddress';

const CREATOR = [
  { key: 'admin', value: 'admin', text: 'admin' },
  { key: 'viewer', value: 'viewer', text: 'viewer' },
];

const ContentPublicTypesOptions = [
  { key: 'text', value: 'text' },
  { key: 'video', value: 'video' },
  { key: 'image', value: 'image' },
];

const ElectronicContentModal = React.memo(
  ({
    // name,
    // color,
    // type,
    // file,
    // fileDirname,
    // playCount,
    // status,
    // description,
    defaultData,
    isDarkMode,
    // districtId,
    // provinceId,
    districts,
    wards,
    attachments,
    allCategories,
    canEdit,
    isViewing,
    isEditting,
    isSubmitting,
    contentId,
    onCreate,
    onUpdate,
    onDelete,
    onErrorClear,
    onAttachmentCreate,
    onAttachmentUpdate,
    onAttachmentDelete,
    onClose,
  }) => {
    const [t] = useTranslation();

    const nameField = useRef(null);
    const addressField = useRef(null);

    // const isGalleryOpened = useRef(false);
    const [myFile, setMyFile] = useState();

    const [data, handleFieldChange, setData] = useForm(() => ({
      color: ScheduleColors[0],
      status: 'draft',
      startDate: new Date(),
      ...defaultData,
      type: 'text',
      creator: 'admin',
      address: '',
      content: '',
      location: {},
    }));

    const [isOpenSelectAddress, handleToggleSelectAddress] = useToggle();

    const [selectTimeFieldState, selectTimeField] = useToggle();

    const handleOpenModalSelectAddress = useCallback(() => {
      if (addressField.current) {
        addressField.current.inputRef.current.blur();
      }

      handleToggleSelectAddress();
    }, [handleToggleSelectAddress]);

    // const handleGalleryOpen = useCallback(() => {
    //   isGalleryOpened.current = true;
    // }, []);

    // const handleGalleryClose = useCallback(() => {
    //   isGalleryOpened.current = false;
    // }, []);

    // const handleClose = useCallback(() => {
    //   if (isGalleryOpened.current) {
    //     return;
    //   }

    //   onClose();
    // }, [onClose]);

    const handleSelectedAddressLocation = useCallback(
      (location) => {
        const [ids] = Object.values(location);

        const addressToString = ids
          ? ids
              .map((id) => {
                const find = wards.find((w) => w.id === id);
                return find ? find.name : null;
              })
              .join(', ')
          : '';

        setData((prev) => ({ ...prev, location, address: addressToString }));
      },
      [wards, setData],
    );

    const handleSubmit = useCallback(() => {
      const cleanData = {
        ...data,
        name: data.name.trim(),
        color: data.color,
        creator: data.creator.trim() || undefined,
        description: data.description?.trim() || undefined,
        type: data.type?.trim() || undefined,
        file: data.file?.trim() || undefined,
        fileDirname: data.fileDirname?.trim() || undefined,
        duration: data.duration?.trim() || undefined,
        categories: data.categories || undefined,
      };

      if (!cleanData.name) {
        nameField.current.select();
        return;
      }
      if (isEditting) {
        onUpdate(cleanData.id, cleanData);
        onClose();
      } else {
        onCreate(cleanData);
      }
      // onClose();
    }, [data, isEditting, onClose, onCreate, onUpdate]);

    const handleAttachmentCreate = useCallback(
      ({ file }) => {
        if (defaultData.id) {
          onAttachmentCreate({
            file,
            contentId: defaultData.id,
          });
          // onClose();
        } else {
          setMyFile(file);
        }
      },
      [defaultData.id, onAttachmentCreate],
    );

    // const handleFileSelect = useCallback(
    //   (file) => {
    //     if (defaultData.id) {
    //       onAttachmentCreate({
    //         file,
    //         contentId: defaultData.id,
    //       });
    //       onClose();
    //     } else {
    //       setMyFile(file);
    //     }
    //   },
    //   [defaultData.id, onAttachmentCreate, onClose],
    // );

    // const handleAttachmentDelete = useCallback(
    //   (id) => {
    //     onAttachmentDelete(id);
    //   },
    //   [onAttachmentDelete],
    // );

    const handleCloseIcon = useCallback(
      (e) => {
        if (e.target.className.includes('close') || isEditting || isViewing) {
          onClose();
        }
      },
      [isEditting, isViewing, onClose],
    );

    const handleSelectCategories = useCallback(
      (categories) => {
        setData((prev) => ({ ...prev, categories }));
      },
      [setData],
    );

    const handleSelectStartDate = useCallback(
      (date) => {
        setData((prev) => ({ ...prev, startDate: date }));
      },
      [setData],
    );

    const mdEditorOptions = useMemo(
      () => ({
        autofocus: true,
        spellChecker: false,
        status: false,
        toolbar: [
          'bold',
          'italic',
          'heading',
          'strikethrough',
          '|',
          'quote',
          'unordered-list',
          'ordered-list',
          'table',
          '|',
          'link',
          'image',
          'preview',
          'fullscreen',
          '|',
          'undo',
          'redo',
        ],
      }),
      [],
    );

    useEffect(() => {
      if (contentId) {
        if (myFile) {
          onAttachmentCreate({
            file: myFile,
            contentId,
          });
        }
        onErrorClear();
        onClose();
      }
    }, [contentId, myFile, onAttachmentCreate, onClose, onErrorClear]);

    return (
      <>
        {isOpenSelectAddress && (
          <SelectAddress
            districts={districts}
            wards={wards}
            locationSelected={data.location}
            onSelectLocation={handleSelectedAddressLocation}
            onClose={handleToggleSelectAddress}
          />
        )}
        <AttachmentAddZone isDarkMode={isDarkMode} onCreate={handleAttachmentCreate}>
          <Modal
            open
            closeIcon
            centered={false}
            onClose={handleCloseIcon}
            className={styles.wrapper}
          >
            <Modal.Header size="huge">
              {t(
                // eslint-disable-next-line no-nested-ternary
                isEditting
                  ? 'common.editContent'
                  : isViewing
                  ? 'common.newsletterContent'
                  : 'common.createContent',
                {
                  context: 'title',
                },
              )}
            </Modal.Header>
            <Modal.Content className={styles.content}>
              <Form onSubmit={handleSubmit}>
                <Form.Field>
                  <span>
                    {t('common.title')}
                    <sup style={{ color: 'red' }}>*</sup>
                  </span>
                  <Input
                    id="form-input-control-name"
                    ref={nameField}
                    name="name"
                    placeholder={t('common.name')}
                    value={data.name}
                    onChange={handleFieldChange}
                  />
                </Form.Field>
                <Form.Field
                  id="form-input-control-type"
                  name="type"
                  width={3}
                  control={Select}
                  label={
                    <span>
                      {t('common.type')}
                      <sup style={{ color: 'red' }}>*</sup>
                    </span>
                  }
                  placeholder="Loại"
                  value={data.type}
                  options={ContentPublicTypesOptions.map((it) => ({
                    ...it,
                    text: t(`common.${it.value}`),
                  }))}
                  onChange={handleFieldChange}
                />
                <Form.Field
                  id="form-input-control-description"
                  name="description"
                  label={t('common.description')}
                  control={TextArea}
                  placeholder={t('common.description')}
                  value={data.description}
                  onChange={handleFieldChange}
                />
                {/* {attachments.length >= 0 && (
                <div className={styles.contentModule}>
                  <div style={{ fontWeight: 'bold', fontSize: 13 }}>
                    Ảnh bìa tin tức <sup style={{ color: 'red' }}>*</sup>
                  </div>
                  <div className={styles.moduleWrapper}>
                    <FilePicker onSelect={handleFileSelect}>
                      <Button positive type="button" icon className={styles.buttonUpload}>
                        <Icon name="upload" />
                        Chọn tệp
                      </Button>
                    </FilePicker>

                    {myFile && (
                      <div className={styles.wrapperImage}>
                        <Button
                          circular
                          className={styles.closeImage}
                          color="red"
                          icon="close"
                          onClick={() => setMyFile()}
                        />
                        <Image src={URL.createObjectURL(myFile)} alt="preview" />
                      </div>
                    )}
                  </div>
                </div>
              )} */}
                <Form.Group widths="equal">
                  <Form.Field>
                    <DropdownCategory
                      cateIds={data.categories}
                      allCategories={allCategories}
                      onSelected={handleSelectCategories}
                    />
                  </Form.Field>
                  <Form.Field>
                    <span>
                      Địa bàn
                      <sup style={{ color: 'red' }}>*</sup>
                    </span>
                    <Input
                      ref={addressField}
                      id="form-input-control-address"
                      name="address"
                      placeholder={t('common.address')}
                      value={data.address}
                      onClick={handleOpenModalSelectAddress}
                      className={styles.inputMarginTop}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <span>
                      Ngày bắt đầu
                      <sup style={{ color: 'red' }}>*</sup>
                    </span>
                    <ReactDatePicker
                      open={selectTimeFieldState}
                      name="date"
                      dateFormat="d/M/yyyy h:mm a"
                      minDate={new Date()}
                      selected={data.startDate}
                      onChange={(date) => handleSelectStartDate(date)}
                      calendarClassName={styles.wrapperCalendar}
                      wrapperClassName={styles.datePicker}
                      showTimeInput
                      timeInputLabel="Thời gian: "
                      onInputClick={selectTimeField}
                      onClickOutside={selectTimeField}
                    >
                      <div className={styles.actionCalendar}>
                        <Button
                          size="mini"
                          content={t('action.cancel')}
                          onClick={() => {
                            setData((prev) => ({ ...prev, startDate: new Date() }));
                            selectTimeField();
                          }}
                        />
                        <Button
                          positive
                          size="mini"
                          content={t('action.save')}
                          onClick={selectTimeField}
                        />
                      </div>
                    </ReactDatePicker>
                  </Form.Field>
                  <Form.Field
                    id="form-input-control-creator"
                    name="creator"
                    control={Select}
                    label={<span>Người tạo</span>}
                    value={data.creator}
                    options={CREATOR.map((it) => ({ ...it, text: t(`common.${it.text}`) }))}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
                <div style={{ fontWeight: 'bold', fontSize: 13, marginBottom: 10 }}>Nội dung:</div>
                <SimpleMDE
                  name="content"
                  value={data.content}
                  options={mdEditorOptions}
                  placeholder={t('common.enterDescription')}
                  className={styles.simplemde}
                  onChange={(value) => handleFieldChange(_, { name: 'content', value })}
                />
                <div className={styles.actionWrapper}>
                  <Select
                    name="status"
                    options={StatusOptions.map((it) => ({ ...it, text: t(`common.${it.key}`) }))}
                    label={{ children: t('common.status'), htmlFor: 'form-select-control-status' }}
                    value={data.status}
                    placeholder={t('common.status')}
                    onChange={handleFieldChange}
                  />
                  {!isViewing && (
                    <Button
                      color="green"
                      icon="checkmark"
                      type="submit"
                      content={t(isEditting ? 'action.editContent' : 'action.save')}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    />
                  )}
                </div>
              </Form>
            </Modal.Content>
          </Modal>
        </AttachmentAddZone>
      </>
    );
  },
);

ElectronicContentModal.propTypes = {
  // name: PropTypes.string.isRequired,
  // color: PropTypes.string,
  // type: PropTypes.string.isRequired,
  // file: PropTypes.string,
  // fileDirname: PropTypes.string,
  // playCount: PropTypes.number.isRequired,
  // status: PropTypes.string.isRequired,
  // description: PropTypes.string,
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isDarkMode: PropTypes.bool.isRequired,
  // districtId: PropTypes.string.isRequired,
  // provinceId: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  districts: PropTypes.array.isRequired,
  wards: PropTypes.array.isRequired,
  attachments: PropTypes.array.isRequired,
  allCategories: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  isViewing: PropTypes.bool.isRequired,
  isEditting: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  contentId: PropTypes.string,
  canEdit: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onErrorClear: PropTypes.func.isRequired,
  onAttachmentCreate: PropTypes.func.isRequired,
  onAttachmentUpdate: PropTypes.func.isRequired,
  onAttachmentDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ElectronicContentModal.defaultProps = {
  // color: undefined,
  // file: undefined,
  // fileDirname: undefined,
  contentId: null,
};

export default ElectronicContentModal;
