import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message, Popup } from 'semantic-ui-react';
import { add } from 'date-fns';

import { conflictTime } from '../../../../utils/modify-time';

import styles from './AddTime.module.scss';
import durationToTime from '../../../../utils/duration-to-time';

const AddTime = React.memo(({ item, position, duration, onAdd }) => {
  const [t] = useTranslation();

  const startRef = useRef(null);
  const endRef = useRef(null);

  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);

  const [hours, minutes, seconds] = durationToTime(duration, true).split(':').map(Number);

  const initialDate = useMemo(() => {
    return {
      start: new Date(item.NgayPhat),
      end: new Date(item.NgayPhat),
    };
  }, [item.NgayPhat]);

  const [date, setDate] = useState(initialDate);

  const handleClick = useCallback(() => {
    if (document.activeElement) {
      document.activeElement.blur();
    }
  }, []);

  const handleStartTimeChange = useCallback(
    (time) => {
      let end = time;

      end = add(end, { hours, minutes, seconds });

      setDate((prev) => ({
        ...prev,
        start: time,
        end,
      }));
      setError(false);
    },
    [hours, minutes, seconds],
  );

  const handleEndTimeChange = useCallback((time, event) => {
    setDate((prev) => ({
      ...prev,
      end: time,
      ...(time < prev.start && !event && { start: time }),
    }));
    setError(false);
  }, []);

  const handleAddTime = useCallback(() => {
    if (!endRef.current) return;

    if (date.start >= date.end) {
      endRef.current.setFocus();
      return;
    }

    const time = {
      ThoiGianBatDau: new Date(date.start).getTime(),
      ThoiGianKetThuc: new Date(date.end).getTime(),
    };

    let schedulesToFormatTime = [];

    if (item.schedules) {
      schedulesToFormatTime = item.schedules.map((it) => ({
        ThoiGianBatDau: it.start.getTime(),
        ThoiGianKetThuc: it.end.getTime(),
      }));
    }

    const sources = [...item.ThoiDiemPhat, ...schedulesToFormatTime];

    const exist = conflictTime(time, sources);

    if (exist) {
      setError(true);
      return;
    }

    onAdd(time);
    setOpen(false);
    setDate(initialDate);
    setError(false);
  }, [initialDate, item.ThoiDiemPhat, item.schedules, date.start, date.end, onAdd]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        handleAddTime();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleAddTime]);

  const contentNode = (
    <Form>
      <Form.Field>
        <span>{t('common.timeStart')}</span>
        <ReactDatePicker
          ref={startRef}
          selected={date.start}
          calendarClassName={styles.calendar}
          wrapperClassName={styles.calendarWrapper}
          showTimeSelect
          showTimeSelectOnly
          timeCaption={t('common.start')}
          timeIntervals={10}
          dateFormat="HH:mm:ss"
          onChange={handleStartTimeChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              startRef.current.setOpen(false);
              endRef.current.setFocus();
            }
          }}
        />
      </Form.Field>
      <Form.Field name="endTime">
        <span>{t('common.timeEnd')}</span>
        <ReactDatePicker
          ref={endRef}
          selected={date.end || date.start}
          calendarClassName={styles.calendar}
          wrapperClassName={styles.calendarWrapper}
          showTimeSelect
          showTimeSelectOnly
          timeCaption={t('common.end')}
          timeIntervals={10}
          dateFormat="HH:mm:ss"
          onChange={handleEndTimeChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && date.end > date.start) {
              endRef.current.setOpen(false);
            }
          }}
        />
      </Form.Field>
      {error && <Message negative content={t('common.selectedTimeIsInConflict')} />}
      <Button primary content={t('action.save')} type="button" onClick={handleAddTime} />
    </Form>
  );

  return (
    <Popup
      content={contentNode}
      on="click"
      position={position}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button className={styles.addTime} onClick={handleClick} icon="plus" type="button" />
      }
    />
  );
});

export default AddTime;

AddTime.propTypes = {
  item: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  position: PropTypes.string,
  duration: PropTypes.number,
  onAdd: PropTypes.func.isRequired,
};

AddTime.defaultProps = {
  position: undefined,
  duration: 0,
};
