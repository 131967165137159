import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message, Popup } from 'semantic-ui-react';
import { add } from 'date-fns';

import { conflictTime } from '../../../../utils/modify-time';
import durationToTime from '../../../../utils/duration-to-time';

import styles from './EditTime.module.scss';

const EditTime = React.memo(({ children, item, dateTime, duration, position, canEdit, onEdit }) => {
  const [t] = useTranslation();

  const startRef = useRef(null);
  const endRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  const [date, setDate] = useState({
    start: new Date(item.ThoiGianBatDau),
    end: new Date(item.ThoiGianKetThuc),
  });

  const [hours, minutes, seconds] = durationToTime(duration, true).split(':').map(Number);

  const handleStartTimeChange = useCallback(
    (time) => {
      let end = time;

      end = add(end, { hours, minutes, seconds });

      setDate((prev) => ({
        ...prev,
        start: time,
        end,
      }));
    },
    [hours, minutes, seconds],
  );

  const handleEndTimeChange = useCallback((time) => {
    setDate((prev) => ({ ...prev, end: time }));
  }, []);

  const handleUnmount = useCallback(() => {
    setError(false);
    setDate({
      start: new Date(item.ThoiGianBatDau),
      end: new Date(item.ThoiGianKetThuc),
    });
  }, [item.ThoiGianBatDau, item.ThoiGianKetThuc]);

  const handleUpdateTime = useCallback(() => {
    if (date.start >= date.end) {
      endRef.current.setFocus();
      return;
    }

    const { id, wardId, ThoiGianBatDau, ThoiGianKetThuc } = item;

    const time = {
      id,
      ThoiGianBatDau: new Date(date.start).getTime(),
      ThoiGianKetThuc: new Date(date.end).getTime(),
      wardId,
    };

    const schedulesToFormatTime = dateTime.schedules.map((it) => ({
      ThoiGianBatDau: it.start.getTime(),
      ThoiGianKetThuc: it.end.getTime(),
    }));

    const multiTimesInOnceRange = [...dateTime.ThoiDiemPhat].filter(
      (it) => it.ThoiGianBatDau === ThoiGianBatDau && it.ThoiGianKetThuc === ThoiGianKetThuc,
    );

    const itemIds = multiTimesInOnceRange.map((it) => it.id || it.ThoiGianBatDau);
    const filterTimes = [...dateTime.ThoiDiemPhat].filter(
      (it) => !itemIds.includes(it.id || it.ThoiGianBatDau),
    );

    const sources = [...filterTimes, ...schedulesToFormatTime];

    const exist = conflictTime(time, sources);

    if (exist) {
      setError(true);
      return;
    }

    const updateTime = [...dateTime.ThoiDiemPhat].map((it, i) =>
      itemIds.includes(it.id || it.ThoiGianBatDau)
        ? {
            ...it,
            ThoiGianBatDau: time.ThoiGianBatDau,
            ThoiGianKetThuc: time.ThoiGianKetThuc,
          }
        : it,
    );

    onEdit(updateTime);
    setOpen(false);
    setError(false);
  }, [
    dateTime.ThoiDiemPhat,
    dateTime.schedules,
    // position,
    date.start,
    date.end,
    item,
    onEdit,
  ]);

  const contentNode = (
    <Form>
      <Form.Field>
        <span>{t('common.timeStart')}</span>
        <ReactDatePicker
          ref={startRef}
          selected={date.start}
          calendarClassName={styles.calendar}
          wrapperClassName={styles.calendarWrapper}
          showTimeSelect
          showTimeSelectOnly
          timeCaption={t('common.start')}
          timeIntervals={10}
          dateFormat="HH:mm:ss"
          timeFormat="HH:mm:ss"
          onChange={handleStartTimeChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              startRef.current.setOpen(false);
              endRef.current.setFocus();
            }
          }}
        />
      </Form.Field>
      <Form.Field name="endTime">
        <span>{t('common.timeEnd')}</span>
        <ReactDatePicker
          ref={endRef}
          selected={date.end || date.start}
          calendarClassName={styles.calendar}
          wrapperClassName={styles.calendarWrapper}
          showTimeSelect
          showTimeSelectOnly
          timeCaption={t('common.end')}
          timeIntervals={10}
          dateFormat="HH:mm:ss"
          timeFormat="HH:mm:ss"
          onChange={handleEndTimeChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && date.end > date.start) {
              endRef.current.setOpen(false);
            }
          }}
        />
      </Form.Field>
      {error && <Message negative content={t('common.selectedTimeIsInConflict')} />}
      <Button primary content={t('action.save')} onClick={handleUpdateTime} />
    </Form>
  );

  return (
    <Popup
      disabled={!canEdit}
      content={contentNode}
      on="click"
      // position="right center"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      onUnmount={handleUnmount}
      open={open}
      trigger={children}
    />
  );
});

export default EditTime;

EditTime.propTypes = {
  children: PropTypes.element.isRequired,
  duration: PropTypes.number,
  item: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  dateTime: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  position: PropTypes.number.isRequired,
  canEdit: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
};

EditTime.defaultProps = {
  duration: 0,
  canEdit: true,
};
