import ActionTypes from '../constants/ActionTypes';

const createUser = (data) => ({
  type: ActionTypes.USER_CREATE,
  payload: {
    data,
  },
});

createUser.success = (
  user,
  permissions,
  provinceManager,
  districtMemberships,
  wardMemberships,
) => ({
  type: ActionTypes.USER_CREATE__SUCCESS,
  payload: {
    user,
    permissions,
    provinceManager,
    districtMemberships,
    wardMemberships,
  },
});

createUser.failure = (error) => ({
  type: ActionTypes.USER_CREATE__FAILURE,
  payload: {
    error,
  },
});

const handleUserCreate = (user) => ({
  type: ActionTypes.USER_CREATE_HANDLE,
  payload: {
    user,
  },
});

const clearUserCreateError = () => ({
  type: ActionTypes.USER_CREATE_ERROR_CLEAR,
  payload: {},
});

const updateUser = (id, data) => ({
  type: ActionTypes.USER_UPDATE,
  payload: {
    id,
    data,
  },
});

updateUser.success = (user) => ({
  type: ActionTypes.USER_UPDATE__SUCCESS,
  payload: {
    user,
  },
});

updateUser.failure = (id, error) => ({
  type: ActionTypes.USER_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleUserUpdate = (user, users, isCurrent) => ({
  type: ActionTypes.USER_UPDATE_HANDLE,
  payload: {
    user,
    users,
    isCurrent,
  },
});

const updateUserEmail = (id, data) => ({
  type: ActionTypes.USER_EMAIL_UPDATE,
  payload: {
    id,
    data,
  },
});

updateUserEmail.success = (user) => ({
  type: ActionTypes.USER_EMAIL_UPDATE__SUCCESS,
  payload: {
    user,
  },
});

updateUserEmail.failure = (id, error) => ({
  type: ActionTypes.USER_EMAIL_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const clearUserEmailUpdateError = (id) => ({
  type: ActionTypes.USER_EMAIL_UPDATE_ERROR_CLEAR,
  payload: {
    id,
  },
});

const updateUserPassword = (id, data) => ({
  type: ActionTypes.USER_PASSWORD_UPDATE,
  payload: {
    id,
    data,
  },
});

updateUserPassword.success = (user, accessToken) => ({
  type: ActionTypes.USER_PASSWORD_UPDATE__SUCCESS,
  payload: {
    user,
    accessToken,
  },
});

updateUserPassword.failure = (id, error) => ({
  type: ActionTypes.USER_PASSWORD_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const clearUserPasswordUpdateError = (id) => ({
  type: ActionTypes.USER_PASSWORD_UPDATE_ERROR_CLEAR,
  payload: {
    id,
  },
});

const updateUserUsername = (id, data) => ({
  type: ActionTypes.USER_USERNAME_UPDATE,
  payload: {
    id,
    data,
  },
});

updateUserUsername.success = (user) => ({
  type: ActionTypes.USER_USERNAME_UPDATE__SUCCESS,
  payload: {
    user,
  },
});

updateUserUsername.failure = (id, error) => ({
  type: ActionTypes.USER_USERNAME_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const updateUserPermission = (id, data) => ({
  type: ActionTypes.USER_PERMISSION_UPDATE,
  payload: {
    id,
    data,
  },
});

updateUserPermission.success = (user, districtMemberships, wardMemberships, permissions) => ({
  type: ActionTypes.USER_PERMISSION_UPDATE__SUCCESS,
  payload: {
    user,
    districtMemberships,
    wardMemberships,
    permissions,
  },
});

updateUserPermission.failure = (id, error) => ({
  type: ActionTypes.USER_PERMISSION_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const clearUserPermissionUpdateError = (id) => ({
  type: ActionTypes.USER_PERMISSION_UPDATE_ERROR_CLEAR,
  payload: {
    id,
  },
});

const clearUserUsernameUpdateError = (id) => ({
  type: ActionTypes.USER_USERNAME_UPDATE_ERROR_CLEAR,
  payload: {
    id,
  },
});

const updateUserAvatar = (id) => ({
  type: ActionTypes.USER_AVATAR_UPDATE,
  payload: {
    id,
  },
});

updateUserAvatar.success = (user) => ({
  type: ActionTypes.USER_AVATAR_UPDATE__SUCCESS,
  payload: {
    user,
  },
});

updateUserAvatar.failure = (id, error) => ({
  type: ActionTypes.USER_AVATAR_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const deleteUser = (id) => ({
  type: ActionTypes.USER_DELETE,
  payload: {
    id,
  },
});

deleteUser.success = (user) => ({
  type: ActionTypes.USER_DELETE__SUCCESS,
  payload: {
    user,
  },
});

deleteUser.failure = (id, error) => ({
  type: ActionTypes.USER_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleUserDelete = (user) => ({
  type: ActionTypes.USER_DELETE_HANDLE,
  payload: {
    user,
  },
});

const addUserToDevice = (id, deviceId, isCurrent) => ({
  type: ActionTypes.USER_TO_DEVICE_ADD,
  payload: {
    id,
    deviceId,
    isCurrent,
  },
});

addUserToDevice.success = (deviceMembership) => ({
  type: ActionTypes.USER_TO_DEVICE_ADD__SUCCESS,
  payload: {
    deviceMembership,
  },
});

addUserToDevice.failure = (id, deviceId, error) => ({
  type: ActionTypes.USER_TO_DEVICE_ADD__FAILURE,
  payload: {
    id,
    deviceId,
    error,
  },
});

const handleUserToDeviceAdd = (deviceMembership) => ({
  type: ActionTypes.USER_TO_DEVICE_ADD_HANDLE,
  payload: {
    deviceMembership,
  },
});

const removeUserFromDevice = (id, deviceId) => ({
  type: ActionTypes.USER_FROM_DEVICE_REMOVE,
  payload: {
    id,
    deviceId,
  },
});

removeUserFromDevice.success = (deviceMembership) => ({
  type: ActionTypes.USER_FROM_DEVICE_REMOVE__SUCCESS,
  payload: {
    deviceMembership,
  },
});

removeUserFromDevice.failure = (id, deviceId, error) => ({
  type: ActionTypes.USER_FROM_DEVICE_REMOVE__FAILURE,
  payload: {
    id,
    deviceId,
    error,
  },
});

const handleUserFromDeviceRemove = (deviceMembership) => ({
  type: ActionTypes.USER_FROM_DEVICE_REMOVE_HANDLE,
  payload: {
    deviceMembership,
  },
});

const addUserToDistrictFilter = (id, districtId) => ({
  type: ActionTypes.USER_TO_DISTRICT_FILTER_ADD,
  payload: {
    id,
    districtId,
  },
});

const removeUserFromDistrictFilter = (id, districtId) => ({
  type: ActionTypes.USER_FROM_DISTRICT_FILTER_REMOVE,
  payload: {
    id,
    districtId,
  },
});

const handleUserPermissionCreate = (permission) => ({
  type: ActionTypes.USER_PERMISSION_CREATE_HANDLE,
  payload: {
    permission,
  },
});

const handleUserPermissionUpdate = (permission) => ({
  type: ActionTypes.USER_PERMISSION_UPDATE_HANDLE,
  payload: {
    permission,
  },
});

export default {
  createUser,
  handleUserCreate,
  clearUserCreateError,
  updateUser,
  handleUserUpdate,
  updateUserEmail,
  clearUserEmailUpdateError,
  updateUserPassword,
  clearUserPasswordUpdateError,
  updateUserUsername,
  clearUserUsernameUpdateError,
  updateUserPermission,
  clearUserPermissionUpdateError,
  updateUserAvatar,
  deleteUser,
  handleUserDelete,
  addUserToDevice,
  handleUserToDeviceAdd,
  removeUserFromDevice,
  handleUserFromDeviceRemove,
  addUserToDistrictFilter,
  removeUserFromDistrictFilter,
  handleUserPermissionCreate,
  handleUserPermissionUpdate,
};
