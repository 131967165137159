const DistrictVisualInformation = (data, districts) => {
  const dataReport = districts.map((district) => {
    return {
      code: district.code,
      name: district.name,
      website: district.TrangThongTinDienTu,
    };
  });

  const accomulator = dataReport.reduce(
    (acc, item) => {
      Object.keys(item).forEach((key) => {
        if (acc[key] !== undefined) {
          acc[key] += item[key];
        }
      });
      return acc;
    },
    {
      website: 0,
    },
  );

  let html = `<div class="header">
    <h3>Bảng 4</h3>
    <p>SỐ LIỆU VỀ THÔNG TIN TRỰC QUAN CẤP HUYỆN</p>
    <p>Kỳ báo cáo: Quý ${data.quater} năm ${data.year.getFullYear()}.</p>
  </div>
  <table>
    <thead>
      <tr>
        <th rowspan="3"><strong>STT</strong></th>
        <th rowspan="3" style="width: 5%">
          <strong>Mã định danh</strong><br />
          (Quyết định số 20/2020/QĐ-TTg ngày 22/7/2020 của Thủ tướng Chính phủ)
        </th>
        <th rowspan="3"><strong>Tên đơn vị hành chính</strong></th>
        <th rowspan="3"><strong>Số lượng cổng thông tin điện tử cấp huyện</strong></th>
        <th colspan="6"><strong>Bảng tin điện tử công cộng cấp huyện</strong></th>
        <th rowspan="3" style="width: 15%"><strong>Ghi chú</strong></th>
      </tr>
      <tr>
        <th colspan="3">Bảng tin kết nối với Hệ thống thông tin nguồn cấp tỉnh</th>
        <th colspan="3">Bảng tin chưa kết nối với Hệ thống thông tin nguồn cấp tỉnh</th>
      </tr>
      <tr>
        <th>Số lượng bảng tin</th>
        <th>Tổng số chương trình đã phát</th>
        <th>Thời lượng chương trình đã phát</th>
        <th>Số lượng bảng tin</th>
        <th>Tổng số chương trình đã phát</th>
        <th>Tổng thời lượng chương trình đã phát</th>
      </tr>
    </thead>
    <tbody>`;

  dataReport.forEach((it, index) => {
    html += `<tr style="text-align: center">
        <td>${index + 1}</td>
        <td>${it.code}</td>
        <td>${it.name}</td>
        <td>${it.website}</td>
        <td>${it.website}</td>
        <td>${it.website}</td>
        <td>${it.website}</td>
        <td>${it.website}</td>
        <td>${it.website}</td>
        <td>${it.website}</td>
        <td>${it.website}</td>
      </tr>`;
  });

  html += `<tr>
        <td colspan="3"><strong>Tổng cộng:</strong></td>
        <td style="text-align: center">${accomulator.website}</td>
        <td style="text-align: center"></td>
        <td style="text-align: center"></td>
        <td style="text-align: center"></td>
        <td style="text-align: center"></td>
        <td style="text-align: center"></td>
        <td style="text-align: center"></td>
        <td style="text-align: center"></td>
      </tr>
    </tbody>
  </table>
  <div class="footer">
    <p>
      <strong>1. Đơn vị cập nhật số liệu: </strong>Phòng Văn hóa và Thông tin cấp huyện: <br />
      - Số lượng cổng thông tin điện tử cấp huyện (có ghi “1”; không có ghi “0”); <br />
      - Số lượng bảng tin điện tử công cộng cấp huyện (có ghi “1” trở lên; không có ghi “0”).
    </p>

    <p>
      <strong>2. Yêu cầu trích xuất số liệu báo cáo theo các biểu: </strong><br />
      - Số huyện của tỉnh (thành phố trực thuộc TW);<br />
      - Số quận của thành phố trực thuộc TW;<br />
      - Số thị xã của tỉnh (thành phố trực thuộc TW);<br />
      - Số thành phố thuộc tỉnh của tỉnh (thành phố trực thuộc TW).
    </p>
    <p>
      <strong>3. Thời hạn gửi báo cáo: </strong> <br />
      - Chậm nhất ngày 08 của tháng cuối quý đối với báo cáo định kỳ hằng quý; <br />
      - Chậm nhất ngày 27/11 đối với báo cáo định kỳ hằng năm.
    </p>
    <p>
      <strong>4. Lưu ý: </strong> <br />
      - Tổng số chương trình đã phát là số lượng chương trình đã phát của các Bảng tin điện tử kết
      nối hoặc Bảng tin điện tử chưa kết nối trên địa bàn cấp huyện/quý. <br />
      - Tổng thời lượng chương trình đã phát là số giờ đã phát của Bảng tin điện tử kết nối hoặc
      Bảng tin điện tử chưa kết nối trên địa bàn cấp huyện/quý.
    </p>
  </div>`;
  return html;
};

export default DistrictVisualInformation;
