import { attr, many } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

const DEFAULT_EMAIL_UPDATE_FORM = {
  data: {
    email: '',
    currentPassword: '',
  },
  isSubmitting: false,
  error: null,
};

const DEFAULT_PASSWORD_UPDATE_FORM = {
  data: {
    password: '',
    currentPassword: '',
  },
  isSubmitting: false,
  error: null,
};

const DEFAULT_USERNAME_UPDATE_FORM = {
  data: {
    username: '',
    currentPassword: '',
  },
  isSubmitting: false,
  error: null,
};

const DEFAULT_PERMISSION_UPDATE_FORM = {
  data: {
    userGroupId: '',
    locationId: '',
    level: '',
    currentPassword: '',
  },
  isSubmitting: false,
  error: null,
};

export default class extends BaseModel {
  static modelName = 'User';

  static fields = {
    id: attr(),
    email: attr(),
    username: attr(),
    name: attr(),
    avatarUrl: attr(),
    phone: attr(),
    organization: attr(),
    language: attr(),
    subscribeToOwnDevices: attr(),
    deletedAt: attr(),
    isAdmin: attr({
      getDefault: () => false,
    }),
    isAvatarUpdating: attr({
      getDefault: () => false,
    }),
    emailUpdateForm: attr({
      getDefault: () => DEFAULT_EMAIL_UPDATE_FORM,
    }),
    passwordUpdateForm: attr({
      getDefault: () => DEFAULT_PASSWORD_UPDATE_FORM,
    }),
    usernameUpdateForm: attr({
      getDefault: () => DEFAULT_USERNAME_UPDATE_FORM,
    }),
    permissionUpdateForm: attr({
      getDefault: () => DEFAULT_PERMISSION_UPDATE_FORM,
    }),
    groups: many('UserGroup', 'users'),
  };

  static reducer({ type, payload }, User) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
        if (payload.users) {
          payload.users.forEach((user) => {
            User.upsert(user);
          });
        }

        if (payload.permissions) {
          payload.permissions.forEach(({ userId, userGroupId }) => {
            User.withId(userId).groups.add(userGroupId);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        User.all().delete();

        User.upsert(payload.user);

        payload.users.forEach((user) => {
          User.upsert(user);
        });

        break;
      case ActionTypes.CORE_INITIALIZE:
        User.upsert(payload.user);

        payload.users.forEach((user) => {
          User.upsert(user);
        });

        if (payload.permissions) {
          payload.permissions.forEach(({ userId, userGroupId }) => {
            User.withId(userId).groups?.add(userGroupId);
          });
        }

        break;
      case ActionTypes.USER_CREATE__SUCCESS:
      case ActionTypes.USER_CREATE_HANDLE:
      case ActionTypes.USER_UPDATE__SUCCESS:
        User.upsert(payload.user);

        if (payload.permissions) {
          payload.permissions.forEach(({ userId, userGroupId }) => {
            User.withId(userId).groups.add(userGroupId);
          });
        }

        break;
      case ActionTypes.USER_UPDATE:
        User.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.USER_UPDATE_HANDLE:
        User.upsert(payload.user);

        if (payload.users) {
          payload.users.forEach((user) => {
            User.upsert(user);
          });
        }

        break;
      case ActionTypes.USER_EMAIL_UPDATE: {
        const userModel = User.withId(payload.id);

        userModel.update({
          emailUpdateForm: {
            ...userModel.emailUpdateForm,
            data: payload.data,
            isSubmitting: true,
          },
        });

        break;
      }
      case ActionTypes.USER_EMAIL_UPDATE__SUCCESS: {
        User.withId(payload.user.id).update({
          ...payload.user,
          emailUpdateForm: DEFAULT_EMAIL_UPDATE_FORM,
        });

        break;
      }
      case ActionTypes.USER_EMAIL_UPDATE__FAILURE: {
        const userModel = User.withId(payload.id);

        userModel.update({
          emailUpdateForm: {
            ...userModel.emailUpdateForm,
            isSubmitting: false,
            error: payload.error,
          },
        });

        break;
      }
      case ActionTypes.USER_EMAIL_UPDATE_ERROR_CLEAR: {
        const userModel = User.withId(payload.id);

        userModel.update({
          emailUpdateForm: {
            ...userModel.emailUpdateForm,
            error: null,
          },
        });

        break;
      }
      case ActionTypes.USER_PASSWORD_UPDATE: {
        const userModel = User.withId(payload.id);

        userModel.update({
          passwordUpdateForm: {
            ...userModel.passwordUpdateForm,
            data: payload.data,
            isSubmitting: true,
          },
        });

        break;
      }
      case ActionTypes.USER_PASSWORD_UPDATE__SUCCESS: {
        User.withId(payload.user.id).update({
          ...payload.user,
          passwordUpdateForm: DEFAULT_PASSWORD_UPDATE_FORM,
        });

        break;
      }
      case ActionTypes.USER_PASSWORD_UPDATE__FAILURE: {
        const userModel = User.withId(payload.id);

        userModel.update({
          passwordUpdateForm: {
            ...userModel.passwordUpdateForm,
            isSubmitting: false,
            error: payload.error,
          },
        });

        break;
      }
      case ActionTypes.USER_PASSWORD_UPDATE_ERROR_CLEAR: {
        const userModel = User.withId(payload.id);

        userModel.update({
          passwordUpdateForm: {
            ...userModel.passwordUpdateForm,
            error: null,
          },
        });

        break;
      }
      case ActionTypes.USER_USERNAME_UPDATE: {
        const userModel = User.withId(payload.id);

        userModel.update({
          usernameUpdateForm: {
            ...userModel.usernameUpdateForm,
            data: payload.data,
            isSubmitting: true,
          },
        });

        break;
      }
      case ActionTypes.USER_USERNAME_UPDATE__SUCCESS: {
        User.withId(payload.user.id).update({
          ...payload.user,
          usernameUpdateForm: DEFAULT_USERNAME_UPDATE_FORM,
        });

        break;
      }
      case ActionTypes.USER_USERNAME_UPDATE__FAILURE: {
        const userModel = User.withId(payload.id);

        userModel.update({
          usernameUpdateForm: {
            ...userModel.usernameUpdateForm,
            isSubmitting: false,
            error: payload.error,
          },
        });

        break;
      }
      case ActionTypes.USER_USERNAME_UPDATE_ERROR_CLEAR: {
        const userModel = User.withId(payload.id);

        userModel.update({
          usernameUpdateForm: {
            ...userModel.usernameUpdateForm,
            error: null,
          },
        });

        break;
      }
      case ActionTypes.USER_PERMISSION_UPDATE: {
        const userModel = User.withId(payload.id);

        userModel.update({
          permissionUpdateForm: {
            ...userModel.permissionUpdateForm,
            data: payload.data,
            isSubmitting: true,
          },
        });

        break;
      }
      case ActionTypes.USER_PERMISSION_UPDATE__SUCCESS: {
        User.withId(payload.user.id).update({
          ...payload.user,
          permissionUpdateForm: DEFAULT_USERNAME_UPDATE_FORM,
        });

        if (payload.permissions) {
          User.withId(payload.user.id).groups.clear();

          payload.permissions.forEach(({ userId, userGroupId }) => {
            User.withId(userId).groups.add(userGroupId);
          });
        }

        break;
      }
      case ActionTypes.USER_PERMISSION_UPDATE__FAILURE: {
        const userModel = User.withId(payload.id);

        userModel.update({
          permissionUpdateForm: {
            ...userModel.permissionUpdateForm,
            isSubmitting: false,
            error: payload.error,
          },
        });

        break;
      }
      case ActionTypes.USER_PERMISSION_UPDATE_ERROR_CLEAR: {
        const userModel = User.withId(payload.id);

        userModel.update({
          permissionUpdateForm: {
            ...userModel.permissionUpdateForm,
            error: null,
          },
        });

        break;
      }
      case ActionTypes.USER_AVATAR_UPDATE:
        User.withId(payload.id).update({
          isAvatarUpdating: true,
        });

        break;
      case ActionTypes.USER_AVATAR_UPDATE__SUCCESS:
        User.withId(payload.user.id).update({
          ...payload.user,
          isAvatarUpdating: false,
        });

        break;
      case ActionTypes.USER_AVATAR_UPDATE__FAILURE:
        User.withId(payload.id).update({
          isAvatarUpdating: false,
        });

        break;
      case ActionTypes.USER_DELETE:
        User.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.USER_DELETE__SUCCESS:
      case ActionTypes.USER_DELETE_HANDLE:
        User.withId(payload.user.id)?.deleteWithRelated(payload.user);

        break;
      // case ActionTypes.PROVINCE_CREATE_HANDLE:
      // case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE:
      // case ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE:
      case ActionTypes.DISTRICT_FETCH__SUCCESS:
      case ActionTypes.ACTIVITIES_FETCH__SUCCESS:
      case ActionTypes.NOTIFICATION_CREATE_HANDLE:
        payload.users.forEach((user) => {
          User.upsert(user);
        });

        break;
      case ActionTypes.CONTENT_FETCH_STATIONS_BROADCASTED__SUCCESS:
        if (payload.users) {
          payload.users.forEach((user) => {
            User.upsert(user);
          });
        }

        break;

      case ActionTypes.USER_PERMISSION_CREATE_HANDLE:
      case ActionTypes.USER_PERMISSION_UPDATE_HANDLE:
        if (payload.permission) {
          User.withId(payload.permission.userId).groups.clear();
          User.withId(payload.permission.userId).groups.add(payload.permission.userGroupId);
        }

        break;

      default:
    }
  }

  static getOrderedUndeletedQuerySet() {
    return this.filter({
      deletedAt: null,
    }).orderBy('id');
  }

  getOrderedProvinceManagersQuerySet() {
    return this.provinceManagers.orderBy('id');
  }

  getOrderedDistrictMembershipsQuerySet() {
    return this.districtMemberships.orderBy('id');
  }

  getOrderedUserLogsQuerySet() {
    return this.userActions.orderBy('createdAt', false);
  }

  getOrderedUnreadNotificationsQuerySet() {
    return this.notifications
      .filter({
        isRead: false,
      })
      .orderBy('id', false);
  }

  getOrderedNotificationsQuerySet() {
    return this.notifications.orderBy('id', false);
  }

  getOrderedAvailableProvincesModelArray() {
    const provinceIds = [];

    const provinceModels = this.getOrderedProvinceManagersQuerySet()
      .toModelArray()
      .map(({ province: provinceModel }) => {
        provinceIds.push(provinceModel.id);

        return provinceModel;
      });

    this.getOrderedDistrictMembershipsQuerySet()
      .toModelArray()
      .forEach(({ district: { province: provinceModel } }) => {
        if (provinceIds.includes(provinceModel.id)) {
          return;
        }

        provinceIds.push(provinceModel.id);
        provinceModels.push(provinceModel);
      });

    return provinceModels;
  }

  deleteRelated() {
    this.provinceManagers.delete();

    this.districtMemberships.toModelArray().forEach((districtMembershipModel) => {
      districtMembershipModel.deleteWithRelated();
    });

    this.userGroups.delete();
    this.wardMemberships.toModelArray().forEach((wardMembershipModel) => {
      wardMembershipModel.deleteWithRelated();
    });
  }

  deleteWithRelated(user) {
    this.deleteRelated();

    this.update(
      user || {
        deletedAt: new Date(),
      },
    );
  }
}
