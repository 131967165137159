import socket from './socket';

/* Actions */

const getReports = (provinceId, data, headers) =>
  socket.get(`/provinces/${provinceId}/reports`, data, headers);

export default {
  getReports,
};
