import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* userGroupsWatchers() {
  yield all([
    takeEvery(EntryActionTypes.USER_GROUP_CREATE, ({ payload: { data } }) =>
      services.createUserGroup(data),
    ),
    takeEvery(EntryActionTypes.USER_GROUP_CREATE_HANDLE, ({ payload: { userGroup } }) =>
      services.handleUserGroupCreate(userGroup),
    ),
    takeEvery(EntryActionTypes.USER_GROUP_UPDATE, ({ payload: { id, data } }) =>
      services.updateUserGroup(id, data),
    ),
    takeEvery(EntryActionTypes.USER_GROUP_UPDATE_HANDLE, ({ payload: { userGroup } }) =>
      services.handleUserGroupUpdate(userGroup),
    ),
    takeEvery(EntryActionTypes.USER_GROUP_DELETE, ({ payload: { id } }) =>
      services.deleteUserGroup(id),
    ),
    takeEvery(EntryActionTypes.USER_GROUP_DELETE_HANDLE, ({ payload: { userGroup } }) =>
      services.handleUserGroupDelete(userGroup),
    ),
    takeEvery(EntryActionTypes.USER_GROUP_ERROR_CLEAR, () => services.clearUserGroupError()),
    takeEvery(EntryActionTypes.USER_GROUPS_FETCH, ({ payload: { page, limit, districtIds } }) =>
      services.fetchUserGroups({ page, limit, districtIds }),
    ),
  ]);
}
