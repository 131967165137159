import React, { useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Label, TransitionGroup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './Notice.module.scss';

const Notice = React.forwardRef((props, ref) => {
  const [t] = useTranslation();

  const timeout = useRef();

  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState();

  const handleAlertTrigger = useCallback((msg) => {
    clearTimeout(timeout.current);

    setVisible(true);
    setMessage(msg);

    timeout.current = setTimeout(() => {
      setVisible(false);
    }, 3000);
  }, []);

  const handleCloseAlert = useCallback(() => {
    setVisible(false);
    clearTimeout(timeout.current);
  }, []);

  useImperativeHandle(ref, () => ({
    handleAlertTrigger,
  }));

  return (
    <div className={styles.containerNotification}>
      <TransitionGroup animation="fly left" duration={500}>
        {visible && (
          <Label
            as="a"
            onClick={handleCloseAlert}
            size="large"
            color="yellow"
            icon="warning sign"
            content={t(message)}
          />
        )}
      </TransitionGroup>
    </div>
  );
});

export default Notice;
