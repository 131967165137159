import merge from 'lodash/merge';

import login from './login';
import core from './core';

export default {
  language: 'vi',
  country: 'vn',
  name: 'Tiếng Việt',
  embeddedLocale: merge(login, core),
};
