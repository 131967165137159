import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import StationsLogsModal from '../components/StationsLogsModal';

const mapStateToProps = (state, { selectedId }) => {
  const { contentBroadcastedTotal: total } = selectors.selectCurrentProvince(state);
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const content = selectors.selectContentById(state, selectedId);
  const items = selectors.selectStationsBroadcastedByContentId(state, selectedId);

  return {
    districts,
    content,
    items: items || [],
    total,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onDelete: entryActions.deleteStationLog,
      onFetchStationsByContent: entryActions.fetchStationsBroadcastedContent,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(StationsLogsModal);
