import ActionTypes from '../constants/ActionTypes';

const initializeCore = (
  user,
  district,
  users,
  province,
  provinces,
  provinceManagers,
  districts,
  districtMemberships,
  wards,
  wardMemberships,
  hamlets,
  categories,
  contents,
  contentCategories,
  stations,
  devices,
  deviceMemberships,
  deviceContents,
  tasks,
  attachments,
  activities,
  notifications,
  contentLocations,
  userGroups,
  permissions,
) => ({
  type: ActionTypes.CORE_INITIALIZE,
  payload: {
    user,
    district,
    users,
    province,
    provinces,
    provinceManagers,
    districts,
    districtMemberships,
    wards,
    wardMemberships,
    hamlets,
    categories,
    contents,
    contentCategories,
    stations,
    devices,
    deviceMemberships,
    deviceContents,
    tasks,
    attachments,
    activities,
    notifications,
    contentLocations,
    userGroups,
    permissions,
  },
});

const logout = () => ({
  type: ActionTypes.LOGOUT,
  payload: {},
});

logout.invalidateAccessToken = () => ({
  type: ActionTypes.LOGOUT__ACCESS_TOKEN_INVALIDATE,
  payload: {},
});

const changeSidebarMenu = () => ({
  type: ActionTypes.SIDEBAR_MENU,
  payload: {},
});

const selectContentId = (id, content) => ({
  type: ActionTypes.SELECT_CONTENT_ID,
  payload: { id, content },
});

export default {
  initializeCore,
  logout,
  changeSidebarMenu,
  selectContentId,
};
