import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { inRange, truncate } from 'lodash';
import { useTranslation } from 'react-i18next';
import vi from 'date-fns/locale/vi';
import { Button, Icon, Label, Modal, Popup } from 'semantic-ui-react';
import { format, formatDistanceToNow } from 'date-fns';
import { rrulestr } from 'rrule';
import { Link } from 'react-router-dom';

import { useSteps } from '../../hooks';
import getLinkStream from '../../utils/get-link-stream';
import rruleTextVi from '../../utils/rrule';
import { ScheduleLogStatusOptions } from '../../constants/Enums';
import Paths from '../../constants/Paths';

import DeleteStep from '../DeleteStep';
import EditStep from './EditStep';

import styles from './ScheduleEditModal.module.scss';

const StepTypes = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
};

const ScheduleEditModal = React.memo(
  ({
    slug,
    defaultData,
    username,
    contents,
    slots,
    canEdit,
    isSubmitting,
    token,
    onUpdate,
    onDelete,
    onClose,
    onSetStyleModal,
    onEditContent,
    onRefetchContents,
  }) => {
    const [t] = useTranslation();
    const [step, openStep, handleBack] = useSteps();
    const [currentAudioUrl, setCurrentAudioUrl] = useState('');

    // const handleEditClick = useCallback(() => {
    //   if (inRange(window.innerWidth, 600, 1280) && window.innerHeight - 310 < slots.style.top) {
    //     onSetStyleModal({
    //       left: slots.style.left,
    //       top: slots.style.top - (window.innerWidth < 768 ? 280 : 90),
    //     });
    //   }
    //   openStep(StepTypes.EDIT);
    // }, [openStep, slots, onSetStyleModal]);

    // const handleDeleteClick = useCallback(() => {
    //   openStep(StepTypes.DELETE);
    // }, [openStep]);

    const handleDelete = useCallback(
      (id) => {
        onDelete(id);
        onClose();
      },
      [onClose, onDelete],
    );

    const handleClick = useCallback(
      (url) => {
        if (url === currentAudioUrl && currentAudioUrl !== '') {
          setCurrentAudioUrl('');
        } else {
          setCurrentAudioUrl(url);
        }
      },
      [currentAudioUrl],
    );

    const handleOnBack = useCallback(() => {
      if (inRange(window.innerWidth, 599, 1280)) {
        onSetStyleModal({
          left: slots.style.left,
          top: slots.style.top + (window.innerWidth < 768 ? 280 : 90),
        });
      }
      handleBack();
    }, [slots, handleBack, onSetStyleModal]);

    const handleStopAudio = useCallback(() => {
      setCurrentAudioUrl('');
    }, []);

    useEffect(() => {
      handleBack();
      handleStopAudio();
    }, [handleBack, handleStopAudio, defaultData.id]);

    const dataDisplay = useMemo(() => {
      const dateFormatVN = format(defaultData.start, 'iiii, dd MMMM', { locale: vi });
      const distanceToNow = formatDistanceToNow(defaultData.start, {
        locale: vi,
        addSuffix: true,
      });
      const contentCurrent = contents.find(({ id }) => id === defaultData.contentId);

      return {
        ...defaultData,
        startDate: dateFormatVN,
        start: format(defaultData.start, 'p', { locale: vi }),
        end: format(defaultData.end, 'p', { locale: vi }),
        // description: 'some thing',
        resource: defaultData.resource || undefined,
        createdAt: distanceToNow,
        username,
        color: defaultData.color,
        attachment:
          contentCurrent?.type === 'audio' // eslint-disable-line no-nested-ternary
            ? {
                ...contentCurrent?.attachments?.[0],
                url: `${contentCurrent?.attachments?.[0]?.url}?accessToken=${token}`,
              }
            : contentCurrent?.type === 'stream'
            ? {
                name: getLinkStream(contentCurrent.description),
                url: getLinkStream(contentCurrent.link),
              }
            : undefined,
        isViewContent: !!contentCurrent && defaultData.status !== 'deleted',
      };
    }, [defaultData, username, contents, token]);

    if (step) {
      if (step.type === StepTypes.EDIT) {
        return (
          <EditStep
            title="common.editSchedule"
            buttonContent="action.editSchedule"
            contents={contents}
            defaultData={defaultData}
            isSubmitting={isSubmitting}
            onBack={handleOnBack}
            onClose={onClose}
            onUpdate={onUpdate}
          />
        );
      }

      if (step.type === StepTypes.DELETE) {
        return (
          <DeleteStep
            title="common.deleteSchedule"
            content="common.areYouSureYouWantToDeleteThisSchedule"
            buttonContent="action.deleteSchedule"
            onConfirm={() => handleDelete(defaultData.id)}
            onBack={handleBack}
          />
        );
      }
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.actions}>
            {dataDisplay.isViewContent && (
              <Button
                as={Link}
                disabled={dataDisplay.playStatus === 'playing'}
                icon={canEdit ? 'edit' : 'eye'}
                onClick={onRefetchContents}
                to={Paths.CONTENT.replace(':slug', slug).replace(':id', defaultData.contentId)}
                // onClick={() => onEditContent(defaultData.contentId)}
                style={{ background: 'transparent', boxShadow: 'none' }}
              />
            )}
            {/* <Button icon="trash alternate outline" onClick={handleDeleteClick} /> */}
            <Button icon="close" onClick={onClose} />
          </div>
        </div>
        <Modal.Content>
          <div className={styles.rowItem}>
            <Icon name="square" style={{ color: dataDisplay.color }} />
            <div>
              <h3>{dataDisplay.title}</h3>
            </div>
          </div>
          {dataDisplay.status === 'active' && (
            <div className={styles.rowItem}>
              {dataDisplay.playStatus === 'playing' ? (
                <Icon name="play" className={styles.playing} />
              ) : (
                <span />
              )}
              <div>
                <Label
                  size="small"
                  color={
                    ScheduleLogStatusOptions.find((it) => it.key === defaultData.playStatus).color
                  }
                >
                  {t(`common.${dataDisplay.playStatus}`)}
                </Label>
              </div>
            </div>
          )}
          {dataDisplay.device && (
            <div className={styles.rowItem}>
              <Icon name="rss" />
              <div>
                <Label size="small" color="blue">
                  {dataDisplay.device}
                </Label>
              </div>
            </div>
          )}
          <div className={styles.rowItem}>
            <span />
            <div>
              {dataDisplay.startDate}
              {defaultData.start?.getFullYear() !== new Date().getFullYear() &&
                `, ${defaultData.start?.getFullYear()}`}
              <span>
                {dataDisplay.start} - {dataDisplay.end}
              </span>
              {dataDisplay.repeatRule && (
                <div>{rruleTextVi(rrulestr(defaultData.repeatRule).toText())}</div>
              )}
            </div>
          </div>
          {dataDisplay.attachment && (
            <div className={styles.rowItem}>
              <Icon name="attach" />
              <div>
                <Popup
                  size="mini"
                  wide="very"
                  content={dataDisplay.attachment.name}
                  trigger={
                    <Label
                      icon={currentAudioUrl === dataDisplay.attachment.url ? 'pause' : 'play'}
                      as="a"
                      className={styles.attachment}
                      content={truncate(dataDisplay.attachment.name, { length: 20 })}
                      onClick={() => handleClick(dataDisplay.attachment.url)}
                    />
                  }
                />
              </div>
            </div>
          )}
          <audio src={currentAudioUrl} autoPlay={!!currentAudioUrl} onPause={handleStopAudio}>
            <track kind="captions" />
          </audio>
          {/* {dataDisplay.description && (
            <div className={styles.rowItem}>
              <Icon name="align justify" />
              <span>{dataDisplay.description}</span>
            </div>
          )} */}
          {dataDisplay.resource && (
            <div className={styles.rowItem}>
              <Icon name="attach" />
              <span>{dataDisplay.resource}</span>
            </div>
          )}
          {dataDisplay.createdAt && (
            <div className={styles.rowItem}>
              <Icon name="bell outline" />
              <span>{dataDisplay.createdAt}</span>
            </div>
          )}
          {dataDisplay.user && (
            <div className={styles.rowItem}>
              <Icon name="calendar outline" />
              <span>{dataDisplay.user?.name || dataDisplay.username}</span>
            </div>
          )}
        </Modal.Content>
      </div>
    );
  },
);

ScheduleEditModal.propTypes = {
  slug: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  contents: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  isSubmitting: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  slots: PropTypes.objectOf(PropTypes.any), // eslint-disable-line react/forbid-prop-types
  token: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSetStyleModal: PropTypes.func.isRequired,
  onEditContent: PropTypes.func.isRequired,
  onRefetchContents: PropTypes.func.isRequired,
};

ScheduleEditModal.defaultProps = {
  // color: undefined,
  // file: undefined,
  // fileDirname: undefined,
  slots: null,
};

export default ScheduleEditModal;
