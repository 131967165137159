import EntryActionTypes from '../constants/EntryActionTypes';

const createUserGroup = (data) => ({
  type: EntryActionTypes.USER_GROUP_CREATE,
  payload: {
    data,
  },
});

const handleUserGroupCreate = (userGroup) => ({
  type: EntryActionTypes.USER_GROUP_CREATE_HANDLE,
  payload: {
    userGroup,
  },
});

const updateUserGroup = (id, data) => ({
  type: EntryActionTypes.USER_GROUP_UPDATE,
  payload: {
    id,
    data,
  },
});

const handleUserGroupUpdate = (userGroup) => ({
  type: EntryActionTypes.USER_GROUP_UPDATE_HANDLE,
  payload: {
    userGroup,
  },
});

const updateCurrentUserGroup = (data) => ({
  type: EntryActionTypes.CURRENT_USER_GROUP_UPDATE,
  payload: {
    data,
  },
});

const deleteUserGroup = (id) => ({
  type: EntryActionTypes.USER_GROUP_DELETE,
  payload: {
    id,
  },
});

const handleUserGroupDelete = (userGroup) => ({
  type: EntryActionTypes.USER_GROUP_DELETE_HANDLE,
  payload: {
    userGroup,
  },
});

const fetchUserGroups = (page, limit, districtIds) => ({
  type: EntryActionTypes.USER_GROUPS_FETCH,
  payload: {
    page,
    limit,
    districtIds,
  },
});

const clearUserGroupError = () => ({
  type: EntryActionTypes.USER_GROUP_ERROR_CLEAR,
  payload: {},
});

export default {
  createUserGroup,
  handleUserGroupCreate,
  updateUserGroup,
  handleUserGroupUpdate,
  updateCurrentUserGroup,
  deleteUserGroup,
  handleUserGroupDelete,
  fetchUserGroups,
  clearUserGroupError,
};
