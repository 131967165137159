import { attr, fk } from 'redux-orm';
import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

export default class extends BaseModel {
  static modelName = 'ScheduleLog';

  static fields = {
    id: attr(),
    deviceId: fk({
      to: 'Device',
      as: 'device',
      relatedName: 'scheduleLogs',
    }),
    contentId: fk({
      to: 'Content',
      as: 'content',
      relatedName: 'scheduleLogs',
    }),
    scheduleId: fk({
      to: 'Schedule',
      as: 'schedule',
      relatedName: 'scheduleLogs',
    }),
    stationId: fk({
      to: 'Station',
      as: 'station',
      relatedName: 'scheduleLogs',
    }),
  };

  static reducer({ type, payload }, ScheduleLog) {
    switch (type) {
      case ActionTypes.CONTENT_FETCH_STATIONS_BROADCASTED__SUCCESS:
        if (payload.scheduleLogs) {
          ScheduleLog.all().delete();

          payload.scheduleLogs.forEach((item) => {
            ScheduleLog.upsert(item);
          });
        }

        break;

      default:
    }
  }
}
