import { call, put, select } from 'redux-saga/effects';

import { goToDistrict, goToDevice } from './router';
import request from '../request';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createDevice(stationId, data, autoOpen) {
  const { districtId } = yield select(selectors.selectStationById, stationId);
  const { id: provinceId } = yield select(selectors.selectCurrentProvince);

  const nextData = {
    ...data,
    position: yield select(selectors.selectNextDevicePosition, stationId),
  };

  const localId = yield call(createLocalId);

  yield put(
    actions.createDevice({
      ...nextData,
      districtId,
      stationId,
      id: localId,
    }),
  );

  let device;
  try {
    ({ item: device } = yield call(request, api.createDevice, stationId, nextData));
  } catch (error) {
    yield put(actions.createDevice.failure(localId, error));
    return;
  }

  yield put(actions.createDevice.success(localId, { ...device, provinceId }));

  if (autoOpen) {
    yield call(goToDevice, device.id);
  }
}

export function* handleDeviceCreate(device) {
  yield put(actions.handleDeviceCreate(device));
}

export function* fetchDevices(page, limit, filter) {
  const { id: provinceId } = yield select(selectors.selectCurrentProvince);
  yield put(actions.fetchDevices(provinceId));

  let devices;
  let total;

  try {
    ({
      items: devices,
      included: { total },
    } = yield call(request, api.getDevices, provinceId, { page, limit, filter }));
  } catch (error) {
    yield put(actions.fetchDevices.failure(provinceId, error));
    return;
  }

  yield put(actions.fetchDevices.success(provinceId, devices, total));
}

export function* updateDevice(id, data) {
  yield put(actions.updateDevice(id, data));

  let device;
  try {
    ({ item: device } = yield call(request, api.updateDevice, id, data));
  } catch (error) {
    yield put(actions.updateDevice.failure(id, error));
    return;
  }

  yield put(actions.updateDevice.success(device));
}

export function* updateCurrentDevice(data) {
  const { deviceId } = yield select(selectors.selectPath);

  yield call(updateDevice, deviceId, data);
}

// TODO: handle device transfer
export function* handleDeviceUpdate(device) {
  yield put(actions.handleDeviceUpdate(device));
}

export function* moveDevice(id, stationId, index) {
  const position = yield select(selectors.selectNextDevicePosition, stationId, index, id);

  yield call(updateDevice, id, {
    stationId,
    position,
  });
}

export function* moveCurrentDevice(stationId, index) {
  const { deviceId } = yield select(selectors.selectPath);

  yield call(moveDevice, deviceId, stationId, index);
}

export function* transferDevice(id, districtId, stationId, index) {
  const { deviceId: currentDeviceId, districtId: currentDistrictId } = yield select(
    selectors.selectPath,
  );
  const position = yield select(selectors.selectNextDevicePosition, stationId, index, id);

  if (id === currentDeviceId) {
    yield call(goToDistrict, currentDistrictId);
  }

  yield call(updateDevice, id, {
    districtId,
    stationId,
    position,
  });
}

export function* transferCurrentDevice(districtId, stationId, index) {
  const { deviceId } = yield select(selectors.selectPath);

  yield call(transferDevice, deviceId, districtId, stationId, index);
}

export function* deleteDevice(id) {
  const { deviceId, districtId } = yield select(selectors.selectPath);
  const { id: provinceId } = yield select(selectors.selectCurrentProvince);

  if (id === deviceId) {
    yield call(goToDistrict, districtId);
  }

  yield put(actions.deleteDevice(id));

  let device;
  try {
    ({ item: device } = yield call(request, api.deleteDevice, id));
  } catch (error) {
    yield put(actions.deleteDevice.failure(id, error));
    return;
  }

  yield put(actions.deleteDevice.success({ ...device, provinceId }));
}

export function* deleteCurrentDevice() {
  const { deviceId } = yield select(selectors.selectPath);

  yield call(deleteDevice, deviceId);
}

export function* handleDeviceDelete(device) {
  const { deviceId, districtId } = yield select(selectors.selectPath);

  if (device.id === deviceId) {
    yield call(goToDistrict, districtId);
  }

  yield put(actions.handleDeviceDelete(device));
}

export function* controlDevices(data) {
  const { id: provinceId } = yield select(selectors.selectCurrentProvince);
  yield put(actions.controlDevices(provinceId, data));

  let isSuccess;

  try {
    ({ isSuccess } = yield call(request, api.controlDevices, provinceId, data));
  } catch (error) {
    yield put(actions.controlDevices.failure(provinceId, error));
    return;
  }

  yield put(actions.controlDevices.success(provinceId, isSuccess));
}

export default {
  createDevice,
  handleDeviceCreate,
  fetchDevices,
  controlDevices,
  updateDevice,
  updateCurrentDevice,
  moveDevice,
  moveCurrentDevice,
  transferDevice,
  transferCurrentDevice,
  handleDeviceUpdate,
  deleteDevice,
  deleteCurrentDevice,
  handleDeviceDelete,
};
