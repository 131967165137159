import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import { useDidUpdate, useToggle } from '../../../lib/hooks';
import { Input, Popup } from '../../../lib/custom-ui';

import { useForm } from '../../../hooks';
import { UnitTypes } from '../../../constants/Enums';

import styles from './AddStep.module.scss';

const AddStep = React.memo(({ id, unit, code, onCreate, onClose }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange, setData] = useForm({
    name: '',
    code: code || '',
  });

  const [focusNameFieldState, focusNameField] = useToggle();

  const nameField = useRef(null);
  const identifierField = useRef(null);

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      name: data.name.trim(),
      code: data.code.trim(),
      ...(unit === 'DISTRICT' && {
        provinceId: id,
        position: 2,
        NhanLuc: {
          SanXuatNoiDung: 0,
          KyThuat: 0,
          CongViecKhac: 0,
        },
        ThietBiCongNghe: {
          MayTinh: 0,
          MayQuayPhim: 0,
          MayThuAmKyThuatSo: 0,
          MayAnh: 0,
          PhanMemBienTapChuongTrinhTruyenHinh: 0,
          PhanMemBienTapChuongTrinhPhatThanh: 0,
        },
        ChuongTrinhTuSanXuat: {
          PhatThanh: 0,
          TruyenHinh: 0,
          TruyenThongDaPhuongTien: 0,
        },
        TrangThongTinDienTu: 0,
      }),
      ...(unit === 'WARD' && {
        districtId: id,
        NhanLuc: {
          CongChucVanHoaXaHoi: 0,
          CongChucKhac: 0,
          NguoiHoatDongKhongChuyenTrach: 0,
          TuyenTruyenVienCoSo: 0,
        },
        TrangThongTinDienTu: 0,
        DaiFm: 0,
      }),
      ...(unit === 'HAMLET' && {
        wardId: id,
      }),
    };

    if (!cleanData.name) {
      nameField.current.select();
      return;
    }

    if (!cleanData.code) {
      identifierField.current.select();
      return;
    }

    onCreate(cleanData);
    onClose();
  }, [id, unit, onClose, data, onCreate]);

  useEffect(() => {
    nameField.current.focus({
      preventScroll: true,
    });
  }, []);

  useDidUpdate(() => {
    nameField.current.focus();
  }, [focusNameFieldState]);

  return (
    <>
      <Popup.Header>
        {t(UnitTypes[unit].title, {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Form onSubmit={handleSubmit}>
          <Input
            fluid
            placeholder={t(UnitTypes[unit].name)}
            ref={nameField}
            name="name"
            value={data.name}
            className={styles.field}
            onChange={handleFieldChange}
          />
          <Input
            fluid
            placeholder={t('common.identifier')}
            ref={identifierField}
            name="code"
            value={data.code}
            className={styles.field}
            onChange={handleFieldChange}
          />
          <div className={styles.controls}>
            <Button positive content={t('action.create')} className={styles.createButton} />
          </div>
        </Form>
      </Popup.Content>
    </>
  );
});

AddStep.propTypes = {
  id: PropTypes.string,
  unit: PropTypes.string.isRequired,
  code: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

AddStep.defaultProps = {
  id: undefined,
  code: undefined,
};

export default AddStep;
