import upperFirst from 'lodash/upperFirst';
import React from 'react';
import { Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './IdentifierSIM.module.scss';

const SIZES = {
  TINY: 'tiny',
  SMALL: 'small',
  MEDIUM: 'medium',
};

const IdentifierSIM = React.memo(({ value, size, isDisabled, onClick }) => {
  const [t] = useTranslation();

  const contentNode = (
    <>
      {value.code && (
        <span
          className={classNames(
            styles.wrapper,
            styles.marginRight,
            styles[`wrapper${upperFirst(size)}`],
            onClick && styles.wrapperHoverable,
          )}
        >
          {`${t('common.code')}: ${value.code}`}
        </span>
      )}
      {value.simNumber && (
        <span
          className={classNames(
            styles.wrapper,
            styles[`wrapper${upperFirst(size)}`],
            onClick && styles.wrapperHoverable,
          )}
        >
          {`${t('common.simNumber')}: ${value.simNumber}`}
        </span>
      )}
    </>
  );

  return onClick ? (
    <button type="button" disabled={isDisabled} className={styles.button} onClick={onClick}>
      {contentNode}
    </button>
  ) : (
    contentNode
  );
});

IdentifierSIM.propTypes = {
  value: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  size: PropTypes.oneOf(Object.values(SIZES)),
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

IdentifierSIM.defaultProps = {
  size: SIZES.MEDIUM,
  isDisabled: false,
  onClick: undefined,
};

export default IdentifierSIM;
