import socket from './socket';
import { transformAttachment } from './attachments';

/* Transformers */

export const transformDevice = (device) => ({
  ...device,
  ...(device.dueDate && {
    dueDate: new Date(device.dueDate),
  }),
  ...(device.stopwatch && {
    stopwatch: {
      ...device.stopwatch,
      ...(device.stopwatch.startedAt && {
        startedAt: new Date(device.stopwatch.startedAt),
      }),
    },
  }),
});

export const transformDeviceData = (data) => ({
  ...data,
  ...(data.dueDate && {
    dueDate: data.dueDate.toISOString(),
  }),
  ...(data.stopwatch && {
    stopwatch: {
      ...data.stopwatch,
      ...(data.stopwatch.startedAt && {
        startedAt: data.stopwatch.startedAt.toISOString(),
      }),
    },
  }),
});

/* Actions */

const createDevice = (stationId, data, headers) =>
  socket
    .post(`/stations/${stationId}/devices`, transformDeviceData(data), headers)
    .then((body) => ({
      ...body,
      item: transformDevice(body.item),
    }));

const getDevices = (provinceId, data, headers) =>
  socket.get(`/provinces/${provinceId}/devices`, data, headers).then((body) => ({
    ...body,
    items: body.items.map(transformDevice),
  }));

const controlDevices = (provinceId, data, headers) =>
  socket.put(`/provinces/${provinceId}/devices`, data, headers);

const getDevice = (id, headers) =>
  socket.get(`/devices/${id}`, undefined, headers).then((body) => ({
    ...body,
    item: transformDevice(body.item),
    included: {
      ...body.included,
      attachments: body.included.attachments.map(transformAttachment),
    },
  }));

const updateDevice = (id, data, headers) =>
  socket.patch(`/devices/${id}`, transformDeviceData(data), headers).then((body) => ({
    ...body,
    item: transformDevice(body.item),
  }));

const deleteDevice = (id, headers) =>
  socket.delete(`/devices/${id}`, undefined, headers).then((body) => ({
    ...body,
    item: transformDevice(body.item),
  }));

/* Event handlers */

const makeHandleDeviceCreate = (next) => (body) => {
  next({
    ...body,
    item: transformDevice(body.item),
  });
};

const makeHandleDeviceUpdate = makeHandleDeviceCreate;

const makeHandleDeviceDelete = makeHandleDeviceCreate;

export default {
  createDevice,
  getDevices,
  controlDevices,
  getDevice,
  updateDevice,
  deleteDevice,
  makeHandleDeviceCreate,
  makeHandleDeviceUpdate,
  makeHandleDeviceDelete,
};
