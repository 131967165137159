import ActionTypes from '../constants/ActionTypes';

const getReports = (provinceId, districtId) => ({
  type: ActionTypes.REPORTS_FETCH,
  payload: { provinceId, districtId },
});

getReports.success = (devices, stations, categories, contentCategories, contents, districtId) => ({
  type: ActionTypes.REPORTS_FETCH__SUCCESS,
  payload: { districtId, devices, stations, categories, contentCategories, contents },
});

getReports.failure = (error) => ({
  type: ActionTypes.REPORTS_FETCH__FAILURE,
  payload: { error },
});

const getExportReport = (provinceId, data) => ({
  type: ActionTypes.REPORTS_EXPORT,
  payload: { provinceId, data },
});

getExportReport.success = (devices, stations, contents) => ({
  type: ActionTypes.REPORTS_EXPORT__SUCCESS,
  payload: { devices, stations, contents },
});

getExportReport.failure = (error) => ({
  type: ActionTypes.REPORTS_EXPORT__FAILURE,
  payload: { error },
});

const clearExportState = () => ({
  type: ActionTypes.REPORTS_EXPORT_CLEAR,
  payload: {},
});

export default {
  getReports,
  getExportReport,
  clearExportState,
};
