import { call, select } from 'redux-saga/effects';

import request from '../request';
import selectors from '../../../selectors';
import api from '../../../api';
import Paths from '../../../constants/Paths';

export function* fetchStationByCurrentPath() {
  const pathsMatch = yield select(selectors.selectPathsMatch);

  let stations;
  let users;

  if (pathsMatch) {
    let provinceId;
    if (pathsMatch.pattern.path === Paths.STATIONS) {
      provinceId = pathsMatch.params.id;
    }

    if (provinceId) {
      ({
        items: stations,
        included: { users },
      } = yield call(request, api.getStations, provinceId, 1, 20));
    }
  }

  return {
    stations,
    users,
  };
}

export default {
  fetchStationByCurrentPath,
};
