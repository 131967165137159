import { call, put, select } from 'redux-saga/effects';

import requests from '../requests';
import selectors from '../../../selectors';
import actions from '../../../actions';

export function* handleSocketDisconnect() {
  yield put(actions.handleSocketDisconnect());
}

export function* handleSocketReconnect() {
  const currentUserId = yield select(selectors.selectCurrentUserId);
  const { districtId } = yield select(selectors.selectPath);

  yield put(actions.handleSocketReconnect.fetchCore(currentUserId, districtId));

  const {
    user,
    district,
    users,
    province,
    provinces,
    provinceManagers,
    districts,
    districtMemberships,
    wards,
    wardMemberships,
    hamlets,
    categories,
    contents,
    contentCategories,
    schedules,
    stations,
    devices,
    deviceMemberships,
    deviceContents,
    tasks,
    attachments,
    activities,
    notifications,
    totals,
    contentLocations,
    contentSchedules,
    userGroups,
  } = yield call(requests.fetchCore); // TODO: handle error

  yield put(
    actions.handleSocketReconnect(
      user,
      district,
      users,
      province,
      provinces,
      provinceManagers,
      districts,
      districtMemberships,
      wards,
      wardMemberships,
      hamlets,
      categories,
      contents,
      contentCategories,
      schedules,
      stations,
      devices,
      deviceMemberships,
      deviceContents,
      tasks,
      attachments,
      activities,
      notifications,
      totals,
      contentLocations,
      contentSchedules,
      userGroups,
    ),
  );
}

export default {
  handleSocketDisconnect,
  handleSocketReconnect,
};
