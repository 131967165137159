import EntryActionTypes from '../constants/EntryActionTypes';

const createMembershipInCurrentDistrict = (data) => ({
  type: EntryActionTypes.MEMBERSHIP_IN_CURRENT_DISTRICT_CREATE,
  payload: {
    data,
  },
});

const handleDistrictMembershipCreate = (districtMembership) => ({
  type: EntryActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE,
  payload: {
    districtMembership,
  },
});

const updateDistrictMembership = (id, data) => ({
  type: EntryActionTypes.DISTRICT_MEMBERSHIP_UPDATE,
  payload: {
    id,
    data,
  },
});

const handleDistrictMembershipUpdate = (districtMembership) => ({
  type: EntryActionTypes.DISTRICT_MEMBERSHIP_UPDATE_HANDLE,
  payload: {
    districtMembership,
  },
});

const deleteDistrictMembership = (id) => ({
  type: EntryActionTypes.DISTRICT_MEMBERSHIP_DELETE,
  payload: {
    id,
  },
});

const handleDistrictMembershipDelete = (districtMembership) => ({
  type: EntryActionTypes.DISTRICT_MEMBERSHIP_DELETE_HANDLE,
  payload: {
    districtMembership,
  },
});

export default {
  createMembershipInCurrentDistrict,
  handleDistrictMembershipCreate,
  updateDistrictMembership,
  handleDistrictMembershipUpdate,
  deleteDistrictMembership,
  handleDistrictMembershipDelete,
};
