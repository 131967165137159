import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* contentsWatchers() {
  yield all([
    takeEvery(EntryActionTypes.CONTENT_IN_CURRENT_DISTRICT_CREATE, ({ payload: { data } }) =>
      services.createContentInCurrentDistrict(data),
    ),
    takeEvery(EntryActionTypes.CONTENT_IN_CURRENT_PROVINCE_CREATE, ({ payload: { data } }) =>
      services.createContentInCurrentProvince(data),
    ),
    takeEvery(EntryActionTypes.CONTENT_CREATE_HANDLE, ({ payload: { content } }) =>
      services.handleContentCreate(content),
    ),
    takeEvery(EntryActionTypes.CONTENTS_FETCH, ({ payload: { page, limit, filter } }) =>
      services.fetchContents(page, limit, filter),
    ),
    takeEvery(EntryActionTypes.CONTENTS_SEARCH, ({ payload: { filter } }) =>
      services.searchContents(filter),
    ),
    takeEvery(EntryActionTypes.CONTENT_SELECT, ({ payload: { content } }) =>
      services.selectContent(content),
    ),
    takeEvery(EntryActionTypes.CONTENT_UPDATE, ({ payload: { id, data } }) =>
      services.updateContent(id, data),
    ),
    takeEvery(EntryActionTypes.CONTENT_UPDATE_HANDLE, ({ payload: { content, attached } }) =>
      services.handleContentUpdate(content, attached),
    ),
    takeEvery(EntryActionTypes.CONTENT_MOVE, ({ payload: { id, index } }) =>
      services.moveContent(id, index),
    ),
    takeEvery(EntryActionTypes.CONTENT_DELETE, ({ payload: { id } }) => services.deleteContent(id)),
    takeEvery(EntryActionTypes.CONTENT_DELETE_HANDLE, ({ payload: { content } }) =>
      services.handleContentDelete(content),
    ),
    takeEvery(EntryActionTypes.CONTENT_TEXT_TO_SPEECH, ({ payload: { data } }) =>
      services.text2Speech(data),
    ),
    takeEvery(EntryActionTypes.CONTENT_TO_DEVICE_ADD, ({ payload: { id, deviceId } }) =>
      services.addContentToDevice(id, deviceId),
    ),
    takeEvery(EntryActionTypes.CONTENT_TO_CURRENT_DEVICE_ADD, ({ payload: { id } }) =>
      services.addContentToCurrentDevice(id),
    ),
    takeEvery(EntryActionTypes.CONTENT_TO_DEVICE_ADD_HANDLE, ({ payload: { deviceContent } }) =>
      services.handleContentToDeviceAdd(deviceContent),
    ),
    takeEvery(EntryActionTypes.CONTENT_FROM_DEVICE_REMOVE, ({ payload: { id, deviceId } }) =>
      services.removeContentFromDevice(id, deviceId),
    ),
    takeEvery(EntryActionTypes.CONTENT_FROM_CURRENT_DEVICE_REMOVE, ({ payload: { id } }) =>
      services.removeContentFromCurrentDevice(id),
    ),
    takeEvery(
      EntryActionTypes.CONTENT_FROM_DEVICE_REMOVE_HANDLE,
      ({ payload: { deviceContent } }) => services.handleContentFromDeviceRemove(deviceContent),
    ),
    takeEvery(EntryActionTypes.CONTENT_TO_FILTER_IN_CURRENT_DISTRICT_ADD, ({ payload: { id } }) =>
      services.addContentToFilterInCurrentDistrict(id),
    ),
    takeEvery(
      EntryActionTypes.CONTENT_FROM_FILTER_IN_CURRENT_DISTRICT_REMOVE,
      ({ payload: { id } }) => services.removeContentFromFilterInCurrentDistrict(id),
    ),
    takeEvery(EntryActionTypes.CONTENT_ERROR_CLEAR, () => services.clearContentError()),
    takeEvery(EntryActionTypes.CONTENT_PLAYLIST_CLEAR, () => services.clearContentPlaylist()),
    takeEvery(EntryActionTypes.CONTENT_CANCEL_SCHEDULE, ({ payload: { id, LichPhatID } }) =>
      services.cancelScheduleContent(id, LichPhatID),
    ),
    takeEvery(
      EntryActionTypes.CONTENT_CANCEL_SCHEDULE_HANDLE,
      ({ payload: { content, contentSchedules } }) =>
        services.handleContentCancelSchedule(content, contentSchedules),
    ),
    takeEvery(EntryActionTypes.CONTENT_FETCH_STATIONS_BROADCASTED, ({ payload: { id, filter } }) =>
      services.fetchStationsBroadcastedContent(id, filter),
    ),
    takeEvery(EntryActionTypes.CONTENT_CONCAT_FILE, ({ payload: { data } }) =>
      services.concatFile(data),
    ),
    takeEvery(EntryActionTypes.CONTENT_CONCAT_FILE__CLEAR, () => services.clearContentConcatFile()),
    takeEvery(EntryActionTypes.CONTENT_CATEGORY_CREATE_HANDLE, ({ payload: { contentCategory } }) =>
      services.handleContentCategoryCreate(contentCategory),
    ),
    takeEvery(EntryActionTypes.CONTENT_CATEGORY_UPDATE_HANDLE, ({ payload: { contentCategory } }) =>
      services.handleContentCategoryUpdate(contentCategory),
    ),
    takeEvery(EntryActionTypes.CONTENT_CATEGORY_DELETE_HANDLE, ({ payload: { contentCategory } }) =>
      services.handleContentCategoryDelete(contentCategory),
    ),
    takeEvery(EntryActionTypes.CONTENT_LOCATION_CREATE_HANDLE, ({ payload: { contentLocation } }) =>
      services.handleContentLocationCreate(contentLocation),
    ),
    takeEvery(EntryActionTypes.CONTENT_LOCATION_DELETE_HANDLE, ({ payload: { contentLocation } }) =>
      services.handleContentLocationDelete(contentLocation),
    ),
    takeEvery(
      EntryActionTypes.CONTENT_LOCATION_UPDATE_HANDLE,
      ({ payload: { contentLocations } }) => services.handleContentLocationUpdate(contentLocations),
    ),
    takeEvery(EntryActionTypes.ENABLE_CONTENTS_FETCHING, () => services.enalbleContentsFetching()),
  ]);
}
