import isEmail from 'validator/lib/isEmail';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Form, Message, Modal } from 'semantic-ui-react';
import { usePrevious } from '../../lib/hooks';
import { Input } from '../../lib/custom-ui';

import { useForm } from '../../hooks';
import { isPassword, isUsername } from '../../utils/validator';
import { DistrictMembershipRoles, USER_LEVELS } from '../../constants/Enums';

import styles from './UserModalAddStep.module.scss';

const createMessage = (error) => {
  if (!error) {
    return error;
  }

  switch (error.message) {
    case 'Email already in use':
      return {
        type: 'error',
        content: 'common.emailAlreadyInUse',
      };
    case 'Username already in use':
      return {
        type: 'error',
        content: 'common.usernameAlreadyInUse',
      };
    default:
      return {
        type: 'warning',
        content: 'common.unknownError',
      };
  }
};

const USER_LEVEL_OPTIONS = [
  {
    key: USER_LEVELS.PROVINCE,
    value: USER_LEVELS.PROVINCE,
    text: 'common.provinceLevelUsers',
  },
  {
    key: USER_LEVELS.DISTRICT,
    value: USER_LEVELS.DISTRICT,
    text: 'common.districtLevelUsers',
  },
  {
    key: USER_LEVELS.WARD,
    value: USER_LEVELS.WARD,
    text: 'common.wardLevelUsers',
  },
];

const levelOptions = (level) => {
  switch (level) {
    case 'district':
      return USER_LEVEL_OPTIONS.slice(1);
    case 'ward':
      return USER_LEVEL_OPTIONS.slice(2);
    default:
      return USER_LEVEL_OPTIONS;
  }
};

const UserModalAddStep = React.memo(
  ({
    defaultData,
    provinces,
    districts,
    wards,
    groups,
    isSubmitting,
    error,
    level,
    onCreate,
    onMessageDismiss,
    onClose,
  }) => {
    const [t] = useTranslation();
    const wasSubmitting = usePrevious(isSubmitting);

    const [data, handleFieldChange] = useForm(() => ({
      email: '',
      password: '',
      name: '',
      username: '',
      level: '',
      userGroupId: '',
      ...defaultData,
    }));

    const message = useMemo(() => createMessage(error), [error]);

    const emailField = useRef(null);
    const passwordField = useRef(null);
    const nameField = useRef(null);
    const usernameField = useRef(null);
    const levelField = useRef(null);
    const locationField = useRef(null);
    const groupField = useRef(null);

    const handleSubmit = useCallback(() => {
      const cleanData = {
        ...data,
        email: data.email.trim(),
        name: data.name.trim(),
        username: data.username.trim() || null,
        level: data.level.trim(),
        locationId: data.locationId?.trim(),
        userGroupId: data.userGroupId.trim(),
      };

      if (!isEmail(cleanData.email)) {
        emailField.current.select();
        return;
      }

      if (!cleanData.password || !isPassword(cleanData.password)) {
        passwordField.current.focus();
        return;
      }

      if (!cleanData.level) {
        levelField.current.open();
        return;
      }

      if (!cleanData.locationId) {
        locationField.current.open();
        return;
      }

      if (!cleanData.userGroupId) {
        groupField.current.open();
        return;
      }

      if (!cleanData.name) {
        nameField.current.select();
        return;
      }

      if (cleanData.username && !isUsername(cleanData.username)) {
        usernameField.current.select();
        return;
      }

      onCreate(cleanData);
    }, [onCreate, data]);

    const LevelOptions = levelOptions(level);

    const areaOptions = useMemo(() => {
      const ProvinceOptions = provinces.map((province) => ({
        key: province.id,
        value: province.id,
        text: `${province.code} - ${province.name}`,
      }));

      const DistrictOptions = districts.map((district) => ({
        key: district.id,
        value: district.id,
        text: `${district.code} - ${district.name}`,
      }));

      const WardOptions = wards.map((ward) => ({
        key: ward.id,
        value: ward.id,
        text: `${ward.code} - ${ward.name}`,
      }));

      switch (data.level) {
        case USER_LEVELS.PROVINCE:
          return ProvinceOptions;
        case USER_LEVELS.DISTRICT:
          return DistrictOptions;
        case USER_LEVELS.WARD:
          return WardOptions;

        default:
          return [];
      }
    }, [data.level, provinces, districts, wards]);

    const GroupOptions = groups.map((group) => ({
      key: group.id,
      value: group.id,
      text: group.name,
    }));

    useEffect(() => {
      emailField.current.focus({
        preventScroll: true,
      });
    }, []);

    useEffect(() => {
      if (wasSubmitting && !isSubmitting) {
        if (error) {
          switch (error.message) {
            case 'Email already in use':
              emailField.current.select();

              break;
            case 'Username already in use':
              usernameField.current.select();

              break;
            default:
          }
        } else {
          onClose();
        }
      }
    }, [isSubmitting, wasSubmitting, error, onClose]);

    return (
      <Modal open size="tiny" closeIcon onClose={onClose}>
        <Modal.Header>
          {t('common.addUser', {
            context: 'title',
          })}
        </Modal.Header>
        <Modal.Content size="large">
          {message && (
            <Message
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...{
                [message.type]: true,
              }}
              visible
              content={t(message.content)}
              onDismiss={onMessageDismiss}
            />
          )}
          <Form onSubmit={handleSubmit}>
            <div className={styles.text}>{t('common.email')}</div>
            <Input
              fluid
              ref={emailField}
              name="email"
              value={data.email}
              readOnly={isSubmitting}
              className={styles.field}
              onChange={handleFieldChange}
            />
            <div className={styles.text}>{t('common.password')}</div>
            <Input.Password
              withStrengthBar
              fluid
              ref={passwordField}
              name="password"
              value={data.password}
              readOnly={isSubmitting}
              className={styles.field}
              onChange={handleFieldChange}
            />
            <div className={styles.text}>{t('common.userLevel_title')}</div>
            <Dropdown
              fluid
              ref={levelField}
              selection
              readOnly={isSubmitting}
              options={LevelOptions.map((userLevel) => ({
                ...userLevel,
                text: t(userLevel.text),
              }))}
              name="level"
              value={data.level}
              className={styles.field}
              onChange={handleFieldChange}
            />
            <div className={styles.text}>{t('common.selectArea')}</div>
            <Dropdown
              fluid
              ref={locationField}
              selection
              readOnly={isSubmitting}
              options={areaOptions}
              name="locationId"
              value={data.locationId}
              className={styles.field}
              onChange={handleFieldChange}
            />
            <div className={styles.text}>{t('common.userGroups')}</div>
            <Dropdown
              fluid
              ref={groupField}
              selection
              name="userGroupId"
              value={data.userGroupId}
              readOnly={isSubmitting}
              options={GroupOptions}
              className={styles.field}
              onChange={handleFieldChange}
            />
            <div className={styles.text}>{t('common.name')}</div>
            <Input
              fluid
              ref={nameField}
              name="name"
              value={data.name}
              readOnly={isSubmitting}
              className={styles.field}
              onChange={handleFieldChange}
            />
            <div className={styles.text}>
              {t('common.username')} (
              {t('common.optional', {
                context: 'inline',
              })}
              )
            </div>
            <Input
              fluid
              ref={usernameField}
              name="username"
              value={data.username}
              readOnly={isSubmitting}
              className={styles.field}
              onChange={handleFieldChange}
            />
            <Button
              positive
              content={t('action.addUser')}
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </Form>
        </Modal.Content>
      </Modal>
    );
  },
);

UserModalAddStep.propTypes = {
  level: PropTypes.string.isRequired,
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  provinces: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  districts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  wards: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  groups: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onCreate: PropTypes.func.isRequired,
  onMessageDismiss: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

UserModalAddStep.defaultProps = {
  error: undefined,
};

export default UserModalAddStep;
