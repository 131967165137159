import { attr, fk, many } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

const DEFAULT_DELETE_FORM = {
  data: {},
  error: null,
};

export default class extends BaseModel {
  static modelName = 'District';

  static fields = {
    id: attr(),
    position: attr(),
    name: attr(),
    isFetching: attr({
      getDefault: () => null,
    }),
    provinceId: fk({
      to: 'Province',
      as: 'province',
      relatedName: 'districts',
    }),
    deleteForm: attr({
      getDefault: () => DEFAULT_DELETE_FORM,
    }),
    memberUsers: many({
      to: 'User',
      through: 'DistrictMembership',
      relatedName: 'districts',
    }),
    filterUsers: many('User', 'filterDistricts'),
    filterContents: many('Content', 'filterDistricts'),
  };

  static reducer({ type, payload }, District) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
        if (payload.district) {
          District.upsert({
            ...payload.district,
            isFetching: false,
          });
        }

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__DISTRICT_FETCH:
      case ActionTypes.DISTRICT_FETCH:
        District.withId(payload.id).update({
          isFetching: true,
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        District.all().delete();

        if (payload.district) {
          District.upsert({
            ...payload.district,
            isFetching: false,
          });
        }

        payload.districts.forEach((district) => {
          District.upsert(district);
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE__CORE_FETCH:
        District.all()
          .toModelArray()
          .forEach((districtModel) => {
            if (districtModel.id !== payload.currentDistrictId) {
              districtModel.update({
                isFetching: null,
              });

              districtModel.deleteRelated(payload.currentUserId);
            }
          });

        break;
      case ActionTypes.CORE_INITIALIZE:
        if (payload.district) {
          District.upsert({
            ...payload.district,
            isFetching: false,
          });
        }

        payload.districts.forEach((district) => {
          District.upsert(district);
        });

        break;
      case ActionTypes.USER_TO_DISTRICT_FILTER_ADD:
        District.withId(payload.districtId).filterUsers.add(payload.id);

        break;
      case ActionTypes.USER_FROM_DISTRICT_FILTER_REMOVE:
        District.withId(payload.districtId).filterUsers.remove(payload.id);

        break;
      case ActionTypes.PROVINCE_CREATE_HANDLE:
        payload.districts.forEach((district) => {
          District.upsert(district);
        });

        break;
      case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE:
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE:
        if (payload.districts) {
          payload.districts.forEach((district) => {
            District.upsert({
              ...district,
              ...(payload.district &&
                payload.district.id === district.id && {
                  isFetching: false,
                }),
            });
          });
        }

        break;
      case ActionTypes.DISTRICT_CREATE:
      case ActionTypes.DISTRICT_CREATE_HANDLE:
      case ActionTypes.DISTRICT_UPDATE__SUCCESS:
      case ActionTypes.DISTRICT_UPDATE_HANDLE:
        District.upsert(payload.district);

        break;
      case ActionTypes.DISTRICT_CREATE__SUCCESS:
        District.withId(payload.localId).delete();
        District.upsert(payload.district);

        break;
      case ActionTypes.DISTRICT_FETCH__SUCCESS:
        District.upsert({
          ...payload.district,
          isFetching: false,
        });

        break;
      case ActionTypes.DISTRICT_FETCH__FAILURE:
        District.withId(payload.id).update({
          isFetching: null,
        });

        break;
      case ActionTypes.DISTRICT_UPDATE:
        District.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.DISTRICT_DELETE:
        // District.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.DISTRICT_DELETE__SUCCESS:
      case ActionTypes.DISTRICT_DELETE_HANDLE: {
        const districtModel = District.withId(payload.district.id);

        if (districtModel) {
          districtModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.DISTRICT_DELETE__FAILURE: {
        const districtModel = District.withId(payload.id);

        districtModel.update({
          deleteForm: {
            ...districtModel.deleteForm,
            error: payload.error,
          },
        });

        break;
      }
      case ActionTypes.CONTENT_TO_DISTRICT_FILTER_ADD:
        District.withId(payload.districtId).filterContents.add(payload.id);

        break;
      case ActionTypes.CONTENT_FROM_DISTRICT_FILTER_REMOVE:
        District.withId(payload.districtId).filterContents.remove(payload.id);

        break;
      default:
    }
  }

  getOrderedMembershipsQuerySet() {
    return this.memberships.orderBy('id');
  }

  getOrderedContentsQuerySet() {
    return this.contents.orderBy('position');
  }

  getOrderedStationsQuerySet() {
    return this.stations.orderBy('position');
  }

  getOrderedWardsQuerySet() {
    return this.wards.orderBy('id');
  }

  getMembershipModelForUser(userId) {
    return this.memberships
      .filter({
        userId,
      })
      .first();
  }

  hasMembershipForUser(userId) {
    return this.memberships
      .filter({
        userId,
      })
      .exists();
  }

  isAvailableForUser(userId) {
    return (
      this.province &&
      (this.province.hasManagerForUser(userId) || this.hasMembershipForUser(userId))
    );
  }

  deleteRelated(exceptMemberUserId) {
    this.memberships.toModelArray().forEach((districtMembershipModel) => {
      if (districtMembershipModel.userId !== exceptMemberUserId) {
        districtMembershipModel.deleteWithRelated();
      }
    });

    this.contents.delete();

    this.stations.toModelArray().forEach((stationModel) => {
      stationModel.deleteWithRelated();
    });
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
