import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Header, Icon, Input, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Counter from './Counter';

import styles from './VerifyCode.module.scss';

const BUTTONS = ['send', 'retrieveCode'];

const VerifyCode = React.memo(
  ({
    isSubmitting,
    expires,
    isVerifyCode,
    message,
    onMessageDismiss,
    onAuthenticate,
    onStopCountDown,
    onFetchCode,
  }) => {
    const [t] = useTranslation();

    const codeField = useRef(null);

    const [code, setCode] = useState('');
    const [buttonSelected, setButtonSelected] = useState();

    const handleFieldChange = useCallback((_, { value }) => {
      setCode(value);
    }, []);

    const handleSubmit = useCallback(() => {
      const token = window.location.search.split('?')[1];

      if (!code) {
        codeField.current.focus();
        return;
      }

      setButtonSelected(BUTTONS[0]);

      onAuthenticate({ otp: code, token, authType: 'email' });
    }, [code, onAuthenticate]);

    const handleRetrieveCode = useCallback(() => {
      const token = window.location.search.split('?')[1];

      setButtonSelected(BUTTONS[1]);
      onFetchCode(token);
    }, [onFetchCode]);

    useEffect(() => {
      codeField.current.select();
    }, []);

    return (
      <>
        <Header
          as="h1"
          textAlign="left"
          content={t('common.verifyInformation')}
          className={styles.formTitle}
        />
        <strong className={styles.notice}>
          {t('common.toLoginEnterTheCodeSuppliedToYourEmailDddress')}
        </strong>
        <Counter expires={expires} onStopCountDown={onStopCountDown} isVerifyCode={isVerifyCode} />
        <div>
          {message && (
            <Message
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...{
                [message.type]: true,
              }}
              visible
              content={t(message.content)}
              onDismiss={onMessageDismiss}
            />
          )}
          <Form size="large" onSubmit={handleSubmit}>
            <div className={styles.inputWrapper}>
              <div className={styles.inputLabel}>{t('common.varifycationCodes')}</div>
              <Input
                fluid
                ref={codeField}
                name="code"
                value={code}
                readOnly={isSubmitting}
                className={styles.input}
                onChange={handleFieldChange}
              />
            </div>
            <Button
              size="large"
              fluid
              content={t(`action.${BUTTONS[0]}`)}
              loading={isSubmitting && buttonSelected === BUTTONS[0]}
              disabled={isSubmitting && buttonSelected !== BUTTONS[0]}
              className={styles.button}
            />
            <div>
              <strong>{t('common.youDidNotReceiveVerificationCode')}</strong>
              <Button
                size="large"
                type="button"
                animated="vertical"
                loading={isSubmitting && buttonSelected === BUTTONS[1]}
                disabled={isSubmitting && buttonSelected !== BUTTONS[1]}
                onClick={handleRetrieveCode}
                className={styles.marginLeftButton}
              >
                <Button.Content visible>{t(`action.${BUTTONS[1]}`)}</Button.Content>
                <Button.Content hidden>
                  <Icon name="modx" />
                </Button.Content>
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  },
);

export default VerifyCode;

VerifyCode.propTypes = {
  message: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  expires: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isSubmitting: PropTypes.bool.isRequired,
  isVerifyCode: PropTypes.bool.isRequired,
  onMessageDismiss: PropTypes.func.isRequired,
  onAuthenticate: PropTypes.func.isRequired,
  onStopCountDown: PropTypes.func.isRequired,
  onFetchCode: PropTypes.func.isRequired,
};

VerifyCode.defaultProps = {
  message: undefined,
  expires: null,
};
