import React, { useCallback, useRef, useState } from 'react';
import { MapContainer, TileLayer, useMap, useMapEvent } from 'react-leaflet';
import PropTypes from 'prop-types';

import 'leaflet/dist/leaflet.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

import L from 'leaflet';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import markerBroadcast from '../../assets/icons/maps/map-pin-2.svg';
import Config from '../../constants/Config';

const iconStation = new L.Icon({
  iconUrl: markerBroadcast,
  iconRetinaUrl: markerBroadcast,
  popupAnchor: [-0, -0],
  iconSize: [32, 45],
});

const { COORDINATES, VIEW_CENTER } = Config;
const coordinates = [
  ...JSON.parse(process.env.REACT_APP_SOUTH_WEST),
  JSON.parse(process.env.REACT_APP_NORTH_EAST),
];

const [DEFAULT_LAT, DEFAULT_LNG] = VIEW_CENTER;

const southWest = L.latLng(COORDINATES[0], COORDINATES[1]);

const northEast = L.latLng(COORDINATES[2], COORDINATES[3]);

const bounds = L.latLngBounds(southWest, northEast);

const PickLocation = React.memo(({ showAction, currentLocation, title, onBack, onClick }) => {
  const [t] = useTranslation();
  const marker = useRef(null);
  const mapControl = useRef(null);
  const [location, setLocation] = useState({
    lat: currentLocation.lat || DEFAULT_LAT,
    lng: currentLocation.lng || DEFAULT_LNG,
  });

  function Initialize() {
    const map = useMap();
    mapControl.current = map;

    map.setMaxBounds(bounds);
    map.isFullscreen();

    if (marker.current !== null) {
      map.removeLayer(marker.current);
    }

    marker.current = L.marker([location.lat, location.lng], {
      icon: iconStation,
      draggable: true,
    });

    map.panTo(new L.LatLng(location.lat, location.lng));

    map.addLayer(marker.current);

    marker.current.on('dragend', (event) => {
      const point = event.target;
      const position = point.getLatLng();

      point.setLatLng(new L.LatLng(position.lat, position.lng), { draggable: 'true' });

      setLocation({ lat: position.lat, lng: position.lng });
    });

    const handleOnClickMap = useCallback((e) => {
      setLocation({ lat: e.latlng.lat, lng: e.latlng.lng });
    }, []);

    useMapEvent('click', handleOnClickMap);

    return null;
  }

  const handleRemoveMarker = useCallback(() => {
    if (!marker.current) return;

    mapControl.current.removeLayer(marker.current);
    marker.current = null;
    setLocation({ lat: DEFAULT_LAT, lng: DEFAULT_LNG });
  }, []);

  const handleSelectLocation = useCallback(() => {
    onClick(location);
    onBack();
  }, [location, onClick, onBack]);

  return (
    <Modal open closeIcon size="small" centered={false} onClose={onBack}>
      <Modal.Header>
        <h4>
          {t(title, {
            context: 'title',
          })}
        </h4>
      </Modal.Header>
      <Modal.Content>
        <MapContainer
          center={[DEFAULT_LAT, DEFAULT_LNG]}
          minZoom={10}
          zoom={12}
          zoomSnap={0.25}
          zoomDelta={0.5}
          scrollWheelZoom
          wheelPxPerZoomLevel={120}
          fullscreenControl={showAction}
          dragging
          style={{
            width: '100%',
            height: 500,
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Initialize />
        </MapContainer>
      </Modal.Content>
      {showAction && (
        <Modal.Actions>
          <Button onClick={handleRemoveMarker} disabled={!location}>
            {t('action.cancel')}
          </Button>
          <Button positive onClick={handleSelectLocation} disabled={!location}>
            {t('action.save')}
          </Button>
        </Modal.Actions>
      )}
    </Modal>
  );
});

PickLocation.propTypes = {
  showAction: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  currentLocation: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

PickLocation.defaultProps = {
  showAction: true,
};

export default PickLocation;
