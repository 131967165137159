import { call, select } from 'redux-saga/effects';

import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import request from '../request';
import selectors from '../../../selectors';
import api from '../../../api';
import Config from '../../../constants/Config';
import Paths from '../../../constants/Paths';

export function* fetchProvinceByCurrentPath(myProvinces) {
  const pathsMatch = yield select(selectors.selectPathsMatch);

  let province;
  let district;
  let device;
  let users;
  let provinces;
  let districts;
  let districtMemberships;
  let wards;
  let wardMemberships;
  let hamlets;
  let categories;
  let contents;
  let contentCategories;
  let schedules;
  let stations;
  let devices;
  let deviceMemberships;
  let deviceContents;
  let tasks;
  let attachments;
  let total;
  let contentLocations;
  let contentSchedules;
  let userGroups;

  if (pathsMatch) {
    let districtId;
    let { slug } = pathsMatch.params;

    if (!slug && myProvinces.length > 0) {
      slug = myProvinces[0].slug;
    }

    if (slug) {
      try {
        ({
          item: province,
          included: {
            districts,
            districtMemberships,
            categories,
            stations,
            devices,
            wards,
            wardMemberships,
            hamlets,
            userGroups,
            users,
          },
        } = yield call(request, api.getProvince, slug));
      } catch (error) {
        console.error(error);
      }
    }

    switch (pathsMatch.pattern.path) {
      case Paths.DEVICES: {
        try {
          ({
            items: devices,
            included: { total },
          } = yield call(request, api.getDevices, province.id, {
            page: 1,
            limit: Config.LIMIT_PER_PAGE,
          }));
        } catch (error) {
          console.error(error);
        } // eslint-disable-line no-empty

        break;
      }
      case Paths.CONTENTS: {
        try {
          ({
            items: contents,
            included: { total, attachments, contentCategories, contentLocations, contentSchedules },
          } = yield call(request, api.getContents, province.id, {
            page: 1,
            limit: Config.LIMIT_PER_PAGE,
          }));
        } catch (error) {
          console.error(error);
        } // eslint-disable-line no-empty

        break;
      }
      case Paths.SCHEDULES: {
        const today = new Date();
        const from = startOfWeek(startOfMonth(today), { weekStartsOn: 1 });
        const to = endOfWeek(endOfMonth(today), { weekStartsOn: 1 });

        try {
          ({
            items: schedules,
            included: { contents },
          } = yield call(request, api.getSchedules, province.id, { from, to }));
        } catch (error) {
          console.error(error);
        } // eslint-disable-line no-empty

        break;
      }
      // case Paths.STATIONS: {
      //   try {
      //     ({
      //       items: stations,
      //       included: { total },
      //     } = yield call(request, api.getStations, province.id, {
      //       page: 1,
      //       limit: Config.LIMIT_PER_PAGE,
      //     }));
      //   } catch (error) {} // eslint-disable-line no-empty

      //   break;
      // }
      case Paths.DISTRICTS:
        districtId = pathsMatch.params.id;
        break;
      case Paths.DEVICE:
        ({
          item: device,
          item: { districtId },
        } = yield call(request, api.getDevice, pathsMatch.params.id));
        break;
      case Paths.SETTING_USERS:
        ({ items: userGroups } = yield call(request, api.getUserGroups, {
          page: 1,
          limit: 1000,
        }));
        break;
      case Paths.SETTING_USER_GROUPS:
        ({ items: userGroups } = yield call(request, api.getUserGroups, {
          page: 1,
          limit: Config.LIMIT_PER_PAGE,
        }));
        break;
      default:
    }

    if (districtId) {
      ({
        item: district,
        included: {
          provinces,
          stations,
          devices,
          deviceMemberships,
          deviceContents,
          tasks,
          attachments,
        },
      } = yield call(request, api.getDistrict, districtId, true));
    }
  }

  return {
    district,
    device,
    users,
    districts,
    districtMemberships,
    wards,
    wardMemberships,
    hamlets,
    categories,
    contents,
    contentCategories,
    schedules,
    stations,
    devices,
    deviceMemberships,
    deviceContents,
    tasks,
    attachments,
    total,
    province,
    contentLocations,
    contentSchedules,
    userGroups,
  };
}

export default {
  fetchProvinceByCurrentPath,
};
