import EntryActionTypes from '../constants/EntryActionTypes';

const liveBroadcastStart = (data) => ({
  type: EntryActionTypes.LIVE_BROADCAST_START,
  payload: { data },
});

const liveBroadcastStop = (data) => ({
  type: EntryActionTypes.LIVE_BROADCAST_STOP,
  payload: { data },
});

const handleBroadcastingError = (message) => ({
  type: EntryActionTypes.LIVE_BROADCAST_ERROR_HANDLE,
  payload: { message },
});

const handleBroadcastFinished = (message) => ({
  type: EntryActionTypes.LIVE_BROADCAST_FINISHED_HANDLE,
  payload: { message },
});

const clearBroadcast = () => ({
  type: EntryActionTypes.LIVE_BROADCAST_CLEAR,
  payload: {},
});

const closeMessageBroadcast = () => ({
  type: EntryActionTypes.LIVE_BROADCAST_MESSAGE_CLOSE,
  payload: {},
});

const handleOverrideContentByMicrophone = (content) => ({
  type: EntryActionTypes.OVERRIDE_CONTENT_BY_MICROPHONE,
  payload: { content },
});

export default {
  liveBroadcastStart,
  liveBroadcastStop,
  handleBroadcastFinished,
  handleBroadcastingError,
  clearBroadcast,
  closeMessageBroadcast,
  handleOverrideContentByMicrophone,
};
