import React from 'react';
import PropTypes from 'prop-types';
import { camelCase, upperFirst } from 'lodash';
import { Card, Header } from 'semantic-ui-react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './CardTotal.module.scss';
import globalStyles from '../../styles.module.scss';

const CardTotal = React.memo(({ item, color, path }) => {
  return (
    <Card
      className={classNames(
        styles.wrapCard,
        globalStyles[`background${upperFirst(camelCase(color))}`],
      )}
      as={Link}
      to={path}
    >
      <Card.Content className={styles.content}>
        <Card.Header className={styles.header}>{item.title}</Card.Header>

        <Card.Description className={styles.description}>
          <span>{item.num}</span>
          {item.icon}
        </Card.Description>
      </Card.Content>
    </Card>
  );
});

CardTotal.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  item: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default CardTotal;
