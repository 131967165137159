import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import entryActions from '../entry-actions';
import selectors from '../selectors';

import SearchContent from '../components/Schedules/SearchContent';

const mapStateToProps = (state) => {
  const { results, isLoading } = state.ui.contentSearch;

  return {
    results,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSearch: entryActions.searchContent,
      onSelectContent: entryActions.selectContent,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchContent);
