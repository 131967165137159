import ActionTypes from '../constants/ActionTypes';

const createProvinceManager = (provinceManager) => ({
  type: ActionTypes.PROVINCE_MANAGER_CREATE,
  payload: {
    provinceManager,
  },
});

createProvinceManager.success = (localId, provinceManager) => ({
  type: ActionTypes.PROVINCE_MANAGER_CREATE__SUCCESS,
  payload: {
    localId,
    provinceManager,
  },
});

createProvinceManager.failure = (localId, error) => ({
  type: ActionTypes.PROVINCE_MANAGER_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleProvinceManagerCreate = (
  provinceManager,
  province,
  district,
  users,
  provinceManagers,
  districts,
  districtMemberships,
  contents,
  stations,
  devices,
  deviceMemberships,
  deviceContents,
  tasks,
  attachments,
  deletedNotifications,
) => ({
  type: ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE,
  payload: {
    provinceManager,
    province,
    district,
    users,
    provinceManagers,
    districts,
    districtMemberships,
    contents,
    stations,
    devices,
    deviceMemberships,
    deviceContents,
    tasks,
    attachments,
    deletedNotifications,
  },
});

handleProvinceManagerCreate.fetchProvince = (id, currentUserId, currentDistrictId) => ({
  type: ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE__PROVINCE_FETCH,
  payload: {
    id,
    currentUserId,
    currentDistrictId,
  },
});

const deleteProvinceManager = (id, isCurrentUser, isCurrentProvince) => ({
  type: ActionTypes.PROVINCE_MANAGER_DELETE,
  payload: {
    id,
    isCurrentUser,
    isCurrentProvince,
  },
});

deleteProvinceManager.success = (provinceManager) => ({
  type: ActionTypes.PROVINCE_MANAGER_DELETE__SUCCESS,
  payload: {
    provinceManager,
  },
});

deleteProvinceManager.failure = (id, error) => ({
  type: ActionTypes.PROVINCE_MANAGER_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleProvinceManagerDelete = (provinceManager, isCurrentUser, isCurrentProvince) => ({
  type: ActionTypes.PROVINCE_MANAGER_DELETE_HANDLE,
  payload: {
    provinceManager,
    isCurrentUser,
    isCurrentProvince,
  },
});

export default {
  createProvinceManager,
  handleProvinceManagerCreate,
  deleteProvinceManager,
  handleProvinceManagerDelete,
};
