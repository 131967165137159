import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';
import { transformSchedule } from '../../../api/schedules';

export function* createContent(districtId, data) {
  const nextData = {
    ...data,
    position: yield select(selectors.selectNextContentPosition, districtId),
  };

  const localId = yield call(createLocalId);

  yield put(
    actions.createContent({
      ...nextData,
      districtId,
      id: localId,
    }),
  );

  let content;
  let contentCategories;
  let contentLocations;
  let contentSchedules;
  let attachment;

  try {
    ({
      item: content,
      included: { contentCategories, contentLocations, contentSchedules, attachment },
    } = yield call(request, api.createContent, districtId, nextData));
  } catch (error) {
    yield put(actions.createContent.failure(localId, error));
    return;
  }

  yield put(
    actions.createContent.success(
      localId,
      content,
      contentCategories,
      contentLocations,
      contentSchedules,
      attachment,
    ),
  );
}

export function* createContentInProvince(provinceId, data) {
  const nextData = {
    ...data,
    position: 0, // yield select(selectors.selectNextContentPosition, provinceId),
  };

  const localId = yield call(createLocalId);

  yield put(
    actions.createContent({
      ...nextData,
      provinceId,
      id: localId,
    }),
  );

  let content;
  let contentCategories;
  let contentLocations;
  let contentSchedules;
  let attachment;

  try {
    ({
      item: content,
      included: { contentCategories, contentLocations, contentSchedules, attachment },
    } = yield call(request, api.createContent, provinceId, nextData));
  } catch (error) {
    yield put(actions.createContent.failure(localId, error));
    return;
  }

  yield put(
    actions.createContent.success(
      localId,
      content,
      contentCategories,
      contentLocations,
      contentSchedules,
      attachment,
    ),
  );
}

export function* createContentInCurrentDistrict(data) {
  const { districtId } = yield select(selectors.selectPath);

  yield call(createContent, districtId, data);
}

export function* createContentInCurrentProvince(data) {
  const { provinceId } = yield select(selectors.selectPath);

  yield call(createContentInProvince, provinceId, data);
}

export function* handleContentCreate(content) {
  yield put(actions.handleContentCreate(content));
}

export function* fetchContents(page, limit, filter) {
  const { id: provinceId } = yield select(selectors.selectCurrentProvince);
  yield put(actions.fetchContents(provinceId));

  let contents;
  let total;
  let attachments;
  let contentCategories;
  let contentLocations;
  let contentSchedules;

  try {
    ({
      items: contents,
      included: { total, attachments, contentCategories, contentLocations, contentSchedules },
    } = yield call(request, api.getContents, provinceId, { page, limit, filter }));
  } catch (error) {
    yield put(actions.fetchContents.failure(provinceId, error));
    return;
  }

  yield put(
    actions.fetchContents.success(
      provinceId,
      contents,
      total,
      attachments,
      contentCategories,
      contentLocations,
      contentSchedules,
    ),
  );
}

export function* searchContents(filter) {
  const { id: provinceId } = yield select(selectors.selectCurrentProvince);
  yield put(actions.searchContents(provinceId));

  let contents;
  try {
    ({ items: contents } = yield call(request, api.getContents, provinceId, { filter }));
  } catch (error) {
    yield put(actions.searchContents.failure(provinceId, error));
    return;
  }

  yield put(actions.searchContents.success(provinceId, contents));
}

export function* selectContent(content) {
  yield put(actions.selectContent(content));
}

export function* updateContent(id, data) {
  yield put(actions.updateContent(id, data));

  let content;
  let contentLocations;
  let contentSchedules;
  let attachment;
  let contentCategories;

  try {
    ({
      item: content,
      included: { contentLocations, contentSchedules, attachment, contentCategories },
    } = yield call(request, api.updateContent, id, data));
  } catch (error) {
    yield put(actions.updateContent.failure(id, error));
    return;
  }

  yield put(
    actions.updateContent.success(
      content,
      contentLocations,
      contentSchedules,
      attachment,
      contentCategories,
    ),
  );
}

export function* handleContentUpdate(content, attached) {
  const { provinceId } = content;

  let schedules;
  let contentLocations;

  try {
    ({
      items: schedules,
      included: { contentLocations },
    } = yield call(request, api.getSchedules, provinceId, {
      filter: { contentId: content.id },
    }));
  } catch (error) {
    yield put(actions.fetchSchedules.failure(provinceId, error));
    return;
  }

  yield put(
    actions.handleContentUpdate(content, {
      schedules,
      contentLocations,
      ...attached?.included,
    }),
  );
}

export function* moveContent(id, index) {
  const { districtId } = yield select(selectors.selectContentById, id);
  const position = yield select(selectors.selectNextContentPosition, districtId, index, id);

  yield call(updateContent, id, {
    position,
  });
}

export function* deleteContent(id) {
  yield put(actions.deleteContent(id));

  let content;
  let schedules;

  try {
    ({
      item: content,
      included: { schedules },
    } = yield call(request, api.deleteContent, id));
  } catch (error) {
    yield put(actions.deleteContent.failure(id, error));
    return;
  }

  yield put(actions.deleteContent.success(content, schedules));
}

export function* handleContentDelete(content) {
  yield put(actions.handleContentDelete(content));
}

export function* text2Speech(data) {
  yield put(actions.text2Speech(data));

  let payload;
  try {
    ({ item: payload } = yield call(request, api.text2Speech, data));
  } catch (error) {
    yield put(actions.text2Speech.failure(error));
    return;
  }

  if (payload.error_code === 0) {
    yield put(actions.text2Speech.success(payload));
  } else {
    yield put(actions.text2Speech.failure(payload));
  }
}

export function* addContentToDevice(id, deviceId) {
  yield put(actions.addContentToDevice(id, deviceId));

  let deviceContent;
  try {
    ({ item: deviceContent } = yield call(request, api.createDeviceContent, deviceId, {
      contentId: id,
    }));
  } catch (error) {
    yield put(actions.addContentToDevice.failure(id, deviceId, error));
    return;
  }

  yield put(actions.addContentToDevice.success(deviceContent));
}

export function* addContentToCurrentDevice(id) {
  const { deviceId } = yield select(selectors.selectPath);

  yield call(addContentToDevice, id, deviceId);
}

export function* handleContentToDeviceAdd(deviceContent) {
  yield put(actions.handleContentToDeviceAdd(deviceContent));
}

export function* removeContentFromDevice(id, deviceId) {
  yield put(actions.removeContentFromDevice(id, deviceId));

  let deviceContent;
  try {
    ({ item: deviceContent } = yield call(request, api.deleteDeviceContent, deviceId, id));
  } catch (error) {
    yield put(actions.removeContentFromDevice.failure(id, deviceId, error));
    return;
  }

  yield put(actions.removeContentFromDevice.success(deviceContent));
}

export function* removeContentFromCurrentDevice(id) {
  const { deviceId } = yield select(selectors.selectPath);

  yield call(removeContentFromDevice, id, deviceId);
}

export function* handleContentFromDeviceRemove(deviceContent) {
  yield put(actions.handleContentFromDeviceRemove(deviceContent));
}

export function* addContentToDistrictFilter(id, districtId) {
  yield put(actions.addContentToDistrictFilter(id, districtId));
}

export function* addContentToFilterInCurrentDistrict(id) {
  const { districtId } = yield select(selectors.selectPath);

  yield call(addContentToDistrictFilter, id, districtId);
}

export function* removeContentFromDistrictFilter(id, districtId) {
  yield put(actions.removeContentFromDistrictFilter(id, districtId));
}

export function* removeContentFromFilterInCurrentDistrict(id) {
  const { districtId } = yield select(selectors.selectPath);

  yield call(removeContentFromDistrictFilter, id, districtId);
}

export function* clearContentError() {
  yield put(actions.clearContentError());
}

export function* clearContentPlaylist() {
  yield put(actions.clearContentPlaylist());
}

export function* clearContentConcatFile() {
  yield put(actions.clearContentConcatFile());
}

export function* cancelScheduleContent(id, LichPhatID) {
  yield put(actions.cancelScheduleContent(id));

  let content;
  try {
    ({ item: content } = yield call(request, api.cancelScheduleContent, id, { LichPhatID }));
  } catch (error) {
    yield put(actions.cancelScheduleContent.failure(id, error));
    return;
  }

  yield put(actions.cancelScheduleContent.success(content));
}

export function* handleContentCancelSchedule(content, contentSchedules) {
  yield put(actions.handleContentCancelSchedule(content, contentSchedules.map(transformSchedule)));
}

export function* fetchStationsBroadcastedContent(id, filter) {
  yield put(actions.fetchStationsBroadcastedContent(id, filter));

  let content;
  let scheduleLogs;
  let schedules;
  let stations;
  let total;
  let users;

  try {
    ({
      item: content,
      included: { scheduleLogs, schedules, stations, total, users },
    } = yield call(request, api.getContentStations, id, filter));
  } catch (error) {
    yield put(actions.fetchStationsBroadcastedContent.failure(id, error));
    return;
  }

  yield put(
    actions.fetchStationsBroadcastedContent.success(
      content,
      scheduleLogs,
      schedules,
      stations,
      total,
      users,
    ),
  );
}

export function* concatFile(data) {
  yield put(actions.concatFile(data));

  let attachment;
  let duration;

  try {
    ({
      item: attachment,
      included: { duration },
    } = yield call(request, api.concatFile, data));
  } catch (error) {
    yield put(actions.concatFile.failure(error));
    return;
  }

  yield put(actions.concatFile.success(attachment, duration));
}

export function* handleContentCategoryCreate(contentCategory) {
  yield put(actions.handleContentCategoryCreate(contentCategory));
}

export function* handleContentCategoryUpdate(contentCategory) {
  yield put(actions.handleContentCategoryUpdate(contentCategory));
}

export function* handleContentCategoryDelete(contentCategory) {
  yield put(actions.handleContentCategoryDelete(contentCategory));
}

export function* handleContentLocationCreate(contentLocation) {
  yield put(actions.handleContentLocationCreate(contentLocation));
}

export function* handleContentLocationDelete(contentLocation) {
  yield put(actions.handleContentLocationDelete(contentLocation));
}

export function* handleContentLocationUpdate(contentLocations) {
  yield put(actions.handleContentLocationUpdate(contentLocations));
}

export function* enalbleContentsFetching(contentLocations) {
  yield put(actions.enalbleContentsFetching(contentLocations));
}

export default {
  createContent,
  createContentInCurrentDistrict,
  createContentInCurrentProvince,
  handleContentCreate,
  fetchContents,
  searchContents,
  selectContent,
  updateContent,
  handleContentUpdate,
  moveContent,
  deleteContent,
  handleContentDelete,
  text2Speech,
  addContentToDevice,
  addContentToCurrentDevice,
  handleContentToDeviceAdd,
  removeContentFromDevice,
  removeContentFromCurrentDevice,
  handleContentFromDeviceRemove,
  addContentToDistrictFilter,
  addContentToFilterInCurrentDistrict,
  removeContentFromDistrictFilter,
  removeContentFromFilterInCurrentDistrict,
  clearContentError,
  clearContentPlaylist,
  clearContentConcatFile,
  cancelScheduleContent,
  handleContentCancelSchedule,
  fetchStationsBroadcastedContent,
  concatFile,
  handleContentCategoryCreate,
  handleContentCategoryUpdate,
  handleContentCategoryDelete,
  handleContentLocationCreate,
  handleContentLocationDelete,
  handleContentLocationUpdate,
  enalbleContentsFetching,
};
