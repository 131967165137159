import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Icon, Image, Label, Loader, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import durationToTime from '../../../utils/duration-to-time';
import { BroadcastingZone, NoticeMessages, RelaySourceOptions } from '../../../constants/Enums';
import { Markdown } from '../../../lib/custom-ui';
import { pickLocationSelected } from '../../../utils/content-method';
import topicControl from '../../../utils/control-device-topic';

import Notice from '../Notice';

import styles from './LiveStream.module.scss';

const getIcon = (type) => {
  switch (type) {
    case 'text':
      return 'file text';
    case 'file':
    case 'audio':
      return 'file audio';
    case 'video':
      return 'file video';
    case 'image':
      return 'file image';
    case 'stream':
      return 'podcast';
    default:
      return '';
  }
};

const SECONDS_SEND_DATA = 5;

const LiveStream = React.memo(
  ({
    broadcast,
    areasReceived,
    data,
    categories,
    districts,
    wards,
    hamlets,
    devices,
    onClose,
    onCreate,
    onLiveBroadcast,
    onLiveBroadcastStop,
    onControl,
  }) => {
    const [t] = useTranslation();

    const { path, contentId, isError, isFinished, status: statusBroadcast } = broadcast;

    const noticeRef = useRef();
    const mediaStream = useRef();
    const interval = useRef();
    const isRecording = useRef();

    const [status, setStatus] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const wardFilter = pickLocationSelected(areasReceived.wards, wards);

    const audioContent = useMemo(() => {
      if (data.myFile) {
        const fileExt = data.myFile.type;

        if (fileExt.includes('audio') || fileExt.includes('image') || fileExt.includes('video')) {
          return URL.createObjectURL(data.myFile);
        }
      }

      if (data.concatFile) {
        return data.concatFile.url;
      }

      if (data.attachment) {
        return data.attachment.url;
      }

      if (data.link) {
        return data.link;
      }

      return undefined;
    }, [data.myFile, data.concatFile, data.attachment, data.link]);

    const payloadControl = useMemo(() => {
      const payload = topicControl(areasReceived, {
        districts,
        wards,
        hamlets,
        devices,
      });

      const deviceIds = areasReceived.devices.map((d) => d.id);

      return { ...payload, deviceIds };
    }, [areasReceived, districts, wards, hamlets, devices]);

    const handleStopLiveBroadcast = useCallback(
      (liveStatus) => {
        isRecording.current = false;
        clearInterval(interval.current);

        if (mediaStream.current && mediaStream.current.state === 'recording') {
          mediaStream.current.stop();

          const tracks = mediaStream.current.stream.getTracks();

          tracks.forEach((track) => {
            track.stop();
          });
        }

        mediaStream.current = undefined;
        onLiveBroadcastStop({
          audio: [],
          active: false,
          liveStatus,
          contentId: liveStatus ? contentId : undefined,
        });
        setStatus(null);
        setIsLoading(false);
      },
      [contentId, onLiveBroadcastStop],
    );

    const handleLiveStreamByMicrophone = useCallback(
      async (bcStatus) => {
        if (bcStatus === 'play') {
          isRecording.current = true;
          setIsLoading(true);

          try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            // MEDIA RECORDER
            mediaStream.current = new MediaRecorder(stream);
            mediaStream.current.ondataavailable = (event) => {
              if (event.data.size > 0) {
                const arrayBuffer = event.data.arrayBuffer();

                arrayBuffer.then((buffer) => {
                  if (isRecording.current) {
                    onLiveBroadcast({ audio: buffer, active: true });
                    setIsLoading(false);
                  }
                });
              }
            };

            mediaStream.current.start();
            interval.current = setInterval(() => {
              if (mediaStream.current && mediaStream.current.state === 'recording') {
                mediaStream.current.requestData();
              }
            }, SECONDS_SEND_DATA * 1000);
          } catch (error) {
            isRecording.current = false;
            clearInterval(interval.current);
            mediaStream.current = undefined;
          }
        } else {
          const DieuKhienThietBi = {
            MaLenh: '2',
            ThamSo: '1',
          };

          handleStopLiveBroadcast('stop');
          if (payloadControl.deviceIds.length > 0) {
            onControl({ ...payloadControl, DieuKhienThietBi });
          }
        }
      },
      [payloadControl, handleStopLiveBroadcast, onLiveBroadcast, onControl],
    );

    const handleLiveStreamContentFile = useCallback(() => {
      onCreate();
    }, [onCreate]);

    const handleTogglePlayStop = useCallback(() => {
      const updateStatus = status === (null || 'play') ? 'stop' : 'play';

      if (data.NguonPhat === 'normal') {
        handleLiveStreamContentFile(updateStatus);
      } else {
        handleLiveStreamByMicrophone(updateStatus);
      }

      setStatus(updateStatus);
    }, [status, data.NguonPhat, handleLiveStreamByMicrophone, handleLiveStreamContentFile]);

    const handleClose = useCallback(() => {
      if (status === 'play') {
        noticeRef.current.handleAlertTrigger(NoticeMessages.cannotClose);

        return;
      }

      onClose();
    }, [status, onClose]);

    const streamElement = useCallback((file) => {
      if (file) {
        const ext = file.type;
        const url = URL.createObjectURL(file);

        if (ext.includes('audio')) {
          return (
            <audio controls>
              <source src={url} />
              <track kind="captions" />
            </audio>
          );
        }

        if (ext.includes('video')) {
          return <iframe src={url} width="100%" height="300" title="Video" />;
        }

        if (ext.includes('image')) {
          return <Image src={url} height={300} alt="Content Image" />;
        }
      }

      return undefined;
    }, []);

    const broadcastingZone =
      data.PhanLoai === 'bulletinBoard' &&
      BroadcastingZone.find(({ value }) => value === data.VungPhat);

    const relay = RelaySourceOptions.find((p) => p.value === data.TiepAm);

    /* eslint-disable no-nested-ternary */
    const mediaContentNode =
      data.type === 'video' ? (
        <iframe src={audioContent} width="100%" height="300" title={audioContent} />
      ) : data.type === 'image' ? (
        <Image src={audioContent} height={300} alt="Content Image" />
      ) : data.type === 'stream' && data.PhanLoai === 'bulletinBoard' ? (
        streamElement(data.myFile)
      ) : (
        <audio controls>
          <source src={audioContent} />
          <track kind="captions" />
        </audio>
      );

    const detailContentNode = data.NoiDung ? (
      <Markdown linkTarget="_blank">{data.NoiDung}</Markdown>
    ) : (
      mediaContentNode
    );

    const audioContentNode = detailContentNode || <div>{t('common.noAudioContent')}</div>;

    useEffect(() => {
      if (isError || isFinished) {
        handleStopLiveBroadcast();
      }
    }, [isError, isFinished, handleStopLiveBroadcast]);

    useEffect(() => {
      if (path) {
        onCreate(path);
      }
    }, [path, onCreate]);

    return (
      <Modal open closeIcon centered={false} onClose={handleClose} className={styles.wrapper}>
        <Header color="red" className={styles.header}>
          {t(
            `common.${
              data.PhanLoai === 'broadcasting' ? 'emergencyBroadcasting' : 'emergencyBulletinBoard'
            }`,
          )}
        </Header>
        <Modal.Content>
          <Notice ref={noticeRef} />
          <div className={styles.field}>
            <span className={styles.fieldName}>{t('common.contentName')}</span>
            <span>{data.name}</span>
          </div>
          <div className={styles.field}>
            <span className={styles.fieldName}>{t('common.contentType')}</span>
            <span>
              <Icon color="teal" size="large" name={getIcon(data.type)} />{' '}
              {t(`common.${data.type}`)}
            </span>
          </div>
          {broadcastingZone && (
            <div className={styles.field}>
              <span className={styles.fieldName}>{t('common.broadcastingZone')}</span>
              <span>{t(`common.${broadcastingZone.key}`)}</span>
            </div>
          )}
          {data.NguonPhat === 'microphone' ? (
            <div className={styles.field}>
              <span className={styles.fieldName}>{t('common.status')}</span>
              <div>
                {statusBroadcast && <Label color="blue" content={t(`common.${statusBroadcast}`)} />}
                {isLoading && !statusBroadcast && <Label color="blue" content="Đang chờ..." />}
              </div>
            </div>
          ) : (
            !!status && (
              <div className={styles.field}>
                <span className={styles.fieldName}>{t('common.status')}</span>
                <div>
                  <Label
                    color={status === 'play' ? 'blue' : 'red'}
                    content={t(`common.${status === 'play' ? 'playing' : 'played'}`)}
                  />
                </div>
              </div>
            )
          )}
          <div className={styles.field}>
            <span className={styles.fieldName}>{t('common.duration')}</span>
            <span>{durationToTime(data.duration)}</span>
          </div>
          <div className={styles.field}>
            <span className={styles.fieldName}>{t('common.relay')}</span>
            <span>{t(`common.${relay.key}`)}</span>
          </div>
          <div className={styles.field}>
            <span className={styles.fieldName}>{t('common.relaySource')}</span>
            <span>{data.NguonTiepAm}</span>
          </div>
          <div className={styles.field}>
            <span className={styles.fieldName}>{t('common.content')}</span>
            {data.NguonPhat === 'microphone' ? (
              <div>{t('common.microphone')}</div>
            ) : (
              audioContentNode
            )}
          </div>
          <div className={styles.field}>
            <span className={styles.fieldName}>{t('common.category')}</span>
            <div className={styles.listCategories}>
              {categories.map((cate) =>
                data.categories.includes(cate.id) ? (
                  <span
                    key={cate.id}
                    style={{ backgroundColor: cate.color }}
                    className={styles.category}
                  >
                    {cate.name}
                  </span>
                ) : (
                  ''
                ),
              )}
            </div>
          </div>
          <div className={styles.field}>
            <span className={styles.fieldName}>{t('common.broadcastingArea')}</span>
            <div className={styles.wrapperWard}>
              {wardFilter.map((w) => {
                const deviceItems = areasReceived.devices.filter((d) => d.wardId === w.id);

                const deviceStringNames = deviceItems.map((d) => d.name).join(', ');

                return (
                  <Label
                    key={w.id}
                    content={`${w.name}: ${deviceStringNames}`}
                    color="teal"
                    className={styles.labelWard}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.field}>
            <span className={styles.fieldName}>{t('common.contentDetails')}</span>
            <div className={styles.wrapperWard}>
              {React.Children.toArray(
                data.ThongTinChiTietBanTin.map((it) => (
                  <Label
                    style={{ marginBottom: 4 }}
                    color="teal"
                    content={`${it.Ten} - ${it.GiaTri}`}
                  />
                )),
              )}
            </div>
          </div>
          <div className={styles.field}>
            <span className={styles.fieldName}>{t('common.author')}</span>
            <div>
              <Label size="large">
                <span className={styles.marginRightTen}>{t('common.fullname')}:</span>
                {data.TacGia.TenDayDu}
              </Label>
              <Label size="large">
                <span className={styles.marginRightTen}>{t('common.pseudonym')}:</span>
                {data.TacGia.ButDanh}
              </Label>
              <Label size="large">
                <span className={styles.marginRightTen}>Email:</span>
                {data.TacGia.Email}
              </Label>
            </div>
          </div>
          <Button
            loading={isLoading}
            disabled={statusBroadcast === 'played'}
            labelPosition="right"
            icon={status === 'play' ? 'stop' : 'play'}
            content={t(`action.${status === 'play' ? 'stop' : 'saveAndPlay'}`)}
            onClick={handleTogglePlayStop}
          />
        </Modal.Content>
      </Modal>
    );
  },
);

export default LiveStream;

LiveStream.propTypes = {
  broadcast: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  areasReceived: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  districts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  hamlets: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  wards: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  devices: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  categories: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onLiveBroadcast: PropTypes.func.isRequired,
  onLiveBroadcastStop: PropTypes.func.isRequired,
  onControl: PropTypes.func.isRequired,
};
