import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import { Button, Dropdown, Icon, Pagination, Table } from 'semantic-ui-react';

import { useToggle } from '../../lib/hooks';
import Config from '../../constants/Config';
import { pickDayOfWeek } from '../../utils/custom-repeat';

import UserLogModalContainer from '../../containers/UserLogModalContainer';
import PaginationCustom from '../Pagination';

import styles from './UserLog.module.scss';

const LIMIT = Config.LIMIT_PER_PAGE;

const UserLog = React.memo(({ items, users, total, onFetch }) => {
  const [t] = useTranslation();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());

  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const [isOpenModal, handleToggleModal] = useToggle();

  const [selectedActionId, setSelectedActionId] = useState();

  const [selectPage, setSelectPage] = useState(1);

  const handleFilter = useCallback(() => {
    onFetch({ userId: selectedUserIds, from: startDate, to: endDate }, 20, 1);
  }, [selectedUserIds, startDate, endDate, onFetch]);

  const handleSelectUser = useCallback((_, { value, name }) => {
    setSelectedUserIds(value);
  }, []);

  const handleSelectActionId = useCallback(
    (id) => {
      setSelectedActionId(id);
      handleToggleModal();
    },
    [handleToggleModal],
  );

  const handlePagination = useCallback(
    (_, { activePage }) => {
      const filter = {
        userId: selectedUserIds,
        from: startDate || undefined,
        to: endDate || undefined,
      };

      setSelectPage(activePage);
      onFetch(filter, 20, activePage);
    },
    [selectedUserIds, startDate, endDate, onFetch],
  );

  const UserOptions = users.map((user) => ({ key: user.id, value: user.id, text: user.name }));

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperFilter}>
        <div>
          <Icon name="filter" size="large" />
          <Dropdown
            clearable
            style={{ marginRight: 5 }}
            selection
            multiple
            // closeOnChange
            onChange={handleSelectUser}
            value={selectedUserIds}
            name="userId"
            options={UserOptions}
            placeholder={t('common.selectUser')}
          />
        </div>
        <div>
          <span className={styles.label}>{t('common.fromDate')}</span>
          <ReactDatePicker
            wrapperClassName={styles.wrapperDatePicker}
            calendarClassName={styles.calendar}
            dateFormat="d/M/yyyy"
            startDate={startDate}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            maxDate={new Date()}
            disabledKeyboardNavigation
          />
        </div>
        <div>
          <span className={styles.label}>{t('common.toDate')}</span>
          <ReactDatePicker
            wrapperClassName={styles.wrapperDatePicker}
            calendarClassName={styles.calendar}
            dateFormat="d/M/yyyy"
            endDate={endDate}
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            maxDate={new Date()}
            disabledKeyboardNavigation
          />
        </div>

        <Button
          content={t('action.filter')}
          className={styles.buttonFilter}
          onClick={handleFilter}
        />
      </div>
      <Table className={styles.table}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('common.activity')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common.implementationAccount')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common.time')}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((it) => (
            <Table.Row key={it.id}>
              <Table.Cell>{t(`common.${it.type}`)}</Table.Cell>
              <Table.Cell>{it.user}</Table.Cell>
              <Table.Cell>{pickDayOfWeek(it.createdAt, 'pp, dd/MM/yyy')}</Table.Cell>
              <Table.Cell textAlign="right">
                <Button
                  size="mini"
                  content={t('action.view')}
                  onClick={() => handleSelectActionId(it.id)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row textAlign="right">
            <Table.HeaderCell colSpan="4">
              <PaginationCustom
                onPageChange={handlePagination}
                activePage={selectPage}
                totalPages={Math.ceil(total / LIMIT)}
                size="mini"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      {isOpenModal && (
        <UserLogModalContainer selectedId={selectedActionId} onClose={handleToggleModal} />
      )}
    </div>
  );
});

UserLog.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  items: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  onFetch: PropTypes.func.isRequired,
};

export default UserLog;
