import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Icon, Modal, Label } from 'semantic-ui-react';
import { usePopup } from '../../lib/popup';
import { Markdown } from '../../lib/custom-ui';
import { useToggle } from '../../lib/hooks';
import { useModal } from '../../hooks';

import { startStopwatch, stopStopwatch } from '../../utils/stopwatch';
import NameField from './NameField';
import DescriptionEdit from './DescriptionEdit';
import Tasks from './Tasks';
import Attachments from './Attachments';
import AttachmentAddZone from './AttachmentAddZone';
import AttachmentAddStep from './AttachmentAddStep';
import Activities from './Activities';
import User from '../User';
import Content from '../Content';
import DueDate from '../DueDate';
import Stopwatch from '../Stopwatch';
import DistrictMembershipsStep from '../DistrictMembershipsStep';
import ContentsStep from '../ContentsStep';
import DueDateEditStep from '../DueDateEditStep';
import StopwatchEditStep from '../StopwatchEditStep';
import DeviceMoveStep from '../DeviceMoveStep';
import DeleteStep from '../DeleteStep';
import PickLocation from '../StationModal/PickLocation';
import IdentifierSIMEditStep from '../IdentifierSIMEditStep';
import IdentifierSIM from '../IdentifierSIM';

import styles from './DeviceModal.module.scss';

const DeviceModal = React.memo(
  ({
    name,
    description,
    dueDate,
    stopwatch,
    isSubscribed,
    isActivitiesFetching,
    isAllActivitiesFetched,
    isActivitiesDetailsVisible,
    isActivitiesDetailsFetching,
    isDarkMode,
    stationId,
    districtId,
    provinceId,
    location,
    simNumber,
    code,
    createdAt,
    users,
    contents,
    tasks,
    attachments,
    activities,
    allProvincesToStations,
    allDistrictMemberships,
    allContents,
    canEdit,
    canEditCommentActivities,
    canEditAllCommentActivities,
    onUpdate,
    onMove,
    onTransfer,
    onDelete,
    onUserAdd,
    onUserRemove,
    onDistrictFetch,
    onContentAdd,
    onContentRemove,
    onContentCreate,
    onContentUpdate,
    onContentMove,
    onContentDelete,
    onTaskCreate,
    onTaskUpdate,
    onTaskMove,
    onTaskDelete,
    onAttachmentCreate,
    onAttachmentUpdate,
    onAttachmentDelete,
    onActivitiesFetch,
    onActivitiesDetailsToggle,
    onCommentActivityCreate,
    onCommentActivityUpdate,
    onCommentActivityDelete,
    onClose,
  }) => {
    const [t] = useTranslation();

    const isGalleryOpened = useRef(false);
    const [openSidebarMobile, handleOpenSidebarMobile] = useToggle();

    const [openPickLocation, onOpenPickLocation, handleClosePickLocation] = useModal();

    const handleToggleStopwatchClick = useCallback(() => {
      onUpdate({
        stopwatch: stopwatch.startedAt ? stopStopwatch(stopwatch) : startStopwatch(stopwatch),
      });
    }, [stopwatch, onUpdate]);

    const handleNameUpdate = useCallback(
      (newName) => {
        onUpdate({
          name: newName,
        });
      },
      [onUpdate],
    );

    const handleDescriptionUpdate = useCallback(
      (newDescription) => {
        onUpdate({
          description: newDescription,
        });
      },
      [onUpdate],
    );

    const handleDueDateUpdate = useCallback(
      (newDueDate) => {
        onUpdate({
          dueDate: newDueDate,
        });
      },
      [onUpdate],
    );

    const handleStopwatchUpdate = useCallback(
      (newStopwatch) => {
        onUpdate({
          stopwatch: newStopwatch,
        });
      },
      [onUpdate],
    );

    const handleCoverUpdate = useCallback(
      (newCoverAttachmentId) => {
        onUpdate({
          coverAttachmentId: newCoverAttachmentId,
        });
      },
      [onUpdate],
    );

    const handleLocationUpdate = useCallback(
      (coordinate) => {
        onUpdate(coordinate);
      },
      [onUpdate],
    );

    const handleIdentifierSIMUpdate = useCallback(
      (value) => {
        onUpdate(value);
      },
      [onUpdate],
    );

    const handleToggleSubscriptionClick = useCallback(() => {
      onUpdate({
        isSubscribed: !isSubscribed,
      });
    }, [isSubscribed, onUpdate]);

    const handleGalleryOpen = useCallback(() => {
      isGalleryOpened.current = true;
    }, []);

    const handleGalleryClose = useCallback(() => {
      isGalleryOpened.current = false;
    }, []);

    const handleClose = useCallback(() => {
      if (isGalleryOpened.current) {
        return;
      }

      onClose();
    }, [onClose]);

    const AttachmentAddPopup = usePopup(AttachmentAddStep);
    const DistrictMembershipsPopup = usePopup(DistrictMembershipsStep);
    const ContentsPopup = usePopup(ContentsStep);
    const DueDateEditPopup = usePopup(DueDateEditStep);
    const StopwatchEditPopup = usePopup(StopwatchEditStep);
    const DeviceMovePopup = usePopup(DeviceMoveStep);
    const DeletePopup = usePopup(DeleteStep);
    const IdentifierSIMPopup = usePopup(IdentifierSIMEditStep);

    const userIds = users.map((user) => user.id);
    const contentIds = contents.map((content) => content.id);

    const contentNode = (
      <Grid className={styles.grid}>
        <Grid.Row className={styles.headerPadding}>
          <Grid.Column width={16} className={styles.headerPadding}>
            <div className={styles.headerWrapper}>
              <Icon name="list alternate outline" className={styles.moduleIcon} />
              <div className={styles.headerTitleWrapper}>
                {canEdit ? (
                  <NameField defaultValue={name} onUpdate={handleNameUpdate} />
                ) : (
                  <div className={styles.headerTitle}>{name}</div>
                )}
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={styles.modalPadding}>
          <Grid.Column
            mobile={16}
            tablet={canEdit ? 12 : 16}
            computer={canEdit ? 12 : 16}
            largeScreen={canEdit ? 12 : 16}
            widescreen={canEdit ? 12 : 16}
            className={styles.contentPadding}
          >
            {createdAt && (
              <div className={styles.contentModule}>
                <div className={styles.moduleWrapper}>
                  <div className={styles.moduleHeader}>
                    <span>{t('common.dateActive')}</span>
                    <Label
                      color="blue"
                      content={new Date(createdAt).toLocaleString('vi-VN', {
                        hourCycle: 'h23',
                        timeStyle: 'medium',
                        dateStyle: 'full',
                      })}
                    />
                  </div>
                </div>
              </div>
            )}
            {(users.length > 0 ||
              contents.length > 0 ||
              dueDate ||
              stopwatch ||
              code ||
              simNumber) && (
              <div className={styles.moduleWrapper}>
                {users.length > 0 && (
                  <div className={styles.attachments}>
                    <div className={styles.text}>
                      {t('common.members', {
                        context: 'title',
                      })}
                    </div>
                    {users.map((user) => (
                      <span key={user.id} className={styles.attachment}>
                        {canEdit ? (
                          <DistrictMembershipsPopup
                            items={allDistrictMemberships}
                            currentUserIds={userIds}
                            onUserSelect={onUserAdd}
                            onUserDeselect={onUserRemove}
                          >
                            <User name={user.name} avatarUrl={user.avatarUrl} />
                          </DistrictMembershipsPopup>
                        ) : (
                          <User name={user.name} avatarUrl={user.avatarUrl} />
                        )}
                      </span>
                    ))}
                    {canEdit && (
                      <DistrictMembershipsPopup
                        items={allDistrictMemberships}
                        currentUserIds={userIds}
                        onUserSelect={onUserAdd}
                        onUserDeselect={onUserRemove}
                      >
                        <button
                          type="button"
                          className={classNames(styles.attachment, styles.dueDate)}
                        >
                          <Icon name="add" size="small" className={styles.addAttachment} />
                        </button>
                      </DistrictMembershipsPopup>
                    )}
                  </div>
                )}
                {contents.length > 0 && (
                  <div className={styles.attachments}>
                    <div className={styles.text}>
                      {t('common.contents', {
                        context: 'title',
                      })}
                    </div>
                    {contents.map((content) => (
                      <span key={content.id} className={styles.attachment}>
                        {canEdit ? (
                          <ContentsPopup
                            key={content.id}
                            items={allContents}
                            currentIds={contentIds}
                            onSelect={onContentAdd}
                            onDeselect={onContentRemove}
                            onCreate={onContentCreate}
                            onUpdate={onContentUpdate}
                            onMove={onContentMove}
                            onDelete={onContentDelete}
                          >
                            <Content name={content.name} color={content.color} />
                          </ContentsPopup>
                        ) : (
                          <Content name={content.name} color={content.color} />
                        )}
                      </span>
                    ))}
                    {canEdit && (
                      <ContentsPopup
                        items={allContents}
                        currentIds={contentIds}
                        onSelect={onContentAdd}
                        onDeselect={onContentRemove}
                        onCreate={onContentCreate}
                        onUpdate={onContentUpdate}
                        onMove={onContentMove}
                        onDelete={onContentDelete}
                      >
                        <button
                          type="button"
                          className={classNames(styles.attachment, styles.dueDate)}
                        >
                          <Icon name="add" size="small" className={styles.addAttachment} />
                        </button>
                      </ContentsPopup>
                    )}
                  </div>
                )}
                {dueDate && (
                  <div className={styles.attachments}>
                    <div className={styles.text}>
                      {t('common.dueDate', {
                        context: 'title',
                      })}
                    </div>
                    <span className={styles.attachment}>
                      {canEdit ? (
                        <DueDateEditPopup defaultValue={dueDate} onUpdate={handleDueDateUpdate}>
                          <DueDate value={dueDate} />
                        </DueDateEditPopup>
                      ) : (
                        <DueDate value={dueDate} />
                      )}
                    </span>
                  </div>
                )}
                {stopwatch && (
                  <div className={styles.attachments}>
                    <div className={styles.text}>
                      {t('common.stopwatch', {
                        context: 'title',
                      })}
                    </div>
                    <span className={styles.attachment}>
                      {canEdit ? (
                        <StopwatchEditPopup
                          defaultValue={stopwatch}
                          onUpdate={handleStopwatchUpdate}
                        >
                          <Stopwatch startedAt={stopwatch.startedAt} total={stopwatch.total} />
                        </StopwatchEditPopup>
                      ) : (
                        <Stopwatch startedAt={stopwatch.startedAt} total={stopwatch.total} />
                      )}
                    </span>
                    {canEdit && (
                      <button
                        onClick={handleToggleStopwatchClick}
                        type="button"
                        className={classNames(styles.attachment, styles.dueDate)}
                      >
                        <Icon
                          name={stopwatch.startedAt ? 'pause' : 'play'}
                          size="small"
                          className={styles.addAttachment}
                        />
                      </button>
                    )}
                  </div>
                )}
                {(code || simNumber) && (
                  <div className={styles.attachments}>
                    <div className={styles.text}>
                      {t('common.identifierSIM', { context: 'title' })}
                    </div>
                    <span className={styles.attachment}>
                      {canEdit ? (
                        <IdentifierSIMPopup
                          defaultValue={{ code, simNumber }}
                          onUpdate={handleIdentifierSIMUpdate}
                        >
                          <IdentifierSIM value={{ code, simNumber }} />
                        </IdentifierSIMPopup>
                      ) : (
                        <IdentifierSIM value={{ code, simNumber }} />
                      )}
                    </span>
                  </div>
                )}
              </div>
            )}
            {location.lat && location.lng && (
              <div className={styles.contentModule}>
                <div className={styles.moduleWrapper}>
                  <Icon name="map marker alternate" className={styles.moduleIcon} />
                  <div className={styles.moduleHeader}>{t('common.location')}</div>
                  {canEdit ? (
                    <button
                      type="button"
                      onClick={onOpenPickLocation}
                      className={classNames(styles.locationButton, styles.cursorPointer)}
                    >
                      <div>
                        <Label
                          className={styles.locationMobile}
                          color="teal"
                          content={`${t('common.latitude')} : ${location.lat}`}
                        />
                        <Label
                          className={styles.locationMobile}
                          color="teal"
                          content={`${t('common.longitude')} : ${location.lng}`}
                        />
                      </div>
                    </button>
                  ) : (
                    <>
                      <Label
                        className={styles.locationMobile}
                        size="large"
                        color="teal"
                        content={`${t('common.latitude')} : ${location.lat}`}
                      />
                      <Label
                        className={styles.locationMobile}
                        size="large"
                        color="teal"
                        content={`${t('common.longitude')} : ${location.lng}`}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            {(description || canEdit) && (
              <div className={styles.contentModule}>
                <div className={styles.moduleWrapper}>
                  <Icon name="align justify" className={styles.moduleIcon} />
                  <div className={styles.moduleHeader}>{t('common.description')}</div>
                  {canEdit ? (
                    <DescriptionEdit defaultValue={description} onUpdate={handleDescriptionUpdate}>
                      {description ? (
                        <button
                          type="button"
                          className={classNames(styles.descriptionText, styles.cursorPointer)}
                        >
                          <Markdown linkStopPropagation linkTarget="_blank">
                            {description}
                          </Markdown>
                        </button>
                      ) : (
                        <button type="button" className={styles.descriptionButton}>
                          <span className={styles.descriptionButtonText}>
                            {t('action.addMoreDetailedDescription')}
                          </span>
                        </button>
                      )}
                    </DescriptionEdit>
                  ) : (
                    <div className={styles.descriptionText}>
                      <Markdown linkStopPropagation linkTarget="_blank">
                        {description}
                      </Markdown>
                    </div>
                  )}
                </div>
              </div>
            )}
            {(tasks.length > 0 || canEdit) && (
              <div className={styles.contentModule}>
                <div className={styles.moduleWrapper}>
                  <Icon name="check square outline" className={styles.moduleIcon} />
                  <div className={styles.moduleHeader}>{t('common.tasks')}</div>
                  <Tasks
                    items={tasks}
                    canEdit={canEdit}
                    onCreate={onTaskCreate}
                    onUpdate={onTaskUpdate}
                    onMove={onTaskMove}
                    onDelete={onTaskDelete}
                  />
                </div>
              </div>
            )}
            {attachments.length > 0 && (
              <div className={styles.contentModule}>
                <div className={styles.moduleWrapper}>
                  <Icon name="attach" className={styles.moduleIcon} />
                  <div className={styles.moduleHeader}>{t('common.attachments')}</div>
                  <Attachments
                    items={attachments}
                    isDarkMode={isDarkMode}
                    canEdit={canEdit}
                    onUpdate={onAttachmentUpdate}
                    onDelete={onAttachmentDelete}
                    onCoverUpdate={handleCoverUpdate}
                    onGalleryOpen={handleGalleryOpen}
                    onGalleryClose={handleGalleryClose}
                  />
                </div>
              </div>
            )}
            <Activities
              items={activities}
              isFetching={isActivitiesFetching}
              isAllFetched={isAllActivitiesFetched}
              isDetailsVisible={isActivitiesDetailsVisible}
              isDetailsFetching={isActivitiesDetailsFetching}
              canEdit={canEditCommentActivities}
              canEditAllComments={canEditAllCommentActivities}
              isDarkMode={isDarkMode}
              onFetch={onActivitiesFetch}
              onDetailsToggle={onActivitiesDetailsToggle}
              onCommentCreate={onCommentActivityCreate}
              onCommentUpdate={onCommentActivityUpdate}
              onCommentDelete={onCommentActivityDelete}
            />
          </Grid.Column>
          {canEdit && (
            <>
              <Button
                icon={openSidebarMobile ? 'close' : 'ellipsis horizontal'}
                className={styles.sidebarButtonMobile}
                onClick={handleOpenSidebarMobile}
              />
              <Grid.Column
                mobile={8}
                tablet={4}
                computer={4}
                largeScreen={4}
                widescreen={4}
                className={classNames(
                  styles.sidebarPadding,
                  styles.sidebarHidden,
                  openSidebarMobile && styles.sidebarMobile,
                )}
              >
                <div className={styles.actions}>
                  <span className={styles.actionsTitle}>{t('action.addToDevice')}</span>
                  <DistrictMembershipsPopup
                    items={allDistrictMemberships}
                    currentUserIds={userIds}
                    onUserSelect={onUserAdd}
                    onUserDeselect={onUserRemove}
                  >
                    <Button fluid className={styles.actionButton}>
                      <Icon name="user outline" className={styles.actionIcon} />
                      {t('common.members')}
                    </Button>
                  </DistrictMembershipsPopup>
                  <ContentsPopup
                    items={allContents}
                    currentIds={contentIds}
                    onSelect={onContentAdd}
                    onDeselect={onContentRemove}
                    onCreate={onContentCreate}
                    onUpdate={onContentUpdate}
                    onMove={onContentMove}
                    onDelete={onContentDelete}
                  >
                    <Button fluid className={styles.actionButton}>
                      <Icon name="bookmark outline" className={styles.actionIcon} />
                      {t('common.contents')}
                    </Button>
                  </ContentsPopup>
                  <DueDateEditPopup defaultValue={dueDate} onUpdate={handleDueDateUpdate}>
                    <Button fluid className={styles.actionButton}>
                      <Icon name="calendar check outline" className={styles.actionIcon} />
                      {t('common.dueDate', {
                        context: 'title',
                      })}
                    </Button>
                  </DueDateEditPopup>
                  <StopwatchEditPopup defaultValue={stopwatch} onUpdate={handleStopwatchUpdate}>
                    <Button fluid className={styles.actionButton}>
                      <Icon name="clock outline" className={styles.actionIcon} />
                      {t('common.stopwatch')}
                    </Button>
                  </StopwatchEditPopup>
                  <AttachmentAddPopup onCreate={onAttachmentCreate}>
                    <Button fluid className={styles.actionButton}>
                      <Icon name="attach" className={styles.actionIcon} />
                      {t('common.attachment')}
                    </Button>
                  </AttachmentAddPopup>
                  <Button fluid className={styles.actionButton} onClick={onOpenPickLocation}>
                    <Icon name="map marker alternate" className={styles.actionIcon} />
                    {t('common.selectLocation')}
                  </Button>
                  <IdentifierSIMPopup
                    defaultValue={{ simNumber, code }}
                    onUpdate={handleIdentifierSIMUpdate}
                  >
                    <Button fluid className={styles.actionButton}>
                      <Icon name="barcode" className={styles.actionIcon} />
                      {t('common.identifierSIM', { context: 'title' })}
                    </Button>
                  </IdentifierSIMPopup>
                </div>
                <div className={styles.actions}>
                  <span className={styles.actionsTitle}>{t('common.actions')}</span>
                  <Button
                    fluid
                    className={styles.actionButton}
                    onClick={handleToggleSubscriptionClick}
                  >
                    <Icon name="paper plane outline" className={styles.actionIcon} />
                    {isSubscribed ? t('action.unsubscribe') : t('action.subscribe')}
                  </Button>
                  <DeviceMovePopup
                    provincesToStations={allProvincesToStations}
                    defaultPath={{
                      provinceId,
                      districtId,
                      stationId,
                    }}
                    onMove={onMove}
                    onTransfer={onTransfer}
                    onDistrictFetch={onDistrictFetch}
                  >
                    <Button
                      fluid
                      className={styles.actionButton}
                      onClick={handleToggleSubscriptionClick}
                    >
                      <Icon name="share square outline" className={styles.actionIcon} />
                      {t('action.move')}
                    </Button>
                  </DeviceMovePopup>
                  <DeletePopup
                    title="common.deleteDevice"
                    content="common.areYouSureYouWantToDeleteThisDevice"
                    buttonContent="action.deleteDevice"
                    onConfirm={onDelete}
                  >
                    <Button fluid className={styles.actionButton}>
                      <Icon name="trash alternate outline" className={styles.actionIcon} />
                      {t('action.delete')}
                    </Button>
                  </DeletePopup>
                </div>
              </Grid.Column>
            </>
          )}
        </Grid.Row>
      </Grid>
    );

    return (
      <>
        <Modal open closeIcon centered={false} onClose={handleClose} className={styles.wrapper}>
          {canEdit ? (
            <AttachmentAddZone isDarkMode={isDarkMode} onCreate={onAttachmentCreate}>
              {contentNode}
            </AttachmentAddZone>
          ) : (
            contentNode
          )}
        </Modal>
        {openPickLocation && (
          <PickLocation
            currentLocation={location}
            title="common.selectDeviceLocation"
            onBack={handleClosePickLocation}
            showAction
            onClick={handleLocationUpdate}
          />
        )}
      </>
    );
  },
);

DeviceModal.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  dueDate: PropTypes.instanceOf(Date),
  stopwatch: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isSubscribed: PropTypes.bool.isRequired,
  isActivitiesFetching: PropTypes.bool.isRequired,
  isAllActivitiesFetched: PropTypes.bool.isRequired,
  isActivitiesDetailsVisible: PropTypes.bool.isRequired,
  isActivitiesDetailsFetching: PropTypes.bool.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  stationId: PropTypes.string.isRequired,
  districtId: PropTypes.string.isRequired,
  provinceId: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  simNumber: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  users: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  contents: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  attachments: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  allProvincesToStations: PropTypes.array.isRequired,
  allDistrictMemberships: PropTypes.array.isRequired,
  allContents: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  canEdit: PropTypes.bool.isRequired,
  canEditCommentActivities: PropTypes.bool.isRequired,
  canEditAllCommentActivities: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onTransfer: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUserAdd: PropTypes.func.isRequired,
  onUserRemove: PropTypes.func.isRequired,
  onDistrictFetch: PropTypes.func.isRequired,
  onContentAdd: PropTypes.func.isRequired,
  onContentRemove: PropTypes.func.isRequired,
  onContentCreate: PropTypes.func.isRequired,
  onContentUpdate: PropTypes.func.isRequired,
  onContentMove: PropTypes.func.isRequired,
  onContentDelete: PropTypes.func.isRequired,
  onTaskCreate: PropTypes.func.isRequired,
  onTaskUpdate: PropTypes.func.isRequired,
  onTaskMove: PropTypes.func.isRequired,
  onTaskDelete: PropTypes.func.isRequired,
  onAttachmentCreate: PropTypes.func.isRequired,
  onAttachmentUpdate: PropTypes.func.isRequired,
  onAttachmentDelete: PropTypes.func.isRequired,
  onActivitiesFetch: PropTypes.func.isRequired,
  onActivitiesDetailsToggle: PropTypes.func.isRequired,
  onCommentActivityCreate: PropTypes.func.isRequired,
  onCommentActivityUpdate: PropTypes.func.isRequired,
  onCommentActivityDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

DeviceModal.defaultProps = {
  description: undefined,
  dueDate: undefined,
  stopwatch: undefined,
  simNumber: undefined,
};

export default DeviceModal;
