const CommuneTrainingOfficers = (data, wards) => {
  const dataReport = wards.map((ward) => {
    const workerTotal =
      ward.NhanLuc.CongChucVanHoaXaHoi +
      ward.NhanLuc.CongChucKhac +
      ward.NhanLuc.NguoiHoatDongKhongChuyenTrach +
      ward.NhanLuc.TuyenTruyenVienCoSo;

    return {
      code: ward.code,
      name: ward.name,
      workerTotal,
    };
  });

  const accumulator = dataReport.reduce((acc, item) => acc + item.workerTotal, 0);
  let html = '';
  html = ` <div class="header">
  <h3>Bảng 5</h3>
  <p>SỐ LIỆU TẬP HUẤN, BỒI DƯỠNG CÁN BỘ CẤP XÃ</p>
  <p>Kỳ báo cáo: Quý ${data.quater} năm ${data.year.getFullYear()}.</p>
</div>
<table>
  <thead>
    <tr> 
      <th><strong>STT</strong></th>
      <th style="width: 25%">
      <strong>Mã định danh</strong><br>
        (Quyết định số 20/2020/QĐ-TTg ngày 22/7/2020 của Thủ tướng Chính phủ)
      </th>
      <th style="width: 20%"><strong>Tên đơn vị hành chính</strong></th>
      <th><strong>Số lượng cán bộ cấp xã được tập huấn, bồi dưỡng</strong></th>
      <th style="width: 20%"><strong>Ghi chú</strong></th>
    </tr>
  </thead>
  <tbody>`;
  dataReport.forEach((it, index) => {
    html += `<tr style="text-align: center">
      <td>${index + 1}</td>
      <td>${it.code}</td>
      <td>${it.name}</td>
      <td>${it.workerTotal}</td>
      <td></td>
    </tr>`;
  });
  html += `<tr>
      <td colspan="3"><strong>Tổng cộng:</strong></td>
      <td style="text-align: center">${accumulator}</td>
      <td></td>
    </tr>
  </tbody>
</table>
<div class="footer">
  <p>
    <strong>1. Đơn vị cập nhật số liệu: </strong>Ủy ban nhân dân cấp xã:<br />
    - Số lượng cán bộ của cấp xã (công chức VHXH, nhân viên đài truyền thanh, tuyên truyền viên cơ sở…) được tập huấn, bồi dưỡng chuyên môn, nghiệp vụ thông tin cơ sở (có ghi “1” trở lên; không có ghi “0”).
  </p>

  <p>
    <strong>2. Yêu cầu trích xuất số liệu báo cáo theo các biểu: </strong><br />
    - Số xã của tỉnh (thành phố trực thuộc TW)/ huyện (thị xã, thành phố thuộc tỉnh); <br />
    - Số phường của tỉnh (thành phố trực thuộc TW)/ quận (thị xã, thành phố thuộc tỉnh); <br />
    - Số thị trấn của tỉnh (thành phố trực thuộc TW)/ huyện.
    
  </p>
  <p>
    <strong>3. Thời hạn gửi báo cáo: </strong> <br />
    - Chậm nhất ngày 08 của tháng cuối quý đối với báo cáo định kỳ hằng quý; <br />
    - Chậm nhất ngày 27/11 đối với báo cáo định kỳ hằng năm.
  </p>
</div>`;
  return html;
};

export default CommuneTrainingOfficers;
