import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { truncate } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Icon,
  Image,
  Input,
  Label,
  Popup,
  Select,
  Table,
} from 'semantic-ui-react';

import { usePopup } from '../../lib/popup';
import Config from '../../constants/Config';
import { ContentTypeOptions, StatusOptions } from '../../constants/Enums';
import Paths from '../../constants/Paths';

import DeleteStep from '../DeleteStep';
import AudioPlayer from './AudioPlayer';
import ContentModalContainer from '../../containers/ContentModalContainer';
import StationsLogsModalContainer from '../../containers/StationsLogsModalContainer';
import Media from './Media';
import PaginationCustom from '../Pagination';

import styles from './Contents.module.scss';

const LIMIT = Config.LIMIT_PER_PAGE;

const permissionDeleteContent = (content, { level, userId }) => {
  const canDelete = content.creatorUserId === userId && content.MucDoUuTien !== 1;

  if (level === 'ward') {
    return canDelete;
  }

  return canDelete && content.sendStatus === 'pending';
};

const mergeByProperty = (items, key) => {
  return items.reduce((res, location) => {
    const prop = location[key];
    res[prop] = res[prop] ? [...res[prop], location] : [location];
    return res;
  }, {});
};

const countStatusContent = (data) =>
  data.reduce((acc, { statusContent, ward, id }) => {
    acc[statusContent] = { count: (acc[statusContent]?.count || 0) + 1, ward, id };
    return acc;
  }, {});

const mostFrequentStatusContent = (item) =>
  Object.keys(item).map((key) => {
    const statusCounts = countStatusContent(item[key]);

    const frequentStatusContent =
      // eslint-disable-next-line no-nested-ternary
      'playing' in statusCounts
        ? 'playing'
        : 'played' in statusCounts
        ? 'played'
        : Object.keys(statusCounts).reduce((a, b) =>
            statusCounts[a].count > statusCounts[b].count ? a : b,
          );

    return {
      statusContent: frequentStatusContent,
      ...statusCounts[frequentStatusContent],
    };
  });

const Contents = React.memo(
  ({
    // selectedContentId,
    slug,
    categories,
    contents,
    contentTotal,
    sidebarMenu,
    level,
    userId,
    accessToken,
    canEdit,
    provinces,
    districts,
    wards,
    openContentModal,
    canDelete,
    onCreate,
    onFetch,
    onUpdate,
    onMove,
    onDelete,
    onCancel,
    onClose,
  }) => {
    const [t] = useTranslation();

    const [isContentModalOpened, setContentModalOpened] = useState(false);
    const [isStationLogsModalOpened, setStationLogsModalOpened] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [limit, setLimit] = useState(LIMIT);
    const [page, setPage] = useState(1);
    const [name, setName] = useState('');
    const [category, setCategory] = useState([]);
    const [status, setStatus] = useState([]);
    const [audioFiles, setAudioFiles] = useState([]);
    const [currentAudio, setCurrentAudio] = useState({ name: '', url: '' });
    const [media, setMedia] = useState({ type: '', url: '' });
    const [isPlaying, setIsPlaying] = useState(true);
    const [sourceIds, setSourceIds] = useState([]);

    const totalPages = Math.ceil(contentTotal / limit);

    const handleViewMedia = useCallback(
      (source) => {
        if (media.url) {
          setMedia({ type: '', url: '' });
        } else {
          setMedia(source);
        }
      },
      [media.url],
    );

    const handleEditOpen = useCallback((id) => {
      setSelectedId(id);
      setContentModalOpened(true);
    }, []);

    const handleRefetchContent = useCallback(
      (num) => {
        const filter = {
          ...(name && { name }),
          ...(category.length > 0 && { categories: category }),
          ...(status.length > 0 && { status }),
          ...(sourceIds.length > 0 && { sourceIds }),
        };

        setPage(num);
        onFetch(num, limit, filter);
      },
      [limit, name, category, status, sourceIds, onFetch],
    );

    const handleDelete = useCallback(
      (id) => {
        onDelete(id);
        if (page === totalPages && page !== 1) {
          if (contents.length === 1) {
            const newPage = totalPages - 1;
            setPage(newPage);
            onFetch(newPage, limit);
          }
        }

        if (page < totalPages) {
          onFetch(page, limit);
        }
      },
      [contents, page, limit, totalPages, onDelete, onFetch],
    );

    const handleContentModalOpen = useCallback(() => {
      setSelectedId(undefined);
      setContentModalOpened(true);
    }, []);

    const handleContentModalClose = useCallback(() => {
      setContentModalOpened(false);
      onClose();
    }, [onClose]);

    const handleStationLogsModalOpen = useCallback((id) => {
      setSelectedId(id);
      setStationLogsModalOpened(true);
    }, []);

    const handleStationLogsModalClose = useCallback(() => {
      setStationLogsModalOpened(false);
    }, []);

    const handleSelectPage = useCallback(
      (_, { activePage }) => {
        handleRefetchContent(activePage);
      },
      [handleRefetchContent],
    );

    const onChangeName = useCallback((_, { value }) => {
      setName(value);
    }, []);

    const onChangeSelectCategory = useCallback((_, { value }) => {
      setCategory(value);
    }, []);

    const onChangeSelectStatus = useCallback((_, { value }) => {
      setStatus(value);
    }, []);

    const onChangeSelectAreas = useCallback((_, { value }) => {
      setSourceIds(value);
    }, []);

    const handleFilter = useCallback(() => {
      handleRefetchContent(1);
    }, [handleRefetchContent]);

    const handleSelectAudioFiles = useCallback(
      (attachments) => {
        const mp3s = attachments; // .filter((at) => at.name.match(/.mp3$/));
        if (
          mp3s.length > 0 &&
          audioFiles.length > 0 &&
          mp3s[0].contentId === audioFiles[0].contentId &&
          isPlaying
        ) {
          setAudioFiles([]);
          setIsPlaying(false);
        } else {
          const mp3sWithToken = mp3s.map((m) => ({
            ...m,
            url: `${m.url}?accessToken=${accessToken}`,
          }));
          setAudioFiles(mp3sWithToken);
          setIsPlaying(true);
        }
      },
      [isPlaying, audioFiles, accessToken],
    );

    // const handleClick = (at) => {
    //   if (at.url === currentAudio.url && currentAudio.url !== '') {
    //     setCurrentAudio({ name: '', url: '' });
    //   } else {
    //     setCurrentAudio(at);
    //   }
    // };

    const renderStatus = useCallback(
      (item) => {
        if (item.status === 'draft') {
          return (
            <Label
              style={{ marginBottom: 4 }}
              color={StatusOptions.find(({ key }) => key === item.status)?.color}
              content={t(`common.${item.status}`)}
            />
          );
        }

        if (level === 'ward') {
          if (item.contentLocations.length > 0) {
            const location = { [item.contentLocations[0].id]: item.contentLocations };
            const contentLocations = mostFrequentStatusContent(location);

            return (
              <Label
                color={
                  StatusOptions.find(({ key }) => key === contentLocations[0]?.statusContent)?.color
                }
                content={
                  contentLocations[0]?.statusContent
                    ? t(`common.${contentLocations[0]?.statusContent}`)
                    : '-'
                }
              />
            );
          }

          return (
            <Label
              style={{ marginBottom: 4 }}
              color={StatusOptions.find(({ key }) => key === item.status)?.color}
              content={t(`common.${item.status}`)}
            />
          );
        }

        const mergeLocations = mergeByProperty(item.contentLocations, 'wardId');

        const contentLocations = mostFrequentStatusContent(mergeLocations);

        return contentLocations.length > 1 ? (
          <Popup
            on="click"
            content={contentLocations.map((contentLocation) => (
              <Label
                key={contentLocation.id}
                style={{ marginBottom: 4 }}
                color={
                  StatusOptions.find(({ key }) => key === contentLocation.statusContent)?.color
                }
                content={`${t(`common.${contentLocation.statusContent}`)} - ${
                  contentLocation.ward
                }`}
              />
            ))}
            trigger={<Button size="mini" content={t('action.view')} />}
          />
        ) : (
          <Label
            style={{ marginBottom: 4 }}
            color={StatusOptions.find(({ key }) => key === item.status)?.color}
            content={t(`common.${item.status}`)}
          />
        );
      },
      [level, t],
    );

    // useEffect(() => {
    //   if (selectedContentId) {
    //     handleEditOpen(selectedContentId);
    //   }
    // }, [selectedContentId, handleEditOpen]);

    const DeletePopup = usePopup(DeleteStep);

    const categoryOptions = categories.map(({ id, name: categoryName }) => ({
      key: id,
      value: id,
      text: categoryName,
    }));

    const provincesOptions = provinces.map(({ id, name: provinceName }) => ({
      key: id,
      value: id,
      text: provinceName,
    }));

    const districtsOptions = districts.map(({ id, name: districtName }) => ({
      key: id,
      value: id,
      text: districtName,
    }));

    const wardsOptions = wards.map((w) => {
      const district = districts.find((d) => d.id === w?.districtId);
      return {
        key: w.id,
        value: w.id,
        text: `${w.name} - ${district?.name}`,
      };
    });

    const sourceOptions = [...provincesOptions, ...districtsOptions, ...wardsOptions];

    return (
      <div className={styles.wrapper}>
        <div className={styles.wrapperFilterAndAdd}>
          <div className={styles.wrapperFilter}>
            <Link to={Paths.SCHEDULES.replace(':slug', slug)}>
              <Button className={styles.buttonNavigate} content={t('menu.schedules')} />
            </Link>
            <Input
              className={styles.textBox}
              placeholder={t('common.nameContent')}
              icon="filter"
              iconPosition="left"
              value={name}
              onChange={onChangeName}
            />
            <Select
              className={styles.selectBox}
              multiple
              clearable
              placeholder={t('common.selectCategory')}
              options={categoryOptions}
              value={category}
              onChange={onChangeSelectCategory}
            />
            <Select
              className={styles.selectBox}
              multiple
              clearable
              placeholder={t('common.selectStatus')}
              options={StatusOptions.map((it) => ({ ...it, text: t(`common.${it.key}`) }))}
              value={status}
              onChange={onChangeSelectStatus}
            />
            <Dropdown
              className={styles.searchBox}
              clearable
              fluid
              multiple
              search
              selection
              placeholder={t('common.selectSource')}
              options={sourceOptions}
              value={sourceIds}
              onChange={onChangeSelectAreas}
              noResultsMessage={t('common.noResultsFound')}
            />
            <Button className={styles.buttonFilter} onClick={handleFilter}>
              <span>{t('action.filter')}</span>
            </Button>
          </div>
          {canEdit && (
            <div>
              <Button className={styles.buttonAdd} onClick={handleContentModalOpen}>
                <Icon name="add" />
                <span>{t('action.createNewContent')}</span>
              </Button>
            </div>
          )}
        </div>
        <div className={styles.wrapperTable}>
          <Table selectable unstackable singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>{t('common.name')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common.classification')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common.contentType')}</Table.HeaderCell>
                <Table.HeaderCell width={2}>{t('common.file')}</Table.HeaderCell>
                <Table.HeaderCell width={2}>{t('common.category')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common.createdIn')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common.status')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common.playCount')}</Table.HeaderCell>
                <Table.HeaderCell width={3} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {contents.map((it) => {
                let audio = it.attachments.filter(({ url }) => url?.match(/.mp3|.wav$/));

                if (audio.length <= 0 && it.file && it.file.length > 0) {
                  audio = [
                    {
                      id: it.id,
                      url: it.file.map((f, idx) => ({
                        id: idx,
                        url: f,
                      })),
                      contentId: it.id,
                      name: `Âm thanh`,
                    },
                  ];
                }

                const isLoadingContent = false; // audio.length <= 0 && !it.link;

                // eslint-disable-next-line no-nested-ternary
                const createdIn = it.ward
                  ? `${it.ward?.name}-${it.ward?.district}`
                  : it.district
                  ? `${it.district?.name}-${it.province.name}`
                  : it.province.name;

                const nameCategory = it.categories.map((cat) => cat.name).join(', ');

                return (
                  <Table.Row key={it.id} disabled={isLoadingContent}>
                    <Table.Cell>
                      <span title={it.name}>{truncate(it.name, { length: 30 })}</span>
                    </Table.Cell>
                    <Table.Cell>{t(`common.${it.PhanLoai}`)}</Table.Cell>
                    <Table.Cell>
                      {t(
                        `common.${ContentTypeOptions.find(({ value }) => value === it.type)?.key}`,
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {/* {it.file &&
                        it.file.length > 0 &&
                        audio
                          .slice(0, 1)
                          .map((au) => au.name)
                          .join(', ')}
                      {it.file && it.file.length > 1 && ` (+${it.file.length - 1})`} */}
                      {it.attachments.map((at) =>
                        at.image ? (
                          <Image key={at.id} src={at.coverUrl} alt={at.name} height={32} />
                        ) : (
                          <div key={at.id}>
                            <span title={at.name}>{truncate(at.name, { length: 30 })}</span>
                          </div>
                        ),
                      )}
                      {it.link && (
                        <a target="_blank" rel="noreferrer" href={it.link} title={it.link}>
                          {truncate(it.link, { length: 30 })}
                        </a>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <span title={nameCategory}>{truncate(nameCategory, { length: 30 })}</span>
                    </Table.Cell>
                    <Table.Cell>{createdIn}</Table.Cell>
                    <Table.Cell>{renderStatus(it)}</Table.Cell>
                    <Table.Cell>{it.playCount}</Table.Cell>
                    {/* {isLoadingContent ? (
                      <Table.Cell>
                        <div style={{ position: 'relative' }}>
                          <Loader active />
                        </div>
                      </Table.Cell>
                    ) : ( */}
                    <Table.Cell textAlign="right">
                      {/* {it.link && (
                        <Button
                          content={t(
                            currentAudio.url === it.link ? 'common.pause' : 'common.tryListening',
                          )}
                          className={styles.action}
                          size="mini"
                          onClick={() => handleClick({ name: '', url: it.link })}
                        />
                      )} */}
                      {it.attachments.map((at) =>
                        at.image ? (
                          <Button
                            key={at.id}
                            content={t('common.image')}
                            className={styles.action}
                            size="mini"
                            onClick={() => handleViewMedia({ type: 'image', url: at.url })}
                          />
                        ) : (
                          at.name.match(/.mp4$/) && (
                            <Button
                              key={at.id}
                              content={t(media.url === at.url ? 'common.pause' : 'common.play')}
                              className={styles.action}
                              size="mini"
                              onClick={() => handleViewMedia({ type: 'video', url: at.url })}
                            />
                          )
                        ),
                      )}
                      {audio.length > 0 && (
                        <Button
                          icon
                          size="mini"
                          content={
                            t(
                              audioFiles[0]?.id === audio[0].id && isPlaying
                                ? 'common.pause'
                                : 'common.tryListening',
                            )
                            // <Icon name={audioFiles[0]?.id === audio[0].id ? 'pause' : 'play'} />
                          }
                          className={styles.action}
                          onClick={() => handleSelectAudioFiles(audio)}
                        />
                      )}
                      {it.LichPhatID && canDelete && it.status !== 'draft' && (
                        <DeletePopup
                          title="common.cancelSchedule"
                          content="common.areYouSureYouWantToCancelThisSchedule"
                          buttonContent="action.yes"
                          onConfirm={() => onCancel(it.id, it.LichPhatID)}
                        >
                          <Button
                            icon
                            size="mini"
                            content={t('action.cancelSchedule')}
                            className={styles.action}
                          />
                        </DeletePopup>
                      )}

                      <Button
                        icon
                        size="mini"
                        content={t('action.history')}
                        className={styles.action}
                        onClick={() => handleStationLogsModalOpen(it.id)}
                      />
                      {(audio.length > 0 || it.link) && (
                        <Link to={Paths.CONTENT.replace(':slug', slug).replace(':id', it.id)}>
                          <Button
                            icon
                            size="mini"
                            content={t(
                              `action.${it.MucDoUuTien === 1 || !canEdit ? 'view' : 'edit'}`,
                            )}
                            className={styles.action}
                            // onClick={() => handleEditOpen(it.id)}
                          />
                        </Link>
                      )}
                      {permissionDeleteContent(it, { level, userId }) && canDelete && (
                        <DeletePopup
                          title="common.deleteContent"
                          content="common.areYouSureYouWantToDeleteThisContent"
                          buttonContent="action.deleteContent"
                          onConfirm={() => handleDelete(it.id)}
                        >
                          <Button
                            icon
                            size="mini"
                            content={t('action.delete')}
                            className={classNames(styles.action, styles.delete)}
                          />
                        </DeletePopup>
                      )}
                    </Table.Cell>
                    {/* )} */}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
        {/* <audio src={currentAudio.url} autoPlay={!!currentAudio.url}>
          <track kind="captions" />
        </audio> */}
        <div className={styles.wrapperPagination}>
          <PaginationCustom
            activePage={page}
            totalPages={totalPages}
            onPageChange={handleSelectPage}
          />
        </div>

        {audioFiles.length > 0 && (
          <AudioPlayer
            key={audioFiles[0].id}
            sidebarMenu={sidebarMenu}
            audioFiles={audioFiles}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            onClose={() => setAudioFiles([])}
          />
        )}
        {(isContentModalOpened || openContentModal) && (
          <ContentModalContainer onClose={handleContentModalClose} />
        )}
        {isStationLogsModalOpened && (
          <StationsLogsModalContainer
            onClose={handleStationLogsModalClose}
            selectedId={selectedId}
          />
        )}
        {media.url && <Media media={media} onClose={handleViewMedia} />}
      </div>
    );
  },
);

Contents.propTypes = {
  // selectedContentId: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  sidebarMenu: PropTypes.bool.isRequired,
  openContentModal: PropTypes.bool.isRequired,
  /* eslint-disable react/forbid-prop-types */
  categories: PropTypes.array.isRequired,
  contents: PropTypes.array.isRequired,
  contentTotal: PropTypes.number.isRequired,
  /* eslint-enable react/forbid-prop-types */
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  provinces: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  districts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  wards: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onCreate: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Contents;
