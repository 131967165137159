import { call, put, select } from 'redux-saga/effects';
import actions from '../../../actions';
import api from '../../../api';
import request from '../request';
import selectors from '../../../selectors';

export function* fetchActions(filter, limit, page) {
  const { id: provinceId } = yield select(selectors.selectCurrentProvince);

  yield put(actions.fetchActions(provinceId, filter));

  let items;
  let total;
  let users;
  let contents;
  let stations;
  let devices;

  try {
    ({
      items,
      included: { total, users, contents, stations, devices },
    } = yield call(request, api.getActions, provinceId, { filter, limit, page }));
  } catch (error) {
    yield put(actions.fetchActions.failure(error));
    return;
  }

  yield put(
    actions.fetchActions.success(items, total, users, contents, stations, devices, provinceId),
  );
}

export default { fetchActions };
