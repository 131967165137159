import ActionTypes from '../constants/ActionTypes';

const handleLocationChange = (
  district,
  ward,
  hamlet,
  users,
  provinces,
  districtMemberships,
  categories,
  contents,
  contentCategories,
  schedules,
  stations,
  devices,
  deviceMemberships,
  deviceContents,
  tasks,
  attachments,
  deletedNotifications,
  province,
  contentLocations,
  contentSchedules,
  userGroups,
  userLogs,
) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE,
  payload: {
    district,
    ward,
    hamlet,
    users,
    provinces,
    districtMemberships,
    categories,
    contents,
    contentCategories,
    schedules,
    stations,
    devices,
    deviceMemberships,
    deviceContents,
    tasks,
    attachments,
    deletedNotifications,
    province,
    contentLocations,
    contentSchedules,
    userGroups,
    actions: userLogs,
  },
});

handleLocationChange.fetchDistrict = (id) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__DISTRICT_FETCH,
  payload: {
    id,
  },
});

handleLocationChange.fetchDevices = (provinceId) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__DEVICES_FETCH,
  payload: {
    provinceId,
  },
});

handleLocationChange.fetchContents = (provinceId) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__CONTENTS_FETCH,
  payload: {
    provinceId,
  },
});

handleLocationChange.fetchSchedules = (provinceId) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__SCHEDULES_FETCH,
  payload: {
    provinceId,
  },
});

handleLocationChange.fetchStations = (provinceId) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__STATIONS_FETCH,
  payload: {
    provinceId,
  },
});

handleLocationChange.fetchReports = (provinceId) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__REPORTS_FETCH,
  payload: {
    provinceId,
  },
});

handleLocationChange.fetchUserGroups = (provinceId) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__USER_GROUPS_FETCH,
  payload: {
    provinceId,
  },
});

handleLocationChange.fetchActions = (provinceId) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__ACTIONS_FETCH,
  payload: {
    provinceId,
  },
});

export default {
  handleLocationChange,
};
