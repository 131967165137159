import { attr, fk, many, oneToOne } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';
import Config from '../constants/Config';
import { ActivityTypes } from '../constants/Enums';

export default class extends BaseModel {
  static modelName = 'Device';

  static fields = {
    id: attr(),
    position: attr(),
    name: attr(),
    description: attr(),
    dueDate: attr(),
    stopwatch: attr(),
    isSubscribed: attr({
      getDefault: () => false,
    }),
    isActivitiesFetching: attr({
      getDefault: () => false,
    }),
    isAllActivitiesFetched: attr({
      getDefault: () => false,
    }),
    isActivitiesDetailsVisible: attr({
      getDefault: () => false,
    }),
    isActivitiesDetailsFetching: attr({
      getDefault: () => false,
    }),
    districtId: fk({
      to: 'District',
      as: 'district',
      relatedName: 'devices',
    }),
    stationId: fk({
      to: 'Station',
      as: 'station',
      relatedName: 'devices',
    }),
    wardId: fk({
      to: 'Ward',
      as: 'ward',
      relatedName: 'devices',
    }),
    hamletId: fk({
      to: 'Hamlet',
      as: 'hamlet',
      relatedName: 'devices',
    }),
    coverAttachmentId: oneToOne({
      to: 'Attachment',
      as: 'coverAttachment',
      relatedName: 'coveredDevice',
    }),
    users: many('User', 'devices'),
    contents: many('Content', 'devices'),
  };

  static reducer({ type, payload }, Device) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE:
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE:
      case ActionTypes.ACTIONS_FETCH__SUCCESS:
        if (payload.devices) {
          payload.devices.forEach((device) => {
            Device.upsert(device);
          });
        }

        if (payload.deviceMemberships) {
          payload.deviceMemberships.forEach(({ deviceId, userId }) => {
            const existedUser = Device.withId(deviceId).users.exists({ id: userId });

            if (!existedUser) {
              Device.withId(deviceId).users.add(userId);
            }
          });
        }

        if (payload.deviceContents) {
          payload.deviceContents.forEach(({ deviceId, contentId }) => {
            Device.withId(deviceId).contents.add(contentId);
          });
        }

        break;
      case ActionTypes.DEVICES_FETCH__SUCCESS:
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Device.all()
          .toModelArray()
          .forEach((deviceModel) => {
            deviceModel.deleteWithClearable();
          });

        if (payload.devices) {
          payload.devices.forEach((device) => {
            Device.upsert(device);
          });
        }

        if (payload.deviceMemberships) {
          payload.deviceMemberships.forEach(({ deviceId, userId }) => {
            Device.withId(deviceId).users.add(userId);
          });
        }

        if (payload.deviceContents) {
          payload.deviceContents.forEach(({ deviceId, contentId }) => {
            Device.withId(deviceId).contents.add(contentId);
          });
        }

        break;
      case ActionTypes.USER_TO_DEVICE_ADD: {
        const deviceModel = Device.withId(payload.deviceId);
        deviceModel.users.add(payload.id);

        if (payload.isCurrent) {
          deviceModel.isSubscribed = true;
        }

        break;
      }
      case ActionTypes.USER_TO_DEVICE_ADD__SUCCESS:
      case ActionTypes.USER_TO_DEVICE_ADD_HANDLE:
        try {
          Device.withId(payload.deviceMembership.deviceId).users.add(
            payload.deviceMembership.userId,
          );
        } catch {} // eslint-disable-line no-empty

        break;
      case ActionTypes.USER_FROM_DEVICE_REMOVE:
        Device.withId(payload.deviceId).users.remove(payload.id);

        break;
      case ActionTypes.USER_FROM_DEVICE_REMOVE__SUCCESS:
      case ActionTypes.USER_FROM_DEVICE_REMOVE_HANDLE:
        try {
          Device.withId(payload.deviceMembership.deviceId).users.remove(
            payload.deviceMembership.userId,
          );
        } catch {} // eslint-disable-line no-empty

        break;
      case ActionTypes.DISTRICT_FETCH__SUCCESS:
        payload.devices.forEach((device) => {
          Device.upsert(device);
        });

        payload.deviceMemberships.forEach(({ deviceId, userId }) => {
          Device.withId(deviceId).users.add(userId);
        });

        payload.deviceContents.forEach(({ deviceId, contentId }) => {
          Device.withId(deviceId).contents.add(contentId);
        });

        break;
      case ActionTypes.CONTENT_TO_DEVICE_ADD:
        Device.withId(payload.deviceId).contents.add(payload.id);

        break;
      case ActionTypes.CONTENT_TO_DEVICE_ADD__SUCCESS:
      case ActionTypes.CONTENT_TO_DEVICE_ADD_HANDLE:
        try {
          Device.withId(payload.deviceContent.deviceId).contents.add(
            payload.deviceContent.contentId,
          );
        } catch {} // eslint-disable-line no-empty

        break;
      case ActionTypes.CONTENT_FROM_DEVICE_REMOVE:
        Device.withId(payload.deviceId).contents.remove(payload.id);

        break;
      case ActionTypes.CONTENT_FROM_DEVICE_REMOVE__SUCCESS:
      case ActionTypes.CONTENT_FROM_DEVICE_REMOVE_HANDLE:
        try {
          Device.withId(payload.deviceContent.deviceId).contents.remove(
            payload.deviceContent.contentId,
          );
        } catch {} // eslint-disable-line no-empty

        break;
      case ActionTypes.DEVICE_CREATE:
      case ActionTypes.DEVICE_CREATE_HANDLE:
      case ActionTypes.DEVICE_UPDATE__SUCCESS:
      case ActionTypes.DEVICE_UPDATE_HANDLE:
        Device.upsert(payload.device);

        break;
      case ActionTypes.DEVICE_CREATE__SUCCESS: {
        Device.withId(payload.localId).delete();

        const deviceTotal = Device.all().count();

        if (deviceTotal < Config.LIMIT_PER_PAGE) {
          Device.upsert(payload.device);
        }

        break;
      }
      case ActionTypes.DEVICE_UPDATE:
        Device.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.DEVICE_DELETE:
        Device.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.DEVICE_DELETE__SUCCESS:
      case ActionTypes.DEVICE_DELETE_HANDLE: {
        const deviceModel = Device.withId(payload.device.id);

        if (deviceModel) {
          deviceModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.ACTIVITIES_FETCH:
        Device.withId(payload.deviceId).update({
          isActivitiesFetching: true,
        });

        break;
      case ActionTypes.ACTIVITIES_FETCH__SUCCESS:
        Device.withId(payload.deviceId).update({
          isActivitiesFetching: false,
          isAllActivitiesFetched: payload.activities.length < Config.ACTIVITIES_LIMIT,
        });

        break;
      case ActionTypes.ACTIVITIES_DETAILS_TOGGLE: {
        const deviceModel = Device.withId(payload.deviceId);
        deviceModel.isActivitiesDetailsVisible = payload.isVisible;

        if (payload.isVisible) {
          deviceModel.isActivitiesDetailsFetching = true;
        }

        break;
      }
      case ActionTypes.ACTIVITIES_DETAILS_TOGGLE__SUCCESS: {
        const deviceModel = Device.withId(payload.deviceId);

        deviceModel.update({
          isAllActivitiesFetched: payload.activities.length < Config.ACTIVITIES_LIMIT,
          isActivitiesDetailsFetching: false,
        });

        deviceModel.deleteActivities();

        break;
      }
      case ActionTypes.NOTIFICATION_FETCH__SUCCESS:
      case ActionTypes.NOTIFICATION_CREATE_HANDLE:
        payload.devices.forEach((device) => {
          Device.upsert(device);
        });

        break;
      default:
    }
  }

  getOrderedTasksQuerySet() {
    return this.tasks.orderBy('position');
  }

  getOrderedAttachmentsQuerySet() {
    return this.attachments.orderBy('id', false);
  }

  getFilteredOrderedInDeviceActivitiesQuerySet() {
    const filter = {
      isInDevice: true,
    };

    if (!this.isActivitiesDetailsVisible) {
      filter.type = ActivityTypes.COMMENT_DEVICE;
    }

    return this.activities.filter(filter).orderBy('id', false);
  }

  getUnreadNotificationsQuerySet() {
    return this.notifications.filter({
      isRead: false,
    });
  }

  isAvailableForUser(userId) {
    return this.district && this.district.isAvailableForUser(userId);
  }

  deleteClearable() {
    this.users.clear();
    this.contents.clear();
  }

  deleteActivities() {
    this.activities.toModelArray().forEach((activityModel) => {
      if (activityModel.notification) {
        activityModel.update({
          isInDevice: false,
        });
      } else {
        activityModel.delete();
      }
    });
  }

  deleteRelated() {
    this.deleteClearable();
    this.tasks.delete();
    this.attachments.delete();
    this.deleteActivities();
  }

  deleteWithClearable() {
    this.deleteClearable();
    this.delete();
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
