import React from 'react';
import { List } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import TreeItem from './TreeItem';

const TreeView = React.memo(({ data, onSelect }) => {
  const renderSubtrees = (trees) => {
    return trees.map((tree) => {
      if (!tree.childNodes || tree.childNodes.length <= 0) {
        return <TreeItem key={tree.id} id={tree.id} name={tree.name} onSelect={onSelect} />;
      }

      return (
        <TreeItem key={tree.id} id={tree.id} name={tree.name} expand onSelect={onSelect}>
          {renderSubtrees(tree?.childNodes)}
        </TreeItem>
      );
    });
  };
  return <List>{renderSubtrees(data)}</List>;
});

TreeView.propTypes = {
  data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onSelect: PropTypes.func.isRequired,
};

export default TreeView;
