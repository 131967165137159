const CommuneVisualInformation = (data, wards) => {
  const dataReport = wards.map((ward) => {
    return {
      code: ward.code,
      name: ward.name,
      contents: 0,
      website: ward.TrangThongTinDienTu,
      grassrootsPropagandist: ward.NhanLuc.TuyenTruyenVienCoSo,
    };
  });

  const accumulator = dataReport.reduce(
    (acc, item) => {
      Object.keys(item).forEach((key) => {
        if (acc[key] !== undefined) {
          acc[key] += item[key];
        }
      });

      return acc;
    },
    {
      contents: 0,
      website: 0,
      grassrootsPropagandist: 0,
    },
  );
  let html = `<div class="header">
  <h3>Bảng 3</h3>
  <p>SỐ LIỆU VỀ THÔNG TIN TRỰC QUAN CẤP XÃ</p>
  <p>Kỳ báo cáo: Quý ${data.quater} năm ${data.year.getFullYear()}.</p>
</div>
<table>
  <thead>
    <tr>
      <th><strong>STT</strong></th>
      <th style="width: 15%;">
        <strong>Mã định danh</strong><br/> (Quyết định số 20/2020/QĐ-TTg ngày 22/7/2020 của Thủ tướng Chính phủ)
      </th>
      <th style="width: 20%;"><strong>Tên đơn vị hành chính</strong></th>
      <th><strong>Số lượng bảng tin điện tử công cộng cấp xã</strong></th>
      <th><strong>Số lượng trang thông tin điện tử cấp xã</strong></th>
      <th><strong>Số lượng tuyên truyền viên cơ sở</strong></th>
      <th style="width: 15%;"><strong>Ghi chú</strong></th>
    </tr>
  </thead>
  <tbody>`;
  dataReport.forEach((it, index) => {
    html += `<tr style="text-align: center">
      <td>${index + 1}</td>
      <td>${it.code}</td>
      <td>${it.name}</td>
      <td>${it.contents}</td>
      <td>${it.website}</td>
      <td>${it.grassrootsPropagandist}</td>
      <td></td>
    </tr>`;
  });
  html += `<tr>
      <td colspan="3"><strong>Tổng cộng:</strong></td>
      <td style="text-align: center">${accumulator.contents}</td>
      <td style="text-align: center">${accumulator.website}</td>
      <td style="text-align: center">${accumulator.grassrootsPropagandist}</td>
      <td></td>
    </tr>
  </tbody>
</table>
<div class="footer">
  <p>
    <strong>1. Đơn vị cập nhật số liệu: </strong>Ủy ban nhân dân cấp xã: <br />
    - Số lượng bảng tin điện tử công cộng của cấp xã (có ghi “1” trở lên; không có ghi “0”);<br />
    - Số lượng trang thông điện tử của cấp xã (có ghi “1”; không có ghi “0”);<br />
    - Số lượng tuyên truyền viên cơ sở của cấp xã (có ghi “1” trở lên; không có ghi “0”).
  </p>

  <p>
    <strong>2. Yêu cầu trích xuất số liệu báo cáo theo các biểu: </strong><br />
    - Số xã của tỉnh (thành phố trực thuộc TW)/ huyện (thị xã, thành phố thuộc tỉnh);<br />
    - Số phường của tỉnh (thành phố trực thuộc TW)/ quận (thị xã, thành phố thuộc tỉnh);<br />
    - Số thị trấn của tỉnh (thành phố trực thuộc TW)/ huyện.
  </p>
  <p>
    <strong>3. Thời hạn gửi báo cáo: </strong> <br />
    - Chậm nhất ngày 08 của tháng cuối quý đối với báo cáo định kỳ hằng quý; <br />
    - Chậm nhất ngày 27/11 đối với báo cáo định kỳ hằng năm.
  </p>
</div>`;
  return html;
};

export default CommuneVisualInformation;
