import EntryActionTypes from '../constants/EntryActionTypes';

const createWardInCurrentProvince = (data) => ({
  type: EntryActionTypes.WARD_IN_CURRENT_PROVINCE_CREATE,
  payload: {
    data,
  },
});

const handleWardCreate = (ward, requestId) => ({
  type: EntryActionTypes.WARD_CREATE_HANDLE,
  payload: {
    ward,
    requestId,
  },
});

const fetchWard = (id) => ({
  type: EntryActionTypes.WARD_FETCH,
  payload: {
    id,
  },
});

const updateWard = (id, data) => ({
  type: EntryActionTypes.WARD_UPDATE,
  payload: {
    id,
    data,
  },
});

const handleWardUpdate = (ward) => ({
  type: EntryActionTypes.WARD_UPDATE_HANDLE,
  payload: {
    ward,
  },
});

const moveWard = (id, index) => ({
  type: EntryActionTypes.WARD_MOVE,
  payload: {
    id,
    index,
  },
});

const deleteWard = (id) => ({
  type: EntryActionTypes.WARD_DELETE,
  payload: {
    id,
  },
});

const handleWardDelete = (ward) => ({
  type: EntryActionTypes.WARD_DELETE_HANDLE,
  payload: {
    ward,
  },
});

export default {
  createWardInCurrentProvince,
  handleWardCreate,
  fetchWard,
  updateWard,
  handleWardUpdate,
  moveWard,
  deleteWard,
  handleWardDelete,
};
