import EntryActionTypes from '../constants/EntryActionTypes';

const createUser = (data) => ({
  type: EntryActionTypes.USER_CREATE,
  payload: {
    data,
  },
});

const handleUserCreate = (user) => ({
  type: EntryActionTypes.USER_CREATE_HANDLE,
  payload: {
    user,
  },
});

const clearUserCreateError = () => ({
  type: EntryActionTypes.USER_CREATE_ERROR_CLEAR,
  payload: {},
});

const updateUser = (id, data) => ({
  type: EntryActionTypes.USER_UPDATE,
  payload: {
    id,
    data,
  },
});

const updateCurrentUser = (data) => ({
  type: EntryActionTypes.CURRENT_USER_UPDATE,
  payload: {
    data,
  },
});

const handleUserUpdate = (user) => ({
  type: EntryActionTypes.USER_UPDATE_HANDLE,
  payload: {
    user,
  },
});

const updateCurrentUserLanguage = (language) => ({
  type: EntryActionTypes.CURRENT_USER_LANGUAGE_UPDATE,
  payload: {
    language,
  },
});

const updateUserEmail = (id, data) => ({
  type: EntryActionTypes.USER_EMAIL_UPDATE,
  payload: {
    id,
    data,
  },
});

const updateCurrentUserEmail = (data) => ({
  type: EntryActionTypes.CURRENT_USER_EMAIL_UPDATE,
  payload: {
    data,
  },
});

const clearUserEmailUpdateError = (id) => ({
  type: EntryActionTypes.USER_EMAIL_UPDATE_ERROR_CLEAR,
  payload: {
    id,
  },
});

const clearCurrentUserEmailUpdateError = () => ({
  type: EntryActionTypes.CURRENT_USER_EMAIL_UPDATE_ERROR_CLEAR,
  payload: {},
});

const updateUserPassword = (id, data) => ({
  type: EntryActionTypes.USER_PASSWORD_UPDATE,
  payload: {
    id,
    data,
  },
});

const updateCurrentUserPassword = (data) => ({
  type: EntryActionTypes.CURRENT_USER_PASSWORD_UPDATE,
  payload: {
    data,
  },
});

const clearUserPasswordUpdateError = (id) => ({
  type: EntryActionTypes.USER_PASSWORD_UPDATE_ERROR_CLEAR,
  payload: {
    id,
  },
});

const clearCurrentUserPasswordUpdateError = () => ({
  type: EntryActionTypes.CURRENT_USER_PASSWORD_UPDATE_ERROR_CLEAR,
  payload: {},
});

const updateUserUsername = (id, data) => ({
  type: EntryActionTypes.USER_USERNAME_UPDATE,
  payload: {
    id,
    data,
  },
});

const updateCurrentUserUsername = (data) => ({
  type: EntryActionTypes.CURRENT_USER_USERNAME_UPDATE,
  payload: {
    data,
  },
});

const clearUserUsernameUpdateError = (id) => ({
  type: EntryActionTypes.USER_USERNAME_UPDATE_ERROR_CLEAR,
  payload: {
    id,
  },
});

const clearCurrentUserUsernameUpdateError = () => ({
  type: EntryActionTypes.CURRENT_USER_USERNAME_UPDATE_ERROR_CLEAR,
  payload: {},
});

const updateUserPermission = (id, data) => ({
  type: EntryActionTypes.USER_PERMISSION_UPDATE,
  payload: {
    id,
    data,
  },
});

const updateCurrentUserPermission = (data) => ({
  type: EntryActionTypes.CURRENT_USER_PERMISSION_UPDATE,
  payload: {
    data,
  },
});

const clearUserPermissionUpdateError = (id) => ({
  type: EntryActionTypes.USER_PERMISSION_UPDATE_ERROR_CLEAR,
  payload: {
    id,
  },
});

const updateCurrentUserAvatar = (data) => ({
  type: EntryActionTypes.CURRENT_USER_AVATAR_UPDATE,
  payload: {
    data,
  },
});

const deleteUser = (id) => ({
  type: EntryActionTypes.USER_DELETE,
  payload: {
    id,
  },
});

const handleUserDelete = (user) => ({
  type: EntryActionTypes.USER_DELETE_HANDLE,
  payload: {
    user,
  },
});

const addUserToDevice = (id, deviceId) => ({
  type: EntryActionTypes.USER_TO_DEVICE_ADD,
  payload: {
    id,
    deviceId,
  },
});

const addUserToCurrentDevice = (id) => ({
  type: EntryActionTypes.USER_TO_CURRENT_DEVICE_ADD,
  payload: {
    id,
  },
});

const handleUserToDeviceAdd = (deviceMembership) => ({
  type: EntryActionTypes.USER_TO_DEVICE_ADD_HANDLE,
  payload: {
    deviceMembership,
  },
});

const removeUserFromDevice = (id, deviceId) => ({
  type: EntryActionTypes.USER_FROM_DEVICE_REMOVE,
  payload: {
    id,
    deviceId,
  },
});

const removeUserFromCurrentDevice = (id) => ({
  type: EntryActionTypes.USER_FROM_CURRENT_DEVICE_REMOVE,
  payload: {
    id,
  },
});

const handleUserFromDeviceRemove = (deviceMembership) => ({
  type: EntryActionTypes.USER_FROM_DEVICE_REMOVE_HANDLE,
  payload: {
    deviceMembership,
  },
});

const addUserToFilterInCurrentDistrict = (id) => ({
  type: EntryActionTypes.USER_TO_FILTER_IN_CURRENT_DISTRICT_ADD,
  payload: {
    id,
  },
});

const removeUserFromFilterInCurrentDistrict = (id) => ({
  type: EntryActionTypes.USER_FROM_FILTER_IN_CURRENT_DISTRICT_REMOVE,
  payload: {
    id,
  },
});

const handleUserPermissionCreate = (permission) => ({
  type: EntryActionTypes.USER_PERMISSION_CREATE_HANDLE,
  payload: {
    permission,
  },
});

const handleUserPermissionUpdate = (permission) => ({
  type: EntryActionTypes.USER_PERMISSION_UPDATE_HANDLE,
  payload: {
    permission,
  },
});

export default {
  createUser,
  handleUserCreate,
  clearUserCreateError,
  updateUser,
  updateCurrentUser,
  handleUserUpdate,
  updateCurrentUserLanguage,
  updateUserEmail,
  updateCurrentUserEmail,
  clearUserEmailUpdateError,
  clearCurrentUserEmailUpdateError,
  updateUserPassword,
  updateCurrentUserPassword,
  clearUserPasswordUpdateError,
  clearCurrentUserPasswordUpdateError,
  updateUserUsername,
  updateCurrentUserUsername,
  clearUserUsernameUpdateError,
  clearCurrentUserUsernameUpdateError,
  updateUserPermission,
  updateCurrentUserPermission,
  clearUserPermissionUpdateError,
  updateCurrentUserAvatar,
  deleteUser,
  handleUserDelete,
  addUserToDevice,
  addUserToCurrentDevice,
  handleUserToDeviceAdd,
  removeUserFromDevice,
  removeUserFromCurrentDevice,
  handleUserFromDeviceRemove,
  addUserToFilterInCurrentDistrict,
  removeUserFromFilterInCurrentDistrict,
  handleUserPermissionCreate,
  handleUserPermissionUpdate,
};
