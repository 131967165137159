import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Config from '../constants/Config';
import UserAccounts from '../components/UserAccounts';

const mapStateToProps = (state) => {
  let users = selectors.selectUsersExceptCurrent(state);

  const { isAdmin, level, email } = selectors.selectCurrentUser(state);

  if (level === 'district') {
    users = users.filter((user) => user.level !== 'province');
  }

  if (level === 'ward') {
    const wardMemberships = selectors.selectWardMemberships(state);
    const wardUserIds = wardMemberships.map((wm) => wm.userId);
    users = users.filter((user) => user.level === level && wardUserIds.includes(user.id));
  }

  if (email !== Config.ADMIN_EMAIL) {
    users = users.filter((user) => user.email !== Config.ADMIN_EMAIL);
  }

  return {
    items: users,
    isAdmin,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdate: entryActions.updateUser,
      onUsernameUpdate: entryActions.updateUserUsername,
      onUsernameUpdateMessageDismiss: entryActions.clearUserUsernameUpdateError,
      onEmailUpdate: entryActions.updateUserEmail,
      onEmailUpdateMessageDismiss: entryActions.clearUserEmailUpdateError,
      onPasswordUpdate: entryActions.updateUserPassword,
      onPasswordUpdateMessageDismiss: entryActions.clearUserPasswordUpdateError,
      openUsersAccountsClick: entryActions.openUsersAccountsModal,
      onDelete: entryActions.deleteUser,
      onClose: entryActions.closeModal,
      onManagerCreate: entryActions.createManagerInCurrentProvince,
      onManagerDelete: entryActions.deleteProvinceManager,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserAccounts);
