import EntryActionTypes from '../constants/EntryActionTypes';

const createMembershipInCurrentWard = (data, wardId) => ({
  type: EntryActionTypes.MEMBERSHIP_IN_CURRENT_WARD_CREATE,
  payload: {
    data,
    wardId,
  },
});

const handleWardMembershipCreate = (wardMembership) => ({
  type: EntryActionTypes.WARD_MEMBERSHIP_CREATE_HANDLE,
  payload: {
    wardMembership,
  },
});

const updateWardMembership = (id, data) => ({
  type: EntryActionTypes.WARD_MEMBERSHIP_UPDATE,
  payload: {
    id,
    data,
  },
});

const handleWardMembershipUpdate = (wardMembership) => ({
  type: EntryActionTypes.WARD_MEMBERSHIP_UPDATE_HANDLE,
  payload: {
    wardMembership,
  },
});

const deleteWardMembership = (id) => ({
  type: EntryActionTypes.WARD_MEMBERSHIP_DELETE,
  payload: {
    id,
  },
});

const handleWardMembershipDelete = (wardMembership) => ({
  type: EntryActionTypes.WARD_MEMBERSHIP_DELETE_HANDLE,
  payload: {
    wardMembership,
  },
});

export default {
  createMembershipInCurrentWard,
  handleWardMembershipCreate,
  updateWardMembership,
  handleWardMembershipUpdate,
  deleteWardMembership,
  handleWardMembershipDelete,
};
