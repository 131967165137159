import http from './http';
import { transformSchedule } from './schedules';
import socket from './socket';

/* Actions */

const createContent = (provinceId, data, headers) =>
  socket.post(`/provinces/${provinceId}/contents`, data, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      contentSchedules: body.included.contentSchedules.map(transformSchedule),
    },
  }));

const getContents = (provinceId, data, headers) =>
  socket.get(`/provinces/${provinceId}/contents`, data, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      contentSchedules: body.included.contentSchedules.map(transformSchedule),
    },
  }));

const getContent = (id, headers) =>
  socket.get(`/contents/${id}`, undefined, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      contentSchedules: body.included.schedules.map(transformSchedule),
    },
  }));

const getContentStations = (id, data, headers) =>
  socket.get(`/contents/${id}/stations`, data, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      schedules: body.included.schedules.map(transformSchedule),
    },
  }));

const updateContent = (id, data, headers) =>
  socket.patch(`/contents/${id}`, data, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      contentSchedules: body.included.contentSchedules.map(transformSchedule),
    },
  }));

const deleteContent = (id, headers) => socket.delete(`/contents/${id}`, undefined, headers);

const text2Speech = (data, headers) => socket.post(`/tts`, data, headers);

const cancelScheduleContent = (id, data, headers) => socket.put(`/contents/${id}`, data, headers);

const concatFile = (data, headers) => http.post('/concat', data, headers);

export default {
  createContent,
  getContents,
  getContent,
  getContentStations,
  updateContent,
  deleteContent,
  text2Speech,
  cancelScheduleContent,
  concatFile,
};
