import { format, formatDistanceToNowStrict } from 'date-fns';
import { vi } from 'date-fns/locale';
import { inRange } from 'lodash';

const JUST_FINISHED = 10;

export function conflictTime(time, rangeTime) {
  if (rangeTime.length <= 0) return false;

  const { ThoiGianBatDau, ThoiGianKetThuc } = time;

  return rangeTime.some((item) => {
    const existStart = inRange(ThoiGianBatDau, item.ThoiGianBatDau, item.ThoiGianKetThuc);
    const existEnd = inRange(ThoiGianKetThuc, item.ThoiGianBatDau, item.ThoiGianKetThuc);

    const itemInTimeStart = inRange(item.ThoiGianBatDau, ThoiGianBatDau, ThoiGianKetThuc);
    const itemInTimeEnd = inRange(item.ThoiGianKetThuc, ThoiGianBatDau, ThoiGianKetThuc);

    if (!existStart && !existEnd && !itemInTimeStart && !itemInTimeEnd) {
      return false;
    }

    return true;
  });
}

export const areDatesEqual = (date, times, sources = []) => {
  if (!date || sources.length <= 0 || times.length <= 0) {
    return { already: false, items: [] };
  }

  const d = format(new Date(date), 'dd/MM/yyyy');

  const findDateExist = sources.filter((s) => format(s.start, 'dd/MM/yyyy') === d);

  if (findDateExist.length <= 0) {
    return { already: false, items: [] };
  }

  const sourcesToTimeNumber = sources.map((s) => ({
    ThoiGianBatDau: new Date(s.start).getTime(),
    ThoiGianKetThuc: new Date(s.end).getTime(),
  }));

  const boolean = times.some((time) => {
    const isConflict = conflictTime(time, sourcesToTimeNumber);
    return isConflict;
  });

  return { already: boolean, items: findDateExist };
};

export const epochTime = (sources) => {
  const NUM_MILLISECONDS = 1000;
  return sources.map((it) => ({
    NgayPhat: Math.floor(it.NgayPhat / NUM_MILLISECONDS),
    ThoiDiemPhat: it.ThoiDiemPhat.map((ti) => ({
      id: ti.id,
      ThoiGianBatDau: Math.floor(ti.ThoiGianBatDau / NUM_MILLISECONDS),
      ThoiGianKetThuc: Math.floor(ti.ThoiGianKetThuc / NUM_MILLISECONDS),
      wardId: ti.wardId,
    })),
  }));
};

export const setDateToTime = (date, timeNumber) => {
  const d = typeof date === 'number' ? new Date(date) : date;

  const time = new Date(timeNumber);

  return time.setFullYear(d.getFullYear(), d.getMonth(), d.getDate());
};

export const setDateToTimeFrame = (date, timeFrame) => {
  if (!date || timeFrame.length <= 0) return [];
  const arr = [];
  timeFrame.forEach((time) => {
    const obj = {
      ThoiGianBatDau: setDateToTime(date, time.ThoiGianBatDau),
      ThoiGianKetThuc: setDateToTime(date, time.ThoiGianKetThuc),
    };
    arr.push(obj);
  });

  return arr;
};

export const generateEventsFromRRule = (rrule, startDate, timeFrame) => {
  const generatedEvents = [];

  rrule.forEach((ev) => {
    const d = new Date(ev);

    if (startDate.toDateString() !== d.toDateString()) {
      const timeId = new Date(d);
      const fr = setDateToTimeFrame(d, timeFrame);

      timeId.setHours(0, 0, 0, 0);

      const newEvent = {
        id: timeId.toDateString(),
        NgayPhat: d.getTime(),
        ThoiDiemPhat: fr,
      };
      generatedEvents.push(newEvent);
    }
  });
  return generatedEvents;
};

export const timeToString = (time) => {
  if (!time) return null;

  const { ThoiGianBatDau, ThoiGianKetThuc } = time;
  const start = format(new Date(ThoiGianBatDau), 'HH:mm:ss');
  const end = format(new Date(ThoiGianKetThuc), 'HH:mm:ss');
  return `${start}-${end}`;
};

export const convertToTimezone = (datetime, timezone = 'Asia/Ho_Chi_Minh') => {
  let date = datetime;

  if (typeof datetime === 'string') {
    date = new Date(datetime);
  }

  const timeWithTimeZone = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(date);

  return new Date(timeWithTimeZone);
};

export const datetimeDistanceToNow = (datetime) => {
  let date = datetime;

  if (typeof datetime === 'string') {
    date = new Date(datetime);
  }

  const utcTime = convertToTimezone(date);
  const now = new Date();
  const secondsDifference = Math.abs((now - utcTime) / 1000);

  if (secondsDifference < JUST_FINISHED) {
    return 'vừa xong';
  }

  return formatDistanceToNowStrict(utcTime, {
    locale: vi,
    addSuffix: true,
  });
};
