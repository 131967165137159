import EntryActionTypes from '../constants/EntryActionTypes';

const handleNotificationCreate = (notification) => ({
  type: EntryActionTypes.NOTIFICATION_CREATE_HANDLE,
  payload: {
    notification,
  },
});

const updateNotification = (id) => ({
  type: EntryActionTypes.NOTIFICATION_UPDATE,
  payload: {
    id,
  },
});

const deleteNotification = (id) => ({
  type: EntryActionTypes.NOTIFICATION_DELETE,
  payload: {
    id,
  },
});

const handleNotificationDelete = (notification) => ({
  type: EntryActionTypes.NOTIFICATION_DELETE_HANDLE,
  payload: {
    notification,
  },
});

const fetchNotifications = (type, page, limit) => ({
  type: EntryActionTypes.NOTIFICATION_FETCH,
  payload: {
    type,
    page,
    limit,
  },
});

export default {
  handleNotificationCreate,
  updateNotification,
  deleteNotification,
  handleNotificationDelete,
  fetchNotifications,
};
