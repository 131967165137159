import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import entryActions from '../entry-actions';
import MergeFile from '../components/Contents/MergeFile';

const mapStateToProps = (state) => {
  const { isConcating, concatFile: attachment, errorConcat } = state.ui.contentCreateForm;
  const { accessToken } = state.auth;

  return {
    accessToken,
    isConcating,
    attachment,
    error: errorConcat,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onMergeFiles: entryActions.concatFile,
      onConcatClear: entryActions.clearContentConcatFile,
      onErrorClear: entryActions.clearContentError,
      onDeleteAttachment: entryActions.deleteAttachment,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MergeFile);
