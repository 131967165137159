import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Menu } from 'semantic-ui-react';

import { Popup } from '../../../../lib/custom-ui';

import styles from './LevelUsers.module.scss';

const LevelUsers = React.memo(({ title, onSelect, onBack }) => {
  const [t] = useTranslation();

  const handleSelectRoleClick = useCallback(
    (level) => {
      onSelect(level);
    },
    [onSelect],
  );

  return (
    <>
      <Popup.Header onBack={onBack}>
        {t(title, {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Menu secondary vertical className={styles.menu}>
          <Menu.Item onClick={() => handleSelectRoleClick('province')}>
            <div className={styles.menuItemTitle}>{t('common.provinceLevelUsers')}</div>
          </Menu.Item>
          <Menu.Item onClick={() => handleSelectRoleClick('district')}>
            <div className={styles.menuItemTitle}>{t('common.districtLevelUsers')}</div>
          </Menu.Item>
        </Menu>
      </Popup.Content>
    </>
  );
});

export default LevelUsers;

LevelUsers.propTypes = {
  title: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  // onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

LevelUsers.defaultProps = {
  title: 'common.userLevel',
};
