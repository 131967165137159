import { connect } from 'react-redux';

import selectors from '../selectors';
import Core from '../components/Core';

const mapStateToProps = (state) => {
  const isCoreInitializing = selectors.selectIsCoreInitializing(state);
  const isSocketDisconnected = selectors.selectIsSocketDisconnected(state);
  const isDarkMode = selectors.selectIsDarkMode(state);
  const currentModal = selectors.selectCurrentModal(state);
  const currentProvince = selectors.selectCurrentProvince(state);
  const currentDistrict = selectors.selectCurrentDistrict(state);

  return {
    isSocketDisconnected,
    isDarkMode,
    currentModal,
    currentProvince,
    currentDistrict,
    isInitializing: isCoreInitializing,
  };
};

export default connect(mapStateToProps)(Core);
