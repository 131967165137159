import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { usePopup } from '../../lib/popup';

import User from '../User';
import Content from '../Content';
import DistrictMembershipsStep from '../DistrictMembershipsStep';
import ContentsStep from '../ContentsStep';

import styles from './Filters.module.scss';

const Filters = React.memo(
  ({
    users,
    contents,
    allDistrictMemberships,
    allContents,
    canEdit,
    onUserAdd,
    onUserRemove,
    onContentAdd,
    onContentRemove,
    onContentCreate,
    onContentUpdate,
    onContentMove,
    onContentDelete,
  }) => {
    const [t] = useTranslation();

    const handleRemoveUserClick = useCallback(
      (id) => {
        onUserRemove(id);
      },
      [onUserRemove],
    );

    const handleRemoveContentClick = useCallback(
      (id) => {
        onContentRemove(id);
      },
      [onContentRemove],
    );

    const DistrictMembershipsPopup = usePopup(DistrictMembershipsStep);
    const ContentsPopup = usePopup(ContentsStep);

    return (
      <>
        <span className={styles.filter}>
          <DistrictMembershipsPopup
            items={allDistrictMemberships}
            currentUserIds={users.map((user) => user.id)}
            title="common.filterByMembers"
            onUserSelect={onUserAdd}
            onUserDeselect={onUserRemove}
          >
            <button type="button" className={styles.filterButton}>
              <span className={styles.filterTitle}>{`${t('common.members')}:`}</span>
              {users.length === 0 && (
                <span className={styles.filterContent}>{t('common.all')}</span>
              )}
            </button>
          </DistrictMembershipsPopup>
          {users.map((user) => (
            <span key={user.id} className={styles.filterItem}>
              <User
                name={user.name}
                avatarUrl={user.avatarUrl}
                size="tiny"
                onClick={() => handleRemoveUserClick(user.id)}
              />
            </span>
          ))}
        </span>
        {/* <span className={styles.filter}>
          <ContentsPopup
            items={allContents}
            currentIds={contents.map((content) => content.id)}
            title="common.filterByContents"
            canEdit={canEdit}
            onSelect={onContentAdd}
            onDeselect={onContentRemove}
            onCreate={onContentCreate}
            onUpdate={onContentUpdate}
            onMove={onContentMove}
            onDelete={onContentDelete}
          >
            <button type="button" className={styles.filterButton}>
              <span className={styles.filterTitle}>{`${t('common.contents')}:`}</span>
              {contents.length === 0 && (
                <span className={styles.filterContent}>{t('common.all')}</span>
              )}
            </button>
          </ContentsPopup>
          {contents.map((content) => (
            <span key={content.id} className={styles.filterItem}>
              <Content
                name={content.name}
                color={content.color}
                size="small"
                onClick={() => handleRemoveContentClick(content.id)}
              />
            </span>
          ))}
        </span> */}
      </>
    );
  },
);

Filters.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  users: PropTypes.array.isRequired,
  contents: PropTypes.array.isRequired,
  allDistrictMemberships: PropTypes.array.isRequired,
  allContents: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  canEdit: PropTypes.bool.isRequired,
  onUserAdd: PropTypes.func.isRequired,
  onUserRemove: PropTypes.func.isRequired,
  onContentAdd: PropTypes.func.isRequired,
  onContentRemove: PropTypes.func.isRequired,
  onContentCreate: PropTypes.func.isRequired,
  onContentUpdate: PropTypes.func.isRequired,
  onContentMove: PropTypes.func.isRequired,
  onContentDelete: PropTypes.func.isRequired,
};

export default Filters;
