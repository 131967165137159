import { call, put, select, take } from 'redux-saga/effects';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { push } from '../../../lib/redux-router';
import Config from '../../../constants/Config';

import request from '../request';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import ActionTypes from '../../../constants/ActionTypes';
import Paths from '../../../constants/Paths';

export function* goToRoot() {
  yield put(push(Paths.ROOT));
}

export function* goToContents() {
  const { slug } = yield select(selectors.selectCurrentProvince);

  yield put(push(Paths.CONTENTS.replace(':slug', slug)));
}

export function* goToProvince(provinceId) {
  yield put(push(Paths.PROVINCES.replace(':slug', provinceId)));
}

export function* goToDistrict(districtId) {
  const { slug } = yield select(selectors.selectCurrentProvince);
  yield put(push(Paths.DISTRICTS.replace(':slug', slug).replace(':id', districtId)));
}

export function* goToDevice(deviceId) {
  yield put(push(Paths.DEVICE.replace(':id', deviceId)));
}

export function* handleLocationChange() {
  const pathsMatch = yield select(selectors.selectPathsMatch);

  if (!pathsMatch) {
    return;
  }

  switch (pathsMatch.pattern.path) {
    case Paths.LOGIN:
    case Paths.SCAN_QRCODE:
    case Paths.VERIFY_CODE:
      yield call(goToRoot);

      break;
    default:
  }

  const isCoreInitializing = yield select(selectors.selectIsCoreInitializing);

  if (isCoreInitializing) {
    yield take(ActionTypes.CORE_INITIALIZE);
  }

  let district;
  let users;
  let provinces;
  let ward;
  let hamlet;
  let districtMemberships;
  let categories;
  let contents;
  let contentCategories;
  let schedules;
  let stations;
  let devices;
  let deviceMemberships;
  let deviceContents;
  let tasks;
  let attachments;
  let deletedNotifications;
  let province;
  let total;
  let contentLocations;
  let contentSchedules;
  let userGroups;
  let userLogs;

  switch (pathsMatch.pattern.path) {
    case Paths.DISTRICTS:
    case Paths.DEVICE: {
      const currentDistrict = yield select(selectors.selectCurrentDistrict);

      if (currentDistrict && currentDistrict.isFetching === null) {
        yield put(actions.handleLocationChange.fetchDistrict(currentDistrict.id));

        try {
          ({
            item: district,
            included: {
              users,
              provinces,
              districtMemberships,
              categories,
              contents,
              stations,
              devices,
              deviceMemberships,
              deviceContents,
              tasks,
              attachments,
            },
          } = yield call(request, api.getDistrict, currentDistrict.id, true));
        } catch (error) {} // eslint-disable-line no-empty
      }

      if (pathsMatch.pattern.path === Paths.DEVICE) {
        const notificationIds = yield select(selectors.selectNotificationIdsForCurrentDevice);

        if (notificationIds && notificationIds.length > 0) {
          try {
            ({ items: deletedNotifications } = yield call(
              request,
              api.updateNotifications,
              notificationIds,
              {
                isRead: true,
              },
            ));
          } catch (error) {} // eslint-disable-line no-empty
        }
      }

      break;
    }

    case Paths.DISPLAYS:
    case Paths.DEVICES: {
      const currentProvince = yield select(selectors.selectCurrentProvince);

      if (currentProvince && currentProvince.isDevicesFetching === null) {
        yield put(actions.handleLocationChange.fetchDevices(currentProvince.id));
        province = { id: currentProvince.id };

        try {
          ({
            items: devices,
            included: { total, stations },
          } = yield call(request, api.getDevices, currentProvince.id, {
            page: 1,
            limit: Config.LIMIT_PER_PAGE,
          }));
        } catch (error) {
          console.error(error);
        }
      }

      break;
    }

    case Paths.CONTENTS: {
      const currentProvince = yield select(selectors.selectCurrentProvince);

      if (currentProvince && currentProvince.isContentsFetching === null) {
        yield put(actions.handleLocationChange.fetchContents(currentProvince.id));
        province = { id: currentProvince.id };

        try {
          ({
            items: contents,
            included: {
              users,
              total,
              attachments,
              contentCategories,
              contentLocations,
              contentSchedules,
            },
          } = yield call(request, api.getContents, currentProvince.id, {
            page: 1,
            limit: Config.LIMIT_PER_PAGE,
          }));
        } catch (error) {} // eslint-disable-line no-empty
      }

      break;
    }

    case Paths.CONTENT: {
      const currentProvince = yield select(selectors.selectCurrentProvince);

      if (currentProvince) {
        province = { id: currentProvince.id };

        const { contentId } = yield select(selectors.selectPath);

        let content;

        try {
          ({
            item: content,
            included: { attachments, contentCategories, contentLocations, contentSchedules },
          } = yield call(request, api.getContent, contentId));
        } catch (error) {} // eslint-disable-line no-empty

        contents = [content];
      }

      break;
    }

    case Paths.SCHEDULES: {
      const currentProvince = yield select(selectors.selectCurrentProvince);
      const today = new Date();
      const from = startOfWeek(startOfMonth(today), { weekStartsOn: 1 });
      const to = endOfWeek(endOfMonth(today), { weekStartsOn: 1 });

      if (
        currentProvince
        //  && currentProvince.isSchedulesFetching === null
      ) {
        yield put(actions.handleLocationChange.fetchSchedules(currentProvince.id));

        try {
          ({
            items: schedules,
            included: { contents, attachments, contentCategories, contentLocations },
          } = yield call(request, api.getSchedules, currentProvince.id, { from, to }));
        } catch (error) {} // eslint-disable-line no-empty
      }

      break;
    }

    case Paths.STATIONS: {
      const currentProvince = yield select(selectors.selectCurrentProvince);

      if (currentProvince && currentProvince.isStationsFetching === null) {
        yield put(actions.handleLocationChange.fetchStations(currentProvince.id));
        province = { id: currentProvince.id };

        try {
          ({
            items: stations,
            included: { total },
          } = yield call(request, api.getStations, currentProvince.id, {
            page: 1,
            limit: Config.LIMIT_PER_PAGE,
          }));
        } catch (error) {} // eslint-disable-line no-empty
      }

      break;
    }

    case Paths.REPORTS: {
      const currentProvince = yield select(selectors.selectCurrentProvince);

      if (currentProvince && currentProvince.isReportsFetching === null) {
        yield put(actions.handleLocationChange.fetchReports(currentProvince.id));
        province = { id: currentProvince.id };

        try {
          ({
            included: { devices, stations, contentCategories, contents },
          } = yield call(request, api.getReports, currentProvince.id, {}));
        } catch (error) {} // eslint-disable-line no-empty
      }

      break;
    }

    case Paths.SETTING_USERS:
      {
        const currentUser = yield select(selectors.selectCurrentUser);
        if (!currentUser.isAdmin) {
          yield call(goToRoot);
        }
      }
      break;

    case Paths.SETTING_USER_GROUPS: {
      const currentUser = yield select(selectors.selectCurrentUser);

      if (!currentUser.isAdmin) {
        yield call(goToRoot);
        return;
      }

      const currentProvince = yield select(selectors.selectCurrentProvince);

      if (currentProvince && currentProvince.isUserGroupsFetching === null) {
        yield put(actions.handleLocationChange.fetchUserGroups(currentProvince.id));
        province = { id: currentProvince.id };

        try {
          ({
            items: userGroups,
            included: { total },
          } = yield call(request, api.getUserGroups, { page: 1, limit: Config.LIMIT_PER_PAGE }));
        } catch (error) {} // eslint-disable-line no-empty
      }

      break;
    }

    case Paths.USER_LOG:
      {
        const currentProvince = yield select(selectors.selectCurrentProvince);

        if (currentProvince) {
          yield put(actions.handleLocationChange.fetchActions(currentProvince.id));
          province = { id: currentProvince.id };

          let actionTotal = 0;

          try {
            ({
              items: userLogs,
              included: {
                total: actionTotal,
                contents,
                stations,
                devices,
                users,
                contentCategories,
              },
            } = yield call(request, api.getActions, currentProvince.id, {
              page: 1,
              limit: Config.LIMIT_PER_PAGE,
            }));
          } catch (error) {} // eslint-disable-line no-empty

          province.actionTotal = actionTotal;
        }
      }

      break;

    default:
  }

  yield put(
    actions.handleLocationChange(
      district,
      ward,
      hamlet,
      users,
      provinces,
      districtMemberships,
      categories,
      contents,
      contentCategories,
      schedules,
      stations,
      devices,
      deviceMemberships,
      deviceContents,
      tasks,
      attachments,
      deletedNotifications,
      { ...province, total },
      contentLocations,
      contentSchedules,
      userGroups,
      userLogs,
    ),
  );
}

export default {
  goToRoot,
  goToProvince,
  goToDistrict,
  goToDevice,
  handleLocationChange,
};
