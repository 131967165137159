import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Header, Input, Message } from 'semantic-ui-react';
import { Trans, useTranslation } from 'react-i18next';
import qrCodeError from '../../assets/images/qr_code_error.jpg';

import styles from './QRCode.module.scss';

const BUTTONS = ['send', 'retrieveQRCode'];

const QRCode = React.memo(
  ({
    isSubmitting,
    isInitial,
    qrcode,
    message,
    onMessageDismiss,
    onAuthenticate,
    onRetrieveQRCode,
  }) => {
    const [t] = useTranslation();

    const codeField = useRef(null);

    const [code, setCode] = useState('');

    const [buttonSelected, setButtonSelected] = useState();

    const handleFieldChange = useCallback((_, { value }) => {
      setCode(value);
    }, []);

    const handleSubmit = useCallback(() => {
      const token = window.location.search.split('?')[1];

      if (!code) {
        codeField.current.focus();
        return;
      }

      setButtonSelected(BUTTONS[0]);

      onAuthenticate({ otp: code, token, authType: 'qrcode' });
    }, [code, onAuthenticate]);

    const handleRetrieveQRCode = useCallback(() => {
      const token = window.location.search.split('?')[1];

      setButtonSelected(BUTTONS[1]);
      onRetrieveQRCode(token);
    }, [onRetrieveQRCode]);

    useEffect(() => {
      codeField.current.select();
    }, []);

    return (
      <>
        <Header
          as="h1"
          textAlign="left"
          content={t('common.verifyInformation')}
          className={styles.formTitle}
        />
        {isInitial && (
          <>
            <p style={{ textAlign: 'center' }}>
              <Trans
                i18nKey="common.scanQrcodeBelowToGetTheLoginCodeByUsingGoogleAuthenticator"
                components={{
                  0: <strong />,
                  1: <br />,
                  2: <strong className={styles.notice} />,
                  3: <strong className={styles.notice} style={{ color: '#2185d0' }} />,
                }}
              />
            </p>
            <div style={{ textAlign: 'center' }}>
              <div className={styles.inputLabel}>{t('common.qrCode')}</div>
              <img
                style={{ textAlign: 'center' }}
                src={qrcode}
                alt="qr-code-wowspeaker"
                width="200px"
                height="200px"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = qrCodeError;
                }}
              />
            </div>
          </>
        )}
        <div>
          {message && (
            <Message
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...{
                [message.type]: true,
              }}
              visible
              content={t(message.content)}
              onDismiss={onMessageDismiss}
            />
          )}
          <Form size="large" onSubmit={handleSubmit}>
            <div className={styles.inputWrapper}>
              <Input
                fluid
                ref={codeField}
                placeholder={t('common.enterOtpCode')}
                name="code"
                value={code}
                readOnly={isSubmitting}
                className={styles.input}
                onChange={handleFieldChange}
              />
            </div>
            <Button
              size="large"
              fluid
              content={t(`action.${BUTTONS[0]}`)}
              loading={isSubmitting && buttonSelected === BUTTONS[0]}
              disabled={isSubmitting && buttonSelected !== BUTTONS[0]}
              className={styles.button}
            />
            <Button
              size="large"
              fluid
              type="button"
              content={t(`action.${BUTTONS[1]}`)}
              onClick={handleRetrieveQRCode}
              loading={isSubmitting && buttonSelected === BUTTONS[1]}
              disabled={isSubmitting && buttonSelected !== BUTTONS[1]}
              className={styles.button}
            />
          </Form>
        </div>
      </>
    );
  },
);

export default QRCode;

QRCode.propTypes = {
  message: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isSubmitting: PropTypes.bool.isRequired,
  isInitial: PropTypes.bool,
  qrcode: PropTypes.string,
  onMessageDismiss: PropTypes.func.isRequired,
  onAuthenticate: PropTypes.func.isRequired,
  onRetrieveQRCode: PropTypes.func.isRequired,
};

QRCode.defaultProps = {
  message: undefined,
  qrcode: null,
  isInitial: null,
};
