import EntryActionTypes from '../constants/EntryActionTypes';

const createManagerInCurrentProvince = (data) => ({
  type: EntryActionTypes.MANAGER_IN_CURRENT_PROVINCE_CREATE,
  payload: {
    data,
  },
});

const handleProvinceManagerCreate = (provinceManager) => ({
  type: EntryActionTypes.PROVINCE_MANAGER_CREATE_HANDLE,
  payload: {
    provinceManager,
  },
});

const deleteProvinceManager = (id) => ({
  type: EntryActionTypes.PROVINCE_MANAGER_DELETE,
  payload: {
    id,
  },
});

const handleProvinceManagerDelete = (provinceManager) => ({
  type: EntryActionTypes.PROVINCE_MANAGER_DELETE_HANDLE,
  payload: {
    provinceManager,
  },
});

export default {
  createManagerInCurrentProvince,
  handleProvinceManagerCreate,
  deleteProvinceManager,
  handleProvinceManagerDelete,
};
