import socket from './socket';
import http from './http';
import { transformDevice } from './devices';
import { transformAttachment } from './attachments';

/* Actions */

const createHamlet = (wardId, data, headers) =>
  socket.post(`/wards/${wardId}/hamlets`, data, headers);

const createHamletWithImport = (wardId, data, requestId, headers) =>
  http.post(`/wards/${wardId}/hamlets?requestId=${requestId}`, data, headers);

const getHamlet = (id, subscribe, headers) =>
  socket
    .get(`/hamlets/${id}${subscribe ? '?subscribe=true' : ''}`, undefined, headers)
    .then((body) => ({
      ...body,
      included: {
        ...body.included,
        devices: body.included.devices.map(transformDevice),
        attachments: body.included.attachments.map(transformAttachment),
      },
    }));

const updateHamlet = (id, data, headers) => socket.patch(`/hamlets/${id}`, data, headers);

const deleteHamlet = (id, headers) => socket.delete(`/hamlets/${id}`, undefined, headers);

export default {
  createHamlet,
  createHamletWithImport,
  getHamlet,
  updateHamlet,
  deleteHamlet,
};
