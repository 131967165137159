import { attr, fk } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

export default class extends BaseModel {
  static modelName = 'ProvinceManager';

  static fields = {
    id: attr(),
    provinceId: fk({
      to: 'Province',
      as: 'province',
      relatedName: 'managers',
    }),
    userId: fk({
      to: 'User',
      as: 'user',
      relatedName: 'provinceManagers',
    }),
  };

  static reducer({ type, payload }, ProvinceManager) {
    switch (type) {
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        ProvinceManager.all().delete();

        payload.provinceManagers.forEach((provinceManager) => {
          ProvinceManager.upsert(provinceManager);
        });

        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROVINCE_CREATE__SUCCESS:
      case ActionTypes.PROVINCE_CREATE_HANDLE:
        payload.provinceManagers.forEach((provinceManager) => {
          ProvinceManager.upsert(provinceManager);
        });

        break;
      case ActionTypes.USER_CREATE__SUCCESS:
      case ActionTypes.PROVINCE_MANAGER_CREATE:
        ProvinceManager.upsert(payload.provinceManager);

        break;
      case ActionTypes.PROVINCE_MANAGER_CREATE__SUCCESS:
        ProvinceManager.withId(payload.localId).delete();
        ProvinceManager.upsert(payload.provinceManager);

        break;
      case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE:
        ProvinceManager.upsert(payload.provinceManager);

        if (payload.provinceManagers) {
          payload.provinceManagers.forEach((provinceManager) => {
            ProvinceManager.upsert(provinceManager);
          });
        }

        break;
      case ActionTypes.PROVINCE_MANAGER_DELETE:
        ProvinceManager.withId(payload.id).delete();

        break;
      case ActionTypes.PROVINCE_MANAGER_DELETE__SUCCESS:
      case ActionTypes.PROVINCE_MANAGER_DELETE_HANDLE: {
        const provinceManagerModel = ProvinceManager.withId(payload.provinceManager.id);

        if (provinceManagerModel) {
          provinceManagerModel.delete();
        }

        break;
      }
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE:
        if (payload.provinceManagers) {
          payload.provinceManagers.forEach((provinceManager) => {
            ProvinceManager.upsert(provinceManager);
          });
        }

        break;
      default:
    }
  }
}
