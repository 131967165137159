import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';

import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { format, endOfMonth, endOfWeek, parse, startOfMonth, startOfWeek, getDay } from 'date-fns';
import vi from 'date-fns/locale/vi';
import { RRule } from 'rrule';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';

import { EVENT_COLORS } from '../../constants/Enums';

import ScheduleModalContainer from '../../containers/ScheduleModalContainer';
import CustomToolbar from './CustomToolbar';

import styles from './Schedules.module.scss';

const DragAndDropCalendar = withDragAndDrop(Calendar);

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  startOfMonth,
  getDay,
  locales: {
    vi,
  },
});

// // example implementation of a wrapper
// function ColoredDateCellWrapper(props) {
//   console.log(props);
//   const style = {
//     display: 'flex',
//     flex: 1,
//     borderLeft: '1px solid #DDD',
//     // backgroundColor: '#f5f5dc',
//   };
//   return (
//     <div style={style}>
//       <span style={{ fontSize: '8px' }}>(12)</span>
//       <span>{props.children}</span>
//     </div>
//   );
// }

function EventCellWrapper({ children }) {
  return (
    // eslint-disable-next-line  jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()}>{children}</div>
  );
}

EventCellWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const Schedules = React.memo(
  ({ slug, items, onCreate, onFetch, onUpdate, onDelete, onRefetchContents }) => {
    const contextRef = useRef();
    const timeoutRef = useRef();
    const [view, setView] = useState(Views.MONTH);
    const [dateRange, setDateRange] = useState();

    const [isScheduleModalOpened, setScheduleModalOpened] = useState(false);
    const [slots, setSlots] = useState();
    const [isSelectable, setSelectable] = useState(true);
    const [draggedEvent, setDraggedEvent] = useState();

    const events = useMemo(() => {
      const x = [
        ...items.map((it) => ({ ...it, title: `${it.title}${it.ward ? ` - ${it.ward}` : ''}` })),
      ];
      let from;
      let to;
      // console.log(dateRange);
      if (dateRange) {
        ({ from, to } = dateRange);
        // console.log('from, to1', from, to);
      } else {
        const today = new Date();
        from = startOfWeek(startOfMonth(today), { weekStartsOn: 1 });
        to = endOfWeek(endOfMonth(today), { weekStartsOn: 1 });
        // console.log('from, to2', from, to);
      }

      items.forEach((it) => {
        if (it.repeatRule) {
          const rr = RRule.fromString(it.repeatRule);
          // console.log(rr.toText());
          rr.between(from, to).forEach((ev) => {
            // console.log(ev);
            const d = new Date(ev);
            if (it.start.toDateString() !== d.toDateString()) {
              const { start, end } = it;
              const s = new Date(start);
              const e = new Date(end);
              s.setFullYear(d.getFullYear(), d.getMonth(), d.getDate());
              e.setFullYear(d.getFullYear(), d.getMonth(), d.getDate());
              x.push({ ...it, start: s, end: e });
            }
          });
        }
      });
      return x;
    }, [dateRange, items]);

    const handleContentModalOpen = useCallback(() => {
      setScheduleModalOpened(true);
    }, []);

    const handleContentModalClose = useCallback(() => {
      setScheduleModalOpened(false);
      setSelectable(true);
    }, []);

    const handleSetStyleModal = useCallback((value) => {
      setSlots((prev) => ({ ...prev, style: value }));
    }, []);

    const moveEvent = useCallback(
      ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
        // const { allDay } = event;
        // if (!allDay && droppedOnAllDaySlot) {
        //   event.allDay = true;
        // }
        // if (start === end) {
        //   end.setMilliseconds(-1);
        // }
        if (end?.getDate() !== start?.getDate()) {
          end.setDate(start?.getDate());
        }
        // console.log(start, end);

        let repeatRule = null;
        console.log('event.repeatRule', event.repeatRule);
        if (event.repeatRule) {
          const rr = RRule.fromString(event.repeatRule);
          rr.options.dtstart = start;
          rr.origOptions.dtstart = start;
          repeatRule = rr.toString();
        }

        onUpdate(event.id, { start, end, repeatRule });
      },
      [onUpdate],
    );

    const resizeEvent = useCallback(({ event, start, end }) => {
      // setMyEvents((prev) => {
      //   const existing = prev.find((ev) => ev.id === event.id) ?? {};
      //   const filtered = prev.filter((ev) => ev.id !== event.id);
      //   return [...filtered, { ...existing, start, end }];
      // });
    }, []);

    const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent]);

    const dragRowSetStyle = useCallback((mode) => {
      clearTimeout(timeoutRef.current);
      const dragRow = document.querySelector('.rbc-addons-dnd-drag-row');

      if (dragRow) {
        if (mode === 'hidden') {
          dragRow.style.visibility = mode;
        } else {
          timeoutRef.current = setTimeout(() => {
            dragRow.style.visibility = mode;
          }, 200);
        }
      }
    }, []);

    const handleDragStart = useCallback((event) => {
      setDraggedEvent(event);
    }, []);

    const handleOnDropFromOutside = useCallback(
      ({ start, end }) => {
        if (!draggedEvent) return;

        if (start.getDate() !== draggedEvent.start.getDate()) {
          draggedEvent.start.setDate(start.getDate());
          draggedEvent.end.setDate(end.getDate());

          moveEvent({
            event: draggedEvent,
            start: draggedEvent.start,
            end: draggedEvent.end,
          });
        }
        setDraggedEvent();
        dragRowSetStyle('hidden');
      },
      [draggedEvent, dragRowSetStyle, moveEvent],
    );

    const handleSelectSlot = useCallback(({ start, end, ...rest }) => {
      // console.log('handleSelectSlot', rest);
      const now = new Date();
      const hour = now.getHours();
      const minute = now.getMinutes();
      const [st, en] = [start, end];
      st.setHours(hour, minute);
      en.setHours(hour, minute);

      const subtractSidebar = window.innerWidth <= 1223 ? 210 : 0;
      const distanceBelow = window.innerWidth <= 767 ? 150 : 0;
      let x = 0;
      let y = 0;
      if (rest.bounds) {
        x = rest.bounds.x;
        y = rest.bounds.y;
      } else {
        x = rest.box.x;
        y = rest.box.y;
      }
      if (contextRef.current.offsetWidth - 400 + 260 - subtractSidebar < x) {
        x -= 400;
      }
      if (contextRef.current.offsetHeight - 380 - distanceBelow + 70 < y) {
        y = contextRef.current.offsetHeight - 380 - distanceBelow + 70;
      }

      setSlots({ style: { left: Math.abs(x), top: y }, event: { start: st, end: en } });

      setScheduleModalOpened(true);
    }, []);

    const handleSelectEvent = useCallback(
      (event, { clientX, clientY }) => {
        // console.log(event);
        const subtractSidebar = window.innerWidth < 1223 ? 210 : 0;
        let x = clientX;
        let y = clientY;
        if (contextRef.current.offsetWidth - 400 + 260 - subtractSidebar < x) {
          x -= 400;
        }
        if (contextRef.current.offsetHeight - 380 + 70 < y) {
          y -= 190;
        }
        setSlots({ style: { left: Math.abs(x), top: y }, event });

        setSelectable(false);
        if (event.id !== slots?.event.id) {
          setScheduleModalOpened(true);
        } else {
          setScheduleModalOpened(!isScheduleModalOpened);
          if (!isSelectable) setSelectable(true);
        }
      },
      [isSelectable, isScheduleModalOpened, slots?.event.id],
    );

    // const handleDrillDown = useCallback((newDate) => {
    //   setDate(newDate);
    //   setView(Views.DAY);
    //   setScheduleModalOpened(false);
    // }, []);

    const onNavigate = useCallback(
      (newDate, newView) => {
        // console.log('onNavigate', newDate, newView);
        // console.log('onNavigate', startOfMonth(newDate));
        const from = startOfWeek(startOfMonth(newDate), { weekStartsOn: 1 });
        const to = endOfWeek(endOfMonth(newDate), { weekStartsOn: 1 });

        // console.log(from, to);

        setDateRange({ from, to });
        // console.log('from, to3', { from, to });
        onFetch({ from, to });

        // setDate(newDate);
        // setScheduleModalOpened(false);
      },
      [onFetch],
    );
    // const onView = useCallback(
    //   (newView) => {
    //     setView(newView);
    //     setScheduleModalOpened(false);
    //   },
    //   [setView],
    // );

    const onRangeChange = useCallback(
      (range, view1) => {
        // console.log('range', range);

        let from;
        let to;
        if (range.end) {
          // from = new Date(range.start);
          // from.setDate(from.getDate() + 1);
          from = startOfWeek(range.start, { weekStartsOn: 1 });
          // from.setDate(from.getDate() + 1);
          // to = range.end;
          to = endOfWeek(range.end, { weekStartsOn: 1 });
          // to.setDate(to.getDate() + 1);
        } else if (range.length >= 2) {
          from = new Date(range[0]);
          from.setDate(from.getDate() + 1);
          to = new Date(range[range.length - 1]);
          to.setDate(to.getDate() + 1);
          to.setHours(23, 59, 59);
        } else if (range.length === 1) {
          from = new Date(range[0]);
          to = new Date(range[0]);
          to.setHours(23, 59, 59);
        }
        setDateRange({ from, to });
        // console.log('from, to3', { from, to });
        onFetch({ from, to });
      },
      [onFetch],
    );

    const { defaultDate, scrollToTime } = useMemo(
      () => ({
        defaultDate: new Date(),
        scrollToTime: new Date(1970, 1, 1, 6),
      }),
      [],
    );

    const eventPropGetter = useCallback(
      (event, start, end, isSelected) => ({
        ...(isSelected && {
          style: {
            color: '#000',
          },
        }),
        style: {
          // backgroundColor: event?.color,
          backgroundColor: EVENT_COLORS[event.status],
          border: '1px solid #fff',
          ...(event.playStatus === 'playing' && {
            backgroundColor: '#21ba45',
          }),
          // borderRadius: '0px',
          // border: 'none',
          // width: '100%',
        },
      }),
      [],
    );

    return (
      <div id="schedule-dnd-calendar" className={styles.wrapper} ref={contextRef}>
        <DragAndDropCalendar
          defaultDate={defaultDate}
          // date={date}
          defaultView={Views.MONTH}
          // view={view}
          // views={[Views.MONTH, Views.WEEK, Views.AGENDA]}
          dayLayoutAlgorithm="no-overlap"
          longPressThreshold={50}
          localizer={localizer}
          culture="vi"
          events={events}
          step={10}
          timeslots={1}
          cl
          // startAccessor="start"
          // endAccessor="end"
          style={{ height: 'calc(100vh - 70px)' }}
          // onEventDrop={moveEvent}
          onEventResize={resizeEvent}
          onSelectEvent={handleSelectEvent}
          // onSelectSlot={handleSelectSlot}
          handleDragStart={handleDragStart}
          // dragFromOutsideItem={dragFromOutsideItem}
          // onDropFromOutside={handleOnDropFromOutside}
          // onDrillDown={handleDrillDown}
          onNavigate={onNavigate}
          // onView={onView}
          // onRangeChange={onRangeChange}
          // onDragStart={() => dragRowSetStyle('visible')}
          popup
          // popupOffset={{ x: 0, y: -720 }}
          resizable={false}
          selectable={false}
          scrollToTime={scrollToTime}
          components={{
            // dateCellWrapper: ColoredDateCellWrapper,
            eventWrapper: EventCellWrapper,
            /* eslint-disable  react/no-unstable-nested-components, react/jsx-props-no-spreading */
            toolbar: (toolbarProps) => (
              <CustomToolbar {...toolbarProps} onRefetchContents={onRefetchContents} slug={slug} />
            ),
          }}
          // showAllEvents
          messages={{
            next: '>',
            previous: '<',
            today: 'Hôm nay',
            yesterday: 'Hôm qua',
            tomorrow: 'Ngày mai',
            month: 'Tháng',
            week: 'Tuần',
            day: 'Ngày',
            agenda: 'Bản tin',
            work_week: '5 ngày',
            date: 'Ngày',
            time: 'Thời gian',
            event: 'Bản tin',
            allDay: 'Cả ngày',
            noEventsInRange: 'Không có lịch phát nào ngày này.',
            showMore: (total) => `+${total} tin`,
          }}
          eventPropGetter={eventPropGetter}
        />
        {/* <AddPopup onCreate={onCreate}>
        <Button circular icon="plus" color="green" className={styles.addButton} />
      </AddPopup> */}
        {isScheduleModalOpened && (
          <ScheduleModalContainer
            onClose={handleContentModalClose}
            slots={slots}
            onSetStyleModal={handleSetStyleModal}
          />
        )}
        {/* {isScheduleModalOpened && (
        <AddPopup open context={contextRef} onCreate={onCreate} onClose={handleContentModalClose}>
          <Button circular icon="plus" color="green" className={styles.addButton} />
        </AddPopup>
      )} */}
      </div>
    );
  },
);

Schedules.propTypes = {
  slug: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  // currentId: PropTypes.string,
  // canEdit: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
  // onMove: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRefetchContents: PropTypes.func.isRequired,
};

Schedules.defaultProps = {
  // currentId: undefined,
};

export default Schedules;
