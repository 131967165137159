import { createSelector } from 'redux-orm';

import orm from '../orm';

export const makeSelectCategoryById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Category }, id) => {
      const categoryModel = Category.withId(id);

      if (!categoryModel) {
        return categoryModel;
      }

      return categoryModel.ref;
    },
  );

export const selectCategoryById = makeSelectCategoryById();

export const makeSelectAttachmentsByCategoryId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Attachment }, id) =>
      Attachment.all()
        .toRefArray()
        .filter(({ categoryId }) => categoryId === id),
  );

export default {
  makeSelectCategoryById,
  selectCategoryById,
  // selectNextCategoryPosition,
};
