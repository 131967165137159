import { RRule } from 'rrule';

export const ProvinceBackgroundTypes = {
  GRADIENT: 'gradient',
  IMAGE: 'image',
};

export const DistrictMembershipRoles = {
  EDITOR: 'editor',
  VIEWER: 'viewer',
};

export const WardMembershipRoles = {
  EDITOR: 'editor',
  VIEWER: 'viewer',
};

export const ActivityTypes = {
  CREATE_DEVICE: 'createDevice',
  CREATE_CONTENT: 'createContent',
  CREATE_STATION: 'createStation',
  CREATE_SCHEDULE: 'createSchedule',
  CREATE_USER: 'createUser',
  CREATE_USER_GROUP: 'createUserGroup',
  UPDATE_DEVICE: 'updateDevice',
  UPDATE_CONTENT: 'updateContent',
  UPDATE_CONTENT_LOCATION: 'updateContentLocation',
  UPDATE_STATION: 'updateStation',
  UPDATE_SCHEDULE: 'updateSchedule',
  UPDATE_USER_GROUP: 'updateUserGroup',
  DELETE_CONTENT: 'deleteContent',
  DELETE_CONTENT_LOCATION: 'deleteContentLocation',
  DELETE_DEVICE: 'deleteDevice',
  DELETE_SCHEDULE: 'deleteSchedule',
  DELETE_STATION: 'deleteStation',
  DELETE_USER: 'deleteUser',
  DELETE_USER_GROUP: 'deleteUserGroup',
  MOVE_DEVICE: 'moveDevice',
  COMMENT_DEVICE: 'commentDevice',
  CONTROL_DEVICE: 'controlDevice',
  CONTROL_MULTI_DEVICE: 'controlMultiDevice',
  CANCEL_SCHEDULE: 'cancelSchedule',
};

export const StatusOptions = [
  {
    key: 'draft',
    value: 'draft',
    color: 'grey',
  },
  {
    key: 'new',
    value: 'new',
    color: 'grey',
  },
  {
    key: 'unapproved',
    value: 'unapproved',
    color: 'yellow',
  },
  {
    key: 'approved',
    value: 'approved',
    color: 'blue',
  },
  // {
  //   key: 'publish',
  //   value: 'publish',
  //   color: 'green',
  // },

  {
    key: 'playing',
    value: 'playing',
    color: 'teal',
  },
  {
    key: 'played',
    value: 'played',
    color: 'orange',
  },
  {
    key: 'canceled',
    value: 'canceled',
    color: 'red',
  },
  // {
  //   key: 'deleted',
  //   value: 'deleted',
  //   color: 'red',
  // },
];

export const DeviceOptions = [
  {
    key: 'speaker',
    value: 'speaker',
    text: 'speaker',
  },
  {
    key: 'screen',
    value: 'screen',
    text: 'screen',
  },
  {
    key: 'integratedDevice',
    value: 'integratedDevice',
    text: 'integratedDevice',
  },
];

export const DeviceStatusOptions = [
  // {
  //   key: 'active',
  //   value: 'active',
  //   color: 'green',
  // },
  // {
  //   key: 'disconnect',
  //   value: 'disconnect',
  //   color: 'red',
  // },
  // {
  //   key: 'pause',
  //   value: 'pause',
  //   color: 'yellow',
  // },
  // {
  //   key: 'deleted',
  //   value: 'deleted',
  //   color: 'grey',
  // },
  {
    key: 'new',
    value: 'new',
    color: 'blue',
  },
  {
    key: 'transmitting',
    value: 'transmitting',
    color: 'blue',
  },
  {
    key: 'transmissionPaused',
    value: 'transmissionPaused',
    color: 'yellow',
  },
  {
    key: 'transmissionOff',
    value: 'transmissionOff',
    color: 'red',
  },
];

export const DeviceConnectStatusOptionsColors = {
  '3G': 'green',
  '4G': 'teal',
  '5G': 'violet',
  Ethernet: 'purple',
  Wifi: 'blue',
  'Mất kết nối': 'red',
};

export const ScheduleStatusOptions = [
  {
    key: 'active',
    value: 'active',
    color: 'green',
  },
  {
    key: 'pause',
    value: 'pause',
    color: 'grey',
  },
  {
    key: 'deleted',
    value: 'deleted',
    color: 'grey',
  },
];

export const ScheduleLogStatusOptions = [
  {
    key: 'playing',
    value: 'playing',
    color: 'green',
  },
  {
    key: 'pending',
    value: 'pending',
    color: 'grey',
  },
  {
    key: 'finish',
    value: 'finish',
    color: 'teal',
  },
];

export const RepeatOptions = [
  {
    key: 'norepeat',
    value: null,
    text: 'noRepeat',
  },
  {
    key: 'daily',
    value: 'daily',
    text: 'daily',
  },
  {
    key: 'weekly',
    value: 'weekly',
    text: 'weekly',
  },
  {
    key: 'monthly',
    value: 'monthly',
    text: 'monthly',
  },
  {
    key: 'yearly',
    value: 'yearly',
    text: 'yearly',
  },
  {
    key: 'custom',
    value: 'custom',
    text: 'custom',
  },
];
export const WEEKS = ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'];
export const BYWEEKDAYS = {
  T2: RRule.MO,
  T3: RRule.TU,
  T4: RRule.WE,
  T5: RRule.TH,
  T6: RRule.FR,
  T7: RRule.SA,
  CN: RRule.SU,
};
/*
export declare enum Frequency {
    YEARLY = 0,
    MONTHLY = 1,
    WEEKLY = 2,
    DAILY = 3,
    HOURLY = 4,
    MINUTELY = 5,
    SECONDLY = 6
}
*/
export const FREQS = {
  daily: RRule.DAILY,
  weekly: RRule.WEEKLY,
  monthly: RRule.MONTHLY,
  yearly: RRule.YEARLY,
};

export const CHECK_TYPES = {
  never: 'never',
  inDate: 'inDate',
  after: 'after',
};

export const ContentTypesOptions = [
  {
    key: 'text',
    value: 'text',
  },
  {
    key: 'stream',
    value: 'stream',
  },
  {
    key: 'fileMP3',
    value: 'file',
  },
];

export const ContentTypeOptions = [
  {
    key: 'text',
    value: 'text',
  },
  {
    key: 'image',
    value: 'image',
  },
  {
    key: 'audio',
    value: 'audio',
  },
  {
    key: 'video',
    value: 'video',
  },
  {
    key: 'stream',
    value: 'stream',
  },
];

export const TotalPageKeys = {
  contents: 'contentTotal',
  stations: 'stationTotal',
  devices: 'deviceTotal',
  userGroups: 'userGroupTotal',
  actions: 'actionTotal',
};

export const CustomOptions = [
  { text: 'date', type: 'daily', count: 30 },
  { text: 'week', type: 'weekly', count: 13 },
  { text: 'month', type: 'monthly', count: 12 },
  { text: 'year', type: 'yearly', count: 5 },
];

export const UnitTypes = {
  DISTRICT: {
    title: 'common.createDistrict',
    editTitle: 'common.editDistrict',
    deleteTitle: 'common.deleteDistrict',
    name: 'common.nameDistrict',
    content: 'common.areYouSureYouWantToDeleteThisDistrict',
  },
  WARD: {
    title: 'common.createWard',
    editTitle: 'common.editWard',
    deleteTitle: 'common.deleteWard',
    name: 'common.nameWard',
    content: 'common.areYouSureYouWantToDeleteThisWard',
  },
  HAMLET: {
    title: 'common.createHamlet',
    editTitle: 'common.editHamlet',
    deleteTitle: 'common.deleteHamlet',
    name: 'common.nameHamlet',
    content: 'common.areYouSureYouWantToDeleteThisHamlet',
  },
};

export const PriorityTypes = [
  {
    key: 'normal',
    value: 3,
    text: 'normal',
  },
  {
    key: 'priority',
    value: 2,
    text: 'priority',
  },
  {
    key: 'urgent',
    value: 1,
    text: 'urgent',
  },
];

export const SourceTypes = [
  {
    key: 'normal',
    value: 'normal',
    text: 'normal',
  },
  {
    key: 'microphone',
    value: 'microphone',
    text: 'microphone',
  },
];

export const NoticeMessages = {
  noContent: 'common.noAudioContent',
  cannotClose: 'common.windowCannotBeClosedWhileContentIsPlaying',
  notEnoughInfor: 'common.notEnoughInformation',
  chooseArea: 'common.pleaseChooseBroadcastArea',
  noScheduleName: 'common.scheduleNameHasNotBeenEnterd',
};

export const MessageSchedule = {
  CONFLICT_SCHEDULE: [
    'common.selectedScheduleAlreadyExists',
    'common.pleaseChooseAnotherBroadcastSchedule',
  ],
  NO_SCHEDULE: [
    'common.broadcastScheduleHasNotBeenSelected',
    'common.pleaseChooseBroadcastSchedule',
  ],
  NO_AREA: ['common.receivingAreaHasNotBeenSelected', 'common.pleaseChooseBroadcastArea'],
};

export const SelectContentTypesOptions = [
  {
    key: 'broadcasting',
    value: 'broadcasting',
    text: 'broadcasting',
  },
  // {
  //   key: 'bulletinBoard',
  //   value: 'bulletinBoard',
  //   text: 'bulletinBoard',
  // },
];

export const BroadcastingZone = [
  {
    key: 'fullScreen',
    value: 0,
  },
  {
    key: 'notificationZone',
    value: 1,
  },
  {
    key: 'leftZone',
    value: 2,
  },
  {
    key: 'rightZone',
    value: 3,
  },
];

export const RelaySourceOptions = [
  {
    key: 'hasRelay',
    value: 0,
  },
  {
    key: 'noRelay',
    value: 1,
  },
];

export const EVENT_COLORS = {
  deleted: '#afb0a4',
  active: '#109dc0',
  pause: '#f7d036',
};

export const CONTROL_CODES = [
  { value: 'default', key: 'selectControl' },
  { value: 0, key: 'controlVolume' },
  { value: 1, key: 'wattage' },
  { value: 2, key: 'stopPlayingContent' },
  { value: 3, key: 'restartDevice' },
  { value: 4, key: 'versionUpgrade' },
];

export const WATTAGE_MODES = [
  { value: 0, key: 'turnOn' },
  { value: 1, key: 'turnOff' },
];

export const STOP_PLAYING_CONTENT = [
  { value: 0, key: 'stopAllContent' },
  { value: 1, key: 'stopsCurrentContentAndMoveToNextContentIfItExists' },
  { value: 2, key: 'pauseCurrentlyPlayingContent' },
  { value: 3, key: 'resumePreviouslyStoppedContent' },
];

export const USER_LEVELS = {
  PROVINCE: 'province',
  DISTRICT: 'district',
  WARD: 'ward',
};

export const USER_PERMISSIONS = {
  CAN_ADD: 'canAdd',
  CAN_EDIT: 'canEdit',
  CAN_DELETE: 'canDelete',
  CAN_VIEW: 'canView',
};
