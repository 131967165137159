import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import { Input, Popup } from '../../lib/custom-ui';

import { useForm } from '../../hooks';

import styles from './IdentifierSIMEditStep.module.scss';

const IdentifierSIMEditStep = React.memo(({ defaultValue, onUpdate, onBack, onClose }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange] = useForm(() => ({
    code: defaultValue.code || '',
    simNumber: defaultValue.simNumber || '',
  }));

  const identifierField = useRef(null);
  const simNumberField = useRef(null);

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      code: data.code.trim(),
      simNumber: data.simNumber.trim(),
    };

    if (!cleanData.code) {
      identifierField.current.select();
      return;
    }

    if (!cleanData.simNumber) {
      simNumberField.current.select();
      return;
    }

    onUpdate(cleanData);
    onClose();
  }, [onUpdate, onClose, data]);

  const handleClearClick = useCallback(() => {
    if (defaultValue) {
      onUpdate(null);
    }

    onClose();
  }, [defaultValue, onUpdate, onClose]);

  useEffect(() => {
    identifierField.current.select();
  }, []);

  return (
    <>
      <Popup.Header onBack={onBack}>
        {t('common.editIdentifierSIM', {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Form onSubmit={handleSubmit}>
          <div className={styles.fieldWrapper}>
            <div className={styles.fieldBox}>
              <div className={styles.text}>{t('common.identifier')}</div>
              <Input
                fluid
                // mask="999.999.999.999"
                // maskChar={null}
                name="code"
                onChange={handleFieldChange}
                ref={identifierField}
                value={data.code}
              />
            </div>
            <div className={styles.fieldBox}>
              <div className={styles.text}>{t('common.simNumber')}</div>
              <Input.Mask
                fluid
                mask="9999999999"
                maskChar={null}
                name="simNumber"
                onChange={handleFieldChange}
                ref={simNumberField}
                value={data.simNumber}
              />
            </div>
          </div>
          <Button positive content={t('action.save')} />
        </Form>
        <Button
          className={styles.deleteButton}
          content={t('action.remove')}
          negative
          onClick={handleClearClick}
        />
      </Popup.Content>
    </>
  );
});

IdentifierSIMEditStep.propTypes = {
  defaultValue: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onUpdate: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

IdentifierSIMEditStep.defaultProps = {
  defaultValue: undefined,
  onBack: undefined,
};

export default IdentifierSIMEditStep;
