import { createSelector } from 'redux-orm';

import orm from '../orm';
import { isLocalId } from '../utils/local-id';

export const makeSelectStationById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Station }, id) => {
      const stationModel = Station.withId(id);

      if (!stationModel) {
        return stationModel;
      }

      return {
        ...stationModel.ref,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const selectStationById = makeSelectStationById();

export const makeSelectDeviceIdsByStationId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Station }, id) => {
      const stationModel = Station.withId(id);

      if (!stationModel) {
        return stationModel;
      }

      return stationModel
        .getFilteredOrderedDevicesModelArray()
        .map((deviceModel) => deviceModel.id);
    },
  );

export const selectDeviceIdsByStationId = makeSelectDeviceIdsByStationId();

export default {
  makeSelectStationById,
  selectStationById,
  makeSelectDeviceIdsByStationId,
  selectDeviceIdsByStationId,
};
