import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Header, Tab } from 'semantic-ui-react';
import { usePopup } from '../../../lib/popup';

import InformationEdit from './InformationEdit';
import DeleteStep from '../../DeleteStep';

import styles from './GeneralPane.module.scss';

const GeneralPane = React.memo(({ name, code, error, canEdit, canDelete, onUpdate, onDelete }) => {
  const [t] = useTranslation();

  const DeletePopup = usePopup(DeleteStep);

  return (
    <Tab.Pane attached={false} className={styles.wrapper}>
      <InformationEdit
        defaultData={{
          name,
          code,
        }}
        canEdit={canEdit}
        onUpdate={onUpdate}
      />
      {canDelete && (
        <>
          <Divider horizontal section>
            <Header as="h4">
              {t('common.dangerZone', {
                context: 'title',
              })}
            </Header>
          </Divider>
          <div className={styles.action}>
            <DeletePopup
              title="common.deleteProvince"
              content={
                error ? 'common.locationInUse' : 'common.areYouSureYouWantToDeleteThisProvince'
              }
              buttonContent="action.deleteProvince"
              onConfirm={onDelete}
              disabled={!!error}
            >
              <Button className={styles.actionButton}>
                {t('action.deleteProvince', {
                  context: 'title',
                })}
              </Button>
            </DeletePopup>
          </div>
        </>
      )}
    </Tab.Pane>
  );
});

GeneralPane.propTypes = {
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  error: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

GeneralPane.defaultProps = {
  error: undefined,
};

export default GeneralPane;
