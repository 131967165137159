import { createSelector } from 'redux-orm';
import orm from '../orm';

export const selectActions = createSelector(orm, ({ Action }) => {
  return Action.all()
    .toModelArray()
    .map((actionModel) => ({
      ...actionModel.ref,
      user: actionModel.user?.name,
    }));
});

export const selectActionById = createSelector(
  orm,
  (_, id) => id,
  ({ Action }, id) => {
    if (!id) {
      return id;
    }
    const actionModel = Action.withId(id);

    if (!actionModel) {
      return actionModel;
    }

    return {
      ...actionModel.ref,
      user: actionModel.user?.ref,
      station: actionModel.station?.ref,
      content: actionModel.content?.ref,
      device: actionModel.device?.ref,
    };
  },
);

export default { selectActions, selectActionById };
