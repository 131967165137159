import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import ContentModal from '../components/ContentModal';
import { pickScheduleInLocations } from '../utils/content-method';

const mapStateToProps = (state) => {
  const {
    data: defaultData,
    isSubmitting,
    error,
    contentId,
    playlist,
    attachment,
  } = state.ui.contentCreateForm;
  const broadcast = state.ui.liveBroadcast;
  const { selectedContentId } = state.core;
  const { accessToken } = state.auth;

  const { provinceId } = selectors.selectPath(state);
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);
  const isDarkMode = selectors.selectIsDarkMode(state);
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const wards = selectors.selectWardsForCurrentProvince(state) || [];
  const hamlets = selectors.selectHamletsForCurrentProvince(state) || [];
  const schedules = selectors.selectSchedulesForCurrentProvince(state);
  const devices = selectors.selectDevicesForCurrentProvince(state);
  const province = selectors.selectCurrentProvince(state);
  const { permissions } = selectors.selectCurrentUser(state);
  const { contentId: selectedId } = selectors.selectPath(state);
  const allCategories = selectors.selectCategoriesForCurrentProvince(state);
  const currentArea = selectors.selectAreaForCurrentUser(state);

  let content;
  let attachments = [];
  let contentLocations;
  let contentSchedules = [];
  let existScheduleInWards = [];
  if (selectedId) {
    content = selectors.selectContentById(state, selectedId);
    if (content) {
      Object.keys(content).forEach((k) => content[k] == null && delete content[k]);

      attachments = selectors.selectAttachmentsByContentId(state, selectedId);
      content.categories = selectors
        .selectCategoriesByContentId(state, selectedId)
        .map((cat) => cat.id);

      contentLocations = selectors.selectLocationsByContentId(state, selectedId);
      contentSchedules = selectors.selectSchedulesByContentId(state, selectedId);
      if (contentSchedules.length > 0) {
        contentSchedules = contentSchedules.filter((it) => it.status === 'active');
      }

      const scheduleInWards = pickScheduleInLocations(contentLocations.wards, schedules, 'wardId');

      existScheduleInWards = scheduleInWards.filter(
        (it) => !contentSchedules.some((s) => s.id === it.id),
      );
    }
  }

  const isCurrentUserEditorOrCanComment = false;
  const isCurrentUserEditor = permissions.includes('CAN_EDIT');
  const duration = (defaultData.duration ? defaultData.duration : content?.duration) || 0;

  return {
    accessToken,
    currentArea,
    existScheduleInWards,
    broadcast,
    province: [province],
    contentLocations,
    contentSchedules,
    selectedContentId,
    playlist,
    defaultData: { ...defaultData, ...content, duration },
    isDarkMode,
    districts,
    wards,
    hamlets,
    schedules: selectedId
      ? schedules.filter((it) => it.contentId !== selectedId && it.status === 'active')
      : schedules,
    devices,
    provinceId,
    attachments,
    allCategories,
    isSubmitting,
    error,
    isEditing: selectedId !== undefined,
    contentId,
    attachment,
    canEdit: isCurrentUserEditor,
    canEditCommentActivities: isCurrentUserEditorOrCanComment,
    canEditAllCommentActivities: isCurrentUserManager,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createContentInCurrentProvince,
      onUpdate: entryActions.updateContent,
      onDelete: entryActions.deleteCurrentDevice,
      onContentMove: entryActions.moveContent,
      onContentDelete: entryActions.deleteContent,
      onText2Speech: entryActions.text2Speech,
      onAttachmentCreate: entryActions.createAttachmentToContent,
      onAttachmentUpdate: entryActions.updateAttachment,
      onAttachmentDelete: entryActions.deleteAttachment,
      onErrorClear: entryActions.clearContentError,
      onPlaylistClear: entryActions.clearContentPlaylist,
      onClearSelectedContentId: entryActions.selectContentId,
      onLiveBroadcast: entryActions.liveBroadcastStart,
      onLiveBroadcastStop: entryActions.liveBroadcastStop,
      onClearBroadcast: entryActions.clearBroadcast,
      onCloseMessageBroadcast: entryActions.closeMessageBroadcast,
      onControl: entryActions.controlDevices,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContentModal);
