import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  data: {
    emailOrUsername: '',
    password: '',
  },
  isSubmitting: false,
  expires: null,
  error: null,
  qrcode: null,
  isInitial: null,
  isRetrieveQRCode: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.AUTHENTICATE:
      return {
        ...state,
        isSubmitting: true,
      };
    case ActionTypes.AUTHENTICATE_CODE:
    case ActionTypes.AUTHENTICATE_QRCODE:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
        isSubmitting: true,
      };
    case ActionTypes.AUTHENTICATE_GET_QRCODE:
      return {
        ...state,
        qrcode: payload.qrcode,
        isInitial: payload.qrcode !== 'None',
      };
    case ActionTypes.AUTHENTICATE_CODE_RETRIEVE:
    case ActionTypes.AUTHENTICATE_QRCODE_RETRIEVE:
      return {
        ...state,
        isSubmitting: true,
      };
    case ActionTypes.AUTHENTICATE_CODE_RETRIEVE__SUCCESS:
      return {
        ...state,
        expires: payload.expires ? new Date(payload.expires) : null,
        isSubmitting: false,
      };
    case ActionTypes.AUTHENTICATE_QRCODE_RETRIEVE__SUCCESS:
      return {
        ...state,
        isRetrieveQRCode: payload.success,
        isSubmitting: false,
      };
    case ActionTypes.AUTHENTICATE_CODE__SUCCESS:
    case ActionTypes.AUTHENTICATE__SUCCESS:
      return initialState;
    case ActionTypes.AUTHENTICATE_QRCODE__SUCCESS:
      return {
        ...initialState,
        qrcode: payload.qrcode,
        isInitial: payload.isInitial,
      };
    case ActionTypes.AUTHENTICATE_GET_EXPIRES_CODE:
      return {
        ...state,
        expires: payload.expires ? new Date(payload.expires) : null,
      };
    case ActionTypes.AUTHENTICATE__FAILURE:
    case ActionTypes.AUTHENTICATE_CODE__FAILURE:
    case ActionTypes.AUTHENTICATE_QRCODE__FAILURE:
    case ActionTypes.AUTHENTICATE_CODE_RETRIEVE__FAILURE:
    case ActionTypes.AUTHENTICATE_QRCODE_RETRIEVE__FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: payload.error,
      };
    case ActionTypes.AUTHENTICATE_ERROR_CLEAR:
      return {
        ...state,
        error: null,
        isRetrieveQRCode: null,
      };
    case ActionTypes.AUTHENTICATE_COUNT_DOWN_CLEAR:
      return {
        ...state,
        expires: null,
      };
    default:
      return state;
  }
};
