import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* stationsWatchers() {
  yield all([
    takeEvery(EntryActionTypes.STATION_IN_CURRENT_DISTRICT_CREATE, ({ payload: { data } }) =>
      services.createStationInCurrentDistrict(data),
    ),
    takeEvery(EntryActionTypes.STATION_CREATE_HANDLE, ({ payload: { station } }) =>
      services.handleStationCreate(station),
    ),
    takeEvery(EntryActionTypes.STATION_UPDATE, ({ payload: { id, data } }) =>
      services.updateStation(id, data),
    ),
    takeEvery(EntryActionTypes.STATION_UPDATE_HANDLE, ({ payload: { station } }) =>
      services.handleStationUpdate(station),
    ),
    takeEvery(EntryActionTypes.STATION_MOVE, ({ payload: { id, index } }) =>
      services.moveStation(id, index),
    ),
    takeEvery(EntryActionTypes.STATION_DELETE, ({ payload: { id } }) => services.deleteStation(id)),
    takeEvery(EntryActionTypes.STATION_DELETE_HANDLE, ({ payload: { station } }) =>
      services.handleStationDelete(station),
    ),
    takeEvery(EntryActionTypes.STATION_ERROR_CLEAR, () => services.clearStationError()),
    takeEvery(EntryActionTypes.STATIONS_FETCH, ({ payload: { page, limit, districtIds } }) =>
      services.fetchStations({ page, limit, districtIds }),
    ),
  ]);
}
