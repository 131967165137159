import { createSelector } from 'redux-orm';

import orm from '../orm';
import { selectPath } from './router';
import { selectCurrentUserId } from './users';
import { isLocalId } from '../utils/local-id';

export const selectCurrentProvince = createSelector(
  orm,
  (state) => selectPath(state).provinceId,
  ({ Province }, id) => {
    if (!id) {
      return id;
    }

    const provinceModel = Province.withId(id);

    if (!provinceModel) {
      return provinceModel;
    }

    return provinceModel.ref;
  },
);

export const selectManagersForCurrentProvince = createSelector(
  orm,
  (state) => selectPath(state).provinceId,
  (state) => selectCurrentUserId(state),
  ({ Province }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const provinceModel = Province.withId(id);

    if (!provinceModel) {
      return provinceModel;
    }

    return provinceModel
      .getOrderedManagersQuerySet()
      .toModelArray()
      .map((provinceManagerModel) => ({
        ...provinceManagerModel.ref,
        isPersisted: !isLocalId(provinceManagerModel.id),
        user: {
          ...provinceManagerModel.user?.ref,
          isCurrent: provinceManagerModel.user?.id === currentUserId,
        },
      }));
  },
);

export const selectDistrictsForCurrentProvince = createSelector(
  orm,
  (state) => selectPath(state).provinceId,
  (state) => selectCurrentUserId(state),
  ({ Province }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const provinceModel = Province.withId(id);

    if (!provinceModel) {
      return provinceModel;
    }

    return provinceModel
      .getOrderedDistrictsModelArrayAvailableForUser(currentUserId)
      .map((districtModel) => ({
        ...districtModel.ref,
        wardTotal: districtModel.wards.toRefArray().length,
        isPersisted: !isLocalId(districtModel.id),
      }));
  },
);

export const selectWardsForCurrentProvince = createSelector(
  orm,
  (state) => selectPath(state).provinceId,
  (state) => selectCurrentUserId(state),
  ({ Province }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const provinceModel = Province.withId(id);

    if (!provinceModel) {
      return provinceModel;
    }

    return provinceModel
      .getOrderedWardsModelArrayAvailableForUser(currentUserId)
      .map((wardModel) => ({
        ...wardModel.ref,
        stationTotal: wardModel.stations.toRefArray().length,
        hamletTotal: wardModel.hamlets.toRefArray().length,
        devices: wardModel.devices.toRefArray(),
        isPersisted: !isLocalId(wardModel.id),
      }));
  },
);

export const selectHamletsForCurrentProvince = createSelector(
  orm,
  (state) => selectPath(state).provinceId,
  (state) => selectCurrentUserId(state),
  ({ Province }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const provinceModel = Province.withId(id);

    if (!provinceModel) {
      return provinceModel;
    }

    return provinceModel
      .getOrderedHamletsModelArrayAvailableForUser(currentUserId)
      .map((hamletModel) => ({
        ...hamletModel.ref,
        isPersisted: !isLocalId(hamletModel.id),
      }));
  },
);

export const selectCategoriesForCurrentProvince = createSelector(
  orm,
  (state) => selectPath(state).provinceId,
  (state) => selectCurrentUserId(state),
  ({ Province }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const provinceModel = Province.withId(id);

    if (!provinceModel) {
      return provinceModel;
    }

    return provinceModel
      .getOrderedCategoriesQuerySet()
      .toRefArray()
      .map((content) => ({
        ...content,
        isPersisted: !isLocalId(content.id),
      }));
  },
);

export const selectStationsForCurrentProvince = createSelector(
  orm,
  (state) => selectPath(state).provinceId,
  (state) => selectCurrentUserId(state),
  ({ Province }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const provinceModel = Province.withId(id);

    if (!provinceModel) {
      return provinceModel;
    }

    return provinceModel
      .getOrderedStationsModelArrayAvailableForUser(currentUserId)
      .map((stationModel) => ({
        ...stationModel.ref,
        isPersisted: !isLocalId(stationModel.id),
      }));
  },
);

export const selectDevicesForCurrentProvince = createSelector(
  orm,
  (state) => selectCurrentUserId(state),
  ({ Device }, currentUserId) => {
    if (!currentUserId) {
      return currentUserId;
    }

    return Device.all()
      .orderBy(['districtId', 'stationId'])
      .toModelArray()
      .map((device) => ({
        ...device.ref,
        ward: device.ward?.ref.name,
        hamlet: device.hamlet?.ref.name,
        district: device.district?.ref.name,
        station: device.station?.ref.name,
        isPersisted: !isLocalId(device.id),
      }));
  },
);

export const selectIsCurrentUserManagerForCurrentProvince = createSelector(
  orm,
  (state) => selectPath(state).provinceId,
  (state) => selectCurrentUserId(state),
  ({ Province }, id, currentUserId) => {
    if (!id) {
      return false;
    }

    const provinceModel = Province.withId(id);

    if (!provinceModel) {
      return false;
    }

    return provinceModel.hasManagerForUser(currentUserId);
  },
);

export const selectContentsForCurrentProvince = createSelector(
  orm,
  (state) => selectPath(state).provinceId,
  ({ Province }, id) => {
    if (!id) {
      return id;
    }

    const provinceModel = Province.withId(id);

    if (!provinceModel) {
      return provinceModel;
    }

    return provinceModel
      .getOrderedContentsQuerySet()
      .toModelArray()
      .map((contentModel) => {
        return {
          ...contentModel.ref,
          attachments: contentModel.attachments.toRefArray(),
          categories: contentModel.categories.toRefArray(),
          isPersisted: !isLocalId(contentModel.id),
          contentLocations: contentModel.contentLocations.toModelArray().map((model) => ({
            ...model.ref,
            ward: model.ward?.name,
          })),
          province: contentModel.province.ref,
          district: contentModel.district?.ref,
          ward: contentModel.ward && {
            ...contentModel.ward?.ref,
            district: contentModel.ward?.district?.name,
          },
        };
      });
  },
);

export const selectSchedulesForCurrentProvince = createSelector(
  orm,
  (state) => selectPath(state).provinceId,
  ({ Province }, id) => {
    if (!id) {
      return id;
    }

    const provinceModel = Province.withId(id);

    if (!provinceModel) {
      return provinceModel;
    }

    return provinceModel
      .getOrderedSchedulesQuerySet()
      .toModelArray()
      .map((schedule) => ({
        ...schedule.ref,
        // color: schedule.content?.ref.color,
        ward: schedule.ward?.name,
        device: schedule.device?.name,
        isPersisted: !isLocalId(schedule.id),
      }));
  },
);

export const selectUserGroupsForCurrentProvince = createSelector(
  orm,
  (state) => selectPath(state).provinceId,
  ({ Province }, id) => {
    if (!id) {
      return id;
    }

    const provinceModel = Province.withId(id);

    if (!provinceModel) {
      return provinceModel;
    }

    return provinceModel
      .getOrderedUserGroupsQuerySet()
      .toModelArray()
      .map((userGroupModel) => ({
        ...userGroupModel.ref,
        user: { name: userGroupModel.user?.name },
      }));
  },
);

export default {
  selectCurrentProvince,
  selectManagersForCurrentProvince,
  selectDistrictsForCurrentProvince,
  selectWardsForCurrentProvince,
  selectHamletsForCurrentProvince,
  selectCategoriesForCurrentProvince,
  selectStationsForCurrentProvince,
  selectDevicesForCurrentProvince,
  selectIsCurrentUserManagerForCurrentProvince,
  selectContentsForCurrentProvince,
  selectSchedulesForCurrentProvince,
  selectUserGroupsForCurrentProvince,
};
