import { createSelector } from 'redux-orm';

import orm from '../orm';

export const makeSelectScheduleById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Schedule }, id) => {
      const scheduleModel = Schedule.withId(id);

      if (!scheduleModel) {
        return scheduleModel;
      }

      return scheduleModel.ref;
    },
  );

export const selectScheduleById = makeSelectScheduleById();

export default {
  makeSelectScheduleById,
  selectScheduleById,
};
