export default [
  '#e04556',
  '#f0982d',
  '#109dc0',
  '#f97394',
  '#c7a57b',
  '#fab623',
  '#a5c261',
  '#6c99bb',
  '#8b8680',
  '#00b4b1',
  '#bfca02',
  '#52bad5',
  '#ffc66d',
  '#004d73',
  '#8aa177',
  '#355263',
  '#4a8753',
  '#ad5f7d',
  '#afb0a4',
  '#fc736d',
  '#edcb76',
  '#166a8f',
  '#f7d036',
  '#2b6a6c',
  '#87564a',
];
