import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Districts from '../components/Districts';

const mapStateToProps = (state) => {
  const { districtId } = selectors.selectPath(state);
  const { slug } = selectors.selectCurrentProvince(state);
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);

  return {
    items: districts,
    province: { slug },
    currentId: districtId,
    canEdit: false, // isCurrentUserManager,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createDistrictInCurrentProvince,
      onUpdate: entryActions.updateDistrict,
      onMove: entryActions.moveDistrict,
      onDelete: entryActions.deleteDistrict,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Districts);
