import React from 'react';
import PropTypes from 'prop-types';

import DistrictsContainer from '../../containers/DistrictsContainer';
import ProvinceSettingsModalContainer from '../../containers/ProvinceSettingsModalContainer';

import styles from './Province.module.scss';

const Province = React.memo(({ isSettingsModalOpened }) => {
  return (
    <>
      <div className={styles.wrapper}>
        <DistrictsContainer />
      </div>
      {isSettingsModalOpened && <ProvinceSettingsModalContainer />}
    </>
  );
});

Province.propTypes = {
  isSettingsModalOpened: PropTypes.bool.isRequired,
};

export default Province;
