import { eventChannel } from 'redux-saga';
import { all, call, cancelled, put, take, takeEvery } from 'redux-saga/effects';

import services from '../services';
import entryActions from '../../../entry-actions';
import api, { socket } from '../../../api';
import EntryActionTypes from '../../../constants/EntryActionTypes';

const createSocketEventsChannel = () =>
  eventChannel((emit) => {
    const handleDisconnect = () => {
      emit(entryActions.handleSocketDisconnect());
    };

    const handleReconnect = () => {
      emit(entryActions.handleSocketReconnect());
    };

    const handleUserCreate = ({ item }) => {
      emit(entryActions.handleUserCreate(item));
    };

    const handleUserUpdate = ({ item }) => {
      emit(entryActions.handleUserUpdate(item));
    };

    const handleUserDelete = ({ item }) => {
      emit(entryActions.handleUserDelete(item));
    };

    const handleUserGroupCreate = ({ item }) => {
      emit(entryActions.handleUserGroupCreate(item));
    };

    const handleUserGroupUpdate = ({ item }) => {
      emit(entryActions.handleUserGroupUpdate(item));
    };

    const handleUserGroupDelete = ({ item }) => {
      emit(entryActions.handleUserGroupDelete(item));
    };

    const handleUserPermissionCreate = ({ item }) => {
      emit(entryActions.handleUserPermissionCreate(item));
    };

    const handleUserPermissionUpdate = ({ item }) => {
      emit(entryActions.handleUserPermissionUpdate(item));
    };

    const handleProvinceCreate = ({ item }) => {
      emit(entryActions.handleProvinceCreate(item));
    };

    const handleProvinceUpdate = ({ item }) => {
      emit(entryActions.handleProvinceUpdate(item));
    };

    const handleProvinceDelete = ({ item }) => {
      emit(entryActions.handleProvinceDelete(item));
    };

    const handleProvinceManagerCreate = ({ item }) => {
      emit(entryActions.handleProvinceManagerCreate(item));
    };

    const handleProvinceManagerDelete = ({ item }) => {
      emit(entryActions.handleProvinceManagerDelete(item));
    };

    const handleDistrictCreate = ({ item, requestId }) => {
      emit(entryActions.handleDistrictCreate(item, requestId));
    };

    const handleDistrictUpdate = ({ item }) => {
      emit(entryActions.handleDistrictUpdate(item));
    };

    const handleDistrictDelete = ({ item }) => {
      emit(entryActions.handleDistrictDelete(item));
    };

    const handleDistrictMembershipCreate = ({ item }) => {
      emit(entryActions.handleDistrictMembershipCreate(item));
    };

    const handleDistrictMembershipUpdate = ({ item }) => {
      emit(entryActions.handleDistrictMembershipUpdate(item));
    };

    const handleDistrictMembershipDelete = ({ item }) => {
      emit(entryActions.handleDistrictMembershipDelete(item));
    };

    const handleWardCreate = ({ item }) => {
      emit(entryActions.handleWardCreate(item));
    };

    const handleWardUpdate = ({ item }) => {
      emit(entryActions.handleWardUpdate(item));
    };

    const handleWardDelete = ({ item }) => {
      emit(entryActions.handleWardDelete(item));
    };

    const handleWardMembershipCreate = ({ item }) => {
      emit(entryActions.handleWardMembershipCreate(item));
    };

    const handleWardMembershipUpdate = ({ item }) => {
      emit(entryActions.handleWardMembershipUpdate(item));
    };

    const handleWardMembershipDelete = ({ item }) => {
      emit(entryActions.handleWardMembershipDelete(item));
    };

    const handleHamletCreate = ({ item }) => {
      emit(entryActions.handleHamletCreate(item));
    };

    const handleHamletUpdate = ({ item }) => {
      emit(entryActions.handleHamletUpdate(item));
    };

    const handleHamletDelete = ({ item }) => {
      emit(entryActions.handleHamletDelete(item));
    };

    const handleCategoryCreate = ({ item }) => {
      emit(entryActions.handleCategoryCreate(item));
    };

    const handleCategoryUpdate = ({ item }) => {
      emit(entryActions.handleCategoryUpdate(item));
    };

    const handleCategoryDelete = ({ item }) => {
      emit(entryActions.handleCategoryDelete(item));
    };

    const handleStationCreate = ({ item }) => {
      emit(entryActions.handleStationCreate(item));
    };

    const handleStationUpdate = ({ item }) => {
      emit(entryActions.handleStationUpdate(item));
    };

    const handleStationDelete = ({ item }) => {
      emit(entryActions.handleStationDelete(item));
    };

    const handleContentCreate = ({ item }) => {
      emit(entryActions.handleContentCreate(item));
    };

    const handleContentUpdate = ({ item, ...rest }) => {
      emit(entryActions.handleContentUpdate(item, rest));
    };

    const handleContentDelete = ({ item }) => {
      emit(entryActions.handleContentDelete(item));
    };

    const handleContentCategoryCreate = ({ item }) => {
      emit(entryActions.handleContentCategoryCreate(item));
    };

    const handleContentCategoryUpdate = ({ item }) => {
      emit(entryActions.handleContentCategoryUpdate(item));
    };

    const handleContentCategoryDelete = ({ item }) => {
      emit(entryActions.handleContentCategoryDelete(item));
    };

    const handleContentLocationCreate = ({ item }) => {
      emit(entryActions.handleContentLocationCreate(item));
    };

    const handleContentLocationDelete = ({ item }) => {
      emit(entryActions.handleContentLocationDelete(item));
    };

    const handleContentLocationUpdate = ({ items }) => {
      emit(entryActions.handleContentLocationUpdate(items));
    };

    const handleContentCancelSchedule = ({ item, included: { contentSchedules } }) => {
      emit(entryActions.handleContentCancelSchedule(item, contentSchedules));
    };

    const handleDeviceCreate = api.makeHandleDeviceCreate(({ item }) => {
      emit(entryActions.handleDeviceCreate(item));
    });

    const handleDeviceUpdate = api.makeHandleDeviceUpdate(({ item }) => {
      emit(entryActions.handleDeviceUpdate(item));
    });

    const handleDeviceDelete = api.makeHandleDeviceDelete(({ item }) => {
      emit(entryActions.handleDeviceDelete(item));
    });

    const handleUserToDeviceAdd = ({ item }) => {
      emit(entryActions.handleUserToDeviceAdd(item));
    };

    const handleUserFromDeviceRemove = ({ item }) => {
      emit(entryActions.handleUserFromDeviceRemove(item));
    };

    const handleContentToDeviceAdd = ({ item }) => {
      emit(entryActions.handleContentToDeviceAdd(item));
    };

    const handleContentFromDeviceRemove = ({ item }) => {
      emit(entryActions.handleContentFromDeviceRemove(item));
    };

    const handleTaskCreate = ({ item }) => {
      emit(entryActions.handleTaskCreate(item));
    };

    const handleTaskUpdate = ({ item }) => {
      emit(entryActions.handleTaskUpdate(item));
    };

    const handleTaskDelete = ({ item }) => {
      emit(entryActions.handleTaskDelete(item));
    };

    const handleScheduleCreate = api.makeHandleScheduleCreate(({ item }) => {
      emit(entryActions.handleScheduleCreate(item));
    });

    const handleScheduleUpdate = api.makeHandleScheduleUpdate(({ item }) => {
      emit(entryActions.handleScheduleUpdate(item));
    });

    const handleScheduleDelete = api.makeHandleScheduleDelete(({ item }) => {
      emit(entryActions.handleScheduleDelete(item));
    });

    const handleAttachmentCreate = api.makeHandleAttachmentCreate(({ item, requestId }) => {
      emit(entryActions.handleAttachmentCreate(item, requestId));
    });

    const handleAttachmentUpdate = api.makeHandleAttachmentUpdate(({ item }) => {
      emit(entryActions.handleAttachmentUpdate(item));
    });

    const handleAttachmentDelete = api.makeHandleAttachmentDelete(({ item }) => {
      emit(entryActions.handleAttachmentDelete(item));
    });

    const handleActivityCreate = api.makeHandleActivityCreate(({ item }) => {
      emit(entryActions.handleActivityCreate(item));
    });

    const handleActivityUpdate = api.makeHandleActivityUpdate(({ item }) => {
      emit(entryActions.handleActivityUpdate(item));
    });

    const handleActivityDelete = api.makeHandleActivityDelete(({ item }) => {
      emit(entryActions.handleActivityDelete(item));
    });

    const handleNotificationCreate = api.makeHandleNotificationCreate(({ item }) => {
      emit(entryActions.handleNotificationCreate(item));
    });

    const handleNotificationUpdate = api.makeHandleNotificationUpdate(({ item }) => {
      emit(entryActions.handleNotificationDelete(item));
    });

    const handleBroadcastingError = ({ message }) => {
      emit(entryActions.handleBroadcastingError(message));
    };

    const handleBroadcastFinished = ({ message }) => {
      emit(entryActions.handleBroadcastFinished(message));
    };

    const handleOverrideContentByMicrophone = ({ item }) => {
      emit(entryActions.handleOverrideContentByMicrophone(item));
    };

    socket.on('disconnect', handleDisconnect);
    socket.on('reconnect', handleReconnect);

    socket.on('userCreate', handleUserCreate);
    socket.on('userUpdate', handleUserUpdate);
    socket.on('userDelete', handleUserDelete);

    socket.on('userGroupCreate', handleUserGroupCreate);
    socket.on('userGroupUpdate', handleUserGroupUpdate);
    socket.on('userGroupDelete', handleUserGroupDelete);

    socket.on('userPermissionCreate', handleUserPermissionCreate);
    socket.on('userPermissionUpdate', handleUserPermissionUpdate);

    socket.on('provinceCreate', handleProvinceCreate);
    socket.on('provinceUpdate', handleProvinceUpdate);
    socket.on('provinceDelete', handleProvinceDelete);

    socket.on('provinceManagerCreate', handleProvinceManagerCreate);
    socket.on('provinceManagerDelete', handleProvinceManagerDelete);

    socket.on('districtCreate', handleDistrictCreate);
    socket.on('districtUpdate', handleDistrictUpdate);
    socket.on('districtDelete', handleDistrictDelete);

    socket.on('districtMembershipCreate', handleDistrictMembershipCreate);
    socket.on('districtMembershipUpdate', handleDistrictMembershipUpdate);
    socket.on('districtMembershipDelete', handleDistrictMembershipDelete);

    socket.on('wardCreate', handleWardCreate);
    socket.on('wardUpdate', handleWardUpdate);
    socket.on('wardDelete', handleWardDelete);

    socket.on('wardMembershipCreate', handleWardMembershipCreate);
    socket.on('wardMembershipUpdate', handleWardMembershipUpdate);
    socket.on('wardMembershipDelete', handleWardMembershipDelete);

    socket.on('hamletCreate', handleHamletCreate);
    socket.on('hamletUpdate', handleHamletUpdate);
    socket.on('hamletDelete', handleHamletDelete);

    socket.on('categoryCreate', handleCategoryCreate);
    socket.on('categoryUpdate', handleCategoryUpdate);
    socket.on('categoryDelete', handleCategoryDelete);

    socket.on('stationCreate', handleStationCreate);
    socket.on('stationUpdate', handleStationUpdate);
    socket.on('stationDelete', handleStationDelete);

    socket.on('contentCreate', handleContentCreate);
    socket.on('contentUpdate', handleContentUpdate);
    socket.on('contentDelete', handleContentDelete);
    socket.on('contentCancelSchedule', handleContentCancelSchedule);

    socket.on('contentCategoryCreate', handleContentCategoryCreate);
    socket.on('contentCategoryUpdate', handleContentCategoryUpdate);
    socket.on('contentCategoryDelete', handleContentCategoryDelete);

    socket.on('contentLocationCreate', handleContentLocationCreate);
    socket.on('contentLocationDelete', handleContentLocationDelete);
    socket.on('contentLocationUpdate', handleContentLocationUpdate);

    socket.on('deviceCreate', handleDeviceCreate);
    socket.on('deviceUpdate', handleDeviceUpdate);
    socket.on('deviceDelete', handleDeviceDelete);

    socket.on('deviceMembershipCreate', handleUserToDeviceAdd);
    socket.on('deviceMembershipDelete', handleUserFromDeviceRemove);

    socket.on('deviceContentCreate', handleContentToDeviceAdd);
    socket.on('deviceContentDelete', handleContentFromDeviceRemove);

    socket.on('taskCreate', handleTaskCreate);
    socket.on('taskUpdate', handleTaskUpdate);
    socket.on('taskDelete', handleTaskDelete);

    socket.on('scheduleCreate', handleScheduleCreate);
    socket.on('scheduleUpdate', handleScheduleUpdate);
    socket.on('scheduleDelete', handleScheduleDelete);

    socket.on('attachmentCreate', handleAttachmentCreate);
    socket.on('attachmentUpdate', handleAttachmentUpdate);
    socket.on('attachmentDelete', handleAttachmentDelete);

    socket.on('actionCreate', handleActivityCreate);
    socket.on('actionUpdate', handleActivityUpdate);
    socket.on('actionDelete', handleActivityDelete);

    socket.on('notificationCreate', handleNotificationCreate);
    socket.on('notificationUpdate', handleNotificationUpdate);

    socket.on('broadcastingError', handleBroadcastingError);
    socket.on('broadcastCompleted', handleBroadcastFinished);

    socket.on('overrideContentByMicrophone', handleOverrideContentByMicrophone);

    return () => {
      socket.off('disconnect', handleDisconnect);
      socket.off('reconnect', handleReconnect);

      socket.off('userCreate', handleUserCreate);
      socket.off('userUpdate', handleUserUpdate);
      socket.off('userDelete', handleUserDelete);

      socket.off('userGroupCreate', handleUserGroupCreate);
      socket.off('userGroupUpdate', handleUserGroupUpdate);
      socket.off('userGroupDelete', handleUserGroupDelete);

      socket.off('userPermissionCreate', handleUserPermissionCreate);
      socket.off('userPermissionUpdate', handleUserPermissionUpdate);

      socket.off('provinceCreate', handleProvinceCreate);
      socket.off('provinceUpdate', handleProvinceUpdate);
      socket.off('provinceDelete', handleProvinceDelete);

      socket.off('provinceManagerCreate', handleProvinceManagerCreate);
      socket.off('provinceManagerDelete', handleProvinceManagerDelete);

      socket.off('districtCreate', handleDistrictCreate);
      socket.off('districtUpdate', handleDistrictUpdate);
      socket.off('districtDelete', handleDistrictDelete);

      socket.off('districtMembershipCreate', handleDistrictMembershipCreate);
      socket.off('districtMembershipUpdate', handleDistrictMembershipUpdate);
      socket.off('districtMembershipDelete', handleDistrictMembershipDelete);

      socket.off('wardCreate', handleWardCreate);
      socket.off('wardUpdate', handleWardUpdate);
      socket.off('wardDelete', handleWardDelete);

      socket.off('wardMembershipCreate', handleWardMembershipCreate);
      socket.off('wardMembershipUpdate', handleWardMembershipUpdate);
      socket.off('wardMembershipDelete', handleWardMembershipDelete);

      socket.on('hamletCreate', handleHamletCreate);
      socket.on('hamletUpdate', handleHamletUpdate);
      socket.on('hamletDelete', handleHamletDelete);

      socket.off('categoryCreate', handleCategoryCreate);
      socket.off('categoryUpdate', handleCategoryUpdate);
      socket.off('categoryDelete', handleCategoryDelete);

      socket.off('stationCreate', handleStationCreate);
      socket.off('stationUpdate', handleStationUpdate);
      socket.off('stationDelete', handleStationDelete);

      socket.off('contentCreate', handleContentCreate);
      socket.off('contentUpdate', handleContentUpdate);
      socket.off('contentDelete', handleContentDelete);
      socket.off('contentCancelSchedule', handleContentCancelSchedule);

      socket.off('contentCategoryCreate', handleContentCategoryCreate);
      socket.off('contentCategoryUpdate', handleContentCategoryUpdate);
      socket.off('contentCategoryDelete', handleContentCategoryDelete);

      socket.off('contentLocationCreate', handleContentLocationCreate);
      socket.off('contentLocationDelete', handleContentLocationDelete);
      socket.off('contentLocationUpdate', handleContentLocationUpdate);

      socket.off('deviceCreate', handleDeviceCreate);
      socket.off('deviceUpdate', handleDeviceUpdate);
      socket.off('deviceDelete', handleDeviceDelete);

      socket.off('deviceMembershipCreate', handleUserToDeviceAdd);
      socket.off('deviceMembershipDelete', handleUserFromDeviceRemove);

      socket.off('deviceContentCreate', handleContentToDeviceAdd);
      socket.off('deviceContentDelete', handleContentFromDeviceRemove);

      socket.off('taskCreate', handleTaskCreate);
      socket.off('taskUpdate', handleTaskUpdate);
      socket.off('taskDelete', handleTaskDelete);

      socket.off('scheduleCreate', handleScheduleCreate);
      socket.off('scheduleUpdate', handleScheduleUpdate);
      socket.off('scheduleDelete', handleScheduleDelete);

      socket.off('attachmentCreate', handleAttachmentCreate);
      socket.off('attachmentUpdate', handleAttachmentUpdate);
      socket.off('attachmentDelete', handleAttachmentDelete);

      socket.off('actionCreate', handleActivityCreate);
      socket.off('actionUpdate', handleActivityUpdate);
      socket.off('actionDelete', handleActivityDelete);

      socket.off('notificationCreate', handleNotificationCreate);
      socket.off('notificationUpdate', handleNotificationUpdate);

      socket.off('broadcastingError', handleBroadcastingError);
      socket.off('broadcastCompleted', handleBroadcastFinished);

      socket.on('overrideContentByMicrophone', handleOverrideContentByMicrophone);
    };
  });

export default function* socketWatchers() {
  yield all([
    yield takeEvery(EntryActionTypes.SOCKET_DISCONNECT_HANDLE, () =>
      services.handleSocketDisconnect(),
    ),
    yield takeEvery(EntryActionTypes.SOCKET_RECONNECT_HANDLE, () =>
      services.handleSocketReconnect(),
    ),
  ]);

  const socketEventsChannel = yield call(createSocketEventsChannel);

  try {
    while (true) {
      const action = yield take(socketEventsChannel);

      yield put(action);
    }
  } finally {
    if (yield cancelled()) {
      socketEventsChannel.close();
    }
  }
}
