import { addDays, addMonths, addWeeks, addYears, format, getDay, getDaysInMonth } from 'date-fns';
import { vi } from 'date-fns/locale';

export function pickDayOfWeek(time, pattern) {
  return format(new Date(time), pattern, { locale: vi });
}

export function addDayWeekMonthYear(type, date, times) {
  let result = date;
  switch (type) {
    case 'daily':
      result = addDays(new Date(date), times);
      break;
    case 'weekly':
      result = addWeeks(new Date(date), times);
      break;
    case 'monthly':
      result = addMonths(new Date(date), times);
      break;
    case 'yearly':
      result = addYears(new Date(date), times);
      break;
    default:
      break;
  }

  return result;
}

export function detailDayInMonth(date) {
  if (!date) return {};

  const frequencies = {
    1: 'first',
    2: 'second',
    3: 'third',
    4: 'fourth',
    5: 'final',
  };

  const dayOfWeek = pickDayOfWeek(date, 'cccc');
  const numberDayOfWeek = +pickDayOfWeek(date, 'i');
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  let count = 0;
  let dayDetail = {
    day: day + 1,
    frequency: 0,
    freq: 0,
    dayOfWeek,
  };
  /* eslint-disable no-plusplus */
  for (let d = 0; d < getDaysInMonth(new Date(year, month, day)); d++) {
    if (getDay(new Date(year, month, d)) + 1 === numberDayOfWeek) {
      count++;
      if (d === day - 1) {
        dayDetail = {
          day: d + 1,
          frequency: frequencies[count],
          freq: count,
          dayOfWeek,
        };
      }
    }
  }

  dayDetail.byday = pickDayOfWeek(date, 'ccccc');

  return dayDetail;
}
