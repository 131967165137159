import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* wardMembershipsWatchers() {
  yield all([
    takeEvery(EntryActionTypes.MEMBERSHIP_IN_CURRENT_WARD_CREATE, ({ payload: { data, wardId } }) =>
      services.createMembershipInCurrentWard(data, wardId),
    ),
    takeEvery(EntryActionTypes.WARD_MEMBERSHIP_CREATE_HANDLE, ({ payload: { wardMembership } }) =>
      services.handleWardMembershipCreate(wardMembership),
    ),
    takeEvery(EntryActionTypes.WARD_MEMBERSHIP_UPDATE, ({ payload: { id, data } }) =>
      services.updateWardMembership(id, data),
    ),
    takeEvery(EntryActionTypes.WARD_MEMBERSHIP_UPDATE_HANDLE, ({ payload: { wardMembership } }) =>
      services.handleWardMembershipUpdate(wardMembership),
    ),
    takeEvery(EntryActionTypes.WARD_MEMBERSHIP_DELETE, ({ payload: { id } }) =>
      services.deleteWardMembership(id),
    ),
    takeEvery(EntryActionTypes.WARD_MEMBERSHIP_DELETE_HANDLE, ({ payload: { wardMembership } }) =>
      services.handleWardMembershipDelete(wardMembership),
    ),
  ]);
}
