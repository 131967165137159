import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import DisplayAddModal from '../components/DisplayAddModal';

const mapStateToProps = (state, { selectedId, isViewing }) => {
  const { data: defaultData, isSubmitting, deviceId, error } = state.ui.deviceCreateForm;
  const { provinceId } = selectors.selectPath(state);
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);
  const isDarkMode = selectors.selectIsDarkMode(state);

  let device;
  const attachments = [];
  if (selectedId) {
    device = selectors.selectDeviceById(state, selectedId);
    Object.keys(device).forEach((k) => device[k] == null && delete device[k]);

    // attachments = selectors.selectAttachmentsByDeviceId(state, selectedId);
  }

  const isCurrentUserEditor = false;
  const isCurrentUserEditorOrCanComment = false;
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const stations = selectors.selectStationsForCurrentProvince(state);

  return {
    // name,
    // color,
    // type,
    // file,
    // fileDirname,
    // playCount,
    // status,
    // description,
    defaultData: { ...defaultData, ...device },
    isDarkMode,
    // districtId,
    provinceId,
    attachments,
    districts,
    stations,
    isSubmitting,
    isViewing,
    isEditting: selectedId !== undefined && !isViewing,
    deviceId,
    canEdit: isCurrentUserEditor,
    canEditCommentActivities: isCurrentUserEditorOrCanComment,
    canEditAllCommentActivities: isCurrentUserManager,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: (data) => entryActions.createDevice(data.stationId, data, false),
      onUpdate: entryActions.updateDevice,
      onDelete: entryActions.deleteCurrentDevice,
      onAttachmentCreate: entryActions.createAttachmentToDevice,
      onAttachmentUpdate: entryActions.updateAttachment,
      onAttachmentDelete: entryActions.deleteAttachment,
      // onClose: entryActions.closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DisplayAddModal);
