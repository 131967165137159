import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Accordion, Button, Checkbox, Menu, MenuItem, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { removeValues } from '../../utils/content-method';
import useToggle from '../../lib/hooks/use-toggle';
import topicControl from '../../utils/control-device-topic';

import SelectOption from './SelectOption';
import Notice from '../ContentModal/Notice';

import styles from './DevicesControlModal.module.scss';

const findItem = (sources, itemId) => {
  return sources.find((it) => it.id === itemId);
};

const isIncluded = (itemId, sources = []) => {
  const ids = sources.map((it) => it.id);

  return ids.includes(itemId);
};

const updateSources = (data, sources) => {
  const { key, isExistLocation, location, newItems } = data;
  const itemsInLocation = newItems.filter((d) => d[key] === location.id);

  // eslint-disable-next-line no-nested-ternary
  return itemsInLocation.length
    ? isExistLocation
      ? sources
      : [...sources, location]
    : removeValues(sources, location, 'id');
};

const DevicesControlModal = React.memo(
  ({ items, provinces, districts, wards, hamlets, onClose, onControl }) => {
    const [t] = useTranslation();

    const noticeRef = useRef();

    const [activeObject, setActiveObject] = useState({
      districtId: undefined,
      wardId: undefined,
      hamletId: undefined,
    });

    const [isOpenSelectOption, handleToggleSelectOption] = useToggle(false);

    const [areasReceived, setAreasReceived] = useState({
      provinces,
      districts: [],
      wards: [],
      hamlets: [],
      devices: [],
    });

    const payloadControl = useMemo(() => {
      const payload = topicControl(areasReceived, {
        districts,
        wards,
        hamlets,
        devices: items,
      });

      const deviceIds = areasReceived.devices.map((d) => d.id);

      return { ...payload, deviceIds };
    }, [areasReceived, districts, wards, hamlets, items]);

    const handleSelectActive = useCallback(
      (event, { name, value }) => {
        if (event.target.nodeName === 'LABEL') return;

        if (name === 'hamletId') {
          setActiveObject((prev) => ({ ...prev, [name]: value }));
          return;
        }

        if (activeObject[name] === value) {
          setActiveObject((prev) => ({ ...prev, [name]: undefined }));
          return;
        }

        setActiveObject((prev) => ({ ...prev, [name]: value }));
      },
      [activeObject],
    );

    const handleSelectAll = useCallback(
      (_, { checked }) => {
        if (checked) {
          setAreasReceived({
            provinces,
            districts,
            wards,
            hamlets,
            devices: items,
          });
        } else {
          setAreasReceived({
            provinces,
            districts: [],
            wards: [],
            hamlets: [],
            devices: [],
          });
        }
      },
      [provinces, districts, wards, hamlets, items],
    );

    const handleSelectDeviceByDistrict = useCallback(
      (item) => {
        const existDistrict = findItem(areasReceived.districts, item.id);

        const devicesInDistrict = items.filter((it) => it.districtId === item.id);
        const hemletsInDistrict = hamlets.filter((it) => it.districtId === item.id);
        const wardsInDistrict = wards.filter((it) => it.districtId === item.id);

        const newDevices = existDistrict
          ? removeValues(areasReceived.devices, devicesInDistrict, 'id')
          : [...areasReceived.devices, ...devicesInDistrict];

        const newHamlets = existDistrict
          ? removeValues(areasReceived.hamlets, hemletsInDistrict, 'id')
          : [...areasReceived.hamlets, ...hemletsInDistrict];

        const newWards = existDistrict
          ? removeValues(areasReceived.wards, wardsInDistrict, 'id')
          : [...areasReceived.wards, ...wardsInDistrict];

        const newDistricts = existDistrict
          ? removeValues(areasReceived.districts, item, 'id')
          : [...areasReceived.districts, item];

        setActiveObject((prev) => ({ ...prev, districtId: item.id }));
        setAreasReceived((prev) => ({
          ...prev,
          districts: newDistricts,
          wards: newWards,
          hamlets: newHamlets,
          devices: newDevices,
        }));
      },
      [
        items,
        wards,
        hamlets,
        areasReceived.districts,
        areasReceived.wards,
        areasReceived.hamlets,
        areasReceived.devices,
      ],
    );

    const handleSelectDeviceByWard = useCallback(
      (item) => {
        const existWard = findItem(areasReceived.wards, item.id);

        const devicesInWard = items.filter((it) => it.wardId === item.id);
        const hemletsInWard = hamlets.filter((it) => it.wardId === item.id);

        const districtByWard = findItem(districts, item.districtId);

        const isExistDistrict = findItem(areasReceived.districts, item.districtId);

        const newHamlets = existWard
          ? removeValues(areasReceived.hamlets, hemletsInWard, 'id')
          : [...areasReceived.hamlets, ...hemletsInWard];

        const newDevices = existWard
          ? removeValues(areasReceived.devices, devicesInWard, 'id')
          : [...areasReceived.devices, ...devicesInWard];

        const newWards = existWard
          ? removeValues(areasReceived.wards, item, 'id')
          : [...areasReceived.wards, item];

        const newDistricts = updateSources(
          {
            key: 'districtId',
            isExistLocation: isExistDistrict,
            location: districtByWard,
            newItems: newWards,
          },
          areasReceived.districts,
        );

        setActiveObject((prev) => ({ ...prev, wardId: item.id }));
        setAreasReceived((prev) => ({
          ...prev,
          districts: newDistricts,
          wards: newWards,
          hamlets: newHamlets,
          devices: newDevices,
        }));
      },
      [
        items,
        districts,
        hamlets,
        areasReceived.districts,
        areasReceived.wards,
        areasReceived.hamlets,
        areasReceived.devices,
      ],
    );

    const handleSelectDeviceByHamlet = useCallback(
      (item) => {
        const existHamlet = findItem(areasReceived.hamlets, item.id);

        const devicesInHamlet = items.filter((it) => it.hamletId === item.id);
        const wardByHamlet = findItem(wards, item.wardId);
        const districtByHamlet = findItem(districts, item.districtId);

        const isExistWard = findItem(areasReceived.wards, item.wardId);
        const isExistDistrict = findItem(areasReceived.districts, item.districtId);

        const newHamlets = existHamlet
          ? removeValues(areasReceived.hamlets, item, 'id')
          : [...areasReceived.hamlets, item];

        const newDevices = existHamlet
          ? removeValues(areasReceived.devices, devicesInHamlet, 'id')
          : [...areasReceived.devices, ...devicesInHamlet];

        const newWards = updateSources(
          {
            key: 'wardId',
            isExistLocation: isExistWard,
            location: wardByHamlet,
            newItems: newHamlets,
          },
          areasReceived.wards,
        );

        const newDistricts = updateSources(
          {
            key: 'districtId',
            isExistLocation: isExistDistrict,
            location: districtByHamlet,
            newItems: newHamlets,
          },
          areasReceived.districts,
        );

        setActiveObject((prev) => ({ ...prev, hamletId: item.id }));
        setAreasReceived((prev) => ({
          ...prev,
          districts: newDistricts,
          wards: newWards,
          hamlets: newHamlets,
          devices: newDevices,
        }));
      },
      [
        items,
        districts,
        wards,
        areasReceived.districts,
        areasReceived.wards,
        areasReceived.hamlets,
        areasReceived.devices,
      ],
    );

    const handleSelectDevice = useCallback(
      (device) => {
        const existDevice = findItem(areasReceived.devices, device.id);

        const hamletByDevice = findItem(hamlets, device.hamletId);
        const wardByDevice = findItem(wards, device.wardId);
        const districtByDevice = findItem(districts, device.districtId);

        const isExistHamlet = findItem(areasReceived.hamlets, device.hamletId);
        const isExistWard = findItem(areasReceived.wards, device.wardId);
        const isExistDistrict = findItem(areasReceived.districts, device.districtId);

        const newDevices = existDevice
          ? removeValues(areasReceived.devices, device, 'id')
          : [...areasReceived.devices, device];

        const newHamlets = updateSources(
          {
            key: 'hamletId',
            isExistLocation: isExistHamlet,
            location: hamletByDevice,
            newItems: newDevices,
          },
          areasReceived.hamlets,
        );

        const newWards = updateSources(
          {
            key: 'wardId',
            isExistLocation: isExistWard,
            location: wardByDevice,
            newItems: newDevices,
          },
          areasReceived.wards,
        );

        const newDistricts = updateSources(
          {
            key: 'districtId',
            isExistLocation: isExistDistrict,
            location: districtByDevice,
            newItems: newDevices,
          },
          areasReceived.districts,
        );

        setAreasReceived((prev) => ({
          ...prev,
          districts: newDistricts,
          wards: newWards,
          hamlets: newHamlets,
          devices: newDevices,
        }));
      },
      [
        areasReceived.devices,
        areasReceived.hamlets,
        areasReceived.wards,
        areasReceived.districts,
        districts,
        wards,
        hamlets,
      ],
    );

    const handleOpenSelectOption = useCallback(() => {
      if (areasReceived.devices.length <= 0) {
        noticeRef.current.handleAlertTrigger('common.deviceNotSelected');
        return;
      }
      handleToggleSelectOption(true);
    }, [areasReceived.devices, handleToggleSelectOption]);

    const handleClose = useCallback(
      (e) => {
        handleToggleSelectOption(false);
        if (!e) {
          onClose();
        }
      },
      [onClose, handleToggleSelectOption],
    );

    const renderHamlets = (item) => {
      const hamletFilters = hamlets.filter((it) => it.wardId === item.id);

      return hamletFilters.map((hamlet) => (
        <MenuItem
          key={hamlet.id}
          active={activeObject.hamletId === hamlet.id}
          value={hamlet.id}
          onClick={handleSelectActive}
          name="hamletId"
          className={styles.itemDevice}
        >
          <Checkbox
            checked={isIncluded(hamlet.id, areasReceived.hamlets)}
            onClick={() => handleSelectDeviceByHamlet(hamlet)}
          />
          <span style={{ fontSize: 14 }}>{hamlet.name}</span>
        </MenuItem>
      ));
    };

    const renderWards = (item) => {
      const wardFilters = wards.filter((it) => it.districtId === item.id);

      return (
        !!wardFilters.length && (
          <Accordion as={Menu} vertical style={{ width: '100%' }}>
            {wardFilters.map((ward) => (
              <MenuItem key={ward.id}>
                <Accordion.Title
                  active={activeObject.wardId === ward.id}
                  content={
                    <div className={styles.itemDevice}>
                      <Checkbox
                        checked={isIncluded(ward.id, areasReceived.wards)}
                        onClick={() => handleSelectDeviceByWard(ward)}
                      />
                      <span style={{ fontSize: 14 }}>{ward.name}</span>
                    </div>
                  }
                  value={ward.id}
                  name="wardId"
                  onClick={handleSelectActive}
                />
                <Accordion.Content
                  active={activeObject.wardId === ward.id}
                  content={renderHamlets(ward)}
                />
              </MenuItem>
            ))}
          </Accordion>
        )
      );
    };

    const devicesInHamlet = items.filter((d) => d.hamletId === activeObject.hamletId);

    return (
      <>
        <Modal open centered={false} closeIcon onClose={onClose} className={styles.wrapper}>
          <Modal.Header>{t('common.controlDevice')}</Modal.Header>
          <Notice ref={noticeRef} />
          <Modal.Content>
            <div className={styles.wrapperColumn}>
              <Accordion as={Menu} vertical style={{ width: '100%' }}>
                <MenuItem>
                  <Checkbox onClick={handleSelectAll} label={t('common.selectAll')} />
                </MenuItem>
                {districts.map((district) => (
                  <MenuItem key={district.id}>
                    <Accordion.Title
                      name="districtId"
                      active={activeObject.districtId === district.id}
                      content={
                        <div className={styles.itemDevice}>
                          <Checkbox
                            checked={isIncluded(district.id, areasReceived.districts)}
                            onClick={() => handleSelectDeviceByDistrict(district)}
                          />
                          {district.name}
                        </div>
                      }
                      value={district.id}
                      onClick={handleSelectActive}
                    />
                    <Accordion.Content
                      style={{ paddingTop: 0 }}
                      active={activeObject.districtId === district.id}
                      content={renderWards(district)}
                    />
                  </MenuItem>
                ))}
              </Accordion>
              <div>
                <h3>{t('common.devices')}</h3>
                {devicesInHamlet.map((d) => (
                  <MenuItem key={d.id}>
                    <Checkbox
                      checked={areasReceived.devices.map((it) => it.id).includes(d.id)}
                      label={d.name}
                      onClick={() => handleSelectDevice(d)}
                    />
                  </MenuItem>
                ))}
              </div>
              <div>
                <h3>{t('common.selectedDevices')}</h3>
                <div className={styles.scrollBox}>
                  {areasReceived.devices
                    .sort((a, b) => a.id - b.id)
                    .map((d) => (
                      <MenuItem key={d.id}>
                        <Checkbox
                          checked
                          label={d.name}
                          // onClick={() => handleSelectDevice(d)}
                        />
                      </MenuItem>
                    ))}
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" content={t('action.confirm')} onClick={handleOpenSelectOption} />
          </Modal.Actions>
        </Modal>
        {isOpenSelectOption && (
          <SelectOption payload={payloadControl} onClose={handleClose} onControl={onControl} />
        )}
      </>
    );
  },
);

export default DevicesControlModal;

DevicesControlModal.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  provinces: PropTypes.arrayOf(PropTypes.object).isRequired,
  districts: PropTypes.arrayOf(PropTypes.object).isRequired,
  wards: PropTypes.arrayOf(PropTypes.object).isRequired,
  hamlets: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  onControl: PropTypes.func.isRequired,
};
