import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Chart, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Legend, Tooltip, Title, ArcElement, PieController } from 'chart.js';
import { Label } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import ScheduleColors from '../../constants/ScheduleColors';

import styles from './StationStatistics.module.scss';

ChartJS.register(PieController, Legend, Tooltip, Title, ArcElement);

const labelInsidePluggin = {
  id: 'customeLabels',
  afterDatasetsDraw(chart) {
    const { ctx } = chart;
    const { unit } = chart.data.datasets[0];

    chart.data.datasets[0].data.forEach((dataset, index) => {
      const element = chart.getDatasetMeta(0).data[index];
      const position = element.tooltipPosition();

      if (element.circumference) {
        ctx.fillStyle = '#fff';
        ctx.font = 'bold 12px sans-serif';
        ctx.textAlign = 'center';
        ctx.fillText(`${dataset} ${unit || ''}`, position.x, position.y);
      }
    });
  },
};

const countStationInLocation = (locations, stations, attribute, idName) => {
  const result = stations.reduce((acc, station) => {
    const location = locations.find((loc) => loc.id === station[idName]);
    if (location) {
      const index = acc.findIndex((a) => a.id === location.id);

      if (index !== -1) {
        acc[index] = {
          ...acc[index],
          total: acc[index].total + 1,
        };
        return acc;
      }

      acc.push({ ...location, total: 1 });
    }
    return acc;
  }, []);

  const labels = result.map((l) => l[attribute]);
  const data = result.map((l) => l.total);

  return { labels, dataset: data };
};

const StationStatistics = React.memo(({ stations, wards, districts, district }) => {
  const [t] = useTranslation();

  const data = useMemo(() => {
    const locations = !isEmpty(district) ? wards : districts;
    const idName = !isEmpty(district) ? 'wardId' : 'districtId';
    const { labels, dataset } = countStationInLocation(locations, stations, 'name', idName);

    return {
      labels,
      datasets: [
        {
          label: 'Số đài ',
          data: dataset,
          backgroundColor: ScheduleColors.slice(0, dataset.length),
          hoverOffset: 4,
        },
      ],
    };
  }, [district, wards, stations, districts]);

  const options = {
    plugins: {
      legend: {
        display: false,
        position: 'right',
        align: 'center',
        labels: {
          boxWidth: 10,
          padding: 5,
        },
      },
    },
  };

  const totalWard = wards.length;
  const totalStation = stations.length;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.title}>{t('common.ICTBasedRadioStation')}</div>
      </div>
      <div className={styles.container}>
        {!district.id && (
          <div className={styles.statistic}>
            <h3>{districts.length}</h3>
            <Label size="small" color="teal">
              {t('common.district')}
            </Label>
          </div>
        )}
        <div className={styles.statistic}>
          <h3>{totalWard}</h3>
          <Label size="small" color="teal">
            {t('common.ward')}
          </Label>
        </div>
        <div className={styles.statistic}>
          <h3>{totalStation}</h3>
          <Label size="small" color="teal">
            {t('common.stations')}
          </Label>
        </div>
      </div>
      <div className={styles.wrapperChart}>
        {/* <Chart type="pie" data={data} options={options} plugins={[labelInsidePluggin]} /> */}
        <Pie data={data} options={options} plugins={[labelInsidePluggin]} />
        <div className={styles.noted}>
          {data.labels.map((label, i) => (
            <div key={label} className={styles.label}>
              <span
                className={styles.box}
                style={{
                  backgroundColor: `${data.datasets[0].backgroundColor[i]}`,
                }}
              />
              {label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

StationStatistics.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  districts: PropTypes.arrayOf(PropTypes.object).isRequired,
  stations: PropTypes.array.isRequired,
  wards: PropTypes.arrayOf(PropTypes.object).isRequired,
  district: PropTypes.object.isRequired,
};

export default StationStatistics;
