import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ReduxRouter } from '../lib/redux-router';

import Paths from '../constants/Paths';
import LoginContainer from '../containers/LoginContainer';
import CoreContainer from '../containers/CoreContainer';
import NotFound from './NotFound';

import 'react-datepicker/dist/react-datepicker.css';
import 'photoswipe/dist/photoswipe.css';
import 'easymde/dist/easymde.min.css';
import '../lib/custom-ui/react-big-calendar.css';
import '../lib/custom-ui/styles.css';

import '../styles.module.scss';

function Root({ store, history }) {
  return (
    <Provider store={store}>
      <ReduxRouter history={history}>
        <Routes>
          <Route path={Paths.LOGIN} element={<LoginContainer />} />
          <Route path={Paths.SCAN_QRCODE} element={<LoginContainer />} />
          <Route path={Paths.VERIFY_CODE} element={<LoginContainer />} />
          <Route path={Paths.ROOT} element={<CoreContainer />} />
          <Route path={Paths.USERS} element={<CoreContainer />} />
          <Route path={Paths.PROVINCES} element={<CoreContainer />} />
          <Route path={Paths.SETTING_PROVINCE_CATEGOGY} element={<CoreContainer />} />
          <Route path={Paths.CONTENTS} element={<CoreContainer />} />
          <Route path={Paths.CONTENT} element={<CoreContainer />} />
          <Route path={Paths.ELECTRONIC_CONTENTS} element={<CoreContainer />} />
          <Route path={Paths.REPORTS} element={<CoreContainer />} />
          <Route path={Paths.SETTINGS} element={<CoreContainer />} />
          <Route path={Paths.SETTING_USERS} element={<CoreContainer />} />
          <Route path={Paths.SCHEDULES} element={<CoreContainer />} />
          <Route path={Paths.SCHEDULES_LOGS} element={<CoreContainer />} />
          <Route path={Paths.DISTRICTS} element={<CoreContainer />} />
          <Route path={Paths.DEVICES} element={<CoreContainer />} />
          <Route path={Paths.DISPLAYS} element={<CoreContainer />} />
          <Route path={Paths.STATIONS} element={<CoreContainer />} />
          <Route path={Paths.DEVICE} element={<CoreContainer />} />
          <Route path={Paths.USER} element={<CoreContainer />} />
          <Route path={Paths.SETTING_USER_GROUPS} element={<CoreContainer />} />
          <Route path={Paths.USER_LOG} element={<CoreContainer />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ReduxRouter>
    </Provider>
  );
}

Root.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
};

export default Root;
