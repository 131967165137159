import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import HeaderContainer from '../../containers/HeaderContainer';
import ProvinceContainer from '../../containers/ProvinceContainer';
import DistrictActionsContainer from '../../containers/DistrictActionsContainer';

import styles from './Fixed.module.scss';
import Paths from '../../constants/Paths';

function Fixed({ provinceId, district, path, sidebarMenu, level }) {
  const [previousPath, setPreviouPath] = useState();

  useEffect(() => {
    if (path !== Paths.DEVICE) {
      setPreviouPath(path);
    }
  }, [path]);

  const matchPath =
    path === Paths.DISTRICTS || (previousPath === Paths.DISTRICTS && path === Paths.DEVICE);

  return (
    <div className={classNames(styles.wrapper, sidebarMenu && styles.sidebarMargin)}>
      <HeaderContainer />
      {provinceId && district && matchPath && <ProvinceContainer />}
      {district && !district.isFetching && matchPath && level === 'province' && (
        <DistrictActionsContainer />
      )}
    </div>
  );
}

Fixed.propTypes = {
  provinceId: PropTypes.string,
  district: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  path: PropTypes.string.isRequired,
  sidebarMenu: PropTypes.bool.isRequired,
  level: PropTypes.string.isRequired,
};

Fixed.defaultProps = {
  provinceId: undefined,
  district: undefined,
};

export default Fixed;
