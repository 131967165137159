import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* devicesWatchers() {
  yield all([
    takeEvery(EntryActionTypes.DEVICE_CREATE, ({ payload: { stationId, data, autoOpen } }) =>
      services.createDevice(stationId, data, autoOpen),
    ),
    takeEvery(EntryActionTypes.DEVICE_CREATE_HANDLE, ({ payload: { device } }) =>
      services.handleDeviceCreate(device),
    ),
    takeEvery(EntryActionTypes.DEVICES_FETCH, ({ payload: { page, limit, filter } }) =>
      services.fetchDevices(page, limit, filter),
    ),
    takeEvery(EntryActionTypes.DEVICE_UPDATE, ({ payload: { id, data } }) =>
      services.updateDevice(id, data),
    ),
    takeEvery(EntryActionTypes.CURRENT_DEVICE_UPDATE, ({ payload: { data } }) =>
      services.updateCurrentDevice(data),
    ),
    takeEvery(EntryActionTypes.DEVICE_UPDATE_HANDLE, ({ payload: { device } }) =>
      services.handleDeviceUpdate(device),
    ),
    takeEvery(EntryActionTypes.DEVICE_MOVE, ({ payload: { id, stationId, index } }) =>
      services.moveDevice(id, stationId, index),
    ),
    takeEvery(EntryActionTypes.CURRENT_DEVICE_MOVE, ({ payload: { stationId, index } }) =>
      services.moveCurrentDevice(stationId, index),
    ),
    takeEvery(
      EntryActionTypes.DEVICE_TRANSFER,
      ({ payload: { id, districtId, stationId, index } }) =>
        services.transferDevice(id, districtId, stationId, index),
    ),
    takeEvery(
      EntryActionTypes.CURRENT_DEVICE_TRANSFER,
      ({ payload: { districtId, stationId, index } }) =>
        services.transferCurrentDevice(districtId, stationId, index),
    ),
    takeEvery(EntryActionTypes.DEVICE_DELETE, ({ payload: { id } }) => services.deleteDevice(id)),
    takeEvery(EntryActionTypes.CURRENT_DEVICE_DELETE, () => services.deleteCurrentDevice()),
    takeEvery(EntryActionTypes.DEVICE_DELETE_HANDLE, ({ payload: { device } }) =>
      services.handleDeviceDelete(device),
    ),
    takeEvery(EntryActionTypes.DEVICES_CONTROL, ({ payload: { data } }) =>
      services.controlDevices(data),
    ),
  ]);
}
