import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Icon, Select, Table } from 'semantic-ui-react';
import { closePopup, usePopup } from '../../lib/popup';

import Config from '../../constants/Config';
// import AddStep from './AddStep';
// import EditStep from './EditStep';
import DeleteStation from './DeleteStation';
import StationModalContainer from '../../containers/StationModalContainer';
import PaginationCustom from '../Pagination';

import styles from './Stations.module.scss';

const LIMIT = Config.LIMIT_PER_PAGE;

const createMessage = (error) => {
  if (!error) return error;

  switch (error.message) {
    case 'Cannot delete this station':
      return 'common.cannotDeleteThisStation';

    default:
      return 'common.unknowError';
  }
};

const Stations = React.memo(
  ({
    items,
    districts,
    wards,
    // currentId,
    canAdd,
    canDelete,
    canEdit,
    canView,
    province,
    error,
    // onCreate,
    // onUpdate,
    // onMove,
    onDelete,
    onFetchStations,
    // onEditStationClick,
    onClear,
  }) => {
    const [t] = useTranslation();
    const [isStationModalOpened, setStationModalOpened] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [districtIds, setSelectDistrictId] = useState([]);

    const [limit, setLimit] = useState(LIMIT);
    const [page, setPage] = useState(1);

    const DeletePopup = usePopup(DeleteStation);

    const message = useMemo(() => createMessage(error), [error]);

    const handleClose = useCallback(() => {
      if (message) {
        onClear();
      }
    }, [message, onClear]);

    const handleRefetchStations = useCallback(() => {
      setPage(1);
      onFetchStations(1, limit, districtIds);
    }, [limit, districtIds, onFetchStations]);

    const handleDelete = useCallback(
      (id) => {
        onDelete(id);
      },
      [onDelete],
    );

    const handleStationModalOpen = useCallback(() => {
      setSelectedId(undefined);
      setStationModalOpened(true);
    }, []);

    const handleStationModalClose = useCallback(() => {
      setStationModalOpened(false);
    }, []);

    const handleEditOpen = useCallback((id) => {
      setSelectedId(id);
      setStationModalOpened(true);
    }, []);

    const handleSelectPage = useCallback(
      (_, { activePage }) => {
        setPage(activePage);
        onFetchStations(activePage, limit, districtIds);
      },
      [limit, districtIds, onFetchStations],
    );

    const onChangeSelectDistrict = useCallback((_, { value }) => {
      setSelectDistrictId(value);
    }, []);

    const handleFilterRegion = useCallback(() => {
      handleRefetchStations();
    }, [handleRefetchStations]);

    const districtOptions = districts.map(({ id, name }) => ({ key: id, value: id, text: name }));

    return (
      <div className={styles.wrapper}>
        <div className={styles.wrapperSelect}>
          <div className={styles.alignLeft}>
            <Select
              multiple
              closeOnChange
              placeholder={t('common.selectDistrict')}
              options={districtOptions}
              value={districtIds}
              onChange={onChangeSelectDistrict}
            />
            <Button className={styles.buttonFilter} onClick={handleFilterRegion} size="mini">
              <Icon name="filter" />
              <span>{t('action.filter')}</span>
            </Button>
          </div>
          {canAdd && (
            <Button className={styles.buttonAdd} onClick={handleStationModalOpen} size="mini">
              <Icon name="add" />
              <span>{t('action.addStation')}</span>
            </Button>
          )}
        </div>
        <div className={styles.wrapperTable}>
          <Table unstackable singleLine className={styles.table}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className={styles.header}>{t('common.name')}</Table.HeaderCell>
                <Table.HeaderCell className={styles.header}>
                  {t('common.description')}
                </Table.HeaderCell>
                <Table.HeaderCell className={styles.header}>
                  {t('common.district')}
                </Table.HeaderCell>
                <Table.HeaderCell className={styles.header}>{t('common.ward')}</Table.HeaderCell>
                <Table.HeaderCell className={styles.header}>{t('common.phone')}</Table.HeaderCell>
                <Table.HeaderCell className={styles.header}> </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {items.map((it) => {
                const district = districts.find((dst) => dst.id === it.districtId);
                const ward = wards.find((w) => w.id === it.wardId);

                return (
                  <Table.Row key={it.id}>
                    <Table.Cell>{it.name}</Table.Cell>
                    <Table.Cell>{truncate(it.description, { length: 50 })}</Table.Cell>
                    <Table.Cell>{district?.name}</Table.Cell>
                    <Table.Cell>{ward?.name}</Table.Cell>
                    <Table.Cell>{it.phone}</Table.Cell>
                    <Table.Cell textAlign="right">
                      <Button
                        onClick={() => handleEditOpen(it.id)}
                        icon
                        size="mini"
                        content={t(canEdit ? 'action.edit' : 'action.view')}
                        className={styles.action}
                      />
                      {canDelete && (
                        <DeletePopup
                          title={t('common.deleteStation_title')}
                          content={
                            message || 'common.areYouSureYouWantToDeleteThisStationCannotUndo'
                          }
                          station={it.name}
                          disabled={!!message}
                          onDelete={() => handleDelete(it.id)}
                          onClose={handleClose}
                        >
                          <Button
                            icon
                            size="mini"
                            content={t('action.delete')}
                            className={classNames(styles.action, styles.delete)}
                          />
                        </DeletePopup>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
        <div className={styles.pagination}>
          <PaginationCustom
            onPageChange={handleSelectPage}
            activePage={page}
            totalPages={Math.ceil(province.stationTotal / limit)}
          />
        </div>
        {isStationModalOpened && (
          <StationModalContainer onClose={handleStationModalClose} selectedId={selectedId} />
        )}
      </div>
    );
  },
);

Stations.propTypes = {
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  districts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  wards: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  canEdit: PropTypes.bool.isRequired,
  canAdd: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canView: PropTypes.bool.isRequired,
  province: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  error: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onDelete: PropTypes.func.isRequired,
  onFetchStations: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

Stations.defaultProps = {
  error: undefined,
};

export default Stations;
