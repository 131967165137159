import { attr, fk } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';
// import { transformSchedule } from '../api/schedules';

export default class extends BaseModel {
  static modelName = 'Schedule';

  static fields = {
    id: attr(),
    title: attr(),
    start: attr(),
    end: attr(),
    repeatRule: attr(),
    resouce: attr(),
    status: attr(),
    provinceId: fk({
      to: 'Province',
      as: 'province',
      relatedName: 'schedules',
    }),
    // districtId: fk({
    //   to: 'District',
    //   as: 'district',
    //   relatedName: 'schedules',
    // }),
    contentId: fk({
      to: 'Content',
      as: 'content',
      relatedName: 'schedules',
    }),
    wardId: fk({
      to: 'Ward',
      as: 'ward',
      relatedName: 'schedules',
    }),
    deviceId: fk({
      to: 'Device',
      as: 'device',
      relatedName: 'schedules',
    }),
  };

  static reducer({ type, payload }, Schedule) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE__SCHEDULES_FETCH:
        Schedule.all().delete();

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE:
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE:
        if (payload.schedules) {
          Schedule.all().delete();
          payload.schedules.forEach((schedule) => {
            Schedule.upsert(schedule);
          });
        }

        if (payload.contentSchedules) {
          Schedule.all().delete();
          payload.contentSchedules.forEach((schedule) => {
            Schedule.upsert(schedule);
          });
        }

        break;
      case ActionTypes.SCHEDULES_FETCH__SUCCESS:
        if (payload.schedules) {
          payload.schedules.forEach((schedule) => {
            Schedule.upsert(schedule);
          });
        }

        if (payload.contentSchedules) {
          payload.contentSchedules.forEach((schedule) => {
            Schedule.upsert(schedule);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Schedule.all().delete();

        if (payload.schedules) {
          payload.schedules.forEach((schedule) => {
            Schedule.upsert(schedule);
          });
        }

        if (payload.contentSchedules) {
          payload.contentSchedules.forEach((schedule) => {
            Schedule.upsert(schedule);
          });
        }

        break;
      case ActionTypes.DISTRICT_FETCH__SUCCESS:
        if (payload.schedules) {
          payload.schedules.forEach((schedule) => {
            Schedule.upsert(schedule);
          });
        }

        break;
      // case ActionTypes.SCHEDULES_FETCH:
      // Schedule.all().delete();
      // break;
      case ActionTypes.SCHEDULE_CREATE:
      case ActionTypes.SCHEDULE_CREATE_HANDLE:
      case ActionTypes.SCHEDULE_UPDATE__SUCCESS:
      case ActionTypes.SCHEDULE_UPDATE_HANDLE:
        Schedule.upsert(payload.schedule);

        break;
      case ActionTypes.SCHEDULE_CREATE__SUCCESS:
        Schedule.withId(payload.localId).delete();
        Schedule.upsert(payload.schedule);

        break;
      // case ActionTypes.SCHEDULE_UPDATE:
      //   Schedule.withId(payload.id).update(payload.data);

      //   break;
      case ActionTypes.SCHEDULE_DELETE:
        Schedule.withId(payload.id).delete();

        break;
      case ActionTypes.SCHEDULE_DELETE__SUCCESS:
      case ActionTypes.SCHEDULE_DELETE_HANDLE: {
        const scheduleModel = Schedule.withId(payload.schedule.id);

        if (scheduleModel) {
          scheduleModel.delete();
        }

        break;
      }
      case ActionTypes.CONTENT_CREATE__SUCCESS:
      case ActionTypes.CONTENT_UPDATE__SUCCESS:
      case ActionTypes.CONTENT_CANCEL_SCHEDULE_HANDLE:
        if (payload.contentSchedules) {
          payload.contentSchedules.forEach((schedule) => {
            Schedule.upsert(schedule);
          });
        }
        break;
      case ActionTypes.CONTENT_FETCH_STATIONS_BROADCASTED__SUCCESS:
        if (payload.schedules) {
          payload.schedules.forEach((schedule) => {
            Schedule.upsert(schedule);
          });
        }
        break;
      case ActionTypes.CONTENTS_FETCH__SUCCESS:
        if (payload.contentSchedules) {
          payload.contentSchedules.forEach((schedule) => {
            Schedule.upsert(schedule);
          });
        }
        break;
      case ActionTypes.CONTENT_DELETE__SUCCESS:
        if (payload.schedules) {
          payload.schedules.forEach((schedule) => {
            Schedule.upsert(schedule);
          });
        }
        break;
      case ActionTypes.CONTENT_UPDATE_HANDLE:
        if (payload.included.schedules) {
          payload.included.schedules.forEach((schedule) => {
            Schedule.upsert(schedule);
          });
        }

        break;
      default:
    }
  }
}
