import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Label, Popup } from 'semantic-ui-react';

import useToggle from '../../lib/hooks/use-toggle';

import PieChart from './PieChart';
import DevicesOperation from './DevicesOperation';
import StationStatistics from './StationStatistics';
import ExportContainer from '../../containers/ExportContainer';

import styles from './Reports.module.scss';

const Reports = React.memo(
  ({ district, districts, wards, contents, devices, stations, canFilter, onFetchReports }) => {
    const [t] = useTranslation();

    const [selectedID, setSelectedID] = useState();

    const [open, handleToggle] = useToggle();
    const [isPopup, handlePopup] = useToggle();

    return (
      <div className={styles.wrapper}>
        <div style={{ marginBottom: 20, textAlign: 'right' }}>
          {canFilter && (
            <Popup
              size="large"
              open={isPopup}
              on="click"
              onClose={() => handlePopup(false)}
              onOpen={() => handlePopup(true)}
              trigger={<Button content={district.name || t('action.areas')} color="teal" />}
              style={{ maxWidth: 300 }}
            >
              <div>
                <Label.Group>
                  {districts.map((d) => (
                    <Label
                      as="a"
                      basic
                      active={selectedID === d.id}
                      key={d.id}
                      content={d.name}
                      onClick={() => setSelectedID(d.id)}
                      {...(selectedID === d.id && { color: 'teal' })} // eslint-disable-line react/jsx-props-no-spreading
                    />
                  ))}
                </Label.Group>
                <div>
                  <Button
                    size="mini"
                    content={t('action.unchecked')}
                    color="grey"
                    onClick={() => {
                      setSelectedID();
                      onFetchReports();
                      handlePopup(false);
                    }}
                  />
                  <Button
                    size="mini"
                    content={t('action.seeResults')}
                    color="teal"
                    onClick={() => {
                      onFetchReports(selectedID);
                      handlePopup(false);
                    }}
                  />
                </div>
              </div>
            </Popup>
          )}
          <span style={{ marginLeft: 5 }}>
            <Button onClick={handleToggle}>{t('action.report')}</Button>
          </span>
        </div>
        <Grid>
          <Grid.Column widescreen={8} largeScreen={8} computer={8} tablet={16}>
            <DevicesOperation devices={devices} />
          </Grid.Column>
          <Grid.Column widescreen={8} largeScreen={8} computer={8} tablet={16}>
            <StationStatistics
              stations={stations}
              wards={wards}
              district={district}
              districts={districts}
            />
          </Grid.Column>
          <Grid.Column widescreen={8} largeScreen={8} computer={8} tablet={16}>
            <PieChart
              contents={contents}
              contentType="broadcasting"
              title={t('common.radioNews')}
            />
          </Grid.Column>
          <Grid.Column widescreen={8} largeScreen={8} computer={8} tablet={16}>
            <PieChart
              contents={contents}
              contentType="bulletinBoard"
              title={t('common.electronicBulletinBoardNews')}
            />
          </Grid.Column>
        </Grid>
        {open && <ExportContainer onClose={handleToggle} />}
      </div>
    );
  },
);

Reports.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  districts: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  wards: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  contents: PropTypes.arrayOf(PropTypes.object),
  devices: PropTypes.arrayOf(PropTypes.object),
  stations: PropTypes.array.isRequired,
  district: PropTypes.object.isRequired,
  /* eslint-enalble react/forbid-prop-types */
  canFilter: PropTypes.bool.isRequired,
  onFetchReports: PropTypes.func.isRequired,
};

Reports.defaultProps = {
  contents: [],
  devices: [],
};

export default Reports;
