import { attr, fk } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

export default class extends BaseModel {
  static modelName = 'Attachment';

  static fields = {
    id: attr(),
    url: attr(),
    coverUrl: attr(),
    image: attr(),
    name: attr(),
    contentId: fk({
      to: 'Content',
      as: 'content',
      relatedName: 'attachments',
    }),
    deviceId: fk({
      to: 'Device',
      as: 'device',
      relatedName: 'attachments',
    }),
  };

  static reducer({ type, payload }, Attachment) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CONTENTS_FETCH__SUCCESS:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE:
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE:
        if (payload.attachments) {
          payload.attachments.forEach((attachment) => {
            Attachment.upsert(attachment);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        if (payload.attachments) {
          // FIXME: bug with oneToOne relation in Redux-ORM
          const attachmentIds = payload.attachments.map((attachment) => attachment.id);

          Attachment.all()
            .toModelArray()
            .forEach((attachmentModel) => {
              if (!attachmentIds.includes(attachmentModel.id)) {
                attachmentModel.delete();
              }
            });

          payload.attachments.forEach((attachment) => {
            Attachment.upsert(attachment);
          });
        } else {
          Attachment.all().delete();
        }

        break;
      case ActionTypes.SCHEDULES_FETCH__SUCCESS:
      case ActionTypes.DISTRICT_FETCH__SUCCESS:
        payload.attachments.forEach((attachment) => {
          Attachment.upsert(attachment);
        });

        break;
      case ActionTypes.ATTACHMENT_CREATE:
      case ActionTypes.ATTACHMENT_CREATE_HANDLE:
      case ActionTypes.ATTACHMENT_UPDATE__SUCCESS:
      case ActionTypes.ATTACHMENT_UPDATE_HANDLE:
        Attachment.upsert(payload.attachment);

        break;
      case ActionTypes.ATTACHMENT_CREATE__SUCCESS:
        Attachment.withId(payload.localId).delete();
        Attachment.upsert(payload.attachment);

        break;
      case ActionTypes.ATTACHMENT_UPDATE:
        Attachment.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.ATTACHMENT_DELETE:
        Attachment.withId(payload.id)?.delete();

        break;
      case ActionTypes.ATTACHMENT_DELETE__SUCCESS:
      case ActionTypes.ATTACHMENT_DELETE_HANDLE: {
        const attachmentModel = Attachment.withId(payload.attachment.id);

        if (attachmentModel) {
          attachmentModel.delete();
        }

        break;
      }
      case ActionTypes.CONTENT_DELETE__SUCCESS:
        if (payload.content) {
          const attachmentModel = Attachment.filter({ contentId: payload.content.id }).first();
          if (attachmentModel) {
            attachmentModel.delete();
          }
        }

        break;

      case ActionTypes.CONTENT_UPDATE_HANDLE:
        if (payload.included?.attachments) {
          payload.included?.attachments.forEach((attachment) => {
            Attachment.upsert(attachment);
          });
        }

        break;
      case ActionTypes.CONTENT_CREATE__SUCCESS:
      case ActionTypes.CONTENT_UPDATE__SUCCESS:
        if (payload.attachment) {
          Attachment.upsert(payload.attachment);
        }

        break;
      default:
    }
  }
}
