import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Icon, Segment } from 'semantic-ui-react';

import styles from './AudioPlayer.module.scss';
import TrackItem from './TrackItem';
import Player from './Player';

const BoxAudioPlayer = React.memo(
  ({ isPlaying, setIsPlaying, audioFiles, sidebarMenu, onClose }) => {
    // const timerRef = useRef(null);
    const [currentAudioIndex, setCurrentAudioIndex] = useState(0);

    // const [isPlaying, setIsPlaying] = useState(true);

    const checkFile = audioFiles[0].name === 'Âm thanh';

    let currentAudio;

    if (checkFile) {
      currentAudio = audioFiles[0].url[currentAudioIndex];
    } else {
      currentAudio = audioFiles[currentAudioIndex];
    }

    const handleTogglePlayPause = useCallback(
      (num) => {
        if (num === currentAudioIndex) {
          setIsPlaying(!isPlaying);
        } else {
          setIsPlaying(true);
        }
        setCurrentAudioIndex(num);
      },
      [isPlaying, currentAudioIndex, setIsPlaying],
    );

    const handlePlayerEnded = useCallback(() => {
      if (checkFile && currentAudioIndex + 1 < audioFiles[0].url.length) {
        setCurrentAudioIndex(currentAudioIndex + 1);
        setIsPlaying(true);
      } else if (currentAudioIndex + 1 < audioFiles.length) {
        setCurrentAudioIndex(currentAudioIndex + 1);
        setIsPlaying(true);
      } else {
        setIsPlaying(false);
        setCurrentAudioIndex(0);
      }
    }, [currentAudioIndex, audioFiles, checkFile, setIsPlaying]);

    return (
      <Segment className={styles.wrapper}>
        <Button
          icon
          size="mini"
          content={<Icon size="large" name="close" />}
          className={styles.closeButton}
          onClick={onClose}
        />
        <Player
          key={currentAudioIndex}
          currentAudio={currentAudio}
          audioIndex={currentAudioIndex}
          sidebarMenu={sidebarMenu}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          onEnded={handlePlayerEnded}
        />
        <div className={classNames(styles.wrapPlaylist)}>
          <div className={styles.titlePlayList}>
            <Icon name="list alternate outline" /> Danh sách phát
          </div>
          <div
            className={styles.playlist}
            // onScroll={handleOnScrollPlaylist}
          >
            {audioFiles.map((audio, index) => (
              <TrackItem
                key={audio.id}
                trackNumber={index + 1}
                selected={checkFile || index === currentAudioIndex}
                isPlaying={isPlaying}
                title={audio.name}
                onClick={() => handleTogglePlayPause(index)}
              />
            ))}
          </div>
          {/* <div className={styles.coverBar} /> */}
        </div>
      </Segment>
    );
  },
);

BoxAudioPlayer.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  audioFiles: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  sidebarMenu: PropTypes.bool.isRequired,
  setIsPlaying: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BoxAudioPlayer;
