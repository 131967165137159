import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Label, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { usePopup } from '../../lib/popup';
import { USER_PERMISSIONS } from '../../constants/Enums';

import DeleteStep from '../DeleteStep';

import styles from './Item.module.scss';

const createMessage = (error) => {
  if (!error) return error;

  switch (error.message) {
    case 'User group being used':
      return 'common.userGroupBeingUserd';

    default:
      return 'common.unknowError';
  }
};

const Item = React.memo(
  ({ canDelete, isAdmin, name, user, permissions, error, onUpdate, onDelete, onClear }) => {
    const [t] = useTranslation();

    const message = useMemo(() => createMessage(error), [error]);

    const DeletePopup = usePopup(DeleteStep);

    const handleClose = useCallback(() => {
      if (message) {
        onClear();
      }
    }, [message, onClear]);

    return (
      <Table.Row>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>
          {permissions?.map((it) => (
            <Label color="teal" key={it} content={t(`permission.${USER_PERMISSIONS[it]}`)} />
          ))}
        </Table.Cell>
        <Table.Cell>{user.name}</Table.Cell>
        {isAdmin && (
          <Table.Cell textAlign="right">
            <Button className={styles.button} onClick={onUpdate}>
              <Icon fitted name="pencil" />
            </Button>
            {canDelete && (
              <DeletePopup
                title="common.deleteUserGroup"
                content={message || 'common.areYouSureYouWantToDeleteThisUserGroup'}
                buttonContent="action.delete"
                disabled={!!message}
                onConfirm={onDelete}
                onClose={handleClose}
              >
                <Button className={styles.button}>
                  <Icon fitted name="trash alternate outline" />
                </Button>
              </DeletePopup>
            )}
          </Table.Cell>
        )}
      </Table.Row>
    );
  },
);

Item.propTypes = {
  canDelete: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  permissions: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  error: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  user: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

Item.defaultProps = {
  user: {},
  error: undefined,
};

export default Item;
