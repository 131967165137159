import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Displays from '../components/Displays';

const mapStateToProps = (state) => {
  const { districtId } = selectors.selectPath(state);
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const devices = selectors.selectDevicesForCurrentProvince(state);
  const { name, deviceTotal } = selectors.selectCurrentProvince(state);
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);

  return {
    items: devices.filter(({ description }) => description === 'Màn Hình'),
    districts,
    currentId: districtId,
    canEdit: isCurrentUserManager,
    province: { name, deviceTotal },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createDevice,
      onUpdate: entryActions.updateDevice,
      onMove: entryActions.moveDevice,
      onDelete: entryActions.deleteDevice,
      onFetch: entryActions.fetchDevices,
      onRestart: entryActions.restartDevice,
      // onEditDeviceClick: entryActions.openEditDeviceModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Displays);
