import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import selectors from '../selectors';

import ElectronicContents from '../components/ElectronicContents';

const mapStateToProps = (state) => {
  const contents = selectors.selectContentsForCurrentProvince(state);
  const { contentTotal } = selectors.selectCurrentProvince(state);
  const categories = selectors.selectCategoriesForCurrentProvince(state);

  return { categories, items: contents, contentTotal };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ElectronicContents);
