import { call, put, select } from 'redux-saga/effects';

import { changeCoreLanguage, logout } from './core';
import request from '../request';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { setAccessToken } from '../../../utils/access-token-storage';

export function* createUser(data) {
  yield put(actions.createUser(data));

  let user;
  let permissions;
  let provinceManager;
  let districtMemberships;
  let wardMemberships;

  try {
    ({
      item: user,
      included: { permissions, provinceManager, districtMemberships, wardMemberships },
    } = yield call(request, api.createUser, data));
  } catch (error) {
    yield put(actions.createUser.failure(error));
    return;
  }

  yield put(
    actions.createUser.success(
      user,
      permissions,
      provinceManager,
      districtMemberships,
      wardMemberships,
    ),
  );
}

export function* fetchUserActions(data) {
  const id = yield select(selectors.selectCurrentUserId);
  const { id: provinceId } = yield select(selectors.selectCurrentProvince);

  yield put(actions.fetchUserActions(id));
  let userActions;
  let total;
  try {
    ({
      items: userActions,
      included: { total },
    } = yield call(request, api.getUserActions, id, data));
  } catch (error) {
    yield put(actions.fetchUserActions.failure(error));
    return;
  }

  yield put(actions.fetchUserActions.success(userActions, total, provinceId));
}

export function* handleUserCreate(user) {
  yield put(actions.handleUserCreate(user));
}

export function* clearUserCreateError() {
  yield put(actions.clearUserCreateError());
}

export function* updateUser(id, data) {
  yield put(actions.updateUser(id, data));

  let user;

  try {
    ({ item: user } = yield call(request, api.updateUser, id, data));
  } catch (error) {
    yield put(actions.updateUser.failure(id, error));
    return;
  }

  yield put(actions.updateUser.success(user));
}

export function* updateCurrentUser(data) {
  const id = yield select(selectors.selectCurrentUserId);

  yield call(updateUser, id, data);
}

export function* handleUserUpdate(user) {
  const currentUser = yield select(selectors.selectCurrentUser);
  const isCurrent = user.id === currentUser.id;

  let users;
  if (isCurrent && !currentUser.isAdmin && user.isAdmin) {
    ({ items: users } = yield call(request, api.getUsers));
  }

  yield put(actions.handleUserUpdate(user, users, isCurrent));
}

// TODO: add loading state
export function* updateUserLanguage(id, language) {
  yield call(changeCoreLanguage, language);

  yield call(updateUser, id, {
    language,
  });
}

export function* updateCurrentUserLanguage(language) {
  const id = yield select(selectors.selectCurrentUserId);

  yield call(updateUserLanguage, id, language);
}

export function* updateUserEmail(id, data) {
  yield put(actions.updateUserEmail(id, data));

  let user;
  try {
    ({ item: user } = yield call(request, api.updateUserEmail, id, data));
  } catch (error) {
    yield put(actions.updateUserEmail.failure(id, error));
    return;
  }

  yield put(actions.updateUserEmail.success(user));
}

export function* updateCurrentUserEmail(data) {
  const id = yield select(selectors.selectCurrentUserId);

  yield call(updateUserEmail, id, data);
}

export function* clearUserEmailUpdateError(id) {
  yield put(actions.clearUserEmailUpdateError(id));
}

export function* clearCurrentUserEmailUpdateError() {
  const id = yield select(selectors.selectCurrentUserId);

  yield call(clearUserEmailUpdateError, id);
}

export function* updateUserPassword(id, data) {
  yield put(actions.updateUserPassword(id, data));

  let user;
  let accessTokens;

  try {
    ({ item: user, included: { accessTokens } = {} } = yield call(
      request,
      api.updateUserPassword,
      id,
      data,
    ));
  } catch (error) {
    yield put(actions.updateUserPassword.failure(id, error));
    return;
  }

  const accessToken = accessTokens && accessTokens[0];

  if (accessToken) {
    yield call(setAccessToken, accessToken);
  }

  yield put(actions.updateUserPassword.success(user, accessToken));
}

export function* updateCurrentUserPassword(data) {
  const id = yield select(selectors.selectCurrentUserId);

  yield call(updateUserPassword, id, data);
}

export function* clearUserPasswordUpdateError(id) {
  yield put(actions.clearUserPasswordUpdateError(id));
}

export function* clearCurrentUserPasswordUpdateError() {
  const id = yield select(selectors.selectCurrentUserId);

  yield call(clearUserPasswordUpdateError, id);
}

export function* updateUserUsername(id, data) {
  yield put(actions.updateUserUsername(id, data));

  let user;
  try {
    ({ item: user } = yield call(request, api.updateUserUsername, id, data));
  } catch (error) {
    yield put(actions.updateUserUsername.failure(id, error));
    return;
  }

  yield put(actions.updateUserUsername.success(user));
}

export function* updateCurrentUserUsername(data) {
  const id = yield select(selectors.selectCurrentUserId);

  yield call(updateUserUsername, id, data);
}

export function* updateUserPermission(id, data) {
  yield put(actions.updateUserPermission(id, data));

  let user;
  let districtMemberships;
  let wardMemberships;
  let permissions;

  try {
    ({
      item: user,
      included: { districtMemberships, wardMemberships, permissions },
    } = yield call(request, api.updateUserPermission, id, data));
  } catch (error) {
    yield put(actions.updateUserPermission.failure(id, error));
    return;
  }

  yield put(
    actions.updateUserPermission.success(user, districtMemberships, wardMemberships, permissions),
  );
}

export function* updateCurrentUserPermission(data) {
  const id = yield select(selectors.selectCurrentUserId);

  yield call(updateUserPermission, id, data);
}

export function* clearUserPermissionUpdateError(id) {
  yield put(actions.clearUserPermissionUpdateError(id));
}

export function* clearUserUsernameUpdateError(id) {
  yield put(actions.clearUserUsernameUpdateError(id));
}

export function* clearCurrentUserUsernameUpdateError() {
  const id = yield select(selectors.selectCurrentUserId);

  yield call(clearUserUsernameUpdateError, id);
}

export function* updateUserAvatar(id, data) {
  yield put(actions.updateUserAvatar(id));

  let user;
  try {
    ({ item: user } = yield call(request, api.updateUserAvatar, id, data));
  } catch (error) {
    yield put(actions.updateUserAvatar.failure(id, error));
    return;
  }

  yield put(actions.updateUserAvatar.success(user));
}

export function* updateCurrentUserAvatar(data) {
  const id = yield select(selectors.selectCurrentUserId);

  yield call(updateUserAvatar, id, data);
}

export function* deleteUser(id) {
  yield put(actions.deleteUser(id));

  let user;
  try {
    ({ item: user } = yield call(request, api.deleteUser, id));
  } catch (error) {
    yield put(actions.deleteUser.failure(id, error));
    return;
  }

  yield put(actions.deleteUser.success(user));
}

export function* handleUserDelete(user) {
  const currentUserId = yield select(selectors.selectCurrentUserId);

  if (user.id === currentUserId) {
    yield call(logout, false);
  }

  yield put(actions.handleUserDelete(user));
}

export function* addUserToDevice(id, deviceId) {
  const currentUserId = yield select(selectors.selectCurrentUserId);

  yield put(actions.addUserToDevice(id, deviceId, id === currentUserId));

  let deviceMembership;
  try {
    ({ item: deviceMembership } = yield call(request, api.createDeviceMembership, deviceId, {
      userId: id,
    }));
  } catch (error) {
    yield put(actions.addUserToDevice.failure(id, deviceId, error));
    return;
  }

  yield put(actions.addUserToDevice.success(deviceMembership));
}

export function* addUserToCurrentDevice(id) {
  const { deviceId } = yield select(selectors.selectPath);

  yield call(addUserToDevice, id, deviceId);
}

export function* handleUserToDeviceAdd(deviceMembership) {
  yield put(actions.handleUserToDeviceAdd(deviceMembership));
}

export function* removeUserFromDevice(id, deviceId) {
  yield put(actions.removeUserFromDevice(id, deviceId));

  let deviceMembership;
  try {
    ({ item: deviceMembership } = yield call(request, api.deleteDeviceMembership, deviceId, id));
  } catch (error) {
    yield put(actions.removeUserFromDevice.failure(id, deviceId, error));
    return;
  }

  yield put(actions.removeUserFromDevice.success(deviceMembership));
}

export function* removeUserFromCurrentDevice(id) {
  const { deviceId } = yield select(selectors.selectPath);

  yield call(removeUserFromDevice, id, deviceId);
}

export function* handleUserFromDeviceRemove(deviceMembership) {
  yield put(actions.handleUserFromDeviceRemove(deviceMembership));
}

export function* addUserToDistrictFilter(id, districtId) {
  yield put(actions.addUserToDistrictFilter(id, districtId));
}

export function* addUserToFilterInCurrentDistrict(id) {
  const { districtId } = yield select(selectors.selectPath);

  yield call(addUserToDistrictFilter, id, districtId);
}

export function* removeUserFromDistrictFilter(id, districtId) {
  yield put(actions.removeUserFromDistrictFilter(id, districtId));
}

export function* removeUserFromFilterInCurrentDistrict(id) {
  const { districtId } = yield select(selectors.selectPath);

  yield call(removeUserFromDistrictFilter, id, districtId);
}

export function* handleUserPermissionCreate(permission) {
  yield put(actions.handleUserPermissionCreate(permission));
}

export function* handleUserPermissionUpdate(permission) {
  yield put(actions.handleUserPermissionUpdate(permission));
}

export default {
  createUser,
  fetchUserActions,
  handleUserCreate,
  clearUserCreateError,
  updateUser,
  updateCurrentUser,
  handleUserUpdate,
  updateUserLanguage,
  updateCurrentUserLanguage,
  updateUserEmail,
  updateCurrentUserEmail,
  clearUserEmailUpdateError,
  clearCurrentUserEmailUpdateError,
  updateUserPassword,
  updateCurrentUserPassword,
  clearUserPasswordUpdateError,
  clearCurrentUserPasswordUpdateError,
  updateUserUsername,
  updateCurrentUserUsername,
  clearUserUsernameUpdateError,
  clearCurrentUserUsernameUpdateError,
  updateUserPermission,
  updateCurrentUserPermission,
  clearUserPermissionUpdateError,
  updateUserAvatar,
  updateCurrentUserAvatar,
  deleteUser,
  handleUserDelete,
  addUserToDevice,
  addUserToCurrentDevice,
  handleUserToDeviceAdd,
  removeUserFromDevice,
  removeUserFromCurrentDevice,
  handleUserFromDeviceRemove,
  addUserToDistrictFilter,
  addUserToFilterInCurrentDistrict,
  removeUserFromDistrictFilter,
  removeUserFromFilterInCurrentDistrict,
  handleUserPermissionCreate,
  handleUserPermissionUpdate,
};
