import { call, select } from 'redux-saga/effects';

import request from '../request';
import selectors from '../../../selectors';
import api from '../../../api';
import Paths from '../../../constants/Paths';

export function* fetchDistrictByCurrentPath() {
  const pathsMatch = yield select(selectors.selectPathsMatch);

  let province;
  let district;
  let device;
  let users;
  let provinces;
  let districtMemberships;
  let categories;
  let contents;
  let contentCategories;
  let schedules;
  let stations;
  let devices;
  let deviceMemberships;
  let deviceContents;
  let tasks;
  let attachments;
  let totals;

  if (pathsMatch) {
    let districtId;

    switch (pathsMatch.pattern.path) {
      case Paths.DISTRICTS:
        districtId = pathsMatch.params.id;
        break;
      case Paths.DEVICE:
        ({
          item: device,
          item: { districtId },
        } = yield call(request, api.getDevice, pathsMatch.params.id));
        break;
      default:
        break;
    }

    // if (pathsMatch.pattern.path === Paths.DISTRICTS) {
    //   districtId = pathsMatch.params.id;
    // } else if (pathsMatch.pattern.path === Paths.DEVICE) {
    //   ({
    //     item: device,
    //     item: { districtId },
    //   } = yield call(request, api.getDevice, pathsMatch.params.id));
    // }

    if (districtId) {
      ({
        item: district,
        included: {
          users,
          provinces,
          districtMemberships,
          contents,
          contentCategories,
          stations,
          devices,
          deviceMemberships,
          deviceContents,
          tasks,
          attachments,
        },
      } = yield call(request, api.getDistrict, districtId, true));
    }
  }

  if (!province && provinces.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    province = provinces[0];
  }

  return {
    district,
    device,
    users,
    districtMemberships,
    categories,
    contents,
    contentCategories,
    schedules,
    stations,
    devices,
    deviceMemberships,
    deviceContents,
    tasks,
    attachments,
    totals,
    province,
  };
}

export default {
  fetchDistrictByCurrentPath,
};
