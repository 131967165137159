import ActionTypes from '../constants/ActionTypes';

const createDevice = (device) => ({
  type: ActionTypes.DEVICE_CREATE,
  payload: {
    device,
  },
});

createDevice.success = (localId, device) => ({
  type: ActionTypes.DEVICE_CREATE__SUCCESS,
  payload: {
    localId,
    device,
  },
});

createDevice.failure = (localId, error) => ({
  type: ActionTypes.DEVICE_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleDeviceCreate = (device) => ({
  type: ActionTypes.DEVICE_CREATE_HANDLE,
  payload: {
    device,
  },
});

const fetchDevices = (provinceId) => ({
  type: ActionTypes.DEVICES_FETCH,
  payload: {
    provinceId,
  },
});

fetchDevices.success = (provinceId, devices, total) => ({
  type: ActionTypes.DEVICES_FETCH__SUCCESS,
  payload: {
    provinceId,
    devices,
    total,
  },
});

fetchDevices.failure = (provinceId, error) => ({
  type: ActionTypes.DEVICES_FETCH__FAILURE,
  payload: {
    provinceId,
    error,
  },
});

const updateDevice = (id, data) => ({
  type: ActionTypes.DEVICE_UPDATE,
  payload: {
    id,
    data,
  },
});

updateDevice.success = (device) => ({
  type: ActionTypes.DEVICE_UPDATE__SUCCESS,
  payload: {
    device,
  },
});

updateDevice.failure = (id, error) => ({
  type: ActionTypes.DEVICE_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleDeviceUpdate = (device) => ({
  type: ActionTypes.DEVICE_UPDATE_HANDLE,
  payload: {
    device,
  },
});

const deleteDevice = (id) => ({
  type: ActionTypes.DEVICE_DELETE,
  payload: {
    id,
  },
});

deleteDevice.success = (device) => ({
  type: ActionTypes.DEVICE_DELETE__SUCCESS,
  payload: {
    device,
  },
});

deleteDevice.failure = (id, error) => ({
  type: ActionTypes.DEVICE_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const controlDevices = (provinceId, data) => ({
  type: ActionTypes.DEVICE_CONTROL,
  payload: {
    provinceId,
    data,
  },
});

controlDevices.success = (provinceId, message) => ({
  type: ActionTypes.DEVICE_CONTROL__SUCCESS,
  payload: {
    provinceId,
    message,
  },
});

controlDevices.failure = (provinceId, error) => ({
  type: ActionTypes.DEVICE_CONTROL__FAILURE,
  payload: {
    provinceId,
    error,
  },
});

const handleDeviceDelete = (device) => ({
  type: ActionTypes.DEVICE_DELETE_HANDLE,
  payload: {
    device,
  },
});

export default {
  createDevice,
  handleDeviceCreate,
  fetchDevices,
  controlDevices,
  updateDevice,
  handleDeviceUpdate,
  deleteDevice,
  handleDeviceDelete,
};
