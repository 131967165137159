import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';
import { Button, Icon } from 'semantic-ui-react';

import styles from './TrackItem.module.scss';

const TrackItem = React.memo(({ title, trackNumber, selected, isPlaying, onClick }) => {
  return (
    <Button onClick={onClick} className={classNames(styles.track, selected && styles.active)}>
      <Icon name="sound" size="small" />
      <p>{truncate(title.replace(/.mp3$/, ''), { length: 26 })}</p>
      <span>
        {selected && isPlaying ? (
          <Icon name="pause" size="small" />
        ) : (
          <Icon name="play" size="small" />
        )}
      </span>
    </Button>
  );
});

TrackItem.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  trackNumber: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default TrackItem;
