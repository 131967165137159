import { call, put, select } from 'redux-saga/effects';

import { goToProvince, goToRoot } from './router';
import request from '../request';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';

export function* createProvince(data) {
  yield put(actions.createProvince(data));

  let province;
  let provinceManagers;

  try {
    ({
      item: province,
      included: { provinceManagers },
    } = yield call(request, api.createProvince, data));
  } catch (error) {
    yield put(actions.createProvince.failure(error));
    return;
  }

  yield put(actions.createProvince.success(province, provinceManagers));
  yield call(goToProvince, province.id);
}

export function* handleProvinceCreate({ id }) {
  let province;
  let users;
  let provinceManagers;
  let districts;
  let districtMemberships;

  try {
    ({
      item: province,
      included: { users, provinceManagers, districts, districtMemberships },
    } = yield call(request, api.getProvince, id));
  } catch (error) {
    return;
  }

  yield put(
    actions.handleProvinceCreate(province, users, provinceManagers, districts, districtMemberships),
  );
}

export function* updateProvince(id, data) {
  yield put(actions.updateProvince(id, data));

  let province;
  try {
    ({ item: province } = yield call(request, api.updateProvince, id, data));
  } catch (error) {
    yield put(actions.updateProvince.failure(id, error));
    return;
  }

  yield put(actions.updateProvince.success(province));
}

export function* updateCurrentProvince(data) {
  const { provinceId } = yield select(selectors.selectPath);

  yield call(updateProvince, provinceId, data);
}

export function* handleProvinceUpdate(province) {
  yield put(actions.handleProvinceUpdate(province));
}

export function* updateProvinceBackgroundImage(id, data) {
  yield put(actions.updateProvinceBackgroundImage(id));

  let province;
  try {
    ({ item: province } = yield call(request, api.updateProvinceBackgroundImage, id, data));
  } catch (error) {
    yield put(actions.updateProvinceBackgroundImage.failure(id, error));
    return;
  }

  yield put(actions.updateProvinceBackgroundImage.success(province));
}

export function* updateCurrentProvinceBackgroundImage(data) {
  const { provinceId } = yield select(selectors.selectPath);

  yield call(updateProvinceBackgroundImage, provinceId, data);
}

export function* deleteProvince(id) {
  // const { provinceId } = yield select(selectors.selectPath);

  // if (id === provinceId) {
  //   yield call(goToRoot);
  // }

  yield put(actions.deleteProvince(id));

  let province;
  try {
    ({ item: province } = yield call(request, api.deleteProvince, id));
  } catch (error) {
    yield put(actions.deleteProvince.failure(id, error));
    return;
  }

  yield put(actions.deleteProvince.success(province));
}

export function* deleteCurrentProvince() {
  const { provinceId } = yield select(selectors.selectPath);

  yield call(deleteProvince, provinceId);
}

export function* handleProvinceDelete(province) {
  const { provinceId } = yield select(selectors.selectPath);

  if (province.id === provinceId) {
    yield call(goToRoot);
  }

  yield put(actions.handleProvinceDelete(province));
}

export default {
  createProvince,
  handleProvinceCreate,
  updateProvince,
  updateCurrentProvince,
  handleProvinceUpdate,
  updateProvinceBackgroundImage,
  updateCurrentProvinceBackgroundImage,
  deleteProvince,
  deleteCurrentProvince,
  handleProvinceDelete,
};
