import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createSchedule(districtId, data) {
  const nextData = {
    ...data,
    position: yield select(selectors.selectNextSchedulePosition, districtId),
  };

  const localId = yield call(createLocalId);

  yield put(
    actions.createSchedule({
      ...nextData,
      districtId,
      id: localId,
    }),
  );

  let schedule;
  try {
    ({ item: schedule } = yield call(request, api.createSchedule, districtId, nextData));
  } catch (error) {
    yield put(actions.createSchedule.failure(localId, error));
    return;
  }

  yield put(actions.createSchedule.success(localId, schedule));
}

export function* createScheduleInProvince(provinceId, data) {
  const nextData = {
    ...data,
    position: 0, // yield select(selectors.selectNextSchedulePosition, provinceId),
  };

  const localId = yield call(createLocalId);

  yield put(
    actions.createSchedule({
      ...nextData,
      provinceId,
      id: localId,
    }),
  );

  let schedule;
  try {
    ({ item: schedule } = yield call(request, api.createSchedule, provinceId, nextData));
  } catch (error) {
    yield put(actions.createSchedule.failure(localId, error));
    return;
  }

  yield put(actions.createSchedule.success(localId, schedule));
}

export function* createScheduleInCurrentDistrict(data) {
  const { districtId } = yield select(selectors.selectPath);

  yield call(createSchedule, districtId, data);
}

export function* createScheduleInCurrentProvince(data) {
  const { provinceId } = yield select(selectors.selectPath);

  yield call(createScheduleInProvince, provinceId, data);
}

export function* handleScheduleCreate(schedule) {
  yield put(actions.handleScheduleCreate(schedule));
}

export function* fetchSchedules(data) {
  const { provinceId } = yield select(selectors.selectPath);
  yield put(actions.fetchSchedules(provinceId));

  let schedules;
  let contents;
  let attachments;
  let users;
  let contentCategories;
  let contentLocations;

  try {
    ({
      items: schedules,
      included: { users, contents, attachments, contentCategories, contentLocations },
    } = yield call(request, api.getSchedules, provinceId, { page: 0, limit: 20, ...data }));
  } catch (error) {
    yield put(actions.fetchSchedules.failure(provinceId, error));
    return;
  }

  yield put(
    actions.fetchSchedules.success(
      provinceId,
      schedules,
      users,
      contents,
      attachments,
      contentCategories,
      contentLocations,
    ),
  );
}

export function* updateSchedule(id, data) {
  yield put(actions.updateSchedule(id, data));

  let schedule;
  try {
    ({ item: schedule } = yield call(request, api.updateSchedule, id, data));
  } catch (error) {
    yield put(actions.updateSchedule.failure(id, error));
    return;
  }

  yield put(actions.updateSchedule.success(schedule));
}

export function* handleScheduleUpdate(schedule) {
  yield put(actions.handleScheduleUpdate(schedule));
}

export function* moveSchedule(id, index) {
  const { districtId } = yield select(selectors.selectScheduleById, id);
  const position = yield select(selectors.selectNextSchedulePosition, districtId, index, id);

  yield call(updateSchedule, id, {
    position,
  });
}

export function* deleteSchedule(id) {
  yield put(actions.deleteSchedule(id));

  let schedule;
  try {
    ({ item: schedule } = yield call(request, api.deleteSchedule, id));
  } catch (error) {
    yield put(actions.deleteSchedule.failure(id, error));
    return;
  }

  yield put(actions.deleteSchedule.success(schedule));
}

export function* handleScheduleDelete(schedule) {
  yield put(actions.handleScheduleDelete(schedule));
}

export function* addScheduleToDevice(id, deviceId) {
  yield put(actions.addScheduleToDevice(id, deviceId));

  let deviceSchedule;
  try {
    ({ item: deviceSchedule } = yield call(request, api.createDeviceSchedule, deviceId, {
      scheduleId: id,
    }));
  } catch (error) {
    yield put(actions.addScheduleToDevice.failure(id, deviceId, error));
    return;
  }

  yield put(actions.addScheduleToDevice.success(deviceSchedule));
}

export function* addScheduleToCurrentDevice(id) {
  const { deviceId } = yield select(selectors.selectPath);

  yield call(addScheduleToDevice, id, deviceId);
}

export function* handleScheduleToDeviceAdd(deviceSchedule) {
  yield put(actions.handleScheduleToDeviceAdd(deviceSchedule));
}

export function* removeScheduleFromDevice(id, deviceId) {
  yield put(actions.removeScheduleFromDevice(id, deviceId));

  let deviceSchedule;
  try {
    ({ item: deviceSchedule } = yield call(request, api.deleteDeviceSchedule, deviceId, id));
  } catch (error) {
    yield put(actions.removeScheduleFromDevice.failure(id, deviceId, error));
    return;
  }

  yield put(actions.removeScheduleFromDevice.success(deviceSchedule));
}

export function* removeScheduleFromCurrentDevice(id) {
  const { deviceId } = yield select(selectors.selectPath);

  yield call(removeScheduleFromDevice, id, deviceId);
}

export function* handleScheduleFromDeviceRemove(deviceSchedule) {
  yield put(actions.handleScheduleFromDeviceRemove(deviceSchedule));
}

export function* addScheduleToDistrictFilter(id, districtId) {
  yield put(actions.addScheduleToDistrictFilter(id, districtId));
}

export function* addScheduleToFilterInCurrentDistrict(id) {
  const { districtId } = yield select(selectors.selectPath);

  yield call(addScheduleToDistrictFilter, id, districtId);
}

export function* removeScheduleFromDistrictFilter(id, districtId) {
  yield put(actions.removeScheduleFromDistrictFilter(id, districtId));
}

export function* removeScheduleFromFilterInCurrentDistrict(id) {
  const { districtId } = yield select(selectors.selectPath);

  yield call(removeScheduleFromDistrictFilter, id, districtId);
}

export function* clearScheduleError() {
  yield put(actions.clearScheduleError());
}

export default {
  createSchedule,
  createScheduleInCurrentDistrict,
  createScheduleInCurrentProvince,
  fetchSchedules,
  handleScheduleCreate,
  updateSchedule,
  handleScheduleUpdate,
  moveSchedule,
  deleteSchedule,
  handleScheduleDelete,
  addScheduleToDevice,
  addScheduleToCurrentDevice,
  handleScheduleToDeviceAdd,
  removeScheduleFromDevice,
  removeScheduleFromCurrentDevice,
  handleScheduleFromDeviceRemove,
  addScheduleToDistrictFilter,
  addScheduleToFilterInCurrentDistrict,
  removeScheduleFromDistrictFilter,
  removeScheduleFromFilterInCurrentDistrict,
  clearScheduleError,
};
