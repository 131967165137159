import React, { useCallback, useMemo, useRef } from 'react';
import { dequal } from 'dequal';
import classNames from 'classnames';
import { pickBy } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  TextArea,
  Divider,
  Icon,
} from 'semantic-ui-react';

import { useForm, useSteps } from '../../hooks';
import { Input as InputCustom } from '../../lib/custom-ui';

import PickLocation from '../StationModal/PickLocation';
import editIcon from '../../assets/images/edit.png';

import styles from './DisplayAddModal.module.scss';
import { DeviceStatusOptions } from '../../constants/Enums';

const StepTypes = {
  VIEW_MAP: 'VIEW_MAP',
};

const DEFAULT_VOLUME = 50;

const DisplayAddModal = React.memo(
  ({
    // name,
    // background,
    // backgroundImage,
    // isBackgroundImageUpdating,
    defaultData,
    districts,
    stations,
    isDarkMode,
    isViewing,
    isEditting,
    isSubmitting,
    // managers,
    // allUsers,
    onCreate,
    onUpdate,
    // onDelete,
    // onBackgroundImageUpdate,
    // onManagerCreate,
    // onManagerDelete,
    onClose,
  }) => {
    const [t] = useTranslation();

    const [data, handleFieldChange, setData] = useForm(() => ({
      name: '',
      phone: '',
      identifier: '',
      code: '',
      type: 'screen',
      simNumber: '',
      simSerial: '',
      accessToken: '',
      ssid: '',
      password: '',
      volume: DEFAULT_VOLUME,
      // address: '',
      description: '',
      stationId: '',
      lng: '',
      lat: '',
      status: 'new',
      ...pickBy(defaultData),
    }));

    const [step, openStep, handleBack] = useSteps();

    const cleanData = useMemo(
      () => ({
        ...data,
        name: data.name.trim(),
        phone: data.phone.trim() || undefined,
        code: data.code.trim() || undefined,
        simNumber: data.simNumber.trim() || undefined,
        simSerial: data.simSerial.trim() || undefined,
        accessToken: data.accessToken.trim() || undefined,
        ssid: data.ssid.trim() || undefined,
        password: data.password.trim() || undefined,
        identifier: data.identifier.trim() || undefined,
        volume: data.volume,
        // address: data.address.trim() || null,
        description: data.description.trim() || undefined,
        stationId: data.stationId.trim(),
        lng: data.lng,
        lat: data.lat,
        status: data.status,
      }),
      [data],
    );

    const nameField = useRef(null);
    const lngField = useRef(null);
    const latField = useRef(null);
    const districtRef = useRef(null);
    const stationRef = useRef(null);

    const handleCloseIcon = useCallback(
      (e) => {
        if (isEditting || isViewing || e.target.className.includes('close')) {
          onClose();
        }
      },
      [isEditting, isViewing, onClose],
    );

    const handleSubmit = useCallback(() => {
      Object.keys(cleanData).forEach(
        (key) => cleanData[key] === undefined && delete cleanData[key],
      );

      if (!cleanData.name) {
        nameField.current.select();
        return;
      }

      if (!cleanData.districtId) {
        districtRef.current.open();
        return;
      }

      if (!cleanData.stationId) {
        stationRef.current.open();
        return;
      }

      if (!cleanData.lat) {
        latField.current.select();

        return;
      }

      if (!cleanData.lng) {
        lngField.current.select();

        return;
      }

      if (isEditting) {
        onUpdate(cleanData.id, cleanData);
        onClose();
      } else {
        onCreate(cleanData);
        onClose();
      }
    }, [isEditting, onCreate, onUpdate, onClose, cleanData]);

    const handleOpenMap = useCallback(() => {
      openStep(StepTypes.VIEW_MAP);
    }, [openStep]);

    const handleSetLatLng = useCallback(
      (location) => {
        setData((prev) => ({ ...prev, ...location }));
      },
      [setData],
    );

    const handleSelectDeviceCode = useCallback(
      (_, { value }) => {
        setData((prev) => ({ ...prev, name: prev.name || value, code: value }));
      },
      [setData],
    );

    const handleRefreshToken = useCallback(() => {}, []);

    const defaultOption = { key: '1', value: '', text: t('common.selectDistrict') };

    const districtOptions = [
      defaultOption,
      ...districts.map(({ id, name }) => ({ key: id, value: id, text: name })),
    ];

    const stationOptions = stations
      .filter((it) => it.districtId === data.districtId)
      .map(({ id, name }) => ({ key: id, value: id, text: name }));

    const deviceCodeOptions = [1, 2, 3].map((i) => ({
      key: `CS${i}`,
      value: `CS${i}`,
      text: `CS${i}`,
    }));

    if (step) {
      if (step.type === StepTypes.VIEW_MAP) {
        return (
          <PickLocation
            title="common.selectDeviceLocation"
            currentLocation={{ lat: data.lat, lng: data.lng }}
            onBack={handleBack}
            onClick={handleSetLatLng}
            showAction={!isViewing}
          />
        );
      }
    }

    return (
      <Modal open closeIcon size="small" centered={false} onClose={handleCloseIcon}>
        <Modal.Header>
          <h4>
            <Image src={editIcon} width={40} />
            {t(
              // eslint-disable-next-line no-nested-ternary
              isEditting
                ? 'common.editDevice'
                : isViewing
                ? 'common.viewInfoDevice'
                : 'action.addDevice',
              {
                context: 'title',
              },
            )}
          </h4>
        </Modal.Header>
        <Modal.Content className={styles.wrapperContent}>
          <Form onSubmit={handleSubmit}>
            <div className={styles.topic}>{t('common.deviceInformation')}</div>
            <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <div className={styles.text}>
                  {t('common.deviceCode')}
                  <sup>*</sup>
                </div>
                <Dropdown
                  name="code"
                  value={data.code}
                  className={styles.borderDropdown}
                  fluid
                  search
                  selection
                  onChange={handleSelectDeviceCode}
                  options={deviceCodeOptions}
                  noResultsMessage={t('common.noResultsFound')}
                />
              </div>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.deviceName')}
                  <sup>*</sup>
                </span>
                <Input
                  ref={nameField}
                  name="name"
                  value={data.name}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
            </div>
            <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.identifier')}
                  <sup>*</sup>
                </span>
                <Input
                  name="identifier"
                  value={data.identifier}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
              <div className={styles.wrapperField}>
                <span className={styles.text}>{t('common.phone')}</span>
                <Input
                  name="phone"
                  value={data.phone}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
            </div>
            <Divider />
            <div className={styles.topic}>{t('common.locationInformation')}</div>
            <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.district')}
                  <sup>*</sup>
                </span>
                <Dropdown
                  ref={districtRef}
                  placeholder={t('common.selectDistrict')}
                  fluid
                  selection
                  name="districtId"
                  options={districtOptions}
                  value={data.districtId}
                  className={styles.borderDropdown}
                  onChange={handleFieldChange}
                />
              </div>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.stations')}
                  <sup>*</sup>
                </span>
                <Dropdown
                  ref={stationRef}
                  name="stationId"
                  placeholder={t('common.selectStation')}
                  className={styles.borderDropdown}
                  fluid
                  search
                  selection
                  options={stationOptions}
                  value={data.stationId}
                  onChange={handleFieldChange}
                  noResultsMessage={t('common.noResultsFound')}
                />
              </div>
            </div>
            <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.latitude')} <sup>*</sup>
                </span>
                <Input
                  type="number"
                  ref={latField}
                  placeholder="10.435257"
                  name="lat"
                  value={data.lat}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.longitude')}
                  <sup>*</sup>
                </span>
                <Input
                  type="number"
                  ref={lngField}
                  placeholder="108.435257"
                  name="lng"
                  value={data.lng}
                  className={styles.field}
                  onChange={handleFieldChange}
                  icon={
                    <Icon
                      name="map marker alternate"
                      size="large"
                      color="blue"
                      link
                      onClick={handleOpenMap}
                    />
                  }
                />
              </div>
            </div>
            <p style={{ marginTop: '4px', color: '#ccc' }}>{t('common.enterOrClickIcon')}</p>
            <Divider />
            <div className={styles.topic}>{t('common.sim4GInformation')}</div>
            <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.simNumber')}
                  <sup>*</sup>
                </span>
                <Input
                  name="simNumber"
                  value={data.simNumber}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.simSerial')}
                  <sup>*</sup>
                </span>
                <Input
                  name="simSerial"
                  value={data.simSerial}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
            </div>
            <Divider />
            <div className={styles.topic}>{t('common.wifiSetupInformation')}</div>
            <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  SSID
                  <sup>*</sup>
                </span>
                <Input
                  name="ssid"
                  fluid
                  value={data.SSID}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.password')}
                  <sup>*</sup>
                </span>
                <InputCustom.Password
                  name="password"
                  fluid
                  value={data.password}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
            </div>
            <Divider />
            <div className={styles.topic}>Thông tin khác</div>
            <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  Access Token
                  <sup>*</sup>
                </span>
                <Input
                  name="accessToken"
                  value={data.accessToken}
                  className={classNames(styles.field, styles.readOnly)}
                  onChange={handleFieldChange}
                  icon={<Icon name="repeat" link onClick={handleRefreshToken} />}
                />
              </div>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.status')}
                  <sup>*</sup>
                </span>
                <Dropdown
                  name="status"
                  placeholder={t('common.status')}
                  fluid
                  selection
                  className={styles.borderDropdown}
                  options={DeviceStatusOptions.map((it) => ({
                    ...it,
                    text: t(`common.${it.key}`),
                  }))}
                  value={data.status}
                  onChange={handleFieldChange}
                />
              </div>
              {(isEditting || isViewing) && (
                <div className={styles.wrapperField}>
                  <span className={styles.text}>
                    {t('common.volume')}
                    <sup>*</sup>
                  </span>
                  <Input
                    name="volume"
                    type="number"
                    max={100}
                    min={0}
                    className={styles.field}
                    value={data.volume}
                    onChange={handleFieldChange}
                  />
                </div>
              )}
            </div>
            <div className={styles.wrapperField}>
              <span className={styles.text}>{t('common.des')}</span>
              <TextArea
                rows={6}
                name="description"
                value={data.description}
                // placeholder="Mô tả"
                onChange={handleFieldChange}
              />
            </div>

            <div className={styles.actions} style={{ display: isViewing ? 'none' : '' }}>
              <Button type="button" positive className={styles.button} onClick={onClose}>
                <span>{t('action.cancel')}</span>
              </Button>
              <Button
                loading={isSubmitting}
                positive
                disabled={dequal(data.districtId, '')}
                className={styles.button}
              >
                <span>{t('action.save')}</span>
              </Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  },
);

DisplayAddModal.propTypes = {
  // name: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  // background: PropTypes.object,
  // backgroundImage: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
  // isBackgroundImageUpdating: PropTypes.bool.isRequired,
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  districts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  stations: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  isDarkMode: PropTypes.bool.isRequired,
  isViewing: PropTypes.bool.isRequired,
  isEditting: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  /* eslint-disable react/forbid-prop-types */
  // managers: PropTypes.array.isRequired,
  // allUsers: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  // onBackgroundImageUpdate: PropTypes.func.isRequired,
  // onDelete: PropTypes.func.isRequired,
  // onManagerCreate: PropTypes.func.isRequired,
  // onManagerDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

DisplayAddModal.defaultProps = {
  // background: undefined,
  // backgroundImage: undefined,
};

export default DisplayAddModal;
