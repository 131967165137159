import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import UserGroups from '../components/UserGroups';

const mapStateToProps = (state) => {
  const userGroups = selectors.selectUserGroupsForCurrentProvince(state);
  const { isAdmin, level, permissions } = selectors.selectCurrentUser(state);
  const { userGroupTotal } = selectors.selectCurrentProvince(state);

  const { error } = state.ui.userGroupForm;

  return {
    items: userGroups,
    isAdmin,
    total: userGroupTotal,
    canDelete: isAdmin && level === 'province' && permissions.includes('CAN_DELETE'),
    error,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onDelete: entryActions.deleteUserGroup,
      onFetch: entryActions.fetchUserGroups,
      onClear: entryActions.clearUserGroupError,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserGroups);
