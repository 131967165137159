import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import StationModal from '../components/StationModal';

const mapStateToProps = (state, { selectedId }) => {
  const { data: defaultData, isSubmitting, error } = state.ui.stationCreateForm;
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const wards = selectors.selectWardsForCurrentProvince(state);

  const { permissions } = selectors.selectCurrentUser(state);
  const isDarkMode = selectors.selectIsDarkMode(state);
  let station;
  if (selectedId) {
    station = selectors.selectStationById(state, selectedId);
  }

  return {
    defaultData: { ...defaultData, ...station },
    districts,
    wards,
    isSubmitting,
    isDarkMode,
    isEditting: selectedId !== undefined,
    canAdd: permissions.includes('CAN_ADD'),
    canEdit: permissions.includes('CAN_EDIT'),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createStationInCurrentDistrict,
      onUpdate: entryActions.updateStation,
      onFetchStations: entryActions.fetchStations,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(StationModal);
