import ActionTypes from '../constants/ActionTypes';

const fetchActions = (provinceId, filter) => ({
  type: ActionTypes.ACTIONS_FETCH,
  payload: { provinceId, filter },
});

fetchActions.success = (actions, total, users, contents, stations, devices, provinceId) => ({
  type: ActionTypes.ACTIONS_FETCH__SUCCESS,
  payload: { actions, total, users, contents, stations, devices, provinceId },
});

fetchActions.failure = (error) => ({
  type: ActionTypes.ACTIONS_FETCH__FAILURE,
  payload: { error },
});

export default {
  fetchActions,
};
