import http from './http';
// import { transformSchedule } from './schedules';
import socket from './socket';

/* Actions */

const getProvinces = (headers) => socket.get('/provinces', undefined, headers);

const createProvince = (data, headers) => socket.post('/provinces', data, headers);

const getProvince = (id, headers) => socket.get(`/provinces/${id}`, undefined, headers);
// socket.get(`/provinces/${id}`, undefined, headers).then((body) => ({
//   ...body,
//   included: {
//     ...body.included,
//     // schedules: body.included.schedules.map(transformSchedule),
//   },
// }));

const updateProvince = (id, data, headers) => socket.patch(`/provinces/${id}`, data, headers);

const updateProvinceBackgroundImage = (id, data, headers) =>
  http.post(`/provinces/${id}/background-image`, data, headers);

const deleteProvince = (id, headers) => socket.delete(`/provinces/${id}`, undefined, headers);

export default {
  getProvinces,
  createProvince,
  getProvince,
  updateProvince,
  updateProvinceBackgroundImage,
  deleteProvince,
};
