import { call } from 'redux-saga/effects';

import { fetchProvinceByCurrentPath } from './provinces';
import request from '../request';
import api from '../../../api';
import mergeRecords from '../../../utils/merge-records';
import Config from '../../../constants/Config';

export function* fetchCore() {
  const { item: user } = yield call(request, api.getCurrentUser, true);
  const {
    items: users1,
    included: { permissions },
  } = yield call(request, api.getUsers);

  const {
    items: provinces1,
    included: { provinceManagers },
  } = yield call(request, api.getProvinces);

  let province;
  let district;
  let device;
  let users2;
  let provinces2;
  let districts;
  let wards;
  let hamlets;
  let districtMemberships2;
  let categories;
  let contents;
  let contentCategories;
  let schedules;
  let stations;
  let devices1;
  let deviceMemberships;
  let deviceContents;
  let tasks;
  let attachments;
  let totals;
  let contentLocations;
  let contentSchedules;
  let wardMemberships;
  let userGroups;

  try {
    ({
      device,
      users: users2,
      province,
      provinces: provinces2,
      districts,
      districtMemberships: districtMemberships2,
      wards,
      wardMemberships,
      hamlets,
      categories,
      contents,
      contentCategories,
      schedules,
      stations,
      devices: devices1,
      deviceMemberships,
      deviceContents,
      tasks,
      attachments,
      totals,
      contentLocations,
      contentSchedules,
      userGroups,
    } = yield call(fetchProvinceByCurrentPath, provinces1));
  } catch (error) {
    console.error(error);
  } // eslint-disable-line no-empty

  const body = yield call(request, api.getNotifications, {
    type: 'unread',
    page: 1,
    limit: Config.LIMIT_NOTIFICATION,
  });

  let { items: notifications } = body;

  const {
    included: {
      users: users3,
      devices: devices2,
      activities,
      contents: contents2,
      stations: stations2,
      unreadTotal,
      total: notificationTotal,
    },
  } = body;

  province = { ...province, unreadTotal, notificationTotal };

  if (device) {
    const notificationIds = notifications.flatMap((notification) =>
      notification.deviceId === device.id ? [notification.id] : [],
    );

    if (notificationIds.length > 0) {
      yield call(request, api.updateNotifications, notificationIds, {
        isRead: true,
      });

      notifications = notifications.filter(
        (notification) => !notificationIds.includes(notification.id),
      );
    }
  }

  return {
    user,
    district,
    province,
    provinceManagers,
    districts,
    wards,
    wardMemberships,
    hamlets,
    categories,
    contents: mergeRecords(contents, contents2),
    contentCategories,
    schedules,
    stations: mergeRecords(stations, stations2),
    deviceMemberships,
    deviceContents,
    tasks,
    attachments,
    activities,
    notifications,
    users: mergeRecords(users1, users2, users3),
    provinces: mergeRecords(provinces1, provinces2),
    districtMemberships: districtMemberships2,
    devices: mergeRecords(devices1, devices2),
    totals,
    contentLocations,
    contentSchedules,
    userGroups,
    permissions,
  };
}

export default {
  fetchCore,
};
