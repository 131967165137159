import EntryActionTypes from '../constants/EntryActionTypes';

const initializeCore = () => ({
  type: EntryActionTypes.CORE_INITIALIZE,
  payload: {},
});

const logout = () => ({
  type: EntryActionTypes.LOGOUT,
  payload: {},
});

const changeSidebarMenu = (type) => ({
  type: EntryActionTypes.SIDEBAR_MENU,
  payload: type,
});

const selectContentId = (id) => ({
  type: EntryActionTypes.SELECT_CONTENT_ID,
  payload: { id },
});

export default {
  initializeCore,
  logout,
  changeSidebarMenu,
  selectContentId,
};
