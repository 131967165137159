const PREFIX = '@entry';

export default {
  PREFIX,

  /* Socket */

  SOCKET_DISCONNECT_HANDLE: `${PREFIX}/SOCKET_DISCONNECT_HANDLE`,
  SOCKET_RECONNECT_HANDLE: `${PREFIX}/SOCKET_RECONNECT_HANDLE`,

  /* Login */

  AUTHENTICATE: `${PREFIX}/AUTHENTICATE`,
  AUTHENTICATE_CODE: `${PREFIX}/AUTHENTICATE_CODE`,
  AUTHENTICATE_QRCODE: `${PREFIX}/AUTHENTICATE_QRCODE`,
  AUTHENTICATE_ERROR_CLEAR: `${PREFIX}/AUTHENTICATE_ERROR_CLEAR`,
  AUTHENTICATE_CODE_RETRIEVE: `${PREFIX}/AUTHENTICATE_CODE_RETRIEVE`,
  AUTHENTICATE_QRCODE_RETRIEVE: `${PREFIX}/AUTHENTICATE_QRCODE_RETRIEVE`,
  AUTHENTICATE_COUNT_DOWN_CLEAR: `${PREFIX}/AUTHENTICATE_COUNT_DOWN_CLEAR`,

  /* Core */

  CORE_INITIALIZE: `${PREFIX}/CORE_INITIALIZE`,
  LOGOUT: `${PREFIX}/LOGOUT`,

  /* Modals */

  MODAL_OPEN: `${PREFIX}/MODAL_OPEN`,
  MODAL_CLOSE: `${PREFIX}/MODAL_CLOSE`,

  /* Sidebar */

  SIDEBAR_MENU: `${PREFIX}/SIDEBAR_MENU`,

  /* Users */

  USER_CREATE: `${PREFIX}/USER_CREATE`,
  USER_CREATE_HANDLE: `${PREFIX}/USER_CREATE_HANDLE`,
  USER_CREATE_ERROR_CLEAR: `${PREFIX}/USER_CREATE_ERROR_CLEAR`,
  USER_UPDATE: `${PREFIX}/USER_UPDATE`,
  CURRENT_USER_UPDATE: `${PREFIX}/CURRENT_USER_UPDATE`,
  USER_UPDATE_HANDLE: `${PREFIX}/USER_UPDATE_HANDLE`,
  CURRENT_USER_LANGUAGE_UPDATE: `${PREFIX}/CURRENT_USER_LANGUAGE_UPDATE`,
  USER_EMAIL_UPDATE: `${PREFIX}/USER_EMAIL_UPDATE`,
  CURRENT_USER_EMAIL_UPDATE: `${PREFIX}/CURRENT_USER_EMAIL_UPDATE`,
  USER_EMAIL_UPDATE_ERROR_CLEAR: `${PREFIX}/USER_EMAIL_UPDATE_ERROR_CLEAR`,
  CURRENT_USER_EMAIL_UPDATE_ERROR_CLEAR: `${PREFIX}/CURRENT_USER_EMAIL_UPDATE_ERROR_CLEAR`,
  USER_PASSWORD_UPDATE: `${PREFIX}/USER_PASSWORD_UPDATE`,
  CURRENT_USER_PASSWORD_UPDATE: `${PREFIX}/CURRENT_USER_PASSWORD_UPDATE`,
  USER_PASSWORD_UPDATE_ERROR_CLEAR: `${PREFIX}/USER_PASSWORD_UPDATE_ERROR_CLEAR`,
  CURRENT_USER_PASSWORD_UPDATE_ERROR_CLEAR: `${PREFIX}/CURRENT_USER_PASSWORD_UPDATE_ERROR_CLEAR`,
  USER_USERNAME_UPDATE: `${PREFIX}/USER_USERNAME_UPDATE`,
  CURRENT_USER_USERNAME_UPDATE: `${PREFIX}/CURRENT_USER_USERNAME_UPDATE`,
  USER_PERMISSION_UPDATE: `${PREFIX}/USER_PERMISSION_UPDATE`,
  CURRENT_USER_PERMISSION_UPDATE: `${PREFIX}/CURRENT_USER_PERMISSION_UPDATE`,
  USER_PERMISSION_UPDATE_ERROR_CLEAR: `${PREFIX}/USER_PERMISSION_UPDATE_ERROR_CLEAR`,
  USER_USERNAME_UPDATE_ERROR_CLEAR: `${PREFIX}/USER_USERNAME_UPDATE_ERROR_CLEAR`,
  CURRENT_USER_USERNAME_UPDATE_ERROR_CLEAR: `${PREFIX}/CURRENT_USER_USERNAME_UPDATE_ERROR_CLEAR`,
  CURRENT_USER_AVATAR_UPDATE: `${PREFIX}/CURRENT_USER_AVATAR_UPDATE`,
  USER_DELETE: `${PREFIX}/USER_DELETE`,
  USER_DELETE_HANDLE: `${PREFIX}/USER_DELETE_HANDLE`,
  USER_TO_DEVICE_ADD: `${PREFIX}/USER_TO_DEVICE_ADD`,
  USER_TO_CURRENT_DEVICE_ADD: `${PREFIX}/USER_TO_CURRENT_DEVICE_ADD`,
  USER_TO_DEVICE_ADD_HANDLE: `${PREFIX}/USER_TO_DEVICE_ADD_HANDLE`,
  USER_FROM_DEVICE_REMOVE: `${PREFIX}/USER_FROM_DEVICE_REMOVE`,
  USER_FROM_CURRENT_DEVICE_REMOVE: `${PREFIX}/USER_FROM_CURRENT_DEVICE_REMOVE`,
  USER_FROM_DEVICE_REMOVE_HANDLE: `${PREFIX}/USER_FROM_DEVICE_REMOVE_HANDLE`,
  USER_TO_FILTER_IN_CURRENT_DISTRICT_ADD: `${PREFIX}/USER_TO_FILTER_IN_CURRENT_DISTRICT_ADD`,
  USER_FROM_FILTER_IN_CURRENT_DISTRICT_REMOVE: `${PREFIX}/USER_FROM_FILTER_IN_CURRENT_DISTRICT_REMOVE`,

  /* User groups */

  USER_GROUP_CREATE: `${PREFIX}/USER_GROUP_CREATE`,
  USER_GROUP_CREATE_HANDLE: `${PREFIX}/USER_GROUP_CREATE_HANDLE`,
  USER_GROUP_UPDATE: `${PREFIX}/USER_GROUP_UPDATE`,
  CURRENT_USER_GROUP_UPDATE: `${PREFIX}/CURRENT_USER_GROUP_UPDATE`,
  USER_GROUP_UPDATE_HANDLE: `${PREFIX}/USER_GROUP_UPDATE_HANDLE`,
  USER_GROUP_DELETE: `${PREFIX}/USER_GROUP_DELETE`,
  USER_GROUP_DELETE_HANDLE: `${PREFIX}/USER_GROUP_DELETE_HANDLE`,
  USER_GROUP_ERROR_CLEAR: `${PREFIX}/USER_GROUP_ERROR_CLEAR`,
  USER_GROUPS_FETCH: `${PREFIX}/USER_GROUPS_FETCH`,

  /* User permission */

  USER_PERMISSION_CREATE_HANDLE: `${PREFIX}/USER_PERMISSION_CREATE_HANDLE`,
  USER_PERMISSION_UPDATE_HANDLE: `${PREFIX}/USER_PERMISSION_UPDATE_HANDLE`,

  /* Provinces */

  PROVINCE_CREATE: `${PREFIX}/PROVINCE_CREATE`,
  PROVINCE_CREATE_HANDLE: `${PREFIX}/PROVINCE_CREATE_HANDLE`,
  CURRENT_PROVINCE_UPDATE: `${PREFIX}/CURRENT_PROVINCE_UPDATE`,
  PROVINCE_UPDATE_HANDLE: `${PREFIX}/PROVINCE_UPDATE_HANDLE`,
  CURRENT_PROVINCE_BACKGROUND_IMAGE_UPDATE: `${PREFIX}/CURRENT_PROVINCE_BACKGROUND_IMAGE_UPDATE`,
  CURRENT_PROVINCE_DELETE: `${PREFIX}/CURRENT_PROVINCE_DELETE`,
  PROVINCE_DELETE_HANDLE: `${PREFIX}/PROVINCE_DELETE_HANDLE`,

  /* Province managers */

  MANAGER_IN_CURRENT_PROVINCE_CREATE: `${PREFIX}/MANAGER_IN_CURRENT_PROVINCE_CREATE`,
  PROVINCE_MANAGER_CREATE_HANDLE: `${PREFIX}/PROVINCE_MANAGER_CREATE_HANDLE`,
  PROVINCE_MANAGER_DELETE: `${PREFIX}/PROVINCE_MANAGER_DELETE`,
  PROVINCE_MANAGER_DELETE_HANDLE: `${PREFIX}/PROVINCE_MANAGER_DELETE_HANDLE`,

  /* Districts */

  DISTRICT_IN_CURRENT_PROVINCE_CREATE: `${PREFIX}/DISTRICT_IN_CURRENT_PROVINCE_CREATE`,
  DISTRICT_CREATE_HANDLE: `${PREFIX}/DISTRICT_CREATE_HANDLE`,
  DISTRICT_FETCH: `${PREFIX}/DISTRICT_FETCH`,
  DISTRICT_UPDATE: `${PREFIX}/DISTRICT_UPDATE`,
  DISTRICT_UPDATE_HANDLE: `${PREFIX}/DISTRICT_UPDATE_HANDLE`,
  DISTRICT_MOVE: `${PREFIX}/DISTRICT_MOVE`,
  DISTRICT_DELETE: `${PREFIX}/DISTRICT_DELETE`,
  DISTRICT_DELETE_HANDLE: `${PREFIX}/DISTRICT_DELETE_HANDLE`,

  /* District memberships */

  MEMBERSHIP_IN_CURRENT_DISTRICT_CREATE: `${PREFIX}/MEMBERSHIP_IN_CURRENT_DISTRICT_CREATE`,
  DISTRICT_MEMBERSHIP_CREATE_HANDLE: `${PREFIX}/DISTRICT_MEMBERSHIP_CREATE_HANDLE`,
  DISTRICT_MEMBERSHIP_UPDATE: `${PREFIX}/DISTRICT_MEMBERSHIP_UPDATE`,
  DISTRICT_MEMBERSHIP_UPDATE_HANDLE: `${PREFIX}/DISTRICT_MEMBERSHIP_UPDATE_HANDLE`,
  DISTRICT_MEMBERSHIP_DELETE: `${PREFIX}/DISTRICT_MEMBERSHIP_DELETE`,
  DISTRICT_MEMBERSHIP_DELETE_HANDLE: `${PREFIX}/DISTRICT_MEMBERSHIP_DELETE_HANDLE`,

  /* Wards */

  WARD_IN_CURRENT_PROVINCE_CREATE: `${PREFIX}/WARD_IN_CURRENT_PROVINCE_CREATE`,
  WARD_CREATE_HANDLE: `${PREFIX}/WARD_CREATE_HANDLE`,
  WARD_FETCH: `${PREFIX}/WARD_FETCH`,
  WARD_UPDATE: `${PREFIX}/WARD_UPDATE`,
  WARD_UPDATE_HANDLE: `${PREFIX}/WARD_UPDATE_HANDLE`,
  WARD_MOVE: `${PREFIX}/WARD_MOVE`,
  WARD_DELETE: `${PREFIX}/WARD_DELETE`,
  WARD_DELETE_HANDLE: `${PREFIX}/WARD_DELETE_HANDLE`,

  /* District memberships */

  MEMBERSHIP_IN_CURRENT_WARD_CREATE: `${PREFIX}/MEMBERSHIP_IN_CURRENT_WARD_CREATE`,
  WARD_MEMBERSHIP_CREATE_HANDLE: `${PREFIX}/WARD_MEMBERSHIP_CREATE_HANDLE`,
  WARD_MEMBERSHIP_UPDATE: `${PREFIX}/WARD_MEMBERSHIP_UPDATE`,
  WARD_MEMBERSHIP_UPDATE_HANDLE: `${PREFIX}/WARD_MEMBERSHIP_UPDATE_HANDLE`,
  WARD_MEMBERSHIP_DELETE: `${PREFIX}/WARD_MEMBERSHIP_DELETE`,
  WARD_MEMBERSHIP_DELETE_HANDLE: `${PREFIX}/WARD_MEMBERSHIP_DELETE_HANDLE`,

  /* Hamlets */

  HAMLET_IN_CURRENT_PROVINCE_CREATE: `${PREFIX}/HAMLET_IN_CURRENT_PROVINCE_CREATE`,
  HAMLET_CREATE_HANDLE: `${PREFIX}/HAMLET_CREATE_HANDLE`,
  HAMLET_FETCH: `${PREFIX}/HAMLET_FETCH`,
  HAMLET_UPDATE: `${PREFIX}/HAMLET_UPDATE`,
  HAMLET_UPDATE_HANDLE: `${PREFIX}/HAMLET_UPDATE_HANDLE`,
  HAMLET_MOVE: `${PREFIX}/HAMLET_MOVE`,
  HAMLET_DELETE: `${PREFIX}/HAMLET_DELETE`,
  HAMLET_DELETE_HANDLE: `${PREFIX}/HAMLET_DELETE_HANDLE`,

  /* Categories */

  CATEGORY_IN_CURRENT_PROVINCE_CREATE: `${PREFIX}/CATEGORY_IN_CURRENT_PROVINCE_CREATE`,
  CATEGORY_CREATE_HANDLE: `${PREFIX}/CATEGORY_CREATE_HANDLE`,
  CATEGORY_UPDATE: `${PREFIX}/CATEGORY_UPDATE`,
  CATEGORY_UPDATE_HANDLE: `${PREFIX}/CATEGORY_UPDATE_HANDLE`,
  CATEGORY_MOVE: `${PREFIX}/CATEGORY_MOVE`,
  CATEGORY_DELETE: `${PREFIX}/CATEGORY_DELETE`,
  CATEGORY_DELETE_HANDLE: `${PREFIX}/CATEGORY_DELETE_HANDLE`,

  /* Contents */

  CONTENT_IN_CURRENT_DISTRICT_CREATE: `${PREFIX}/CONTENT_IN_CURRENT_DISTRICT_CREATE`,
  CONTENT_IN_CURRENT_PROVINCE_CREATE: `${PREFIX}/CONTENT_IN_CURRENT_PROVINCE_CREATE`,
  CONTENT_CREATE_HANDLE: `${PREFIX}/CONTENT_CREATE_HANDLE`,
  CONTENTS_FETCH: `${PREFIX}/CONTENTS_FETCH`,
  CONTENTS_SEARCH: `${PREFIX}/CONTENTS_SEARCH`,
  CONTENT_SELECT: `${PREFIX}/CONTENT_SELECT`,
  CONTENT_UPDATE: `${PREFIX}/CONTENT_UPDATE`,
  CONTENT_UPDATE_HANDLE: `${PREFIX}/CONTENT_UPDATE_HANDLE`,
  CONTENT_MOVE: `${PREFIX}/CONTENT_MOVE`,
  CONTENT_DELETE: `${PREFIX}/CONTENT_DELETE`,
  CONTENT_DELETE_HANDLE: `${PREFIX}/CONTENT_DELETE_HANDLE`,
  CONTENT_CANCEL_SCHEDULE_HANDLE: `${PREFIX}/CONTENT_CANCEL_SCHEDULE_HANDLE`,
  CONTENT_TEXT_TO_SPEECH: `${PREFIX}/CONTENT_TEXT_TO_SPEECH`,
  CONTENT_TO_DEVICE_ADD: `${PREFIX}/CONTENT_TO_DEVICE_ADD`,
  CONTENT_TO_CURRENT_DEVICE_ADD: `${PREFIX}/CONTENT_TO_CURRENT_DEVICE_ADD`,
  CONTENT_TO_DEVICE_ADD_HANDLE: `${PREFIX}/CONTENT_TO_DEVICE_ADD_HANDLE`,
  CONTENT_FROM_DEVICE_REMOVE: `${PREFIX}/CONTENT_FROM_DEVICE_REMOVE`,
  CONTENT_FROM_CURRENT_DEVICE_REMOVE: `${PREFIX}/CONTENT_FROM_CURRENT_DEVICE_REMOVE`,
  CONTENT_FROM_DEVICE_REMOVE_HANDLE: `${PREFIX}/CONTENT_FROM_DEVICE_REMOVE_HANDLE`,
  CONTENT_TO_FILTER_IN_CURRENT_DISTRICT_ADD: `${PREFIX}/CONTENT_TO_FILTER_IN_CURRENT_DISTRICT_ADD`,
  CONTENT_FROM_FILTER_IN_CURRENT_DISTRICT_REMOVE: `${PREFIX}/CONTENT_FROM_FILTER_IN_CURRENT_DISTRICT_REMOVE`,
  CONTENT_ERROR_CLEAR: `${PREFIX}/CONTENT_ERROR_CLEAR`,
  CONTENT_PLAYLIST_CLEAR: `${PREFIX}/CONTENT_PLAYLIST_CLEAR`,
  SELECT_CONTENT_ID: `${PREFIX}/SELECT_CONTENT_ID`,
  CONTENT_CANCEL_SCHEDULE: `${PREFIX}/CONTENT_CANCEL_SCHEDULE`,
  CONTENT_FETCH_STATIONS_BROADCASTED: `${PREFIX}/CONTENT_FETCH_STATIONS_BROADCASTED`,
  CONTENT_CONCAT_FILE: `${PREFIX}/CONTENT_CONCAT_FILE`,
  CONTENT_CONCAT_FILE__CLEAR: `${PREFIX}/CONTENT_CONCAT_FILE__CLEAR`,
  ENABLE_CONTENTS_FETCHING: `${PREFIX}/ENABLE_CONTENTS_FETCHING`,

  /* Content Category */

  CONTENT_CATEGORY_CREATE_HANDLE: `${PREFIX}/CONTENT_CATEGORY_CREATE_HANDLE`,
  CONTENT_CATEGORY_UPDATE_HANDLE: `${PREFIX}/CONTENT_CATEGORY_UPDATE_HANDLE`,
  CONTENT_CATEGORY_DELETE_HANDLE: `${PREFIX}/CONTENT_CATEGORY_DELETE_HANDLE`,

  /* Content Location */

  CONTENT_LOCATION_CREATE_HANDLE: `${PREFIX}/CONTENT_LOCATION_CREATE_HANDLE`,
  CONTENT_LOCATION_DELETE_HANDLE: `${PREFIX}/CONTENT_LOCATION_DELETE_HANDLE`,
  CONTENT_LOCATION_UPDATE_HANDLE: `${PREFIX}/CONTENT_LOCATION_UPDATE_HANDLE`,

  /* Schedules */

  SCHEDULE_IN_CURRENT_DISTRICT_CREATE: `${PREFIX}/SCHEDULE_IN_CURRENT_DISTRICT_CREATE`,
  SCHEDULE_IN_CURRENT_PROVINCE_CREATE: `${PREFIX}/SCHEDULE_IN_CURRENT_PROVINCE_CREATE`,
  SCHEDULE_CREATE_HANDLE: `${PREFIX}/SCHEDULE_CREATE_HANDLE`,
  SCHEDULES_FETCH: `${PREFIX}/SCHEDULES_FETCH`,
  SCHEDULE_UPDATE: `${PREFIX}/SCHEDULE_UPDATE`,
  SCHEDULE_UPDATE_HANDLE: `${PREFIX}/SCHEDULE_UPDATE_HANDLE`,
  SCHEDULE_MOVE: `${PREFIX}/SCHEDULE_MOVE`,
  SCHEDULE_DELETE: `${PREFIX}/SCHEDULE_DELETE`,
  SCHEDULE_DELETE_HANDLE: `${PREFIX}/SCHEDULE_DELETE_HANDLE`,
  SCHEDULE_TO_DEVICE_ADD: `${PREFIX}/SCHEDULE_TO_DEVICE_ADD`,
  SCHEDULE_TO_CURRENT_DEVICE_ADD: `${PREFIX}/SCHEDULE_TO_CURRENT_DEVICE_ADD`,
  SCHEDULE_TO_DEVICE_ADD_HANDLE: `${PREFIX}/SCHEDULE_TO_DEVICE_ADD_HANDLE`,
  SCHEDULE_FROM_DEVICE_REMOVE: `${PREFIX}/SCHEDULE_FROM_DEVICE_REMOVE`,
  SCHEDULE_FROM_CURRENT_DEVICE_REMOVE: `${PREFIX}/SCHEDULE_FROM_CURRENT_DEVICE_REMOVE`,
  SCHEDULE_FROM_DEVICE_REMOVE_HANDLE: `${PREFIX}/SCHEDULE_FROM_DEVICE_REMOVE_HANDLE`,
  SCHEDULE_TO_FILTER_IN_CURRENT_DISTRICT_ADD: `${PREFIX}/SCHEDULE_TO_FILTER_IN_CURRENT_DISTRICT_ADD`,
  SCHEDULE_FROM_FILTER_IN_CURRENT_DISTRICT_REMOVE: `${PREFIX}/SCHEDULE_FROM_FILTER_IN_CURRENT_DISTRICT_REMOVE`,
  SCHEDULE_ERROR_CLEAR: `${PREFIX}/SCHEDULE_ERROR_CLEAR`,

  /* Stations */

  STATION_IN_CURRENT_DISTRICT_CREATE: `${PREFIX}/STATION_IN_CURRENT_DISTRICT_CREATE`,
  STATION_CREATE_HANDLE: `${PREFIX}/STATION_CREATE_HANDLE`,
  STATIONS_FETCH: `${PREFIX}/STATIONS_FETCH`,
  STATIONS_LOGS_FETCH: `${PREFIX}/STATIONS_LOGS_FETCH`,
  STATION_UPDATE: `${PREFIX}/STATION_UPDATE`,
  STATION_UPDATE_HANDLE: `${PREFIX}/STATION_UPDATE_HANDLE`,
  STATION_MOVE: `${PREFIX}/STATION_MOVE`,
  STATION_DELETE: `${PREFIX}/STATION_DELETE`,
  STATION_LOG_DELETE: `${PREFIX}/STATION_LOG_DELETE`,
  STATION_DELETE_HANDLE: `${PREFIX}/STATION_DELETE_HANDLE`,
  STATION_ERROR_CLEAR: `${PREFIX}/STATION_ERROR_CLEAR`,

  /* Devices */

  DEVICE_CREATE: `${PREFIX}/DEVICE_CREATE`,
  DEVICE_CREATE_HANDLE: `${PREFIX}/DEVICE_CREATE_HANDLE`,
  DEVICES_FETCH: `${PREFIX}/DEVICES_FETCH`,
  DEVICE_UPDATE: `${PREFIX}/DEVICE_UPDATE`,
  CURRENT_DEVICE_UPDATE: `${PREFIX}/CURRENT_DEVICE_UPDATE`,
  DEVICE_UPDATE_HANDLE: `${PREFIX}/DEVICE_UPDATE_HANDLE`,
  DEVICE_MOVE: `${PREFIX}/DEVICE_MOVE`,
  CURRENT_DEVICE_MOVE: `${PREFIX}/CURRENT_DEVICE_MOVE`,
  DEVICE_TRANSFER: `${PREFIX}/DEVICE_TRANSFER`,
  CURRENT_DEVICE_TRANSFER: `${PREFIX}/CURRENT_DEVICE_TRANSFER`,
  DEVICE_DELETE: `${PREFIX}/DEVICE_DELETE`,
  CURRENT_DEVICE_DELETE: `${PREFIX}/CURRENT_DEVICE_DELETE`,
  DEVICE_DELETE_HANDLE: `${PREFIX}/DEVICE_DELETE_HANDLE`,
  DEVICE_RESTART: `${PREFIX}/DEVICE_RESTART`,
  DEVICES_CONTROL: `${PREFIX}/DEVICES_CONTROL`,

  /* Tasks */

  TASK_IN_CURRENT_DEVICE_CREATE: `${PREFIX}/TASK_IN_CURRENT_DEVICE_CREATE`,
  TASK_CREATE_HANDLE: `${PREFIX}/TASK_CREATE_HANDLE`,
  TASK_UPDATE: `${PREFIX}/TASK_UPDATE`,
  TASK_UPDATE_HANDLE: `${PREFIX}/TASK_UPDATE_HANDLE`,
  TASK_MOVE: `${PREFIX}/TASK_MOVE`,
  TASK_DELETE: `${PREFIX}/TASK_DELETE`,
  TASK_DELETE_HANDLE: `${PREFIX}/TASK_DELETE_HANDLE`,

  /* Attachments */

  ATTACHMENT_TO_CONTENT_CREATE: `${PREFIX}/ATTACHMENT_TO_CONTENT_CREATE`,
  ATTACHMENT_IN_CURRENT_DEVICE_CREATE: `${PREFIX}/ATTACHMENT_IN_CURRENT_DEVICE_CREATE`,
  ATTACHMENT_CREATE_HANDLE: `${PREFIX}/ATTACHMENT_CREATE_HANDLE`,
  ATTACHMENT_UPDATE: `${PREFIX}/ATTACHMENT_UPDATE`,
  ATTACHMENT_UPDATE_HANDLE: `${PREFIX}/ATTACHMENT_UPDATE_HANDLE`,
  ATTACHMENT_DELETE: `${PREFIX}/ATTACHMENT_DELETE`,
  ATTACHMENT_DELETE_HANDLE: `${PREFIX}/ATTACHMENT_DELETE_HANDLE`,

  /* Activities */

  ACTIVITIES_IN_CURRENT_DEVICE_FETCH: `${PREFIX}/ACTIVITIES_IN_CURRENT_DEVICE_FETCH`,
  ACTIVITIES_DETAILS_IN_CURRENT_DEVICE_TOGGLE: `${PREFIX}/ACTIVITIES_DETAILS_IN_CURRENT_DEVICE_TOGGLE`,
  ACTIVITY_CREATE_HANDLE: `${PREFIX}/ACTIVITY_CREATE_HANDLE`,
  ACTIVITY_UPDATE_HANDLE: `${PREFIX}/ACTIVITY_UPDATE_HANDLE`,
  ACTIVITY_DELETE_HANDLE: `${PREFIX}/ACTIVITY_DELETE_HANDLE`,

  /* Comment activities */

  COMMENT_ACTIVITY_IN_CURRENT_DEVICE_CREATE: `${PREFIX}/COMMENT_ACTIVITY_IN_CURRENT_DEVICE_CREATE`,
  COMMENT_ACTIVITY_UPDATE: `${PREFIX}/COMMENT_ACTIVITY_UPDATE`,
  COMMENT_ACTIVITY_DELETE: `${PREFIX}/COMMENT_ACTIVITY_DELETE`,

  /* Notifications */

  NOTIFICATION_CREATE_HANDLE: `${PREFIX}/NOTIFICATION_CREATE_HANDLE`,
  NOTIFICATION_UPDATE: `${PREFIX}/NOTIFICATION_UPDATE`,
  NOTIFICATION_DELETE: `${PREFIX}/NOTIFICATION_DELETE`,
  NOTIFICATION_DELETE_HANDLE: `${PREFIX}/NOTIFICATION_DELETE_HANDLE`,
  NOTIFICATION_FETCH: `${PREFIX}/NOTIFICATION_FETCH`,

  /* Live broadcast */

  LIVE_BROADCAST_START: `${PREFIX}/LIVE_BROADCAST_START`,
  LIVE_BROADCAST_STOP: `${PREFIX}/LIVE_BROADCAST_STOP`,
  LIVE_BROADCAST_ERROR_HANDLE: `${PREFIX}/LIVE_BROADCAST_ERROR_HANDLE`,
  LIVE_BROADCAST_FINISHED_HANDLE: `${PREFIX}/LIVE_BROADCAST_FINISHED_HANDLE`,
  LIVE_BROADCAST_CLEAR: `${PREFIX}/LIVE_BROADCAST_CLEAR`,
  LIVE_BROADCAST_MESSAGE_CLOSE: `${PREFIX}/LIVE_BROADCAST_MESSAGE_CLOSE`,
  OVERRIDE_CONTENT_BY_MICROPHONE: `${PREFIX}/OVERRIDE_CONTENT_BY_MICROPHONE`,

  /* Reports */

  REPORTS_FETCH: `${PREFIX}/REPORTS_FETCH`,
  REPORTS_EXPORT: `${PREFIX}/REPORTS_EXPORT`,
  REPORTS_EXPORT_CLEAR: `${PREFIX}/REPORTS_EXPORT_CLEAR`,

  /* Actions */

  ACTIONS_FETCH: `${PREFIX}/ACTIONS_FETCH`,
};
