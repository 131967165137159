import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { uniq } from 'lodash';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import { DistrictMembershipRoles } from '../constants/Enums';
import Config from '../constants/Config';

import DistrictActions from '../components/DistrictActions';

const mapStateToProps = (state) => {
  const allUsers = selectors.selectUsers(state);
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);
  let memberships = selectors.selectMembershipsForCurrentDistrict(state);
  const contents = selectors.selectContentsForCurrentDistrict(state);
  const filterUsers = selectors.selectFilterUsersForCurrentDistrict(state);
  const filterContents = selectors.selectFilterContentsForCurrentDistrict(state);
  const currentUserMembership = selectors.selectCurrentUserMembershipForCurrentDistrict(state);
  const { isAdmin, email } = selectors.selectCurrentUser(state);

  const wardMemberships = selectors.selectWardMemberships(state);
  const districtMembershipIds = selectors.selectDistrictMembershipIds(state);
  const wardIds = selectors.selectWardIdsForCurrentDistrict(state);

  const wardMembershipForCurrentDistrict = wardMemberships.filter(({ wardId }) =>
    wardIds.includes(wardId),
  );

  const wardMembershipIds = wardMembershipForCurrentDistrict.map((member) => member.userId);

  const usersExcludedWardMembership = allUsers.filter(
    (user) => !uniq([...wardMembershipIds, ...districtMembershipIds]).includes(user.id),
  );

  memberships = memberships.filter(({ userId }) => !wardMembershipIds.includes(userId));

  if (email !== Config.ADMIN_EMAIL) {
    memberships = memberships.filter(({ user }) => user.email !== Config.ADMIN_EMAIL);
  }

  const isCurrentUserEditor =
    !!currentUserMembership && currentUserMembership.role === DistrictMembershipRoles.EDITOR;

  return {
    memberships,
    contents,
    filterUsers,
    filterContents,
    allUsers: usersExcludedWardMembership,
    canEdit: isCurrentUserEditor,
    // canEditMemberships: isCurrentUserManager && isAdmin,
    canEditMemberships: false,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onMembershipCreate: entryActions.createMembershipInCurrentDistrict,
      onMembershipUpdate: entryActions.updateDistrictMembership,
      onMembershipDelete: entryActions.deleteDistrictMembership,
      onUserToFilterAdd: entryActions.addUserToFilterInCurrentDistrict,
      onUserFromFilterRemove: entryActions.removeUserFromFilterInCurrentDistrict,
      onContentToFilterAdd: entryActions.addContentToFilterInCurrentDistrict,
      onContentFromFilterRemove: entryActions.removeContentFromFilterInCurrentDistrict,
      onContentCreate: entryActions.createContentInCurrentDistrict,
      onContentUpdate: entryActions.updateContent,
      onContentMove: entryActions.moveContent,
      onContentDelete: entryActions.deleteContent,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DistrictActions);
