import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createCategory(districtId, data) {
  const nextData = {
    ...data,
    position: yield select(selectors.selectNextCategoryPosition, districtId),
  };

  const localId = yield call(createLocalId);

  yield put(
    actions.createCategory({
      ...nextData,
      districtId,
      id: localId,
    }),
  );

  let category;
  try {
    ({ item: category } = yield call(request, api.createCategory, districtId, nextData));
  } catch (error) {
    yield put(actions.createCategory.failure(localId, error));
    return;
  }

  yield put(actions.createCategory.success(localId, category));
}

export function* createCategoryInProvince(provinceId, data) {
  const nextData = {
    ...data,
    position: 0, // yield select(selectors.selectNextCategoryPosition, provinceId),
  };

  const localId = yield call(createLocalId);

  yield put(
    actions.createCategory({
      ...nextData,
      provinceId,
      id: localId,
    }),
  );

  let category;
  try {
    ({ item: category } = yield call(request, api.createCategory, provinceId, nextData));
  } catch (error) {
    yield put(actions.createCategory.failure(localId, error));
    return;
  }

  yield put(actions.createCategory.success(localId, category));
}

export function* createCategoryInCurrentProvince(data) {
  const { provinceId } = yield select(selectors.selectPath);

  yield call(createCategoryInProvince, provinceId, data);
}

export function* handleCategoryCreate(category) {
  yield put(actions.handleCategoryCreate(category));
}

export function* updateCategory(id, data) {
  yield put(actions.updateCategory(id, data));

  let category;
  try {
    ({ item: category } = yield call(request, api.updateCategory, id, data));
  } catch (error) {
    yield put(actions.updateCategory.failure(id, error));
    return;
  }

  yield put(actions.updateCategory.success(category));
}

export function* handleCategoryUpdate(category) {
  yield put(actions.handleCategoryUpdate(category));
}

export function* moveCategory(id, index, parentId) {
  const { provinceId } = yield select(selectors.selectCategoryById, id);
  const position = yield select(selectors.selectNextCategoryPosition, id, index, id, parentId);

  yield call(updateCategory, id, {
    position,
    parentId,
  });
}

export function* deleteCategory(id) {
  yield put(actions.deleteCategory(id));

  let category;
  try {
    ({ item: category } = yield call(request, api.deleteCategory, id));
  } catch (error) {
    yield put(actions.deleteCategory.failure(id, error));
    return;
  }

  yield put(actions.deleteCategory.success(category));
}

export function* handleCategoryDelete(category) {
  yield put(actions.handleCategoryDelete(category));
}

export default {
  createCategory,
  createCategoryInCurrentProvince,
  handleCategoryCreate,
  updateCategory,
  handleCategoryUpdate,
  moveCategory,
  deleteCategory,
  handleCategoryDelete,
};
