import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Dropdown, Icon, Label, Modal, Pagination, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Config from '../../constants/Config';
import DeleteStep from './DeleteStep';
import { ScheduleLogStatusOptions } from '../../constants/Enums';
import { usePopup } from '../../lib/popup';
import durationToTime from '../../utils/duration-to-time';

import PaginationCustom from '../Pagination';

import styles from './StationsLogsModal.module.scss';

const LIMIT = Config.LIMIT_PER_PAGE;

const StationsLogsModal = React.memo(
  ({ content, districts, items, total, onClose, onDelete, onFetchStationsByContent }) => {
    const [t] = useTranslation();

    const [limit, setLimit] = useState(LIMIT);
    const [page, setPage] = useState(1);
    const [districtIds, setDistrictIds] = useState([]);

    const handleOnChangeCategories = useCallback((_, { value }) => {
      setDistrictIds(value);
    }, []);

    const handleRefetchStationsLogs = useCallback(() => {
      setPage(1);
      onFetchStationsByContent(content.id, {
        page: 1,
        limit,
      });
      setDistrictIds([]);
    }, [content.id, limit, onFetchStationsByContent]);

    const handleSelectPage = useCallback(
      (_, { activePage }) => {
        setPage(activePage);
        onFetchStationsByContent(content.id, {
          page: activePage,
          limit,
          ...(districtIds.length > 0 && { districtId: districtIds }),
        });
      },
      [content.id, districtIds, limit, onFetchStationsByContent],
    );

    const handleFilterStationsLogs = useCallback(() => {
      setPage(1);
      onFetchStationsByContent(content.id, {
        page: 1,
        limit,
        ...(districtIds.length > 0 && { districtId: districtIds }),
      });
    }, [content.id, limit, districtIds, onFetchStationsByContent]);

    const handleDeleteLog = useCallback(
      (id) => {
        onDelete(id);
      },
      [onDelete],
    );

    useEffect(() => {
      onFetchStationsByContent(content.id, { page: 1, limit });
    }, [content.id, limit, onFetchStationsByContent]);

    const DeletePopup = usePopup(DeleteStep);

    const districtOptions = districts.map((it) => ({ key: it.id, value: it.id, text: it.name }));

    return (
      <Modal
        open
        className={styles.wrapper}
        closeIcon
        centered={false}
        size="fullscreen"
        onClose={onClose}
      >
        <Modal.Header>
          <div className={styles.title}>
            {t('common.stationsBroadcastContents', { content: content.name })}
          </div>
        </Modal.Header>
        <Modal.Content>
          <div className={styles.wrapperSelect}>
            <Dropdown
              // clearable
              closeOnChange
              multiple
              onChange={handleOnChangeCategories}
              options={districtOptions}
              placeholder={t('common.selectDistrict')}
              selection
              value={districtIds}
            />
            <Button
              content={t('action.filter')}
              className={styles.button}
              onClick={handleFilterStationsLogs}
            />
            <Button
              icon="recycle"
              content={t('action.refresh')}
              className={styles.button}
              onClick={handleRefetchStationsLogs}
            />
          </div>
          <div className={styles.wrapperTable}>
            <Table unstackable singleLine className={styles.table}>
              <Table.Header className={styles.tableHeader}>
                <Table.Row>
                  <Table.HeaderCell>{t('common.time')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.stations')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.duration')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.scheduleCreator')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.status')}</Table.HeaderCell>
                  {/* <Table.HeaderCell /> */}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {items.map((it) => (
                  <Table.Row key={it.id}>
                    <Table.Cell>
                      {new Date(it.start).toLocaleString('en-US', { hourCycle: 'h23' })}
                    </Table.Cell>
                    <Table.Cell>{`${it.device?.name}, ${it.station.name}`}</Table.Cell>
                    <Table.Cell>{durationToTime(it.duration, true)}</Table.Cell>
                    <Table.Cell>{it.user?.name}</Table.Cell>
                    <Table.Cell>
                      {it.status && (
                        <Label
                          color={
                            ScheduleLogStatusOptions.find(({ key }) => key === it.playStatus).color
                          }
                          content={t(`common.${it.playStatus}`)}
                        />
                      )}
                    </Table.Cell>
                    {/* <Table.Cell textAlign="right">
                      <DeletePopup
                        title={t('common.deleteLog_title')}
                        content={t('common.areYouSureYouWantToDeleteThisLog')}
                        onDelete={handleDeleteLog}
                      >
                        <Button
                          icon="trash alternate outline"
                          className={classNames(styles.action, styles.delete)}
                        />
                      </DeletePopup>
                    </Table.Cell> */}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
          <div className={styles.pagination}>
            <PaginationCustom
              onPageChange={handleSelectPage}
              activePage={page}
              totalPages={Math.ceil(total / limit)}
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  },
);

StationsLogsModal.propTypes = {
  content: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  districts: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
  items: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
  total: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFetchStationsByContent: PropTypes.func.isRequired,
};

// StationsLogsModal.defaultProps = {
//   total: 20,
// };

export default StationsLogsModal;
