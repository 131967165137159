import EntryActionTypes from '../constants/EntryActionTypes';

const fetchActivitiesInCurrentDevice = () => ({
  type: EntryActionTypes.ACTIVITIES_IN_CURRENT_DEVICE_FETCH,
  payload: {},
});

const toggleActivitiesDetailsInCurrentDevice = (isVisible) => ({
  type: EntryActionTypes.ACTIVITIES_DETAILS_IN_CURRENT_DEVICE_TOGGLE,
  payload: {
    isVisible,
  },
});

const handleActivityCreate = (activity) => ({
  type: EntryActionTypes.ACTIVITY_CREATE_HANDLE,
  payload: {
    activity,
  },
});

const handleActivityUpdate = (activity) => ({
  type: EntryActionTypes.ACTIVITY_UPDATE_HANDLE,
  payload: {
    activity,
  },
});

const handleActivityDelete = (activity) => ({
  type: EntryActionTypes.ACTIVITY_DELETE_HANDLE,
  payload: {
    activity,
  },
});

export default {
  fetchActivitiesInCurrentDevice,
  toggleActivitiesDetailsInCurrentDevice,
  handleActivityCreate,
  handleActivityUpdate,
  handleActivityDelete,
};
