import isEmail from 'validator/lib/isEmail';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Form, Message } from 'semantic-ui-react';
import { usePrevious } from '../../lib/hooks';
import { Input, Popup } from '../../lib/custom-ui';

import { useForm } from '../../hooks';
import { DistrictMembershipRoles, USER_LEVELS } from '../../constants/Enums';

import styles from './UserPermissionEditStep.module.scss';

const createMessage = (error) => {
  if (!error) {
    return error;
  }

  switch (error.message) {
    case 'Email already in use':
      return {
        type: 'error',
        content: 'common.emailAlreadyInUse',
      };
    case 'Invalid current password':
      return {
        type: 'error',
        content: 'common.invalidCurrentPassword',
      };
    default:
      return {
        type: 'warning',
        content: 'common.unknownError',
      };
  }
};

const USER_LEVEL_OPTIONS = [
  {
    key: USER_LEVELS.PROVINCE,
    value: USER_LEVELS.PROVINCE,
    text: 'common.provinceLevelUsers',
  },
  {
    key: USER_LEVELS.DISTRICT,
    value: USER_LEVELS.DISTRICT,
    text: 'common.districtLevelUsers',
  },
  {
    key: USER_LEVELS.WARD,
    value: USER_LEVELS.WARD,
    text: 'common.wardLevelUsers',
  },
];

const UserPermissionEditStep = React.memo(
  ({
    provinces,
    districts,
    wards,
    locationId,
    group,
    groups,
    user,
    isSubmitting,
    error,
    usePasswordConfirmation,
    onUpdate,
    onMessageDismiss,
    onBack,
    onClose,
  }) => {
    const [t] = useTranslation();
    const wasSubmitting = usePrevious(isSubmitting);

    const [data, handleFieldChange, setData] = useForm({
      locationId: locationId || '',
      level: user.level || '',
      userGroupId: group.id || '',
      currentPassword: '',
    });

    const message = useMemo(() => createMessage(error), [error]);

    const levelField = useRef(null);
    const locationField = useRef(null);
    const groupField = useRef(null);
    const currentPasswordField = useRef(null);

    const handleSubmit = useCallback(() => {
      const cleanData = {
        ...data,
        locationId: data.locationId || undefined,
        level: data.level || undefined,
        userGroupId: data.userGroupId || undefined,
      };

      if (!cleanData.level) {
        levelField.current.open();
        return;
      }

      if (!cleanData.locationId) {
        locationField.current.open();
        return;
      }

      if (!cleanData.userGroupId) {
        groupField.current.open();
        return;
      }

      if (usePasswordConfirmation) {
        if (!cleanData.currentPassword) {
          currentPasswordField.current.focus();
          return;
        }
      } else {
        delete cleanData.currentPassword;
      }

      onUpdate(user.id, cleanData);
    }, [data, user.id, usePasswordConfirmation, onUpdate]);

    const handleChangeLevelUser = useCallback(
      (_, { name, value }) => {
        setData((prev) => ({
          ...prev,
          [name]: value,
          locationId: value === prev.level ? prev.locationId : '',
        }));
      },
      [setData],
    );

    const areaOptions = useMemo(() => {
      const ProvinceOptions = provinces.map((province) => ({
        key: province.id,
        value: province.id,
        text: province.name,
      }));

      const DistrictOptions = districts.map((district) => ({
        key: district.id,
        value: district.id,
        text: district.name,
      }));

      const WardOptions = wards.map((ward) => ({
        key: ward.id,
        value: ward.id,
        text: ward.name,
      }));

      switch (data.level) {
        case USER_LEVELS.PROVINCE:
          return ProvinceOptions;
        case USER_LEVELS.DISTRICT:
          return DistrictOptions;
        case USER_LEVELS.WARD:
          return WardOptions;

        default:
          return [];
      }
    }, [data.level, provinces, districts, wards]);

    const GroupOptions = groups.map((item) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));

    useEffect(() => {
      if (wasSubmitting && !isSubmitting) {
        if (error) {
          switch (error.message) {
            case 'Email already in use':
              break;
            case 'Invalid current password':
              break;
            default:
          }
        } else {
          onClose();
        }
      }
    }, [isSubmitting, wasSubmitting, error, onClose]);

    return (
      <>
        <Popup.Header onBack={onBack}>
          {t('common.editPermissions', {
            context: 'title',
          })}
        </Popup.Header>
        <Popup.Content>
          {message && (
            <Message
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...{
                [message.type]: true,
              }}
              visible
              content={t(message.content)}
              onDismiss={() => onMessageDismiss(user.id)}
            />
          )}
          <Form onSubmit={handleSubmit}>
            <div className={styles.text}>{t('common.userLevel_title')}</div>
            <Dropdown
              disabled
              fluid
              ref={levelField}
              selection
              readOnly={isSubmitting}
              options={USER_LEVEL_OPTIONS.map((userLevel) => ({
                ...userLevel,
                text: t(userLevel.text),
              }))}
              name="level"
              value={data.level}
              className={styles.field}
              onChange={handleChangeLevelUser}
            />
            <div className={styles.text}>{t('common.selectArea')}</div>
            <Dropdown
              disabled
              fluid
              ref={locationField}
              selection
              readOnly={isSubmitting}
              options={areaOptions}
              name="locationId"
              value={data.locationId}
              className={styles.field}
              onChange={handleFieldChange}
            />
            <div className={styles.text}>{t('common.userGroups')}</div>
            <Dropdown
              fluid
              ref={groupField}
              selection
              name="userGroupId"
              value={data.userGroupId}
              readOnly={isSubmitting}
              options={GroupOptions}
              className={styles.field}
              onChange={handleFieldChange}
            />
            {usePasswordConfirmation && (
              <>
                <div className={styles.text}>{t('common.currentPassword')}</div>
                <Input.Password
                  fluid
                  ref={currentPasswordField}
                  name="currentPassword"
                  value={data.currentPassword}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </>
            )}
            <Button
              positive
              content={t('action.save')}
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </Form>
        </Popup.Content>
      </>
    );
  },
);

UserPermissionEditStep.propTypes = {
  provinces: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  districts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  wards: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  locationId: PropTypes.string.isRequired,
  group: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  groups: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  usePasswordConfirmation: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  onMessageDismiss: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

UserPermissionEditStep.defaultProps = {
  group: { id: undefined },
  error: undefined,
  usePasswordConfirmation: false,
  onBack: undefined,
};

export default UserPermissionEditStep;
