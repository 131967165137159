import ActionTypes from '../constants/ActionTypes';

const liveBroadcastRequest = (message, url) => ({
  type: ActionTypes.LIVE_BROADCAST_REQUEST,
  payload: { message, url },
});

const liveBroadcastStart = (message, url) => ({
  type: ActionTypes.LIVE_BROADCAST_START,
  payload: { message, url },
});

const liveBroadcastStop = (message, url, liveStatus) => ({
  type: ActionTypes.LIVE_BROADCAST_STOP,
  payload: { message, url, liveStatus },
});

const liveBroadcastError = (error) => ({
  type: ActionTypes.LIVE_BROADCAST_ERROR,
  payload: { error },
});

const handleBroadcastingError = (message) => ({
  type: ActionTypes.LIVE_BROADCAST_ERROR_HANDLE,
  payload: { message },
});

const handleBroadcastFinished = (message) => ({
  type: ActionTypes.LIVE_BROADCAST_FINISHED_HANDLE,
  payload: { message },
});

const clearBroadcast = () => ({
  type: ActionTypes.LIVE_BROADCAST_CLEAR,
  payload: {},
});

const closeMessageBroadcast = () => ({
  type: ActionTypes.LIVE_BROADCAST_MESSAGE_CLOSE,
  payload: {},
});

const handleOverrideContentByMicrophone = (content) => ({
  type: ActionTypes.OVERRIDE_CONTENT_BY_MICROPHONE,
  payload: { content },
});

export default {
  liveBroadcastRequest,
  liveBroadcastStart,
  liveBroadcastStop,
  liveBroadcastError,
  handleBroadcastingError,
  handleBroadcastFinished,
  clearBroadcast,
  closeMessageBroadcast,
  handleOverrideContentByMicrophone,
};
