import { call, put, select } from 'redux-saga/effects';

import {
  // goToHamlet,
  goToProvince,
} from './router';
import request from '../request';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createHamlet(wardId, { import: hamletImport, ...data }) {
  const nextData = {
    ...data,
    // position: yield select(selectors.selectNextHamletPosition, wardId),
  };

  const localId = yield call(createLocalId);

  yield put(
    actions.createHamlet({
      ...nextData,
      wardId,
      id: localId,
    }),
  );

  let hamlet;
  let hamletMemberships;

  try {
    ({
      item: hamlet,
      included: { hamletMemberships },
    } = yield hamletImport
      ? call(
          request,
          api.createHamletWithImport,
          wardId,
          {
            ...nextData,
            importType: hamletImport.type,
            importFile: wardImport.file,
          },
          localId,
        )
      : call(request, api.createHamlet, wardId, nextData));
  } catch (error) {
    yield put(actions.createHamlet.failure(localId, error));
    return;
  }

  yield put(actions.createHamlet.success(localId, hamlet, hamletMemberships));
  // yield call(goToHamlet, hamlet.id);
}

export function* createHamletInCurrentProvince(data) {
  const { provinceId } = yield select(selectors.selectPath);

  yield call(createHamlet, data.wardId, data);
}

export function* handleHamletCreate(hamlet) {
  yield put(actions.handleHamletCreate(hamlet));
}

export function* fetchHamlet(id) {
  yield put(actions.fetchHamlet(id));

  let hamlet;
  let users;
  let provinces;
  let hamletMemberships;
  let contents;
  let contentCategories;
  let stations;
  let devices;
  let deviceMemberships;
  let deviceContents;
  let tasks;
  let attachments;

  try {
    ({
      item: hamlet,
      included: {
        users,
        provinces,
        hamletMemberships,
        contents,
        contentCategories,
        stations,
        devices,
        deviceMemberships,
        deviceContents,
        tasks,
        attachments,
      },
    } = yield call(request, api.getHamlet, id, true));
  } catch (error) {
    yield put(actions.fetchHamlet.failure(id, error));
    return;
  }

  yield put(
    actions.fetchHamlet.success(
      hamlet,
      users,
      provinces,
      hamletMemberships,
      contents,
      contentCategories,
      stations,
      devices,
      deviceMemberships,
      deviceContents,
      tasks,
      attachments,
    ),
  );
}

export function* updateHamlet(id, data) {
  yield put(actions.updateHamlet(id, data));

  let hamlet;
  try {
    ({ item: hamlet } = yield call(request, api.updateHamlet, id, data));
  } catch (error) {
    yield put(actions.updateHamlet.failure(id, error));
    return;
  }

  yield put(actions.updateHamlet.success(hamlet));
}

export function* handleHamletUpdate(hamlet) {
  yield put(actions.handleHamletUpdate(hamlet));
}

export function* moveHamlet(id, index) {
  const { provinceId } = yield select(selectors.selectHamletById, id);
  const position = yield select(selectors.selectNextHamletPosition, provinceId, index, id);

  yield call(updateHamlet, id, {
    position,
  });
}

export function* deleteHamlet(id) {
  const { wardId, provinceId } = yield select(selectors.selectPath);

  if (id === wardId) {
    yield call(goToProvince, provinceId);
  }

  yield put(actions.deleteHamlet(id));

  let hamlet;
  try {
    ({ item: hamlet } = yield call(request, api.deleteHamlet, id));
  } catch (error) {
    yield put(actions.deleteHamlet.failure(id, error));
    return;
  }

  yield put(actions.deleteHamlet.success(hamlet));
}

export function* handleHamletDelete(hamlet) {
  const { wardId, provinceId } = yield select(selectors.selectPath);

  if (hamlet.id === wardId) {
    yield call(goToProvince, provinceId);
  }

  yield put(actions.handleHamletDelete(hamlet));
}

export default {
  createHamlet,
  createHamletInCurrentProvince,
  handleHamletCreate,
  fetchHamlet,
  updateHamlet,
  handleHamletUpdate,
  moveHamlet,
  deleteHamlet,
  handleHamletDelete,
};
