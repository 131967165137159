import ActionTypes from '../../constants/ActionTypes';
import EntryActionTypes from '../../constants/EntryActionTypes';

const initialState = {
  results: [],
  isLoading: false,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case EntryActionTypes.CONTENTS_SEARCH:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CONTENTS_SEARCH__SUCCESS:
      return {
        results: payload.contents,
        isLoading: false,
      };
    case ActionTypes.SCHEDULE_CREATE__SUCCESS:
      return initialState;
    default:
      return state;
  }
};
