import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Modal, Radio, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { useToggle } from '../../lib/hooks';
import { usePopup } from '../../lib/popup';

import UserAddStepContainer from '../../containers/UserAddStepContainer';
import Item from './Item';
import UserModalAddStepContainer from '../../containers/UserModalAddStepContainer';

import styles from './UserAccounts.module.scss';

const UserAccounts = React.memo(
  ({
    isAdmin,
    items,
    onUpdate,
    onUsernameUpdate,
    onUsernameUpdateMessageDismiss,
    onEmailUpdate,
    onEmailUpdateMessageDismiss,
    onPasswordUpdate,
    onPasswordUpdateMessageDismiss,
    onDelete,
    onClose,
    onManagerCreate,
    onManagerDelete,
  }) => {
    const [t] = useTranslation();

    const [isOpenModalAddUser, handleToggleModalAddUser] = useToggle();

    const handleUpdate = useCallback(
      (id, data) => {
        onUpdate(id, data);
      },
      [onUpdate],
    );

    const handleUsernameUpdate = useCallback(
      (id, data) => {
        onUsernameUpdate(id, data);
      },
      [onUsernameUpdate],
    );

    const handleUsernameUpdateMessageDismiss = useCallback(
      (id) => {
        onUsernameUpdateMessageDismiss(id);
      },
      [onUsernameUpdateMessageDismiss],
    );

    const handleEmailUpdate = useCallback(
      (id, data) => {
        onEmailUpdate(id, data);
      },
      [onEmailUpdate],
    );

    const handleEmailUpdateMessageDismiss = useCallback(
      (id) => {
        onEmailUpdateMessageDismiss(id);
      },
      [onEmailUpdateMessageDismiss],
    );

    const handlePasswordUpdate = useCallback(
      (id, data) => {
        onPasswordUpdate(id, data);
      },
      [onPasswordUpdate],
    );

    const handlePasswordUpdateMessageDismiss = useCallback(
      (id) => {
        onPasswordUpdateMessageDismiss(id);
      },
      [onPasswordUpdateMessageDismiss],
    );

    const handleDelete = useCallback(
      (id) => {
        onDelete(id);
      },
      [onDelete],
    );

    const handleActivatedAccount = useCallback(
      (user) => {
        const { isProvinceManager, id: userId, managers } = user;

        if (isProvinceManager) {
          onManagerDelete(managers[0].id);
        } else {
          onManagerCreate({ userId });
        }
      },
      [onManagerCreate, onManagerDelete],
    );

    const UserAddPopupContainer = usePopup(UserAddStepContainer);

    return (
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('common.users')}</h2>
        <Table stackable onClose={onClose} singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1} />
              <Table.HeaderCell width={2}>{t('common.name')}</Table.HeaderCell>
              <Table.HeaderCell width={4}>{t('common.username')}</Table.HeaderCell>
              <Table.HeaderCell width={4}>{t('common.email')}</Table.HeaderCell>
              <Table.HeaderCell width={2}>{t('user.level')}</Table.HeaderCell>
              {isAdmin && (
                <>
                  <Table.HeaderCell>{t('common.administrator')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.activated')}</Table.HeaderCell>
                  <Table.HeaderCell width={3} />
                </>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              return (
                <Item
                  canSetAdmin={isAdmin}
                  avatarUrl={item.avatarUrl}
                  email={item.email}
                  emailUpdateForm={item.emailUpdateForm}
                  isAdmin={item.isAdmin}
                  isProvinceManager={item.isProvinceManager}
                  key={item.id}
                  name={item.name}
                  level={t(`user.${item.level}`)}
                  onDelete={() => handleDelete(item.id)}
                  onEmailUpdate={(data) => handleEmailUpdate(item.id, data)}
                  onEmailUpdateMessageDismiss={() => handleEmailUpdateMessageDismiss(item.id)}
                  onPasswordUpdate={(data) => handlePasswordUpdate(item.id, data)}
                  onPasswordUpdateMessageDismiss={() => handlePasswordUpdateMessageDismiss(item.id)}
                  onUpdate={(data) => handleUpdate(item.id, data)}
                  onUsernameUpdate={(data) => handleUsernameUpdate(item.id, data)}
                  onUsernameUpdateMessageDismiss={() => handleUsernameUpdateMessageDismiss(item.id)}
                  organization={item.organization}
                  passwordUpdateForm={item.passwordUpdateForm}
                  phone={item.phone}
                  username={item.username}
                  usernameUpdateForm={item.usernameUpdateForm}
                  onActivated={() => handleActivatedAccount(item)}
                />
              );
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row textAlign="right">
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              {isAdmin && (
                <>
                  <Table.HeaderCell />
                  <Table.HeaderCell />
                  <Table.HeaderCell />
                </>
              )}
              <Table.HeaderCell>
                {isAdmin && (
                  <Modal.Actions>
                    {/* <UserAddPopupContainer>
                    <Button className={styles.centerButton} content={t('action.addUser')} />
                  </UserAddPopupContainer> */}
                    <Button
                      className={styles.centerButton}
                      content={t('action.addUser')}
                      onClick={handleToggleModalAddUser}
                    />
                  </Modal.Actions>
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        {isOpenModalAddUser && <UserModalAddStepContainer onClose={handleToggleModalAddUser} />}
      </div>
    );
  },
);

UserAccounts.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onUpdate: PropTypes.func.isRequired,
  onUsernameUpdate: PropTypes.func.isRequired,
  onUsernameUpdateMessageDismiss: PropTypes.func.isRequired,
  onEmailUpdate: PropTypes.func.isRequired,
  onEmailUpdateMessageDismiss: PropTypes.func.isRequired,
  onPasswordUpdate: PropTypes.func.isRequired,
  onPasswordUpdateMessageDismiss: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onManagerCreate: PropTypes.func.isRequired,
  onManagerDelete: PropTypes.func.isRequired,
};
UserAccounts.defaultProps = {
  // avatarUrl: undefined,
  // organization: undefined,
  // phone: undefined,
};

export default UserAccounts;
