import EntryActionTypes from '../constants/EntryActionTypes';

const createDevice = (stationId, data, autoOpen) => ({
  type: EntryActionTypes.DEVICE_CREATE,
  payload: {
    stationId,
    data,
    autoOpen,
  },
});

const handleDeviceCreate = (device) => ({
  type: EntryActionTypes.DEVICE_CREATE_HANDLE,
  payload: {
    device,
  },
});

const fetchDevices = (page, limit, filter) => ({
  type: EntryActionTypes.DEVICES_FETCH,
  payload: {
    page,
    limit,
    filter,
  },
});

const updateDevice = (id, data) => ({
  type: EntryActionTypes.DEVICE_UPDATE,
  payload: {
    id,
    data,
  },
});

const updateCurrentDevice = (data) => ({
  type: EntryActionTypes.CURRENT_DEVICE_UPDATE,
  payload: {
    data,
  },
});

const handleDeviceUpdate = (device) => ({
  type: EntryActionTypes.DEVICE_UPDATE_HANDLE,
  payload: {
    device,
  },
});

const moveDevice = (id, stationId, index = 0) => ({
  type: EntryActionTypes.DEVICE_MOVE,
  payload: {
    id,
    stationId,
    index,
  },
});

const moveCurrentDevice = (stationId, index = 0) => ({
  type: EntryActionTypes.CURRENT_DEVICE_MOVE,
  payload: {
    stationId,
    index,
  },
});

const transferDevice = (id, districtId, stationId, index = 0) => ({
  type: EntryActionTypes.DEVICE_TRANSFER,
  payload: {
    id,
    districtId,
    stationId,
    index,
  },
});

const transferCurrentDevice = (districtId, stationId, index = 0) => ({
  type: EntryActionTypes.CURRENT_DEVICE_TRANSFER,
  payload: {
    districtId,
    stationId,
    index,
  },
});

const deleteDevice = (id) => ({
  type: EntryActionTypes.DEVICE_DELETE,
  payload: {
    id,
  },
});

const deleteCurrentDevice = () => ({
  type: EntryActionTypes.CURRENT_DEVICE_DELETE,
  payload: {},
});

const handleDeviceDelete = (device) => ({
  type: EntryActionTypes.DEVICE_DELETE_HANDLE,
  payload: {
    device,
  },
});

const restartDevice = (id) => ({
  type: EntryActionTypes.DEVICE_RESTART,
  payload: {
    id,
  },
});

const controlDevices = (data) => ({
  type: EntryActionTypes.DEVICES_CONTROL,
  payload: {
    data,
  },
});

export default {
  createDevice,
  handleDeviceCreate,
  fetchDevices,
  controlDevices,
  updateDevice,
  updateCurrentDevice,
  handleDeviceUpdate,
  moveDevice,
  moveCurrentDevice,
  restartDevice,
  transferDevice,
  transferCurrentDevice,
  deleteDevice,
  deleteCurrentDevice,
  handleDeviceDelete,
};
