export const margeBroadcastTime = (previous, current) => {
  if (previous.length === 0) {
    return current;
  }

  if (current.length === 0) {
    return previous;
  }

  const mergedTimes = [...previous];

  current.forEach((time) => {
    const index = previous.findIndex((it) => it.ThoiGianBatDau === time.ThoiGianBatDau);

    if (index >= 0) {
      mergedTimes[index] = time;
    } else {
      mergedTimes.push(time);
    }
  });

  return mergedTimes.sort((a, b) => a.ThoiGianBatDau - b.ThoiGianBatDau);
};

const mergeRecordsSchedule = (target, ...sources) => {
  if (sources.length === 0) {
    return target;
  }

  const source = sources.shift();

  if (!target || !source) {
    return mergeRecordsSchedule(target || source, ...sources);
  }

  const nextTarget = [...target];

  source.forEach((sourceRecord) => {
    const index = nextTarget.findIndex((targetRecord) => targetRecord.id === sourceRecord.id);

    if (index >= 0) {
      Object.assign(nextTarget[index], {
        ...sourceRecord,
        ThoiDiemPhat: margeBroadcastTime(sourceRecord.ThoiDiemPhat, nextTarget[index].ThoiDiemPhat),
      });
    } else {
      nextTarget.push(sourceRecord);
    }
  });

  return mergeRecordsSchedule(nextTarget, ...sources);
};

export default mergeRecordsSchedule;
