import _ from 'lodash';
import { Draggable } from 'react-beautiful-dnd';
import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Label, Icon } from 'semantic-ui-react';
// import NameEdit from './NameEdit';
import { usePopup } from '../../../lib/popup';
import DeleteStep from '../../DeleteStep';
import styles from './Drag.module.scss';
import AddStep from '../AddStep/AddStep';
import EditStep from '../EditStep';

const Drag = React.memo(
  ({
    isAdmin,
    children,
    id,
    index,
    category,
    name,
    color,
    onCreate,
    onUpdateCategory,
    onDelete,
    ...props
  }) => {
    // const nameEdit = useRef(null);
    const DeletePopup = usePopup(DeleteStep);
    const CreateCategoryPopup = usePopup(AddStep);
    const EditPopup = usePopup(EditStep);

    const handleDeleteCategory = useCallback(() => {
      onDelete(id);
    }, [onDelete, id]);

    // const handleHeaderClick = useCallback(() => {
    //   if (name) {
    //     nameEdit.current?.open();
    //   }
    // }, [name]);

    return (
      <Draggable draggableId={id} index={index}>
        {(provided) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps} // eslint-disable-line react/jsx-props-no-spreading
              {...props} // eslint-disable-line react/jsx-props-no-spreading
              // eslint-disable-line react/jsx-props-no-spreading
              {...provided.dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
              className={styles.draggableContainer}
            >
              {name && (
                <ul
                  className={styles.tree} // eslint-disable-line
                >
                  <li>
                    <details open={false}>
                      <summary>
                        <Label as="a" color="teal" image className={styles.labelSummory}>
                          {/* <NameEdit
                            ref={nameEdit}
                            defaultValue={name}
                            categoryId={id}
                            onUpdate={onUpdateCategory}
                          > */}
                          <img
                            src="https://react.semantic-ui.com/images/avatar/small/nan.jpg"
                            alt="color"
                          />
                          {name}
                          {isAdmin && (
                            <>
                              <EditPopup category={category} onUpdate={onUpdateCategory}>
                                <Icon
                                  name="edit"
                                  className={styles.icon}
                                  // onClick={handleHeaderClick}
                                />
                              </EditPopup>
                              <CreateCategoryPopup
                                className={styles.buttonCreateCategory}
                                onCreate={onCreate}
                                parentId={id}
                              >
                                <Icon className={styles.icon} name="plus" />
                              </CreateCategoryPopup>
                              <DeletePopup
                                title="common.deleteCategory_titile"
                                content="common.areYouSureYouWantToDeleteThisCategory"
                                buttonContent="action.deleteCategory"
                                onConfirm={handleDeleteCategory}
                              >
                                <Icon name="delete" className={styles.icon} />
                              </DeletePopup>
                            </>
                          )}
                          {/* </NameEdit> */}
                        </Label>
                      </summary>
                      {children}
                      {/* <List.Description>{color}</List.Description> */}
                    </details>
                  </li>
                </ul>
              )}

              {children}
            </div>
          );
        }}
      </Draggable>
    );
  },
);
Drag.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  children: PropTypes.node, // eslint-disable-line react/forbid-prop-types
  color: PropTypes.string,
  id: PropTypes.string,
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  onUpdateCategory: PropTypes.func,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  category: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  //   props: PropTypes.object.isRequired,
};
Drag.defaultProps = {
  category: undefined,
  children: undefined,
  color: undefined,
  id: '',
  name: '',
  onCreate: () => {},
  onDelete: () => {},
  onUpdateCategory: () => {},
};
export default Drag;
