import { call, put, select } from 'redux-saga/effects';

import { goToProvince } from './router';
import request from '../request';
import requests from '../requests';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';
import mergeRecords from '../../../utils/merge-records';

export function* createDistrictMembership(districtId, data) {
  const localId = yield call(createLocalId);

  yield put(
    actions.createDistrictMembership({
      ...data,
      districtId,
      id: localId,
    }),
  );

  let districtMembership;
  try {
    ({
      items: [districtMembership],
    } = yield call(request, api.createDistrictMembership, districtId, data));
  } catch (error) {
    yield put(actions.createDistrictMembership.failure(localId, error));
    return;
  }

  yield put(actions.createDistrictMembership.success(localId, districtMembership));
}

export function* createMembershipInCurrentDistrict(data) {
  const { districtId } = yield select(selectors.selectPath);

  yield call(createDistrictMembership, districtId, data);
}

export function* handleDistrictMembershipCreate(districtMembership) {
  const currentUserId = yield select(selectors.selectCurrentUserId);
  const isCurrentUser = districtMembership.userId === currentUserId;

  let user;
  let province;
  let district1;
  let users1;
  let users2;
  let provinceManagers;
  let districts;
  let districtMemberships1;
  let districtMemberships2;
  let contents;
  let contentCategories;
  let stations;
  let devices;
  let deviceMemberships;
  let deviceContents;
  let tasks;
  let attachments;
  let deletedNotifications;

  if (isCurrentUser) {
    let district2;
    try {
      ({ item: district2 } = yield call(
        request,
        api.getDistrict,
        districtMembership.districtId,
        false,
      ));
    } catch {
      return;
    }

    const { districtId } = yield select(selectors.selectPath);

    yield put(
      actions.handleDistrictMembershipCreate.fetchProvince(
        district2.provinceId,
        currentUserId,
        districtId,
      ),
    );

    try {
      ({
        item: province,
        included: {
          users: users1,
          provinceManagers,
          districts,
          districtMemberships: districtMemberships1,
        },
      } = yield call(request, api.getProvince, district2.provinceId));
    } catch {
      return;
    }

    let body;
    try {
      body = yield call(requests.fetchDistrictByCurrentPath);
    } catch {} // eslint-disable-line no-empty

    if (body && body.province && body.province.id === district2.provinceId) {
      ({
        province,
        district: district1,
        users: users2,
        districtMemberships: districtMemberships2,
        contents,
        contentCategories,
        stations,
        devices,
        deviceMemberships,
        deviceContents,
        tasks,
        attachments,
      } = body);

      if (body.device) {
        deletedNotifications = yield select(
          selectors.selectNotificationsByDeviceId,
          body.device.id,
        );
      }
    }
  } else {
    try {
      ({ item: user } = yield call(request, api.getUser, districtMembership.userId));
    } catch {
      return;
    }
  }

  yield put(
    actions.handleDistrictMembershipCreate(
      districtMembership,
      province,
      district1,
      isCurrentUser ? mergeRecords(users1, users2) : [user],
      provinceManagers,
      districts,
      mergeRecords(districtMemberships1, districtMemberships2),
      contents,
      contentCategories,
      stations,
      devices,
      deviceMemberships,
      deviceContents,
      tasks,
      attachments,
      deletedNotifications,
    ),
  );
}

export function* updateDistrictMembership(id, data) {
  yield put(actions.updateDistrictMembership(id, data));

  let districtMembership;
  try {
    ({ item: districtMembership } = yield call(request, api.updateDistrictMembership, id, data));
  } catch (error) {
    yield put(actions.updateDistrictMembership.failure(id, error));
    return;
  }

  yield put(actions.updateDistrictMembership.success(districtMembership));
}

export function* handleDistrictMembershipUpdate(districtMembership) {
  yield put(actions.handleDistrictMembershipUpdate(districtMembership));
}

export function* deleteDistrictMembership(id) {
  let districtMembership = yield select(selectors.selectDistrictMembershipById, id);

  const currentUserId = yield select(selectors.selectCurrentUserId);
  const { districtId, provinceId } = yield select(selectors.selectPath);

  if (districtMembership.userId === currentUserId && districtMembership.districtId === districtId) {
    const isCurrentUserManager = yield select(
      selectors.selectIsCurrentUserManagerForCurrentProvince,
    );

    if (!isCurrentUserManager) {
      yield call(goToProvince, provinceId);
    }
  }

  yield put(actions.deleteDistrictMembership(id));

  try {
    ({ item: districtMembership } = yield call(request, api.deleteDistrictMembership, id));
  } catch (error) {
    yield put(actions.deleteDistrictMembership.failure(id, error));
    return;
  }

  yield put(actions.deleteDistrictMembership.success(districtMembership));
}

export function* handleDistrictMembershipDelete(districtMembership) {
  const currentUserId = yield select(selectors.selectCurrentUserId);
  const { districtId, provinceId } = yield select(selectors.selectPath);

  if (districtMembership.userId === currentUserId && districtMembership.districtId === districtId) {
    const isCurrentUserManager = yield select(
      selectors.selectIsCurrentUserManagerForCurrentProvince,
    );

    if (!isCurrentUserManager) {
      yield call(goToProvince, provinceId);
    }
  }

  yield put(actions.handleDistrictMembershipDelete(districtMembership));
}

export default {
  createDistrictMembership,
  createMembershipInCurrentDistrict,
  handleDistrictMembershipCreate,
  updateDistrictMembership,
  handleDistrictMembershipUpdate,
  deleteDistrictMembership,
  handleDistrictMembershipDelete,
};
