import socket from './socket';

/* Actions */

const createWardMembership = (wardId, data, headers) =>
  socket.post(`/wards/${wardId}/memberships`, data, headers);

const updateWardMembership = (id, data, headers) =>
  socket.patch(`/ward-memberships/${id}`, data, headers);

const deleteWardMembership = (id, headers) =>
  socket.delete(`/ward-memberships/${id}`, undefined, headers);

export default {
  createWardMembership,
  updateWardMembership,
  deleteWardMembership,
};
