import EntryActionTypes from '../constants/EntryActionTypes';

const createHamletInCurrentProvince = (data) => ({
  type: EntryActionTypes.HAMLET_IN_CURRENT_PROVINCE_CREATE,
  payload: {
    data,
  },
});

const handleHamletCreate = (hamlet, requestId) => ({
  type: EntryActionTypes.HAMLET_CREATE_HANDLE,
  payload: {
    hamlet,
    requestId,
  },
});

const fetchHamlet = (id) => ({
  type: EntryActionTypes.HAMLET_FETCH,
  payload: {
    id,
  },
});

const updateHamlet = (id, data) => ({
  type: EntryActionTypes.HAMLET_UPDATE,
  payload: {
    id,
    data,
  },
});

const handleHamletUpdate = (hamlet) => ({
  type: EntryActionTypes.HAMLET_UPDATE_HANDLE,
  payload: {
    hamlet,
  },
});

const moveHamlet = (id, index) => ({
  type: EntryActionTypes.HAMLET_MOVE,
  payload: {
    id,
    index,
  },
});

const deleteHamlet = (id) => ({
  type: EntryActionTypes.HAMLET_DELETE,
  payload: {
    id,
  },
});

const handleHamletDelete = (hamlet) => ({
  type: EntryActionTypes.HAMLET_DELETE_HANDLE,
  payload: {
    hamlet,
  },
});

export default {
  createHamletInCurrentProvince,
  handleHamletCreate,
  fetchHamlet,
  updateHamlet,
  handleHamletUpdate,
  moveHamlet,
  deleteHamlet,
  handleHamletDelete,
};
