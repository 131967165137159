import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Label } from 'semantic-ui-react';

import { Input, Popup } from '../../../lib/custom-ui';
import { useForm, useSteps } from '../../../hooks';
import { UnitTypes } from '../../../constants/Enums';

import DeleteStep from '../../DeleteStep';

import styles from './EditStep.module.scss';
import MoreInformation from '../../MoreInformation';

const StepTypes = {
  DELETE: 'DELETE',
};

const FIELDS_INFO = ['TrangThongTinDienTu', 'DaiFM', 'TaiLieuKhongKinhDoanh', 'BanTinGiay'];

const ERRORS = {
  'District in use': 'common.locationInUse',
  'Hamlet in use': 'common.locationInUse',
  'Ward in use': 'common.locationInUse',
};

const EditStep = React.memo(({ defaultData, unit, onUpdate, onDelete, onClose }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange, setData] = useForm({
    ...defaultData,
    ...(unit === 'WARD' &&
      !defaultData.NhanLuc && {
        NhanLuc: {
          CongChucVanHoaXaHoi: 0,
          CongChucKhac: 0,
          NguoiHoatDongKhongChuyenTrach: 0,
          TuyenTruyenVienCoSo: 0,
        },
      }),
    ...(unit === 'DISTRICT' && {
      ...(!defaultData.NhanLuc && {
        NhanLuc: {
          SanXuatNoiDung: 0,
          KyThuat: 0,
          CongViecKhac: 0,
        },
      }),
      ...(!defaultData.ThietBiCongNghe && {
        ThietBiCongNghe: {
          MayTinh: 0,
          MayQuayPhim: 0,
          MayThuAmKyThuatSo: 0,
          MayAnh: 0,
          PhanMemBienTapChuongTrinhTruyenHinh: 0,
          PhanMemBienTapChuongTrinhPhatThanh: 0,
        },
      }),
      ...(!defaultData.ChuongTrinhTuSanXuat && {
        ChuongTrinhTuSanXuat: {
          PhatThanh: 0,
          TruyenHinh: 0,
          TruyenThongDaPhuongTien: 0,
        },
      }),
    }),
    name: defaultData.name,
    code: defaultData.code || '',
    TrangThongTinDienTu: defaultData.TrangThongTinDienTu,
    BanTinGiay: defaultData.BanTinGiay,
    TaiLieuKhongKinhDoanh: defaultData.TaiLieuKhongKinhDoanh,
  });

  const [step, openStep, handleBack] = useSteps();

  const nameField = useRef(null);
  const indentifierField = useRef(null);

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      name: data.name.trim(),
      code: data.code.trim() || undefined,
    };

    if (!cleanData.name) {
      nameField.current.select();
      return;
    }
    if (!cleanData.code) {
      indentifierField.current.select();
      return;
    }

    onUpdate(defaultData.id, cleanData);
    onClose();
  }, [onClose, data, onUpdate, defaultData.id]);

  const handleDeleteClick = useCallback(() => {
    openStep(StepTypes.DELETE);
  }, [openStep]);

  const handleDelete = useCallback(
    (id) => {
      onDelete(id);
    },
    [onDelete],
  );

  const handleChangeMoreInformation = useCallback(
    (key, name, value) => {
      if (FIELDS_INFO.includes(key)) {
        setData((prev) => ({ ...prev, [name]: Number(value) }));
      } else {
        setData((prev) => ({ ...prev, [key]: { ...prev[key], [name]: Number(value) } }));
      }
    },
    [setData],
  );

  useEffect(() => {
    nameField.current.focus({
      preventScroll: true,
    });
  }, []);

  if (step && step.type === StepTypes.DELETE) {
    let { content } = UnitTypes[unit];

    const { error } = defaultData.deleteForm;

    if (error) {
      content = ERRORS[error.message];
    }

    return (
      <DeleteStep
        title={UnitTypes[unit].deleteTitle}
        content={content}
        buttonContent="action.delete"
        onConfirm={() => handleDelete(defaultData.id)}
        disabled={!!error}
        onBack={handleBack}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <Popup.Header>
        {t(UnitTypes[unit].editTitle, {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Form onSubmit={handleSubmit}>
          <Input
            placeholder={t(UnitTypes[unit].name)}
            fluid
            ref={nameField}
            name="name"
            value={data.name}
            className={styles.field}
            onChange={handleFieldChange}
          />
          <Input
            placeholder={t('common.identifier')}
            ref={indentifierField}
            fluid
            name="code"
            value={data.code}
            className={styles.field}
            onChange={handleFieldChange}
          />
          {unit !== 'HAMLET' && (
            <MoreInformation unit={unit} data={data} onChange={handleChangeMoreInformation} />
          )}
          <div className={styles.controls}>
            <Button positive content={t('action.save')} className={styles.createButton} />
            <Button type="button" onClick={handleDeleteClick} content={t('action.delete')} />
          </div>
        </Form>
      </Popup.Content>
    </div>
  );
});

EditStep.propTypes = {
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  unit: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

EditStep.defaultProps = {
  unit: 'DISTRICT',
};

export default EditStep;
