import ActionTypes from '../constants/ActionTypes';

const createContent = (content) => ({
  type: ActionTypes.CONTENT_CREATE,
  payload: {
    content,
  },
});

createContent.success = (
  localId,
  content,
  contentCategories,
  contentLocations,
  contentSchedules,
  attachment,
) => ({
  type: ActionTypes.CONTENT_CREATE__SUCCESS,
  payload: {
    localId,
    content,
    contentCategories,
    contentLocations,
    contentSchedules,
    attachment,
  },
});

createContent.failure = (localId, error) => ({
  type: ActionTypes.CONTENT_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleContentCreate = (content) => ({
  type: ActionTypes.CONTENT_CREATE_HANDLE,
  payload: {
    content,
  },
});

const fetchContents = (provinceId) => ({
  type: ActionTypes.CONTENTS_FETCH,
  payload: {
    provinceId,
  },
});

fetchContents.success = (
  provinceId,
  contents,
  total,
  attachments,
  contentCategories,
  contentLocations,
  contentSchedules,
) => ({
  type: ActionTypes.CONTENTS_FETCH__SUCCESS,
  payload: {
    provinceId,
    contents,
    total,
    attachments,
    contentCategories,
    contentLocations,
    contentSchedules,
  },
});

fetchContents.failure = (provinceId, error) => ({
  type: ActionTypes.CONTENTS_FETCH__FAILURE,
  payload: {
    provinceId,
    error,
  },
});

const searchContents = (provinceId) => ({
  type: ActionTypes.CONTENTS_SEARCH,
  payload: {
    provinceId,
  },
});

searchContents.success = (provinceId, contents) => ({
  type: ActionTypes.CONTENTS_SEARCH__SUCCESS,
  payload: {
    provinceId,
    contents,
  },
});

searchContents.failure = (provinceId, error) => ({
  type: ActionTypes.CONTENTS_SEARCH__FAILURE,
  payload: {
    provinceId,
    error,
  },
});

const selectContent = (content) => ({
  type: ActionTypes.CONTENT_SELECT,
  payload: {
    content,
  },
});

const updateContent = (id, data) => ({
  type: ActionTypes.CONTENT_UPDATE,
  payload: {
    id,
    data,
  },
});

updateContent.success = (
  content,
  contentLocations,
  contentSchedules,
  attachment,
  contentCategories,
) => ({
  type: ActionTypes.CONTENT_UPDATE__SUCCESS,
  payload: {
    content,
    contentLocations,
    contentSchedules,
    attachment,
    contentCategories,
  },
});

updateContent.failure = (id, error) => ({
  type: ActionTypes.CONTENT_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleContentUpdate = (content, included) => ({
  type: ActionTypes.CONTENT_UPDATE_HANDLE,
  payload: {
    content,
    included,
  },
});

const deleteContent = (id) => ({
  type: ActionTypes.CONTENT_DELETE,
  payload: {
    id,
  },
});

deleteContent.success = (content, schedules) => ({
  type: ActionTypes.CONTENT_DELETE__SUCCESS,
  payload: {
    content,
    schedules,
  },
});

deleteContent.failure = (id, error) => ({
  type: ActionTypes.CONTENT_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleContentDelete = (content) => ({
  type: ActionTypes.CONTENT_DELETE_HANDLE,
  payload: {
    content,
  },
});

const text2Speech = (data) => ({
  type: ActionTypes.CONTENT_TEXT_TO_SPEECH,
  payload: {
    data,
  },
});

text2Speech.success = (item) => ({
  type: ActionTypes.CONTENT_TEXT_TO_SPEECH__SUCCESS,
  payload: {
    item,
  },
});

text2Speech.failure = (error) => ({
  type: ActionTypes.CONTENT_TEXT_TO_SPEECH__FAILURE,
  payload: {
    error,
  },
});

const addContentToDevice = (id, deviceId) => ({
  type: ActionTypes.CONTENT_TO_DEVICE_ADD,
  payload: {
    id,
    deviceId,
  },
});

addContentToDevice.success = (deviceContent) => ({
  type: ActionTypes.CONTENT_TO_DEVICE_ADD__SUCCESS,
  payload: {
    deviceContent,
  },
});

addContentToDevice.failure = (id, deviceId, error) => ({
  type: ActionTypes.CONTENT_TO_DEVICE_ADD__FAILURE,
  payload: {
    id,
    deviceId,
    error,
  },
});

const handleContentToDeviceAdd = (deviceContent) => ({
  type: ActionTypes.CONTENT_TO_DEVICE_ADD_HANDLE,
  payload: {
    deviceContent,
  },
});

const removeContentFromDevice = (id, deviceId) => ({
  type: ActionTypes.CONTENT_FROM_DEVICE_REMOVE,
  payload: {
    id,
    deviceId,
  },
});

removeContentFromDevice.success = (deviceContent) => ({
  type: ActionTypes.CONTENT_FROM_DEVICE_REMOVE__SUCCESS,
  payload: {
    deviceContent,
  },
});

removeContentFromDevice.failure = (id, deviceId, error) => ({
  type: ActionTypes.CONTENT_FROM_DEVICE_REMOVE__FAILURE,
  payload: {
    id,
    deviceId,
    error,
  },
});

const handleContentFromDeviceRemove = (deviceContent) => ({
  type: ActionTypes.CONTENT_FROM_DEVICE_REMOVE_HANDLE,
  payload: {
    deviceContent,
  },
});

const addContentToDistrictFilter = (id, districtId) => ({
  type: ActionTypes.CONTENT_TO_DISTRICT_FILTER_ADD,
  payload: {
    id,
    districtId,
  },
});

const removeContentFromDistrictFilter = (id, districtId) => ({
  type: ActionTypes.CONTENT_FROM_DISTRICT_FILTER_REMOVE,
  payload: {
    id,
    districtId,
  },
});

const clearContentError = () => ({
  type: ActionTypes.CONTENT_ERROR_CLEAR,
  payload: {},
});

const clearContentPlaylist = () => ({
  type: ActionTypes.CONTENT_PLAYLIST_CLEAR,
  payload: {},
});

const clearContentConcatFile = () => ({
  type: ActionTypes.CONTENT_CONCAT_FILE__CLEAR,
  payload: {},
});

const cancelScheduleContent = (id, LichPhatID) => ({
  type: ActionTypes.CONTENT_CANCEL_SCHEDULE,
  payload: {
    id,
    LichPhatID,
  },
});

cancelScheduleContent.success = (content) => ({
  type: ActionTypes.CONTENT_CANCEL_SCHEDULE__SUCCESS,
  payload: {
    content,
  },
});

cancelScheduleContent.failure = (id, error) => ({
  type: ActionTypes.CONTENT_CANCEL_SCHEDULE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleContentCancelSchedule = (content, contentSchedules) => ({
  type: ActionTypes.CONTENT_CANCEL_SCHEDULE_HANDLE,
  payload: {
    content,
    contentSchedules,
  },
});

const fetchStationsBroadcastedContent = (id, MucDoUuTien) => ({
  type: ActionTypes.CONTENT_FETCH_STATIONS_BROADCASTED,
  payload: {
    id,
    MucDoUuTien,
  },
});

fetchStationsBroadcastedContent.success = (
  content,
  scheduleLogs,
  schedules,
  stations,
  total,
  users,
) => ({
  type: ActionTypes.CONTENT_FETCH_STATIONS_BROADCASTED__SUCCESS,
  payload: {
    content,
    scheduleLogs,
    schedules,
    stations,
    total,
    users,
  },
});

fetchStationsBroadcastedContent.failure = (id, error) => ({
  type: ActionTypes.CONTENT_FETCH_STATIONS_BROADCASTED__FAILURE,
  payload: {
    id,
    error,
  },
});

const concatFile = (data) => ({
  type: ActionTypes.CONTENT_CONCAT_FILE,
  payload: {
    data,
  },
});

concatFile.success = (attachment, duration) => ({
  type: ActionTypes.CONTENT_CONCAT_FILE__SUCCESS,
  payload: {
    attachment,
    duration,
  },
});

concatFile.failure = (error) => ({
  type: ActionTypes.CONTENT_CONCAT_FILE__FAILURE,
  payload: {
    error,
  },
});

const handleContentCategoryCreate = (contentCategory) => ({
  type: ActionTypes.CONTENT_CATEGORY_CREATE_HANDLE,
  payload: {
    contentCategory,
  },
});

const handleContentCategoryUpdate = (contentCategory) => ({
  type: ActionTypes.CONTENT_CATEGORY_UPDATE_HANDLE,
  payload: {
    contentCategory,
  },
});

const handleContentCategoryDelete = (contentCategory) => ({
  type: ActionTypes.CONTENT_CATEGORY_DELETE_HANDLE,
  payload: {
    contentCategory,
  },
});

const handleContentLocationCreate = (contentLocation) => ({
  type: ActionTypes.CONTENT_LOCATION_CREATE_HANDLE,
  payload: {
    contentLocation,
  },
});

const handleContentLocationDelete = (contentLocation) => ({
  type: ActionTypes.CONTENT_LOCATION_DELETE_HANDLE,
  payload: {
    contentLocation,
  },
});

const handleContentLocationUpdate = (contentLocations) => ({
  type: ActionTypes.CONTENT_LOCATION_UPDATE_HANDLE,
  payload: {
    contentLocations,
  },
});

const enalbleContentsFetching = () => ({
  type: ActionTypes.ENABLE_CONTENTS_FETCHING,
  payload: {},
});

export default {
  createContent,
  handleContentCreate,
  fetchContents,
  searchContents,
  selectContent,
  updateContent,
  handleContentUpdate,
  deleteContent,
  handleContentDelete,
  text2Speech,
  addContentToDevice,
  handleContentToDeviceAdd,
  removeContentFromDevice,
  handleContentFromDeviceRemove,
  addContentToDistrictFilter,
  removeContentFromDistrictFilter,
  clearContentError,
  clearContentPlaylist,
  clearContentConcatFile,
  cancelScheduleContent,
  handleContentCancelSchedule,
  fetchStationsBroadcastedContent,
  concatFile,
  handleContentCategoryCreate,
  handleContentCategoryUpdate,
  handleContentCategoryDelete,
  handleContentLocationCreate,
  handleContentLocationDelete,
  handleContentLocationUpdate,
  enalbleContentsFetching,
};
