import { attr, fk } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

const DEFAULT_DELETE_FORM = {
  data: {},
  error: null,
};

export default class extends BaseModel {
  static modelName = 'Category';

  static fields = {
    id: attr(),
    position: attr(),
    name: attr(),
    color: attr(),
    provinceId: fk({
      to: 'Province',
      as: 'province',
      relatedName: 'categories',
    }),
    parentId: fk({
      to: 'Category',
      as: 'category',
      relatedName: 'categories',
    }),
    deleteForm: attr({
      getDefault: () => DEFAULT_DELETE_FORM,
    }),
  };

  static reducer({ type, payload }, Category) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE:
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE:
        if (payload.categories) {
          payload.categories.forEach((category) => {
            Category.upsert(category);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Category.all().delete();

        if (payload.categories) {
          payload.categories.forEach((category) => {
            Category.upsert(category);
          });
        }

        break;
      case ActionTypes.CATEGORY_CREATE:
      case ActionTypes.CATEGORY_CREATE_HANDLE:
      case ActionTypes.CATEGORY_UPDATE__SUCCESS:
      case ActionTypes.CATEGORY_UPDATE_HANDLE:
        Category.upsert(payload.category);

        break;
      case ActionTypes.CATEGORY_CREATE__SUCCESS: {
        Category.withId(payload.localId).delete();

        Category.upsert(payload.category);

        break;
      }
      case ActionTypes.CATEGORY_UPDATE:
        Category.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.CATEGORY_DELETE:
        // Category.withId(payload.id).delete();

        break;
      case ActionTypes.CATEGORY_DELETE__SUCCESS:
      case ActionTypes.CATEGORY_DELETE_HANDLE: {
        const categoryModel = Category.withId(payload.category.id);

        if (categoryModel) {
          categoryModel.delete();
        }

        break;
      }
      case ActionTypes.CATEGORY_DELETE__FAILURE: {
        const categoryModel = Category.withId(payload.id);

        if (categoryModel) {
          categoryModel.update({
            ...categoryModel.deleteForm,
            deleteForm: { error: payload.error },
          });
        }

        break;
      }
      default:
    }
  }
}
