import EntryActionTypes from '../constants/EntryActionTypes';

const authenticate = (data) => ({
  type: EntryActionTypes.AUTHENTICATE,
  payload: {
    data,
  },
});

const authenticateCode = (data) => ({
  type: EntryActionTypes.AUTHENTICATE_CODE,
  payload: {
    data,
  },
});

const authenticateQRCode = (data) => ({
  type: EntryActionTypes.AUTHENTICATE_QRCODE,
  payload: {
    data,
  },
});

const clearAuthenticateCountDown = () => ({
  type: EntryActionTypes.AUTHENTICATE_COUNT_DOWN_CLEAR,
  payload: {},
});

const clearAuthenticateError = () => ({
  type: EntryActionTypes.AUTHENTICATE_ERROR_CLEAR,
  payload: {},
});

const retrieveQRCode = (token) => ({
  type: EntryActionTypes.AUTHENTICATE_QRCODE_RETRIEVE,
  payload: {
    token,
  },
});

const retrieveAuthenticateCode = (token) => ({
  type: EntryActionTypes.AUTHENTICATE_CODE_RETRIEVE,
  payload: { token },
});

export default {
  authenticate,
  authenticateCode,
  authenticateQRCode,
  retrieveAuthenticateCode,
  clearAuthenticateCountDown,
  clearAuthenticateError,
  retrieveQRCode,
};
