import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* categoriesWatchers() {
  yield all([
    takeEvery(EntryActionTypes.CATEGORY_IN_CURRENT_PROVINCE_CREATE, ({ payload: { data } }) =>
      services.createCategoryInCurrentProvince(data),
    ),
    takeEvery(EntryActionTypes.CATEGORY_CREATE_HANDLE, ({ payload: { category } }) =>
      services.handleCategoryCreate(category),
    ),
    takeEvery(EntryActionTypes.CATEGORY_UPDATE, ({ payload: { id, data } }) =>
      services.updateCategory(id, data),
    ),
    takeEvery(EntryActionTypes.CATEGORY_UPDATE_HANDLE, ({ payload: { category } }) =>
      services.handleCategoryUpdate(category),
    ),
    takeEvery(EntryActionTypes.CATEGORY_MOVE, ({ payload: { id, index, parentId } }) =>
      services.moveCategory(id, index, parentId),
    ),
    takeEvery(EntryActionTypes.CATEGORY_DELETE, ({ payload: { id } }) =>
      services.deleteCategory(id),
    ),
    takeEvery(EntryActionTypes.CATEGORY_DELETE_HANDLE, ({ payload: { category } }) =>
      services.handleCategoryDelete(category),
    ),
  ]);
}
