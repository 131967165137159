import React from 'react';
import PropTypes from 'prop-types';

import Filters from './Filters';
import Memberships from '../Memberships';
import DistrictMembershipPermissionsSelectStep from '../DistrictMembershipPermissionsSelectStep';

import styles from './DistrictActions.module.scss';

const DistrictActions = React.memo(
  ({
    memberships,
    contents,
    filterUsers,
    filterContents,
    allUsers,
    canEdit,
    canEditMemberships,
    onMembershipCreate,
    onMembershipUpdate,
    onMembershipDelete,
    onUserToFilterAdd,
    onUserFromFilterRemove,
    onContentToFilterAdd,
    onContentFromFilterRemove,
    onContentCreate,
    onContentUpdate,
    onContentMove,
    onContentDelete,
  }) => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.actions}>
          <div className={styles.action}>
            <Memberships
              items={memberships}
              allUsers={allUsers}
              permissionsSelectStep={DistrictMembershipPermissionsSelectStep}
              canEdit={canEditMemberships}
              onCreate={onMembershipCreate}
              onUpdate={onMembershipUpdate}
              onDelete={onMembershipDelete}
            />
          </div>
          <div className={styles.action}>
            <Filters
              users={filterUsers}
              contents={filterContents}
              allDistrictMemberships={memberships}
              allContents={contents}
              canEdit={canEdit}
              onUserAdd={onUserToFilterAdd}
              onUserRemove={onUserFromFilterRemove}
              onContentAdd={onContentToFilterAdd}
              onContentRemove={onContentFromFilterRemove}
              onContentCreate={onContentCreate}
              onContentUpdate={onContentUpdate}
              onContentMove={onContentMove}
              onContentDelete={onContentDelete}
            />
          </div>
        </div>
      </div>
    );
  },
);

DistrictActions.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  memberships: PropTypes.array.isRequired,
  contents: PropTypes.array.isRequired,
  filterUsers: PropTypes.array.isRequired,
  filterContents: PropTypes.array.isRequired,
  allUsers: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  canEdit: PropTypes.bool.isRequired,
  canEditMemberships: PropTypes.bool.isRequired,
  onMembershipCreate: PropTypes.func.isRequired,
  onMembershipUpdate: PropTypes.func.isRequired,
  onMembershipDelete: PropTypes.func.isRequired,
  onUserToFilterAdd: PropTypes.func.isRequired,
  onUserFromFilterRemove: PropTypes.func.isRequired,
  onContentToFilterAdd: PropTypes.func.isRequired,
  onContentFromFilterRemove: PropTypes.func.isRequired,
  onContentCreate: PropTypes.func.isRequired,
  onContentUpdate: PropTypes.func.isRequired,
  onContentMove: PropTypes.func.isRequired,
  onContentDelete: PropTypes.func.isRequired,
};

export default DistrictActions;
