import React from 'react';
import { Image, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const Media = React.memo(({ media, onClose }) => {
  const { type, url } = media;

  return (
    <Modal open onClose={onClose} size="small">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: 20,
        }}
      >
        {type === 'video' && (
          <video autoPlay controls style={{ width: '100%' }} muted>
            <source src={url} />
            <track kind="captions" />
          </video>
        )}
        {type === 'image' && <Image src={url} style={{ height: 'auto' }} />}
      </div>
    </Modal>
  );
});

export default Media;

Media.propTypes = {
  media: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onClose: PropTypes.func.isRequired,
};
