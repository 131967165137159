import EntryActionTypes from '../constants/EntryActionTypes';

const createScheduleInCurrentDistrict = (data) => ({
  type: EntryActionTypes.SCHEDULE_IN_CURRENT_DISTRICT_CREATE,
  payload: {
    data,
  },
});

const createScheduleInCurrentProvince = (data) => ({
  type: EntryActionTypes.SCHEDULE_IN_CURRENT_PROVINCE_CREATE,
  payload: {
    data,
  },
});

const handleScheduleCreate = (schedule) => ({
  type: EntryActionTypes.SCHEDULE_CREATE_HANDLE,
  payload: {
    schedule,
  },
});

const fetchSchedules = (data) => ({
  type: EntryActionTypes.SCHEDULES_FETCH,
  payload: {
    data,
  },
});

const updateSchedule = (id, data) => ({
  type: EntryActionTypes.SCHEDULE_UPDATE,
  payload: {
    id,
    data,
  },
});

const handleScheduleUpdate = (schedule) => ({
  type: EntryActionTypes.SCHEDULE_UPDATE_HANDLE,
  payload: {
    schedule,
  },
});

const moveSchedule = (id, index) => ({
  type: EntryActionTypes.SCHEDULE_MOVE,
  payload: {
    id,
    index,
  },
});

const deleteSchedule = (id) => ({
  type: EntryActionTypes.SCHEDULE_DELETE,
  payload: {
    id,
  },
});

const handleScheduleDelete = (schedule) => ({
  type: EntryActionTypes.SCHEDULE_DELETE_HANDLE,
  payload: {
    schedule,
  },
});

const addScheduleToDevice = (id, deviceId) => ({
  type: EntryActionTypes.SCHEDULE_TO_DEVICE_ADD,
  payload: {
    id,
    deviceId,
  },
});

const addScheduleToCurrentDevice = (id) => ({
  type: EntryActionTypes.SCHEDULE_TO_CURRENT_DEVICE_ADD,
  payload: {
    id,
  },
});

const handleScheduleToDeviceAdd = (deviceSchedule) => ({
  type: EntryActionTypes.SCHEDULE_TO_DEVICE_ADD_HANDLE,
  payload: {
    deviceSchedule,
  },
});

const removeScheduleFromDevice = (id, deviceId) => ({
  type: EntryActionTypes.SCHEDULE_FROM_DEVICE_REMOVE,
  payload: {
    id,
    deviceId,
  },
});

const removeScheduleFromCurrentDevice = (id) => ({
  type: EntryActionTypes.SCHEDULE_FROM_CURRENT_DEVICE_REMOVE,
  payload: {
    id,
  },
});

const handleScheduleFromDeviceRemove = (deviceSchedule) => ({
  type: EntryActionTypes.SCHEDULE_FROM_DEVICE_REMOVE_HANDLE,
  payload: {
    deviceSchedule,
  },
});

const addScheduleToFilterInCurrentDistrict = (id) => ({
  type: EntryActionTypes.SCHEDULE_TO_FILTER_IN_CURRENT_DISTRICT_ADD,
  payload: {
    id,
  },
});

const removeScheduleFromFilterInCurrentDistrict = (id) => ({
  type: EntryActionTypes.SCHEDULE_FROM_FILTER_IN_CURRENT_DISTRICT_REMOVE,
  payload: {
    id,
  },
});

const clearScheduleError = () => ({
  type: EntryActionTypes.SCHEDULE_ERROR_CLEAR,
  payload: {},
});

export default {
  createScheduleInCurrentDistrict,
  createScheduleInCurrentProvince,
  handleScheduleCreate,
  fetchSchedules,
  updateSchedule,
  handleScheduleUpdate,
  moveSchedule,
  deleteSchedule,
  handleScheduleDelete,
  addScheduleToDevice,
  addScheduleToCurrentDevice,
  handleScheduleToDeviceAdd,
  removeScheduleFromDevice,
  removeScheduleFromCurrentDevice,
  handleScheduleFromDeviceRemove,
  addScheduleToFilterInCurrentDistrict,
  removeScheduleFromFilterInCurrentDistrict,
  clearScheduleError,
};
