import ActionTypes from '../constants/ActionTypes';

const fetchActivities = (deviceId) => ({
  type: ActionTypes.ACTIVITIES_FETCH,
  payload: {
    deviceId,
  },
});

fetchActivities.success = (deviceId, activities, users) => ({
  type: ActionTypes.ACTIVITIES_FETCH__SUCCESS,
  payload: {
    deviceId,
    activities,
    users,
  },
});

fetchActivities.failure = (deviceId, error) => ({
  type: ActionTypes.ACTIVITIES_FETCH__FAILURE,
  payload: {
    deviceId,
    error,
  },
});

const toggleActivitiesDetails = (deviceId, isVisible) => ({
  type: ActionTypes.ACTIVITIES_DETAILS_TOGGLE,
  payload: {
    deviceId,
    isVisible,
  },
});

toggleActivitiesDetails.success = (deviceId, activities, users) => ({
  type: ActionTypes.ACTIVITIES_DETAILS_TOGGLE__SUCCESS,
  payload: {
    deviceId,
    activities,
    users,
  },
});

toggleActivitiesDetails.failure = (deviceId, error) => ({
  type: ActionTypes.ACTIVITIES_DETAILS_TOGGLE__FAILURE,
  payload: {
    deviceId,
    error,
  },
});

const handleActivityCreate = (activity) => ({
  type: ActionTypes.ACTIVITY_CREATE_HANDLE,
  payload: {
    activity,
  },
});

const handleActivityUpdate = (activity) => ({
  type: ActionTypes.ACTIVITY_UPDATE_HANDLE,
  payload: {
    activity,
  },
});

const handleActivityDelete = (activity) => ({
  type: ActionTypes.ACTIVITY_DELETE_HANDLE,
  payload: {
    activity,
  },
});

export default {
  fetchActivities,
  toggleActivitiesDetails,
  handleActivityCreate,
  handleActivityUpdate,
  handleActivityDelete,
};
