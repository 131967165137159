import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { at, upperFirst } from 'lodash';
import { Button, Form, Message, Modal, Select } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { endOfQuarter, startOfQuarter } from 'date-fns';

import { useForm } from '../../../hooks';

import CommuneRadioActivities from './CommuneRadioActivities';
import DistrictRadioTelevisionActivities from './DistrictRadioTelevisionActivities';
import CommuneVisualInformation from './CommuneVisualInformation';
import DistrictVisualInformation from './DistrictVisualInformation';
import CommuneTrainingOfficers from './CommuneTrainingOfficers';
import DistrictTrainingOfficers from './DistrictTrainingOfficers';
import NoBusinessNewsletter from './NoBusinessNewsletter';

import styles from './Export.module.scss';

const FormReports = [
  'SỐ LIỆU HOẠT ĐỘNG TRUYỀN THANH CẤP XÃ',
  'SỐ LIỆU HOẠT ĐỘNG TRUYỀN THANH - TRUYỀN HÌNH CẤP HUYỆN',
  'SỐ LIỆU VỀ THÔNG TIN TRỰC QUAN CẤP XÃ',
  'SỐ LIỆU VỀ THÔNG TIN TRỰC QUAN CẤP HUYỆN',
  'SỐ LIỆU TẬP HUẤN, BỒI DƯỠNG CÁN BỘ CẤP XÃ',
  'SỐ LIỆU TẬP HUẤN, BỒI DƯỠNG CÁN BỘ CẤP HUYỆN',
  'SỐ LIỆU TÀI LIỆU KHÔNG KINH DOANH VÀ BẢN TIN GIẤY',
];

const createMessage = (error) => {
  if (!error) {
    return error;
  }

  switch (error) {
    case 'quater':
      return {
        type: 'quater',
        content: 'common.quaterNotSelected',
      };
    case 'year':
      return {
        type: 'year',
        content: 'common.yearNotSelected',
      };
    case 'selectedForm':
      return {
        type: 'selectedForm',
        content: 'common.reportTemplateNotSelected',
      };
    default:
      return error;
  }
};

const QUATERS = ['I', 'II', 'III', 'IV'];

const LOCATION_REPORTS = {
  province: [0, 1, 4, 5, 6],
  district: [0, 1, 4, 5],
  ward: [0, 4],
};

const Export = React.memo(
  ({
    isSuccess,
    level,
    districts,
    wards,
    contents,
    devices,
    stations,
    onClose,
    onExport,
    onClear,
  }) => {
    const [t] = useTranslation();

    const [data, handleFieldChange] = useForm({
      quater: '',
      year: new Date(),
    });

    const [error, handleError] = useState('');

    const [selectedForm, setSelectedForm] = useState('');

    const message = useMemo(() => createMessage(error), [error]);

    const handleSelectForm = (_, { value }) => {
      setSelectedForm(value);
      handleError();
    };

    const handleSelectQuater = (_, { name, value }) => {
      handleFieldChange(_, { name, value });
      handleError();
    };

    const handlePickYear = (date) => {
      handleFieldChange(_, { name: 'year', value: date });
      handleError();
    };

    const handleExport = useCallback(() => {
      if (!data.quater) {
        handleError('quater');
        return;
      }

      if (!data.year) {
        handleError('year');
        return;
      }

      if (!selectedForm) {
        handleError('selectedForm');
        return;
      }

      const index = QUATERS.indexOf(data.quater);

      const startMonth = +index * 3;
      const year = data.year.getFullYear();

      const startDate = new Date(year, startMonth, 1);
      const start = startOfQuarter(startDate);
      const end = endOfQuarter(startDate);

      const filter = {
        from: start,
        to: end,
      };

      onExport({ filter });
    }, [data, selectedForm, onExport]);

    const handlePrintReport = useCallback(() => {
      const iframe = document.getElementById('frame-report');
      const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
      let html = '';
      let margin = '14mm 12mm 15mm 12mm';

      switch (selectedForm) {
        case FormReports[0]:
          html = CommuneRadioActivities(data, wards, contents, devices, stations);
          margin = '14mm 0mm 15mm 0mm';
          break;

        case FormReports[1]:
          html = DistrictRadioTelevisionActivities(data, districts, contents);
          margin = '14mm 0mm 15mm 0mm';
          break;

        case FormReports[2]:
          html = CommuneVisualInformation(data, wards);
          break;

        case FormReports[3]:
          html = DistrictVisualInformation(data, districts);
          break;

        case FormReports[4]:
          html = CommuneTrainingOfficers(data, wards);
          break;

        case FormReports[5]:
          html = DistrictTrainingOfficers(data, districts);
          break;

        case FormReports[6]:
          html = NoBusinessNewsletter(data, districts, wards);
          break;

        default:
      }

      const htmlPrint = `<!doc html><html>
      <head>
      <style>
      @media print {
        @page {
          size: A4 landscape;
          margin: ${margin};
        }
        body {
          padding: 20px;
        }
        table {
          border-collapse: collapse;
        }
        th {
          font-weight: normal;
          font-size: 13px;
          padding: 10px;
        }
        td {
          padding: 4px 6px;
        }
        td, th {
          height: 30px;
          border: 1px solid black;
        }
        tr, td {
          page-break-inside: avoid;
        }
        .header {
          text-align: center;
        }
        .header > p {
          font-size: 18px;
        }
        .footer {
          width: 80%;
          margin: auto;
        }
      }
      </style>
    </head>
    <body>
      ${html}
    </body>
    </html>`;

      iframeDoc.write(htmlPrint);
      iframeDoc.close();
      iframeDoc.defaultView.print();
      onClear();
    }, [selectedForm, data, districts, wards, contents, devices, stations, onClear]);

    useEffect(() => {
      if (isSuccess) {
        handlePrintReport();
      }
    }, [isSuccess, handlePrintReport]);

    const Options = at(FormReports, LOCATION_REPORTS[level]).map((it, i) => ({
      key: i,
      value: it,
      text: upperFirst(it.toLowerCase()),
    }));

    const QuaterOptions = QUATERS.map((it) => ({
      key: it,
      value: it,
      text: it,
    }));

    const FormOptions = [
      { key: 'default', value: '', text: t('common.selectReportTemplate') },
      ...Options,
    ];

    return (
      <Modal open closeIcon size="tiny" onClose={onClose}>
        <Modal.Header>{t('common.reportTemplate')}</Modal.Header>
        <Modal.Content>
          <iframe id="frame-report" style={{ display: 'none' }} title="iframe report" />
          {message && (
            <Message
              size="tiny"
              negative
              content={t(message.content)}
              className={styles.messageNonMarginTop}
            />
          )}
          <Form onSubmit={handleExport}>
            <Form.Group>
              <Form.Field
                width={8}
                control={Select}
                options={QuaterOptions}
                name="quater"
                onChange={handleSelectQuater}
                value={data.quater}
                placeholder={t('common.enterQuater')}
                error={message?.type === 'quater'}
                className={styles.marginBottom}
              />
              <Form.Field
                width={8}
                className={styles.marginBottom}
                error={message?.type === 'year'}
              >
                <ReactDatePicker
                  dateFormat="yyyy"
                  disabledKeyboardNavigation
                  selected={data.year}
                  showYearPicker
                  maxDate={new Date()}
                  yearItemNumber={9}
                  onChange={handlePickYear}
                  calendarClassName={styles.calendar}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field
              control={Select}
              className={styles.marginBottom}
              placeholder={t('common.selectReportTemplate')}
              options={FormOptions}
              value={selectedForm}
              fluid
              onChange={handleSelectForm}
              error={message?.type === 'selectedForm'}
            />
            <Button
            // onClick={handlePrintReport}
            >
              {t('action.exportReport')}
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  },
);

export default Export;

Export.propTypes = {
  isSuccess: PropTypes.bool.isRequired,
  level: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  districts: PropTypes.array.isRequired,
  wards: PropTypes.array.isRequired,
  contents: PropTypes.array.isRequired,
  devices: PropTypes.array.isRequired,
  stations: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};
