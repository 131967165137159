import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import ProvinceSettings from '../components/ProvinceSettings';
import createDataTree from '../utils/create-data-tree';

const mapStateToProps = (state) => {
  let users = selectors.selectUsers(state);

  const {
    name,
    code,
    background,
    backgroundImage,
    isBackgroundImageUpdating,
    deleteForm: { error },
  } = selectors.selectCurrentProvince(state);

  let managers = selectors.selectManagersForCurrentProvince(state);
  const isDarkMode = selectors.selectIsDarkMode(state);
  // const payload = selectors.selectCategoryById(state);
  const categories = selectors.selectCategoriesForCurrentProvince(state);
  const { level, isAdmin } = selectors.selectCurrentUser(state);

  const currentUserCanAddManagerUser = level === 'province' && isAdmin;

  if (level === 'district') {
    users = users.filter((user) => user.level !== 'province');
    managers = managers.filter((it) => it.user.level && it.user.level !== 'province');
  }

  if (level === 'ward') {
    users = users.filter((user) => user.level === level);
    managers = managers.filter((it) => it.user.level && it.user.level === level);
  }

  return {
    allUsers: users,
    background,
    backgroundImage,
    categories: createDataTree(categories),
    isBackgroundImageUpdating,
    isDarkMode,
    isAdmin,
    canAdd: currentUserCanAddManagerUser,
    canEdit: currentUserCanAddManagerUser,
    canDelete: currentUserCanAddManagerUser,
    managers,
    name,
    code,
    error,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onBackgroundImageUpdate: entryActions.updateCurrentProvinceBackgroundImage,
      onCategoryCreate: entryActions.createCategoryInCurrentProvince,
      onCategoryDelete: entryActions.deleteCategory,
      onCategoryMove: entryActions.moveCategory,
      onCategoryUpdate: entryActions.updateCategory,
      onClose: entryActions.closeModal,
      onDelete: entryActions.deleteCurrentProvince,
      onManagerCreate: entryActions.createManagerInCurrentProvince,
      onManagerDelete: entryActions.deleteProvinceManager,
      onUpdate: entryActions.updateCurrentProvince,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProvinceSettings);
