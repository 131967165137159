import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  Image,
  Input,
  Label,
  Message,
  Select,
  TextArea,
} from 'semantic-ui-react';
import SimpleMDE from 'react-simplemde-editor';
import { useTranslation } from 'react-i18next';
import { truncate } from 'lodash';
import 'easymde/dist/easymde.min.css';
import isEmail from 'validator/lib/isEmail';
import isUrl from 'validator/lib/isURL';

import { FilePicker } from '../../../lib/custom-ui';
import {
  BroadcastingZone,
  ContentTypeOptions,
  PriorityTypes,
  RelaySourceOptions,
  SourceTypes,
} from '../../../constants/Enums';
import { useToggle } from '../../../lib/hooks';
import Config from '../../../constants/Config';

import MergeFileContainer from '../../../containers/MergeFileContainer';
import WarningChangeAttachment from './WarningChangeAttachment';

import styles from './StepCreateContent.module.scss';

const getIcon = (type) => {
  switch (type) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
      return 'file word';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'file excel';
    case 'application/pdf':
      return 'file pdf';
    case 'text/plain':
    case 'text/csv':
      return 'file text';
    case 'audio/mpeg':
      return 'file audio';
    case 'image/png':
    case 'image/jpeg':
    case 'image/gif':
    case 'image/svg+xml':
    case 'image/heic':
      return 'file image';
    default:
      return 'file';
  }
};

const acceptFile = (contentType, type) => {
  if (contentType === 'broadcasting') {
    return 'audio/mpeg, audio/wav, application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  }

  switch (type) {
    case 'audio':
      return 'audio/mpeg, audio/wav, application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'video':
      return 'video/*';
    case 'image':
      return 'image/*';
    case 'text':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    default:
      return `application/vnd.openxmlformats-officedocument.wordprocessingml.document, video/*, image/*`;
  }
};

const BroadcastingTypeOptions = ['audio', 'stream'];
const BulletinBoardTypeOptions = ['image', 'video', 'text', 'stream'];

const DETAIL_INIT = {
  Ten: '',
  GiaTri: '',
};

const StepCreateContent = React.forwardRef(
  (
    {
      token,
      isSubmitting,
      isEditing,
      myFile,
      data,
      playlist,
      attachments,
      categories,
      time,
      attachment,
      concatFile,
      warningRef,
      onChange,
      onDeleteAttachment,
      onDeleteMyFile,
      onFileSelect,
      onSelectCategories,
      onSelectPriorityLevel,
      onText2Speech,
      onSelectContentType,
      onChangeAuthor,
      onChangeContentDetails,
      onChangeDuration,
      onSelectSource,
      onSelectConcatFile,
      onRemoveConcatFile,
      onRemoveT2S,
    },
    ref,
  ) => {
    const [t] = useTranslation();

    const nameField = useRef();
    const emailField = useRef();
    const timeField = useRef();
    const linkField = useRef();
    const descriptionField = useRef();

    const [openSelectType, setOpenSelectType] = useState(false);
    const [openPriorityType, setOpenPriorityType] = useState(false);
    const [openSelectCategory, setOpenSelectCategory] = useState(false);
    const [openSelectZone, setOpenSelectZone] = useState(false);
    const [openSourceType, setOpenSourceType] = useState(false);
    const [autoPlay, setAutoPlay] = useState(true);
    // const [playingIndex, setPlayingIndex] = useState(0);
    const [detail, setDetail] = useState(DETAIL_INIT);
    const [fileSizeMessage, setFileSizeMessage] = useState('');

    const [isOpenModalConcatMultiFiles, handleToggleModalConcatMultiFiles] = useToggle();

    const handleText2Speech = useCallback(() => {
      if (data.description) {
        onText2Speech({
          data: data.description,
          voice: '1',
          speed: '1',
        });
      }
      setAutoPlay(true);
    }, [data.description, onText2Speech]);

    const handleValidateField = useCallback(() => {
      if (!data.name) {
        nameField.current.focus();
        return;
      }

      if (!data.type) {
        setOpenSelectType(true);
        return;
      }

      if (data.categories.length <= 0) {
        setOpenSelectCategory(true);
        return;
      }

      if (data.TacGia.Email && !isEmail(data.TacGia.Email)) {
        emailField.current.focus();
        return;
      }

      if (!data.duration) {
        timeField.current.focus();
        return;
      }

      if (data.type === 'stream' && data.NguonPhat !== 'microphone' && !isUrl(data.link)) {
        linkField.current.focus();
        return;
      }

      if (!data.description) {
        descriptionField.current.focus();
      }
    }, [
      data.name,
      data.type,
      data.categories,
      data.description,
      data.duration,
      data.TacGia.Email,
      data.link,
      data.NguonPhat,
    ]);

    const handleToggleSelectType = useCallback((boolean) => {
      setOpenSelectType(boolean);
    }, []);

    const handleTogglePriorityType = useCallback((boolean) => {
      setOpenPriorityType(boolean);
    }, []);

    const handleToggleSelectCategory = useCallback((boolean) => {
      setOpenSelectCategory(boolean);
    }, []);

    const handleToggleSourceType = useCallback((boolean) => {
      setOpenSourceType(boolean);
    }, []);

    const handleToggleSelectZone = useCallback((boolean) => {
      setOpenSelectZone(boolean);
    }, []);

    // const handlePlayerEnded = useCallback(() => {
    //   if (playingIndex + 1 < playlist.length) {
    //     setAutoPlay(true);
    //     setPlayingIndex(playingIndex + 1);
    //   } else {
    //     setAutoPlay(false);
    //     setPlayingIndex(0);
    //   }
    // }, [playingIndex, playlist.length]);

    const handleChangeDetail = useCallback((_, { name, value }) => {
      setDetail((prev) => ({ ...prev, [name]: value }));
    }, []);

    const handleCreateContentDetail = useCallback(() => {
      if (detail.Ten && detail.GiaTri) {
        onChangeContentDetails(detail);
        setDetail(DETAIL_INIT);
      }
    }, [detail, onChangeContentDetails]);

    const handleLargeFileSize = (message) => {
      setFileSizeMessage(message);
    };

    const mdEditorOptions = useMemo(
      () => ({
        autofocus: true,
        spellChecker: false,
        status: false,
        toolbar: [
          'bold',
          'italic',
          'heading',
          'strikethrough',
          '|',
          'quote',
          'unordered-list',
          'ordered-list',
          'table',
          '|',
          'link',
          'image',
          'preview',
          '|',
          'undo',
          'redo',
        ],
      }),
      [],
    );

    const isInvalid = !!data.TacGia.Email && !isEmail(data.TacGia.Email);

    const isLink = !!data.link && !isUrl(data.link);

    const CategoriesOptions = categories.map((cate) => ({
      key: cate.id,
      value: cate.id,
      text: cate.name,
    }));

    const ContentTypesOptionsFilter =
      data.PhanLoai === 'broadcasting'
        ? ContentTypeOptions.filter((it) => BroadcastingTypeOptions.includes(it.key))
        : ContentTypeOptions.filter((it) => BulletinBoardTypeOptions.includes(it.key));

    const SourceTypeOptions =
      data.PhanLoai === 'bulletinBoard' || data.type === 'audio' ? [SourceTypes[0]] : SourceTypes;

    const renderLabel = (label) => ({
      content: label.text,
      color: 'teal',
    });

    useImperativeHandle(ref, () => ({ handleValidateField, handleLargeFileSize }));

    return (
      <div className={styles.wrapperCreateContent}>
        <Form.Group widths="equal">
          <Form.Field>
            <div className={styles.label}>{t('common.name')}</div>
            <Input
              readOnly={data.MucDoUuTien === 1 && isEditing}
              ref={nameField}
              id="form-input-control-name"
              name="name"
              placeholder={t('common.name')}
              value={data.name}
              onChange={onChange}
            />
          </Form.Field>
          <Form.Field>
            <div className={styles.label}>{t('common.contentType')}</div>
            <Select
              disabled={isEditing}
              open={openSelectType}
              onBlur={() => handleToggleSelectType(false)}
              onClose={() => handleToggleSelectType(false)}
              onClick={() => handleToggleSelectType(true)}
              id="form-input-control-type"
              name="type"
              value={data.type}
              placeholder={t('common.contentType')}
              onChange={onSelectContentType}
              options={ContentTypesOptionsFilter.map((it) => ({
                ...it,
                // disabled: it.key === 'stream' && isEditing,
                text: `Bản tin ${t(`common.${it.key}`)}`,
              }))}
            />
          </Form.Field>
          <Form.Field>
            <div className={styles.label}>{t('common.category')}</div>
            <Select
              disabled={data.MucDoUuTien === 1 && isEditing}
              multiple
              open={openSelectCategory}
              closeOnChange
              onBlur={() => handleToggleSelectCategory(false)}
              onClose={() => handleToggleSelectCategory(false)}
              onClick={() => handleToggleSelectCategory(true)}
              name="categories"
              renderLabel={renderLabel}
              value={data.categories}
              placeholder={t('common.searchCategory')}
              onChange={(_, { value }) => onSelectCategories(value)}
              options={CategoriesOptions}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <div className={styles.label}>{t('common.priorityLevel')}</div>
            <Select
              open={openPriorityType}
              disabled={data.MucDoUuTien === 1 && isEditing}
              onBlur={() => handleTogglePriorityType(false)}
              onClose={() => handleTogglePriorityType(false)}
              onClick={() => handleTogglePriorityType(true)}
              id="form-input-control-type"
              name="MucDoUuTien"
              value={data.MucDoUuTien}
              placeholder={t('common.priorityLevel')}
              onChange={onSelectPriorityLevel}
              options={PriorityTypes.map((it) => ({
                ...it,
                disabled: it.key === 'urgent' && isEditing,
                text: t(`common.${it.key}`),
              }))}
            />
          </Form.Field>
          {data.PhanLoai === 'bulletinBoard' && (
            <Form.Field>
              <div className={styles.label}>{t('common.broadcastingZone')}</div>
              <Select
                disabled={data.MucDoUuTien === 1 && isEditing}
                open={openSelectZone}
                closeOnChange
                onBlur={() => handleToggleSelectZone(false)}
                onClose={() => handleToggleSelectZone(false)}
                onClick={() => handleToggleSelectZone(true)}
                name="VungPhat"
                renderLabel={renderLabel}
                value={data.VungPhat}
                placeholder={t('common.broadcastingZone')}
                onChange={onChange}
                options={BroadcastingZone.map((it) => ({ ...it, text: t(`common.${it.key}`) }))}
              />
            </Form.Field>
          )}
          {data.MucDoUuTien === 1 && (
            <Form.Field>
              <div className={styles.label}>{t('common.source')}</div>
              <Select
                disabled={data.MucDoUuTien === 1 && isEditing}
                open={openSourceType}
                onBlur={() => handleToggleSourceType(false)}
                onClose={() => handleToggleSourceType(false)}
                onClick={() => handleToggleSourceType(true)}
                id="form-input-control-type"
                name="NguonPhat"
                value={data.NguonPhat}
                placeholder={t('common.source')}
                onChange={onSelectSource}
                options={SourceTypeOptions.map((it) => ({
                  ...it,
                  text: t(`common.${it.key}`),
                }))}
              />
            </Form.Field>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Field width={5}>
            <div className={styles.label}>{t('common.duration')}</div>
            <Input
              id="duration-input"
              type="text"
              ref={timeField}
              placeholder="00:00:00"
              value={time}
              error={!time.match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/)}
              onChange={onChangeDuration}
            />
          </Form.Field>
          {data.PhanLoai === 'broadcasting' &&
            data.type === 'stream' &&
            data.NguonPhat !== 'microphone' && (
              <Form.Field width={11}>
                <div className={styles.label}>{t('common.linkLiveStream')}</div>
                <Input
                  id="form-input-control-link"
                  ref={linkField}
                  name="link"
                  placeholder="https://..."
                  value={data.link}
                  error={isLink}
                  onChange={onChange}
                />
              </Form.Field>
            )}
        </Form.Group>
        <div className={styles.field}>
          <span className={styles.label}>{t('common.summaryContent')}</span>
          <TextArea
            ref={descriptionField}
            readOnly={data.MucDoUuTien === 1 && isEditing}
            rows={6}
            name="description"
            value={data.description}
            placeholder={t('common.summaryContent')}
            onChange={onChange}
          />
        </div>
        {data.NguonPhat !== 'microphone' && (
          <>
            {data.PhanLoai === 'bulletinBoard' &&
              (data.type === 'text' || data.type === 'stream') && (
                <SimpleMDE
                  name="NoiDung"
                  value={data.NoiDung}
                  options={mdEditorOptions}
                  placeholder={t('common.enterDescription')}
                  onChange={(value) => onChange(_, { name: 'NoiDung', value })}
                />
              )}
            {attachments.length >= 0 && (
              <div>
                <div className={styles.moduleWrapper}>
                  <Icon name="attach" className={styles.moduleIcon} />
                  <FilePicker onSelect={onFileSelect} accept={acceptFile(data.PhanLoai, data.type)}>
                    <Button
                      type="button"
                      disabled={
                        (data.type === 'stream' && data.PhanLoai === 'broadcasting') ||
                        attachments.length > 0
                      }
                    >
                      {t('common.attachments', {
                        context: 'title',
                      })}
                    </Button>
                  </FilePicker>
                  <Button
                    type="button"
                    onClick={handleToggleModalConcatMultiFiles}
                    disabled={
                      (data.type === 'stream' && data.PhanLoai === 'broadcasting') ||
                      attachments.length > 0
                    }
                  >
                    {t('common.joinMultiFiles', {
                      context: 'title',
                    })}
                  </Button>
                  {/* <div className={styles.tip}>
                    {t('common.pressPasteShortcutToAddAttachmentFromClipboard')}
                  </div> */}

                  {myFile && (
                    <>
                      {myFile.type.indexOf('image') === 0 && (
                        <Image src={URL.createObjectURL(myFile)} alt="preview" height={100} />
                      )}
                      <Label
                        as="a"
                        icon={getIcon(myFile.type)}
                        content={truncate(myFile.name, { length: 10 })}
                        onRemove={onDeleteMyFile}
                      />
                    </>
                  )}
                  {concatFile && (
                    <Label
                      as="a"
                      icon={getIcon()}
                      content={truncate(concatFile.name, { length: 10 })}
                      onRemove={onRemoveConcatFile}
                    />
                  )}

                  {attachment && (
                    <Label
                      as="a"
                      icon={getIcon()}
                      content={truncate(attachment.name, { length: 10 })}
                      onRemove={onRemoveT2S}
                    />
                  )}
                  {fileSizeMessage && (
                    <Message
                      style={{ marginTop: 0, padding: '1em 2em' }}
                      size="small"
                      visible
                      negative
                      onDismiss={() => setFileSizeMessage('')}
                      content={t(fileSizeMessage, { fileSize: Config.FILE_SIZE })}
                    />
                  )}
                </div>
                {attachments.map(({ name, id }) => (
                  <Label
                    key={id}
                    as="a"
                    className={styles.attachmentMarginTop}
                    content={truncate(name, { length: 10 })}
                    onRemove={() => onDeleteAttachment(id)}
                    removeIcon={data.MucDoUuTien === 1 && isEditing ? '' : 'close'}
                  />
                ))}
                <WarningChangeAttachment ref={warningRef} />
              </div>
            )}
            {data.PhanLoai === 'broadcasting' && (
              <>
                <Divider />
                <div className={styles.text}>
                  {t('common.text2speech')}({data.description?.trim().length})
                </div>
                <div className={styles.text2SpeechWrapper}>
                  {/* {playlist.length > 0 && ( */}
                  {attachment && (
                    <audio
                      // src={playlist[playingIndex]}
                      src={`${attachment.url}?accessToken=${token}`}
                      autoPlay={autoPlay}
                      controls
                      // onEnded={handlePlayerEnded}
                    >
                      <track kind="captions" />
                    </audio>
                  )}
                  <Button
                    type="button"
                    onClick={handleText2Speech}
                    loading={isSubmitting}
                    disabled={
                      isSubmitting ||
                      (data.type === 'stream' && data.PhanLoai === 'broadcasting') ||
                      (data.MucDoUuTien === 1 && isEditing) ||
                      myFile?.type?.includes('audio') ||
                      !!concatFile ||
                      Boolean(attachments.length > 0 && attachments[0].name.match(/.mp3|.wav$/))
                    }
                  >
                    {t('common.tryListening')}
                  </Button>
                </div>
              </>
            )}
          </>
        )}
        <Divider horizontal fitted>
          <Header as="h4">{t('common.author')}</Header>
        </Divider>
        <Form.Group widths="equal">
          <Form.Field>
            <div className={styles.label}>{t('common.fullname')}</div>
            <Input
              readOnly={data.MucDoUuTien === 1 && isEditing}
              id="form-input-control-author-name"
              name="TenDayDu"
              placeholder={t('common.fullname')}
              value={data.TacGia.TenDayDu}
              onChange={onChangeAuthor}
            />
          </Form.Field>
          <Form.Field>
            <div className={styles.label}>{t('common.pseudonym')}</div>
            <Input
              readOnly={data.MucDoUuTien === 1 && isEditing}
              id="form-input-control-author-pseudonym"
              name="ButDanh"
              placeholder={t('common.pseudonym')}
              value={data.TacGia.ButDanh}
              onChange={onChangeAuthor}
            />
          </Form.Field>
          <Form.Field>
            <div className={styles.label}>
              Email
              {isInvalid && (
                <small style={{ color: '#9f3a38 ', marginLeft: 10 }}>
                  {t('common.emailInvalid')}
                </small>
              )}
            </div>
            <Input
              readOnly={data.MucDoUuTien === 1 && isEditing}
              ref={emailField}
              id="form-input-control-author-email"
              name="Email"
              error={isInvalid}
              placeholder="Email"
              value={data.TacGia.Email}
              onChange={onChangeAuthor}
            />
          </Form.Field>
        </Form.Group>
        <Divider horizontal fitted>
          <Header as="h4">{t('common.contentDetails')}</Header>
        </Divider>
        <Form.Group widths="equal">
          <Form.Field>
            <div className={styles.label}>{t('common.name')}</div>
            <Input
              readOnly={data.MucDoUuTien === 1 && isEditing}
              id="form-input-control-detail-name"
              name="Ten"
              placeholder={t('common.name')}
              value={detail.Ten}
              onChange={handleChangeDetail}
            />
          </Form.Field>
          <Form.Field>
            <div className={styles.label}>{t('common.value')}</div>
            <Input
              readOnly={data.MucDoUuTien === 1 && isEditing}
              id="form-input-control-detail-value"
              name="GiaTri"
              placeholder={t('common.value')}
              value={detail.GiaTri}
              onChange={handleChangeDetail}
            />
          </Form.Field>
          <Form.Button
            style={{ marginTop: 22, minHeight: 38 }}
            type="button"
            content={t('action.create')}
            disabled={data.MucDoUuTien === 1 && isEditing}
            onClick={handleCreateContentDetail}
          />
        </Form.Group>
        {React.Children.toArray(
          data.ThongTinChiTietBanTin.map((it, idx) => (
            <Label
              style={{ marginBottom: 4 }}
              color="teal"
              content={`${it.Ten} - ${it.GiaTri}`}
              onRemove={() => onChangeContentDetails({}, idx)}
            />
          )),
        )}
        <Divider horizontal fitted>
          <Header as="h4">{t('common.relaySource')}</Header>
        </Divider>
        <Form.Group>
          <Form.Field width={6}>
            <div className={styles.label}>{t('common.relay')}</div>
            <Select
              value={data.TiepAm}
              name="TiepAm"
              onChange={onChange}
              options={RelaySourceOptions.map((it) => ({ ...it, text: t(`common.${it.key}`) }))}
            />
          </Form.Field>
          <Form.Field width={10}>
            <span className={styles.label}>{t('common.description')}</span>
            <Input
              fluid
              readOnly={data.MucDoUuTien === 1 && isEditing}
              id="form-input-control-relay-source"
              name="NguonTiepAm"
              placeholder={t('common.description')}
              value={data.NguonTiepAm}
              onChange={onChange}
            />
          </Form.Field>
        </Form.Group>
        {isOpenModalConcatMultiFiles && (
          <MergeFileContainer
            onSelect={onSelectConcatFile}
            onClose={handleToggleModalConcatMultiFiles}
          />
        )}
      </div>
    );
  },
);

export default StepCreateContent;

StepCreateContent.propTypes = {
  token: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  attachments: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  categories: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isSubmitting: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  myFile: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  playlist: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  attachment: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  concatFile: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  warningRef: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func.isRequired,
  onDeleteAttachment: PropTypes.func.isRequired,
  onDeleteMyFile: PropTypes.func.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  onSelectCategories: PropTypes.func.isRequired,
  onSelectPriorityLevel: PropTypes.func.isRequired,
  onText2Speech: PropTypes.func.isRequired,
  onSelectContentType: PropTypes.func.isRequired,
  onChangeAuthor: PropTypes.func.isRequired,
  onChangeContentDetails: PropTypes.func.isRequired,
  onChangeDuration: PropTypes.func.isRequired,
  onSelectSource: PropTypes.func.isRequired,
  onSelectConcatFile: PropTypes.func.isRequired,
  onRemoveConcatFile: PropTypes.func.isRequired,
  onRemoveT2S: PropTypes.func.isRequired,
};

StepCreateContent.defaultProps = {
  myFile: undefined,
  concatFile: undefined,
  attachment: undefined,
  warningRef: undefined,
};
