import truncate from 'lodash/truncate';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Tab } from 'semantic-ui-react';

import { Popup } from '../../lib/custom-ui';
import Paths from '../../constants/Paths';
import { ActivityTypes, CONTROL_CODES, PriorityTypes } from '../../constants/Enums';
import { datetimeDistanceToNow } from '../../utils/modify-time';
import Config from '../../constants/Config';

import User from '../User';

import styles from './NotificationsStep.module.scss';

const LIMIT = Config.LIMIT_NOTIFICATION;

const FILTER = {
  0: 'all',
  1: 'unread',
  2: 'read',
};

const LENGTH_STRING = 85;

const filterLocationToString = (entities, level, locationId, entityType = 'id') => {
  let entityString = entities.map((it) => it.name).join(', ');

  if (level === 'district') {
    const filterEntities = entities.filter((it) => it.districtId === locationId);
    entityString = filterEntities.map((it) => it.name).join(', ');
  } else if (level === 'ward') {
    const filterEntities = entities.filter((it) => it[entityType] === locationId);
    entityString = filterEntities.map((it) => it.name).join(', ');
  }

  return truncate(entityString, { length: LENGTH_STRING });
};

const NotificationsStep = React.memo(
  ({ items, total, level, location, type, onDelete, onUpdate, onClose, onFetch }) => {
    const [t] = useTranslation();

    const filterType = Object.values(FILTER).indexOf(type);

    const [selectIndex, setSelectIndex] = useState(filterType);
    const [page, setPage] = useState(1);

    const handleDelete = useCallback(
      (id) => {
        onDelete(id);
      },
      [onDelete],
    );

    const handleUpdate = useCallback(
      (id) => {
        onUpdate(id);
      },
      [onUpdate],
    );

    const handleTabChange = useCallback(
      (_, { activeIndex }) => {
        setSelectIndex(activeIndex);
        setPage(1);
        onFetch(FILTER[activeIndex], 1, LIMIT);
      },
      [onFetch],
    );

    const handleSeeMore = useCallback(() => {
      setPage((prev) => prev + 1);
      onFetch(FILTER[selectIndex], page + 1, LIMIT);
    }, [page, selectIndex, onFetch]);

    const renderItemContent = useCallback(
      ({ activity, device, station, content }) => {
        switch (activity.type) {
          /* CONTENT */

          case ActivityTypes.CREATE_CONTENT: {
            const wardsString = filterLocationToString(activity.data.wards, level, location.id);

            const priorityLevel = PriorityTypes.find(({ value }) => value === content.MucDoUuTien);

            const priority = content.MucDoUuTien === 1 ? t(`common.${priorityLevel.text}`) : '';

            return (
              <Trans
                i18nKey="common.userCreateContentToWards"
                values={{
                  user: activity.user.name,
                  priority,
                  content: content.name,
                  wards: wardsString,
                }}
              >
                <strong style={{ color: '#2185d0 ' }}>{activity.user.name}</strong>
                {' create '}
                <strong style={{ color: '#db2828 ' }}>{priority}</strong>
                <strong>{content.name}</strong>
                {' to '}
                {wardsString}
              </Trans>
            );
          }

          case ActivityTypes.UPDATE_CONTENT: {
            const wardsString = filterLocationToString(activity.data.wards, level, location.id);

            return (
              <Trans
                i18nKey="common.userUpdateInfoContentToWards"
                values={{
                  user: activity.user.name,
                  content: content.name,
                  wards: wardsString,
                }}
              >
                <strong style={{ color: '#2185d0 ' }}>{activity.user.name}</strong>
                {' updateInfo '}
                <strong>{content.name}</strong>
                {' to '}
                {wardsString}
              </Trans>
            );
          }

          case ActivityTypes.UPDATE_CONTENT_LOCATION: {
            const wardsString = filterLocationToString(activity.data.wards, level, location.id);

            return (
              <Trans
                i18nKey="common.userAddLocationToContent"
                values={{
                  user: activity.user.name,
                  content: content.name,
                  wards: wardsString,
                }}
              >
                <strong style={{ color: '#2185d0 ' }}>{activity.user.name}</strong>
                {' updateLocation '}
                <strong style={{ color: '#db2828' }}>{wardsString}</strong>
                {' to '}
                {content.name}
              </Trans>
            );
          }

          case ActivityTypes.UPDATE_SCHEDULE: {
            const wardsString = filterLocationToString(activity.data.wards, level, location.id);

            return (
              <Trans
                i18nKey="common.userUpdateScheduleContentToWards"
                values={{
                  user: activity.user.name,
                  content: content.name,
                  wards: wardsString,
                }}
              >
                <strong style={{ color: '#2185d0 ' }}>{activity.user.name}</strong>
                {' updateSchedule '}
                <strong>{content.name}</strong>
                {' to '}
                {wardsString}
              </Trans>
            );
          }

          case ActivityTypes.DELETE_CONTENT_LOCATION: {
            const wardsString = filterLocationToString(activity.data.wards, level, location.id);

            return (
              <Trans
                i18nKey="common.userDeleteLocationToContent"
                values={{
                  user: activity.user.name,
                  content: content.name,
                  wards: wardsString,
                }}
              >
                <strong style={{ color: '#2185d0 ' }}>{activity.user.name}</strong>
                {' updateLocation '}
                <strong style={{ color: '#db2828' }}>{wardsString}</strong>
                {' to '}
                {content.name}
              </Trans>
            );
          }

          case ActivityTypes.DELETE_SCHEDULE: {
            const wardsString = filterLocationToString(activity.data.wards, level, location.id);

            return (
              <Trans
                i18nKey="common.userDeleteScheduleToContent"
                values={{
                  user: activity.user.name,
                  content: content.name,
                  wards: wardsString,
                }}
              >
                <strong style={{ color: '#2185d0 ' }}>{activity.user.name}</strong>
                {' updateLocation '}
                <strong style={{ color: '#db2828' }}>{content.name}</strong>
                {' to '}
                {wardsString}
              </Trans>
            );
          }

          case ActivityTypes.DELETE_CONTENT: {
            const wardsString = filterLocationToString(activity.data.wards, level, location.id);

            return (
              <Trans
                i18nKey="common.userDeleteContentAtWards"
                values={{
                  user: activity.user.name,
                  content: content.name,
                  wards: wardsString,
                }}
              >
                <strong style={{ color: '#2185d0 ' }}>{activity.user.name}</strong>
                {' delete '}
                <strong style={{ color: '#db2828' }}>{content.name}</strong>
                {' at '}
                {wardsString}
              </Trans>
            );
          }

          case ActivityTypes.CANCEL_SCHEDULE: {
            const wardsString = filterLocationToString(activity.data.wards, level, location.id);

            return (
              <Trans
                i18nKey="common.userCancelScheduleOfContent"
                values={{
                  user: activity.user.name,
                  content: content.name,
                  wards: wardsString,
                }}
              >
                <strong style={{ color: '#2185d0 ' }}>{activity.user.name}</strong>
                {' cancelSchedule '}
                <strong style={{ color: '#db2828' }}>{content.name}</strong>
                {' of '}
                {wardsString}
              </Trans>
            );
          }

          /* DEVICE */

          case ActivityTypes.CREATE_DEVICE:
            return (
              <Trans
                i18nKey="common.userCreateDeviceToStation"
                values={{
                  user: activity.user.name,
                  device: device.name,
                  station: activity.data.station.name,
                }}
              >
                <strong style={{ color: '#2185d0 ' }}>{activity.user.name}</strong>
                {' create '}
                {/* <Link to={Paths.DEVICE.replace(':id', device.id)} onClick={onClose}> */}
                <strong>{device.name}</strong>
                {/* </Link> */}
                {' to '}
                {activity.data.station.name}
              </Trans>
            );
          case ActivityTypes.UPDATE_DEVICE:
            return (
              <Trans
                i18nKey="common.userUpdateInfoDeviceAtStation"
                values={{
                  user: activity.user.name,
                  device: device.name,
                  station: activity.data.station.name,
                }}
              >
                <strong style={{ color: '#2185d0 ' }}>{activity.user.name}</strong>
                {' updateInfo '}
                <strong>{device.name}</strong>
                {' at '}
                {activity.data.station.name}
              </Trans>
            );
          case ActivityTypes.DELETE_DEVICE:
            return (
              <Trans
                i18nKey="common.userDeleteDeviceAtStation"
                values={{
                  user: activity.user.name,
                  device: device.name,
                  station: activity.data.station.name,
                }}
              >
                <strong style={{ color: '#2185d0 ' }}>{activity.user.name}</strong>
                {' delete '}
                <strong style={{ color: '#db2828' }}>{device.name}</strong>
                {' at '}
                {activity.data.station.name}
              </Trans>
            );
          case ActivityTypes.MOVE_DEVICE:
            return (
              <Trans
                i18nKey="common.userMovedDeviceFromStationToStation"
                values={{
                  user: activity.user.name,
                  device: device.name,
                  fromStation: activity.data.fromStation.name,
                  toStation: activity.data.toStation.name,
                }}
              >
                {activity.user.name}
                {' moved '}
                <Link to={Paths.DEVICE.replace(':id', device.id)} onClick={onClose}>
                  {device.name}
                </Link>
                {' from '}
                {activity.data.fromStation.name}
                {' to '}
                {activity.data.toStation.name}
              </Trans>
            );
          case ActivityTypes.COMMENT_DEVICE: {
            const commentText = truncate(activity.data.text);

            return (
              <Trans
                i18nKey="common.userLeftNewCommentToDevice"
                values={{
                  user: activity.user.name,
                  comment: commentText,
                  device: device.name,
                }}
              >
                {activity.user.name}
                {` left a new comment «${commentText}» to `}
                <Link to={Paths.DEVICE.replace(':id', device.id)} onClick={onClose}>
                  {device.name}
                </Link>
              </Trans>
            );
          }
          case ActivityTypes.CONTROL_DEVICE: {
            const controlCode = CONTROL_CODES.find((it) => it.value === +activity.data.to.MaLenh);

            return (
              <Trans
                i18nKey="common.userControlModeToSpeaker"
                values={{
                  user: activity.user.name,
                  mode: t(`common.${controlCode.key}`),
                  device: device.name,
                }}
              >
                <strong style={{ color: '#2185d0' }}>{activity.user.name}</strong>
                {' control '}
                <strong>{t(`common.${controlCode.key}`)}</strong>
                {' to '}
                <strong>{device.name}</strong>
              </Trans>
            );
          }
          case ActivityTypes.CONTROL_MULTI_DEVICE: {
            const controlCode = CONTROL_CODES.find((it) => it.value === +activity.data.to.MaLenh);

            const nameDevices = filterLocationToString(
              activity.data.devices,
              level,
              location.id,
              'wardId',
            );

            return (
              <Trans
                i18nKey="common.userControlModeToSpeakers"
                values={{
                  user: activity.user.name,
                  mode: t(`common.${controlCode.key}`),
                  devices: nameDevices,
                }}
              >
                <strong style={{ color: '#2185d0' }}>{activity.user.name}</strong>
                {' control '}
                <strong>{t(`common.${controlCode.key}`)}</strong>
                {' to '}
                <strong>{nameDevices}</strong>
              </Trans>
            );
          }

          /* STATION */

          case ActivityTypes.CREATE_STATION:
            return (
              <Trans
                i18nKey="common.userCreateStationToStation"
                values={{
                  user: activity.user.name,
                  station: station.name,
                  ward: activity.data.ward.name,
                }}
              >
                <strong style={{ color: '#2185d0 ' }}>{activity.user.name}</strong>
                {' create '}
                <strong>{station.name}</strong>
                {' to '}
                {activity.data.ward.name}
              </Trans>
            );
          case ActivityTypes.UPDATE_STATION:
            return (
              <Trans
                i18nKey="common.userUpdateInfoStationAtWard"
                values={{
                  user: activity.user.name,
                  station: station.name,
                  ward: activity.data.ward.name,
                }}
              >
                <strong style={{ color: '#2185d0 ' }}>{activity.user.name}</strong>
                {' updateInfo '}
                <strong>{station.name}</strong>
                {' at '}
                {activity.data.ward.name}
              </Trans>
            );
          case ActivityTypes.DELETE_STATION:
            return (
              <Trans
                i18nKey="common.userDeleteStationAtWard"
                values={{
                  user: activity.user.name,
                  station: station.name,
                  ward: activity.data.ward.name,
                }}
              >
                <strong style={{ color: '#2185d0 ' }}>{activity.user.name}</strong>
                {' delete '}
                <strong style={{ color: '#db2828' }}>{station.name}</strong>
                {' at '}
                {activity.data.ward.name}
              </Trans>
            );

          default:
        }

        return null;
      },
      [level, location.id, onClose, t],
    );

    const renderNotifications = useCallback(() => {
      if (items.length === 0) {
        return <p style={{ textAlign: 'center' }}>{t('common.noUnreadNotifications')}</p>;
      }

      return (
        <div className={styles.wrapper}>
          {items.map((item) => (
            <div key={item.id} className={styles.item}>
              {(item.device || item.station || item.content || item.devices) && item.activity ? (
                <>
                  <User
                    name={item.activity.user.name}
                    avatarUrl={item.activity.user.avatarUrl}
                    size="large"
                  />
                  <span className={styles.itemContent}>
                    {renderItemContent(item)}
                    <div
                      style={{
                        color: item.isRead ? 'inherit' : '#2185d0',
                      }}
                    >
                      {datetimeDistanceToNow(item.activity.createdAt)}
                    </div>
                  </span>
                </>
              ) : (
                <div className={styles.itemDeleted}>{t('common.deviceOrActionAreDeleted')}</div>
              )}
              <Button
                circular
                type="button"
                icon={item.isRead ? 'check circle' : 'circle outline'}
                color="blue"
                className={styles.itemButton}
                disabled={item.isRead}
                onClick={() => handleUpdate(item.id)}
              />
              <Button
                circular
                type="button"
                icon="trash alternate"
                color="grey"
                className={styles.itemOptionButton}
                onClick={() => handleDelete(item.id)}
              />
            </div>
          ))}
          {total > items.length && total > LIMIT && (
            <Button
              color="blue"
              size="tiny"
              onClick={handleSeeMore}
              style={{ width: '96%', marginLeft: '50%', transform: 'translateX(-50%)' }}
            >
              {t('action.seeMore')}
            </Button>
          )}
        </div>
      );
    }, [items, total, t, renderItemContent, handleDelete, handleUpdate, handleSeeMore]);

    const panes = [
      {
        menuItem: t('common.all'),
        render: () => renderNotifications(),
      },
      {
        menuItem: t('common.unread'),
        render: () => renderNotifications(),
      },
      {
        menuItem: t('common.read'),
        render: () => renderNotifications(),
      },
    ];

    return (
      <>
        <Popup.Header>
          {t('common.notifications', {
            context: 'title',
          })}
        </Popup.Header>
        <Popup.Content className={styles.content}>
          <Tab
            activeIndex={selectIndex}
            panes={panes}
            menu={{ color: 'blue', text: true }}
            onTabChange={handleTabChange}
          />
        </Popup.Content>
      </>
    );
  },
);

NotificationsStep.propTypes = {
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  total: PropTypes.number.isRequired,
  level: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  type: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
};

export default NotificationsStep;
