import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './PlayerControls.module.scss';

const PlayerControls = React.memo(
  ({
    isReady,
    isPlaying,
    loading,
    disablePrev,
    disableNext,
    volume,
    durationDisplay,
    elapsedDisplay,
    onVolumeChange,
    onPlayPause,
    onClick,
    onPrev,
    onNext,
  }) => {
    const [t] = useTranslation();

    const inputStyles = {
      accentColor: 'var(--primary-color-)',
    };

    return (
      <div className={styles.wrapper}>
        <div className={styles.actions}>
          <Popup
            content={t('common.prevTenSeconds')}
            size="small"
            trigger={
              <Button
                className={styles.button}
                onClick={onPrev}
                disabled={disablePrev}
                aria-label="Prev"
                size="mini"
                icon="angle double left"
              />
            }
          />
          <Popup
            content={isPlaying ? t('common.pause') : t('common.play')}
            size="small"
            trigger={
              <Button
                className={styles.button}
                disabled={!isReady}
                onClick={onPlayPause}
                aria-label={isPlaying ? 'Pause' : 'Play'}
                size="mini"
                loading={loading}
                icon={isPlaying ? 'pause' : 'play'}
              />
            }
          />

          <Popup
            content={t('common.nextTenSeconds')}
            size="small"
            trigger={
              <Button
                className={styles.button}
                onClick={onNext}
                disabled={disableNext}
                aria-label="Next"
                size="mini"
                icon="angle double right"
              />
            }
          />
          <span className={styles.currentTime}>
            {elapsedDisplay} / {durationDisplay}
          </span>
        </div>
        <div className={styles.volume}>
          <Popup
            content={t('common.volume')}
            size="small"
            trigger={
              <Button
                icon
                content={volume === 0 ? <Icon name="volume off" /> : <Icon name="volume up" />}
                size="small"
                onClick={onClick}
                aria-label={volume === 0 ? 'unmute' : 'mute'}
              />
            }
          />
          <Popup
            content={`${Math.ceil(volume * 100)}%`}
            size="small"
            trigger={
              <input
                aria-label="volume"
                name="volume"
                type="range"
                min={0}
                step={0.05}
                max={1}
                value={volume}
                style={inputStyles}
                onChange={(e) => {
                  onVolumeChange(e.currentTarget.valueAsNumber);
                }}
              />
            }
          />
        </div>
      </div>
    );
  },
);

PlayerControls.propTypes = {
  durationDisplay: PropTypes.string.isRequired,
  elapsedDisplay: PropTypes.string.isRequired,
  volume: PropTypes.number.isRequired,
  isReady: PropTypes.bool.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  disablePrev: PropTypes.bool.isRequired,
  disableNext: PropTypes.bool.isRequired,
  onPlayPause: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onVolumeChange: PropTypes.func.isRequired,
};

export default PlayerControls;
