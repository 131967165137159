import http from './http';
import socket from './socket';

/* Actions */

const createAccessToken = (data, headers) => http.post('/access-tokens', data, headers);
const createAccessTokenByOtpCode = (data, headers) =>
  http.post('/authentication/access-token', data, headers);

const createQRCode = (data, headers) => http.post('/authentication/qrcode', data, headers);

const createOTPCode = (data, headers) => http.post('/authentication/otp', data, headers);

const deleteCurrentAccessToken = (headers) =>
  socket.delete('/access-tokens/me', undefined, headers);

const retrieveOTPCode = (token, headers) =>
  http.post(`/authentication/${token}/retrieve-otp`, {}, headers);

const retrieveQRCode = (data, headers) =>
  http.post(`/authentication/${data.token}/retrieve-qrcode`, data, headers);

export default {
  createAccessToken,
  createAccessTokenByOtpCode,
  createQRCode,
  createOTPCode,
  retrieveOTPCode,
  deleteCurrentAccessToken,
  retrieveQRCode,
};
