import { intervalToDuration, formatDuration } from 'date-fns';

export default (seconds, isGetHour = false) => {
  const duration = intervalToDuration({
    start: 0,
    end: Number.isFinite(seconds) ? seconds * 1000 : 0,
  });

  const formats = ['hours', 'minutes', 'seconds'];

  if (!isGetHour && !duration.hours) {
    formats.splice(0, 1);
  }

  const zeroPad = (num) => String(num).padStart(2, '0');

  return formatDuration(duration, {
    format: formats,
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_, count) => zeroPad(count),
    },
  });
};
