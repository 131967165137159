const rrule = {
  every: 'Hàng',
  day: 'ngày',
  days: 'ngày',
  week: 'tuần',
  weeks: 'tuần',
  month: 'tháng',
  months: 'tháng',
  year: 'năm',
  years: 'năm',
  on: 'vào',
  the: '',
  '1st': 'đầu tiên',
  '2nd': 'thứ 2',
  '3rd': 'thứ 3',
  '4th': 'thứ 4',
  '5th': 'cuối cùng',
  for: ',',
  time: 'lần',
  times: 'lần',
  until: 'đến',
  Monday: 'Thứ hai',
  Tuesday: 'Thứ ba',
  Wednesday: 'Thứ tư',
  Thursday: 'Thứ năm',
  Friday: 'Thứ sáu',
  Saturday: 'Thứ bảy',
  Sunday: 'Chủ nhật',
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};
const rruleTextVi = (r) => {
  let r1 = r.replace(/([A-Z][a-z]+) ([0-9]+), ([0-9]{4})/i, '$2/$1/$3');
  r1 = r1.replace(/(the [0-9][a-z]{2}) (\w+)/, '$2 $1');
  r1 = r1.replace(/(every) ([0-9]+ [a-z]+)/, '$2 1 times');

  return r1.replace(/([a-z]+|[0-9][a-z]+)/gi, (m) => rrule[m]).replace(' ,', ',');
};

export default rruleTextVi;
