import React from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import upperFirst from 'lodash/upperFirst';

import 'leaflet/dist/leaflet.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

import L from 'leaflet';
import markerSpeaker from '../../assets/icons/maps/map-pin.svg';
import markerBroadcast from '../../assets/icons/maps/map-pin-2.svg';
import markerDisplay from '../../assets/icons/maps/map-display.png';
import markerIntegrated from '../../assets/icons/maps/map-integrated.png';
import Config from '../../constants/Config';
import { DeviceStatusOptions } from '../../constants/Enums';

import styles from './Map.module.scss';

const iconStation = new L.Icon({
  iconUrl: markerBroadcast,
  iconRetinaUrl: markerBroadcast,
  popupAnchor: [-0, -0],
  iconSize: [32, 45],
});

const iconIntegrated = new L.Icon({
  iconUrl: markerIntegrated,
  iconRetinaUrl: markerIntegrated,
  popupAnchor: [-0, -0],
  iconSize: [28, 28],
});

const { COORDINATES, VIEW_CENTER } = Config;

const southWest = L.latLng(COORDINATES[0], COORDINATES[1]);

const northEast = L.latLng(COORDINATES[2], COORDINATES[3]);

const bounds = L.latLngBounds(southWest, northEast);

const Map = React.memo(({ sidebarMenu, stations, speakers, displays, integrated }) => {
  const [t] = useTranslation();

  function InvalidateSize() {
    const map = useMap();

    map.setMaxBounds(bounds);

    if (!sidebarMenu) {
      map.invalidateSize();
    }

    return null;
  }

  return (
    <div
      style={{
        width: '100%',
        // height: '100%',
        padding: '16px 0',
        zIndex: 500,
      }}
    >
      <MapContainer
        center={VIEW_CENTER}
        minZoom={9}
        zoom={9}
        zoomSnap={0.25}
        zoomDelta={0.5}
        scrollWheelZoom
        wheelPxPerZoomLevel={120}
        fullscreenControl
        maxBoundsViscosity={1}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {stations.map((item) => (
          <Marker position={[item.lat, item.lng]} icon={iconStation} key={`${item.id}`}>
            <Popup>{`${t('common.stations')} ${item.name}`}</Popup>
          </Marker>
        ))}
        {speakers.map((item) => {
          const status = DeviceStatusOptions.find((it) => it.key === item.status) || {
            color: 'grey',
          };

          const iconSpeaker = new L.Icon({
            iconUrl: markerSpeaker,
            iconRetinaUrl: markerSpeaker,
            popupAnchor: [-0, -0],
            iconSize: [30, 30],
            className: styles[`customIconSpeaker${upperFirst(status.color)}`],
          });

          return (
            <Marker position={[item.lat, item.lng]} icon={iconSpeaker} key={`${item.id}`}>
              <Popup>{`${t('common.speakers')} ${item.name}`}</Popup>
            </Marker>
          );
        })}
        {displays.map((item) => {
          const status = DeviceStatusOptions.find((it) => it.key === item.status) || {
            color: 'grey',
          };

          const iconDisplay = new L.Icon({
            iconUrl: markerDisplay,
            iconRetinaUrl: markerDisplay,
            popupAnchor: [-0, -0],
            iconSize: [28, 28],
            className: styles[`customIconDisplay${upperFirst(status.color)}`],
          });

          return (
            <Marker position={[item.lat, item.lng]} icon={iconDisplay} key={`${item.id}`}>
              <Popup>{`${t('common.display')} ${item.name}`}</Popup>
            </Marker>
          );
        })}
        {integrated.map((item) => (
          <Marker position={[item.lat, item.lng]} icon={iconIntegrated} key={`${item.id}`}>
            <Popup>{`${t('common.integratedDevice')} ${item.name}`}</Popup>
          </Marker>
        ))}
        <InvalidateSize />
      </MapContainer>
    </div>
  );
});

Map.propTypes = {
  sidebarMenu: PropTypes.bool.isRequired,
  /* eslint-disable react/forbid-prop-types */
  stations: PropTypes.arrayOf(PropTypes.any).isRequired,
  speakers: PropTypes.arrayOf(PropTypes.any).isRequired,
  displays: PropTypes.arrayOf(PropTypes.any).isRequired,
  integrated: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Map;
