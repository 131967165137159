import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import { push } from '../lib/redux-router';
import Paths from '../constants/Paths';
import Contents from '../components/Contents';

const mapStateToProps = (state) => {
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);
  const contents = selectors.selectContentsForCurrentProvince(state);
  const { contentTotal } = selectors.selectCurrentProvince(state);
  const sidebarMenu = selectors.selectSidebarMenu(state);
  const province = selectors.selectCurrentProvince(state);
  const { selectedContentId } = state.core;
  const { contentId } = selectors.selectPath(state);

  const { permissions, level, id: userId } = selectors.selectCurrentUser(state);

  const isCurrentUserEditor = permissions.includes('CAN_EDIT');
  const isCurrentUserCanDelete = permissions.includes('CAN_DELETE');

  const categories = selectors.selectCategoriesForCurrentProvince(state);
  const { accessToken } = state.auth;
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const wards = selectors.selectWardsForCurrentProvince(state);

  return {
    selectedContentId,
    slug: province.slug,
    categories,
    contents,
    canEdit: isCurrentUserEditor,
    canEditMemberships: isCurrentUserManager,
    canDelete: isCurrentUserCanDelete,
    contentTotal,
    sidebarMenu,
    level,
    userId,
    accessToken,
    provinces: [province],
    districts,
    wards,
    openContentModal: !!contentId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createContentInCurrentDistrict,
      onFetch: entryActions.fetchContents,
      onUpdate: (contentId, data) => entryActions.updateContent(contentId, data),
      onMove: entryActions.moveContent,
      onDelete: entryActions.deleteContent,
      onCancel: entryActions.cancelScheduleContent,
      push,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onClose: () => {
    return dispatchProps.push(Paths.CONTENTS.replace(':slug', stateProps.slug));
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Contents);
