import ActionTypes from '../constants/ActionTypes';

const createHamlet = (hamlet) => ({
  type: ActionTypes.HAMLET_CREATE,
  payload: {
    hamlet,
  },
});

createHamlet.success = (localId, hamlet, hamletMemberships) => ({
  type: ActionTypes.HAMLET_CREATE__SUCCESS,
  payload: {
    localId,
    hamlet,
    hamletMemberships,
  },
});

createHamlet.failure = (localId, error) => ({
  type: ActionTypes.HAMLET_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleHamletCreate = (hamlet) => ({
  type: ActionTypes.HAMLET_CREATE_HANDLE,
  payload: {
    hamlet,
  },
});

const fetchHamlet = (id) => ({
  type: ActionTypes.HAMLET_FETCH,
  payload: {
    id,
  },
});

fetchHamlet.success = (
  hamlet,
  users,
  provinces,
  hamletMemberships,
  contents,
  contentCategories,
  stations,
  devices,
  deviceMemberships,
  deviceContents,
  tasks,
  attachments,
) => ({
  type: ActionTypes.HAMLET_FETCH__SUCCESS,
  payload: {
    hamlet,
    users,
    provinces,
    hamletMemberships,
    contents,
    contentCategories,
    stations,
    devices,
    deviceMemberships,
    deviceContents,
    tasks,
    attachments,
  },
});

fetchHamlet.failure = (id, error) => ({
  type: ActionTypes.HAMLET_FETCH__FAILURE,
  payload: {
    id,
    error,
  },
});

const updateHamlet = (id, data) => ({
  type: ActionTypes.HAMLET_UPDATE,
  payload: {
    id,
    data,
  },
});

updateHamlet.success = (hamlet) => ({
  type: ActionTypes.HAMLET_UPDATE__SUCCESS,
  payload: {
    hamlet,
  },
});

updateHamlet.failure = (id, error) => ({
  type: ActionTypes.HAMLET_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleHamletUpdate = (hamlet) => ({
  type: ActionTypes.HAMLET_UPDATE_HANDLE,
  payload: {
    hamlet,
  },
});

const deleteHamlet = (id) => ({
  type: ActionTypes.HAMLET_DELETE,
  payload: {
    id,
  },
});

deleteHamlet.success = (hamlet) => ({
  type: ActionTypes.HAMLET_DELETE__SUCCESS,
  payload: {
    hamlet,
  },
});

deleteHamlet.failure = (id, error) => ({
  type: ActionTypes.HAMLET_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleHamletDelete = (hamlet) => ({
  type: ActionTypes.HAMLET_DELETE_HANDLE,
  payload: {
    hamlet,
  },
});

export default {
  createHamlet,
  handleHamletCreate,
  fetchHamlet,
  updateHamlet,
  handleHamletUpdate,
  deleteHamlet,
  handleHamletDelete,
};
