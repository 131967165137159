import socket from './socket';

/* Actions */

const createTask = (deviceId, data, headers) =>
  socket.post(`/devices/${deviceId}/tasks`, data, headers);

const updateTask = (id, data, headers) => socket.patch(`/tasks/${id}`, data, headers);

const deleteTask = (id, headers) => socket.delete(`/tasks/${id}`, undefined, headers);

export default {
  createTask,
  updateTask,
  deleteTask,
};
