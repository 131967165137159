import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Icon, Input, Label, Pagination, Select, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { usePopup } from '../../lib/popup';

import Paths from '../../constants/Paths';
import Config from '../../constants/Config';
import { DeviceStatusOptions } from '../../constants/Enums';
import DeleteDevice from './DeleteDevice';
import DisplayAddModalContainer from '../../containers/DisplayAddModalContainer';
import DeviceModalContainer from '../../containers/DeviceModalContainer';
import PickLocation from '../StationModal/PickLocation';

import styles from './Displays.module.scss';

const LIMIT = Config.LIMIT_PER_PAGE;

const Displays = React.memo(
  ({ items, districts, currentId, onDelete, onFetch, onRestart, province }) => {
    const [t] = useTranslation();
    const [isDeviceModalOpened, setDeviceModalOpened] = useState(false);
    const [name, setName] = useState('');
    const [selectedId, setSelectedId] = useState();
    const [districtIds, setSelectDistrictId] = useState([]);
    const [location, setLocation] = useState({ lat: null, lng: null });
    const [isViewing, setIsViewing] = useState(false);

    const [limit, setLimit] = useState(LIMIT);
    const [page, setPage] = useState(1);

    const DeletePopup = usePopup(DeleteDevice);

    const handleRefetchDevices = useCallback(() => {
      setPage(1);
      onFetch(1, limit, {
        ...(name && { name }),
        ...(districtIds.length > 0 && { districtId: districtIds }),
      });
    }, [onFetch, limit, name, districtIds]);

    const handleClick = useCallback(() => {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }, []);

    const handleDelete = useCallback(
      (id) => {
        onDelete(id);
      },
      [onDelete],
    );

    const handleDeviceModalOpen = useCallback(() => {
      setSelectedId(undefined);
      setDeviceModalOpened(true);
    }, []);

    const handleDeviceModalClose = useCallback(() => {
      setDeviceModalOpened(false);
      setIsViewing(false);
    }, []);

    const handleViewOpen = useCallback((id) => {
      setSelectedId(id);
      setDeviceModalOpened(true);
      setIsViewing((prev) => !prev);
    }, []);

    const handleEditOpen = useCallback((id) => {
      setSelectedId(id);
      setDeviceModalOpened(true);
    }, []);

    const handleSetLocation = useCallback((lat, lng) => {
      setLocation({ lat, lng });
    }, []);

    const handleSelectPage = useCallback(
      (_, { activePage }) => {
        setPage(activePage);
        onFetch(activePage, limit, {
          ...(name && { name }),
          ...(districtIds.length > 0 && { districtId: districtIds }),
        });
      },
      [onFetch, limit, name, districtIds],
    );

    const onChangeSelectDistrict = useCallback((_, { value }) => {
      setSelectDistrictId(value);
    }, []);

    const onChangeName = useCallback((_, { value }) => {
      setName(value);
    }, []);

    const handleFilterRegion = useCallback(() => {
      handleRefetchDevices();
    }, [handleRefetchDevices]);

    const districtOptions = districts.map(({ id, name: text }) => ({ key: id, value: id, text }));

    return (
      <>
        <div className={styles.wrapper}>
          <div className={styles.wrapperSelect}>
            <div className={styles.alignLeft}>
              <Input
                placeholder={t('common.name')}
                icon="filter"
                iconPosition="left"
                value={name}
                onChange={onChangeName}
              />
              <Select
                multiple
                closeOnChange
                placeholder={t('common.selectDistrict')}
                options={districtOptions}
                value={districtIds}
                onChange={onChangeSelectDistrict}
              />
              <Button className={styles.buttonFilter} onClick={handleFilterRegion} size="mini">
                <Icon name="filter" />
                <span>{t('action.filter')}</span>
              </Button>
            </div>
            <Button className={styles.buttonAdd} onClick={handleDeviceModalOpen} size="mini">
              <Icon name="add" />
              <span>{t('action.addDevice')}</span>
            </Button>
          </div>
          <div className={styles.wrapperTable}>
            <Table unstackable singleLine className={styles.table}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('common.name')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.code')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.description')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.stations')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.status')}</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {items.map((it) => {
                  return (
                    <Table.Row key={it.id}>
                      <Table.Cell>{it.name}</Table.Cell>
                      <Table.Cell>
                        {it.code}
                        {it.lat > 0 && it.lng > 0 && (
                          <Button
                            icon="map marker alternate"
                            className={styles.position}
                            size="mini"
                            onClick={() => handleSetLocation(it.lat, it.lng)}
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell>{truncate(it.description, { length: 50 })}</Table.Cell>
                      <Table.Cell>{`${it.station}, ${it.district}`}</Table.Cell>
                      <Table.Cell>
                        {it.status && (
                          <Label
                            color={DeviceStatusOptions.find(({ key }) => key === it.status).color}
                            content={t(`common.${it.status}`)}
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {/* <Link to={Paths.DEVICE.replace(':id', it.id)} onClick={handleClick}> */}
                        <Button
                          icon
                          size="mini"
                          content={t('action.view')}
                          className={styles.action}
                          onClick={() => handleViewOpen(it.id)}
                        />
                        {/* </Link> */}
                        <Button
                          onClick={() => handleEditOpen(it.id)}
                          icon
                          size="mini"
                          content={t('action.edit')}
                          className={styles.action}
                        />
                        <DeletePopup
                          title={t('common.deleteDevice_title')}
                          display={it.name}
                          onDelete={() => handleDelete(it.id)}
                        >
                          <Button
                            icon
                            size="mini"
                            content={t('action.delete')}
                            className={classNames(styles.action, styles.delete)}
                          />
                        </DeletePopup>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
          <div className={styles.pagination}>
            <Pagination
              ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
              firstItem={{ content: <Icon name="angle double left" />, icon: true }}
              lastItem={{ content: <Icon name="angle double right" />, icon: true }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              onPageChange={handleSelectPage}
              activePage={page}
              totalPages={Math.ceil(province.deviceTotal / limit)}
            />
          </div>
          {isDeviceModalOpened && (
            <DisplayAddModalContainer
              onClose={handleDeviceModalClose}
              selectedId={selectedId}
              isViewing={isViewing}
            />
          )}
          {location.lat && location.lng && (
            <PickLocation
              title="common.location"
              showAction={false}
              currentLocation={location}
              onBack={() => setLocation({ lat: null, lng: null })}
              onClick={() => setLocation({ lat: null, lng: null })}
            />
          )}
        </div>
        {currentId && <DeviceModalContainer />}
      </>
    );
  },
);

Displays.propTypes = {
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  districts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  currentId: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
  onRestart: PropTypes.func.isRequired,
  province: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

Displays.defaultProps = {
  currentId: undefined,
};

export default Displays;
