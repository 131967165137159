import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';

export function* handleNotificationCreate(notification) {
  const { deviceId } = yield select(selectors.selectPath);

  if (notification.deviceId === deviceId) {
    try {
      yield call(request, api.updateNotifications, [notification.id], {
        isRead: true,
      });
    } catch {} // eslint-disable-line no-empty
  } else {
    let users;
    let devices;
    let activities;
    let contents;
    let stations;

    try {
      ({
        included: { users, devices, activities, contents, stations },
      } = yield call(request, api.getNotification, notification.id));
    } catch {
      return;
    }

    yield put(
      actions.handleNotificationCreate(
        notification,
        users,
        devices,
        activities,
        contents,
        stations,
      ),
    );
  }
}

export function* updateNotification(id) {
  yield put(actions.updateNotification(id));

  let notifications;
  try {
    ({ items: notifications } = yield call(request, api.updateNotifications, [id], {
      isRead: true,
    }));
  } catch (error) {
    yield put(actions.updateNotification.failure(id, error));
    return;
  }

  yield put(actions.updateNotification.success(notifications[0]));
}

export function* deleteNotification(id) {
  yield put(actions.deleteNotification(id));

  let notification;
  try {
    ({ item: notification } = yield call(request, api.deleteNotification, id));
  } catch (error) {
    yield put(actions.deleteNotification.failure(id, error));
    return;
  }

  yield put(actions.deleteNotification.success(notification));
}

export function* handleNotificationDelete(notification) {
  yield put(actions.handleNotificationDelete(notification));
}

export function* fetchNotifications(type, page, limit) {
  const { notificationType } = yield select(selectors.selectCurrentProvince);

  let users;
  let devices;
  let activities;
  let contents;
  let stations;
  let notifications;
  let total;

  try {
    ({
      items: notifications,
      included: { users, devices, activities, contents, stations, total },
    } = yield call(request, api.getNotifications, { type, page, limit }));
  } catch {
    return;
  }

  yield put(
    actions.fetchNotifications(
      notifications,
      users,
      devices,
      activities,
      contents,
      stations,
      type,
      total,
      type !== notificationType,
    ),
  );
}

export default {
  handleNotificationCreate,
  updateNotification,
  deleteNotification,
  handleNotificationDelete,
  fetchNotifications,
};
