import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { uniq } from 'lodash';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import { DistrictMembershipRoles } from '../constants/Enums';

import Ward from '../components/Ward';

const makeMapStateToProps = () => {
  const selectWardById = selectors.makeSelectWardById();

  return (state, { id, index }) => {
    const { name, isPersisted } = selectWardById(state, id);
    const currentUserMembership = selectors.selectCurrentUserMembershipForCurrentDistrict(state);
    const users = selectors.selectUsersExceptCurrent(state);
    const { isAdmin } = selectors.selectCurrentUser(state);

    const wardMembershipIds = selectors.selectWardMembershipIds(state);
    const districtMembershipIds = selectors.selectDistrictMembershipIds(state);

    const usersExcludedWardMembership = users.filter(
      (user) => !uniq([...wardMembershipIds, ...districtMembershipIds]).includes(user.id),
    );

    const usersInWard = selectors.selectMembershipsForCurrentWard(state, id);

    const isCurrentUserEditor =
      !!currentUserMembership &&
      currentUserMembership.role === DistrictMembershipRoles.EDITOR &&
      isAdmin;

    return {
      id,
      index,
      name,
      users: usersExcludedWardMembership,
      usersInWard,
      isPersisted,
      // canEdit: isCurrentUserEditor,
      canEdit: false,
    };
  };
};

const mapDispatchToProps = (dispatch, { id }) =>
  bindActionCreators(
    {
      onUpdate: (data) => entryActions.updateStation(id, data),
      onMembershipCreate: (data) => entryActions.createMembershipInCurrentWard(data, id),
      onMembershipUpdate: entryActions.updateWardMembership,
      onMembershipDelete: entryActions.deleteWardMembership,
    },
    dispatch,
  );

export default connect(makeMapStateToProps, mapDispatchToProps)(Ward);
