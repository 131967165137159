import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import entryActions from '../entry-actions';
import UserGroupAddModal from '../components/UserGroupAddModal';

import selectors from '../selectors';

const mapStateToProps = (state, { selectedId }) => {
  const {
    userGroupForm: { data: defaultData, isSubmitting, error, userGroupId },
  } = state.ui;

  const { level } = selectors.selectCurrentUser(state);

  let userGroup;
  if (selectedId) {
    userGroup = selectors.selectUserGroupById(state, selectedId);
  }

  return {
    defaultData: { ...defaultData, ...userGroup },
    isSubmitting,
    isEditing: selectedId !== undefined,
    userGroupId,
    error,
    level,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createUserGroup,
      onUpdate: entryActions.updateUserGroup,
      onMessageDismiss: entryActions.clearUserGroupError,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupAddModal);
