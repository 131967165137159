import EntryActionTypes from '../constants/EntryActionTypes';

const createDistrictInCurrentProvince = (data, autoOpen = false) => ({
  type: EntryActionTypes.DISTRICT_IN_CURRENT_PROVINCE_CREATE,
  payload: {
    data,
    autoOpen,
  },
});

const handleDistrictCreate = (district, requestId) => ({
  type: EntryActionTypes.DISTRICT_CREATE_HANDLE,
  payload: {
    district,
    requestId,
  },
});

const fetchDistrict = (id) => ({
  type: EntryActionTypes.DISTRICT_FETCH,
  payload: {
    id,
  },
});

const updateDistrict = (id, data) => ({
  type: EntryActionTypes.DISTRICT_UPDATE,
  payload: {
    id,
    data,
  },
});

const handleDistrictUpdate = (district) => ({
  type: EntryActionTypes.DISTRICT_UPDATE_HANDLE,
  payload: {
    district,
  },
});

const moveDistrict = (id, index) => ({
  type: EntryActionTypes.DISTRICT_MOVE,
  payload: {
    id,
    index,
  },
});

const deleteDistrict = (id) => ({
  type: EntryActionTypes.DISTRICT_DELETE,
  payload: {
    id,
  },
});

const handleDistrictDelete = (district) => ({
  type: EntryActionTypes.DISTRICT_DELETE_HANDLE,
  payload: {
    district,
  },
});

export default {
  createDistrictInCurrentProvince,
  handleDistrictCreate,
  fetchDistrict,
  updateDistrict,
  handleDistrictUpdate,
  moveDistrict,
  deleteDistrict,
  handleDistrictDelete,
};
