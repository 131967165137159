import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { format, getWeekOfMonth } from 'date-fns';
import { truncate } from 'lodash';
import { Button, Form, Icon, Input, Label, Modal, Select, TextArea } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { RRule } from 'rrule';

import { Popup } from '../../../lib/custom-ui';
import { useForm } from '../../../hooks';
import { BYWEEKDAYS, ScheduleStatusOptions } from '../../../constants/Enums';
import getLinkStream from '../../../utils/get-link-stream';
import { pickDayOfWeek } from '../../../utils/custom-repeat';
import rruleTextVi from '../../../utils/rrule';

import styles from './EditStep.module.scss';
import SearchContentContainer from '../../../containers/SearchContentContainer';
import CustomStep from '../../Schedules/CustomStep';

const EditStep = React.memo(
  ({ title, contents, defaultData, isSubmitting, buttonContent, onUpdate, onBack, onClose }) => {
    const [t] = useTranslation();
    const titleField = useRef(null);
    const startRef = useRef(null);
    const endRef = useRef(null);

    const [data, handleFieldChange, setData] = useForm(() => ({
      ...defaultData,
      startDate: defaultData.start,
      description: '',
      // attachment: '',
      color: defaultData.color || '#006C4C',
      // startDate: format(defaultData.start, 'dd/MM/yyyy'),
      // startDate: t('format:date', {
      //   postProcess: 'formatDate',
      //   value: defaultData.startDate || new Date().setHours(12, 0, 0, 0),
      // }),
      // start: t('format:time', {
      //   postProcess: 'formatDate',
      //   value: defaultData.start || new Date().setHours(12, 0, 0, 0),
      // }),
    }));
    const [currentAudioUrl, setCurrentAudioUrl] = useState('');
    const [isShowCustomStep, setIsShowCustomStep] = useState(false);

    const contentCurrent = contents.find(({ id }) => id === data.contentId);
    const attachment =
      contentCurrent?.type === 'file' // eslint-disable-line no-nested-ternary
        ? contentCurrent?.attachments?.[0]
        : contentCurrent?.type === 'stream'
        ? {
            name: getLinkStream(contentCurrent.description),
            url: getLinkStream(contentCurrent.description),
          }
        : undefined;

    const handleDatePickerChange = useCallback(
      (date) => {
        if (!date) return;

        setData((prevData) => {
          const { end } = prevData;
          return {
            ...prevData,
            startDate: date,
            start: date,
            end: new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              end.getHours(),
              end.getMinutes(),
              0,
            ),
            // start: prevData.start.setDate(),
          };
        });
        // startRef.current.setFocus();
        // selectTimeField();
      },
      [setData],
    );

    const handleStartTimeChange = useCallback(
      (date) => {
        setData((prevData) => ({
          ...prevData,
          start: date,
          end: date,
        }));
        // selectTimeField();
        // endRef.current.setFocus();
      },
      [setData],
    );

    const handleEndTimeChange = useCallback(
      (date) => {
        setData((prevData) => ({
          ...prevData,
          end: date,
        }));
        // selectTimeField();
      },
      [setData],
    );

    // const handleFileSelect = useCallback(
    //   (file) => {
    //     setData((prevData) => ({
    //       ...prevData,
    //       attachment: file,
    //     }));
    //   },
    //   [setData],
    // );

    const handleContentSelect = useCallback(
      (id) => {
        setData((prevData) => ({
          ...prevData,
          contentId: id,
        }));
        setCurrentAudioUrl('');
      },
      [setData],
    );

    // const handleClose = useCallback(() => {
    //   onClose();
    // }, [onClose]);

    const handlePlay = useCallback(
      (url) => {
        if (url === currentAudioUrl && currentAudioUrl !== '') {
          setCurrentAudioUrl('');
        } else {
          setCurrentAudioUrl(url);
        }
      },
      [currentAudioUrl],
    );

    const handleSubmit = useCallback(() => {
      const content = contents.find(({ id }) => id === data.contentId);
      // const [sd, sm, sy] = data.startDate.split('/');
      // const st = data.start.split(':');
      // const start = new Date(sy, sm - 1, sd, ...st);
      // const [ed, em, ey] = data.startDate.split('/');
      // const et = data.end.split(':');
      // const end = new Date(ey, em - 1, ed, ...et);

      let repeatRule = null;
      if (data.repeatRule) {
        const rr = RRule.fromString(data.repeatRule);
        rr.options.dtstart = data.start;
        rr.origOptions.dtstart = data.start;
        repeatRule = rr.toString();
      }

      const cleanData = {
        contentId: data.contentId,
        title: data.title.trim() || content?.name,
        start: data.start,
        end: data.end,
        repeatRule,
        repeatUntil: data.repeatUntil || null,
        color: data.color?.trim() || null,
        status: 'active',
      };

      if (!cleanData.title) {
        // nameField.current.select();
        return;
      }

      if (cleanData.start.getTime() >= cleanData.end.getTime()) {
        endRef.current.setFocus();
        return;
      }

      onUpdate(data.id, cleanData);
      onClose();
    }, [
      contents,
      data.contentId,
      data.title,
      data.start,
      data.end,
      data.repeatRule,
      data.repeatUntil,
      data.color,
      data.id,
      onUpdate,
      onClose,
    ]);

    const handleRepeatRule = useCallback(
      (_, { name, value }) => {
        if (value === 'custom') {
          setIsShowCustomStep((prev) => !prev);
        } else {
          setData((prev) => ({ ...prev, [name]: value }));
        }
      },
      [setData],
    );

    const handleRepeatRuleConfirm = useCallback(
      (rruleString, until) => {
        console.log('until: ', until);
        handleFieldChange(_, { name: 'repeatRule', value: rruleString });
        handleFieldChange(_, { name: 'repeatUntil', value: until });
      },
      [handleFieldChange],
    );

    const handleCloseCustomStep = useCallback(() => {
      setIsShowCustomStep((prev) => !prev);
    }, []);

    const repeatRuleOptions = useMemo(() => {
      const WD = pickDayOfWeek(data.startDate, 'eeeee');

      const r1 = new RRule({
        freq: RRule.DAILY,
        interval: 1,
        dtstart: data.startDate,
      });
      const r2 = new RRule({
        freq: RRule.WEEKLY,
        interval: 1,
        dtstart: data.startDate,
        byweekday: [BYWEEKDAYS[WD]],
      });
      const r3 = new RRule({
        freq: RRule.MONTHLY,
        interval: 1,
        dtstart: data.startDate,
        byweekday: BYWEEKDAYS[WD].nth(getWeekOfMonth(data.startDate)),
      });
      const r4 = new RRule({
        freq: RRule.YEARLY,
        interval: 1,
        dtstart: data.startDate,
      });

      const ret = [
        {
          key: 'norepeat',
          value: null,
          text: t(`common.noRepeat`),
        },
        {
          key: 'daily',
          value: r1.toString(),
          text: rruleTextVi(r1.toText()),
        },
        {
          key: 'weekly',
          value: r2.toString(),
          text: rruleTextVi(r2.toText()),
        },
        {
          key: 'monthly',
          value: r3.toString(),
          text: rruleTextVi(r3.toText()),
        },
        {
          key: 'yearly',
          value: r4.toString(),
          text: `${rruleTextVi(r4.toText())} vào ngày ${format(data.startDate, "d 'tháng' M")}`,
        },
      ];

      if (
        data.repeatRule &&
        data.repeatRule.includes('DTSTART') &&
        !ret.find((it) => it.value === data.repeatRule)?.key
      ) {
        const r5 = RRule.fromString(data.repeatRule);
        let text = rruleTextVi(r5.toText());
        if (text === 'Hàng tháng') {
          text += ` vào ngày ${format(data.startDate, 'd')}`;
        }
        ret.push({
          key: r5.toString(),
          value: r5.toString(),
          text,
        });
      }

      ret.push({
        key: 'custom',
        value: 'custom',
        text: t(`common.custom`),
      });

      return ret;
    }, [data.repeatRule, data.startDate, t]);

    // const DeletePopup = usePopup(DeleteStep);

    const handleWindowMouseDown = useCallback(
      (e) => {
        if (e.target.className.includes('modals')) return;
        if (!e.target.closest('.rbc-calendar')) onClose();
        if (e.target.closest('.rbc-toolbar')) onClose();
      },
      [onClose],
    );

    // const handleMeClick = useCallback((e) => {
    //   e.stopPropagation();
    // }, []);

    // useEffect(() => {
    //   setData({
    //     ...defaultData,
    //     contentId: defaultData.contentId || '',
    //     title: defaultData.title,
    //     startDate: defaultData.start,
    //     // startTime: format(defaultData.start, 'HH:mm'),
    //     // endTime: format(defaultData.end, 'HH:mm'),
    //   });
    // }, [defaultData, setData]);

    useEffect(() => {
      if (titleField.current) titleField.current.focus();
    }, []);

    useEffect(() => {
      window.addEventListener('click', handleWindowMouseDown);

      return () => {
        window.removeEventListener('click', handleWindowMouseDown);
      };
    }, [handleWindowMouseDown]);

    return (
      <>
        <Popup.Header onBack={onBack}>
          {t(title, {
            context: 'title',
          })}
        </Popup.Header>
        <Popup.Content>
          <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal" className={styles.field}>
              <Form.Field width={1} className={styles.hiddenMobile}>
                <Icon name="newspaper outline" />
              </Form.Field>
              <Form.Field width={15}>
                <SearchContentContainer
                  onSelect={handleContentSelect}
                  searchValue={contentCurrent && contentCurrent.name}
                />
              </Form.Field>
              {/* <Form.Field
                width={15}
                id="form-input-control-name"
                name="contentId"
                control={Select}
                options={contents.map((it) => ({ key: it.id, value: it.id, text: it.name }))}
                placeholder={t('common.selectContent')}
                required
                value={data.contentId}
                onChange={handleFieldChange}
              /> */}
            </Form.Group>
            <Form.Group widths="equal" className={styles.wrapperField}>
              <Form.Field width={1} className={styles.field}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <Icon name="paint brush" className={styles.labelMobile} />
                <label
                  htmlFor="form-input-color"
                  className={styles.pickColor}
                  style={{ backgroundColor: data.color }}
                >
                  <input
                    id="form-input-color"
                    type="color"
                    value={data.color}
                    onChange={(e) => handleFieldChange(_, { name: 'color', value: e.target.value })}
                  />
                </label>
              </Form.Field>
              <Form.Field width={15} className={styles.field}>
                <Icon name="id card outline" className={styles.labelMobile} />
                <Input
                  ref={titleField}
                  id="form-input-control-name"
                  name="title"
                  placeholder={t('common.name')}
                  value={data.title}
                  onChange={handleFieldChange}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal" className={styles.field}>
              <Form.Field width={1}>
                <Icon name="time" />
              </Form.Field>
              <Form.Group className={styles.fieldTime}>
                <Form.Field width={8}>
                  <span className={styles.labelMobile}>{t('common.date')}</span>
                  <ReactDatePicker
                    dateFormat="d/M/yyyy"
                    disabledKeydistrictNavigation
                    selected={data.startDate}
                    onChange={handleDatePickerChange}
                  />
                </Form.Field>
                <Form.Field name="startTime" width={4}>
                  <span className={styles.labelMobile}>{t('common.timeStart')}</span>
                  <ReactDatePicker
                    ref={startRef}
                    selected={data.start}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    timeCaption={t('common.start')}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        startRef.current.setOpen(false);
                      }
                    }}
                    onChange={handleStartTimeChange}
                  />
                </Form.Field>
                <Form.Field name="endTime" width={4}>
                  <span className={styles.labelMobile}>{t('common.timeEnd')}</span>
                  <ReactDatePicker
                    ref={endRef}
                    selected={data.end}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    timeCaption={t('common.end')}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        endRef.current.setOpen(false);
                      }
                    }}
                    onChange={handleEndTimeChange}
                  />
                </Form.Field>
              </Form.Group>
            </Form.Group>
            <Form.Group widths="equal" className={styles.field}>
              <Form.Field width={1}>
                <Icon name="retweet" />
              </Form.Field>
              <Form.Field width={15}>
                <Select
                  name="repeatRule"
                  options={repeatRuleOptions}
                  // label={{ children: t('common.noRepeat'), htmlFor: 'form-select-control-status' }}
                  value={data.repeatRule}
                  placeholder={t('common.noRepeat')}
                  style={{ minWidth: '60px' }}
                  onChange={handleRepeatRule}
                />
              </Form.Field>
            </Form.Group>
            {attachment && (
              <Form.Group widths="equal" className={styles.field}>
                <Form.Field width={1}>
                  <Icon name="attach" />
                </Form.Field>
                <Form.Field width={15}>
                  <Popup
                    content={attachment.name}
                    size="mini"
                    trigger={
                      <Label
                        as="a"
                        content={truncate(attachment.name, { length: 20 })}
                        onClick={() => handlePlay(attachment.url)}
                        icon={currentAudioUrl === attachment.url ? 'pause' : 'play'}
                      />
                    }
                    wide="very"
                  />
                </Form.Field>
              </Form.Group>
            )}
            <audio
              src={currentAudioUrl}
              autoPlay={!!currentAudioUrl}
              onPause={() => handlePlay('')}
            >
              <track kind="captions" />
            </audio>
            <div className={styles.actionWrapper}>
              <Select
                name="status"
                options={ScheduleStatusOptions.map((it) => ({
                  ...it,
                  text: t(`common.${it.key}`),
                }))}
                label={{ children: t('common.status'), htmlFor: 'form-select-control-status' }}
                value={data.status}
                placeholder={t('common.status')}
                style={{ minWidth: '60px' }}
                onChange={handleFieldChange}
              />
              <Button
                color="green"
                icon="checkmark"
                type="submit"
                content={t(buttonContent)}
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
          </Form>
        </Popup.Content>
        {isShowCustomStep && (
          <CustomStep
            title="common.repeatRuleCustom"
            repeatRule={data.repeatRule}
            startDate={data.startDate}
            days={data.daysOfWeek}
            onConfirm={handleRepeatRuleConfirm}
            onClose={handleCloseCustomStep}
          />
        )}
      </>
    );
  },
);

EditStep.propTypes = {
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  contents: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  isSubmitting: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  buttonContent: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
};

EditStep.defaultProps = {
  onBack: undefined,
  onClose: undefined,
};

export default EditStep;
