import React, { useCallback, useMemo, useRef } from 'react';
import { dequal } from 'dequal';
import { isEmpty, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Form, Image, Input, Modal, TextArea } from 'semantic-ui-react';
import classNames from 'classnames';

import { useForm, useSteps } from '../../hooks';

import editIcon from '../../assets/images/edit.png';
import { ReactComponent as Location } from '../../assets/icons/maps/location.svg';

import styles from './StationModal.module.scss';
import PickLocation from './PickLocation';

const StepTypes = {
  VIEW_MAP: 'VIEW_MAP',
};

const StationModal = React.memo(
  ({
    // name,
    // background,
    // backgroundImage,
    // isBackgroundImageUpdating,
    defaultData,
    districts,
    wards,
    isDarkMode,
    isEditting,
    isSubmitting,
    canEdit,
    canAdd,
    // managers,
    // allUsers,
    onCreate,
    onUpdate,
    // onDelete,
    // onBackgroundImageUpdate,
    // onManagerCreate,
    // onManagerDelete,
    onClose,
  }) => {
    const [t] = useTranslation();

    const [data, handleFieldChange, setData] = useForm(() => ({
      name: '',
      phone: '',
      // identifier: '',
      // address: '',
      description: '',
      districtId: '',
      wardId: '',
      lng: '',
      lat: '',
      ...pickBy(defaultData),
    }));

    const [step, openStep, handleBack] = useSteps();

    const cleanData = useMemo(
      () => ({
        ...data,
        name: data.name.trim(),
        phone: data.phone.trim() || undefined,
        // identifier: data.identifier.trim() || null,
        // address: data.address.trim() || null,
        description: data.description.trim() || undefined,
        districtId: data.districtId.trim(),
        wardId: data.wardId.trim(),
        lng: data.lng,
        lat: data.lat,
      }),
      [data],
    );

    const nameField = useRef(null);
    const districtField = useRef(null);
    const wardField = useRef(null);
    const lngField = useRef(null);
    const latField = useRef(null);

    const handleCloseIcon = useCallback(
      (e) => {
        if (isEditting || e.target.className.includes('close')) {
          onClose();
        }
      },
      [isEditting, onClose],
    );

    const handleSubmit = useCallback(() => {
      Object.keys(cleanData).forEach(
        (key) => cleanData[key] === undefined && delete cleanData[key],
      );

      if (!cleanData.name) {
        nameField.current.select();
        return;
      }

      if (!cleanData.lat) {
        latField.current.select();

        return;
      }

      if (!cleanData.lng) {
        lngField.current.select();
        return;
      }

      if (!cleanData.districtId) {
        districtField.current.open();
        return;
      }
      if (!cleanData.wardId) {
        wardField.current.open();
        return;
      }

      if (isEditting) {
        if (!canEdit) {
          return;
        }
        onUpdate(cleanData.id, cleanData);
        onClose();
      } else {
        if (!canAdd) {
          return;
        }
        onCreate(cleanData);
        onClose();
      }
    }, [isEditting, canAdd, canEdit, onCreate, onUpdate, onClose, cleanData]);

    const handleOpenMap = useCallback(() => {
      openStep(StepTypes.VIEW_MAP);
    }, [openStep]);

    const handleSetLatLng = useCallback(
      (location) => {
        setData((prev) => ({ ...prev, ...location }));
      },
      [setData],
    );

    const handleSelectDistrict = useCallback(
      (_, { name, value }) => {
        setData((prev) => ({ ...prev, [name]: value, wardId: '' }));
      },
      [setData],
    );

    const defaultOption = { key: '1', value: '', text: t('common.selectDistrict') };

    const districtOptions = [
      defaultOption,
      ...districts.map(({ id, name }) => ({ key: id, value: id, text: name })),
    ];

    const wardFilters = wards.filter((w) => w.districtId === data.districtId);

    const wardOptions = [
      ...wardFilters.map(({ id, name }) => ({ key: id, value: id, text: name })),
    ];

    if (step) {
      if (step.type === StepTypes.VIEW_MAP) {
        return (
          <PickLocation
            title="common.selectStationLocation"
            currentLocation={{ lat: data.lat, lng: data.lng }}
            onBack={handleBack}
            onClick={handleSetLatLng}
          />
        );
      }
    }

    return (
      <Modal open closeIcon size="small" centered={false} onClose={handleCloseIcon}>
        <Modal.Header>
          <h4>
            <Image src={editIcon} width={40} />
            {t(
              // eslint-disable-next-line no-nested-ternary
              isEditting
                ? canEdit
                  ? 'common.editStation'
                  : 'common.viewStation'
                : 'common.createStation',
              {
                context: 'title',
              },
            )}
          </h4>
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={handleSubmit}>
            <div className={styles.wrapperField}>
              <span className={styles.text}>
                {t('common.nameStation')}
                <sup>*</sup>
              </span>
              <Input
                ref={nameField}
                name="name"
                value={data.name}
                className={styles.field}
                onChange={handleFieldChange}
              />
            </div>
            <div className={styles.wrapperField}>
              <span className={styles.text}>{t('common.phone')}</span>
              <Input
                name="phone"
                value={data.phone}
                className={styles.field}
                onChange={handleFieldChange}
              />
            </div>
            <div className={styles.wrapperField}>
              <span className={styles.text}>
                {t('common.latitude')} / {t('common.longitude')}
                <sup>*</sup>
              </span>
              <div>
                <div className={styles.position}>
                  <Input
                    type="number"
                    ref={latField}
                    placeholder="10.435257"
                    name="lat"
                    value={data.lat}
                    className={styles.field}
                    onChange={handleFieldChange}
                  />
                  <Input
                    type="number"
                    ref={lngField}
                    name="lng"
                    placeholder="108.566083"
                    value={data.lng}
                    className={styles.field}
                    onChange={handleFieldChange}
                  />
                  <Location
                    onClick={handleOpenMap}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                </div>
                <p style={{ marginTop: '4px', color: '#ccc' }}>{t('common.enterOrClickIcon')}</p>
              </div>
            </div>
            {/* <div className={styles.wrapperField}>
              <span className={styles.text}>
                {t('common.identifier')}
                <sup>*</sup>
              </span>
              <Input
                name="identifier"
                value={data.identifier}
                className={styles.field}
                onChange={handleFieldChange}
              />
            </div>
            <div className={styles.wrapperField}>
              <span className={styles.text}>
                {t('common.address')}
                <sup>*</sup>
              </span>
              <Input
                name="address"
                value={data.address}
                className={styles.field}
                onChange={handleFieldChange}
              />
            </div> */}
            <div className={styles.wrapperField}>
              <span className={styles.text}>{t('common.des')}</span>
              <TextArea
                rows={6}
                name="description"
                value={data.description}
                // placeholder="Mô tả"
                onChange={handleFieldChange}
              />
            </div>
            <div className={styles.wrapperField}>
              <span className={styles.text}>
                {t('common.district')}
                <sup>*</sup>
              </span>
              <Dropdown
                selection
                // search
                ref={districtField}
                placeholder={t('common.selectDistrict')}
                name="districtId"
                options={districtOptions}
                value={data.districtId}
                onChange={handleSelectDistrict}
                noResultsMessage={t('common.notFoundDistricts')}
                className={classNames(styles.dropdown, isEmpty(districts) && styles.errorMessage)}
                error={isEmpty(districts)}
              />
            </div>
            <div className={styles.wrapperField}>
              <span className={styles.text}>
                {t('common.ward')}
                <sup>*</sup>
              </span>
              <Dropdown
                ref={wardField}
                selection
                search
                placeholder={t('common.selectWard')}
                name="wardId"
                options={wardOptions}
                value={data.wardId}
                onChange={handleFieldChange}
                noResultsMessage={t('common.notFoundWards')}
                className={classNames(
                  styles.dropdown,
                  data.districtId && isEmpty(wardFilters) && styles.errorMessage,
                )}
                error={!!data.districtId && isEmpty(wardFilters)}
              />
            </div>
            {canEdit && (
              <div className={styles.actions}>
                <Button type="button" positive className={styles.button} onClick={onClose}>
                  <span>{t('action.cancel')}</span>
                </Button>
                <Button
                  loading={isSubmitting}
                  type="submit"
                  positive
                  disabled={dequal(data.districtId, '')}
                  className={styles.button}
                >
                  <span>{t('action.save')}</span>
                </Button>
              </div>
            )}
          </Form>
        </Modal.Content>
      </Modal>
    );
  },
);

StationModal.propTypes = {
  // name: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  // background: PropTypes.object,
  // backgroundImage: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
  // isBackgroundImageUpdating: PropTypes.bool.isRequired,
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  districts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  wards: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  isDarkMode: PropTypes.bool.isRequired,
  isEditting: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canAdd: PropTypes.bool.isRequired,
  /* eslint-disable react/forbid-prop-types */
  // managers: PropTypes.array.isRequired,
  // allUsers: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  // onBackgroundImageUpdate: PropTypes.func.isRequired,
  // onDelete: PropTypes.func.isRequired,
  // onManagerCreate: PropTypes.func.isRequired,
  // onManagerDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

StationModal.defaultProps = {
  // background: undefined,
  // backgroundImage: undefined,
};

export default StationModal;
