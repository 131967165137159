import { call, put } from 'redux-saga/effects';

import actions from '../../../actions';
import api from '../../../api';
import { setAccessToken } from '../../../utils/access-token-storage';
import { goToScanQRCode, goToVerifyCode } from './router';
import { setItemStorage } from '../../../utils/local-storage';

export function* authenticate(data) {
  yield put(actions.authenticate(data));

  let accessToken;
  try {
    // ({ item: accessToken } = yield call(api.createAccessToken, data));
    ({ item: accessToken } = yield call(api.createAccessTokenByOtpCode, data));
  } catch (error) {
    yield put(actions.authenticate.failure(error));
    return;
  }

  yield call(setAccessToken, accessToken);
  yield put(actions.authenticate.success(accessToken));
}

export function* createQRCode(data) {
  yield put(actions.authenticateQRCode(data));

  let qrcodeUrl;
  let token;
  let isInitial;
  try {
    ({ qrcodeUrl, token, isInitial } = yield call(api.createQRCode, data));
  } catch (error) {
    yield put(actions.authenticateQRCode.failure(error));
    return;
  }

  yield put(actions.authenticateQRCode.success(qrcodeUrl, isInitial));
  yield call(setItemStorage, 'qrcode', qrcodeUrl);
  yield call(goToScanQRCode, token);
}

export function* createOTPCode(data) {
  yield put(actions.authenticateCode(data));

  let expires;
  let token;
  try {
    ({ expires, token } = yield call(api.createOTPCode, data));
  } catch (error) {
    yield put(actions.authenticateCode.failure(error));
    return;
  }

  yield call(setItemStorage, 'isVerify', true);
  yield call(setItemStorage, 'expires', expires);
  yield put(actions.authenticateCode.success(expires));
  yield call(goToVerifyCode, token);
}

export function* clearAuthenticateError() {
  yield put(actions.clearAuthenticateError());
}

export function* retrieveQRCode(token) {
  yield put(actions.retrieveQRCode(token));

  let success;
  let message;
  try {
    ({ success, message } = yield call(api.retrieveQRCode, { token }));
  } catch (error) {
    yield put(actions.retrieveQRCode.failure(error));
    return;
  }

  if (!success) {
    yield put(actions.retrieveQRCode.failure({ message }));
    return;
  }

  yield put(actions.retrieveQRCode.success(success, message));
}

export function* clearAuthenticateCountDown() {
  yield call(setItemStorage, 'expires', '');
  yield put(actions.clearAuthenticateCountDown());
}

export function* retrieveAuthenticateCode(data) {
  yield put(actions.retrieveAuthenticateCode(data));

  let expires;
  try {
    ({ expires } = yield call(api.retrieveOTPCode, data.token));
  } catch (error) {
    yield put(actions.retrieveAuthenticateCode.failure(error));
    return;
  }

  yield call(setItemStorage, 'expires', expires);
  yield put(actions.retrieveAuthenticateCode.success(expires));
}

export default {
  authenticate,
  createQRCode,
  retrieveQRCode,
  createOTPCode,
  clearAuthenticateError,
  clearAuthenticateCountDown,
  retrieveAuthenticateCode,
};
