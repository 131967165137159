import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  path: null,
  isError: false,
  status: '',
  isFinished: false,
  isStop: false,
  contentId: null,
  overrideContentId: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.LIVE_BROADCAST_REQUEST:
      return {
        ...state,
        status: payload.message,
        isError: false,
      };
    case ActionTypes.LIVE_BROADCAST_START:
      return {
        ...state,
        status: payload.message,
        isError: false,
        path: state.path === null ? payload.url : state.path,
      };
    case ActionTypes.LIVE_BROADCAST_ERROR_HANDLE:
      return {
        ...state,
        status: payload.message,
        isError: true,
        path: null,
      };

    case ActionTypes.LIVE_BROADCAST_STOP:
      return {
        ...state,
        status: state.status !== 'anErrorOccured' ? payload.message : state.status,
        isError: false,
        path: null,
        isStop: payload.liveStatus === 'stop',
      };
    case ActionTypes.CONTENT_CREATE__SUCCESS:
      return { ...state, path: '', contentId: payload.content.id };
    case ActionTypes.LIVE_BROADCAST_FINISHED_HANDLE:
      return { ...state, isFinished: true };
    case ActionTypes.LIVE_BROADCAST_MESSAGE_CLOSE:
      return { ...state, isFinished: false };
    case ActionTypes.LIVE_BROADCAST_CLEAR:
      return { ...initialState, isStop: false };
    case ActionTypes.OVERRIDE_CONTENT_BY_MICROPHONE:
      return { ...state, overrideContentId: payload.content.id };

    default:
      return state;
  }
};
