import React, { useState, useCallback, useRef, useEffect, useReducer } from 'react';
import {
  Button,
  Divider,
  Form,
  Icon,
  Input,
  Label,
  Message,
  Modal,
  Popup,
  Progress,
  Select,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { format } from 'date-fns';

import styles from './TextToSpeech.module.scss';

const mimeType = 'audio/mpeg-3';
const TTS_URL = 'http://192.168.1.107:8080/convert-text';

const voiceOptions = [{ key: 1, text: 'Nam - A.An', value: 'an' }];
const speedOptions = [
  { key: 1, text: '2', value: 2 },
  { key: 2, text: '1.75', value: 1.75 },
  { key: 3, text: '1.5', value: 1.5 },
  { key: 4, text: '1.25', value: 1.25 },
  { key: 5, text: 'Bình thường', value: 1 },
  { key: 6, text: '0.75', value: 0.75 },
  { key: 7, text: '0.5', value: 0.5 },
  { key: 8, text: '0.25', value: 0.25 },
];

const TextToSpeech = React.memo(({ text, setMyFile, onClose }) => {
  const [t] = useTranslation();

  const counterRef = useRef();
  const textRef = useRef(null);

  const [audioConvert, setAudioConvert] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [convertError, setConvertError] = useState(false);
  const [counter, setCounter] = useState(0);
  const [filename, setFilename] = useState(`TTS-${format(Date.now(), 'ddMMyyyyHHmmss')}`);
  const [error, setError] = useState({
    isError: false,
    message: '',
  });
  const [convertValue, setConvertValue] = useState({
    data: text,
    voice: voiceOptions[0].value,
    speed: 1,
  });

  const handleStartCounter = useCallback(() => {
    const lengthText = convertValue.data.trim().split(' ').length;

    setError({ isError: false, message: '' });
    counterRef.current = setInterval(() => {
      setCounter((count) => (count < 99 ? count + 1 : count));
    }, lengthText / 50);
  }, [convertValue.data]);

  const handleReset = useCallback((number) => {
    setCounter(number);
    setIsLoading(false);
    clearInterval(counterRef.current);
  }, []);

  const handleCreateAudioFile = useCallback(
    async ({ data, voice, speed }) => {
      setConvertError(false);
      setAudioConvert();

      if (data.trim().split(' ').length < 4) {
        setError({ isError: true, message: `atLeastFourCharacters` });
        textRef.current.focus();
        return;
      }

      if (data.trim().split(' ').length > 5000) {
        setError({ isError: true, message: `canOnlyUnderFiveThousandsCharacters` });
        textRef.current.focus();
        return;
      }

      try {
        setCounter(0);
        setIsLoading(true);
        handleStartCounter();

        const response = await fetch(TTS_URL, {
          method: 'POST',
          body: JSON.stringify({ text: data, speed }),
          headers: {
            'Content-type': 'application/json',
          },
        });

        if (!response.ok) {
          handleReset(0);
          setConvertError(true);
          return;
        }

        const result = await response.json();

        setAudioConvert(result.url);
        handleReset(100);
      } catch (err) {
        handleReset(0);
        setConvertError(true);
      }
    },
    [setIsLoading, handleStartCounter, handleReset],
  );

  const handleOnChange = useCallback(
    (_, { value, name }) => {
      if (convertValue.data.length > 4 && convertValue.data.length < 10000) {
        setError({ isError: false, message: '' });
      }
      setConvertValue({ ...convertValue, [name]: value });
    },
    [setConvertValue, convertValue],
  );

  const handleClear = useCallback(() => {
    setConvertValue((prev) => ({ ...prev, data: '' }));
    setAudioConvert();
    setCounter(0);
    textRef.current.focus();
  }, []);

  const handleSelectFile = useCallback(() => {
    const request = new XMLHttpRequest();

    if (audioConvert) {
      request.open('GET', audioConvert, true);
      request.responseType = 'blob';
      request.onload = function () {
        const reader = new FileReader();
        reader.readAsDataURL(request.response);
        reader.onload = function (e) {
          fetch(e.target.result)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], `${filename}.mp3`, {
                type: mimeType,
              });
              setMyFile(file);
            });
        };
      };
      request.send();
    }
    onClose();
  }, [filename, audioConvert, setMyFile, onClose]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCloseIcon = useCallback(
    (e) => {
      if (e.target.className.includes('close')) onClose();
    },
    [onClose],
  );

  const handleChangeFilename = useCallback((_, { value }) => {
    setFilename(value);
  }, []);

  return (
    <Modal open closeIcon centered={false} className={styles.wrapper} onClose={handleCloseIcon}>
      <Modal.Header as="h2" className={styles.title}>
        <div className={styles.iconHeader}>
          <Icon name="microphone" />
        </div>
        {t('common.convertTextToSpeech_title')}
      </Modal.Header>
      <Modal.Content className={styles.content}>
        <Form>
          {error.isError ? (
            <Label basic color="red" pointing="below">
              {t(`common.${error.message}`)}
            </Label>
          ) : null}

          <textarea
            name="data"
            ref={textRef}
            value={convertValue.data}
            onChange={(e) => handleOnChange(_, e.target)}
            className={styles.textArea}
          />
        </Form>
        <div className={styles.countString}>
          <Label as="a" color="red" content={t('action.delete')} onClick={handleClear} />
          <span>{convertValue.data.trim().split(' ').length} / 5000</span>
        </div>
        {convertError && (
          <Message
            color="red"
            content={t('common.anErrorOccured')}
            onDismiss={() => setConvertError(false)}
          />
        )}
        <Progress
          percent={counter}
          progress
          color="teal"
          className={styles.progress}
          autoSuccess
          indicating
          success={Boolean(counter === 100)}
        />

        {audioConvert && (
          <div className={styles.audio}>
            <Input label="Tên file" value={filename} onChange={handleChangeFilename} />
            <audio controls src={audioConvert}>
              <track kind="captions" />
            </audio>
          </div>
        )}
        <div className={classNames(styles.wrapperOption)}>
          <div className={styles.option}>
            <label htmlFor="voice">{t('common.voice')}</label>
            <Select
              onChange={handleOnChange}
              options={voiceOptions}
              name="voice"
              id="voice"
              value={convertValue.voice}
            />
          </div>
          <div className={styles.option}>
            <label htmlFor="speed">{t('common.speed')}</label>
            <Select
              onChange={handleOnChange}
              options={speedOptions}
              name="speed"
              id="speed"
              value={convertValue.speed}
            />
          </div>
          <Button
            type="button"
            name="voice"
            onClick={() => {
              handleCreateAudioFile({
                data: convertValue.data,
                speed: convertValue.speed,
                voice: convertValue.voice,
              });
            }}
            className={styles.buttonSave}
            disabled={isLoading}
          >
            {t('action.convert')}
          </Button>
        </div>
      </Modal.Content>
      <div className={styles.submitBtn}>
        <Button type="button" className={styles.buttonCancel} onClick={handleClose}>
          {t('action.cancel')}
        </Button>
        <Button type="button" className={styles.buttonSave} onClick={handleSelectFile}>
          {t('action.save')}
        </Button>
      </div>
    </Modal>
  );
});

TextToSpeech.propTypes = {
  setMyFile: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string,
};

TextToSpeech.defaultProps = {
  text: '',
  setMyFile: () => {},
};
export default TextToSpeech;
