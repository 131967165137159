import { createSelector } from 'redux-orm';

import orm from '../orm';

export const makeSelectWardMembershipById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ WardMembership }, id) => {
      const wardMembershipModel = WardMembership.withId(id);

      if (!wardMembershipModel) {
        return wardMembershipModel;
      }

      return wardMembershipModel.ref;
    },
  );

export const selectWardMembershipById = makeSelectWardMembershipById();

export const selectWardMemberships = createSelector(orm, ({ WardMembership }) => {
  return WardMembership.all().toRefArray();
});

export const selectWardMembershipIds = createSelector(orm, ({ WardMembership }) => {
  return WardMembership.all()
    .toRefArray()
    .map((membership) => membership.userId);
});

export default {
  makeSelectWardMembershipById,
  selectWardMembershipById,
  selectWardMemberships,
  selectWardMembershipIds,
};
