import ActionTypes from '../constants/ActionTypes';

const authenticate = (data) => ({
  type: ActionTypes.AUTHENTICATE,
  payload: {
    data,
  },
});

authenticate.success = (accessToken) => ({
  type: ActionTypes.AUTHENTICATE__SUCCESS,
  payload: {
    accessToken,
  },
});

authenticate.failure = (error) => ({
  type: ActionTypes.AUTHENTICATE__FAILURE,
  payload: {
    error,
  },
});

const authenticateCode = (data) => ({
  type: ActionTypes.AUTHENTICATE_CODE,
  payload: {
    data,
  },
});

authenticateCode.success = (expires) => ({
  type: ActionTypes.AUTHENTICATE_CODE__SUCCESS,
  payload: {
    expires,
  },
});

authenticateCode.failure = (error) => ({
  type: ActionTypes.AUTHENTICATE_CODE__FAILURE,
  payload: {
    error,
  },
});

const authenticateQRCode = (data) => ({
  type: ActionTypes.AUTHENTICATE_QRCODE,
  payload: {
    data,
  },
});

authenticateQRCode.success = (qrcode, isInitial) => ({
  type: ActionTypes.AUTHENTICATE_QRCODE__SUCCESS,
  payload: {
    qrcode,
    isInitial,
  },
});

authenticateQRCode.failure = (error) => ({
  type: ActionTypes.AUTHENTICATE_QRCODE__FAILURE,
  payload: {
    error,
  },
});

const retrieveQRCode = (token) => ({
  type: ActionTypes.AUTHENTICATE_QRCODE_RETRIEVE,
  payload: {
    token,
  },
});

retrieveQRCode.success = (success, message) => ({
  type: ActionTypes.AUTHENTICATE_QRCODE_RETRIEVE__SUCCESS,
  payload: {
    success,
    message,
  },
});

retrieveQRCode.failure = (error) => ({
  type: ActionTypes.AUTHENTICATE_QRCODE_RETRIEVE__FAILURE,
  payload: {
    error,
  },
});

const retrieveAuthenticateCode = (token) => ({
  type: ActionTypes.AUTHENTICATE_CODE_RETRIEVE,
  payload: {
    token,
  },
});

retrieveAuthenticateCode.success = (expires) => ({
  type: ActionTypes.AUTHENTICATE_CODE_RETRIEVE__SUCCESS,
  payload: {
    expires,
  },
});

retrieveAuthenticateCode.failure = (error) => ({
  type: ActionTypes.AUTHENTICATE_CODE_RETRIEVE__FAILURE,
  payload: {
    error,
  },
});

const clearAuthenticateError = () => ({
  type: ActionTypes.AUTHENTICATE_ERROR_CLEAR,
  payload: {},
});

const getQRCodeAuthenticate = (qrcode) => ({
  type: ActionTypes.AUTHENTICATE_GET_QRCODE,
  payload: { qrcode },
});

const clearAuthenticateCountDown = () => ({
  type: ActionTypes.AUTHENTICATE_COUNT_DOWN_CLEAR,
  payload: {},
});

const getExpiresCodeAuthenticate = (expires) => ({
  type: ActionTypes.AUTHENTICATE_GET_EXPIRES_CODE,
  payload: { expires },
});

export default {
  authenticate,
  authenticateCode,
  authenticateQRCode,
  getQRCodeAuthenticate,
  retrieveQRCode,
  retrieveAuthenticateCode,
  clearAuthenticateCountDown,
  clearAuthenticateError,
  getExpiresCodeAuthenticate,
};
