const CommuneRadioActivities = (data, wards, contents, devices, stations) => {
  const dataReport = wards.map((ward) => {
    const devicesInWard = devices.filter((d) => d.wardId === ward.id);
    const deviceOn = devicesInWard.filter((d) =>
      ['transmitting', 'transmissionPaused'].includes(d.status),
    ).length;
    const deviceOff = devicesInWard.filter((d) =>
      ['new', 'transmissionOff'].includes(d.status),
    ).length;

    const contentTotal = contents.filter((c) => c.wardId === ward.id).length;
    const stationTotal = stations.filter((s) => s.wardId === ward.id).length;

    const workerTotal =
      ward.NhanLuc.CongChucVanHoaXaHoi +
      ward.NhanLuc.CongChucKhac +
      ward.NhanLuc.NguoiHoatDongKhongChuyenTrach;

    return {
      code: ward.code,
      name: ward.name,
      hamletTotal: ward.hamletTotal,
      fmStation: ward.DaiFM,
      station: stationTotal,
      deviceOn,
      deviceOff,
      contents: contentTotal,
      workerTotal,
      culturalSocialOfficer: ward.NhanLuc.CongChucVanHoaXaHoi,
      otherOfficials: ward.NhanLuc.CongChucKhac,
      amateurActivist: ward.NhanLuc.NguoiHoatDongKhongChuyenTrach,
    };
  });

  const accumulator = dataReport.reduce(
    (acc, item) => {
      Object.keys(item).forEach((key) => {
        if (acc[key] !== undefined) {
          acc[key] += item[key];
        }
      });

      return acc;
    },
    {
      hamletTotal: 0,
      fmStation: 0,
      station: 0,
      deviceOn: 0,
      deviceOff: 0,
      contents: 0,
      workerTotal: 0,
      culturalSocialOfficer: 0,
      otherOfficials: 0,
      amateurActivist: 0,
    },
  );

  let html = `<div class="header">
  <h3>Bảng 1</h3>
  <p>SỐ LIỆU HOẠT ĐỘNG TRUYỀN THANH CẤP XÃ</p>
  <p>Kỳ báo cáo: Quý ${data.quater} năm ${data.year.getFullYear()}.</p>
</div>
<table>
  <thead>
    <tr>
      <th rowspan="2">TT</th>
      <th rowspan="2" style="width: 10%">
        Mã định danh <br/> (Quyết định số 20/2020/QĐ-TTg ngày 22/7/2020 của Thủ tướng Chính phủ)
      </th>
      <th rowspan="2" style="width: 15%">Tên đơn vị hành chính</th>
      <th rowspan="2">Tổng số thôn, tổ dân phố,... của xã, phường, thị trấn</th>
      <th rowspan="2">Đài có dây/không dây FM</th>
      <th rowspan="2">Đài ứng dụng CNTT- VT</th>
      <th colspan="2">Cụm loa ứng dụng CNTT-VT</th>
      <th rowspan="2">Số lượng chương trình phát thanh tự sản xuất (theo kỳ báo cáo)</th>
      <th colspan="4">Nhân lực</th>
    </tr>
    <tr>
      <th>Số lượng cụm loa đang hoạt động</th>
      <th>Số lượng cụm loa hỏng, không hoạt động</th>
      <th>Tổng số người làm việc (10=11+12+13)</th>
      <th>Công chức văn hóa - xã hội kiêm nhiệm</th>
      <th>Công chức khác kiêm nhiệm</th>
      <th>Người hoạt động không chuyên trách</th>
    </tr>
  </thead>
  <tbody>
    <tr style="font-style: italic; text-align: center">
      <td>1</td>
      <td>2</td>
      <td>3</td>
      <td>4</td>
      <td>5</td>
      <td>6</td>
      <td>7</td>
      <td>8</td>
      <td>9</td>
      <td>10</td>
      <td>11</td>
      <td>12</td>
      <td>13</td>
    </tr>`;
  dataReport.forEach((item, index) => {
    html += `<tr key={it} style="text-align: center">
        <td>${index + 1}</td>
        <td style="text-align: left">${item.code}</td>
        <td style="text-align: left">${item.name}</td>
        <td>${item.hamletTotal}</td>
        <td>${item.fmStation}</td>
        <td>${item.station}</td>
        <td>${item.deviceOn}</td>
        <td>${item.deviceOff}</td>
        <td>${item.contents}</td>
        <td>${item.workerTotal}</td>
        <td>${item.culturalSocialOfficer}</td>
        <td>${item.otherOfficials}</td>
        <td>${item.amateurActivist}</td>
        </tr>`;
  });
  html += `<tr>
      <td colspan="3"><strong>Tổng cộng:</strong></td>
      <td style="text-align: center">${accumulator.hamletTotal}</td>
      <td style="text-align: center">${accumulator.fmStation}</td>
      <td style="text-align: center">${accumulator.station}</td>
      <td style="text-align: center">${accumulator.deviceOn}</td>
      <td style="text-align: center">${accumulator.deviceOff}</td>
      <td style="text-align: center">${accumulator.contents}</td>
      <td style="text-align: center">${accumulator.workerTotal}</td>
      <td style="text-align: center">${accumulator.culturalSocialOfficer}</td>
      <td style="text-align: center">${accumulator.otherOfficials}</td>
      <td style="text-align: center">${accumulator.amateurActivist}</td>
    </tr>
  </tbody>
</table>
<div class="footer">
  <p><strong>1. Đơn vị cập nhật số liệu: </strong>Ủy ban nhân dân cấp xã.</p>
  <p>
    <strong>2. Yêu cầu trích xuất số liệu báo cáo theo các biểu: </strong><br />
    - Số xã của tỉnh (thành phố trực thuộc TW)/huyện (thị xã, thành phố thuộc tỉnh); <br />
    - Số phường của tỉnh (thành phố trực thuộc TW)/quận (thị xã, thành phố thuộc tỉnh); <br />
    - Số thị trấn của tỉnh (thành phố trực thuộc TW)/huyện;
  </p>
  <p>
    <strong>3. Thời hạn gửi báo cáo: </strong> <br />
    - Chậm nhất ngày 08 của tháng cuối quý đối với báo cáo định kỳ hằng quý; <br />
    - Chậm nhất ngày 27/11 đối với báo cáo định kỳ hằng năm.
  </p>
  <p>
    <strong>4. Lưu ý: </strong>Cột (5),(6): Có ghi "1"; không có ghi "0" (Trường hợp cấp xã sáp
    nhập có 02 đài truyền thanh trở lên thì cũng chỉ ghi "1", nếu trong đó có đài CNTT-VT ghi
    "1" vào cột (6).
    <br />
    Đài truyền thanh ứng dụng CNTT-VT là đài có 1 hoặc nhiều cụm loa ứng dụng CNTT-VT).
  </p>
</div>`;
  return html;
};

export default CommuneRadioActivities;
