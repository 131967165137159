import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  data: {
    name: '',
    description: '',
    lat: '',
    lng: '',
  },
  isSubmitting: false,
  deviceId: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.DEVICE_CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
        isSubmitting: true,
        deviceId: null,
      };
    case ActionTypes.DEVICE_CREATE__SUCCESS:
      return {
        ...initialState,
        deviceId: payload.device.id,
      };
    case ActionTypes.DEVICE_CREATE__FAILURE:
      return {
        ...state,
        isSubmitting: false,
        deviceId: null,
      };
    // case ActionTypes.DEVICE_ERROR_CLEAR:
    //   return {
    //     ...state,
    //     deviceId: null,
    //     error: null,
    //   };
    default:
      return state;
  }
};
