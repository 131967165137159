import { attr, fk } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

export default class extends BaseModel {
  static modelName = 'DistrictMembership';

  static fields = {
    id: attr(),
    role: attr(),
    canComment: attr(),
    districtId: fk({
      to: 'District',
      as: 'district',
      relatedName: 'memberships',
    }),
    userId: fk({
      to: 'User',
      as: 'user',
      relatedName: 'districtMemberships',
    }),
  };

  static reducer({ type, payload }, DistrictMembership) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE:
        if (payload.districtMemberships) {
          payload.districtMemberships.forEach((districtMembership) => {
            DistrictMembership.upsert(districtMembership);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        DistrictMembership.all().delete();

        payload.districtMemberships.forEach((districtMembership) => {
          DistrictMembership.upsert(districtMembership);
        });

        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROVINCE_CREATE_HANDLE:
      case ActionTypes.DISTRICT_CREATE__SUCCESS:
      case ActionTypes.DISTRICT_FETCH__SUCCESS:
        payload.districtMemberships.forEach((districtMembership) => {
          DistrictMembership.upsert(districtMembership);
        });

        break;
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE:
        DistrictMembership.upsert(payload.districtMembership);

        break;
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE__SUCCESS:
        DistrictMembership.withId(payload.localId).delete();
        DistrictMembership.upsert(payload.districtMembership);

        break;
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE:
        DistrictMembership.upsert(payload.districtMembership);

        if (payload.districtMemberships) {
          payload.districtMemberships.forEach((districtMembership) => {
            DistrictMembership.upsert(districtMembership);
          });
        }

        break;
      case ActionTypes.DISTRICT_MEMBERSHIP_UPDATE:
        DistrictMembership.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.DISTRICT_MEMBERSHIP_UPDATE__SUCCESS:
      case ActionTypes.DISTRICT_MEMBERSHIP_UPDATE_HANDLE:
        DistrictMembership.upsert(payload.districtMembership);

        break;
      case ActionTypes.DISTRICT_MEMBERSHIP_DELETE:
        DistrictMembership.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.DISTRICT_MEMBERSHIP_DELETE__SUCCESS:
      case ActionTypes.DISTRICT_MEMBERSHIP_DELETE_HANDLE: {
        const districtMembershipModel = DistrictMembership.withId(payload.districtMembership.id);

        if (districtMembershipModel) {
          districtMembershipModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.WARD_MEMBERSHIP_CREATE__SUCCESS: {
        DistrictMembership.upsert(payload.districtMembership);

        break;
      }
      case ActionTypes.WARD_MEMBERSHIP_DELETE__SUCCESS: {
        const districtMembershipModel = DistrictMembership.withId(payload.districtMembership.id);

        if (districtMembershipModel) {
          districtMembershipModel.delete();
        }

        break;
      }
      case ActionTypes.USER_CREATE__SUCCESS:
      case ActionTypes.USER_PERMISSION_UPDATE__SUCCESS:
        if (payload.districtMemberships) {
          payload.districtMemberships.forEach((member) => {
            DistrictMembership.upsert(member);
          });
        }

        break;

      default:
    }
  }

  deleteRelated() {
    this.district.devices.toModelArray().forEach((deviceModel) => {
      try {
        deviceModel.users.remove(this.userId);
      } catch {} // eslint-disable-line no-empty
    });

    try {
      this.district.filterUsers.remove(this.userId);
    } catch {} // eslint-disable-line no-empty
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
