import { connect } from 'react-redux';

import selectors from '../selectors';
import ModalTypes from '../constants/ModalTypes';
import Province from '../components/Province';

const mapStateToProps = (state) => {
  const currentModal = selectors.selectCurrentModal(state);

  return {
    isSettingsModalOpened: currentModal === ModalTypes.PROVINCE_SETTINGS,
  };
};

export default connect(mapStateToProps)(Province);
