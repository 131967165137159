import EntryActionTypes from '../constants/EntryActionTypes';

const createCategoryInCurrentProvince = (data) => ({
  type: EntryActionTypes.CATEGORY_IN_CURRENT_PROVINCE_CREATE,
  payload: {
    data,
  },
});

const handleCategoryCreate = (category) => ({
  type: EntryActionTypes.CATEGORY_CREATE_HANDLE,
  payload: {
    category,
  },
});

const updateCategory = (id, data) => ({
  type: EntryActionTypes.CATEGORY_UPDATE,
  payload: {
    id,
    data,
  },
});

const handleCategoryUpdate = (category) => ({
  type: EntryActionTypes.CATEGORY_UPDATE_HANDLE,
  payload: {
    category,
  },
});

const moveCategory = (id, index, parentId) => ({
  type: EntryActionTypes.CATEGORY_MOVE,
  payload: {
    id,
    index,
    parentId,
  },
});

const deleteCategory = (id) => ({
  type: EntryActionTypes.CATEGORY_DELETE,
  payload: {
    id,
  },
});

const handleCategoryDelete = (category) => ({
  type: EntryActionTypes.CATEGORY_DELETE_HANDLE,
  payload: {
    category,
  },
});

export default {
  createCategoryInCurrentProvince,
  handleCategoryCreate,
  updateCategory,
  handleCategoryUpdate,
  moveCategory,
  deleteCategory,
  handleCategoryDelete,
};
