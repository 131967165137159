import { call, put, select } from 'redux-saga/effects';

import { goToDistrict, goToProvince } from './router';
import request from '../request';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createDistrict(provinceId, { import: districtImport, ...data }, autoOpen) {
  const nextData = {
    ...data,
    position: yield select(selectors.selectNextDistrictPosition, provinceId),
  };

  const localId = yield call(createLocalId);

  yield put(
    actions.createDistrict({
      ...nextData,
      provinceId,
      id: localId,
    }),
  );

  let district;
  let districtMemberships;

  try {
    ({
      item: district,
      included: { districtMemberships },
    } = yield districtImport
      ? call(
          request,
          api.createDistrictWithImport,
          provinceId,
          {
            ...nextData,
            importType: districtImport.type,
            importFile: districtImport.file,
          },
          localId,
        )
      : call(request, api.createDistrict, provinceId, nextData));
  } catch (error) {
    yield put(actions.createDistrict.failure(localId, error));
    return;
  }

  yield put(actions.createDistrict.success(localId, district, districtMemberships));
  if (autoOpen) {
    yield call(goToDistrict, district.id);
  }
}

export function* createDistrictInCurrentProvince(data, autoOpen) {
  const { provinceId } = yield select(selectors.selectPath);

  yield call(createDistrict, provinceId, data, autoOpen);
}

export function* handleDistrictCreate(district, requestId) {
  const isExists = yield select(selectors.selectIsDistrictWithIdExists, requestId);

  if (!isExists) {
    yield put(actions.handleDistrictCreate(district));
  }
}

export function* fetchDistrict(id) {
  yield put(actions.fetchDistrict(id));

  let district;
  let users;
  let provinces;
  let districtMemberships;
  let contents;
  let contentCategories;
  let stations;
  let devices;
  let deviceMemberships;
  let deviceContents;
  let tasks;
  let attachments;

  try {
    ({
      item: district,
      included: {
        users,
        provinces,
        districtMemberships,
        contents,
        contentCategories,
        stations,
        devices,
        deviceMemberships,
        deviceContents,
        tasks,
        attachments,
      },
    } = yield call(request, api.getDistrict, id, true));
  } catch (error) {
    yield put(actions.fetchDistrict.failure(id, error));
    return;
  }

  yield put(
    actions.fetchDistrict.success(
      district,
      users,
      provinces,
      districtMemberships,
      contents,
      contentCategories,
      stations,
      devices,
      deviceMemberships,
      deviceContents,
      tasks,
      attachments,
    ),
  );
}

export function* updateDistrict(id, data) {
  yield put(actions.updateDistrict(id, data));

  let district;
  try {
    ({ item: district } = yield call(request, api.updateDistrict, id, data));
  } catch (error) {
    yield put(actions.updateDistrict.failure(id, error));
    return;
  }

  yield put(actions.updateDistrict.success(district));
}

export function* handleDistrictUpdate(district) {
  yield put(actions.handleDistrictUpdate(district));
}

export function* moveDistrict(id, index) {
  const { provinceId } = yield select(selectors.selectDistrictById, id);
  const position = yield select(selectors.selectNextDistrictPosition, provinceId, index, id);

  yield call(updateDistrict, id, {
    position,
  });
}

export function* deleteDistrict(id) {
  const { districtId, provinceId } = yield select(selectors.selectPath);

  if (id === districtId) {
    yield call(goToProvince, provinceId);
  }

  yield put(actions.deleteDistrict(id));

  let district;
  try {
    ({ item: district } = yield call(request, api.deleteDistrict, id));
  } catch (error) {
    yield put(actions.deleteDistrict.failure(id, error));
    return;
  }

  yield put(actions.deleteDistrict.success(district));
}

export function* handleDistrictDelete(district) {
  const { districtId, provinceId } = yield select(selectors.selectPath);

  if (district.id === districtId) {
    yield call(goToProvince, provinceId);
  }

  yield put(actions.handleDistrictDelete(district));
}

export default {
  createDistrict,
  createDistrictInCurrentProvince,
  handleDistrictCreate,
  fetchDistrict,
  updateDistrict,
  handleDistrictUpdate,
  moveDistrict,
  deleteDistrict,
  handleDistrictDelete,
};
