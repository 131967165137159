import { call, put, select, take } from 'redux-saga/effects';

import request from '../request';
import requests from '../requests';
import actions from '../../../actions';
import api from '../../../api';
import i18n from '../../../i18n';
import { removeAccessToken } from '../../../utils/access-token-storage';
import { goToContents } from './router';
import selectors from '../../../selectors';

export function* initializeCore() {
  const {
    user,
    district,
    users,
    province,
    provinces,
    provinceManagers,
    districts,
    districtMemberships,
    wards,
    wardMemberships,
    hamlets,
    categories,
    contents,
    contentCategories,
    stations,
    devices,
    deviceMemberships,
    deviceContents,
    tasks,
    attachments,
    activities,
    notifications,
    contentLocations,
    userGroups,
    permissions,
  } = yield call(requests.fetchCore); // TODO: handle error

  yield call(i18n.changeLanguage, user.language);
  yield call(i18n.loadCoreLocale);

  yield put(
    actions.initializeCore(
      user,
      district,
      users,
      province,
      provinces,
      provinceManagers,
      districts,
      districtMemberships,
      wards,
      wardMemberships,
      hamlets,
      categories,
      contents,
      contentCategories,
      stations,
      devices,
      deviceMemberships,
      deviceContents,
      tasks,
      attachments,
      activities,
      notifications,
      contentLocations,
      userGroups,
      permissions,
    ),
  );
}

export function* changeCoreLanguage(language) {
  if (language === null) {
    yield call(i18n.detectLanguage);
    yield call(i18n.loadCoreLocale);
    yield call(i18n.changeLanguage, i18n.resolvedLanguage);
  } else {
    yield call(i18n.loadCoreLocale, language);
    yield call(i18n.changeLanguage, language);
  }
}

export function* logout(invalidateAccessToken = true) {
  yield call(removeAccessToken);

  if (invalidateAccessToken) {
    yield put(actions.logout.invalidateAccessToken());

    try {
      yield call(request, api.deleteCurrentAccessToken);
    } catch (error) {} // eslint-disable-line no-empty
  }

  yield put(actions.logout());
  yield take();
}

export function* changeSidebarMenu(type) {
  yield put(actions.changeSidebarMenu(type));
}

export function* selectContentId(id) {
  const content = yield select(selectors.selectContentById, id);

  yield put(actions.selectContentId(id, content));

  if (content) {
    yield call(goToContents);
  }
}

export default {
  initializeCore,
  changeCoreLanguage,
  logout,
  changeSidebarMenu,
  selectContentId,
};
