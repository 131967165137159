import http from './http';
import socket from './socket';
import accessTokens from './access-tokens';
import users from './users';
import provinces from './provinces';
import provinceManagers from './province-managers';
import districts from './districts';
import districtMemberships from './district-memberships';
import wards from './wards';
import wardMemberships from './ward-memberships';
import hamlets from './hamlets';
import contents from './contents';
import schedules from './schedules';
import stations from './stations';
import devices from './devices';
import deviceMemberships from './device-memberships';
import deviceContents from './device-contents';
import tasks from './tasks';
import attachments from './attachments';
import activities from './activities';
import commentActivities from './comment-activities';
import notifications from './notifications';
import categories from './categories';
import liveBroadcast from './live-broadcast';
import reports from './reports';
import userGroups from './user-groups';
import actions from './actions';

export { http, socket };

export default {
  ...accessTokens,
  ...categories,
  ...users,
  ...provinces,
  ...provinceManagers,
  ...districts,
  ...districtMemberships,
  ...wards,
  ...wardMemberships,
  ...hamlets,
  ...contents,
  ...schedules,
  ...stations,
  ...devices,
  ...deviceMemberships,
  ...deviceContents,
  ...tasks,
  ...attachments,
  ...activities,
  ...commentActivities,
  ...notifications,
  ...liveBroadcast,
  ...reports,
  ...userGroups,
  ...actions,
};
