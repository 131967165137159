import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar';
import vi from 'date-fns/locale/vi';
import { format, parse, startOfMonth, startOfWeek, getDay } from 'date-fns';
import { Select } from 'semantic-ui-react';

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  startOfMonth,
  getDay,
  locales: {
    vi,
  },
});

function CustomToolbar(toolbar) {
  const { date, localizer: local } = toolbar;

  const label = local.format(date, 'cccc dd, MMMM yyyy');

  return (
    <div className="rbc-toolbar">
      <span className="rbc-toolbar-label">{label}</span>
    </div>
  );
}

export default function ViewCalendar({ contentName, locations, schedule, schedules }) {
  const [selectedDate, setSelectedDate] = useState(schedule.NgayPhat);
  const [locationId, setLocationId] = useState(locations[0]?.id || '');

  const { defaultDate, components } = useMemo(
    () => ({
      defaultDate: new Date(),
      components: {
        toolbar: CustomToolbar,
      },
    }),
    [],
  );

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(isSelected && {
        style: {
          backgroundColor: '#000',
        },
      }),
      style: {
        backgroundColor: event?.color,
        minHeight: 12,
      },
    }),
    [],
  );

  const handleSelectLocation = useCallback((_, { value }) => {
    setLocationId(value);
  }, []);

  const events = useMemo(() => {
    if (!schedule.ThoiDiemPhat) return [];

    const convertToFormat = schedule.ThoiDiemPhat.map((t) => {
      if (!t.wardId) {
        const newSchedules = locations.map((it) => ({
          id: new Date(t.ThoiGianBatDau).getTime(),
          start: new Date(t.ThoiGianBatDau),
          end: new Date(t.ThoiGianKetThuc),
          color: '#2185d0',
          wardId: it.id,
          title: `${contentName} - ${it.name}`,
        }));

        return newSchedules;
      }

      if (!t.id) {
        return {
          id: new Date(t.ThoiGianBatDau).getTime(),
          start: new Date(t.ThoiGianBatDau),
          end: new Date(t.ThoiGianKetThuc),
          color: '#2185d0',
          wardId: t.wardId,
          ...(t.id && { title: `${t.title} - ${t.ward}` }),
        };
      }

      return {
        ...t,
        start: new Date(t.ThoiGianBatDau),
        end: new Date(t.ThoiGianKetThuc),
        color: '#2185d0',
      };
    });

    const flatSchedules = convertToFormat.flat();

    return [
      ...schedules
        .filter((it) => it.wardId === locationId)
        .map((s) => ({
          ...s,
          color: '#f21e1e',
          title: `${s.title} - ${s.ward}`,
        })),
      ...flatSchedules.filter((it) => it.wardId === locationId),
    ];
  }, [contentName, locations, locationId, schedule.ThoiDiemPhat, schedules]);

  const LocationOptions = locations.map((loc) => ({ key: loc.id, value: loc.id, text: loc.name }));

  useEffect(() => {
    setSelectedDate(schedule.NgayPhat);
  }, [schedule.NgayPhat]);

  return (
    <div className="height600">
      <div>Địa bàn</div>
      <Select
        style={{ marginBottom: 5 }}
        value={locationId}
        onChange={handleSelectLocation}
        options={LocationOptions}
        disabled={LocationOptions.length <= 1}
      />
      <Calendar
        date={selectedDate}
        components={components}
        dayLayoutAlgorithm="no-overlap"
        style={{ height: '65vh' }}
        defaultDate={defaultDate}
        defaultView={Views.DAY}
        culture="vi"
        events={events}
        localizer={localizer}
        timeslots={1}
        showMultiDayTimes
        showAllEvents
        step={10}
        views={Views.DAY}
        eventPropGetter={eventPropGetter}
        onNavigate={(date) => {
          setSelectedDate(date);
        }}
      />
    </div>
  );
}

ViewCalendar.propTypes = {
  locations: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  schedules: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  schedule: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  contentName: PropTypes.string.isRequired,
};

ViewCalendar.defaultProps = {
  schedule: {
    NgayPhat: new Date(),
    ThoiDiemPhat: [],
  },
};
