import { attr, fk, many } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';
import Config from '../constants/Config';

export default class extends BaseModel {
  static modelName = 'Content';

  static fields = {
    id: attr(),
    position: attr(),
    name: attr(),
    color: attr(),
    type: attr(),
    file: attr(),
    fileDirname: attr(),
    playCount: attr(),
    status: attr(),
    provinceId: fk({
      to: 'Province',
      as: 'province',
      relatedName: 'contents',
    }),
    districtId: fk({
      to: 'District',
      as: 'district',
      relatedName: 'contents',
    }),
    wardId: fk({
      to: 'Ward',
      as: 'ward',
      relatedName: 'contents',
    }),
    categories: many('Category', 'contents'),
  };

  static reducer({ type, payload }, Content) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE__CONTENTS_FETCH:
        Content.all().delete();

        break;
      case ActionTypes.SCHEDULES_FETCH__SUCCESS:
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE:
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE:
        if (payload.contents) {
          payload.contents.forEach((content) => {
            Content.upsert(content);
            Content.withId(content.id).categories.clear();
          });
        }

        if (payload.contentCategories) {
          payload.contentCategories.forEach(({ contentId, categoryId }) => {
            Content.withId(contentId)?.categories?.add(categoryId);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Content.all().delete();

        if (payload.contents) {
          payload.contents.forEach((content) => {
            Content.upsert(content);
          });
        }

        break;
      case ActionTypes.CONTENTS_FETCH__SUCCESS:
      case ActionTypes.DISTRICT_FETCH__SUCCESS:
        Content.all().delete();

        payload.contents.forEach((content) => {
          Content.upsert(content);
          Content.withId(content.id).categories.clear();
        });

        payload.contentCategories.forEach(({ contentId, categoryId }) => {
          Content.withId(contentId)?.categories?.add(categoryId);
        });

        break;
      // case ActionTypes.SCHEDULES_FETCH__SUCCESS:
      case ActionTypes.NOTIFICATION_FETCH__SUCCESS:
      case ActionTypes.ACTIONS_FETCH__SUCCESS:
        // Content.all().delete();
        if (payload.contents) {
          payload.contents.forEach((content) => {
            Content.upsert(content);
            // Content.withId(content.id).categories.clear();
          });
        }

        break;
      case ActionTypes.CONTENT_CREATE:
      case ActionTypes.CONTENT_CREATE_HANDLE:
      case ActionTypes.CONTENT_UPDATE_HANDLE:
        Content.upsert(payload.content);

        if (payload.included?.contentCategories) {
          payload.included?.contentCategories.forEach(({ contentId, categoryId }) => {
            Content.withId(contentId).categories.clear();
            Content.withId(contentId)?.categories?.add(categoryId);
          });
        }

        break;
      case ActionTypes.CONTENT_UPDATE__SUCCESS:
        Content.upsert(payload.content);

        if (payload.contentCategories) {
          payload.contentCategories.forEach(({ contentId, categoryId }) => {
            Content.withId(contentId).categories.clear();
            Content.withId(contentId)?.categories?.add(categoryId);
          });
        }

        break;
      case ActionTypes.CONTENT_CREATE__SUCCESS: {
        Content.withId(payload.localId).delete();

        const contentTotal = Content.count();

        if (contentTotal >= Config.LIMIT_PER_PAGE) {
          const contentsList = Content.orderBy('createdAt', false).toRefArray();
          const lastObject = contentsList.slice(-1)[0];

          Content.withId(lastObject.id).delete();
          Content.upsert(payload.content);
        } else {
          Content.upsert(payload.content);
        }

        if (payload.contentCategories) {
          payload.contentCategories.forEach(({ contentId, categoryId }) => {
            Content.withId(contentId)?.categories?.add(categoryId);
          });
        }

        break;
      }
      case ActionTypes.CONTENT_UPDATE:
        Content.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.CONTENT_DELETE:
        Content.withId(payload.id).delete();

        break;
      case ActionTypes.CONTENT_DELETE__SUCCESS:
      case ActionTypes.CONTENT_DELETE_HANDLE: {
        const contentModel = Content.withId(payload.content.id);

        if (contentModel) {
          contentModel.delete();
        }

        break;
      }
      case ActionTypes.CONTENT_SELECT:
        Content.upsert(payload.content);

        break;

      // case ActionTypes.CONTENT_CANCEL_SCHEDULE__SUCCESS:
      case ActionTypes.CONTENT_CANCEL_SCHEDULE_HANDLE:
        Content.upsert(payload.content);

        break;
      case ActionTypes.CONTENT_CATEGORY_CREATE_HANDLE:
        if (payload.contentCategory) {
          Content.withId(payload.contentCategory.contentId).categories.clear();
          Content.withId(payload.contentCategory.contentId).categories.add(
            payload.contentCategory.categoryId,
          );
        }

        break;
      case ActionTypes.CONTENT_CATEGORY_UPDATE_HANDLE:
        if (payload.contentCategory) {
          Content.withId(payload.contentCategory.contentId).categories.clear();

          Content.withId(payload.contentCategory.contentId).categories.add(
            payload.contentCategory.categoryId,
          );
        }

        break;
      case ActionTypes.CONTENT_CATEGORY_DELETE_HANDLE:
        if (payload.contentCategory) {
          Content.withId(payload.contentCategory.contentId)?.categories?.clear();
        }

        break;
      case ActionTypes.ATTACHMENT_CREATE__SUCCESS:
      case ActionTypes.SELECT_CONTENT_ID:
        if (payload.content) {
          Content.upsert(payload.content);
        }

        break;
      default:
    }
  }

  getOrderedAttachmentsQuerySet() {
    return this.attachments.orderBy('id', false);
  }

  getOrderedCategoriesQuerySet() {
    return this.categories.orderBy('id', false);
  }

  getOrderedSchedulesQuerySet() {
    return this.schedules.orderBy('start', false);
  }

  getOrderedWardsQuerySet() {
    return this.locations.orderBy('id', false);
  }
}
