import { createSelector } from 'redux-orm';

import orm from '../orm';
import { isLocalId } from '../utils/local-id';

export const makeSelectContentById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Content }, id) => {
      const contentModel = Content.withId(id);

      if (!contentModel) {
        return contentModel;
      }

      return contentModel.ref;
    },
  );

export const selectContentById = makeSelectContentById();

export const makeSelectAttachmentsByContentId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Content }, id) => {
      const contentModel = Content.withId(id);

      if (!contentModel) {
        return contentModel;
      }

      return contentModel
        .getOrderedAttachmentsQuerySet()
        .toRefArray()
        .map((attachment) => ({
          ...attachment,
          isPersisted: !isLocalId(attachment.id),
        }));
    },
    // Attachment.all()
    //   .toRefArray()
    //   .filter(({ contentId }) => contentId === id),
  );

export const selectAttachmentsByContentId = makeSelectAttachmentsByContentId();

export const makeSelectCategoriesByContentId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Content }, id) => {
      const contentModel = Content.withId(id);

      if (!contentModel) {
        return contentModel;
      }

      return contentModel
        .getOrderedCategoriesQuerySet()
        .toRefArray()
        .map((category) => ({
          ...category,
          isPersisted: !isLocalId(category.id),
        }));
    },
  );

export const selectCategoriesByContentId = makeSelectCategoriesByContentId();

export const selectSchedulesByContentId = createSelector(
  orm,
  (_, id) => id,
  ({ Content }, id) => {
    if (!id) {
      return id;
    }

    const contentModel = Content.withId(id);

    if (!contentModel) {
      return contentModel;
    }

    return contentModel
      .getOrderedSchedulesQuerySet()
      .toModelArray()
      .map((scheduleModel) => {
        return {
          ...scheduleModel.ref,
          ward: scheduleModel.ward?.name,
          // attachments: scheduleModel.attachments.toRefArray(),
          categories: [], // scheduleModel.categories.toRefArray(),
          isPersisted: !isLocalId(scheduleModel.id),
        };
      });
  },
);

export const makeSelectWardsByContentId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Content }, id) => {
      const contentModel = Content.withId(id);

      if (!contentModel) {
        return contentModel;
      }

      const wardsArray = contentModel.getOrderedWardsQuerySet().toRefArray();
      const DanhSachDiaBanNhan = {};

      wardsArray.forEach((w) => {
        if (DanhSachDiaBanNhan[w.districtId]) {
          DanhSachDiaBanNhan[w.districtId] = [...DanhSachDiaBanNhan[w.districtId], w.id];
        } else {
          DanhSachDiaBanNhan[w.districtId] = [w.id];
        }
      });

      return DanhSachDiaBanNhan;
    },
  );

export const selectWardsByContentId = makeSelectWardsByContentId();

export const makeSelectLocationsByContentId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Content }, id) => {
      const contentModel = Content.withId(id);

      if (!contentModel) {
        return contentModel;
      }

      const received = {
        districts: [],
        wards: [],
        hamlets: [],
        devices: [],
      };

      return contentModel.contentLocations.toModelArray().reduce((result, locationModel) => {
        const { ward, device } = locationModel;

        const findExistedDistrict = result.districts.find((d) => d.id === ward.districtId);
        const findExistedWard = result.wards.find((w) => w.id === ward.ref.id);
        const findExistedHamlet = device
          ? result.hamlets.findIndex((h) => h?.id === device?.hamletId)
          : undefined;
        const findExistedDevice = device
          ? result.devices.findIndex((d) => d?.id === device?.ref?.id)
          : undefined;

        return {
          ...result,
          districts: findExistedDistrict
            ? result.districts
            : [...result.districts, ward.district.ref],
          wards: findExistedWard ? result.wards : [...result.wards, ward.ref],
          hamlets:
            findExistedHamlet !== -1 || !device?.hamlet?.ref
              ? result.hamlets
              : [...result.hamlets, device?.hamlet?.ref],
          devices: findExistedDevice !== -1 ? result.devices : [...result.devices, device?.ref],
        };
      }, received);
    },
  );

export const selectLocationsByContentId = makeSelectLocationsByContentId();

export const makeSelectStationsBroadcastedByContentId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Content, User }, id) => {
      const contentModel = Content.withId(id);

      if (!contentModel) {
        return contentModel;
      }

      return contentModel.scheduleLogs.toModelArray().map((model) => {
        const { schedule, content, station, start, device } = model;

        const userModel = User.withId(schedule.creatorUserId);

        return {
          ...schedule.ref,
          id: model.ref.id,
          duration: content.duration,
          user: userModel?.ref,
          station: station.ref,
          start,
          device: device?.ref,
        };
      });
    },
  );

export const selectStationsBroadcastedByContentId = makeSelectStationsBroadcastedByContentId();

export default {
  makeSelectContentById,
  selectContentById,
  selectAttachmentsByContentId,
  selectCategoriesByContentId,
  selectSchedulesByContentId,
  selectWardsByContentId,
  selectLocationsByContentId,
  selectStationsBroadcastedByContentId,
};
