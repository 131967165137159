import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  data: {
    name: '',
    permissions: [],
  },
  isSubmitting: false,
  error: null,
  userGroupId: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.USER_GROUP_CREATE:
    case ActionTypes.USER_GROUP_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
        isSubmitting: true,
      };
    case ActionTypes.USER_GROUP_CREATE__SUCCESS:
    case ActionTypes.USER_GROUP_UPDATE__SUCCESS:
      return { ...initialState, userGroupId: payload.userGroup.id };
    case ActionTypes.USER_GROUP_CREATE__FAILURE:
    case ActionTypes.USER_GROUP_UPDATE__FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: payload.error,
      };
    case ActionTypes.USER_GROUP_ERROR_CLEAR:
      return {
        ...state,
        userGroupId: null,
        error: null,
      };
    case ActionTypes.USER_GROUP_DELETE__FAILURE:
      return {
        ...state,
        error: payload.error,
      };
    default:
      return state;
  }
};
