import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import SchedulesLogs from '../components/SchedulesLogs';

const mapStateToProps = (state) => {
  // const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);
  const schedulesLogs = selectors.selectSchedulesLogsForCurrentProvince(state);
  const { scheduleLogTotal } = selectors.selectCurrentProvince(state);

  // const currentUserMembership = selectors.selectCurrentUserMembershipForCurrentDistrict(state);

  // const isCurrentUserEditor =
  //   !!currentUserMembership && currentUserMembership.role === DistrictMembershipRoles.EDITOR;

  return {
    items: schedulesLogs,
    scheduleLogTotal,
    // canEdit: isCurrentUserEditor,
    // canEditMemberships: isCurrentUserManager,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // onCreate: entryActions.createScheduleInCurrentProvince,
      // onUpdate: (scheduleId, data) => entryActions.updateSchedule(scheduleId, data),
      onFetch: entryActions.fetchSchedulesLogs,
      onDelete: entryActions.deleteScheduleLog,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesLogs);
