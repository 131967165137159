import { createSelector } from 'redux-orm';

import orm from '../orm';

export const selectUserGroupById = createSelector(
  orm,
  (_, id) => id,
  ({ UserGroup }, id) => {
    if (!id) {
      return id;
    }

    const userGroupModel = UserGroup.withId(id);

    if (!userGroupModel) {
      return userGroupModel;
    }

    return userGroupModel.ref;
  },
);

export default {
  selectUserGroupById,
};
