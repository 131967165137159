import { createSelector } from 'redux-orm';
import isUndefined from 'lodash/isUndefined';

import orm from '../orm';
import Config from '../constants/Config';

export const selectAccessToken = ({ auth: { accessToken } }) => accessToken;

export const selectIsCoreInitializing = ({ core: { isInitializing } }) => isInitializing;

export const selectIsLogouting = ({ core: { isLogouting } }) => isLogouting;

export const selectSidebarMenu = ({ core: { sidebarMenu } }) => sidebarMenu;

export const selectIsDarkMode = ({ core: { isDarkMode } }) => isDarkMode;

const nextPosition = (items, index, excludedId) => {
  const filteredItems = isUndefined(excludedId)
    ? items
    : items.filter((item) => item.id !== excludedId);

  if (isUndefined(index)) {
    const lastItem = filteredItems[filteredItems.length - 1];

    return (lastItem ? lastItem.position : 0) + Config.POSITION_GAP;
  }
  const prevItem = filteredItems[index - 1];
  const nextItem = filteredItems[index];

  const prevPosition = prevItem ? prevItem.position : 0;

  if (!nextItem) {
    return prevPosition + Config.POSITION_GAP;
  }

  return prevPosition + (nextItem.position - prevPosition) / 2;
};

export const selectNextDistrictPosition = createSelector(
  orm,
  (_, provinceId) => provinceId,
  (_, __, index) => index,
  (_, __, ___, excludedId) => excludedId,
  ({ Province }, provinceId, index, excludedId) => {
    const provinceModel = Province.withId(provinceId);
    console.log(excludedId);
    if (!provinceModel) {
      return provinceModel;
    }

    return nextPosition(
      provinceModel.getOrderedDistrictsQuerySet().toRefArray(),
      index,
      excludedId,
    );
  },
);

export const selectNextContentPosition = createSelector(
  orm,
  (_, districtId) => districtId,
  (_, __, index) => index,
  (_, __, ___, excludedId) => excludedId,
  ({ District }, districtId, index, excludedId) => {
    const districtModel = District.withId(districtId);

    if (!districtModel) {
      return districtModel;
    }

    return nextPosition(districtModel.getOrderedContentsQuerySet().toRefArray(), index, excludedId);
  },
);

export const selectNextStationPosition = createSelector(
  orm,
  (_, districtId) => districtId,
  (_, __, index) => index,
  (_, __, ___, excludedId) => excludedId,
  ({ District }, districtId, index, excludedId) => {
    const districtModel = District.withId(districtId);

    if (!districtModel) {
      return districtModel;
    }

    return nextPosition(districtModel.getOrderedStationsQuerySet().toRefArray(), index, excludedId);
  },
);

export const selectNextDevicePosition = createSelector(
  orm,
  (_, stationId) => stationId,
  (_, __, index) => index,
  (_, __, ___, excludedId) => excludedId,
  ({ Station }, stationId, index, excludedId) => {
    const stationModel = Station.withId(stationId);

    if (!stationModel) {
      return stationModel;
    }

    return nextPosition(stationModel.getFilteredOrderedDevicesModelArray(), index, excludedId);
  },
);

export const selectNextTaskPosition = createSelector(
  orm,
  (_, deviceId) => deviceId,
  (_, __, index) => index,
  (_, __, ___, excludedId) => excludedId,
  ({ Device }, deviceId, index, excludedId) => {
    const deviceModel = Device.withId(deviceId);

    if (!deviceModel) {
      return deviceModel;
    }

    return nextPosition(deviceModel.getOrderedTasksQuerySet().toRefArray(), index, excludedId);
  },
);
export const selectNextCategoryPosition = createSelector(
  orm,
  (_, categoryId) => categoryId,
  (_, __, index) => index,
  (_, __, ___, excludedId) => excludedId,
  (_, __, ___, ____, parentId) => parentId,
  ({ Category }, categoryId, index, excludedId, parentId) => {
    const categoryModel = Category.withId(categoryId);
    const categoryArray = Category.all().orderBy('position').toRefArray();
    let array = categoryArray;

    if (!categoryModel) {
      return categoryModel;
    }

    if (!categoryModel.ref.parentId) {
      array = categoryArray.filter((item) => item.parentId === null);
    } else {
      array = categoryArray.filter((item) => item.parentId === parentId);
    }

    return nextPosition(array, index, excludedId);
  },
);

export default {
  selectAccessToken,
  selectIsCoreInitializing,
  selectIsLogouting,
  selectSidebarMenu,
  selectIsDarkMode,
  selectNextDistrictPosition,
  selectNextContentPosition,
  selectNextStationPosition,
  selectNextDevicePosition,
  selectNextTaskPosition,
  selectNextCategoryPosition,
};
