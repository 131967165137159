import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import durationToTime from '../../../utils/duration-to-time';

import styles from './Player.module.scss';

import AudioProgressBar from './AudioProgressBar';
import PlayerControls from './PlayerControls';

function formatDurationDisplay(duration) {
  const min = Math.floor(duration / 60);
  const sec = Math.floor(duration - min * 60);
  const formatted = [min, sec].map((n) => (n < 10 ? `0${n}` : n)).join(':'); // format - mm:ss
  return formatted;
}

const Player = React.memo(
  ({ isPlaying, currentAudio, audioIndex, sidebarMenu, setIsPlaying, onEnded }) => {
    const audioRef = React.useRef(null);
    const [duration, setDuration] = React.useState(0);
    const [isReady, setIsReady] = React.useState(false);
    const [currrentProgress, setCurrrentProgress] = React.useState(0);
    const [buffered, setBuffered] = React.useState(0);
    const [volume, setVolume] = React.useState(0.5);

    const togglePlayPause = React.useCallback(() => {
      if (isPlaying) {
        setIsPlaying(false);
      } else {
        setIsPlaying(true);
      }
    }, [isPlaying, setIsPlaying]);

    const handleNext = React.useCallback(() => {
      if (!audioRef.current) return;

      audioRef.current.currentTime += 10;
      setCurrrentProgress((prev) => prev + 10);
    }, [audioRef]);

    const handlePrev = React.useCallback(() => {
      if (!audioRef.current) return;

      audioRef.current.currentTime -= 10;
      setCurrrentProgress((prev) => prev - 10);
    }, [audioRef]);

    const handleBufferProgress = React.useCallback((e) => {
      const audio = e.currentTarget;
      const dur = audio.duration;

      if (dur > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < audio.buffered.length; i++) {
          if (audio.buffered.start(audio.buffered.length - 1 - i) < audio.currentTime) {
            const bufferedLength = audio.buffered.end(audio.buffered.length - 1 - i);
            setBuffered(bufferedLength);
            break;
          }
        }
      }
    }, []);

    const handleVolumeChange = React.useCallback(
      (volumeValue) => {
        if (!audioRef.current) return;

        audioRef.current.volume = volumeValue;
        setVolume(volumeValue);
      },
      [audioRef],
    );

    const handleMuteUnmute = React.useCallback(() => {
      if (!audioRef.current) return;

      if (audioRef.current.volume !== 0) {
        audioRef.current.volume = 0;
      } else {
        audioRef.current.volume = 1;
      }
    }, [audioRef]);

    const handleOnChangeProgress = React.useCallback(
      (e) => {
        if (!audioRef.current) return;
        audioRef.current.currentTime = e.currentTarget.valueAsNumber;

        setCurrrentProgress(e.currentTarget.valueAsNumber);
      },
      [audioRef],
    );

    const durationDisplay = durationToTime(duration);
    const elapsedDisplay = durationToTime(currrentProgress);

    // useEffect(() => {
    //   audioRef.current?.pause();
    //   const timeout = setTimeout(() => {
    //     audioRef.current?.play();
    //   }, 500);
    //   return () => {
    //     clearTimeout(timeout);
    //   };
    // }, [audioIndex, audioRef]);

    useEffect(() => {
      if (isPlaying) {
        const playPromise = audioRef.current?.play();

        if (playPromise !== undefined) {
          playPromise.catch((_) => {
            setIsReady(true);
          });
        }
      } else {
        audioRef.current?.pause();
      }
    }, [isPlaying, isReady]);

    return (
      <Segment className={styles.wrapper}>
        {currentAudio && (
          <>
            {/* eslint-disable-next-line  jsx-a11y/media-has-caption */}
            <audio
              ref={audioRef}
              preload="metadata"
              autoPlay={false}
              onCanPlay={(e) => {
                e.currentTarget.volume = volume;
                setIsReady(true);
              }}
              onDurationChange={(e) => setDuration(e.currentTarget.duration)}
              // onPlaying={() => setIsPlaying(true)}
              // onPause={() => setIsPlaying(false)}
              onTimeUpdate={(e) => {
                setCurrrentProgress(e.currentTarget.currentTime);
                handleBufferProgress(e);
              }}
              onProgress={handleBufferProgress}
              onVolumeChange={(e) => setVolume(e.currentTarget.volume)}
              onEnded={onEnded}
            >
              <source type="audio/mpeg" src={currentAudio.url} />
            </audio>
          </>
        )}
        <div className={styles.title}>
          {currentAudio?.name?.replace(/.mp3$/, '') || 'Chọn file âm thanh'}
        </div>
        <AudioProgressBar
          duration={duration}
          currentProgress={currrentProgress}
          buffered={buffered}
          onChange={handleOnChangeProgress}
          sidebarMenu={sidebarMenu}
        />

        <PlayerControls
          durationDisplay={durationDisplay}
          elapsedDisplay={elapsedDisplay}
          loading={Boolean(!isReady && currentAudio)}
          disablePrev={currrentProgress === 0}
          disableNext={currrentProgress === duration}
          isReady={isReady}
          isPlaying={isPlaying}
          volume={volume}
          onVolumeChange={handleVolumeChange}
          onClick={handleMuteUnmute}
          onNext={handleNext}
          onPrev={handlePrev}
          onPlayPause={togglePlayPause}
        />
      </Segment>
    );
  },
);

Player.propTypes = {
  currentAudio: PropTypes.object, // eslint-disable-line  react/forbid-prop-types
  audioIndex: PropTypes.number.isRequired,
  sidebarMenu: PropTypes.bool.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  setIsPlaying: PropTypes.func.isRequired,
  onEnded: PropTypes.func.isRequired,
};

Player.defaultProps = {
  currentAudio: undefined,
};

export default Player;
