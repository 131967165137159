import EntryActionTypes from '../constants/EntryActionTypes';

const fetchActions = (filter, limit, page) => ({
  type: EntryActionTypes.ACTIONS_FETCH,
  payload: { filter, limit, page },
});

export default {
  fetchActions,
};
