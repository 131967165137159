import { createSelector } from 'redux-orm';

import orm from '../orm';
import { selectPath } from './router';
import { selectCurrentUserId } from './users';
import { isLocalId } from '../utils/local-id';

export const makeSelectDeviceById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Device }, id) => {
      const deviceModel = Device.withId(id);

      if (!deviceModel) {
        return deviceModel;
      }

      return {
        ...deviceModel.ref,
        coverUrl: deviceModel.coverAttachment && deviceModel.coverAttachment.coverUrl,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const selectDeviceById = makeSelectDeviceById();

export const makeSelectUsersByDeviceId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Device }, id) => {
      const deviceModel = Device.withId(id);

      if (!deviceModel) {
        return deviceModel;
      }

      return deviceModel.users.toRefArray();
    },
  );

export const selectUsersByDeviceId = makeSelectUsersByDeviceId();

export const makeSelectContentsByDeviceId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Device }, id) => {
      const deviceModel = Device.withId(id);

      if (!deviceModel) {
        return deviceModel;
      }

      return deviceModel.contents.toRefArray();
    },
  );

export const selectContentsByDeviceId = makeSelectContentsByDeviceId();

export const makeSelectTasksByDeviceId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Device }, id) => {
      const deviceModel = Device.withId(id);

      if (!deviceModel) {
        return deviceModel;
      }

      return deviceModel.getOrderedTasksQuerySet().toRefArray();
    },
  );

export const selectTasksByDeviceId = makeSelectTasksByDeviceId();

export const makeSelectLastActivityIdByDeviceId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Device }, id) => {
      const deviceModel = Device.withId(id);

      if (!deviceModel) {
        return deviceModel;
      }

      const lastActivityModel = deviceModel.getFilteredOrderedInDeviceActivitiesQuerySet().last();

      return lastActivityModel && lastActivityModel.id;
    },
  );

export const selectLastActivityIdByDeviceId = makeSelectLastActivityIdByDeviceId();

export const makeSelectNotificationsByDeviceId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Device }, id) => {
      const deviceModel = Device.withId(id);

      if (!deviceModel) {
        return deviceModel;
      }

      return deviceModel.getUnreadNotificationsQuerySet().toRefArray();
    },
  );

export const selectNotificationsByDeviceId = makeSelectNotificationsByDeviceId();

export const makeSelectNotificationsTotalByDeviceId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Device }, id) => {
      const deviceModel = Device.withId(id);

      if (!deviceModel) {
        return deviceModel;
      }

      return deviceModel.getUnreadNotificationsQuerySet().count();
    },
  );

export const selectNotificationsTotalByDeviceId = makeSelectNotificationsTotalByDeviceId();

export const selectCurrentDevice = createSelector(
  orm,
  (state) => selectPath(state).deviceId,
  ({ Device }, id) => {
    if (!id) {
      return id;
    }

    const deviceModel = Device.withId(id);

    if (!deviceModel) {
      return deviceModel;
    }

    return deviceModel.ref;
  },
);

export const selectUsersForCurrentDevice = createSelector(
  orm,
  (state) => selectPath(state).deviceId,
  ({ Device }, id) => {
    if (!id) {
      return id;
    }

    const deviceModel = Device.withId(id);

    if (!deviceModel) {
      return deviceModel;
    }

    return deviceModel.users.toRefArray();
  },
);

export const selectContentsForCurrentDevice = createSelector(
  orm,
  (state) => selectPath(state).deviceId,
  ({ Device }, id) => {
    if (!id) {
      return id;
    }

    const deviceModel = Device.withId(id);

    if (!deviceModel) {
      return deviceModel;
    }

    return deviceModel.contents.toRefArray();
  },
);

export const selectTasksForCurrentDevice = createSelector(
  orm,
  (state) => selectPath(state).deviceId,
  ({ Device }, id) => {
    if (!id) {
      return id;
    }

    const deviceModel = Device.withId(id);

    if (!deviceModel) {
      return deviceModel;
    }

    return deviceModel
      .getOrderedTasksQuerySet()
      .toRefArray()
      .map((task) => ({
        ...task,
        isPersisted: !isLocalId(task.id),
      }));
  },
);

export const selectAttachmentsForCurrentDevice = createSelector(
  orm,
  (state) => selectPath(state).deviceId,
  ({ Device }, id) => {
    if (!id) {
      return id;
    }

    const deviceModel = Device.withId(id);

    if (!deviceModel) {
      return deviceModel;
    }

    return deviceModel
      .getOrderedAttachmentsQuerySet()
      .toRefArray()
      .map((attachment) => ({
        ...attachment,
        isCover: attachment.id === deviceModel.coverAttachmentId,
        isPersisted: !isLocalId(attachment.id),
      }));
  },
);

export const selectActivitiesForCurrentDevice = createSelector(
  orm,
  (state) => selectPath(state).deviceId,
  (state) => selectCurrentUserId(state),
  ({ Device }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const deviceModel = Device.withId(id);

    if (!deviceModel) {
      return deviceModel;
    }

    return deviceModel
      .getFilteredOrderedInDeviceActivitiesQuerySet()
      .toModelArray()
      .map((activityModel) => ({
        ...activityModel.ref,
        isPersisted: !isLocalId(activityModel.id),
        user: {
          ...activityModel.user.ref,
          isCurrent: activityModel.user.id === currentUserId,
        },
      }));
  },
);

export const selectNotificationIdsForCurrentDevice = createSelector(
  orm,
  (state) => selectPath(state).deviceId,
  ({ Device }, id) => {
    if (!id) {
      return id;
    }

    const deviceModel = Device.withId(id);

    if (!deviceModel) {
      return deviceModel;
    }

    return deviceModel
      .getUnreadNotificationsQuerySet()
      .toRefArray()
      .map((notification) => notification.id);
  },
);

export default {
  makeSelectDeviceById,
  selectDeviceById,
  makeSelectUsersByDeviceId,
  selectUsersByDeviceId,
  makeSelectContentsByDeviceId,
  selectContentsByDeviceId,
  makeSelectTasksByDeviceId,
  selectTasksByDeviceId,
  makeSelectLastActivityIdByDeviceId,
  selectLastActivityIdByDeviceId,
  makeSelectNotificationsByDeviceId,
  selectNotificationsByDeviceId,
  makeSelectNotificationsTotalByDeviceId,
  selectNotificationsTotalByDeviceId,
  selectCurrentDevice,
  selectUsersForCurrentDevice,
  selectContentsForCurrentDevice,
  selectTasksForCurrentDevice,
  selectAttachmentsForCurrentDevice,
  selectActivitiesForCurrentDevice,
  selectNotificationIdsForCurrentDevice,
};
