import core from './core';
import provinces from './provinces';
import districts from './districts';
import contents from './contents';
import stations from './stations';

export default {
  ...core,
  ...provinces,
  ...districts,
  ...contents,
  ...stations,
};
