import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* districtsWatchers() {
  yield all([
    takeEvery(
      EntryActionTypes.DISTRICT_IN_CURRENT_PROVINCE_CREATE,
      ({ payload: { data, autoOpen } }) => services.createDistrictInCurrentProvince(data, autoOpen),
    ),
    takeEvery(EntryActionTypes.DISTRICT_CREATE_HANDLE, ({ payload: { district, requestId } }) =>
      services.handleDistrictCreate(district, requestId),
    ),
    takeEvery(EntryActionTypes.DISTRICT_FETCH, ({ payload: { id } }) => services.fetchDistrict(id)),
    takeEvery(EntryActionTypes.DISTRICT_UPDATE, ({ payload: { id, data } }) =>
      services.updateDistrict(id, data),
    ),
    takeEvery(EntryActionTypes.DISTRICT_UPDATE_HANDLE, ({ payload: { district } }) =>
      services.handleDistrictUpdate(district),
    ),
    takeEvery(EntryActionTypes.DISTRICT_MOVE, ({ payload: { id, index } }) =>
      services.moveDistrict(id, index),
    ),
    takeEvery(EntryActionTypes.DISTRICT_DELETE, ({ payload: { id } }) =>
      services.deleteDistrict(id),
    ),
    takeEvery(EntryActionTypes.DISTRICT_DELETE_HANDLE, ({ payload: { district } }) =>
      services.handleDistrictDelete(district),
    ),
  ]);
}
