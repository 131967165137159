import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createStation(districtId, data) {
  const nextData = {
    ...data,
    position: yield select(selectors.selectNextStationPosition, districtId),
  };

  const localId = yield call(createLocalId);

  yield put(
    actions.createStation({
      ...nextData,
      districtId,
      id: localId,
    }),
  );

  let station;
  try {
    ({ item: station } = yield call(request, api.createStation, districtId, nextData));
  } catch (error) {
    yield put(actions.createStation.failure(localId, error));
    return;
  }

  yield put(actions.createStation.success(localId, station));
}

export function* createStationInCurrentDistrict(data) {
  const { districtId: id } = yield select(selectors.selectPath);

  const { districtId } = data;

  yield call(createStation, districtId || id, data);
}

export function* handleStationCreate(station) {
  yield put(actions.handleStationCreate(station));
}

export function* updateStation(id, data) {
  yield put(actions.updateStation(id, data));

  let station;
  try {
    ({ item: station } = yield call(request, api.updateStation, id, data));
  } catch (error) {
    yield put(actions.updateStation.failure(id, error));
    return;
  }

  yield put(actions.updateStation.success(station));
}

export function* handleStationUpdate(station) {
  yield put(actions.handleStationUpdate(station));
}

export function* moveStation(id, index) {
  const { districtId } = yield select(selectors.selectStationById, id);
  const position = yield select(selectors.selectNextStationPosition, districtId, index, id);

  yield call(updateStation, id, {
    position,
  });
}

export function* deleteStation(id) {
  yield put(actions.deleteStation(id));

  let station;
  try {
    ({ item: station } = yield call(request, api.deleteStation, id));
  } catch (error) {
    yield put(actions.deleteStation.failure(id, error));
    return;
  }

  yield put(actions.deleteStation.success(station));
}

export function* handleStationDelete(station) {
  yield put(actions.handleStationDelete(station));
}

export function* clearStationError() {
  yield put(actions.clearStationError());
}

export function* fetchStations({ page, limit, districtIds }) {
  const { id: provinceId } = yield select(selectors.selectCurrentProvince);

  yield put(actions.fetchStations(provinceId));

  let stations;
  let filter;
  let total;

  if (districtIds?.length > 0) {
    filter = JSON.stringify({ districtId: districtIds });
  }

  try {
    ({
      items: stations,
      included: { total },
    } = yield call(request, api.getStations, provinceId, { page, limit, filter }));
  } catch (error) {
    yield put(actions.fetchStations.failure(provinceId, error));
  }

  yield put(actions.fetchStations.success(provinceId, stations, total));
}

export default {
  createStation,
  createStationInCurrentDistrict,
  handleStationCreate,
  updateStation,
  handleStationUpdate,
  moveStation,
  deleteStation,
  handleStationDelete,
  clearStationError,
  fetchStations,
};
