import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import LiveStream from '../components/ContentModal/LiveStream';

const mapStateToProps = (state, { areasReceived }) => {
  const broadcast = state.ui.liveBroadcast;

  const categories = selectors.selectCategoriesForCurrentProvince(state);
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const wards = selectors.selectWardsForCurrentProvince(state);
  const hamlets = selectors.selectHamletsForCurrentProvince(state) || [];
  const devices = selectors.selectDevicesForCurrentProvince(state);

  const { overrideContentId } = broadcast;
  const customAreasReceived = {};

  if (overrideContentId) {
    const contentLocations = selectors.selectLocationsByContentId(state, overrideContentId);
    const deviceIds = contentLocations.devices.map((d) => d.id);
    const filterDevices = areasReceived.devices.filter((device) => !deviceIds.includes(device.id));
    customAreasReceived.devices = filterDevices;
  }

  return {
    areasReceived: {
      ...areasReceived,
      ...customAreasReceived,
    },
    broadcast,
    districts,
    wards,
    hamlets,
    categories,
    devices,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onLiveBroadcast: entryActions.liveBroadcastStart,
      onLiveBroadcastStop: entryActions.liveBroadcastStop,
      onControl: entryActions.controlDevices,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LiveStream);
