import { connect } from 'react-redux';
import Home from '../components/Home';
import selectors from '../selectors';

const mapStateToProps = (state) => {
  const sidebarMenu = selectors.selectSidebarMenu(state);
  const { contentTotal, scheduleTotal, stationTotal, deviceTotal, slug } =
    selectors.selectCurrentProvince(state);

  const stations = selectors.selectStationsForCurrentProvince(state);
  const devices = selectors.selectDevicesForCurrentProvince(state);

  return {
    sidebarMenu,
    contentTotal,
    scheduleTotal,
    stationTotal,
    deviceTotal,
    stations,
    devices,
    slug,
  };
};

export default connect(mapStateToProps)(Home);
