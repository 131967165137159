import { call, put, select } from 'redux-saga/effects';

import { goToProvince } from './router';
import request from '../request';
import requests from '../requests';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';
import mergeRecords from '../../../utils/merge-records';

export function* createWardMembership(wardId, data) {
  const localId = yield call(createLocalId);

  yield put(
    actions.createWardMembership({
      ...data,
      wardId,
      id: localId,
    }),
  );

  let wardMembership;
  let districtMembership;

  try {
    ({
      item: wardMembership,
      included: { districtMembership },
    } = yield call(request, api.createWardMembership, wardId, data));
  } catch (error) {
    yield put(actions.createWardMembership.failure(localId, error));
    return;
  }

  yield put(actions.createWardMembership.success(localId, wardMembership, districtMembership));
}

export function* createMembershipInCurrentWard(data, wardId) {
  yield call(createWardMembership, wardId, data);
}

export function* handleWardMembershipCreate(wardMembership) {
  const currentUserId = yield select(selectors.selectCurrentUserId);
  const isCurrentUser = wardMembership.userId === currentUserId;

  let user;
  let province;
  let district1;
  let users1;
  let users2;
  let provinceManagers;
  let districts;
  let wardMemberships1;
  let wardMemberships2;
  let contents;
  let contentCategories;
  let stations;
  let devices;
  let deviceMemberships;
  let deviceContents;
  let tasks;
  let attachments;
  let deletedNotifications;

  if (isCurrentUser) {
    let district2;
    try {
      ({ item: district2 } = yield call(request, api.getWard, wardMembership.districtId, false));
    } catch {
      return;
    }

    const { districtId } = yield select(selectors.selectPath);

    yield put(
      actions.handleWardMembershipCreate.fetchProvince(
        district2.provinceId,
        currentUserId,
        districtId,
      ),
    );

    try {
      ({
        item: province,
        included: { users: users1, provinceManagers, districts, wardMemberships: wardMemberships1 },
      } = yield call(request, api.getProvince, district2.provinceId));
    } catch {
      return;
    }

    let body;
    try {
      body = yield call(requests.fetchWardByCurrentPath);
    } catch {} // eslint-disable-line no-empty

    if (body && body.province && body.province.id === district2.provinceId) {
      ({
        province,
        district: district1,
        users: users2,
        wardMemberships: wardMemberships2,
        contents,
        contentCategories,
        stations,
        devices,
        deviceMemberships,
        deviceContents,
        tasks,
        attachments,
      } = body);

      if (body.device) {
        deletedNotifications = yield select(
          selectors.selectNotificationsByDeviceId,
          body.device.id,
        );
      }
    }
  } else {
    try {
      ({ item: user } = yield call(request, api.getUser, wardMembership.userId));
    } catch {
      return;
    }
  }

  yield put(
    actions.handleWardMembershipCreate(
      wardMembership,
      province,
      district1,
      isCurrentUser ? mergeRecords(users1, users2) : [user],
      provinceManagers,
      districts,
      mergeRecords(wardMemberships1, wardMemberships2),
      contents,
      contentCategories,
      stations,
      devices,
      deviceMemberships,
      deviceContents,
      tasks,
      attachments,
      deletedNotifications,
    ),
  );
}

export function* updateWardMembership(id, data) {
  yield put(actions.updateWardMembership(id, data));

  let wardMembership;
  try {
    ({ item: wardMembership } = yield call(request, api.updateWardMembership, id, data));
  } catch (error) {
    yield put(actions.updateWardMembership.failure(id, error));
    return;
  }

  yield put(actions.updateWardMembership.success(wardMembership));
}

export function* handleWardMembershipUpdate(wardMembership) {
  yield put(actions.handleWardMembershipUpdate(wardMembership));
}

export function* deleteWardMembership(id) {
  yield put(actions.deleteWardMembership(id));

  let wardMembership;
  let districtMembership;

  try {
    ({
      item: wardMembership,
      included: { districtMembership },
    } = yield call(request, api.deleteWardMembership, id));
  } catch (error) {
    yield put(actions.deleteWardMembership.failure(id, error));
    return;
  }

  yield put(actions.deleteWardMembership.success(wardMembership, districtMembership));
}

export function* handleWardMembershipDelete(wardMembership, districtMembership) {
  const currentUserId = yield select(selectors.selectCurrentUserId);
  const { districtId, provinceId } = yield select(selectors.selectPath);

  if (wardMembership.userId === currentUserId && wardMembership.districtId === districtId) {
    const isCurrentUserManager = yield select(
      selectors.selectIsCurrentUserManagerForCurrentProvince,
    );

    if (!isCurrentUserManager) {
      yield call(goToProvince, provinceId);
    }
  }

  yield put(actions.handleWardMembershipDelete(wardMembership, districtMembership));
}

export default {
  createWardMembership,
  createMembershipInCurrentWard,
  handleWardMembershipCreate,
  updateWardMembership,
  handleWardMembershipUpdate,
  deleteWardMembership,
  handleWardMembershipDelete,
};
