const VERSION = process.env.REACT_APP_VERSION;

const { BASE_URL } = window;
const BASE_PATH = BASE_URL.replace(/^.*\/\/[^/]*(.*)[^?#]*.*$/, '$1');

const SERVER_BASE_URL =
  process.env.REACT_APP_SERVER_BASE_URL ||
  (process.env.NODE_ENV === 'production' ? BASE_URL : 'http://localhost:1337');

const SERVER_HOST_NAME = SERVER_BASE_URL.replace(/^(.*\/\/[^/?#]*).*$/, '$1');

const ACCESS_TOKEN_KEY = 'accessToken';
const ACCESS_TOKEN_VERSION_KEY = 'accessTokenVersion';
const ACCESS_TOKEN_VERSION = '1';

const POSITION_GAP = 65535;
const ACTIVITIES_LIMIT = 50;

const LIMIT_PER_PAGE = 20;
const LIMIT_NOTIFICATION = 10;

const COORDINATES = [
  ...JSON.parse(process.env.REACT_APP_SOUTH_WEST),
  ...JSON.parse(process.env.REACT_APP_NORTH_EAST),
];

const VIEW_CENTER = JSON.parse(process.env.REACT_APP_CENTER_COORDINATE);

const ADMIN_EMAIL = process.env.EMAIL_ADMIN;

const FIRMWARE_URL = process.env.REACT_APP_DEVICE_FIRMWARE_URL || '';

const FILE_SIZE = process.env.REACT_APP_FILE_SIZE;

export default {
  ADMIN_EMAIL,
  VERSION,
  BASE_PATH,
  SERVER_BASE_URL,
  SERVER_HOST_NAME,
  ACCESS_TOKEN_KEY,
  ACCESS_TOKEN_VERSION_KEY,
  ACCESS_TOKEN_VERSION,
  POSITION_GAP,
  ACTIVITIES_LIMIT,
  LIMIT_PER_PAGE,
  LIMIT_NOTIFICATION,
  COORDINATES,
  VIEW_CENTER,
  FIRMWARE_URL,
  FILE_SIZE,
};
