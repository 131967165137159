import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Label } from 'semantic-ui-react';
import classNames from 'classnames';
import { Input, Popup } from '../../../lib/custom-ui';
import { useForm } from '../../../hooks';
import ScheduleColors from '../../../constants/ScheduleColors';

import styles from './EditStep.module.scss';

const EditStep = React.memo(({ category, onUpdate, onClose }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange, setData] = useForm({
    name: category.name,
    color: category.color,
    position: category.position,
    value: category.value,
  });

  const nameField = useRef(null);
  const valueField = useRef(null);
  const colorField = useRef(null);

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      name: data.name.trim(),
      position: data.position,
      color: data.color,
      value: data.value,
    };

    if (!cleanData.name) {
      nameField.current.select();
      return;
    }

    if (!cleanData.value) {
      valueField.current.select();
      return;
    }

    if (!cleanData.color) {
      colorField.current.select();
      return;
    }

    onUpdate(category.id, cleanData);
    onClose();
  }, [onClose, data, onUpdate, category.id]);

  useEffect(() => {
    nameField.current.focus({
      preventScroll: true,
    });
  }, []);

  return (
    <>
      <Popup.Header>
        {t('common.editCategory_title', {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Form onSubmit={handleSubmit}>
          <Input
            placeholder={t('common.nameCategory')}
            fluid
            ref={nameField}
            name="name"
            value={data.name}
            className={styles.field}
            onChange={handleFieldChange}
          />
          <Input
            // placeholder={t('common.position')}
            ref={valueField}
            fluid
            name="value"
            value={data.value}
            className={styles.field}
            onChange={handleFieldChange}
          />
          <div className={styles.text}>{t('common.color')}</div>
          {ScheduleColors.map((color) => (
            <Button
              key={color}
              type="button"
              ref={colorField}
              name="color"
              value={color}
              className={classNames(
                styles.colorButton,
                color === data.color && styles.colorButtonActive,
              )}
              style={{ backgroundColor: color }}
              onClick={handleFieldChange}
            />
          ))}
          <div className={styles.controls}>
            <Button positive content={t('action.save')} className={styles.createButton} />
          </div>
        </Form>
      </Popup.Content>
    </>
  );
});

EditStep.propTypes = {
  category: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditStep;
