import EntryActionTypes from '../constants/EntryActionTypes';

const createContentInCurrentDistrict = (data) => ({
  type: EntryActionTypes.CONTENT_IN_CURRENT_DISTRICT_CREATE,
  payload: {
    data,
  },
});

const createContentInCurrentProvince = (data) => ({
  type: EntryActionTypes.CONTENT_IN_CURRENT_PROVINCE_CREATE,
  payload: {
    data,
  },
});

const handleContentCreate = (content) => ({
  type: EntryActionTypes.CONTENT_CREATE_HANDLE,
  payload: {
    content,
  },
});

const fetchContents = (page, limit, filter) => ({
  type: EntryActionTypes.CONTENTS_FETCH,
  payload: {
    page,
    limit,
    filter,
  },
});

const searchContent = (filter) => ({
  type: EntryActionTypes.CONTENTS_SEARCH,
  payload: {
    filter,
  },
});

const selectContent = (content) => ({
  type: EntryActionTypes.CONTENT_SELECT,
  payload: {
    content,
  },
});

const updateContent = (id, data) => ({
  type: EntryActionTypes.CONTENT_UPDATE,
  payload: {
    id,
    data,
  },
});

const handleContentUpdate = (content, attached) => ({
  type: EntryActionTypes.CONTENT_UPDATE_HANDLE,
  payload: {
    content,
    attached,
  },
});

const moveContent = (id, index) => ({
  type: EntryActionTypes.CONTENT_MOVE,
  payload: {
    id,
    index,
  },
});

const deleteContent = (id) => ({
  type: EntryActionTypes.CONTENT_DELETE,
  payload: {
    id,
  },
});

const handleContentDelete = (content) => ({
  type: EntryActionTypes.CONTENT_DELETE_HANDLE,
  payload: {
    content,
  },
});

const text2Speech = (data) => ({
  type: EntryActionTypes.CONTENT_TEXT_TO_SPEECH,
  payload: {
    data,
  },
});

const addContentToDevice = (id, deviceId) => ({
  type: EntryActionTypes.CONTENT_TO_DEVICE_ADD,
  payload: {
    id,
    deviceId,
  },
});

const addContentToCurrentDevice = (id) => ({
  type: EntryActionTypes.CONTENT_TO_CURRENT_DEVICE_ADD,
  payload: {
    id,
  },
});

const handleContentToDeviceAdd = (deviceContent) => ({
  type: EntryActionTypes.CONTENT_TO_DEVICE_ADD_HANDLE,
  payload: {
    deviceContent,
  },
});

const removeContentFromDevice = (id, deviceId) => ({
  type: EntryActionTypes.CONTENT_FROM_DEVICE_REMOVE,
  payload: {
    id,
    deviceId,
  },
});

const removeContentFromCurrentDevice = (id) => ({
  type: EntryActionTypes.CONTENT_FROM_CURRENT_DEVICE_REMOVE,
  payload: {
    id,
  },
});

const handleContentFromDeviceRemove = (deviceContent) => ({
  type: EntryActionTypes.CONTENT_FROM_DEVICE_REMOVE_HANDLE,
  payload: {
    deviceContent,
  },
});

const addContentToFilterInCurrentDistrict = (id) => ({
  type: EntryActionTypes.CONTENT_TO_FILTER_IN_CURRENT_DISTRICT_ADD,
  payload: {
    id,
  },
});

const removeContentFromFilterInCurrentDistrict = (id) => ({
  type: EntryActionTypes.CONTENT_FROM_FILTER_IN_CURRENT_DISTRICT_REMOVE,
  payload: {
    id,
  },
});

const clearContentError = () => ({
  type: EntryActionTypes.CONTENT_ERROR_CLEAR,
  payload: {},
});

const clearContentPlaylist = () => ({
  type: EntryActionTypes.CONTENT_PLAYLIST_CLEAR,
  payload: {},
});

const clearContentConcatFile = () => ({
  type: EntryActionTypes.CONTENT_CONCAT_FILE__CLEAR,
  payload: {},
});

const cancelScheduleContent = (id, LichPhatID) => ({
  type: EntryActionTypes.CONTENT_CANCEL_SCHEDULE,
  payload: {
    id,
    LichPhatID,
  },
});

const handleContentCancelSchedule = (content, contentSchedules) => ({
  type: EntryActionTypes.CONTENT_CANCEL_SCHEDULE_HANDLE,
  payload: {
    content,
    contentSchedules,
  },
});

const fetchStationsBroadcastedContent = (id, filter) => ({
  type: EntryActionTypes.CONTENT_FETCH_STATIONS_BROADCASTED,
  payload: {
    id,
    filter,
  },
});

const handleContentCategoryCreate = (contentCategory) => ({
  type: EntryActionTypes.CONTENT_CATEGORY_CREATE_HANDLE,
  payload: {
    contentCategory,
  },
});

const handleContentCategoryUpdate = (contentCategory) => ({
  type: EntryActionTypes.CONTENT_CATEGORY_UPDATE_HANDLE,
  payload: {
    contentCategory,
  },
});

const handleContentCategoryDelete = (contentCategory) => ({
  type: EntryActionTypes.CONTENT_CATEGORY_DELETE_HANDLE,
  payload: {
    contentCategory,
  },
});

const handleContentLocationCreate = (contentLocation) => ({
  type: EntryActionTypes.CONTENT_LOCATION_CREATE_HANDLE,
  payload: {
    contentLocation,
  },
});

const handleContentLocationDelete = (contentLocation) => ({
  type: EntryActionTypes.CONTENT_LOCATION_DELETE_HANDLE,
  payload: {
    contentLocation,
  },
});

const handleContentLocationUpdate = (contentLocations) => ({
  type: EntryActionTypes.CONTENT_LOCATION_UPDATE_HANDLE,
  payload: {
    contentLocations,
  },
});

const concatFile = (data) => ({
  type: EntryActionTypes.CONTENT_CONCAT_FILE,
  payload: {
    data,
  },
});

const enalbleContentsFetching = () => ({
  type: EntryActionTypes.ENABLE_CONTENTS_FETCHING,
  payload: {},
});

export default {
  createContentInCurrentDistrict,
  createContentInCurrentProvince,
  handleContentCreate,
  fetchContents,
  searchContent,
  selectContent,
  updateContent,
  handleContentUpdate,
  moveContent,
  deleteContent,
  text2Speech,
  handleContentDelete,
  addContentToDevice,
  addContentToCurrentDevice,
  handleContentToDeviceAdd,
  removeContentFromDevice,
  removeContentFromCurrentDevice,
  handleContentFromDeviceRemove,
  addContentToFilterInCurrentDistrict,
  removeContentFromFilterInCurrentDistrict,
  clearContentError,
  clearContentPlaylist,
  clearContentConcatFile,
  cancelScheduleContent,
  handleContentCancelSchedule,
  fetchStationsBroadcastedContent,
  concatFile,
  handleContentCategoryCreate,
  handleContentCategoryUpdate,
  handleContentCategoryDelete,
  handleContentLocationCreate,
  handleContentLocationDelete,
  handleContentLocationUpdate,
  enalbleContentsFetching,
};
