import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Devices from '../components/Devices';

const mapStateToProps = (state) => {
  const { districtId } = selectors.selectPath(state);
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const devices = selectors.selectDevicesForCurrentProvince(state);
  const { name, deviceTotal } = selectors.selectCurrentProvince(state);
  // const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);
  const { permissions, isAdmin } = selectors.selectCurrentUser(state);

  return {
    items: devices,
    districts,
    currentId: districtId,
    canAdd: permissions.includes('CAN_ADD') && isAdmin,
    canDelete: permissions.includes('CAN_DELETE') && isAdmin,
    canEdit: permissions.includes('CAN_EDIT'),
    canView: permissions.includes('CAN_VIEW'),
    province: { name, deviceTotal },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createDevice,
      onUpdate: entryActions.updateDevice,
      onMove: entryActions.moveDevice,
      onDelete: entryActions.deleteDevice,
      onFetch: entryActions.fetchDevices,
      onRestart: entryActions.restartDevice,
      onControl: entryActions.controlDevices,
      // onEditDeviceClick: entryActions.openEditDeviceModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
