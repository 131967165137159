import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Table } from 'semantic-ui-react';

import { usePopup } from '../../lib/popup';
import AddStep from './AddStep';
import EditStep from './EditStep';

import styles from './TableSelect.module.scss';

const TableSelect = React.memo(
  ({
    parentCode,
    canAdd,
    canEdit,
    items,
    id,
    name,
    onChange,
    currentId,
    onCreate,
    onUpdate,
    onDelete,
  }) => {
    const [t] = useTranslation();

    const AddPopup = usePopup(AddStep);
    const EditPopup = usePopup(EditStep);

    return (
      <div className={styles.table}>
        <Table size="small" attached="top" unstackable selectable>
          <Table.Header className={styles.header}>
            <Table.Row>
              <Table.HeaderCell colSpan="3" style={{ height: 62 }}>
                {t(`common.${name.toLowerCase()}`)}
                {canAdd && (
                  <AddPopup onCreate={onCreate} id={id} unit={name} code={parentCode}>
                    <Button
                      disabled={!id}
                      content={t('action.create')}
                      floated="right"
                      icon="map marker alternate"
                      labelPosition="left"
                      primary
                      size="mini"
                    />
                  </AddPopup>
                )}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell width={9}>{t('common.name')}</Table.HeaderCell>
              <Table.HeaderCell width={5}>{t('common.code')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.length <= 0 ? (
              <Table.Row>
                <Table.Cell colSpan="3">Không có dữ liệu</Table.Cell>
              </Table.Row>
            ) : (
              items.map((item) => (
                <Table.Row
                  key={item.id}
                  active={currentId === item.id}
                  onClick={() => onChange(_, { value: item.id })}
                >
                  <Table.Cell>{item.name}</Table.Cell>
                  <Table.Cell>{item.code}</Table.Cell>
                  <Table.Cell textAlign="right">
                    {canEdit && (
                      <EditPopup
                        defaultData={item}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        unit={name}
                      >
                        <Icon name="edit outline" className={styles.editIcon} />
                      </EditPopup>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))
            )}
          </Table.Body>
        </Table>
      </div>
    );
  },
);

export default TableSelect;

TableSelect.propTypes = {
  parentCode: PropTypes.string,
  canAdd: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  currentId: PropTypes.string,
  onChange: PropTypes.func,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

TableSelect.defaultProps = {
  onChange: () => {},
  currentId: undefined,
  id: undefined,
  parentCode: undefined,
};
