import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Icon, Label, Tab } from 'semantic-ui-react';
import { DragDropContext } from 'react-beautiful-dnd';
import classNames from 'classnames';
import Drop from './DragDrop/Drop';
import Drag from './DragDrop/Drag';
import EditNameStep from './DragDrop';
import AddStep from './AddStep/AddStep';
import { usePopup } from '../../lib/popup';

import styles from './CategoriesPane.module.scss';

const TypeDrag = {
  DROPPABLE_CATEGORY: 'droppable-category',
  DROPPABLE_ITEM: 'droppable-item',
  DROPPABLE: 'droppable',
};

const CategoriesPane = React.memo(
  ({ isAdmin, categories, onCreate, onDelete, onUpdate, onMove }) => {
    const [t] = useTranslation();
    const CreateCategoryPopup = usePopup(AddStep);
    const handleDeleteCategory = useCallback(
      (id) => {
        onDelete(id);
      },
      [onDelete],
    );

    const onUpdateCategory = useCallback(
      (categoryId, data) => {
        onUpdate(categoryId, data);
      },
      [onUpdate],
    );

    const handleDragEnd = useCallback(
      ({ draggableId, source, destination }) => {
        if (
          !destination ||
          (source.droppableId === destination.droppableId && source.index === destination.index)
        ) {
          return;
        }

        const parentCategoryId = destination.droppableId;
        if (parentCategoryId === TypeDrag.DROPPABLE) {
          onMove(draggableId, destination.index);
        } else {
          onMove(draggableId, destination.index, parentCategoryId);
        }
      },
      [onMove],
    );
    const renderChildNodes = useCallback(
      (trees) => {
        return trees.map((data, index) => {
          if (!data.childNodes.length) {
            return (
              <Drag
                isAdmin={isAdmin}
                category={data}
                color={data.color}
                id={data.id}
                index={index}
                key={data.id}
                name={data.name}
                onCreate={onCreate}
                onDelete={onDelete}
                onUpdateCategory={onUpdateCategory}
              />
            );
          }

          return (
            <Drag
              isAdmin={isAdmin}
              category={data}
              id={data.id}
              index={index}
              key={data.id}
              position={data.position}
            >
              <ul className={styles.tree}>
                <li>
                  <details open>
                    <EditNameStep
                      isAdmin={isAdmin}
                      category={data}
                      handleDeleteCategory={handleDeleteCategory}
                      onUpdateCategory={onUpdateCategory}
                    />
                    <Drop key={data.id} id={data.id} type={`TypeDrop:${data.parentId}`}>
                      {renderChildNodes(data.childNodes)}
                    </Drop>
                    {isAdmin && (
                      <CreateCategoryPopup
                        className={styles.buttonCreateCategory}
                        onCreate={onCreate}
                        parentId={data.id}
                      >
                        <Label
                          as="a"
                          className={classNames(styles.labelSummory, styles.labelCreate)}
                        >
                          <Icon className={styles.icon} name="plus" />
                        </Label>
                      </CreateCategoryPopup>
                    )}
                  </details>
                </li>
              </ul>
            </Drag>
          );
        });
      },
      [isAdmin, handleDeleteCategory, onCreate, onDelete, onUpdateCategory],
    );

    return (
      <Tab.Pane attached={false} className={styles.wrapper}>
        {isAdmin && (
          <CreateCategoryPopup onCreate={onCreate} className={styles.buttonCreateCategory}>
            <Button>{t('action.createCategory')}</Button>
          </CreateCategoryPopup>
        )}
        <Divider horizontal inverted />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Drop id={TypeDrag.DROPPABLE} type={TypeDrag.DROPPABLE_CATEGORY}>
            {categories.map((category, categoryIndex) => {
              return (
                <Drag
                  isAdmin={isAdmin}
                  categoriname={category.name}
                  category={category}
                  id={category.id}
                  index={categoryIndex}
                  key={category.id}
                  position={category.position}
                >
                  <ul className={styles.tree}>
                    <li>
                      <details>
                        <EditNameStep
                          isAdmin={isAdmin}
                          category={category}
                          handleDeleteCategory={handleDeleteCategory}
                          onUpdateCategory={onUpdateCategory}
                        />
                        <Drop
                          key={category.parentId}
                          id={category.id}
                          type={`TypeDrop:${category.parentId}`}
                        >
                          {renderChildNodes(category.childNodes)}
                        </Drop>
                        {isAdmin && (
                          <CreateCategoryPopup
                            className={styles.buttonCreateCategory}
                            onCreate={onCreate}
                            parentId={category.id}
                          >
                            <Label
                              as="a"
                              className={classNames(styles.labelSummory, styles.labelCreate)}
                            >
                              <Icon className={styles.icon} name="plus" />
                            </Label>
                          </CreateCategoryPopup>
                        )}
                      </details>
                    </li>
                  </ul>
                </Drag>
              );
            })}
          </Drop>
        </DragDropContext>
      </Tab.Pane>
    );
  },
);

CategoriesPane.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  /* eslint-disable react/forbid-prop-types */
  categories: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  // onCategoryMove: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
};

export default CategoriesPane;
