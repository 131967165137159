import React from 'react';
import { Icon, Pagination } from 'semantic-ui-react';

const PaginationCustom = React.memo(({ ...props }) => {
  const { matches } = window.matchMedia('(max-width: 600px)');

  return (
    <Pagination
      /* eslint-disable react/jsx-props-no-spreading */
      {...props}
      {...(matches && {
        boundaryRange: 0,
        siblingRange: 0,
      })}
      ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
      firstItem={{ content: <Icon name="angle double left" />, icon: true }}
      lastItem={{ content: <Icon name="angle double right" />, icon: true }}
      prevItem={{ content: <Icon name="angle left" />, icon: true }}
      nextItem={{ content: <Icon name="angle right" />, icon: true }}
    />
  );
});

export default PaginationCustom;
