import React, { memo, useCallback, useRef, useEffect } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { usePopup } from '../../../lib/popup';
import DeleteStep from '../../DeleteStep';
// import NameEdit from './NameEdit';
import styles from './EditNameStep.module.scss';
import EditStep from '../EditStep';

const EditNameStep = memo(({ isAdmin, category, handleDeleteCategory, onUpdateCategory }) => {
  const DeletePopup = usePopup(DeleteStep);
  const EditPopup = usePopup(EditStep);
  // const nameEditTitle = useRef(null);

  // const handleHeaderClick = useCallback(() => {
  //   nameEditTitle.current?.open();
  // }, []);

  return (
    <summary>
      <Label className={styles.labelSummory} style={{ backgroundColor: category.color }}>
        {/* <NameEdit
          categoryId={category.id}
          defaultValue={category.name}
          onUpdate={onUpdateCategory}
          ref={nameEditTitle}
          styles={{ backgroundColor: category.color }}
        > */}
        {category.name}
        {isAdmin && (
          <>
            <EditPopup
              className={styles.buttonCreateCategory}
              onUpdate={onUpdateCategory}
              category={category}
            >
              <Icon
                name="edit"
                className={styles.icon}
                // onClick={handleHeaderClick}
              />
            </EditPopup>
            {category.childNodes.length > 0 ? null : (
              <DeletePopup
                disabled={!!category.deleteForm.error}
                title="common.deleteCategory_titile"
                content={
                  category.deleteForm.error
                    ? 'common.categoryInUse'
                    : 'common.areYouSureYouWantToDeleteThisCategory'
                }
                buttonContent="action.deleteCategory"
                onConfirm={() => {
                  handleDeleteCategory(category.id);
                }}
              >
                <Icon name="delete" className={styles.icon} />
              </DeletePopup>
            )}
          </>
        )}
        {/* </NameEdit> */}
      </Label>
    </summary>
  );
});
EditNameStep.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  handleDeleteCategory: PropTypes.func.isRequired,
  onUpdateCategory: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default EditNameStep;
