import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* hamletsWatchers() {
  yield all([
    takeEvery(EntryActionTypes.HAMLET_IN_CURRENT_PROVINCE_CREATE, ({ payload: { data } }) =>
      services.createHamletInCurrentProvince(data),
    ),
    takeEvery(EntryActionTypes.HAMLET_CREATE_HANDLE, ({ payload: { hamlet, requestId } }) =>
      services.handleHamletCreate(hamlet, requestId),
    ),
    takeEvery(EntryActionTypes.HAMLET_FETCH, ({ payload: { id } }) => services.fetchdHamlet(id)),
    takeEvery(EntryActionTypes.HAMLET_UPDATE, ({ payload: { id, data } }) =>
      services.updateHamlet(id, data),
    ),
    takeEvery(EntryActionTypes.HAMLET_UPDATE_HANDLE, ({ payload: { hamlet } }) =>
      services.handleHamletUpdate(hamlet),
    ),
    takeEvery(EntryActionTypes.HAMLET_MOVE, ({ payload: { id, index } }) =>
      services.moveHamlet(id, index),
    ),
    takeEvery(EntryActionTypes.HAMLET_DELETE, ({ payload: { id } }) => services.deleteHamlet(id)),
    takeEvery(EntryActionTypes.HAMLET_DELETE_HANDLE, ({ payload: { hamlet } }) =>
      services.handleHamletDelete(hamlet),
    ),
  ]);
}
