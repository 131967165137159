const DistrictTrainingOfficers = (data, districts) => {
  const dataReport = districts.map((district) => {
    const { CongViecKhac, KyThuat, SanXuatNoiDung } = district.NhanLuc;

    const workerTotal = CongViecKhac + KyThuat + SanXuatNoiDung;

    return {
      code: district.code,
      name: district.name,
      workerTotal,
    };
  });

  const accomulator = dataReport.reduce((acc, item) => acc + item.workerTotal, 0);

  let html = ` <div class="header">
  <h3>Bảng 6</h3>
  <p>SỐ LIỆU TẬP HUẤN, BỒI DƯỠNG CÁN BỘ CẤP HUYỆN</p>
  <p>Kỳ báo cáo: Quý ${data.quater} năm ${data.year.getFullYear()}.</p>
</div>
<table>
  <thead>
    <tr> 
      <th><strong>STT</strong></th>
      <th style="width: 25%">
      <strong>Mã định danh</strong><br>
        (Quyết định số 20/2020/QĐ-TTg ngày 22/7/2020 của Thủ tướng Chính phủ)
      </th>
      <th style="width: 20%"><strong>Tên đơn vị hành chính</strong></th>
      <th><strong>Số lượng cán bộ cấp xã được tập huấn, bồi dưỡng</strong></th>
      <th style="width: 20%"><strong>Ghi chú</strong></th>
    </tr>
  </thead>
  <tbody>`;

  dataReport.forEach((it, index) => {
    html += `<tr style="text-align: center">
      <td>${index + 1}</td>
      <td>${it.code}</td>
      <td>${it.name}</td>
      <td>${it.workerTotal}</td>
      <td></td>
    </tr>`;
  });
  html += `<tr>
      <td colspan="3"><strong>Tổng cộng:</strong></td>
      <td style="text-align: center">${accomulator}</td>
      <td style="text-align: center"></td>
    </tr>
  </tbody>
</table>
<div class="footer">
  <p>
    <strong>1. Đơn vị cập nhật số liệu: </strong>Phòng Văn hóa và Thông tin cấp huyện:<br />
    - Số lượng cán bộ cấp huyện (công chức Phòng VH-TT, viên chức đài truyền thanh huyện/Trung tâm VHTT&TT..)
được tập huấn, bồi dưỡng chuyên môn, nghiệp vụ thông tin cơ sở (có ghi “1” trở lên; không có ghi “0”).
  </p>
  <p>
    <strong>2. Yêu cầu trích xuất số liệu báo cáo theo các biểu: </strong><br />
    - Số huyện của tỉnh (thành phố trực thuộc TW);<br />
    - Số quận của thành phố trực thuộc TW;<br />
    - Số thị xã của tỉnh (thành phố trực thuộc TW);<br />
    - Số thành phố thuộc tỉnh của tỉnh (thành phố trực thuộc TW).
  </p>
  <p>
    <strong>3. Thời hạn gửi báo cáo: </strong> <br />
    - Chậm nhất ngày 08 của tháng cuối quý đối với báo cáo định kỳ hằng quý; <br />
    - Chậm nhất ngày 27/11 đối với báo cáo định kỳ hằng năm.
  </p>
</div>`;
  return html;
};

export default DistrictTrainingOfficers;
