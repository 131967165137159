const NoBusinessNewsletter = (data, districts, wards) => {
  const districtReport = districts.map((district) => ({
    name: district.name,
    code: district.code,
    districtDocuments: district.TaiLieuKhongKinhDoanh,
    wardDocuments: '-',
    districtNewsletter: district.BanTinGiay,
    wardNewsletter: '-',
  }));

  const wardReport = wards.map((ward) => ({
    name: ward.name,
    code: ward.code,
    districtDocuments: '-',
    wardDocuments: ward.TaiLieuKhongKinhDoanh,
    districtNewsletter: '-',
    wardNewsletter: ward.BanTinGiay,
  }));

  const dataReport = [...districtReport, ...wardReport];

  let html = `<div class="header">
  <h3>Bảng 7</h3>
  <p>SỐ LIỆU TÀI LIỆU KHÔNG KINH DOANH VÀ BẢN TIN GIẤY</p>
  <p>Kỳ báo cáo: Quý ${data.quater} năm ${data.year.getFullYear()}.</p>
</div>
<table>
  <thead>
    <tr>
      <th rowspan="2" style="width: 15%">
        <strong> Mã định danh </strong>
        <br />
        (Quyết định số 20/2020/QĐ-TTg ngày 22/7/2020 của Thủ tướng Chính phủ)
      </th>
      <th rowspan="2"><strong>Đơn vị hành chính</strong></th>
      <th colspan="2"><strong>Số lượng Tài liệu không kinh doanh</strong></th>
      <th colspan="2"><strong>Số lượng Bản tin giấy</strong></th>
      <th rowspan="2" style="width: 15%"><strong>Ghi chú</strong></th>
    </tr>
    <tr>
      <th><strong>Cấp huyện</strong></th>
      <th><strong>Cấp xã</strong></th>
      <th><strong>Cấp huyện</strong></th>
      <th><strong>Cấp xã</strong></th>
    </tr>
  </thead>
  <tbody>`;

  dataReport.forEach((it) => {
    html += `<tr>
      <td>${it.name}</td>
      <td>${it.code}</td>
      <td style="text-align: center">${it.districtDocuments}</td>
      <td style="text-align: center">${it.wardDocuments}</td>
      <td style="text-align: center">${it.districtNewsletter}</td>
      <td style="text-align: center">${it.wardNewsletter}</td>
      <td></td>
      </tr>`;
  });

  html += `</tbody>
</table>
<div class="footer">
  <p><strong>1. Đơn vị cập nhật số liệu: </strong>: Sở Thông tin và Truyền thông<br /></p>
  <p>
    <strong>2. Thời hạn gửi báo cáo: </strong> <br />
    - Chậm nhất ngày 08 của tháng cuối quý đối với báo cáo định kỳ hằng quý; <br />
    - Chậm nhất ngày 27/11 đối với báo cáo định kỳ hằng năm.
  </p>
  <p>
    <strong>3. Lưu ý: </strong> <br />
    - Tài liệu không kinh doanh: Tài liệu do Ủy ban nhân dân cấp huyện, cấp xã đề nghị Sở TTTT
    cấp phép (Số đầu tài liệu được cấp phép). <br />
    - Bản tin giấy: Bản tin do Ủy ban nhân dân cấp huyện, cấp xã đề nghị Sở TTTT cấp phép (Số
    đầu bản tin được cấp phép).
  </p>
</div>`;
  return html;
};

export default NoBusinessNewsletter;
