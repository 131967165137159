import { combineReducers } from 'redux';

import authenticateForm from './authenticate-form';
import userCreateForm from './user-create-form';
import provinceCreateForm from './province-create-form';
import stationCreateForm from './station-create-form';
import deviceCreateForm from './device-create-form';
import contentCreateForm from './content-create-form';
import scheduleCreateForm from './schedule-create-form';
import contentSearch from './content-search';
import liveBroadcast from './live-broadcast';
import reports from './reports';
import userGroupForm from './user-group-form';

export default combineReducers({
  authenticateForm,
  userCreateForm,
  provinceCreateForm,
  stationCreateForm,
  deviceCreateForm,
  contentCreateForm,
  scheduleCreateForm,
  contentSearch,
  liveBroadcast,
  reports,
  userGroupForm,
});
