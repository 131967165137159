import socket from './socket';

/* Actions */

const createProvinceManager = (provinceId, data, headers) =>
  socket.post(`/provinces/${provinceId}/managers`, data, headers);

const deleteProvinceManager = (id, headers) =>
  socket.delete(`/province-managers/${id}`, undefined, headers);

export default {
  createProvinceManager,
  deleteProvinceManager,
};
