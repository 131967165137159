import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Dropdown, Form, Header, Input, Label, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { useForm } from '../../hooks';
import { CONTROL_CODES, STOP_PLAYING_CONTENT, WATTAGE_MODES } from '../../constants/Enums';

import styles from './SelectOption.module.scss';
import Config from '../../constants/Config';

const isInvalidTime = (time) => {
  return !String(time).match(/^[0-9]+$/);
};

const defaultData = (option) => {
  if (!option.controlDevice || isEmpty(option.controlDevice)) {
    return {
      MaLenh: 'default',
      ThamSo: 0,
    };
  }

  return {
    MaLenh: +option.controlDevice[0].MaLenh,
    ThamSo: option.controlDevice[0].ThamSo,
  };
};

const DEFAULT_VOLUME = 50;

const { FIRMWARE_URL } = Config;

const SelectOption = React.memo(({ item, payload, onClose, onControl }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange, setData] = useForm(defaultData(item));

  const [time, setTime] = useState(0);
  const [openSelectOption, setOpenSelectOption] = useState(false);

  const timeResetField = useRef(null);
  const linkField = useRef(null);

  const handleToggleSelectOption = useCallback((boolean) => {
    setOpenSelectOption(boolean);
  }, []);

  const handleSelectCommanCode = useCallback(
    (_, { value, name }) => {
      const controlOptions = item?.controlDevice || [];

      const option = controlOptions.find((it) => +it.MaLenh === value);

      setData((prev) => {
        switch (value) {
          case 0:
            return { ...prev, [name]: value, ThamSo: option ? +option.ThamSo : DEFAULT_VOLUME };

          case 3:
            setTime(option ? option.ThamSo : 0);
            return { ...prev, [name]: value, ThamSo: option ? +option.ThamSo : 0 };

          case 4:
            return { ...prev, [name]: value, ThamSo: option ? option.ThamSo : FIRMWARE_URL };

          default:
            return { ...prev, [name]: value, ThamSo: option ? +option.ThamSo : 0 };
        }
      });
    },
    [item?.controlDevice, setData],
  );

  const handleDurationResetDevice = useCallback(
    (_, { value, name }) => {
      if (!isInvalidTime(value)) {
        // const [hour, minute, second] = value.split(':').map(Number);

        // const duration = hour * 3600 + minute * 60 + second;
        handleFieldChange(_, { name, value });
      } else {
        handleFieldChange(_, { name, value: 0 });
      }

      setTime(value);
    },
    [handleFieldChange],
  );

  const handleSubmit = useCallback(() => {
    const option = {
      MaLenh: String(data.MaLenh),
      ThamSo: String(data.ThamSo),
    };

    if (option.MaLenh === 'default') {
      setOpenSelectOption(true);
      return;
    }

    if (data.MaLenh === 3 && !+data.ThamSo) {
      timeResetField.current.focus();
      return;
    }

    if (data.MaLenh === 4 && !data.ThamSo) {
      linkField.current.focus();
      return;
    }

    onControl({ ...payload, DieuKhienThietBi: option });
    onClose();
  }, [payload, data, onControl, onClose]);

  const parameterNode = useCallback(
    (params) => {
      const controlCode = CONTROL_CODES.find((it) => it.value === +params.MaLenh);

      let parameter;

      switch (+params.MaLenh) {
        case 0:
          parameter = params.ThamSo.replace(/^0+/, '');

          break;
        case 1: {
          const mode = WATTAGE_MODES.find((it) => it.value === +params.ThamSo);
          parameter = t(`common.${mode.key}`);

          break;
        }
        case 2: {
          const stopType = STOP_PLAYING_CONTENT.find((it) => it.value === +params.ThamSo);
          parameter = t(`common.${stopType.key}`);

          break;
        }
        case 3:
          parameter = params.ThamSo;

          break;
        case 4:
          parameter = params.ThamSo;

          break;

        default:
      }

      return (
        <div key={params.MaLenh} className={styles.fieldParameter}>
          <span className={styles.text}>{t(`common.${controlCode.key}`)}</span>
          <Label active content={parameter} />
        </div>
      );
    },
    [t],
  );

  return (
    <Modal open closeIcon size="small" onClose={onClose}>
      <Modal.Header>{t('common.setupDeviceControl')}</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <div className={styles.wrapperGroup}>
            <div className={styles.wrapperField}>
              <span className={styles.text}>{t('common.controlDevice')}</span>
              <Dropdown
                name="MaLenh"
                open={openSelectOption}
                fluid
                selection
                className={styles.borderDropdown}
                options={CONTROL_CODES.map((it) => ({
                  ...it,
                  text: t(`common.${it.key}`),
                }))}
                value={data.MaLenh}
                onChange={handleSelectCommanCode}
                onBlur={() => handleToggleSelectOption(false)}
                onClose={() => handleToggleSelectOption(false)}
                onClick={() => handleToggleSelectOption(true)}
              />
            </div>
            {data.MaLenh === 0 && (
              <div className={styles.wrapperField}>
                <span className={styles.text}>{t('common.volume')}</span>
                <Input
                  autoComplete="off"
                  name="ThamSo"
                  type="number"
                  max={100}
                  min={0}
                  value={+data.ThamSo}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
            )}
            {data.MaLenh === 1 && (
              <div className={styles.wrapperField}>
                <span className={styles.text}>{t('common.option')}</span>
                <Dropdown
                  name="ThamSo"
                  placeholder={t('common.controlDevice')}
                  fluid
                  selection
                  className={styles.borderDropdown}
                  options={WATTAGE_MODES.map((it) => ({
                    ...it,
                    text: t(`common.${it.key}`),
                  }))}
                  value={+data.ThamSo}
                  onChange={handleFieldChange}
                />
              </div>
            )}
            {data.MaLenh === 2 && (
              <div className={styles.wrapperField}>
                <span className={styles.text}>{t('common.option')}</span>
                <Dropdown
                  name="ThamSo"
                  placeholder={t('common.controlDevice')}
                  fluid
                  selection
                  style={{ borderRadius: '8px' }}
                  options={STOP_PLAYING_CONTENT.map((it) => ({
                    ...it,
                    text: t(`common.${it.key}`),
                  }))}
                  value={+data.ThamSo}
                  onChange={handleFieldChange}
                />
              </div>
            )}
            {data.MaLenh === 3 && (
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.afterPeriod')}
                  {isInvalidTime(time) && (
                    <small style={{ color: '#9f3a38 ', marginLeft: 10 }}>
                      Thời gian không hợp lệ
                    </small>
                  )}
                </span>
                <Input
                  name="ThamSo"
                  ref={timeResetField}
                  value={time}
                  error={isInvalidTime(time)}
                  className={styles.field}
                  onChange={handleDurationResetDevice}
                />
              </div>
            )}
            {data.MaLenh === 4 && (
              <div className={styles.wrapperField}>
                <span className={styles.text}>{t('common.link')}</span>
                <Input
                  name="ThamSo"
                  ref={linkField}
                  max={100}
                  min={0}
                  value={data.ThamSo}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
            )}
          </div>
          {item.controlDevice && (
            <div>
              <Divider horizontal>
                <Header as="h4">Thông tin thiết bị</Header>
              </Divider>
              <div className={styles.fieldParameter}>
                <span className={styles.text}>{t('common.deviceName')}</span>
                <Label color="blue" size="large" content={item.name} />
              </div>
              {item.controlDevice.map((it) => parameterNode(it))}
            </div>
          )}
          <div className={styles.actionWrapper}>
            <Button color="green" content={t('action.save')} />
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  );
});

export default SelectOption;

SelectOption.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  item: PropTypes.object,
  payload: PropTypes.object.isRequired,
  onControl: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

SelectOption.defaultProps = {
  item: { controlDevice: undefined },
};
