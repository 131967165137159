import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Image, Label, Message } from 'semantic-ui-react';
import pickBy from 'lodash/pickBy';

import { Input } from '../../lib/custom-ui';
import { useForm } from '../../hooks';
import { usePopup } from '../../lib/popup';

import UserEmailEditStep from '../UserEmailEditStep';
import UserPasswordEditStep from '../UserPasswordEditStep';
import UserUsernameEditStep from '../UserUsernameEditStep';
import UserPermissionEditStepContainer from '../../containers/UserPermissionEditStepContainer';

import styles from './UserSettings.module.scss';
import { USER_PERMISSIONS } from '../../constants/Enums';

const createMessage = (error) => {
  if (!error) {
    return error;
  }

  switch (error.message) {
    case 'Email already in use':
      return {
        type: 'error',
        content: 'common.emailAlreadyInUse',
      };
    case 'Invalid current password':
      return {
        type: 'error',
        content: 'common.invalidCurrentPassword',
      };
    default:
      return {
        type: 'warning',
        content: 'common.unknownError',
      };
  }
};
const UserSettings = React.memo(
  ({
    defaultData,
    email,
    emailUpdateForm,
    passwordUpdateForm,
    username,
    usernameUpdateForm,
    permissions,
    isAdmin,
    group,
    onAvatarUpdate,
    onEmailUpdate,
    onEmailUpdateMessageDismiss,
    onPasswordUpdate,
    onPasswordUpdateMessageDismiss,
    onUpdate,
    onUsernameUpdate,
    onUsernameUpdateMessageDismiss,
  }) => {
    const [t] = useTranslation();

    const [data, handleFieldChange, setData] = useForm(() => ({
      avatarUrlUpdate: '',
      dropFile: '',
      name: '',
      organization: '',
      phone: '',
      ...pickBy(defaultData),
    }));

    const [errorMess, setErrorMess] = useState('');
    const [messageForm, setMessageForm] = useState();
    const emailField = useRef(null);
    const nameField = useRef(null);
    const UserEmailEditPopup = usePopup(UserEmailEditStep);
    const UserPasswordEditPopup = usePopup(UserPasswordEditStep);
    const UserUsernameEditPopup = usePopup(UserUsernameEditStep);
    const UserPermissionEditPopup = usePopup(UserPermissionEditStepContainer);
    const errorSub = useMemo(
      () => [emailUpdateForm.error, passwordUpdateForm.error],
      [emailUpdateForm, passwordUpdateForm],
    );

    useMemo(() => {
      setMessageForm('');
      const dataErr = errorSub.find((item) => {
        return item?.error !== null;
      });
      setMessageForm(createMessage(dataErr?.error));
    }, [errorSub]);

    const cleanData = useMemo(
      () => ({
        // ...data,
        name: data.name.trim(),
        phone: data.phone.trim() || null,
        organization: data.organization.trim() || null,
      }),
      [data],
    );

    const handleInputFileChange = useCallback(
      (e) => {
        setData({ ...data, dropFile: '', avatarUrlUpdate: '' });
        const file = e.target.files[0];

        if (!file) return;

        if (file.size > 1024 * 1024) {
          setErrorMess('Dung lượng file tối đa 1Mb.');
          return;
        }

        if (file.name.lastIndexOf('.jpeg') === -1 && file.name.lastIndexOf('.png') === -1) {
          setErrorMess('Chỉ chấp nhập định dạng JPEG, PNG.');
          return;
        }

        const reader = new FileReader();

        reader.onload = (event) => {
          setData({ ...data, dropFile: event.target.result, avatarUrlUpdate: file });
        };
        reader.readAsDataURL(e.target.files[0]);
      },
      [data, setData],
    );

    const handleSubmit = useCallback(() => {
      onUpdate(cleanData);
      if (data.avatarUrlUpdate) {
        onAvatarUpdate({ file: data.avatarUrlUpdate });
      }
    }, [onUpdate, data, cleanData, onAvatarUpdate]);

    useEffect(() => {
      setData((prev) => ({ ...prev, username, email }));
    }, [email, username, setData]);

    return (
      <div className={styles.wrapper}>
        <div className={styles.wrapperUser}>
          <h2 className={styles.title}>{t('common.myAccount')}</h2>
          <p className={styles.descriptionTitle}>{t('common.manageAccountProfileInformation')}</p>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column mobile={16} computer={8} largeScreen={8} widescreen={8}>
                <Form onSubmit={handleSubmit}>
                  <div className={styles.wrapperForm}>
                    <h4 className={styles.subTitle}>{t('menu.accountSettings')}</h4>

                    <Form.Field inline className={styles.formUserSub}>
                      <span>{t('common.username')}</span>
                      <Input
                        className={styles.inputUserFormSetting}
                        name="username"
                        // value={data.username}
                      />
                    </Form.Field>
                    <Form.Field inline className={styles.formUserSub}>
                      <span>{t('common.name')}</span>
                      <Input
                        className={styles.inputUserFormSetting}
                        name="name"
                        onChange={handleFieldChange}
                        ref={nameField}
                        value={data.name}
                      />
                    </Form.Field>
                    <Form.Field inline className={styles.formUserSub}>
                      <span>{t('common.phone')}</span>
                      <Input
                        className={styles.inputUserFormSetting}
                        name="phone"
                        onChange={handleFieldChange}
                        type="number"
                        value={data.phone}
                      />
                    </Form.Field>
                    <Form.Field inline className={styles.formUserSub}>
                      <span>{t('common.email')}</span>
                      <Input
                        className={styles.inputUserFormSetting}
                        name="email"
                        ref={emailField}
                        type="email"
                        value={data.email}
                      />
                    </Form.Field>
                    {/* <Form.Field inline className={styles.formUserSub}>
                      <span>{t('common.role')}</span>
                      <Input
                        className={styles.inputUserFormSetting}
                        name="isAdmin"
                        value={data.isAdmin ? 'Admin' : 'User'}
                      />
                    </Form.Field>
                    <Form.Field inline className={styles.formUserSub}>
                      <span>{t('common.organization')}</span>
                      <Input
                        className={styles.inputUserFormSetting}
                        name="organization"
                        onChange={handleFieldChange}
                        value={data.organization}
                      />
                    </Form.Field>
                    <Form.Field inline className={styles.formUserSub}>
                      <span>{t('common.position')}</span>
                      <Input
                        className={styles.inputUserFormSetting}
                        name="position"
                        onChange={handleFieldChange}
                        // value={data.position}
                      />
                    </Form.Field> */}
                    <Form.Field inline className={styles.formUserSub}>
                      <span>{t('common.userGroups')}</span>
                      <Input readOnly className={styles.inputUserFormSetting} value={group.name} />
                    </Form.Field>
                    <Form.Field inline className={styles.formUserSub}>
                      <span>{t('common.permissions')}</span>
                      <div className={styles.inputUserFormSetting}>
                        {permissions.length === 0 ? (
                          <span>{t('common.emptyPermission')}</span>
                        ) : (
                          permissions.map((it) => (
                            <Label
                              key={it}
                              color="teal"
                              size="large"
                              content={t(`permission.${USER_PERMISSIONS[it]}`)}
                            />
                          ))
                        )}
                      </div>
                    </Form.Field>
                    <Button className={styles.centerButton} content={t('action.saveChange')} />
                    <h4 className={styles.subTitle}>{t('common.securitySetting')}</h4>
                  </div>
                </Form>
              </Grid.Column>
              <Grid.Column mobile={16} computer={8} largeScreen={8} widescreen={8}>
                <div className={styles.middle}>
                  <div className={styles.wrapperForm}>
                    <div className={styles.sideUpload}>
                      <span className={styles.formUserSub}>{t('common.avatar')}</span>
                      <p>{t('common.avatarDescription')}</p>
                      <div className={styles.sideUploadWrapper}>
                        <input
                          name="dropFile"
                          id="selectFile"
                          type="file"
                          title=""
                          accept="image/png, image/jpeg"
                          onChange={handleInputFileChange}
                        />
                        <span>{t('common.dropFileToUpload')}</span>
                        {data.dropFile ? (
                          <Image
                            size="medium"
                            src={data.dropFile}
                            className={styles.avatarUpload}
                          />
                        ) : (
                          <Message
                            error
                            content={errorMess}
                            className={
                              errorMess ? styles.isErrorUploadFile : styles.errorUploadFile
                            }
                          />
                        )}
                        <label htmlFor="selectFile">
                          {data.dropFile === ''
                            ? t('common.selectFile')
                            : t('common.selectAnotherFile')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className={styles.wrapperForm}>
                  <div className={styles.action}>
                    <UserUsernameEditPopup
                      usePasswordConfirmation
                      defaultData={usernameUpdateForm.data}
                      username={username}
                      isSubmitting={usernameUpdateForm.isSubmitting}
                      error={usernameUpdateForm.error}
                      onUpdate={onUsernameUpdate}
                      onMessageDismiss={onUsernameUpdateMessageDismiss}
                    >
                      <Button className={styles.actionButton}>
                        {t('action.editUsername', {
                          context: 'title',
                        })}
                      </Button>
                    </UserUsernameEditPopup>
                  </div>
                  {isAdmin && (
                    <div className={styles.action}>
                      <UserPermissionEditPopup email={email} isCurrentUser={email}>
                        <Button className={styles.actionButton}>
                          {t('action.editPermissions', {
                            context: 'title',
                          })}
                        </Button>
                      </UserPermissionEditPopup>
                    </div>
                  )}
                  <div className={styles.action}>
                    <UserEmailEditPopup
                      usePasswordConfirmation
                      defaultData={emailUpdateForm.data}
                      email={email}
                      isSubmitting={emailUpdateForm.isSubmitting}
                      error={emailUpdateForm.error}
                      onUpdate={onEmailUpdate}
                      onMessageDismiss={onEmailUpdateMessageDismiss}
                    >
                      <Button className={styles.actionButton}>
                        {t('action.editEmail', {
                          context: 'title',
                        })}
                      </Button>
                    </UserEmailEditPopup>
                  </div>
                  <div className={styles.action}>
                    <UserPasswordEditPopup
                      usePasswordConfirmation
                      defaultData={passwordUpdateForm.data}
                      isSubmitting={passwordUpdateForm.isSubmitting}
                      error={passwordUpdateForm.error}
                      onUpdate={onPasswordUpdate}
                      onMessageDismiss={onPasswordUpdateMessageDismiss}
                    >
                      <Button className={styles.actionButton}>
                        {t('action.editPassword', {
                          context: 'title',
                        })}
                      </Button>
                    </UserPasswordEditPopup>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  },
);

UserSettings.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  defaultData: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  username: PropTypes.string,
  emailUpdateForm: PropTypes.object.isRequired,
  passwordUpdateForm: PropTypes.object.isRequired,
  usernameUpdateForm: PropTypes.object.isRequired,
  permissions: PropTypes.array,
  isAdmin: PropTypes.bool.isRequired,
  group: PropTypes.object.isRequired,
  onAvatarUpdate: PropTypes.func.isRequired,
  onEmailUpdate: PropTypes.func.isRequired,
  onEmailUpdateMessageDismiss: PropTypes.func.isRequired,
  onPasswordUpdate: PropTypes.func.isRequired,
  onPasswordUpdateMessageDismiss: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUsernameUpdate: PropTypes.func.isRequired,
  onUsernameUpdateMessageDismiss: PropTypes.func.isRequired,
};

UserSettings.defaultProps = {
  // defaultData: undefined,
  username: null,
  permissions: [],
};
export default UserSettings;
