const getLinkStream = (string) => {
  const start = string.indexOf('[');
  const end = string.indexOf(']');

  if (start === -1 || end === -1) {
    return string;
  }

  return string.slice(start + 1, end);
};
export default getLinkStream;
