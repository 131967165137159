import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* districtMembershipsWatchers() {
  yield all([
    takeEvery(EntryActionTypes.MEMBERSHIP_IN_CURRENT_DISTRICT_CREATE, ({ payload: { data } }) =>
      services.createMembershipInCurrentDistrict(data),
    ),
    takeEvery(
      EntryActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE,
      ({ payload: { districtMembership } }) =>
        services.handleDistrictMembershipCreate(districtMembership),
    ),
    takeEvery(EntryActionTypes.DISTRICT_MEMBERSHIP_UPDATE, ({ payload: { id, data } }) =>
      services.updateDistrictMembership(id, data),
    ),
    takeEvery(
      EntryActionTypes.DISTRICT_MEMBERSHIP_UPDATE_HANDLE,
      ({ payload: { districtMembership } }) =>
        services.handleDistrictMembershipUpdate(districtMembership),
    ),
    takeEvery(EntryActionTypes.DISTRICT_MEMBERSHIP_DELETE, ({ payload: { id } }) =>
      services.deleteDistrictMembership(id),
    ),
    takeEvery(
      EntryActionTypes.DISTRICT_MEMBERSHIP_DELETE_HANDLE,
      ({ payload: { districtMembership } }) =>
        services.handleDistrictMembershipDelete(districtMembership),
    ),
  ]);
}
