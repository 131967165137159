import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* provinceManagersWatchers() {
  yield all([
    takeEvery(EntryActionTypes.MANAGER_IN_CURRENT_PROVINCE_CREATE, ({ payload: { data } }) =>
      services.createManagerInCurrentProvince(data),
    ),
    takeEvery(EntryActionTypes.PROVINCE_MANAGER_CREATE_HANDLE, ({ payload: { provinceManager } }) =>
      services.handleProvinceManagerCreate(provinceManager),
    ),
    takeEvery(EntryActionTypes.PROVINCE_MANAGER_DELETE, ({ payload: { id } }) =>
      services.deleteProvinceManager(id),
    ),
    takeEvery(EntryActionTypes.PROVINCE_MANAGER_DELETE_HANDLE, ({ payload: { provinceManager } }) =>
      services.handleProvinceManagerDelete(provinceManager),
    ),
  ]);
}
