import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Form, Message, Modal } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { usePrevious } from '../../lib/hooks';
import { Input } from '../../lib/custom-ui';
import { useForm } from '../../hooks';
import { USER_PERMISSIONS } from '../../constants/Enums';

import styles from './UserGroupAddModal.module.scss';

const createMessage = (error) => {
  if (!error || isEmpty(error)) {
    return error;
  }

  switch (error.message) {
    case 'Empty permissions':
      return {
        type: 'error',
        content: 'common.emptyPermission',
      };
    default:
      return {
        type: 'warning',
        content: 'common.unknownError',
      };
  }
};

const UserGroupAddModal = React.memo(
  ({
    defaultData,
    isSubmitting,
    isEditing,
    userGroupId,
    error,
    onCreate,
    onUpdate,
    onMessageDismiss,
    onClose,
  }) => {
    const [t] = useTranslation();

    const [data, handleFieldChange] = useForm(() => ({
      name: '',
      permissions: [],
      ...defaultData,
    }));

    const [emptyPermission, setEmptyPermission] = useState();

    const message = useMemo(() => {
      if (error) {
        return createMessage(error);
      }

      if (emptyPermission) {
        return createMessage(emptyPermission);
      }

      return null;
    }, [error, emptyPermission]);

    const nameField = useRef(null);

    const handleSubmit = useCallback(() => {
      const cleanData = {
        ...data,
        name: data.name.trim(),
        permissions: data.permissions.sort(),
      };

      if (!cleanData.name) {
        nameField.current.select();
        return;
      }

      if (isEmpty(cleanData.permissions)) {
        setEmptyPermission({ message: 'Empty permissions' });
        return;
      }

      if (isEditing) {
        onUpdate(cleanData.id, cleanData);
      } else {
        onCreate(cleanData);
      }
      setEmptyPermission();
    }, [data, isEditing, onCreate, onUpdate]);

    const handleSelectPermission = useCallback(
      (_, { checked, value }) => {
        const permissions = checked
          ? [...data.permissions, value]
          : data.permissions.filter((it) => it !== value);

        if (permissions.length > 0) {
          setEmptyPermission();
        }

        handleFieldChange(_, { name: 'permissions', value: permissions });
      },
      [data.permissions, handleFieldChange],
    );

    const handleClearMessage = useCallback(() => {
      if (error) {
        onMessageDismiss();
        return;
      }

      if (emptyPermission) {
        setEmptyPermission();
      }
    }, [emptyPermission, error, onMessageDismiss]);

    useEffect(() => {
      nameField.current.focus({
        preventScroll: true,
      });
    }, []);

    useEffect(() => {
      if (userGroupId) {
        onClose();
        onMessageDismiss();
      }
    }, [userGroupId, onClose, onMessageDismiss]);

    return (
      <Modal open size="tiny" closeIcon onClose={onClose}>
        <Modal.Header>
          {t(isEditing ? 'common.editUserGroup' : 'common.addUserGroup', {
            context: 'title',
          })}
        </Modal.Header>
        <Modal.Content size="large">
          {message && (
            <Message
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...{
                [message.type]: true,
              }}
              visible
              content={t(message.content)}
              onDismiss={handleClearMessage}
            />
          )}
          <Form onSubmit={handleSubmit}>
            <div className={styles.text}>{t('common.name')}</div>
            <Input
              fluid
              ref={nameField}
              name="name"
              value={data.name}
              readOnly={isSubmitting}
              className={styles.field}
              onChange={handleFieldChange}
            />
            <div className={styles.text}>{t('common.selectPermissions')}</div>
            <Form.Group grouped>
              <div className={styles.wrapperSelectPermission}>
                {Object.keys(USER_PERMISSIONS).map((per) => (
                  <Checkbox
                    key={per}
                    checked={data.permissions.includes(per)}
                    label={t(`permission.${USER_PERMISSIONS[per]}`)}
                    value={per}
                    onChange={handleSelectPermission}
                  />
                ))}
              </div>
            </Form.Group>
            <Button
              positive
              content={t(isEditing ? 'action.save' : 'action.create')}
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </Form>
        </Modal.Content>
      </Modal>
    );
  },
);

UserGroupAddModal.propTypes = {
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  userGroupId: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  error: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onMessageDismiss: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

UserGroupAddModal.defaultProps = {
  error: undefined,
  userGroupId: undefined,
};

export default UserGroupAddModal;
