import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* coreWatchers() {
  yield all([
    takeEvery(EntryActionTypes.CORE_INITIALIZE, () => services.initializeCore()),
    takeEvery(EntryActionTypes.LOGOUT, () => services.logout()),
    takeEvery(EntryActionTypes.SIDEBAR_MENU, () => services.changeSidebarMenu()),
    takeEvery(EntryActionTypes.SELECT_CONTENT_ID, ({ payload: { id } }) =>
      services.selectContentId(id),
    ),
  ]);
}
