import { call, select } from 'redux-saga/effects';

import request from '../request';
import selectors from '../../../selectors';
import api from '../../../api';
import Paths from '../../../constants/Paths';

export function* fetchContentByCurrentPath() {
  const pathsMatch = yield select(selectors.selectPathsMatch);

  let contents;
  let users;
  let attachments;
  let contentCategories;

  if (pathsMatch) {
    let provinceId;
    if (pathsMatch.pattern.path === Paths.CONTENTS) {
      provinceId = pathsMatch.params.id;
    }

    if (provinceId) {
      ({
        items: contents,
        included: { users, attachments, contentCategories },
      } = yield call(request, api.getContents, provinceId, 1, 20));
    }
  }

  return {
    contents,
    users,
    attachments,
    contentCategories,
  };
}

export default {
  fetchContentByCurrentPath,
};
