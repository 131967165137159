import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Accordion, Form, Input, Label } from 'semantic-ui-react';

import { useToggle } from '../../lib/hooks';

import styles from './MoreInformation.module.scss';

const deviceOptions = [
  { name: 'MayTinh', title: 'Máy tính' },
  { name: 'MayQuayPhim', title: 'Máy quay phim' },
  { name: 'MayThuAmKyThuatSo', title: 'Máy thu âm kỹ thuật số' },
  { name: 'MayAnh', title: 'Máy ảnh' },
  { name: 'PhanMemBienTapChuongTrinhTruyenHinh', title: 'Phần mềm biên tập CT truyền hình' },
  { name: 'PhanMemBienTapChuongTrinhPhatThanh', title: 'Phần mềm biên tập CT phát thanh' },
];

const workforceDistrictOptions = [
  { name: 'SanXuatNoiDung', title: 'Thực hiện sản xuất nội dung' },
  { name: 'KyThuat', title: 'Thực hiện công việc liên quan đến kỹ thuật' },
  { name: 'CongViecKhac', title: 'Làm công việc khác' },
];

const workforceWardOptions = [
  { name: 'CongChucVanHoaXaHoi', title: 'Công chức văn hóa - xã hội' },
  { name: 'CongChucKhac', title: 'Công chức khác' },
  { name: 'NguoiHoatDongKhongChuyenTrach', title: 'Người hoạt động không chuyên trách' },
  { name: 'TuyenTruyenVienCoSo', title: 'Tuyên truyền viên cơ sở' },
];

const contentOptions = [
  // { name: 'PhatThanh', title: 'CT phát thanh' },
  { name: 'TruyenHinh', title: 'CT truyền hình' },
  { name: 'TruyenThongDaPhuongTien', title: 'Sản phẩm truyền thông đa phương tiện' },
];

const MoreInformation = React.memo(({ unit, data, onChange }) => {
  const [isActive, handleToggle] = useToggle();

  const deviceNodes = deviceOptions.map((device) => (
    <Form.Field
      control={Input}
      size="mini"
      type="number"
      label={device.title}
      key={device.name}
      fluid
      name={device.name}
      value={data.ThietBiCongNghe?.[device.name]}
      className={styles.field}
      onChange={(_, { name, value }) => onChange('ThietBiCongNghe', name, value)}
    />
  ));

  const workforceDistrictNodes = workforceDistrictOptions.map((member) => (
    <Form.Field
      control={Input}
      size="mini"
      type="number"
      label={member.title}
      key={member.name}
      fluid
      name={member.name}
      value={data.NhanLuc?.[member.name]}
      className={styles.field}
      onChange={(_, { name, value }) => onChange('NhanLuc', name, value)}
    />
  ));

  const workforceWardNodes = workforceWardOptions.map((member) => (
    <Form.Field
      control={Input}
      size="mini"
      type="number"
      label={member.title}
      key={member.name}
      fluid
      name={member.name}
      value={data.NhanLuc?.[member.name]}
      className={styles.field}
      onChange={(_, { name, value }) => onChange('NhanLuc', name, value)}
    />
  ));

  const contentNodes = contentOptions.map((content) => (
    <Form.Field
      control={Input}
      size="mini"
      type="number"
      label={content.title}
      key={content.name}
      fluid
      name={content.name}
      value={data.ChuongTrinhTuSanXuat?.[content.name]}
      className={styles.field}
      onChange={(_, { name, value }) => onChange('ChuongTrinhTuSanXuat', name, value)}
    />
  ));

  const panels1 = [
    {
      key: 'devices',
      title: {
        content: <Label basic color="teal" content="Thiết bị, công nghệ sản xuất nội dung" />,
      },
      content: { content: deviceNodes },
    },
    {
      key: 'contents',
      title: {
        content: <Label basic color="teal" content="Nội dung chương trình tự sản xuất" />,
      },
      content: { content: contentNodes },
    },
  ];

  const panels2 = [
    {
      key: 'members',
      title: {
        content: <Label basic color="teal" content="Nhân lực" />,
      },
      content: { content: unit === 'DISTRICT' ? workforceDistrictNodes : workforceWardNodes },
    },
    {
      key: 'website',
      title: {
        content: <Label basic color="teal" content="Cổng thông tin điện tử" />,
      },
      content: {
        content: (
          <Form.Field
            control={Input}
            type="number"
            size="mini"
            min={0}
            max={1}
            fluid
            name="TrangThongTinDienTu"
            value={data.TrangThongTinDienTu}
            className={styles.field}
            onChange={(_, { name, value }) => onChange('TrangThongTinDienTu', name, value)}
          />
        ),
      },
    },
    {
      key: 'noBusinessDocuments',
      title: {
        content: <Label basic color="teal" content="Tài liệu không kinh doanh" />,
      },
      content: {
        content: (
          <Form.Field
            control={Input}
            type="number"
            size="mini"
            min={0}
            fluid
            name="TaiLieuKhongKinhDoanh"
            value={data.TaiLieuKhongKinhDoanh}
            className={styles.field}
            onChange={(_, { name, value }) => onChange('TaiLieuKhongKinhDoanh', name, value)}
          />
        ),
      },
    },
    {
      key: 'newsletter',
      title: {
        content: <Label basic color="teal" content="Bản tin giấy" />,
      },
      content: {
        content: (
          <Form.Field
            control={Input}
            type="number"
            size="mini"
            min={0}
            fluid
            name="BanTinGiay"
            value={data.BanTinGiay}
            className={styles.field}
            onChange={(_, { name, value }) => onChange('BanTinGiay', name, value)}
          />
        ),
      },
    },
  ];

  const panels3 = [
    {
      key: 'fmStation',
      title: {
        content: <Label basic color="teal" content="Đài có dây/không dây FM" />,
      },
      content: {
        content: (
          <Form.Field
            control={Input}
            type="number"
            size="mini"
            min={0}
            max={1}
            fluid
            name="DaiFM"
            value={data.DaiFM}
            className={styles.field}
            onChange={(_, { name, value }) => onChange('DaiFM', name, value)}
          />
        ),
      },
    },
  ];

  return (
    <Accordion>
      <Accordion.Title
        active={isActive}
        onClick={handleToggle}
        className={classNames(styles.title, isActive && styles.active)}
      >
        <Label color="teal" content="Thêm thông tin" />
      </Accordion.Title>
      <Accordion.Content active={isActive} className={styles.content}>
        <Accordion.Accordion
          exclusive={false}
          panels={unit === 'DISTRICT' ? [...panels1, ...panels2] : [...panels2, ...panels3]}
          className={styles.accordion}
        />
      </Accordion.Content>
    </Accordion>
  );
});

export default MoreInformation;

MoreInformation.propTypes = {
  unit: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func.isRequired,
};
