import { connect } from 'react-redux';

import selectors from '../selectors';
import Static from '../components/Static';

const mapStateToProps = (state) => {
  const { deviceId, provinceId } = selectors.selectPath(state);
  const currentDistrict = selectors.selectCurrentDistrict(state);
  const {
    pattern: { path },
  } = selectors.selectPathsMatch(state);
  const sidebarMenu = selectors.selectSidebarMenu(state);
  const isDarkMode = selectors.selectIsDarkMode(state);
  const { level } = selectors.selectCurrentUser(state);

  return {
    provinceId,
    deviceId,
    district: currentDistrict,
    path,
    sidebarMenu,
    isDarkMode,
    level,
  };
};

export default connect(mapStateToProps)(Static);
