import * as React from 'react';
import { Icon, Label, List } from 'semantic-ui-react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TreeView from './TreeView';

import styles from './DropdownCategory.module.scss';

const createDataTree = (dataset) => {
  const hashTable = Object.create(null);
  const dataTree = [];

  dataset.forEach((aData, i) => (hashTable[aData.id] = { ...aData, childNodes: [] })); // eslint-disable-line no-return-assign

  dataset.forEach((aData) => {
    if (aData.parentId && hashTable[aData.parentId])
      hashTable[aData.parentId].childNodes.push(hashTable[aData.id]);
    else dataTree.push(hashTable[aData.id]);
  });

  return dataTree;
};

const addCategory = (prev, data, categoryId) => {
  const index = data.map(({ id }) => id).indexOf(categoryId);

  prev.splice(
    index,
    0,
    data.find(({ id }) => id === categoryId),
  );
  return prev;
};

const filterCategories = (categories, selectedCategory) => {
  if (selectedCategory.length <= 0) return categories;

  const inheritCategories = [...categories];

  selectedCategory.forEach((selected) => {
    const existed = inheritCategories.map(({ id }) => id).indexOf(selected);
    inheritCategories.splice(existed, 1);
  });
  return inheritCategories;
};

const DropdownCategory = React.memo(({ cateIds, allCategories, onSelected }) => {
  const [t] = useTranslation();

  const [search, setSearch] = React.useState('');
  const [categoriesSelected, setCategoriesSelected] = React.useState(
    cateIds.map((id) => allCategories.find((cate) => cate.id === id)),
  );
  const [categoryFilter, setCategoryFilter] = React.useState(
    filterCategories(allCategories, cateIds),
  );
  const [resultsSearch, setResultsSearch] = React.useState([]);
  const [isShowResults, setIsShowResults] = React.useState(false);
  const timeoutRef = React.useRef(null);
  const containerRef = React.useRef(null);
  const inputRef = React.useRef(null);

  const handleOnChange = React.useCallback(
    (event) => {
      const { value } = event.target;
      clearTimeout(timeoutRef.current);
      setIsShowResults(Boolean(value));
      setSearch(value);

      if (!value) {
        setResultsSearch([]);
        setIsShowResults(true);
        return;
      }

      timeoutRef.current = setTimeout(() => {
        const re = new RegExp(_.escapeRegExp(value), 'i');
        const isMatch = (result) => re.test(result.name);
        setResultsSearch(categoryFilter.filter(isMatch));
      }, 300);
    },
    [categoryFilter],
  );

  const handleOnResultSelect = React.useCallback(
    (cate, type) => {
      setCategoriesSelected((prev) => [...prev, cate]);
      onSelected([...categoriesSelected.map(({ id }) => id), cate.id]);
      setCategoryFilter((prev) => prev.filter(({ id }) => id !== cate.id));
      setIsShowResults(false);

      if (type) {
        setResultsSearch((prev) =>
          prev.length > 0 ? prev.filter(({ id }) => id !== cate.id) : [],
        );
        setSearch('');
      }
    },
    [categoriesSelected, onSelected],
  );

  const handleClearLabel = React.useCallback(
    (categoryId) => {
      const filters = categoriesSelected.filter((it) => it.id !== categoryId);

      setCategoriesSelected(filters);
      setCategoryFilter((prev) => addCategory(prev, allCategories, categoryId));
      onSelected(filters.map(({ id }) => id));
    },
    [allCategories, categoriesSelected, onSelected],
  );

  const handleClickFocusCategoryField = React.useCallback(() => {
    inputRef.current.focus();
  }, []);

  React.useEffect(() => {
    const handlerMouseDown = (e) => {
      setIsShowResults(containerRef.current.contains(e.target));
    };

    document.addEventListener('mousedown', handlerMouseDown);

    return () => document.removeEventListener('mousedown', handlerMouseDown);
  }, []);

  const labelsNode = categoriesSelected.map((it) => (
    <Label key={it.id} as="a" className={styles.labelSelected}>
      {it.name}
      <Icon name="delete" onClick={() => handleClearLabel(it.id)} />
    </Label>
  ));

  const resultsSearchNode = resultsSearch.map((it) => (
    <List.Item
      key={it.id}
      as="a"
      className={styles.searchItem}
      onClick={() => handleOnResultSelect({ name: it.name, id: it.id }, 'search')}
    >
      <List.Content>{it.name}</List.Content>
    </List.Item>
  ));

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>{t('common.category')}</div>
      <div
        ref={containerRef}
        id="categories-list"
        role="presentation"
        className={styles.comboBox}
        onClick={handleClickFocusCategoryField}
      >
        {categoriesSelected.length > 0 && labelsNode}
        <input
          ref={inputRef}
          className={styles.input}
          autoComplete="off"
          type="text"
          style={{
            width: 100 + (search ? search.length * 4 : 0),
          }}
          placeholder={t('common.searchCategory')}
          icon="search"
          value={search}
          onChange={handleOnChange}
        />
        <Icon className={styles.dropdown} name="dropdown" />
        {isShowResults && (
          <div className={styles.results}>
            {search ? ( // eslint-disable-line no-nested-ternary
              <List>
                {resultsSearch.length > 0 ? (
                  resultsSearchNode
                ) : (
                  <span style={{ fontWeight: 'bold' }}>{t('common.noResultsFound')}</span>
                )}
              </List>
            ) : categoryFilter.length > 0 ? (
              <TreeView data={createDataTree(categoryFilter)} onSelect={handleOnResultSelect} />
            ) : (
              <span style={{ fontWeight: 'bold' }}>{t('common.noResultsFound')}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

DropdownCategory.propTypes = {
  allCategories: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  cateIds: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onSelected: PropTypes.func.isRequired,
};

export default DropdownCategory;
