import React, { useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Label, Modal } from 'semantic-ui-react';

import styles from './WarningChangeAttachment.module.scss';

const INITITAL = {
  attachment: null,
  updateAttachment: null,
  onUpdate: () => {},
};

const WarningChangeAttachment = React.forwardRef((props, ref) => {
  const [t] = useTranslation();

  const [actions, setActions] = useState(INITITAL);

  const handleWarningChangeAttachment = (action) => {
    setActions({ ...actions, ...action });
  };

  const handleUpdateAttachment = () => {
    actions.onUpdate();
    setActions(INITITAL);
  };

  const handleClose = () => setActions(INITITAL);

  useImperativeHandle(ref, () => ({ handleWarningChangeAttachment }));

  if (!actions.attachment) return null;

  return (
    <Modal open size="tiny" onClose={handleClose}>
      <Modal.Content>
        <h4>{t('common.areYouSureYouWantToReplaceThisFile')}</h4>
        <div className={styles.content}>
          <Label basic color="red" content={actions.attachment.name} />
          <div className={styles.arrowDown} />
          <Label basic color="blue" content={actions.updateAttachment.name} />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button content={t('action.cancel')} onClick={handleClose} />
        <Button content={t('action.save')} onClick={handleUpdateAttachment} />
      </Modal.Actions>
    </Modal>
  );
});

export default WarningChangeAttachment;

WarningChangeAttachment.propTypes = {};
