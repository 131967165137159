import ActionTypes from '../constants/ActionTypes';

const createDistrictMembership = (districtMembership) => ({
  type: ActionTypes.DISTRICT_MEMBERSHIP_CREATE,
  payload: {
    districtMembership,
  },
});

createDistrictMembership.success = (localId, districtMembership) => ({
  type: ActionTypes.DISTRICT_MEMBERSHIP_CREATE__SUCCESS,
  payload: {
    localId,
    districtMembership,
  },
});

createDistrictMembership.failure = (localId, error) => ({
  type: ActionTypes.DISTRICT_MEMBERSHIP_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleDistrictMembershipCreate = (
  districtMembership,
  province,
  district,
  users,
  provinceManagers,
  districts,
  districtMemberships,
  contents,
  stations,
  devices,
  deviceMemberships,
  deviceContents,
  tasks,
  attachments,
  deletedNotifications,
) => ({
  type: ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE,
  payload: {
    districtMembership,
    province,
    district,
    users,
    provinceManagers,
    districts,
    districtMemberships,
    contents,
    stations,
    devices,
    deviceMemberships,
    deviceContents,
    tasks,
    attachments,
    deletedNotifications,
  },
});

handleDistrictMembershipCreate.fetchProvince = (id, currentUserId, currentDistrictId) => ({
  type: ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE__PROVINCE_FETCH,
  payload: {
    id,
    currentUserId,
    currentDistrictId,
  },
});

const updateDistrictMembership = (id, data) => ({
  type: ActionTypes.DISTRICT_MEMBERSHIP_UPDATE,
  payload: {
    id,
    data,
  },
});

updateDistrictMembership.success = (districtMembership) => ({
  type: ActionTypes.DISTRICT_MEMBERSHIP_UPDATE__SUCCESS,
  payload: {
    districtMembership,
  },
});

updateDistrictMembership.failure = (id, error) => ({
  type: ActionTypes.DISTRICT_MEMBERSHIP_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleDistrictMembershipUpdate = (districtMembership) => ({
  type: ActionTypes.DISTRICT_MEMBERSHIP_UPDATE_HANDLE,
  payload: {
    districtMembership,
  },
});

const deleteDistrictMembership = (id) => ({
  type: ActionTypes.DISTRICT_MEMBERSHIP_DELETE,
  payload: {
    id,
  },
});

deleteDistrictMembership.success = (districtMembership) => ({
  type: ActionTypes.DISTRICT_MEMBERSHIP_DELETE__SUCCESS,
  payload: {
    districtMembership,
  },
});

deleteDistrictMembership.failure = (id, error) => ({
  type: ActionTypes.DISTRICT_MEMBERSHIP_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleDistrictMembershipDelete = (districtMembership) => ({
  type: ActionTypes.DISTRICT_MEMBERSHIP_DELETE_HANDLE,
  payload: {
    districtMembership,
  },
});

export default {
  createDistrictMembership,
  handleDistrictMembershipCreate,
  updateDistrictMembership,
  handleDistrictMembershipUpdate,
  deleteDistrictMembership,
  handleDistrictMembershipDelete,
};
