import { attr, many } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';
import { ProvinceBackgroundTypes, TotalPageKeys } from '../constants/Enums';

const DEFAULT_DELETE_FORM = {
  data: {},
  error: null,
};

export default class extends BaseModel {
  static modelName = 'Province';

  static fields = {
    id: attr(),
    name: attr(),
    slug: attr(),
    background: attr(),
    backgroundImage: attr(),
    stationTotal: attr({
      getDefault: () => 0,
    }),
    contentTotal: attr({
      getDefault: () => 0,
    }),
    scheduleTotal: attr({
      getDefault: () => 0,
    }),
    deviceTotal: attr({
      getDefault: () => 0,
    }),
    contentBroadcastedTotal: attr({
      getDefault: () => 0,
    }),
    userGroupTotal: attr({
      getDefault: () => 0,
    }),
    actionTotal: attr({
      getDefault: () => 0,
    }),
    unreadTotal: attr({
      getDefault: () => 0,
    }),
    notificationTotal: attr({
      getDefault: () => 0,
    }),
    notificationType: attr({
      getDefault: () => 'unread',
    }),
    isBackgroundImageUpdating: attr({
      getDefault: () => false,
    }),
    isDevicesFetching: attr({
      getDefault: () => null,
    }),
    isContentsFetching: attr({
      getDefault: () => null,
    }),
    isSchedulesFetching: attr({
      getDefault: () => null,
    }),
    isStationsFetching: attr({
      getDefault: () => null,
    }),
    isReportsFetching: attr({
      getDefault: () => null,
    }),
    isUserGroupsFetching: attr({
      getDefault: () => null,
    }),
    deleteForm: attr({
      getDefault: () => DEFAULT_DELETE_FORM,
    }),
    managerUsers: many({
      to: 'User',
      through: 'ProvinceManager',
      relatedName: 'provinces',
    }),
  };

  static reducer({ type, payload }, Province) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
        {
          if (payload.provinces) {
            payload.provinces.forEach((province) => {
              Province.upsert(province);
            });
          }

          const provinceModel = Province.first();

          if (provinceModel) {
            provinceModel.update({
              isReportsFetching: null,
            });
          }

          if (payload.province) {
            const proModel = Province.withId(payload.province.id);

            if (proModel) {
              proModel.update({
                ...payload.province,
              });
            }
          }
        }
        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__DEVICES_FETCH:
      case ActionTypes.DEVICES_FETCH:
        Province.withId(payload.provinceId).update({
          isDevicesFetching: true,
        });

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__CONTENTS_FETCH:
      case ActionTypes.CONTENTS_FETCH:
        Province.withId(payload.provinceId).update({
          isContentsFetching: true,
        });

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__SCHEDULES_FETCH:
      case ActionTypes.SCHEDULES_FETCH:
        Province.withId(payload.provinceId).update({
          isSchedulesFetching: true,
        });

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__STATIONS_FETCH:
      case ActionTypes.STATIONS_FETCH:
        Province.withId(payload.provinceId).update({
          isStationsFetching: true,
        });

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__REPORTS_FETCH:
      case ActionTypes.REPORTS_FETCH:
        Province.withId(payload.provinceId).update({
          isReportsFetching: true,
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Province.all().delete();

        payload.provinces.forEach((province) => {
          Province.upsert(province);
          // if (payload.totals) {
          //   Province.withId(province.id).update({
          //     contentTotal: payload.totals.contentTotal,
          //     stationTotal: payload.totals.stationTotal,
          //   });
          // }
        });
        if (payload.province) {
          Province.upsert(payload.province);
        }

        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.DISTRICT_FETCH__SUCCESS:
        payload.provinces.forEach((province) => {
          Province.upsert(province);
        });

        if (payload.province) {
          Province.upsert(payload.province);
        }

        break;
      case ActionTypes.PROVINCE_CREATE__SUCCESS:
      case ActionTypes.PROVINCE_CREATE_HANDLE:
      case ActionTypes.PROVINCE_UPDATE__SUCCESS:
      case ActionTypes.PROVINCE_UPDATE_HANDLE:
        Province.upsert(payload.province);

        break;
      case ActionTypes.PROVINCE_UPDATE: {
        const province = Province.withId(payload.id);
        province.update(payload.data);

        if (
          payload.data.backgroundImage === null &&
          province.background &&
          province.background.type === ProvinceBackgroundTypes.IMAGE
        ) {
          province.background = null;
        }

        break;
      }
      case ActionTypes.PROVINCE_BACKGROUND_IMAGE_UPDATE:
        Province.withId(payload.id).update({
          isBackgroundImageUpdating: true,
        });

        break;
      case ActionTypes.PROVINCE_BACKGROUND_IMAGE_UPDATE__SUCCESS:
        Province.withId(payload.province.id).update({
          ...payload.province,
          isBackgroundImageUpdating: false,
        });

        break;
      case ActionTypes.PROVINCE_BACKGROUND_IMAGE_UPDATE__FAILURE:
        Province.withId(payload.id).update({
          isBackgroundImageUpdating: false,
        });

        break;
      case ActionTypes.PROVINCE_DELETE:
        // Province.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.PROVINCE_DELETE__SUCCESS:
      case ActionTypes.PROVINCE_DELETE_HANDLE: {
        const provinceModel = Province.withId(payload.province.id);

        if (provinceModel) {
          provinceModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.PROVINCE_DELETE__FAILURE: {
        const provinceModel = Province.withId(payload.id);

        provinceModel.update({
          deleteForm: {
            ...provinceModel.deleteForm,
            error: payload.error,
          },
        });

        break;
      }
      case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE:
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE:
        if (payload.province) {
          const provinceModel = Province.withId(payload.province.id);

          if (provinceModel) {
            provinceModel.deleteWithRelated();
          }

          Province.upsert(payload.province);
        }

        break;
      case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE__PROVINCE_FETCH:
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE__PROVINCE_FETCH: {
        const provinceModel = Province.withId(payload.id);

        if (provinceModel) {
          provinceModel.districts.toModelArray().forEach((districtModel) => {
            if (districtModel.id !== payload.currentDistrictId) {
              districtModel.update({
                isFetching: null,
              });

              districtModel.deleteRelated(payload.currentUserId);
            }
          });
        }

        break;
      }
      case ActionTypes.USER_GROUPS_FETCH__SUCCESS:
      case ActionTypes.CONTENTS_FETCH__SUCCESS:
      case ActionTypes.DEVICES_FETCH__SUCCESS:
      case ActionTypes.ACTIONS_FETCH__SUCCESS:
      case ActionTypes.STATIONS_FETCH__SUCCESS: {
        const provinceModel = Province.withId(payload.provinceId);

        let currentKey;

        Object.keys(TotalPageKeys).forEach((key) => {
          if (payload[key]) {
            currentKey = TotalPageKeys[key];
          }
        });

        if (provinceModel) {
          provinceModel.update({
            [currentKey]: payload.total,
          });
        }

        break;
      }
      case ActionTypes.CONTENT_CREATE__SUCCESS: {
        const provinceModel = Province.withId(payload.content.provinceId);

        if (provinceModel) {
          provinceModel.update({
            contentTotal: provinceModel.ref.contentTotal + 1,
          });
        }
        break;
      }
      case ActionTypes.DEVICE_CREATE__SUCCESS: {
        const provinceModel = Province.withId(payload.device.provinceId);

        if (provinceModel) {
          provinceModel.update({
            deviceTotal: provinceModel.ref.deviceTotal + 1,
          });
        }
        break;
      }
      case ActionTypes.STATION_CREATE__SUCCESS: {
        const provinceModel = Province.withId(payload.station.provinceId);

        if (provinceModel) {
          provinceModel.update({
            stationTotal: provinceModel.ref.stationTotal + 1,
          });
        }
        break;
      }

      case ActionTypes.USER_GROUP_CREATE__SUCCESS: {
        const provinceModel = Province.withId(payload.provinceId);

        if (provinceModel) {
          provinceModel.update({
            userGroupTotal: provinceModel.ref.userGroupTotal + 1,
          });
        }

        break;
      }

      case ActionTypes.CONTENT_DELETE__SUCCESS: {
        const provinceModel = Province.withId(payload.content.provinceId);

        if (provinceModel) {
          provinceModel.update({
            contentTotal: provinceModel.ref.contentTotal - 1,
          });
        }
        break;
      }
      case ActionTypes.DEVICE_DELETE__SUCCESS: {
        const provinceModel = Province.withId(payload.device.provinceId);

        if (provinceModel) {
          provinceModel.update({
            deviceTotal: provinceModel.ref.deviceTotal - 1,
          });
        }
        break;
      }
      case ActionTypes.STATION_DELETE__SUCCESS: {
        const provinceModel = Province.withId(payload.station.provinceId);

        if (provinceModel) {
          provinceModel.update({
            stationTotal: provinceModel.ref.stationTotal - 1,
          });
        }
        break;
      }

      case ActionTypes.USER_GROUP_DELETE__SUCCESS: {
        const provinceModel = Province.withId(payload.provinceId);

        if (provinceModel) {
          provinceModel.update({
            userGroupTotal: provinceModel.ref.userGroupTotal - 1,
          });
        }

        break;
      }

      case ActionTypes.CONTENT_FETCH_STATIONS_BROADCASTED__SUCCESS:
        {
          const provinceModel = Province.withId(payload.content.provinceId);

          if (provinceModel) {
            provinceModel.update({
              contentBroadcastedTotal: payload.total,
            });
          }
        }

        break;
      case ActionTypes.NOTIFICATION_CREATE_HANDLE:
        {
          const provinceModel = Province.first();

          if (provinceModel) {
            provinceModel.update({
              unreadTotal: provinceModel.unreadTotal + 1,
            });
          }
        }

        break;
      case ActionTypes.NOTIFICATION_UPDATE__SUCCESS: {
        const provinceModel = Province.first();

        if (provinceModel) {
          provinceModel.update({
            unreadTotal: provinceModel.unreadTotal - 1,
          });
        }

        break;
      }
      case ActionTypes.NOTIFICATION_DELETE__SUCCESS: {
        // case ActionTypes.NOTIFICATION_DELETE_HANDLE:
        const provinceModel = Province.first();

        if (provinceModel && !payload.notification.isRead) {
          provinceModel.update({
            unreadTotal: provinceModel.unreadTotal - 1,
          });
        }

        break;
      }
      case ActionTypes.NOTIFICATION_FETCH__SUCCESS: {
        const provinceModel = Province.first();

        if (provinceModel) {
          provinceModel.update({
            notificationTotal: payload.total,
            notificationType: payload.type,
          });
        }

        break;
      }
      case ActionTypes.ENABLE_CONTENTS_FETCHING: {
        const provinceModel = Province.first();

        if (provinceModel) {
          provinceModel.update({
            isContentsFetching: null,
          });
        }

        break;
      }
      default:
    }
  }

  getOrderedManagersQuerySet() {
    return this.managers.orderBy('id');
  }

  getOrderedDistrictsQuerySet() {
    return this.districts.orderBy('position');
  }

  getOrderedWardsQuerySet() {
    return this.wards.orderBy('id');
  }

  getOrderedHamletsQuerySet() {
    return this.hamlets.orderBy('id');
  }

  getOrderedCategoriesQuerySet() {
    return this.categories.orderBy('position');
  }

  getOrderedContentsQuerySet() {
    return this.contents.orderBy('createdAt', false);
    // return this.contents.orderBy();
  }

  getOrderedSchedulesQuerySet() {
    return this.schedules.orderBy('start');
  }

  getOrderedStationsQuerySet() {
    return this.stations.orderBy('position');
  }

  getOrderedUserGroupsQuerySet() {
    return this.userGroups.orderBy('createdAt', false);
  }

  getOrderedDistrictsModelArrayForUser(userId) {
    return this.getOrderedDistrictsQuerySet()
      .toModelArray()
      .filter((districtModel) => districtModel.hasMembershipForUser(userId));
  }

  getOrderedWardsModelArrayForUser(userId) {
    return this.getOrderedWardsQuerySet().toModelArray();
  }

  getOrderedHamletsModelArrayForUser(userId) {
    return this.getOrderedHamletsQuerySet().toModelArray();
  }

  getOrderedDistrictsModelArrayAvailableForUser(userId) {
    if (this.hasManagerForUser(userId)) {
      return this.getOrderedDistrictsQuerySet().toModelArray();
    }

    return this.getOrderedDistrictsModelArrayForUser(userId);
  }

  getOrderedWardsModelArrayAvailableForUser(userId) {
    if (this.hasManagerForUser(userId)) {
      return this.getOrderedWardsQuerySet().toModelArray();
    }

    return this.getOrderedWardsModelArrayForUser(userId);
  }

  getOrderedHamletsModelArrayAvailableForUser(userId) {
    if (this.hasManagerForUser(userId)) {
      return this.getOrderedHamletsQuerySet().toModelArray();
    }

    return this.getOrderedHamletsModelArrayForUser(userId);
  }

  getOrderedStationsModelArrayForUser(userId) {
    return this.getOrderedStationsQuerySet().toModelArray();
    // .filter((stationModel) => stationModel.hasMembershipForUser(userId));
  }

  getOrderedStationsModelArrayAvailableForUser(userId) {
    if (this.hasManagerForUser(userId)) {
      return this.getOrderedStationsQuerySet().toModelArray();
    }

    return this.getOrderedStationsModelArrayForUser(userId);
  }

  hasManagerForUser(userId) {
    return this.managers
      .filter({
        userId,
      })
      .exists();
  }

  hasMembershipInAnyDistrictForUser(userId) {
    return this.districts
      .toModelArray()
      .some((districtModel) => districtModel.hasMembershipForUser(userId));
  }

  isAvailableForUser(userId) {
    return this.hasManagerForUser(userId) || this.hasMembershipInAnyDistrictForUser(userId);
  }

  deleteRelated() {
    this.managers.delete();

    this.districts.toModelArray().forEach((districtModel) => {
      districtModel.deleteWithRelated();
    });

    this.contents.delete();
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
