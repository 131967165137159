import { call, put, select } from 'redux-saga/effects';
import request from '../request';
import api from '../../../api';
import selectors from '../../../selectors';
import actions from '../../../actions';

function* liveBroadcast(data) {
  const { id: provinceId } = yield select(selectors.selectCurrentProvince);

  let url;
  let message;

  try {
    ({ url, message } = yield call(request, api.liveBroadcast, provinceId, data));
  } catch (error) {
    yield put(actions.liveBroadcastError(error));
  }

  if (message === 'connecting') {
    yield put(actions.liveBroadcastRequest(message, url));
  }

  if (message === 'playing') {
    yield put(actions.liveBroadcastStart(message, url));
  }

  if (message === 'played') {
    yield put(actions.liveBroadcastStop(message, url, data.liveStatus));
  }
}

function* liveBroadcastStop(data) {
  yield call(liveBroadcast, data);
}

function* handleBroadcastingError(message) {
  yield put(actions.handleBroadcastingError(message));
}

function* handleBroadcastFinished(message) {
  yield put(actions.handleBroadcastFinished(message));
}

function* clearBroadcast() {
  yield put(actions.clearBroadcast());
}

function* closeMessageBroadcast() {
  yield put(actions.closeMessageBroadcast());
}

function* handleOverrideContentByMicrophone(content) {
  yield put(actions.handleOverrideContentByMicrophone(content));
}

export default {
  liveBroadcast,
  liveBroadcastStop,
  handleBroadcastingError,
  handleBroadcastFinished,
  clearBroadcast,
  closeMessageBroadcast,
  handleOverrideContentByMicrophone,
};
