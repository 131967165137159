import React, { useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';

const DateTimeDisplay = React.memo(() => {
  const [time, setTime] = useState('');
  const timeRef = useRef(null);

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const date = format(now, 'dd/MM/yyyy, HH:mm');
      setTime(date);
      timeRef.current = requestAnimationFrame(updateDateTime);
    };

    timeRef.current = requestAnimationFrame(updateDateTime);

    return () => cancelAnimationFrame(timeRef.current);
  }, []);

  return <div>{time}</div>;
});

export default DateTimeDisplay;
