import React, { useCallback, useMemo, useRef, useState } from 'react';
import { dequal } from 'dequal';
import classNames from 'classnames';
import { isEmpty, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Icon,
  Image,
  Input,
  Modal,
  TextArea,
} from 'semantic-ui-react';

// import { Input as InputCustom } from '../../lib/custom-ui';
import { useForm, useSteps } from '../../hooks';

import editIcon from '../../assets/images/edit.png';
import { DeviceOptions, DeviceStatusOptions, WATTAGE_MODES } from '../../constants/Enums';

import PickLocation from '../StationModal/PickLocation';

import styles from './DeviceAddModal.module.scss';

const StepTypes = {
  VIEW_MAP: 'VIEW_MAP',
};

const DeviceAddModal = React.memo(
  ({
    defaultData,
    districts,
    stations,
    wards,
    hamlets,
    isDarkMode,
    isViewing,
    isEditting,
    isSubmitting,
    canAdd,
    canEdit,
    onCreate,
    onUpdate,
    onClose,
  }) => {
    const [t] = useTranslation();

    const [data, handleFieldChange, setData] = useForm(() => ({
      name: '',
      phone: '',
      code: '',
      type: 'speaker',
      simNumber: '',
      simSerial: '',
      accessToken: '',
      SSID: '',
      password: '',
      identifier: '',
      // volume: DEFAULT_VOLUME,
      // address: '',
      description: '',
      stationId: '',
      wardId: '',
      hamletId: '',
      lng: '',
      lat: '',
      status: 'new',
      version: '',
      ...pickBy(defaultData),
    }));

    const [step, openStep, handleBack] = useSteps();

    const cleanData = useMemo(
      () => ({
        ...data,
        name: data.name.trim(),
        phone: data.phone.trim() || undefined,
        code: data.code.trim(),
        simNumber: data.simNumber.trim() || undefined,
        simSerial: data.simSerial.trim() || undefined,
        accessToken: data.accessToken.trim() || undefined,
        SSID: data.SSID.trim() || undefined,
        password: data.password.trim() || undefined,
        identifier: data.identifier.trim() || undefined,
        // address: data.address.trim() || null,
        description: data.description.trim() || undefined,
        stationId: data.stationId.trim(),
        lng: data.lng,
        lat: data.lat,
        status: data.status,
        // MaLenh: data.MaLenh !== 'default' ? data.MaLenh : undefined,
        // ThamSo: data.MaLenh !== 'default' ? data.ThamSo : undefined,
      }),
      [data],
    );

    const nameField = useRef(null);
    const codeField = useRef(null);
    const lngField = useRef(null);
    const latField = useRef(null);
    const districtRef = useRef(null);
    const wardRef = useRef(null);
    const hamletRef = useRef(null);
    const stationRef = useRef(null);

    const handleCloseIcon = useCallback(
      (e) => {
        if (isEditting || isViewing || e.target.className.includes('close')) {
          onClose();
        }
      },
      [isEditting, isViewing, onClose],
    );

    const handleSubmit = useCallback(() => {
      Object.keys(cleanData).forEach(
        (key) => cleanData[key] === undefined && delete cleanData[key],
      );

      if (!cleanData.name) {
        nameField.current.select();
        return;
      }

      if (!cleanData.code) {
        codeField.current.select();
        return;
      }

      if (!cleanData.districtId) {
        districtRef.current.open();
        return;
      }

      if (!cleanData.wardId) {
        wardRef.current.open();
        return;
      }

      if (!cleanData.hamletId) {
        hamletRef.current.open();
        return;
      }

      if (!cleanData.stationId) {
        stationRef.current.open();
        return;
      }

      if (!cleanData.lat) {
        latField.current.select();

        return;
      }

      if (!cleanData.lng) {
        lngField.current.select();

        return;
      }

      if (isViewing) {
        return;
      }

      if (isEditting) {
        if (!canEdit) {
          return;
        }
        onUpdate(cleanData.id, cleanData);
        onClose();
      } else {
        if (!canAdd) {
          return;
        }
        onCreate(cleanData);
        onClose();
      }
    }, [isEditting, isViewing, canAdd, canEdit, onCreate, onUpdate, onClose, cleanData]);

    const handleOpenMap = useCallback(() => {
      openStep(StepTypes.VIEW_MAP);
    }, [openStep]);

    const handleSetLatLng = useCallback(
      (location) => {
        setData((prev) => ({ ...prev, ...location }));
      },
      [setData],
    );

    // const handleSelectDeviceCode = useCallback(
    //   (_, { value }) => {
    //     setData((prev) => ({ ...prev, name: prev.name || value, code: value }));
    //   },
    //   [setData],
    // );

    const handleSelectDeviceName = useCallback(
      (_, { value }) => {
        setData((prev) => ({ ...prev, type: value }));
      },
      [setData],
    );

    const handleSelectDistrict = useCallback(
      (_, { name, value }) => {
        setData((prev) => ({ ...prev, [name]: value, wardId: '', hamletId: '', stationId: '' }));
      },
      [setData],
    );

    const handleSelectWard = useCallback(
      (_, { name, value }) => {
        setData((prev) => ({ ...prev, [name]: value, hamletId: '' }));
      },
      [setData],
    );

    // const handleRefreshToken = useCallback(() => {}, []);

    // const defaultOption = { key: '1', value: '', text: t('common.selectDistrict') };

    const districtOptions = [
      // defaultOption,
      ...districts.map(({ id, name }) => ({ key: id, value: id, text: name })),
    ];

    const wardFilters = wards.filter((w) => w.districtId === data.districtId);

    const wardOptions = [
      ...wardFilters.map(({ id, name }) => ({ key: id, value: id, text: name })),
    ];

    const hamletFilters = hamlets.filter((w) => w.wardId === data.wardId);

    const hamletOptions = [
      ...hamletFilters.map(({ id, name }) => ({ key: id, value: id, text: name })),
    ];

    const stationOptions = stations
      .filter((it) => it.wardId === data.wardId)
      .map(({ id, name }) => ({ key: id, value: id, text: name }));

    // const deviceCodeOptions = [1, 2, 3].map((i) => ({
    //   key: `CS${i}`,
    //   value: `CS${i}`,
    //   text: `CS${i}`,
    // }));

    if (step) {
      if (step.type === StepTypes.VIEW_MAP) {
        return (
          <PickLocation
            title="common.selectDeviceLocation"
            currentLocation={{ lat: data.lat, lng: data.lng }}
            onBack={handleBack}
            onClick={handleSetLatLng}
            showAction={!isViewing}
          />
        );
      }
    }

    return (
      <Modal open closeIcon size="small" centered={false} onClose={handleCloseIcon}>
        <Modal.Header>
          <h4>
            <Image src={editIcon} width={40} />
            {t(
              // eslint-disable-next-line no-nested-ternary
              isEditting
                ? 'common.editDevice'
                : isViewing
                ? 'common.viewInfoDevice'
                : 'action.addDevice',
              {
                context: 'title',
              },
            )}
          </h4>
        </Modal.Header>
        <Modal.Content className={styles.wrapperContent}>
          <Form onSubmit={handleSubmit}>
            <div className={styles.topic}>{t('common.deviceInformation')}</div>
            <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <div className={styles.text}>
                  {t('common.deviceName')}
                  <sup>*</sup>
                </div>
                <Input
                  ref={nameField}
                  name="name"
                  value={data.name}
                  className={styles.field}
                  fluid
                  placeholder={t('common.deviceName')}
                  onChange={handleFieldChange}
                />
              </div>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.deviceType')}
                  <sup>*</sup>
                </span>
                <Dropdown
                  name="type"
                  value={data.type}
                  className={styles.borderDropdown}
                  fluid
                  search
                  selection
                  onChange={handleSelectDeviceName}
                  options={DeviceOptions.map((it) => ({ ...it, text: t(`common.${it.text}`) }))}
                  noResultsMessage={t('common.noResultsFound')}
                />
              </div>
            </div>
            <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <div className={styles.text}>
                  {t('common.deviceCode')}
                  <sup>*</sup>
                </div>
                <Input
                  ref={codeField}
                  name="code"
                  value={data.code}
                  className={styles.field}
                  fluid
                  placeholder={t('common.deviceCode')}
                  onChange={handleFieldChange}
                />
              </div>
            </div>
            {/* <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.identifier')}
                  <sup>*</sup>
                </span>
                <Input
                  name="identifier"
                  value={data.identifier}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
              <div className={styles.wrapperField}>
                <span className={styles.text}>{t('common.phone')}</span>
                <Input
                  name="phone"
                  value={data.phone}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
            </div> */}
            <Divider />
            <div className={styles.topic}>{t('common.locationInformation')}</div>
            <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.district')}
                  <sup>*</sup>
                </span>
                <Dropdown
                  ref={districtRef}
                  fluid
                  selection
                  // search
                  placeholder={t('common.selectDistrict')}
                  name="districtId"
                  options={districtOptions}
                  value={data.districtId}
                  className={classNames(
                    styles.borderDropdown,
                    isEmpty(districts) && styles.errorMessage,
                  )}
                  onChange={handleSelectDistrict}
                  error={isEmpty(districts)}
                  noResultsMessage={t('common.notFoundDistricts')}
                />
              </div>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.ward')}
                  <sup>*</sup>
                </span>
                <Dropdown
                  ref={wardRef}
                  fluid
                  selection
                  search
                  placeholder={t('common.selectWard')}
                  name="wardId"
                  options={wardOptions}
                  value={data.wardId}
                  className={classNames(
                    styles.borderDropdown,
                    data.districtId && isEmpty(wardFilters) && styles.errorMessage,
                  )}
                  onChange={handleSelectWard}
                  error={!!data.districtId && isEmpty(wardFilters)}
                  noResultsMessage={t('common.notFoundWards')}
                />
              </div>
            </div>
            <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.hamlet')}
                  <sup>*</sup>
                </span>
                <Dropdown
                  ref={hamletRef}
                  fluid
                  selection
                  search
                  placeholder={t('common.selectHamlet')}
                  name="hamletId"
                  options={hamletOptions}
                  value={data.hamletId}
                  className={classNames(
                    styles.borderDropdown,
                    data.wardId && isEmpty(hamletFilters) && styles.errorMessage,
                  )}
                  onChange={handleFieldChange}
                  error={!!data.wardId && isEmpty(hamletFilters)}
                  noResultsMessage={t('common.notFoundHamlets')}
                />
              </div>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.stations')}
                  <sup>*</sup>
                </span>
                <Dropdown
                  ref={stationRef}
                  name="stationId"
                  placeholder={t('common.selectStation')}
                  className={classNames(
                    styles.borderDropdown,
                    data.wardId && isEmpty(stationOptions) && styles.errorMessage,
                  )}
                  fluid
                  search
                  selection
                  options={stationOptions}
                  value={data.stationId}
                  onChange={handleFieldChange}
                  noResultsMessage={t('common.notFoundStations')}
                  error={!!data.wardId && isEmpty(stationOptions)}
                />
              </div>
            </div>
            <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.latitude')} <sup>*</sup>
                </span>
                <Input
                  type="number"
                  ref={latField}
                  placeholder="10.435257"
                  name="lat"
                  value={data.lat}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.longitude')}
                  <sup>*</sup>
                </span>
                <Input
                  type="number"
                  ref={lngField}
                  placeholder="108.435257"
                  name="lng"
                  value={data.lng}
                  className={styles.field}
                  onChange={handleFieldChange}
                  icon={
                    <Icon
                      name="map marker alternate"
                      size="large"
                      color="blue"
                      link
                      onClick={handleOpenMap}
                    />
                  }
                />
              </div>
            </div>
            <p style={{ marginTop: '4px', color: '#ccc' }}>{t('common.enterOrClickIcon')}</p>
            <Divider />
            <div className={styles.topic}>{t('common.sim4GInformation')}</div>
            <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.simNumber')}
                  {/* <sup>*</sup> */}
                </span>
                <Input
                  name="simNumber"
                  value={data.simNumber}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
              {(isEditting || isViewing) && (
                <div className={styles.wrapperField}>
                  <span className={styles.text}>{t('common.softwareVersion')}</span>
                  <Input
                    name="version"
                    value={data.version}
                    className={styles.field}
                    onChange={handleFieldChange}
                  />
                </div>
              )}
            </div>
            {/* <Divider />
            <div className={styles.topic}>{t('common.wifiSetupInformation')}</div>
            <div className={styles.wrapperGroup}>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  SSID
                  <sup>*</sup>
                </span>
                <Input
                  name="SSID"
                  fluid
                  value={data.SSID}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.password')}
                  <sup>*</sup>
                </span>
                <InputCustom.Password
                  name="password"
                  fluid
                  value={data.password}
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </div>
            </div> */}
            <Divider />
            <div className={styles.topic}>{t('common.otherInformation')}</div>
            <div className={styles.wrapperGroup}>
              {/* <div className={styles.wrapperField}>
                <span className={styles.text}>
                  Access Token
                  <sup>*</sup>
                </span>
                <Input
                  name="accessToken"
                  value={data.accessToken}
                  className={classNames(styles.field, styles.readOnly)}
                  onChange={handleFieldChange}
                  icon={<Icon name="repeat" link onClick={handleRefreshToken} />}
                />
              </div> */}
              <div className={styles.wrapperField}>
                <span className={styles.text}>
                  {t('common.status')}
                  <sup>*</sup>
                </span>
                <Dropdown
                  name="status"
                  placeholder={t('common.status')}
                  fluid
                  selection
                  className={styles.borderDropdown}
                  options={DeviceStatusOptions.map((it) => ({
                    ...it,
                    text: t(`common.${it.key}`),
                  }))}
                  value={data.status}
                  onChange={handleFieldChange}
                />
              </div>
              {(isEditting || isViewing) && (
                <div className={styles.wrapperField}>
                  <span className={styles.text}>{t('common.volume')}</span>
                  <Input
                    value={data.specification?.AmLuong}
                    className={classNames(styles.field, styles.readOnly)}
                  />
                </div>
              )}
            </div>
            {(isEditting || isViewing) && (
              <>
                <div className={styles.wrapperGroup}>
                  {[
                    {
                      key: 'TenNhaCungCap',
                      label: 'common.supplierName',
                      value: data.specification?.TenNhaCungCap,
                    },
                    {
                      key: 'MaNhaCungCap',
                      label: 'common.supplierCode',
                      value: data.specification?.MaNhaCungCap,
                    },
                  ].map((field) => (
                    <div key={field.key} className={styles.wrapperField}>
                      <span className={styles.text}>{t(field.label)}</span>
                      <Input
                        value={field.value}
                        className={classNames(styles.field, styles.readOnly)}
                      />
                    </div>
                  ))}
                </div>
                <div className={styles.wrapperGroup}>
                  {[
                    {
                      key: 'CongSuat',
                      label: 'common.wattage',
                      value: data.specification?.ThongTinThietBi?.CongSuat || 1,
                    },
                  ].map((field) => {
                    const mode = WATTAGE_MODES.find((m) => m.value === +field.value);

                    if (!mode) return null;

                    return (
                      <div key={field.key} className={styles.wrapperField}>
                        <span className={styles.text}>{t(field.label)}</span>
                        <Input
                          value={t(`common.${mode.key}`)}
                          className={classNames(styles.field, styles.readOnly)}
                        />
                      </div>
                    );
                  })}
                  <div className={styles.wrapperField}>
                    <span className={styles.text}>{t('common.temperature')}</span>
                    <Input
                      value={data.specification?.ThongTinThietBi?.NhietDo}
                      className={classNames(styles.field, styles.readOnly)}
                    />
                  </div>
                </div>
              </>
            )}
            <div className={styles.wrapperField}>
              <span className={styles.text}>{t('common.des')}</span>
              <TextArea
                rows={6}
                name="description"
                value={data.description}
                // placeholder="Mô tả"
                onChange={handleFieldChange}
              />
            </div>
            <div className={styles.actions} style={{ display: isViewing ? 'none' : '' }}>
              <Button type="button" positive className={styles.button} onClick={onClose}>
                <span>{t('action.cancel')}</span>
              </Button>
              <Button
                loading={isSubmitting}
                positive
                disabled={dequal(data.districtId, '')}
                className={styles.button}
              >
                <span>{t('action.save')}</span>
              </Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  },
);

DeviceAddModal.propTypes = {
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  districts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  stations: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  wards: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  hamlets: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  isDarkMode: PropTypes.bool.isRequired,
  isEditting: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isViewing: PropTypes.bool.isRequired,
  canAdd: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,

  onClose: PropTypes.func.isRequired,
};

DeviceAddModal.defaultProps = {};

export default DeviceAddModal;
