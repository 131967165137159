import { connect } from 'react-redux';

import selectors from '../selectors';
import Fixed from '../components/Fixed';

const mapStateToProps = (state) => {
  const { provinceId } = selectors.selectPath(state);
  const { level } = selectors.selectCurrentUser(state);
  const currentDistrict = selectors.selectCurrentDistrict(state);
  const sidebarMenu = selectors.selectSidebarMenu(state);
  const {
    pattern: { path },
  } = selectors.selectPathsMatch(state);

  return {
    provinceId,
    district: currentDistrict,
    path,
    sidebarMenu,
    level,
  };
};

export default connect(mapStateToProps)(Fixed);
