import ActionTypes from '../constants/ActionTypes';

const createCategory = (category) => ({
  type: ActionTypes.CATEGORY_CREATE,
  payload: {
    category,
  },
});

createCategory.success = (localId, category) => ({
  type: ActionTypes.CATEGORY_CREATE__SUCCESS,
  payload: {
    localId,
    category,
  },
});

createCategory.failure = (localId, error) => ({
  type: ActionTypes.CATEGORY_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleCategoryCreate = (category) => ({
  type: ActionTypes.CATEGORY_CREATE_HANDLE,
  payload: {
    category,
  },
});

const updateCategory = (id, data) => ({
  type: ActionTypes.CATEGORY_UPDATE,
  payload: {
    id,
    data,
  },
});

updateCategory.success = (category) => ({
  type: ActionTypes.CATEGORY_UPDATE__SUCCESS,
  payload: {
    category,
  },
});

updateCategory.failure = (id, error) => ({
  type: ActionTypes.CATEGORY_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleCategoryUpdate = (category) => ({
  type: ActionTypes.CATEGORY_UPDATE_HANDLE,
  payload: {
    category,
  },
});

const deleteCategory = (id) => ({
  type: ActionTypes.CATEGORY_DELETE,
  payload: {
    id,
  },
});

deleteCategory.success = (category) => ({
  type: ActionTypes.CATEGORY_DELETE__SUCCESS,
  payload: {
    category,
  },
});

deleteCategory.failure = (id, error) => ({
  type: ActionTypes.CATEGORY_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleCategoryDelete = (category) => ({
  type: ActionTypes.CATEGORY_DELETE_HANDLE,
  payload: {
    category,
  },
});

export default {
  createCategory,
  handleCategoryCreate,
  updateCategory,
  handleCategoryUpdate,
  deleteCategory,
  handleCategoryDelete,
};
