import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useForceUpdate } from '../../lib/hooks';

import styles from './Counter.module.scss';

const countdown = (endTime) => {
  if (endTime) {
    return Math.floor((endTime - new Date()) / 1000);
  }
  return 0;
};

const Counter = React.memo(({ isVerifyCode, expires, onStopCountDown }) => {
  const interval = useRef(null);
  const timeout = useRef(null);

  const forceUpdate = useForceUpdate();

  const start = useCallback(() => {
    interval.current = setInterval(() => {
      forceUpdate();
    }, 1000);
  }, [forceUpdate]);

  const stop = useCallback(() => {
    if (expires) {
      const duration = countdown(expires);

      timeout.current = setTimeout(() => {
        clearInterval(interval.current);
        onStopCountDown();
      }, duration * 1000);
    }
  }, [expires, onStopCountDown]);

  useEffect(() => {
    if (isVerifyCode && expires) {
      start();
    }

    return () => clearInterval(interval.current);
  }, [start, expires, isVerifyCode]);

  useEffect(() => {
    stop();
    return () => clearTimeout(timeout.current);
  }, [stop]);

  return (
    <div className={classNames(styles.counter, !expires && styles.hidden)}>
      {`${countdown(expires)}s`}
    </div>
  );
});

export default Counter;

Counter.propTypes = {
  expires: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isVerifyCode: PropTypes.bool.isRequired,
  onStopCountDown: PropTypes.func.isRequired,
};

Counter.defaultProps = {
  expires: null,
};
