import { Droppable } from 'react-beautiful-dnd';
import React from 'react';
import PropTypes from 'prop-types';

const Drop = React.memo(({ children, id, type, ...props }) => {
  return (
    <Droppable droppableId={id} type={type}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
            {...props} // eslint-disable-line react/jsx-props-no-spreading
            // style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'transparent' }}
          >
            {children}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
});

Drop.propTypes = {
  children: PropTypes.node, // eslint-disable-line react/forbid-prop-types
  id: PropTypes.string,
  type: PropTypes.string,
};
Drop.defaultProps = {
  children: undefined,
  type: undefined,
  id: '',
};
export default Drop;
