import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, MenuItem, Tab } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { removeValues } from '../../../utils/content-method';

import styles from './StepLocation.module.scss';

const isIndeterminateWard = (isSelected, ward, selectedHamlet, hamlets) => {
  const hamletsInWard = hamlets.filter((h) => h.wardId === ward.id);
  const selectedHamlets = selectedHamlet.filter((h) => h.wardId === ward.id);

  return isSelected && selectedHamlets.length > 0 && selectedHamlets.length < hamletsInWard.length;
};

const isIndeterminateDevice = (isSelected, hamlet, selectedDevice, devices) => {
  const deviceInHamlets = devices.filter((h) => h.hamletId === hamlet.id);
  const selectedDevices = selectedDevice.filter((h) => h.hamletId === hamlet.id);

  return (
    isSelected && selectedDevices.length > 0 && selectedDevices.length < deviceInHamlets.length
  );
};

const isCheckeds = (isSelected, hamletselect, hamlet) => {
  return isSelected && hamletselect === hamlet;
};

const StepLocation = React.memo(
  ({
    isMobile,
    priority,
    districts,
    wards,
    hamlets,
    devices,
    areasReceived,
    onCloseAlertPortal,
    onSetAreasReceived,
  }) => {
    const handleSelectAreasReceived = useCallback(
      (name, item) => {
        if (name === 'districts') {
          const isExisted = areasReceived.districts.find((it) => it.id === item.id);

          const wardsFilter = wards.filter((it) => it.districtId === item.id);
          const hamletsFilter = hamlets.filter((it) => it.districtId === item.id);
          const devicessFilter = devices.filter((it) => it.districtId === item.id);

          const newDistricts = isExisted
            ? removeValues(areasReceived.districts, item, 'id')
            : [...areasReceived.districts, item];

          const newWards = isExisted
            ? removeValues(areasReceived.wards, wardsFilter, 'id')
            : [...areasReceived.wards, ...wardsFilter];

          const newHamlets = isExisted
            ? removeValues(areasReceived.hamlets, hamletsFilter, 'id')
            : [...areasReceived.hamlets, ...hamletsFilter];

          const newDevices = isExisted
            ? removeValues(areasReceived.devices, devicessFilter, 'id')
            : [...areasReceived.devices, ...devicessFilter];

          onSetAreasReceived((prev) => ({
            ...prev,
            districts: newDistricts,
            wards: newWards,
            hamlets: newHamlets,
            devices: newDevices,
          }));
        }

        if (name === 'wards') {
          const isExisted = areasReceived.wards.find((it) => it.id === item.id);

          const findDistrict = districts.find((it) => it.id === item.districtId);
          const hamletsFilter = hamlets.filter((it) => it.wardId === item.id);
          const devicessFilter = devices.filter((it) => it.wardId === item.id);

          const isExistedDistrict = areasReceived.districts.find((d) => d.id === item.districtId);

          const newWards = isExisted
            ? removeValues(areasReceived.wards, item, 'id')
            : [...areasReceived.wards, item];

          const newHamlets = isExisted
            ? removeValues(areasReceived.hamlets, hamletsFilter, 'id')
            : [...areasReceived.hamlets, ...hamletsFilter];

          const newDevices = isExisted
            ? removeValues(areasReceived.devices, devicessFilter, 'id')
            : [...areasReceived.devices, ...devicessFilter];

          const wardsInDistrict = newWards.filter((h) => h.districtId === item.districtId);

          const newDistrict =
            wardsInDistrict.length !== 0 // eslint-disable-line no-nested-ternary
              ? isExistedDistrict
                ? areasReceived.districts
                : [...areasReceived.districts, findDistrict]
              : removeValues(areasReceived.districts, findDistrict, 'id');

          onSetAreasReceived((prev) => ({
            ...prev,
            districts: newDistrict,
            wards: newWards,
            hamlets: newHamlets,
            devices: newDevices,
          }));
        }

        if (name === 'hamlets') {
          const isExisted = areasReceived.hamlets.find((it) => it.id === item.id);

          const devicessFilter = devices.filter((it) => it.hamletId === item.id);

          const newHamlets = isExisted
            ? removeValues(areasReceived.hamlets, item, 'id')
            : [...areasReceived.hamlets, item];

          const newDevices = isExisted
            ? removeValues(areasReceived.devices, devicessFilter, 'id')
            : [...areasReceived.devices, ...devicessFilter];

          const findDistrictByHamlet = districts.find((d) => d.id === item.districtId);
          const findWardByHamlet = wards.find((w) => w.id === item.wardId);
          const hamletsInWard = newHamlets.filter((h) => h.wardId === item.wardId);

          const isExistedWard = areasReceived.wards.find((w) => w.id === item.wardId);
          const isExistedDistrict = areasReceived.districts.find((d) => d.id === item.districtId);

          const newWards =
            hamletsInWard.length !== 0 // eslint-disable-line no-nested-ternary
              ? isExistedWard
                ? areasReceived.wards
                : [...areasReceived.wards, findWardByHamlet]
              : removeValues(areasReceived.wards, findWardByHamlet, 'id');

          onSetAreasReceived((prev) => ({
            ...prev,
            districts: !isExistedDistrict
              ? [...prev.districts, findDistrictByHamlet]
              : prev.districts,
            wards: newWards,
            hamlets: newHamlets,
            devices: newDevices,
          }));
        }

        if (name === 'devices') {
          const isExisted = areasReceived.devices.find((d) => d.code === item.code);

          const newDevices = isExisted
            ? removeValues(areasReceived.devices, item, 'id')
            : [...areasReceived.devices, item];

          const findDistrictByDevice = districts.find((d) => d.id === item.districtId);
          const findHamletByDevice = hamlets.find((h) => h.id === item.hamletId);
          const findWardByHamlet = wards.find((w) => w.id === item.wardId);
          const devicesInHamlet = newDevices.filter((d) => d.hamletId === item.hamletId);

          const isExistedHamlet = areasReceived.hamlets.find((h) => h.id === item.hamletId);
          const isExistedWard = areasReceived.wards.find((w) => w.id === item.wardId);
          const isExistedDistrict = areasReceived.districts.find((d) => d.id === item.districtId);

          const newHamlets =
            devicesInHamlet.length !== 0 // eslint-disable-line no-nested-ternary
              ? isExistedHamlet
                ? areasReceived.hamlets
                : [...areasReceived.hamlets, findHamletByDevice]
              : removeValues(areasReceived.hamlets, findHamletByDevice, 'id');

          onSetAreasReceived((prev) => ({
            ...prev,
            districts: !isExistedDistrict
              ? [...prev.districts, findDistrictByDevice]
              : prev.districts,
            wards: !isExistedWard ? [...prev.wards, findWardByHamlet] : prev.wards,
            hamlets: newHamlets,
            devices: newDevices,
          }));
        }
      },
      [
        areasReceived.districts,
        areasReceived.wards,
        areasReceived.hamlets,
        areasReceived.devices,
        districts,
        wards,
        hamlets,
        devices,
        onSetAreasReceived,
      ],
    );

    const handleSelectDevice = useCallback(
      (device) => {
        handleSelectAreasReceived('devices', device);
      },
      [handleSelectAreasReceived],
    );

    const handletSelectHamlet = useCallback(
      (hamlet) => {
        handleSelectAreasReceived('hamlets', hamlet);
      },
      [handleSelectAreasReceived],
    );

    const handleSelectWard = useCallback(
      (item) => {
        handleSelectAreasReceived('wards', item);

        onCloseAlertPortal();
      },
      [handleSelectAreasReceived, onCloseAlertPortal],
    );

    const handleSelectDistrict = useCallback(
      (item) => {
        handleSelectAreasReceived('districts', item);
        onCloseAlertPortal();
      },
      [handleSelectAreasReceived, onCloseAlertPortal],
    );

    const handleSelectAllProvince = useCallback(
      (_, { checked }) => {
        if (wards.length > 0) {
          if (checked) {
            onSetAreasReceived((prev) => ({
              ...prev,
              districts,
              wards,
              hamlets,
              devices,
            }));
            return;
          }
          onSetAreasReceived((prev) => ({
            ...prev,
            districts: [],
            wards: [],
            hamlets: [],
            devices: [],
          }));
        }
      },
      [districts, wards, hamlets, devices, onSetAreasReceived],
    );

    const menuItemChildren = useCallback(
      (item) => {
        const wardsInDistrict = wards.filter((w) => w.districtId === item.id).map((w) => w.id);

        const selectedDistricts = areasReceived.districts.map((d) => d.id) || [];
        const selectedWards = areasReceived.wards.filter((w) => w.districtId === item.id);

        const isIndeterminate =
          selectedDistricts.includes(item.id) &&
          selectedWards.length > 0 &&
          selectedWards.length < wardsInDistrict.length;

        const isChecked =
          selectedDistricts.includes(item.id) && selectedWards.length === wardsInDistrict.length;

        return (
          <MenuItem key={item.id} className={styles.item} disabled={wardsInDistrict.length <= 0}>
            <Checkbox
              indeterminate={isIndeterminate}
              checked={isChecked}
              onClick={() => handleSelectDistrict(item)}
            />
            {item.name}
          </MenuItem>
        );
      },
      [areasReceived.districts, areasReceived.wards, wards, handleSelectDistrict],
    );

    const renderWardNode = useCallback(
      (districtId) => {
        const wardsInDistrict = wards.filter((w) => w.districtId === districtId);

        const selectedWards = areasReceived.wards.filter((w) => w.districtId === districtId);

        const selectedWardIds = selectedWards.map((w) => w.id);

        if (priority !== 1)
          return wardsInDistrict.map((it) => (
            <MenuItem key={it.id} className={styles.wardItem}>
              <Checkbox
                key={it.id}
                checked={selectedWardIds.includes(it.id)}
                onClick={() => handleSelectWard(it)}
                label={it.name}
              />
            </MenuItem>
          ));

        return (
          <Tab
            key={districtId}
            grid={{ paneWidth: 10, tabWidth: 6 }}
            menu={{
              fluid: isMobile,
              vertical: isMobile,
              tabular: true,
            }}
            panes={wardsInDistrict.map((it) => ({
              menuItem: (
                <MenuItem key={it.id} className={styles.wardItem}>
                  <Checkbox
                    indeterminate={isIndeterminateWard(
                      selectedWardIds.includes(it.id),
                      it,
                      areasReceived.hamlets,
                      hamlets,
                    )}
                    key={it.id}
                    checked={isCheckeds(
                      selectedWardIds.includes(it.id),
                      areasReceived.hamlets.filter((h) => h.wardId === it.id).length,
                      hamlets.filter((h) => h.wardId === it.id).length,
                    )}
                    onClick={() => handleSelectWard(it)}
                  />
                  {it.name}
                </MenuItem>
              ),
              render: () => {
                const filterHamlets = hamlets.filter((h) => h.wardId === it.id);

                const selectedHamlets = areasReceived.hamlets.filter((h) => h.wardId === it.id);

                const selectedHamletIds = selectedHamlets.map((h) => h.id);

                if (priority !== 1)
                  return filterHamlets.map((h) => (
                    <MenuItem key={h.id} className={styles.wardItem}>
                      <Checkbox
                        checked={selectedHamletIds.includes(h.id)}
                        key={h.id}
                        onClick={() => handletSelectHamlet(h)}
                      />
                      {h.name}
                    </MenuItem>
                  ));

                return (
                  <Tab
                    key={it.id}
                    grid={{ paneWidth: 8, tabWidth: 8 }}
                    menu={{
                      fluid: isMobile,
                      vertical: isMobile,
                      tabular: true,
                    }}
                    panes={filterHamlets.map((h) => ({
                      menuItem: (
                        <MenuItem key={h.id} className={styles.wardItem}>
                          <Checkbox
                            key={h.id}
                            indeterminate={isIndeterminateDevice(
                              selectedHamletIds.includes(h.id),
                              h,
                              areasReceived.devices,
                              devices,
                            )}
                            checked={
                              selectedHamletIds.includes(h.id) &&
                              areasReceived.devices.filter((d) => d.hamletId === h.id).length ===
                                devices.filter((d) => d.hamletId === h.id).length
                            }
                            onClick={() => handletSelectHamlet(h)}
                          />
                          {h.name}
                        </MenuItem>
                      ),
                      render: () => {
                        const devicesInHamlet = devices.filter((d) => d.hamletId === h.id);

                        const selectedDevices = areasReceived.devices.filter(
                          (d) => d.hamletId === h.id,
                        );

                        const deviceIds = selectedDevices.map((d) => d.code);

                        return devicesInHamlet.map((d) => (
                          <Checkbox
                            checked={deviceIds.includes(d.code)}
                            style={{
                              marginBottom: 5,
                              marginRight: 5,
                              flexGrow: 0,
                            }}
                            key={d.id}
                            onClick={() => handleSelectDevice(d)}
                            label={d.name}
                          />
                        ));
                      },
                    }))}
                  />
                );
              },
            }))}
          />
        );
      },
      [
        areasReceived.wards,
        areasReceived.hamlets,
        areasReceived.devices,
        isMobile,
        wards,
        hamlets,
        devices,
        priority,
        handleSelectWard,
        handletSelectHamlet,
        handleSelectDevice,
      ],
    );

    const isSelectAllProvince = areasReceived.districts.length === districts.length;

    const panes = [
      {
        menuItem: (
          <MenuItem className={styles.item} key={Math.random()}>
            <Checkbox
              disabled={wards.length <= 0}
              onClick={handleSelectAllProvince}
              checked={isSelectAllProvince}
              label="Chọn tất cả"
            />
          </MenuItem>
        ),
        render: () =>
          wards.length <= 0 ? (
            <div>
              <p>Chưa có thông tin xã/phường/thị trấn</p>
              <Link to="/binh-dinh/don-vi-hanh-chinh">
                <Button color="teal">Thêm thông tin</Button>
              </Link>
            </div>
          ) : null,
      },
      ...districts.map((d) => ({
        menuItem: menuItemChildren(d),
        render: () => renderWardNode(d.id),
      })),
    ];

    return (
      <div className={styles.content}>
        <Tab
          grid={{ paneWidth: 11, tabWidth: 5 }}
          menu={{ fluid: isMobile, vertical: isMobile, tabular: true }}
          panes={panes}
        />
      </div>
    );
  },
);

export default StepLocation;

StepLocation.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  /* eslint-disable react/forbid-prop-types */
  districts: PropTypes.array.isRequired,
  priority: PropTypes.number.isRequired,
  wards: PropTypes.array.isRequired,
  hamlets: PropTypes.array.isRequired,
  devices: PropTypes.array.isRequired,
  areasReceived: PropTypes.object.isRequired,
  onCloseAlertPortal: PropTypes.func.isRequired,
  onSetAreasReceived: PropTypes.func.isRequired,
};
