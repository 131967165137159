import { ORM } from 'redux-orm';

import {
  Activity,
  Attachment,
  District,
  Ward,
  Hamlet,
  DistrictMembership,
  Device,
  Category,
  Content,
  Schedule,
  Station,
  Notification,
  Province,
  ProvinceManager,
  Task,
  User,
  ContentLocation,
  ScheduleLog,
  WardMembership,
  UserGroup,
  Action,
} from './models';

const orm = new ORM({
  stateSelector: (state) => state.orm,
});

orm.register(
  User,
  Province,
  ProvinceManager,
  District,
  Ward,
  Hamlet,
  DistrictMembership,
  Category,
  Content,
  Schedule,
  Station,
  Device,
  Task,
  Attachment,
  Activity,
  Notification,
  ContentLocation,
  ScheduleLog,
  WardMembership,
  UserGroup,
  Action,
);

export default orm;
