import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import DevicesControlModal from '../components/DevicesControlModal';

const mapStateToProps = (state) => {
  const { districtId } = selectors.selectPath(state);
  const province = selectors.selectCurrentProvince(state);
  const districts = selectors.selectDistrictsForCurrentProvince(state);
  const wards = selectors.selectWardsForCurrentProvince(state);
  const hamlets = selectors.selectHamletsForCurrentProvince(state);
  const devices = selectors.selectDevicesForCurrentProvince(state);
  const { name, deviceTotal } = selectors.selectCurrentProvince(state);
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);

  return {
    items: devices,
    provinces: [province],
    districts,
    wards,
    hamlets,
    currentId: districtId,
    canEdit: isCurrentUserManager,
    province: { name, deviceTotal },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onFetch: entryActions.fetchDevices,
      onControl: entryActions.controlDevices,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DevicesControlModal);
