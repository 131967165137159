import { all, call, cancel, fork, take } from 'redux-saga/effects';

import watchers from './watchers';
import services from './services';
import ActionTypes from '../../constants/ActionTypes';
import { removeItemStorage } from '../../utils/local-storage';

export default function* loginSaga() {
  const watcherTasks = yield all(watchers.map((watcher) => fork(watcher)));

  yield take(ActionTypes.AUTHENTICATE__SUCCESS);

  yield cancel(watcherTasks);
  yield call(removeItemStorage, 'qrcode');
  yield call(removeItemStorage, 'expires');
  yield call(removeItemStorage, 'isVerify');
  yield call(services.goToRoot);
}
