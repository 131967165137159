import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import alertDeleteIcon from '../../../assets/images/alert-delete.png';
import styles from './DeleteStep.module.scss';

const DeleteStep = React.memo(({ title, content, onDelete, onClose }) => {
  const [t] = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Image src={alertDeleteIcon} alt="" width={48} />
        <div>
          <h4>{title}</h4>
          <p>{content}</p>
        </div>
      </div>
      <div className={styles.actions}>
        <Button onClick={onClose}>{t('action.cancel')}</Button>
        <Button onClick={onDelete}>{t('action.delete')}</Button>
      </div>
    </div>
  );
});

DeleteStep.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteStep;
