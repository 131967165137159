import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  data: {
    name: '',
    description: '',
    type: '',
    color: '#e04556',
    categories: [],
    fileDirname: null,
    duration: 0,
  },
  playlist: [],
  isSubmitting: false,
  error: null,
  contentId: null,
  attachment: null,
  isConcating: false,
  concatFile: null,
  errorConcat: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.CONTENT_CREATE:
    case ActionTypes.CONTENT_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
        isSubmitting: true,
        contentId: null,
      };
    case ActionTypes.CONTENT_CONCAT_FILE:
      return {
        ...state,
        isConcating: true,
      };
    case ActionTypes.CONTENT_CREATE__SUCCESS:
    case ActionTypes.CONTENT_UPDATE__SUCCESS:
      return {
        ...initialState,
        contentId: payload.content.id,
      };
    case ActionTypes.CONTENT_CONCAT_FILE__SUCCESS:
      return {
        ...state,
        isConcating: false,
        error: null,
        concatFile: payload.attachment,
        data: {
          ...state.data,
          duration: payload.duration,
        },
      };
    case ActionTypes.CONTENT_TEXT_TO_SPEECH:
      return {
        ...initialState,
        isSubmitting: true,
        playlist: [],
      };
    case ActionTypes.CONTENT_TEXT_TO_SPEECH__SUCCESS:
      return {
        ...initialState,
        isSubmitting: false,
        error: null,
        playlist: payload.item.playlist,
        attachment: payload.item.attachment,
        data: {
          ...state.data,
          duration: payload.item.duration,
        },
      };
    case ActionTypes.CONTENT_TEXT_TO_SPEECH__FAILURE:
    case ActionTypes.CONTENT_CREATE__FAILURE:
    case ActionTypes.CONTENT_UPDATE__FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: payload.error,
        contentId: null,
      };
    case ActionTypes.CONTENT_CONCAT_FILE__FAILURE:
      return {
        ...state,
        isConcating: false,
        errorConcat: payload.error,
      };
    case ActionTypes.CONTENT_PLAYLIST_CLEAR:
      return {
        ...state,
        playlist: [],
        attachment: null,
        data: {
          ...state.data,
          duration: 0,
        },
      };
    case ActionTypes.CONTENT_CONCAT_FILE__CLEAR:
      return {
        ...state,
        concatFile: null,
        data: {
          ...state.data,
          // duration: 0,
        },
      };
    case ActionTypes.CONTENT_ERROR_CLEAR:
      return {
        ...state,
        contentId: null,
        error: null,
        attachment: null,
        data: initialState.data,
        errorConcat: null,
      };
    default:
      return state;
  }
};
