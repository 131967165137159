import React from 'react';
import classNames from 'classnames';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CardList } from '../../assets/icons/card/card-list.svg';
import { ReactComponent as CardAmount } from '../../assets/icons/card/card-amount.svg';
import { ReactComponent as CardSchedule } from '../../assets/icons/card/card-schedule.svg';
import { ReactComponent as CardContent } from '../../assets/icons/card/card-news.svg';
import Paths from '../../constants/Paths';

import CardTotal from './CardTotal';
import Map from './Map';

import styles from './Home.module.scss';

const Home = React.memo(
  ({
    sidebarMenu,
    contentTotal,
    scheduleTotal,
    stationTotal,
    deviceTotal,
    stations,
    devices,
    slug,
  }) => {
    const [t] = useTranslation();

    const speakers = devices.filter(({ type }) => type === 'speaker');
    const displays = devices.filter(({ type }) => type === 'screen');
    const integrated = devices.filter(({ type }) => type === 'integratedDevice');

    return (
      <div className={classNames(styles.marginWrapHome, styles.wrapper)}>
        <Grid verticalAlign="middle" centered style={{ paddingTop: '16px' }}>
          <Grid.Column widescreen={4} largeScreen={4} computer={8} tablet={8} mobile={8}>
            <CardTotal
              item={{ num: stationTotal, title: t('common.stations'), icon: <CardAmount /> }}
              color="bright-moss"
              path={Paths.STATIONS.replace(':slug', slug)}
            />
          </Grid.Column>
          <Grid.Column widescreen={4} largeScreen={4} computer={8} tablet={8} mobile={8}>
            <CardTotal
              item={{ num: deviceTotal, title: t('common.devices'), icon: <CardContent /> }}
              color="pumpkin-orange"
              path={Paths.DEVICES.replace(':slug', slug)}
            />
          </Grid.Column>
          <Grid.Column widescreen={4} largeScreen={4} computer={8} tablet={8} mobile={8}>
            <CardTotal
              item={{ num: contentTotal, title: t('common.contents'), icon: <CardList /> }}
              color="red-burgundy"
              path={Paths.CONTENTS.replace(':slug', slug)}
            />
          </Grid.Column>
          <Grid.Column widescreen={4} largeScreen={4} computer={8} tablet={8} mobile={8}>
            <CardTotal
              item={{ num: scheduleTotal, title: t('common.schedules'), icon: <CardSchedule /> }}
              color="antique-blue"
              path={Paths.SCHEDULES.replace(':slug', slug)}
            />
          </Grid.Column>
        </Grid>
        <Map
          sidebarMenu={sidebarMenu}
          stations={stations}
          speakers={speakers}
          displays={displays}
          integrated={integrated}
        />
      </div>
    );
  },
);

Home.propTypes = {
  sidebarMenu: PropTypes.bool.isRequired,
  contentTotal: PropTypes.number.isRequired,
  scheduleTotal: PropTypes.number.isRequired,
  stationTotal: PropTypes.number.isRequired,
  deviceTotal: PropTypes.number.isRequired,
  /* eslint-disable react/forbid-prop-types */
  stations: PropTypes.arrayOf(PropTypes.any).isRequired,
  devices: PropTypes.arrayOf(PropTypes.any).isRequired,
  slug: PropTypes.string.isRequired,
};

export default Home;
