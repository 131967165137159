import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Modal, Pagination, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { useToggle } from '../../lib/hooks';
import Config from '../../constants/Config';

import UserGroupAddModalContainer from '../../containers/UserGroupAddModalContainer';
import Item from './Item';

import styles from './UserGroups.module.scss';

const LIMIT = Config.LIMIT_PER_PAGE;

const UserGroups = React.memo(
  ({ canDelete, isAdmin, total, items, error, onDelete, onFetch, onClear }) => {
    const [t] = useTranslation();

    const [isOpenModalAddUserGroup, handleToggleModalAddUserGroup] = useToggle();

    const [userGroupIdSelected, setUserGroupIdSelectd] = useState();
    const [selectPage, setSelectPage] = useState(1);

    const handleDelete = useCallback(
      (id) => {
        onDelete(id);
      },
      [onDelete],
    );

    const handleSelectUserGroupId = useCallback(
      (id) => {
        setUserGroupIdSelectd(id);
        handleToggleModalAddUserGroup();
      },
      [handleToggleModalAddUserGroup],
    );

    const handleClose = useCallback(() => {
      setUserGroupIdSelectd();
      handleToggleModalAddUserGroup();
    }, [handleToggleModalAddUserGroup]);

    const handlePagination = useCallback(
      (_, { activePage }) => {
        setSelectPage(activePage);
        onFetch(activePage, LIMIT);
      },
      [onFetch],
    );

    return (
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('common.userGroups')}</h2>
        <Table stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>{t('common.name')}</Table.HeaderCell>
              <Table.HeaderCell width={4}>{t('common.permissions')}</Table.HeaderCell>
              <Table.HeaderCell width={4}>{t('common.creator')}</Table.HeaderCell>
              {isAdmin && (
                <Table.HeaderCell width={3}>
                  <Modal.Actions>
                    <Button
                      // floated="right"
                      className={styles.centerButton}
                      content={t('action.addUserGroup')}
                      onClick={handleToggleModalAddUserGroup}
                    />
                  </Modal.Actions>
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              return (
                <Item
                  isAdmin={isAdmin}
                  canDelete={canDelete}
                  key={item.id}
                  name={item.name}
                  user={item.user}
                  permissions={item.permissions}
                  error={error}
                  onDelete={() => handleDelete(item.id)}
                  onUpdate={() => handleSelectUserGroupId(item.id)}
                  onClear={onClear}
                />
              );
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row textAlign="right">
              <Table.HeaderCell colSpan="4">
                <Pagination
                  onPageChange={handlePagination}
                  activePage={selectPage}
                  totalPages={Math.ceil(total / LIMIT)}
                  ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                  firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                  lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                  prevItem={{ content: <Icon name="angle left" />, icon: true }}
                  nextItem={{ content: <Icon name="angle right" />, icon: true }}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        {isOpenModalAddUserGroup && (
          <UserGroupAddModalContainer selectedId={userGroupIdSelected} onClose={handleClose} />
        )}
      </div>
    );
  },
);

UserGroups.propTypes = {
  canDelete: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  error: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onDelete: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

UserGroups.defaultProps = {
  error: undefined,
};

export default UserGroups;
