import ActionTypes from '../constants/ActionTypes';

const createProvince = (data) => ({
  type: ActionTypes.PROVINCE_CREATE,
  payload: {
    data,
  },
});

createProvince.success = (province, provinceManagers) => ({
  type: ActionTypes.PROVINCE_CREATE__SUCCESS,
  payload: {
    province,
    provinceManagers,
  },
});

createProvince.failure = (error) => ({
  type: ActionTypes.PROVINCE_CREATE__FAILURE,
  payload: {
    error,
  },
});

const handleProvinceCreate = (
  province,
  users,
  provinceManagers,
  districts,
  districtMemberships,
) => ({
  type: ActionTypes.PROVINCE_CREATE_HANDLE,
  payload: {
    province,
    users,
    provinceManagers,
    districts,
    districtMemberships,
  },
});

const updateProvince = (id, data) => ({
  type: ActionTypes.PROVINCE_UPDATE,
  payload: {
    id,
    data,
  },
});

updateProvince.success = (province) => ({
  type: ActionTypes.PROVINCE_UPDATE__SUCCESS,
  payload: {
    province,
  },
});

updateProvince.failure = (id, error) => ({
  type: ActionTypes.PROVINCE_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleProvinceUpdate = (province) => ({
  type: ActionTypes.PROVINCE_UPDATE_HANDLE,
  payload: {
    province,
  },
});

const updateProvinceBackgroundImage = (id) => ({
  type: ActionTypes.PROVINCE_BACKGROUND_IMAGE_UPDATE,
  payload: {
    id,
  },
});

updateProvinceBackgroundImage.success = (province) => ({
  type: ActionTypes.PROVINCE_BACKGROUND_IMAGE_UPDATE__SUCCESS,
  payload: {
    province,
  },
});

updateProvinceBackgroundImage.failure = (id, error) => ({
  type: ActionTypes.PROVINCE_BACKGROUND_IMAGE_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const deleteProvince = (id) => ({
  type: ActionTypes.PROVINCE_DELETE,
  payload: {
    id,
  },
});

deleteProvince.success = (province) => ({
  type: ActionTypes.PROVINCE_DELETE__SUCCESS,
  payload: {
    province,
  },
});

deleteProvince.failure = (id, error) => ({
  type: ActionTypes.PROVINCE_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleProvinceDelete = (province) => ({
  type: ActionTypes.PROVINCE_DELETE_HANDLE,
  payload: {
    province,
  },
});

export default {
  createProvince,
  handleProvinceCreate,
  updateProvince,
  handleProvinceUpdate,
  updateProvinceBackgroundImage,
  deleteProvince,
  handleProvinceDelete,
};
