import { call, put, select } from 'redux-saga/effects';

import {
  // goToWard,
  goToProvince,
} from './router';
import request from '../request';
import selectors from '../../../selectors';
import actions from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createWard(districtId, { import: wardImport, ...data }) {
  const nextData = {
    ...data,
    // position: yield select(selectors.selectNextWardPosition, districtId),
  };

  const localId = yield call(createLocalId);

  yield put(
    actions.createWard({
      ...nextData,
      districtId,
      id: localId,
    }),
  );

  let ward;
  let wardMemberships;

  try {
    ({
      item: ward,
      included: { wardMemberships },
    } = yield wardImport
      ? call(
          request,
          api.createWardWithImport,
          districtId,
          {
            ...nextData,
            importType: wardImport.type,
            importFile: wardImport.file,
          },
          localId,
        )
      : call(request, api.createWard, districtId, nextData));
  } catch (error) {
    yield put(actions.createWard.failure(localId, error));
    return;
  }

  yield put(actions.createWard.success(localId, ward, wardMemberships));
  // yield call(goToWard, ward.id);
}

export function* createWardInCurrentProvince(data) {
  const { provinceId } = yield select(selectors.selectPath);

  yield call(createWard, data.districtId, data);
}

export function* handleWardCreate(ward) {
  yield put(actions.handleWardCreate(ward));
}

export function* fetchWard(id) {
  yield put(actions.fetchWard(id));

  let ward;
  let users;
  let provinces;
  let wardMemberships;
  let contents;
  let contentCategories;
  let stations;
  let devices;
  let deviceMemberships;
  let deviceContents;
  let tasks;
  let attachments;

  try {
    ({
      item: ward,
      included: {
        users,
        provinces,
        wardMemberships,
        contents,
        contentCategories,
        stations,
        devices,
        deviceMemberships,
        deviceContents,
        tasks,
        attachments,
      },
    } = yield call(request, api.getWard, id, true));
  } catch (error) {
    yield put(actions.fetchWard.failure(id, error));
    return;
  }

  yield put(
    actions.fetchWard.success(
      ward,
      users,
      provinces,
      wardMemberships,
      contents,
      contentCategories,
      stations,
      devices,
      deviceMemberships,
      deviceContents,
      tasks,
      attachments,
    ),
  );
}

export function* updateWard(id, data) {
  yield put(actions.updateWard(id, data));

  let ward;
  try {
    ({ item: ward } = yield call(request, api.updateWard, id, data));
  } catch (error) {
    yield put(actions.updateWard.failure(id, error));
    return;
  }

  yield put(actions.updateWard.success(ward));
}

export function* handleWardUpdate(ward) {
  yield put(actions.handleWardUpdate(ward));
}

export function* moveWard(id, index) {
  const { provinceId } = yield select(selectors.selectWardById, id);
  const position = yield select(selectors.selectNextWardPosition, provinceId, index, id);

  yield call(updateWard, id, {
    position,
  });
}

export function* deleteWard(id) {
  const { wardId, provinceId } = yield select(selectors.selectPath);

  if (id === wardId) {
    yield call(goToProvince, provinceId);
  }

  yield put(actions.deleteWard(id));

  let ward;
  try {
    ({ item: ward } = yield call(request, api.deleteWard, id));
  } catch (error) {
    yield put(actions.deleteWard.failure(id, error));
    return;
  }

  yield put(actions.deleteWard.success(ward));
}

export function* handleWardDelete(ward) {
  const { wardId, provinceId } = yield select(selectors.selectPath);

  if (ward.id === wardId) {
    yield call(goToProvince, provinceId);
  }

  yield put(actions.handleWardDelete(ward));
}

export default {
  createWard,
  createWardInCurrentProvince,
  handleWardCreate,
  fetchWard,
  updateWard,
  handleWardUpdate,
  moveWard,
  deleteWard,
  handleWardDelete,
};
