import { inRange } from 'lodash';

const topicControl = (sources, total) => {
  const { provinces, districts, wards, hamlets, devices } = sources;
  let topic = '';
  let DanhSachDiaBanNhan = [];

  let DanhSachThietBi = [];

  if (devices.length === 1) {
    const [device] = devices;
    topic = `thietbi/${device.code}`;
    DanhSachDiaBanNhan = [];
    DanhSachThietBi = [];

    return {
      topic,
      DanhSachDiaBanNhan,
      DanhSachThietBi,
    };
  }

  if (devices.length > 1 && hamlets.length === 1) {
    const [hamlet] = hamlets;
    const devicesInHamlet = total.devices.filter((it) => it.hamletId === hamlet.id);

    topic = `thon/${hamlet.code}`;
    DanhSachDiaBanNhan = [];

    if (devices.length === devicesInHamlet.length) {
      DanhSachThietBi = [];
    } else {
      DanhSachThietBi = devices.map((d) => ({
        CumLoaID: d.code,
      }));
    }

    return {
      topic,
      DanhSachDiaBanNhan,
      DanhSachThietBi,
    };
  }

  if (devices.length > 1 && hamlets.length > 1 && wards.length === 1) {
    const [ward] = wards;
    const hamletsInWard = total.hamlets.filter((it) => it.wardId === ward.id);
    const devicesInWard = total.devices.filter((it) => it.wardId === ward.id);
    const deviceIds = devices.map((it) => it.id);
    topic = `xa/${ward.code}`;
    DanhSachDiaBanNhan = [];

    if (
      hamlets.length === hamletsInWard.length &&
      devicesInWard.length === devices.length &&
      devicesInWard.every((it) => deviceIds.includes(it.id))
    ) {
      DanhSachThietBi = [];
    } else {
      DanhSachThietBi = devices.map((d) => ({
        CumLoaID: d.code,
      }));
    }

    return {
      topic,
      DanhSachDiaBanNhan,
      DanhSachThietBi,
    };
  }

  if (devices.length > 1 && wards.length > 1 && districts.length === 1) {
    const [district] = districts;
    const wardsInDistrict = total.wards.filter((it) => it.districtId === district.id);
    const devicesInDistrict = total.devices.filter((it) => it.districtId === district.id);
    const deviceIds = devices.map((it) => it.id);

    topic = `huyen/${district.code}`;
    DanhSachThietBi = [];

    if (
      wards.length === wardsInDistrict.length &&
      devicesInDistrict.length === devices.length &&
      devicesInDistrict.every((it) => deviceIds.includes(it.id))
    ) {
      DanhSachDiaBanNhan = [];
    } else {
      DanhSachDiaBanNhan = wards.map((w) => ({
        DichID: w.code,
        TenDich: w.name,
      }));
    }

    return {
      topic,
      DanhSachDiaBanNhan,
      DanhSachThietBi,
    };
  }

  if (inRange(districts.length, 2, total.districts.length)) {
    topic = `tinh/${provinces[0].code}`;
    DanhSachDiaBanNhan = wards.map((w) => ({
      DichID: w.code,
      TenDich: w.name,
    }));
    DanhSachThietBi = [];

    return {
      topic,
      DanhSachDiaBanNhan,
      DanhSachThietBi,
    };
  }

  if (districts.length === total.districts.length) {
    topic = `tinh/${provinces[0].code}`;
    DanhSachDiaBanNhan = [];
    DanhSachThietBi = [];

    return {
      topic,
      DanhSachDiaBanNhan,
      DanhSachThietBi,
    };
  }

  return {
    topic,
    DanhSachDiaBanNhan,
    DanhSachThietBi,
  };
};

export default topicControl;
