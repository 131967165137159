import { attr, fk, many } from 'redux-orm';

import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

const DEFAULT_DELETE_FORM = {
  data: {},
  error: null,
};

export default class extends BaseModel {
  static modelName = 'Ward';

  static fields = {
    id: attr(),
    name: attr(),
    code: attr(),
    isFetching: attr({
      getDefault: () => null,
    }),
    provinceId: fk({
      to: 'Province',
      as: 'province',
      relatedName: 'wards',
    }),
    districtId: fk({
      to: 'District',
      as: 'district',
      relatedName: 'wards',
    }),
    deleteForm: attr({
      getDefault: () => DEFAULT_DELETE_FORM,
    }),
    memberUsers: many({
      to: 'User',
      through: 'WardMembership',
      relatedName: 'wards',
    }),
    // filterUsers: many('User', 'filterWards'),
    // filterContents: many('Content', 'filterWards'),
  };

  static reducer({ type, payload }, Ward) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
        if (payload.ward) {
          Ward.upsert({
            ...payload.ward,
            isFetching: false,
          });
        }

        break;
      // case ActionTypes.LOCATION_CHANGE_HANDLE__DISTRICT_FETCH:
      // case ActionTypes.DISTRICT_FETCH:
      //   Ward.withId(payload.id).update({
      //     isFetching: true,
      //   });

      //   break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Ward.all().delete();

        if (payload.ward) {
          Ward.upsert({
            ...payload.ward,
            isFetching: false,
          });
        }

        payload.wards.forEach((ward) => {
          Ward.upsert(ward);
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE__CORE_FETCH:
        // Ward.all()
        //   .toModelArray()
        //   .forEach((wardModel) => {
        //     if (wardModel.id !== payload.currentWardId) {
        //       wardModel.update({
        //         isFetching: null,
        //       });

        //       wardModel.deleteRelated(payload.currentUserId);
        //     }
        //   });

        break;
      case ActionTypes.CORE_INITIALIZE:
        if (payload.ward) {
          Ward.upsert({
            ...payload.ward,
            isFetching: false,
          });
        }

        if (payload.wards) {
          payload.wards.forEach((ward) => {
            Ward.upsert(ward);
          });
        }

        break;
      // case ActionTypes.USER_TO_DISTRICT_FILTER_ADD:
      //   Ward.withId(payload.wardId).filterUsers.add(payload.id);

      //   break;
      // case ActionTypes.USER_FROM_DISTRICT_FILTER_REMOVE:
      //   Ward.withId(payload.wardId).filterUsers.remove(payload.id);

      //   break;
      case ActionTypes.PROVINCE_CREATE_HANDLE:
        payload.wards.forEach((ward) => {
          Ward.upsert(ward);
        });

        break;
      case ActionTypes.PROVINCE_MANAGER_CREATE_HANDLE:
      case ActionTypes.DISTRICT_MEMBERSHIP_CREATE_HANDLE:
        if (payload.wards) {
          payload.wards.forEach((ward) => {
            Ward.upsert({
              ...ward,
              ...(payload.ward &&
                payload.ward.id === ward.id && {
                  isFetching: false,
                }),
            });
          });
        }

        break;
      case ActionTypes.WARD_CREATE:
      case ActionTypes.WARD_CREATE_HANDLE:
      case ActionTypes.WARD_UPDATE__SUCCESS:
      case ActionTypes.WARD_UPDATE_HANDLE:
        Ward.upsert(payload.ward);

        break;
      case ActionTypes.WARD_CREATE__SUCCESS:
        Ward.withId(payload.localId).delete();
        Ward.upsert(payload.ward);

        break;
      case ActionTypes.WARD_FETCH__SUCCESS:
        Ward.upsert({
          ...payload.ward,
          isFetching: false,
        });

        break;
      case ActionTypes.WARD_FETCH__FAILURE:
        Ward.withId(payload.id).update({
          isFetching: null,
        });

        break;
      case ActionTypes.WARD_UPDATE:
        Ward.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.WARD_DELETE:
        // Ward.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.WARD_DELETE__SUCCESS:
      case ActionTypes.WARD_DELETE_HANDLE: {
        const wardModel = Ward.withId(payload.ward.id);

        if (wardModel) {
          wardModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.WARD_DELETE__FAILURE: {
        const wardModel = Ward.withId(payload.id);

        wardModel.update({
          deleteForm: {
            ...wardModel.deleteForm,
            error: payload.error,
          },
        });

        break;
      }
      // case ActionTypes.CONTENT_TO_WARD_FILTER_ADD:
      //   Ward.withId(payload.districtId).filterContents.add(payload.id);

      //   break;
      // case ActionTypes.CONTENT_FROM_WARD_FILTER_REMOVE:
      //   Ward.withId(payload.districtId).filterContents.remove(payload.id);

      //   break;
      default:
    }
  }

  getOrderedMembershipsQuerySet() {
    return this.memberships.orderBy('id');
  }

  getOrderedContentsQuerySet() {
    return this.contents.orderBy('position');
  }

  getOrderedStationsQuerySet() {
    return this.stations.orderBy('position');
  }

  getMembershipModelForUser(userId) {
    return this.memberships
      .filter({
        userId,
      })
      .first();
  }

  hasMembershipForUser(userId) {
    return this.memberships
      .filter({
        userId,
      })
      .exists();
  }

  isAvailableForUser(userId) {
    return (
      this.province &&
      (this.province.hasManagerForUser(userId) || this.hasMembershipForUser(userId))
    );
  }

  deleteRelated(exceptMemberUserId) {
    this.memberships.toModelArray().forEach((wardMembershipModel) => {
      if (wardMembershipModel.userId !== exceptMemberUserId) {
        wardMembershipModel.deleteWithRelated();
      }
    });

    this.contents.delete();

    this.stations.toModelArray().forEach((stationModel) => {
      stationModel.deleteWithRelated();
    });
  }

  deleteWithRelated() {
    // this.deleteRelated();
    this.delete();
  }
}
