import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { usePopup } from '../../lib/popup';

import DroppableTypes from '../../constants/DroppableTypes';

import NameEdit from './NameEdit';
import { ReactComponent as PlusMathIcon } from '../../assets/images/plus-math-icon.svg';
import ActionsStep from '../Memberships/ActionsStep';
import DistrictMembershipPermissionsSelectStep from '../DistrictMembershipPermissionsSelectStep';
import AddStep from './AddStep';

import styles from './Ward.module.scss';

const Ward = React.memo(
  ({
    id,
    index,
    name,
    isPersisted,
    users,
    usersInWard,
    canEdit,
    canLeaveIfLast,
    onUpdate,
    onMembershipCreate,
    onMembershipUpdate,
    onMembershipDelete,
  }) => {
    const [t] = useTranslation();

    const nameEdit = useRef(null);

    const handleHeaderClick = useCallback(() => {
      if (isPersisted && canEdit) {
        nameEdit.current.open();
      }
    }, [isPersisted, canEdit]);

    const handleNameUpdate = useCallback(
      (newName) => {
        onUpdate({
          name: newName,
        });
      },
      [onUpdate],
    );

    const ActionsPopup = usePopup(ActionsStep);

    const AddPopup = usePopup(AddStep);

    const usersNode = (
      <Droppable
        droppableId={`station:${id}`}
        type={DroppableTypes.DEVICE}
        // isDropDisabled={!isPersisted}
      >
        {({ innerRef, droppableProps, placeholder }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div {...droppableProps} ref={innerRef}>
            <div className={styles.devices}>
              {usersInWard.map((item, i) => (
                <div className={styles.user} key={item.id}>
                  <ActionsPopup
                    membership={item}
                    permissionsSelectStep={DistrictMembershipPermissionsSelectStep}
                    leaveButtonContent="action.leaveWard"
                    leaveConfirmationTitle="common.leaveWard"
                    leaveConfirmationContent="common.areYouSureYouWantToLeaveWard"
                    leaveConfirmationButtonContent="action.leaveWard"
                    deleteButtonContent="action.removeFromWard"
                    deleteConfirmationTitle="common.removeMember"
                    deleteConfirmationContent="common.areYouSureYouWantToRemoveThisMemberFromWard"
                    deleteConfirmationButtonContent="action.removeMember"
                    canEditContent="common.canEditContentOfWard"
                    canOnlyView="common.canOnlyViewWard"
                    canEdit={canEdit}
                    // canLeave={usersInWard.length > 1 || canLeaveIfLast}
                    canLeave={false}
                    onUpdate={(data) => onMembershipUpdate(item.id, data)}
                    onDelete={() => onMembershipDelete(item.id)}
                  >
                    <div className={styles.name}>{item.user.name}</div>
                  </ActionsPopup>
                </div>
              ))}
              {placeholder}
            </div>
          </div>
        )}
      </Droppable>
    );

    return (
      <Draggable
        draggableId={`station:${id}`}
        index={index}
        // isDragDisabled={!isPersisted || !canEdit}
        isDragDisabled
      >
        {({ innerRef, draggableProps, dragHandleProps }) => (
          <div
            {...draggableProps} // eslint-disable-line react/jsx-props-no-spreading
            data-drag-scroller
            ref={innerRef}
            className={styles.innerWrapper}
          >
            <div className={styles.outerWrapper}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
                                           jsx-a11y/no-static-element-interactions */}
              <div
                {...dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
                className={classNames(styles.header, canEdit && styles.headerEditable)}
                // onClick={handleHeaderClick}
              >
                <NameEdit ref={nameEdit} defaultValue={name} onUpdate={handleNameUpdate}>
                  <div className={styles.headerName}>{name}</div>
                </NameEdit>
              </div>
              <div
                className={classNames(
                  styles.devicesInnerWrapper,
                  !canEdit && styles.devicesInnerWrapperFull,
                )}
              >
                <div className={styles.devicesOuterWrapper}>{usersNode}</div>
              </div>
              {canEdit && (
                <AddPopup
                  users={users}
                  currentUserIds={usersInWard.map((it) => it.user.id)}
                  permissionsSelectStep={DistrictMembershipPermissionsSelectStep}
                  onCreate={onMembershipCreate}
                >
                  <button
                    type="button"
                    disabled={!isPersisted}
                    className={classNames(styles.addDeviceButton)}
                  >
                    <PlusMathIcon className={styles.addDeviceButtonIcon} />
                    <span className={styles.addDeviceButtonText}>
                      {usersInWard.length > 0 ? t('action.addAnotherUser') : t('action.addUser')}
                    </span>
                  </button>
                </AddPopup>
              )}
            </div>
          </div>
        )}
      </Draggable>
    );
  },
);

Ward.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isPersisted: PropTypes.bool.isRequired,
  usersInWard: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  users: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  canEdit: PropTypes.bool.isRequired,
  canLeaveIfLast: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  // onDelete: PropTypes.func.isRequired,
  // onDeviceCreate: PropTypes.func.isRequired,
  onMembershipCreate: PropTypes.func.isRequired,
  onMembershipUpdate: PropTypes.func.isRequired,
  onMembershipDelete: PropTypes.func.isRequired,
};

Ward.defaultProps = {
  canLeaveIfLast: true,
};

export default Ward;
