import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Chart as ChartJS, Legend, Tooltip, Title, ArcElement, DoughnutController } from 'chart.js';
import { Chart, Doughnut } from 'react-chartjs-2';
import { Checkbox, Menu, Table } from 'semantic-ui-react';
import { upperFirst } from 'lodash';
import { useTranslation } from 'react-i18next';

import ScheduleColors from '../../constants/ScheduleColors';

import styles from './PieChart.module.scss';

ChartJS.register(Legend, Tooltip, Title, ArcElement, DoughnutController);

const labelInside = {
  id: 'customLabel',
  afterDatasetsDraw(chart, args, options) {
    const { ctx } = chart;
    const { unit, total } = chart.data.datasets[0];

    chart.data.datasets[0].data.forEach((dataset, index) => {
      const element = chart.getDatasetMeta(0).data[index];
      const position = element.tooltipPosition();

      if (element.circumference) {
        ctx.fillStyle = '#fff';
        ctx.font = 'bold 12px sans-serif';
        ctx.textAlign = 'center';
        ctx.fillText(`${dataset} ${unit}`, position.x, position.y);
      }
    });

    // Total contents
    if (chart.getDatasetMeta(0).data[0]) {
      const { x, y } = chart.getDatasetMeta(0).data[0];
      ctx.font = 'bold 14px sans-serif';
      ctx.textAlign = 'center';
      ctx.fillStyle = '#000';
      ctx.fillText('Tổng số bản tin', x, y - 10);

      ctx.font = 'bold 18px sans-serif';
      ctx.fillStyle = options.fontColor;
      ctx.fillText(total, x, y + 10);
    }
  },
  afterDraw(chart) {
    const { datasets } = chart.data;
    let hasData = false;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < datasets.length; i++) {
      if (datasets[i].data.length > 0) {
        hasData = true;
        break;
      }
    }

    if (!hasData) {
      const { ctx } = chart;
      const { width, height } = chart;

      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = 'bold 14px sans-serif';
      ctx.fillText('Chưa có dữ liệu', width / 2, height / 2);
      ctx.restore();
    }
  },
};

const LONG_TITLE = 9;

const options = {
  cutout: '55%',
  layout: {
    padding: {
      bottom: (ctx) => {
        const { chart } = ctx;
        let pb = 0;
        chart.data.datasets.forEach((el) => {
          const hOffset = el.hoverOffset || 0;
          pb = Math.max(hOffset + 10, pb);
        });
        return pb;
      },
      top: (ctx) => {
        const { chart } = ctx;
        let pb = 0;
        chart.data.datasets.forEach((el) => {
          const hOffset = el.hoverOffset || 0;
          pb = Math.max(hOffset + 10, pb);
        });
        return pb;
      },
    },
  },
  interaction: {
    mode: 'index',
  },
  plugins: {
    customLabel: {
      fontColor: '#006C4C',
    },
    legend: {
      display: false,
    },
    tooltip: {
      position: 'nearest',
      callbacks: {
        title: (context) => {
          let title = context[0].label;

          const words = title.split(' ');

          // If the title exceeds the limit, split it into two lines
          if (words.length > LONG_TITLE) {
            title = `${words.slice(0, LONG_TITLE).join(' ')}\n${words.slice(LONG_TITLE).join(' ')}`;
          }

          return title;
        },
        label: (context) => {
          let label = context.dataset.label || '';

          if (context.parsed !== null) {
            label += context.parsed;
          }

          return `${label} ${context.dataset.unit}`;
        },
      },
    },
  },
  elements: {
    arc: {
      borderJoinStyle: 'miter',
    },
  },
};

const PieChart = React.memo(({ title, contents, contentType }) => {
  const [t] = useTranslation();

  const [percent, setPercent] = useState(false);
  const [selectedType, setSelectedType] = useState('category');

  const filterContents = contents.filter((content) => content.PhanLoai === contentType);

  const data = useMemo(() => {
    const resultData = filterContents.reduce(
      (rs, cur) => {
        /* eslint-disable no-param-reassign */
        if (selectedType === 'contentType') {
          if (!rs.labels.includes(cur.type)) {
            rs.labels.push(cur.type);
            rs.data[rs.data.length] = 1;
            rs.colors.push(cur.type);
            return rs;
          }

          const indexLabel = rs.labels.indexOf(cur.type);
          rs.data[indexLabel] += 1;

          return rs;
        }

        if (!rs.labels.includes(cur.categories[0]?.name || 'Khác')) {
          rs.labels.push(cur.categories[0]?.name || 'Khác');
          rs.data[rs.data.length] = 1;
          rs.colors.push(cur.categories[0]?.color || '#006C4C');
          return rs;
        }

        const indexLabel = rs.labels.indexOf(cur.categories[0]?.name || 'Khác');
        rs.data[indexLabel] += 1;
        return rs;
      },
      { labels: [], data: [], colors: [] },
    );

    return {
      labels:
        selectedType === 'contentType'
          ? resultData.labels.map((it) => t(`common.${it}`))
          : resultData.labels,
      datasets: [
        {
          data: resultData.data,
          backgroundColor:
            selectedType === 'contentType'
              ? ScheduleColors.slice(0, resultData.data.length)
              : resultData.colors,
          borderColor:
            selectedType === 'contentType'
              ? ScheduleColors.slice(0, resultData.data.length)
              : resultData.colors,
          borderWidth: 1,
          hoverOffset: 20,
        },
      ],
    };
  }, [filterContents, selectedType, t]);

  const handleChangeTypeValue = useCallback(() => {
    setPercent(!percent);
  }, [percent]);

  const dataChart = useMemo(() => {
    const dataValues = data.datasets[0].data;
    const sumValues = dataValues.reduce((result, val) => result + val, 0);

    const values = dataValues.map((value) =>
      percent ? Number((value / sumValues) * 100).toFixed(1) : value,
    );
    return {
      ...data,
      datasets: [
        {
          ...data.datasets[0],
          data: [...values],
          label: percent ? t('common.ratio') : t('common.newsNumber'),
          unit: percent ? '%' : '',
          total: sumValues,
        },
      ],
    };
  }, [data, percent, t]);

  const handleItemMenuClick = useCallback((_, { name }) => {
    setSelectedType(name);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <Menu compact size="mini">
          <Menu.Item
            active={selectedType === 'category'}
            as="a"
            onClick={handleItemMenuClick}
            name="category"
          >
            {t('action.category')}
          </Menu.Item>
          <Menu.Item
            active={selectedType === 'contentType'}
            as="a"
            onClick={handleItemMenuClick}
            name="contentType"
          >
            {t('action.content')}
          </Menu.Item>
        </Menu>
      </div>
      <div className={styles.content}>
        <div className={styles.wrapperChart}>
          {/* <Chart type="doughnut" data={dataChart} options={options} plugins={[labelInside]} /> */}
          <Doughnut data={dataChart} options={options} plugins={[labelInside]} />
        </div>
        {dataChart.labels.length > 0 && (
          <>
            <div className={styles.wrapperTable}>
              <Table size="small" basic="very" unstackable className={styles.table}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1} />
                    <Table.HeaderCell width={12}>{t(`common.${selectedType}`)}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">{t('common.quantity')}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {dataChart.labels.map((label, i) => (
                    <Table.Row key={`${label}-${Math.random()}`}>
                      <Table.Cell>
                        <div
                          style={{
                            width: 16,
                            height: 16,
                            borderRadius: 50,
                            backgroundColor: dataChart.datasets[0].backgroundColor[i],
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell>{upperFirst(label)}</Table.Cell>
                      <Table.Cell textAlign="right">{data.datasets[0].data[i]}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
            <Checkbox toggle label="Phần trăm (%)" onChange={handleChangeTypeValue} />
          </>
        )}
      </div>
    </div>
  );
});

PieChart.propTypes = {
  title: PropTypes.string.isRequired,
  contents: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  contentType: PropTypes.string.isRequired,
};

export default PieChart;
