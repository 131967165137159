import { all, takeEvery } from 'redux-saga/effects';

import services from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* provincesWatchers() {
  yield all([
    takeEvery(EntryActionTypes.PROVINCE_CREATE, ({ payload: { data } }) =>
      services.createProvince(data),
    ),
    takeEvery(EntryActionTypes.PROVINCE_CREATE_HANDLE, ({ payload: { province } }) =>
      services.handleProvinceCreate(province),
    ),
    takeEvery(EntryActionTypes.CURRENT_PROVINCE_UPDATE, ({ payload: { data } }) =>
      services.updateCurrentProvince(data),
    ),
    takeEvery(EntryActionTypes.PROVINCE_UPDATE_HANDLE, ({ payload: { province } }) =>
      services.handleProvinceUpdate(province),
    ),
    takeEvery(EntryActionTypes.CURRENT_PROVINCE_BACKGROUND_IMAGE_UPDATE, ({ payload: { data } }) =>
      services.updateCurrentProvinceBackgroundImage(data),
    ),
    takeEvery(EntryActionTypes.CURRENT_PROVINCE_DELETE, () => services.deleteCurrentProvince()),
    takeEvery(EntryActionTypes.PROVINCE_DELETE_HANDLE, ({ payload: { province } }) =>
      services.handleProvinceDelete(province),
    ),
  ]);
}
