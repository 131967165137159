import { call, put, select } from 'redux-saga/effects';
import actions from '../../../actions';
import api from '../../../api';
import request from '../request';
import selectors from '../../../selectors';

export function* getReports(districtId) {
  const { id: provinceId } = yield select(selectors.selectCurrentProvince);

  yield put(actions.getReports(provinceId, districtId));
  const data = { districtId };

  let devices;
  let stations;
  let categories;
  let contentCategories;
  let contents;

  try {
    ({
      included: { devices, stations, categories, contentCategories, contents },
    } = yield call(request, api.getReports, provinceId, data));
  } catch (error) {
    yield put(actions.getReports.failure(error));
  }

  yield put(
    actions.getReports.success(
      devices,
      stations,
      categories,
      contentCategories,
      contents,
      districtId,
    ),
  );
}

export function* getExportReport(data) {
  const { id: provinceId } = yield select(selectors.selectCurrentProvince);

  yield put(actions.getExportReport(provinceId, data));

  let devices;
  let stations;
  let contents;

  try {
    ({
      included: { devices, stations, contents },
    } = yield call(request, api.getReports, provinceId, data));
  } catch (error) {
    yield put(actions.getExportReport.failure(error));
    return;
  }

  yield put(actions.getExportReport.success(devices, stations, contents));
}

export function* clearExportState() {
  yield put(actions.clearExportState());
}

export default { getReports, getExportReport, clearExportState };
