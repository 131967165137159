import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import Schedules from '../components/Schedules';

const mapStateToProps = (state) => {
  const isCurrentUserManager = selectors.selectIsCurrentUserManagerForCurrentProvince(state);
  const schedules = selectors.selectSchedulesForCurrentProvince(state);
  const currentUserMembership = selectors.selectCurrentUserMembershipForCurrentDistrict(state);
  const { slug } = selectors.selectCurrentProvince(state);
  const isCurrentUserEditor =
    !!currentUserMembership && currentUserMembership.role === DistrictMembershipRoles.EDITOR;

  return {
    slug,
    items: schedules,
    canEdit: isCurrentUserEditor,
    canEditMemberships: isCurrentUserManager,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createScheduleInCurrentProvince,
      onUpdate: (scheduleId, data) => entryActions.updateSchedule(scheduleId, data),
      onFetch: entryActions.fetchSchedules,
      onDelete: entryActions.deleteSchedule,
      onRefetchContents: entryActions.enalbleContentsFetching,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Schedules);
